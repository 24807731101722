import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    ListItemText,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../../hooks';
import { selectCatDiscounts } from '../../../store/cat_discounts/selectors';
import {
    selectConcepts,
    selectStudentsInFilteredConceptsDiscounts,
} from '../../../store/concepts/selectors';
import { selectStudents } from '../../../../store/slices/entities/students';
import { useFormik, FieldArray, Formik, Form, Field } from 'formik';
import { selectCatConcepts } from '../../../store/cat_concepts/selectors';
import { Transfer } from 'antd';
import * as Yup from 'yup';
import { createDiscount } from '../../../store/discounts/operations';
import Feedback from '../../../../service/Feedback';
import { fetchCreateDiscount } from '../../../store/discounts/fetchSlice';

const CreateDiscount = ({ open, onclose }) => {
    ///////////// LOCAL STATE /////////////

    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [stepType, setStepType] = useState(null);
    const [selectedCatalogId, setSelectedCatalogId] = useState(null);
    const [filteredConcepts, setFilteredConcepts] = useState([]);

    ///////////// SHARED STATE /////////////
    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const feedbackApp = useFeedback();

    ///////////// SELECTORS /////////////

    const catalogDiscounts = useSelector(selectCatDiscounts);
    const students = Object.values(useSelector(selectStudents));
    const concepts = Object.values(useSelector(selectConcepts));
    const catalogConcepts = useSelector((state) =>
        Object.values(selectCatConcepts(state))
    );

    const filteredStudents = useSelector((state) =>
        selectStudentsInFilteredConceptsDiscounts(state, selectedCatalogId)
    );

    ///////////// USE EFFECTS /////////////

    useEffect(() => {
        if (open) {
            dispatch(fetchCreateDiscount(schoolId)).finally(() =>
                setLoading(false)
            );
        }
    }, [dispatch, schoolId, open]);

    ///////////// FORMIK SETUP /////////////

    const validationSchema = Yup.object({
        description: Yup.string().required('Descripción es requerida'),
        discount_catalog_id: Yup.number().required(
            'Catálogo de Descuentos es requerido'
        ),
    });

    const handleSubmit = async (values) => {
        const data = [];

        const selectedStudentDetails = students.filter((student) =>
            selectedStudents.includes(student.student_id)
        );

        selectedStudentDetails.forEach((student) => {
            const studentConcepts = concepts.filter(
                (concept) =>
                    concept.student_id === student.student_id &&
                    concept.concept_catalog_id === selectedCatalogId &&
                    concept.status === 2 &&
                    !(
                        (concept.in_agreement === true &&
                            concept.in_discount === true) ||
                        (concept.in_agreement === false &&
                            concept.in_discount === true)
                    )
            );
            studentConcepts.forEach((concept) => {
                data.push({
                    description: values.description,
                    concept_id: concept.concept_id,
                    catalog_discount_id: values.discount_catalog_id,
                });
            });
        });

        await dispatch(createDiscount({ data, schoolId }))
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Descuentos Registrados',
                });
                handleReset();
            })
            .catch((error) => {
                feedbackApp.showFeedback({ title: error.feedback.title });
            });
    };

    ////////// FUNCTIONS /////////////

    const steps = ['Información General', 'Asignar Alumnos'];

    const handleStudentSelect = (targetKeys) => {
        setSelectedStudents(targetKeys);
    };
    const handleNext = (type = null) => {
        setStepType(type);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setSelectedStudents([]);
        setSelectedGroups([]);
    };

    const handleReset = () => {
        setActiveStep(0);
        setSelectedStudents([]);
        setSelectedGroups([]);
        setStepType(null);
        onclose();
    };

    const handleCatalogChange = (selectedCatalogId) => {
        setSelectedCatalogId(selectedCatalogId);
    };

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onclose();
                }
            }}
            maxWidth="lg"
            fullWidth
            disableEscapeKeyDown
            sx={{
                '& .MuiDialog-paper': {
                    padding: 5,
                    borderRadius: 2,
                },
            }}>
            {loading ? (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <DialogTitle
                        sx={{
                            textAlign: 'center',
                            position: 'relative',
                        }}>
                        <Typography variant="h4" component="div">
                            Registra un Descuento
                        </Typography>
                        <Typography variant="body2">
                            Aquí podrás registrar un nuevo descuento
                        </Typography>
                        <IconButton
                            onClick={handleReset}
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: 6,
                                top: 6,
                                color: theme.palette.grey[500],
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Formik
                            initialValues={{
                                description: '',
                                discount_catalog_id: null,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}>
                            {({
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form>
                                    <Stepper
                                        activeStep={activeStep}
                                        alternativeLabel>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    {activeStep === 0 && (
                                        <>
                                            <Field
                                                name="description"
                                                as={TextField}
                                                fullWidth
                                                label="Descripción"
                                                error={
                                                    touched.description &&
                                                    Boolean(errors.description)
                                                }
                                                helperText={
                                                    touched.description &&
                                                    errors.description
                                                }
                                                variant="outlined"
                                                margin="normal"
                                            />
                                            <Autocomplete
                                                value={
                                                    catalogConcepts.find(
                                                        (concept) =>
                                                            concept.concept_catalog_id ===
                                                            values.concept_catalog_id
                                                    ) || null
                                                }
                                                noOptionsText="No hay opciones disponibles"
                                                options={catalogConcepts.filter(
                                                    (option) =>
                                                        option.active === true
                                                )}
                                                getOptionLabel={(option) =>
                                                    option.concept
                                                }
                                                onChange={(event, value) => {
                                                    const catalogId =
                                                        value?.concept_catalog_id ||
                                                        null;
                                                    setSelectedCatalogId(
                                                        catalogId
                                                    );
                                                    setFieldValue(
                                                        'concept_catalog_id',
                                                        catalogId
                                                    );
                                                    handleCatalogChange(
                                                        catalogId
                                                    );
                                                }}
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <Box
                                                        component="li"
                                                        {...props}
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                            alignItems:
                                                                'center',
                                                            borderBottom:
                                                                '1px solid #ddd',
                                                            padding: '8px 16px',
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            }}>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}>
                                                                {option.concept}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary">
                                                                ID:{' '}
                                                                {
                                                                    option.concept_catalog_id
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            sx={{
                                                                marginLeft:
                                                                    'auto',
                                                            }}>
                                                            Total: $
                                                            {option.value}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Catálogo de concepto"
                                                        variant="outlined"
                                                        margin="normal"
                                                    />
                                                )}
                                            />
                                            <Autocomplete
                                                value={
                                                    catalogDiscounts.find(
                                                        (cataloDiscounts) =>
                                                            cataloDiscounts.discount_catalog_id ===
                                                            values.discount_catalog_id
                                                    ) || null
                                                }
                                                options={catalogDiscounts.filter(
                                                    (option) =>
                                                        option.active === true
                                                )}
                                                getOptionLabel={(option) =>
                                                    option.title
                                                }
                                                onChange={(event, value) => {
                                                    const discountId =
                                                        value?.discount_catalog_id;
                                                    setFieldValue(
                                                        'discount_catalog_id',
                                                        discountId
                                                    );
                                                }}
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <Box
                                                        component="li"
                                                        {...props}
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                            alignItems:
                                                                'center',
                                                            borderBottom:
                                                                '1px solid #ddd',
                                                            padding: '8px 16px',
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            }}>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}>
                                                                {option.title}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary">
                                                                ID:{' '}
                                                                {
                                                                    option.discount_catalog_id
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            sx={{
                                                                marginLeft:
                                                                    'auto',
                                                            }}>
                                                            Total:
                                                            {option.value_modality ===
                                                            1
                                                                ? `$${option.value}`
                                                                : `${option.value}%`}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Catálogo de Descuentos"
                                                        variant="outlined"
                                                        margin="normal"
                                                        error={
                                                            touched.discount_catalog_id &&
                                                            Boolean(
                                                                errors.discount_catalog_id
                                                            )
                                                        }
                                                        helperText={
                                                            touched.discount_catalog_id &&
                                                            errors.discount_catalog_id
                                                        }
                                                    />
                                                )}
                                            />
                                            <Box
                                                sx={{
                                                    mt: 2,
                                                    textAlign: 'right',
                                                }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleNext()}
                                                    disabled={
                                                        !values.description ||
                                                        !values.discount_catalog_id ||
                                                        !selectedCatalogId
                                                    }>
                                                    Siguiente
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                    {activeStep === 1 && (
                                        <>
                                            <Typography
                                                variant="h6"
                                                textAlign="center"
                                                sx={{ mt: 2, padding: 2 }}>
                                                Selecciona los Alumnos
                                            </Typography>
                                            <Transfer
                                                dataSource={filteredStudents.map(
                                                    (student) => ({
                                                        key: student.student_id,
                                                        title: `${student.name} ${student.last_name} ${student.second_last_name}`,
                                                    })
                                                )}
                                                targetKeys={selectedStudents}
                                                onChange={handleStudentSelect}
                                                render={(item) => item.title}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.title
                                                        .toLowerCase()
                                                        .includes(
                                                            input.toLowerCase()
                                                        )
                                                }
                                                listStyle={{
                                                    width: '100%',
                                                    height: 400,
                                                }}
                                            />
                                            <Box
                                                sx={{
                                                    display: 'flex',

                                                    mt: 2,
                                                }}>
                                                <Button
                                                    fullWidth
                                                    color="primary"
                                                    onClick={handleBack}>
                                                    Atrás
                                                </Button>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={
                                                        isSubmitting ||
                                                        selectedStudents.length ===
                                                            0
                                                    }>
                                                    {isSubmitting ? (
                                                        <CircularProgress
                                                            size={24}
                                                        />
                                                    ) : (
                                                        'Registrar Descuento'
                                                    )}
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};

export default CreateDiscount;
