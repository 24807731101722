import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../../../hooks';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import {
    fetchClassroomSubjectData,
    invalidate,
    selectClassroomSubjectDetails,
    selectClassroomViewmodelFetchStatusById,
} from '../../../store/meGroups/classrooms';
import { Error } from '../../../../components/Feedback';
import SkeletonPage from './../../../components/SkeletonPage';
import ClassroomSubjectPreviewCard from './../../../components/ClassroomPreviewCard';
import SubjectAssistanceTable from './components/SubjectAssistanceTable';
import { DateTime } from 'luxon';

const ClassroomSubjectAssistance = () => {
    ////////// HOOKS //////////
    let { id } = useParams();
    const Auth = useAuth();
    const dispatch = useDispatch();

    const classroomDetails = useSelector(
        selectClassroomSubjectDetails(id, Auth.user.school_id)
    );

    const statusServer = useSelector(
        selectClassroomViewmodelFetchStatusById(id)
    );

    const reload = () => {
        dispatch(
            fetchClassroomSubjectData({
                groupId: classroomDetails.group.group_id,
                subjectId: classroomDetails.subject.subject_id,
                curretnDate: DateTime.local().toFormat('yyyy-MM-dd'),
                schoolId: Auth.user.school_id,
                uuid: classroomDetails.viewmodel.uuid,
            })
        );
    };

    /**
     * UseEffect que ejecuta el proceso
     * de recuperación de datos
     */
    useEffect(() => {
        reload();
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(id));
        reload();
    };

    const getCurrentDate = () => {
        const fecha = DateTime.local();
        const formattedDate = fecha
            .setLocale('es')
            .toFormat("dd 'de' LLLL yyyy");
        return formattedDate;
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {statusServer === 'pending' && <SkeletonPage />}

            {statusServer === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {statusServer === 'fulfilled' && (
                <>
                    <Box
                        sx={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="h4" component="div">
                            Control de asistencia del dia: {getCurrentDate()}{' '}
                        </Typography>

                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}
                            >
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>
                    <ClassroomSubjectPreviewCard classroom={classroomDetails} />
                    <SubjectAssistanceTable classroom={id} />
                </>
            )}
        </Box>
    );
};

export default ClassroomSubjectAssistance;
