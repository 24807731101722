import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Services from '../../../../service/Connection';
import {
    PROFESSORS_SUBJECT_EXPIRE_TIME,
    STUDENTSUI_MODALS_EXPIRE_TIME,
} from '../../../../service/const';
import Feedback from '../../../../service/Feedback';
import * as _ from 'lodash';
import {
    addManyAssists,
    addManyGroupsStudents,
    setManyAssists,
    setManyScores,
    upsertManyScores,
    upsertOneSubject,
} from './entitiesSlice';
import Connection from '../../../../service/Connection';
import {
    addAssists,
    addCalifications,
    addStudents,
    addSubjects,
} from './entitiesSlice';
import { convertArrayToObject } from '../../../../libs/utils';
import { selectClassroomSelected } from './uiSlice';
import { selectClassroomsEntitiesAsArray } from '../classrooms';

const emptyState = {};

/**
 * Slice para el settings UI
 */
export const studentsUIItemsSlice = createSlice({
    name: 'newProfessor/items',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, { payload }) => {
            state[payload].fetch.didInvalidate = true;
        },
        addOneItem: (state, { payload }) => {
            state[payload.subject_id] = payload;
        },
        //addManyItems: itemsAdapter.addMany,
        setAllItems: (state, action) => {
            return action.payload;
        },
        //upsertManyItems: itemsAdapter.upsertMany,
        itemUpdated: (state, { payload }) => {
            const { id, changes } = payload;
            state[id] = { ...state[id], ...changes };
        },
        //removeOneItem: itemsAdapter.removeOne,
        selectStudentItem: (state, { payload }) => {
            const { itemGroupId, itemStudentId } = payload;

            state[itemGroupId].ui.studentSelected = itemStudentId;
        },
        addAditionalDataGroupItem: (state, action) => {
            state[action.payload.id] = {
                ...state[action.payload.id],
                ...action.payload.data,
            };
        },
        /**
         * Marcar que ya se nombro lista
         *
         * @param {*} state
         * @param {*} action
         */
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        /**
         * Recuperar informacion adicional de la materia
         */
        builder.addCase(
            fetchNewSubjectAditionalData.fulfilled,
            (state, { meta }) => {
                const { subjectId } = meta.arg;

                state[subjectId].fetch.expireIn = new Date().setMinutes(
                    new Date().getMinutes() + PROFESSORS_SUBJECT_EXPIRE_TIME
                );
                state[subjectId].fetch.status = 'fulfilled';
                state[subjectId].fetch.fetchingAt = Date.now();
                state[subjectId].fetch.didInvalidate = false;
            }
        );
        builder.addCase(
            fetchNewSubjectAditionalData.pending,
            (state, { meta }) => {
                const { subjectId } = meta.arg;

                state[subjectId].fetch.status = 'pending';
            }
        );
        builder.addCase(
            fetchNewSubjectAditionalData.rejected,
            (state, action) => {
                if (action.payload) {
                    const { subjectId, feedback } = action.payload;
                    //state[groupId].servers.details.feedback = feedback

                    state[subjectId].fetch.status = 'rejected';
                }
            }
        );

        ////////////////// STORE CALIFICATIONS ////////////////////

        // builder.addCase(newStoreCalificationList.rejected, rejectedAssistList);
        // builder.addCase(
        //     newStoreCalificationList.fulfilled,
        //     fulfilledAssistList
        // );
        // builder.addCase(newStoreCalificationList.pending, pendingAssistList);
    },
});

export const {
    addManyItems,
    upsertManyItems,
    itemUpdated,
    setAllItems,
    removeOneItem,
    selectStudentItem,
    addOneItem,
    addAditionalDataGroupItem,
    invalidate,
} = studentsUIItemsSlice.actions;

export default studentsUIItemsSlice.reducer;

//////////////////// SELECTORES //////////////////

/**
 * selector para recuperar todas las entidades de
 * los elementos
 *
 * @param {*} store
 * @returns
 */
export const selectEntitiesItems = (store) =>
    store.professorClassroom.meGroups.home.classroomModelsIds;

/**
 * selector para recuperar todo los items
 *
 * @param {*} store
 * @returns
 */
export const selectAlltems = (state) =>
    Object.values(selectEntitiesItems(state));

/**
 * selector para recuperar todas las Ids de los Items
 *
 * @param {*} store
 * @returns
 */
export const selectItemsIds = (state) =>
    Object.values(selectEntitiesItems(state));

/**
 * selector para recuperar un Item por id
 *
 * @param {*} store
 * @returns
 */
export const selectItemById = (subjectId) => (state) =>
    selectEntitiesItems(state)[subjectId];

/**
 * Selector para recuperar el elemento seleccionado
 *
 * @param {*} store
 * @returns
 */
export const selectItemSelected = (state) =>
    selectEntitiesItems(state)[selectClassroomSelected(state)];

/**
 * Selector para recuperar el estado de fetching del elemento seleccionado
 *
 * @param {*} store
 * @returns
 */
export const selectItemFetchingStatusSelected = (state) =>
    selectItemSelected(state).fetch.status;

/**
 * Selector para recuperar el estado de operation del elemento seleccionado
 *
 * @param {*} store
 * @returns
 */
export const selectItemOperationStatusSelected = (state) =>
    selectItemSelected(state).fetch.operation;

/**
 * Selector para recuperar datos para editar calificaciones
 *
 * @param {*} store
 * @returns
 */
/*
export const selectCalificationsCardEdit = (subjectId, groupId) =>
    (state) => {

        const partials = useSelector(selectAllPartials)

        const califications = useSelector(selectAllCalifications)

        const students = selectStudentsByGroup(store, groupId)


        students.map()

        return {
            students,
            califications,
            partials
        }
    }
*/

/**
 * Seleccionar classroms con su uuid y tipo
 */

export const selectClassroomIdAndType = createSelector(
    selectItemsIds,
    selectClassroomsEntitiesAsArray,
    (itemsIds, classrooms) => {
        let filterClassrooms = classrooms
            .filter((classroom) => itemsIds.some((id) => classroom.id === id))
            .map((object) => {
                return {
                    uuid: object.id,
                    classroomType: object.ui.classroomType,
                };
            });
        return filterClassrooms;
    }
);

///////////////////////// TRUNKS ////////////////////////////

/**
 * Crea un elemento de student
 *
 * @param {*} student
 *
 * @returns
 */
export const getEmptyItem = (studentId, extra = {}) => {
    return {
        listPerformed: false,
        subject_id: studentId,
        fetch: {
            state: 'idel',
            operation: 'idel',
            didInvalidate: false,
            expireIn: null,
            fetchingAt: null,
        },
        ...extra,
    };
};

/**
 * Recuperar informacion adicional de la materia
 */
export const fetchNewSubjectAditionalData = createAsyncThunk(
    'newProfessors/items/data',
    async (data, thunkAPI) => {
        const { groupId, subjectId, curretnDate } = data;

        let FeedbackService = new Feedback();

        try {
            let students = await Connection.StudentsByGroup(groupId).then(
                (res) => res.data.data
            );

            let allAssists = [];
            let allCalifications = [];

            for (let student of students) {
                const assits = await Connection.getStudentAssists(
                    student.student_id,
                    subjectId
                ).then((res) => res.data.data);
                const califications =
                    await Connection.getCalificationbyStudentUsingSubjetID(
                        student.student_id,
                        subjectId
                    ).then((res) => res.data.data);

                allAssists = allAssists.concat(assits);
                allCalifications = allCalifications.concat(califications);
            }

            let groupsStudents = students.map((s) => ({
                group_id: groupId,
                student_id: s.student_id,
            }));

            let assistancePerformed = false;

            let findPerformedList = allAssists.find(
                ({ date }) => date.substring(0, 10) == curretnDate
            );

            assistancePerformed = findPerformedList ? true : false;

            thunkAPI.dispatch(setManyScores(allCalifications));
            thunkAPI.dispatch(setManyAssists(allAssists));
            thunkAPI.dispatch(
                addStudents(convertArrayToObject(students, 'student_id'))
            );
            thunkAPI.dispatch(addManyGroupsStudents(groupsStudents));

            thunkAPI.dispatch(
                addAditionalDataGroupItem({
                    id: subjectId,
                    data: {
                        listPerformed: assistancePerformed,
                    },
                })
            );

            return {
                students,
                allAssists,
                allCalifications,
                groupsStudents,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: ({ subjectId }, { getState, extra }) => {
            const store = getState();

            const studentItemSelected = selectItemById(subjectId)(store);

            let { didInvalidate, expireIn } = studentItemSelected.fetch;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
