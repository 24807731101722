import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';

import {
    fetchInitialInformation,
    selectFetchingStatus,
} from '../../../store/slices/professorModule/fetchSlice';
import { invalidate } from '../../../store/slices/professorModule/fetchSlice';
import { selectItemsIds } from '../../../store/slices/professorModule/ItemsSlice';

import { useAuth } from '../../../hooks';

import { Empty, Error } from '../../../components/Feedback';
import { SkeletonBlock, GroupCard } from './components';


/**
 * Pagina de home de los maestros
 *
 * @returns
 */

const Home = () => {
    ////////// HOOKS //////////

    const Auth = useAuth();
    const dispatch = useDispatch();

    //////////////// SHARES STATE /////////////////

    const groupsItemsIds = useSelector(selectItemsIds);
    const fetchingStatus = useSelector(selectFetchingStatus);

    ///////////// ACTIONS /////////////////

    /**
     * Iniciar el proceso de recuperacion de datos
     */
    const reload = () => {
        dispatch(
            fetchInitialInformation({
                professorId: Auth.getUserID(),
                schoolId: Auth.user.school_id,
            })
        );
    };

    /**
     * UseEffect que ejecuta el proceso
     * de recuperación de datos
     */
    useEffect(() => {
        reload();
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate());
        reload();
    };

    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {fetchingStatus == 'pending' && <SkeletonBlock items={9} />}

            {fetchingStatus == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus == 'fulfilled' && (
                <>
                    <Box
                        style={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="h4" component="div">
                            Mis grupos
                        </Typography>

                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}
                            >
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>

                    <Box sx={{ mt: 5 }}>
                        {groupsItemsIds.length == 0 ? (
                            <Empty label="Sin grupos" />
                        ) : (
                            <Grid container spacing={{ xs: 1, md: 4 }}>
                                {groupsItemsIds.map((item, index) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={4}
                                            lg={3}
                                            key={index}
                                        >
                                            <GroupCard id={item}> </GroupCard>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Home;
