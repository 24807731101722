import { createSlice } from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import { selectEntitiesFiles } from '../../../store/slices/entities/files';
import {
    selectAllGroupsEntities,
    selectGroupsById,
} from '../../../store/slices/entities/groups';

import { selectStudentById } from '../../../store/slices/entities/students';
import { selectUserById } from '../../../store/slices/entities/users';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import { selectReportHome } from './selectors';

const emptyState = [];

export const ReportSlice = createSlice({
    name: 'reportsUI',
    initialState: emptyState,
    reducers: {
        setReport: (state, action) => {
            state.list = action.payload;
        },

        setReports: (state, action) => {
            state.reports = action.payload;
        },
        setAllItems: (state, action) => {
            return action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', () => {
            return emptyState;
        });
    },
});

export const { setReport, setAllItems, setReports } = ReportSlice.actions;

/**
 * Selector para la tbla de reportes
 *
 * @param {*} state
 */
export const selectReports = (state) => {
    //return state.reportsUI.reports
    let reportsCollect = Object.values(state.entities.reports.byId).map((r) => {
        let reportcatalog =
            state.entities.reportscatalogs.byId[r.catalog_report_id];

        let file = selectEntitiesFiles(state)[r.file_id];

        let student = selectStudentById(file.student_id)(state);
        let assessor = selectUserById(file.assessor_id)(state);
        let group = selectGroupsById(student.group_id)(state);
        let reporter = selectUserById(r.profesor_id)(state);

        return {
            ...r,
            file,
            student,
            group,
            reportcatalog,
            assessor,
            reporter,
        };
    });

    return reportsCollect;
};

//////////////////// SELECTORES //////////////////
export const selectGroupsUI = (state) => state.newReports.group;

export const selectGroupsData = (state) => state.reportsUI.data;

export default ReportSlice.reducer;

export const selectAllGroupsItemsEntities = createSelector(
    selectReportHome,
    (home) => {
        return home.items;
    }
);
//Traer todos los grupos de entities
export const selectAllGroupsItems = createSelector(
    selectAllGroupsItemsEntities,
    selectAllGroupsEntities,
    selectGroupsUI,
    (groupsVMIdfeEntities, groupsEntities, viewModels) => {
        return groupsVMIdfeEntities.map((i) => {
            let currentViewModel = viewModels[i];

            return {
                ...groupsEntities[currentViewModel.ui.group_id],
                viewModel: currentViewModel,
            };
        });
    }
);

export const fetchCatalogReport = (schoolId) => async (dispatch) => {
    try {
        let catalogReport = await Connection.getReportCatalogBySchool(schoolId);
        dispatch(setReport(catalogReport.data.data));
    } catch (err) {
        console.log(err);
    }
};
