import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { useAuth, useFeedback } from '../../../../hooks';
import { fetchCreateConcept } from '../../../store/concepts/operations';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { CreateCatConcepts } from '../../../store/cat_concepts/operations';

const CreateCatalog = ({ open, onclose }) => {
    ///////////// SHERD STATE /////////////

    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const feedbackApp = useFeedback();

    ///////////// LOCAL STATE /////////////

    ///////////// FORM CONFIGURATION /////////////

    const formik = useFormik({
        initialValues: {
            concepts: [{ concept: '', value: '' }],
        },
        validationSchema: Yup.object({
            concepts: Yup.array().of(
                Yup.object({
                    concept: Yup.string().required(
                        'El concepto es obligatorio'
                    ),
                    value: Yup.number()
                        .required('El valor es obligatorio')
                        .positive('El valor debe ser positivo'),
                })
            ),
        }),

        onSubmit: async (values, { resetForm }) => {
            await dispatch(
                CreateCatConcepts({ schoolId, data: values.concepts })
            )
                .unwrap()
                .then(() => {
                    feedbackApp.showFeedback({
                        title: 'Catálogo de conceptos creado',
                    });
                    formik.resetForm();
                    onclose();
                })
                .catch((error) => {
                    feedbackApp.showFeedback({ title: error.feedback.title });
                });
        },
    });

    ///////////// RENDER /////////////

    return (
        <>
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onclose();
                    }
                }}
                maxWidth="lg"
                fullWidth
                disableEscapeKeyDown
                sx={{
                    '& .MuiDialog-paper': {
                        padding: 5,
                        width: '60%',
                        borderRadius: 2,
                    },
                }}>
                <DialogTitle
                    sx={{
                        textAlign: 'center',
                        position: 'relative',
                    }}>
                    <Typography variant="h4" component="div">
                        Registra un nuevo catálogo de conceptos
                    </Typography>
                    <Typography variant="body2">
                        Aquí podrás registrar un nuevo catálogo de conceptos
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            formik.resetForm();
                            onclose();
                        }}
                        sx={{
                            position: 'absolute',
                            right: 6,
                            top: 6,
                            color: theme.palette.grey[500],
                        }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <FormikProvider value={formik}>
                    <DialogContent>
                        <FieldArray name="concepts">
                            {({ push, remove }) => (
                                <>
                                    {formik.values.concepts.map(
                                        (concept, index) => (
                                            <Box
                                                key={index}
                                                sx={{ marginBottom: 2 }}>
                                                <TextField
                                                    fullWidth
                                                    label="Nombre del Concepto"
                                                    variant="outlined"
                                                    id={`concepts.${index}.concept`}
                                                    name={`concepts.${index}.concept`}
                                                    value={
                                                        formik.values.concepts[
                                                            index
                                                        ].concept
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    error={
                                                        formik.touched
                                                            .concepts?.[index]
                                                            ?.concept &&
                                                        Boolean(
                                                            formik.errors
                                                                .concepts?.[
                                                                index
                                                            ]?.concept
                                                        )
                                                    }
                                                    helperText={
                                                        formik.touched
                                                            .concepts?.[index]
                                                            ?.concept &&
                                                        formik.errors
                                                            .concepts?.[index]
                                                            ?.concept
                                                    }
                                                    margin="dense"
                                                />
                                                <TextField
                                                    fullWidth
                                                    id={`concepts.${index}.value`}
                                                    name={`concepts.${index}.value`}
                                                    placeholder="0.00"
                                                    type="number"
                                                    label="$ Valor Monetario"
                                                    variant="outlined"
                                                    value={
                                                        formik.values.concepts[
                                                            index
                                                        ].value
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    error={
                                                        formik.touched
                                                            .concepts?.[index]
                                                            ?.value &&
                                                        Boolean(
                                                            formik.errors
                                                                .concepts?.[
                                                                index
                                                            ]?.value
                                                        )
                                                    }
                                                    helperText={
                                                        formik.touched
                                                            .concepts?.[index]
                                                            ?.value &&
                                                        formik.errors
                                                            .concepts?.[index]
                                                            ?.value
                                                    }
                                                    margin="dense"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                $
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                {formik.values.concepts.length >
                                                    1 && (
                                                    <Button
                                                        type="button"
                                                        onClick={() => {
                                                            if (
                                                                formik.values
                                                                    .concepts
                                                                    .length > 1
                                                            ) {
                                                                remove(index);
                                                            }
                                                        }}>
                                                        Eliminar
                                                    </Button>
                                                )}
                                            </Box>
                                        )
                                    )}
                                    <Button
                                        type="button"
                                        onClick={() =>
                                            push({ concept: '', value: '' })
                                        }>
                                        Agregar Concepto
                                    </Button>
                                </>
                            )}
                        </FieldArray>

                        <DialogActions>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={formik.handleSubmit}
                                    disabled={
                                        formik.isSubmitting ||
                                        !(formik.isValid && formik.dirty)
                                    }
                                    type="submit"
                                    sx={{ ml: 'auto' }}>
                                    {formik.isSubmitting ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        'Guardar'
                                    )}{' '}
                                </Button>
                            </Box>
                        </DialogActions>
                    </DialogContent>
                </FormikProvider>
            </Dialog>
        </>
    );
};

export default CreateCatalog;
