import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectThemeBySchool } from '../../../store/slices/entities/settings';
import { selectGroupItem } from '../../../store/slices/groupsUI/uiSlice';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Skeleton,
    Stack,
} from '@mui/material';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { useAuth, useFeedback } from '../../../hooks';
import {
    fetchModules,
    selectFetchModuleStatusByViewModelId,
    selectSubjectsCatalogsByLevel,
} from '../../../store/slices/groupsUI/itemSlice';
import { selectGroupsById } from '../../../store/slices/entities/groups';
import { Error } from '../../../components/Feedback';
import { selectModulesByGroup } from '../../../store/slices/entities/modules';
import { DataGrid, esES, GridActionsCellItem } from '@mui/x-data-grid';
import * as Style from '../../../styles/index';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    deleteModule,
    updateModule,
} from '../../../store/slices/groupsUI/operationsSlice';
import { GroupsToolBarOnSubjects } from './GroupsToolBar';
import Services from '../../../service/Connection';
import ModuleEdit from './ModuleEdit';
import {
    selectMainAndModuleSubjectsByMainGroup,
    selectModulesWithSubjectsByModule,
} from '../../../store/slices/entities/selectors';
import ModuleEdition from './ModuleEdition';

const ModuleDetailsModal = ({ open, setOpen, groupId }) => {
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();
    const classes = Style.tableModalStyle();

    //////////// SHARED STATE  /////////////

    const { colorTitleBar, fontColor } = useSelector(
        selectThemeBySchool(schoolId)
    );

    const fetchStatus = useSelector(
        selectFetchModuleStatusByViewModelId(groupId)
    );
    const group = useSelector(selectGroupsById(groupId));

    const subjectsByLevel = useSelector(
        selectSubjectsCatalogsByLevel(group.level)
    );

    const modules = useSelector(selectModulesWithSubjectsByModule(groupId));
    let unavailableSubjects = useSelector(
        selectMainAndModuleSubjectsByMainGroup(groupId)
    );

    const modulesOrdenados = modules.slice().sort((a, b) => {
        const nombreA = a.annexed_name.toUpperCase();
        const nombreB = b.annexed_name.toUpperCase();
        if (nombreA < nombreB) {
            return -1;
        }
        if (nombreA > nombreB) {
            return 1;
        }
        return 0;
    });

    //////////// LOCAL STATE ////////////////////

    const [typeView, setTypeView] = useState(1);
    const [moduleSelected, setModuleSelected] = useState(null);
    const [selectedToUpdate, setSelectedToUpdate] = useState(null);
    const [statusOperation, setStatusOperation] = useState('idle');

    unavailableSubjects = unavailableSubjects.filter(
        (item) => item.group_id !== moduleSelected?.group_id
    );

    let columns = [
        {
            field: 'annexed_name',
            headerName: 'Nombre',
            flex: 0.4,
            minWidth: 150,
            editable: false,
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}
                    >
                        {params.value}
                    </div>
                );
            },
        },
        {
            field: 'subjects',
            headerName: 'Materias',
            flex: 0.4,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            align: 'center',
        },
        {
            field: 'total_presence',
            headerName: 'Asistencias',
            flex: 0.4,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            align: 'center',
        },
        {
            field: 'total_absence',
            headerName: 'Faltas',
            flex: 0.4,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            align: 'center',
        },
        {
            field: 'average_rating',
            headerName: 'Promedio del módulo',
            flex: 0.4,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            align: 'center',
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            description: 'Esta columna no se puede ordenar',
            sortable: false,
            flex: 0.5,
            minWidth: 50,
            headerAlign: 'center',
            align: 'center',
            type: 'actions',
            headerClassName: 'super-app-theme--header',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    color="primary"
                    title="Editar"
                    label="Editar"
                    showInMenu
                    onClick={() => {
                        handlerShowUpdateModuleView(params.row);
                    }}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    color="primary"
                    title="Eliminar"
                    label="Eliminar"
                    showInMenu
                    onClick={() => {
                        handlerShowDeleteModuleView(params.row);
                    }}
                />,
            ],
        },
    ];

    /////////////////// ACTIONS /////////////////

    useEffect(() => {
        if (open && groupId) {
            dispatch(fetchModules({ groupId }));
        }
    }, [open]);

    const reload = () => {
        dispatch(fetchModules(groupId));
    };

    const formInvalid = () => {
        return (
            selectedToUpdate?.name === '' ||
            selectedToUpdate?.total +
            selectedToUpdate?.added.length -
            selectedToUpdate?.remove.length <
            2
        );
    };

    const handlerShowUpdateModuleView = (module) => {
        setModuleSelected(module);
        setTypeView(2);
    };

    const handlerShowDeleteModuleView = (module) => {
        setModuleSelected(module);
        setTypeView(3);
    };

    const handlerDeleteModule = () => {
        setStatusOperation('pending');

        dispatch(deleteModule(moduleSelected))
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Módulo eliminado',
                });
                setModuleSelected(null);
                setTypeView(1);
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            })
            .then(() => {
                setStatusOperation('idle');
            });
    };

    const handlerUpdateModule = () => {
        setStatusOperation('pending');
        dispatch(
            updateModule({
                moduleId: moduleSelected.group_id,
                removeSubjectsIds: selectedToUpdate.remove,
                addedSubjectsIds: selectedToUpdate.added,
                moduleName: selectedToUpdate.name,
            })
        )
            .unwrap()
            .then((i) => {
                feedbackApp.showFeedback({
                    title: 'Módulo actualizado',
                });
                setModuleSelected(null);
                setTypeView(1);
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            })
            .then(() => {
                setStatusOperation('idle');
            });
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }

        setOpen(false);
        dispatch(selectGroupItem(null));
        setTypeView(1);
        setModuleSelected(null);
    };

    const handleSubjectsSelected = (module) => {
        setSelectedToUpdate(module);
    };

    return (
        <>
            {typeView == 1 && (
                <Dialog
                    open={open}
                    fullWidth={true}
                    size="sm"
                    classes={{ paper: classes.dialogWrapper }}
                    disableEscapeKeyDown={statusOperation == 'pending'}
                    onClose={handleClose}
                >
                    <DialogTitle
                        className={classes.dialogTitle}
                        style={{
                            backgroundColor: colorTitleBar.value,
                            color: fontColor.value,
                        }}
                    >
                        <div
                            style={{ display: 'flex', color: fontColor.value }}
                        >
                            <Typography
                                variant="h6"
                                component="div"
                                style={{ flexGrow: 1 }}
                            >
                                Módulos
                                <br />
                                <span
                                    style={{ fontSize: '15px', color: '#fff' }}
                                >
                                    Grupo {group?.grade}° {group?.group}{' '}
                                    {getTurnLevel(group?.turn)}{' '}
                                    {getLevelName(group?.level)}
                                </span>
                            </Typography>
                        </div>
                    </DialogTitle>
                    <DialogContent classes={{ root: classes.dialogContent }}>
                        {fetchStatus == 'fulfilled' && (
                            <Box
                                sx={{
                                    '& .super-app-theme--header': {
                                        backgroundColor: colorTitleBar.value,
                                        color: fontColor.value,
                                    },
                                    '& .MuiDataGrid-root': {
                                        border: 0,
                                    },
                                }}
                            >
                                <DataGrid
                                    localeText={
                                        esES.components.MuiDataGrid.defaultProps
                                            .localeText
                                    }
                                    getRowId={({ group_id }) => group_id}
                                    rows={modulesOrdenados}
                                    columns={columns}
                                    pageSize={10}
                                    disableSelectionOnClick
                                    autoHeight
                                    componentsProps={{
                                        panel: {
                                            placement: 'bottom-end',
                                        },
                                    }}
                                    disableDensitySelector
                                />
                            </Box>
                        )}

                        {fetchStatus == 'pending' && (
                            <>
                                <Stack spacing={2} sx={{ padding: 2 }}>
                                    <Skeleton
                                        variant="rectangular"
                                        height={30}
                                    />
                                    <Skeleton
                                        variant="rectangular"
                                        height={30}
                                    />
                                    <Skeleton
                                        variant="rectangular"
                                        height={30}
                                    />
                                    <Skeleton
                                        variant="rectangular"
                                        height={30}
                                    />
                                </Stack>
                            </>
                        )}

                        {fetchStatus == 'rejected' && (
                            <Error
                                onRetry={reload}
                                message={'Estamos teniendo problemas'}
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={handleClose}
                            disabled={statusOperation == 'pending'}
                        >
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {typeView == 2 && (
                <Dialog
                    open={open}
                    fullWidth={true}
                    size="lg"
                    disableEscapeKeyDown={statusOperation == 'pending'}
                    onClose={handleClose}
                >
                    <DialogTitle
                        style={{
                            backgroundColor: colorTitleBar.value,
                            color: fontColor.value,
                        }}
                    >
                        <div
                            style={{ display: 'flex', color: fontColor.value }}
                        >
                            <Typography
                                variant="h6"
                                component="div"
                                style={{ flexGrow: 1 }}
                            >
                                Actualizar módulo
                                <br />
                                <span
                                    style={{ fontSize: '15px', color: '#fff' }}
                                >
                                    Módulo {moduleSelected?.annexed_name}
                                </span>
                            </Typography>
                        </div>
                    </DialogTitle>
                    <DialogContent dividers>
                        <ModuleEdition
                            module={moduleSelected}
                            unavailableSubjects={unavailableSubjects}
                            subjectsByLevel={subjectsByLevel}
                            onChange={handleSubjectsSelected}
                        />
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            size="small"
                            color="primary"
                            onClick={handlerUpdateModule}
                            loading={statusOperation == 'pending'}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            disabled={formInvalid()}
                        >
                            Guardar
                        </LoadingButton>

                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => setTypeView(1)}
                            disabled={statusOperation == 'pending'}
                        >
                            Regresar
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {typeView == 3 && (
                <Dialog
                    open={open}
                    fullWidth={true}
                    size="sm"
                    disableEscapeKeyDown={statusOperation == 'pending'}
                    onClose={handleClose}
                >
                    <DialogTitle
                        style={{
                            backgroundColor: colorTitleBar.value,
                            color: fontColor.value,
                        }}
                    >
                        <div
                            style={{ display: 'flex', color: fontColor.value }}
                        >
                            <Typography
                                variant="h6"
                                component="div"
                                style={{ flexGrow: 1 }}
                            >
                                Eliminar módulo
                                <br />
                                <span
                                    style={{ fontSize: '15px', color: '#fff' }}
                                >
                                    Módulo: {moduleSelected?.annexed_name}
                                </span>
                            </Typography>
                        </div>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography
                            variant="h6"
                            component="div"
                            style={{ flexGrow: 1, textAlign: 'center' }}
                        >
                            El módulo y sus materias serán eliminados del grupo
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            size="small"
                            color="error"
                            onClick={handlerDeleteModule}
                            loading={statusOperation == 'pending'}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                        >
                            Eliminar
                        </LoadingButton>

                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => setTypeView(1)}
                            disabled={statusOperation == 'pending'}
                        >
                            Regresar
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default ModuleDetailsModal;
