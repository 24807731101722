import { Construction } from "@mui/icons-material"
import axios from "axios";
import enviroment from "./Service";

/**
 * Clase encargada de validar todo lo referente a los tokens
 * asi como su generacion y guardado
 */
export default class Token {
    token = null
    httpClientAuth = null

    constructor(token = null) {
        if (!token){
            let storageToken = JSON.parse(localStorage.getItem('token'))

            if (storageToken) {
                this.token = this.storageToken    
            }
        } else {
            this.token = token
        }
        
        this.httpClientAuth = axios.create({
            baseURL: enviroment.RUTA_API,
            proxy: false
        });
    }

    /**
     * Recuperamos el token
     * 
     * @returns String token
     */
    getToken() {
        if (!this.token) {
            return null
        }
        return this.token
    }

    /**
     * Se agerga un token para poder realizar varias aperaciones sobre el
     * 
     * @param {*} token 
     */
    setToken(token) {
        this.token = token
    }

    /**
     * Generamos un nuevo token 
     * 
     * @param {*} user 
     * @param {*} password 
     * @returns 
     */
    generateToken(user, password){
        return this.httpClientAuth.post(`/oauth/token`,{
            client_secret: enviroment.SECRET_CLIENT,
            client_id: enviroment.ID_CLIENT,
            grant_type: 'password',
            username: user,
            password: password,
            scope: '*'
        }).then(response => {
            this.token = response.data

            return response.data
        })
    }
    
}