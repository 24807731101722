import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';

const levels = [
    {
        id: 0,
        name: 'Escuela',
    },
    {
        id: 1,
        name: 'Preescolar',
    },
    {
        id: 2,
        name: 'Primaria',
    },
    {
        id: 3,
        name: 'Secundaria',
    },
    {
        id: 4,
        name: 'Bachillerato',
    },
    {
        id: 5,
        name: 'Universidad',
    },
    {
        id: 6,
        name: 'Maestría',
    },
    {
        id: 7,
        name: 'Posgrado',
    },
    {
        id: 8,
        name: 'Doctorado',
    },
    {
        id: 9,
        name: 'Certificación',
    },
];

export const userSlice = createSlice({
    name: 'calendar',
    initialState: {
        dataStatus: 'pending',
        loading: 'idle',
        levels: levels,
        filters: {
            startDate: null,
            endDate: null,
            level: -1,
        },
        activitiesList: [],
        activitiesCalendar: [],
        activities: [],
    },
    reducers: {
        setActivities: (state, action) => {
            state.activities = action.payload;
        },
        filterActivities: (state, action) => {
            if (action.payload != null) {
                state.filters = action.payload;
            }

            // Filtrar por nivel
            let activitiesByLevel = state.activities.filter((item) => {
                let level = state.filters.level;
                if (level == -1) {
                    return item;
                }

                return item.level == level;
            });

            // Filtrar por fecha

            state.activitiesCalendar = activitiesByLevel;

            let activityList = activitiesByLevel;

            if (state.filters.startDate && state.filters.endDate) {
                activityList = activitiesByLevel.filter((item) => {
                    let startDate = new Date(state.filters.startDate);
                    let endDate = new Date(state.filters.endDate);

                    let activityStartDate = new Date(item.start_date);

                    let activityEndDate = new Date(item.end_date);

                    if (item.is_range == 0) {
                        return (
                            activityStartDate >= startDate &&
                            activityStartDate <= endDate
                        );
                    }

                    const inCenter =
                        activityStartDate >= startDate &&
                        activityStartDate <= endDate &&
                        activityEndDate >= startDate &&
                        activityEndDate <= endDate;

                    const inLeft =
                        activityStartDate < startDate &&
                        activityEndDate >= startDate &&
                        activityEndDate <= endDate;

                    const inRight =
                        activityEndDate > endDate &&
                        activityStartDate <= endDate &&
                        activityStartDate >= startDate;

                    const outside =
                        activityStartDate < startDate &&
                        activityEndDate > endDate;

                    return outside || inCenter || inLeft || inRight;
                });
            }

            state.activitiesList = activityList;
        },
        setActivitiesList: (state, action) => {
            state.activitiesCalendar = action.payload;
        },
        setActivitiesCalendar: (state, action) => {
            state.activitiesList = action.payload;
        },
        addActivity: (state, action) => {
            state.activities.push(action.payload);
        },
        updateActivityItem: (state, action) => {
            let newActivities = state.activities.map((item) => {
                if (item.activity_id == action.payload.activity_id) {
                    item = { ...item, ...action.payload };
                }

                return item;
            });

            state.activities = newActivities;
        },
        deleteActivityItem: (state, action) => {
            let newActivities = state.activities.filter((item) => {
                return item.activity_id != action.payload.activity_id;
            });

            state.activities = newActivities;
        },
        loading: (state, action) => {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        hideLoading: (state, action) => {
            if (state.loading === 'pending') {
                state.loading = 'idle';
            }
        },
        loadingData: (state, action) => {
            if (state.dataStatus === 'idle') {
                state.dataStatus = 'pending';
            }
        },
        loadedData: (state, action) => {
            if (state.dataStatus === 'pending') {
                state.dataStatus = 'idle';
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return {
                dataStatus: 'pending',
                loading: 'idle',
                levels: levels,
                filters: {
                    startDate: null,
                    endDate: null,
                    level: -1,
                },
                activitiesList: [],
                activitiesCalendar: [],
                activities: [],
            };
        });

        builder.addCase(updateActivity.fulfilled, (state, action) => {
            let newActivities = state.activities.map((item) => {
                if (item.activity_id == action.payload.activity_id) {
                    item = { ...item, ...action.payload };
                }

                return item;
            });

            state.activities = newActivities;
        });
    },
});

export const {
    loadedData,
    loading,
    hideLoading,
    setActivities,
    filterActivities,
    setActivitiesList,
    setActivitiesCalendar,
    addActivity,
    updateActivityItem,
    deleteActivityItem,
} = userSlice.actions;

////////////////////// ESTADOS /////////////////////////
export const getDataStatus = (state) => state.calendar.dataStatus;
export const getLoading = (state) => state.calendar.loading;

export const getActivities = (state) => state.calendar.activities;
export const getActivitiesList = (state) => state.calendar.activitiesList;
export const getActivitiesCalendar = (state) =>
    state.calendar.activitiesCalendar;

export const getLevels = (state) => state.calendar.levels;

export default userSlice.reducer;

////////////////////// Thunk //////////////////

/**
 * Recuperar todas las actividades
 */
export const fetchAllActivities = createAsyncThunk(
    'calendar/activity',
    async (schoolId, thunkAPI) => {
        thunkAPI.dispatch(loading());

        let activities = await Connection.getActivitiesBySchool(schoolId).then(
            (response) => response.data.data
        );

        thunkAPI.dispatch(setActivities(activities));

        thunkAPI.dispatch(hideLoading());

        return activities;
    }
);

/**
 * Actualziar una actividad
 */
export const updateActivity = createAsyncThunk(
    'calendar/activity/update',
    async (payload, thunkAPI) => {
        const { activity_id, data } = payload;

        thunkAPI.dispatch(loading());

        let activity = await Connection.updateActivity(activity_id, data).then(
            (response) => response.data.data
        );

        //thunkAPI.dispatch(addActivity(activity))

        //dispatch(filterActivities(null))

        thunkAPI.dispatch(hideLoading());

        return activity;
    }
);

/**
 * crear actividad
 */
export const createActivity = createAsyncThunk(
    'calendar/activity/create',
    async (payload, thunkAPI) => {
        const { school_id, data } = payload;

        thunkAPI.dispatch(loading());

        let activity = await Connection.storeActivity(school_id, data).then(
            (response) => response.data.data
        );

        thunkAPI.dispatch(addActivity(activity));

        //dispatch(filterActivities(null))

        thunkAPI.dispatch(hideLoading());

        return activity;
    }
);

/**
 * Eliminar una actividad
 */
export const deleteActivity = createAsyncThunk(
    'calendar/activity/delete',
    async (activityId, thunkAPI) => {
        thunkAPI.dispatch(loading());

        let activity = await Connection.deleteActivity(activityId).then(
            (response) => response.data.data
        );

        thunkAPI.dispatch(deleteActivityItem(activity));

        thunkAPI.dispatch(filterActivities(null));

        thunkAPI.dispatch(hideLoading());

        return activity;
    }
);
