import Connection from './Connection';
import Token from './Token';

export const USERS = {
    ADMINISTER: 'ADMINISTER',
    PROFESSOR: 'PROFESSOR',
    PARENT: 'PARENT',
    ASSESSOR: 'ASSESSOR',
    DIRECTOR: 'DIRECTOR',
    SPOKESMAN: 'SPOKESMAN',
};

/**
 * Clase encargada de la autenticacion y tipos de usuarios
 */
class Authentication {
    //connection = null
    token = null;
    user = null;

    availableUsers = [
        {
            id: 'ADMINISTER',
            regex: /^1[0-1]{19}$/,
            home: '/configuracion',
            endpoint: '/administrators',
            priority: 1,
        },
        {
            id: 'PROFESSOR',
            regex: /^[0-1]{1}1[0-1]{18}$/,
            home: '/inicio-maestros',
            endpoint: '/professors',
            priority: 2,
        },
        {
            id: 'DIRECTOR',
            regex: /^[0-1]{5}1[0-1]{14}$/,
            home: '/director',
            endpoint: '/directors',
            priority: 3,
        },
        {
            id: 'ADMINISTRATIVE',
            regex: /^[0-1]{6}1[0-1]{13}$/,
            home: '/administrativos',
            endpoint: '/administratives',
            priority: 4,
        },
        {
            id: 'ASSESSOR',
            regex: /^[0-1]{2}1[0-1]{17}$/,
            home: '/orientador',
            endpoint: '/assessors',
            priority: 5,
        },
        {
            id: 'SUPERVISOR',
            regex: /^[0-1]{10}1[0-1]{9}$/,
            home: '/Supervisiones',
            endpoint: '/users',
            priority: 6,
        },
        {
            id: 'TUTOR',
            regex: /^[0-1]{7}1[0-1]{12}$/,
            home: '/tutores',
            endpoint: '/users',
            priority: 7,
        },
        {
            id: 'PARENT',
            regex: /^[0-1]{3}1[0-1]{16}$/,
            home: '/padres',
            endpoint: '/parents',
            priority: 8,
        },
        {
            id: 'SPOKESMAN',
            regex: /^[0-1]{4}1[0-1]{15}$/,
            home: '/avisos',
            endpoint: '/spokesmen',
            priority: 99999,
        },
    ];

    constructor() {
        //this.connection = Connection
        this.Token = new Token();
    }

    /**
     * Funcion para logear al usuario
     *
     * @param {*} username
     * @param {*} password
     * @returns
     */
    async login(username, password) {
        this.username = username;
        this.password = password;

        let promise = new Promise((resolve, reject) => {
            this.loginResolve(resolve, reject, username, password);
        }).then((data) => {
            this.setUser(data.userData);

            localStorage.setItem(
                'user',
                JSON.stringify({
                    user: data.userData,
                    loggedIn: true,
                })
            );

            localStorage.setItem(
                'confSchool',
                JSON.stringify(data.setting.data)
            );

            return data;
        });

        return promise;
    }

    /**
     * Deslogeamos al usuario
     */
    async logout() {
        localStorage.removeItem('confSchool');
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('professorModule');
        localStorage.removeItem('noticesModule');
        this.user = null;
    }

    /**
     * Agregar informacion del usuario actuamente logeado
     *
     * @param user Informacion del usuario
     */
    setUser(user) {
        this.user = user;
    }

    /**
     * Recuperamos la informacion del usuario logeado
     *
     * @param user Informacion del usuario
     */
    getUser() {
        return this.user;
    }

    /**
     * Obtenemos el id del usuario
     */
    getUserID(user = null) {
        if (!user) {
            user = this.user;
        }

        let options = [
            user?.administrator_id,
            user?.professor_id,
            user?.assessor_id,
            user?.parent_id,
            user?.spokesman_id,
            user?.adminti_id,
            user?.user_id,
        ];

        return options.find((i) => i != null && i != undefined);
    }

    /**
     * Obtiene el endpoint dependiendo del tipo de usuario
     *
     * @returns
     */
    getEndPoint() {
        let userDetail = this.getGroupDetailsByUser();

        if (!userDetail) {
            return '/users';
        }

        return userDetail.endpoint;
    }

    /**
     * Obtenemos el url del home del usuario
     *
     * @returns
     */
    getHomeRoute() {
        let userDetail = this.getGroupDetailsByUser();

        if (!userDetail) {
            return '/working';
        }

        return userDetail.home;
    }

    /**
     * Obtenemos la informacion del tipo de usuario
     * usando el usuario logeado
     *
     * @returns
     */
    getGroupDetailsByUser() {
        if (this.user == null) {
            return null;
        }

        this.availableUsers.sort((a, b) => {
            if (a.priority < b.priority) {
                return -1;
            }
            if (a.priority > b.priority) {
                return 1;
            }

            return 0;
        });

        const regexObj = this.availableUsers.find((item) =>
            item.regex.test(this.user.group_id)
        );

        if (!regexObj) {
            return null;
        }

        return regexObj;
    }

    /**
     * Verifica si el usuario pertenece a algun grupo especifico
     *
     * @param {*} groupName
     * @returns
     */
    belongToGroup(groupName) {
        if (this.user == null) {
            return null;
        }

        const regexObj = this.availableUsers.find(
            (item) => item.id == groupName
        );

        return regexObj.regex.test(this.user.group_id);
    }

    /**
     * Verifica si el usuario pertenece a algun grupo especifico CURA
     *
     * @param {*} groupName
     * @param {*} user Usuario a verificar
     * @returns
     */
    belongToGroupCura(groupName, user) {
        if (!user) {
            return false;
        }

        const regexObj = this.availableUsers.find(
            (item) => item.id === groupName
        );

        return regexObj && regexObj.regex.test(user.group_id);
    }

    async loginResolve(resolve, reject, username, password) {
        try {
            let token = await this.Token.generateToken(username, password);

            Connection.getClient().setToken(this.Token);

            let user = await Connection.loginMe(token.access_token).then(
                (res) => res.data.data
            );

            this.setUser(user);
            let userId = this.getUserID();

            let school = null;
            let settings = [];

            if (this.hasSchools()) {
                settings = await Connection.getSettingsBySchool(
                    user.school_id
                ).then((res) => res.data.data);
                school = await Connection.getSchoolByUser(
                    this.getEndPoint(),
                    userId
                ).then((r) => r.data.data);
            }

            resolve({
                userData: user,
                token: token,
                setting: settings,
                school,
            });
        } catch (err) {
            reject(err);
        }
    }

    /**
     * Retorna verdadero si es administrador web
     *
     * @returns
     */
    isAdminister() {
        return this.belongToGroup('ADMINISTER');
    }

    /**
     * Retorna verdadero si es profesor
     *
     * @returns
     */
    isProfessor() {
        return this.belongToGroup('PROFESSOR');
    }

    /**
     * Retorna verdadero si es asesor
     *
     * @returns
     */
    isAsessor() {
        return this.belongToGroup('ASSESSOR');
    }

    /**
     * Retorna verdadero si es padre
     *
     * @returns
     */
    isParent() {
        return this.belongToGroup('PARENT');
    }

    /**
     * Retorna verdadero si es profesor
     *
     * @returns
     */
    isSpokesman() {
        return this.belongToGroup('SPOKESMAN');
    }

    /**
     * Retorna verdadero si es director
     *
     * @returns
     */
    isDirector() {
        return this.belongToGroup('DIRECTOR');
    }

    /**
     * Retorna verdadero si es director
     *
     * @returns
     */
    isAdministrative() {
        return this.belongToGroup('ADMINISTRATIVE');
    }

    /**
     * Verifica si el usuario tiene varios roles
     *
     * @param User Usuario a verificar
     *
     * @returns boolean
     */
    isMultiUser(user = null) {
        if (!user) {
            user = this.user;
        }
        let allowed = this.getCountGroupsBelongsToUser(user.group_id);

        return allowed > 1;
    }

    /**
     * Obtenemos la cantidad de permisos disponibles
     * @param groupId
     * @returns
     */
    getCountGroupsBelongsToUser(groupId) {
        let groupsIDs = groupId.split('');

        let allowed = groupsIDs.filter((groupKey) => {
            return groupKey == '1';
        });

        return allowed.length;
    }

    /**
     * Verifica el usuario esta autenticado
     *
     * @returns
     */
    isAuthenticated() {
        return this.user != null;
    }

    /**
     * Retorna todos los tipos de usuarios
     *
     * @returns
     */
    getUserTypes() {
        if (this.user == null) {
            return [];
        }

        return this.availableUsers.filter((item) =>
            item.regex.test(this.user.group_id)
        );
    }

    /**
     * Verifica si el usuario cuenta con alguna escuela
     *
     * @returns
     */
    hasSchools() {
        return (
            this.user.school_id != 0 &&
            this.user.school_id != null &&
            this.user.school_id != undefined
        );
    }
}

export default Authentication;
