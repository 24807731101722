import { createSelector, createSlice } from '@reduxjs/toolkit';
import { selectedReportNoticesUI } from './selectors';

const emptyState = {
    noticeSelected: null,
    filters: {},
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {},
    reducers: {
        noticeReportSelected: (state, { payload }) => {
            state.noticeSelected = payload;
        },
        updateReportFilters: (state, { payload }) => {
            const { filter } = payload;
            state.filters = { ...filter };
        },
    },

    extraReducers: (builder) => {
        builder.addCase('app/clear', () => {
            return emptyState;
        });
    },
});

export const { noticeReportSelected, updateReportFilters } = uiSlice.actions;

export default uiSlice.reducer;

///////////// REDUCERS /////////////

export const selectSelectedReportNoticeUI = createSelector(
    selectedReportNoticesUI,
    (ui) => {
        return ui.noticeSelected;
    }
);

export const selectFilterNoticesFilter = createSelector(
    selectedReportNoticesUI,
    (ui) => {
        return ui.filters;
    }
);
