import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Card, CardContent, Paper, Grid } from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectBestAverageLevel,
} from '../../../store/students/fetchSlice';
import { getBestAverageLevel } from '../../../store/students/thunks';
import { selectComponentStudentsBestAverageLevelStatusServer } from '../../../store/students/componentsStatusSelectors';
import { SkeletonStudentsCards } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';

const BestAverageLevel = ({ level, onDataAvailable }) => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentStudentsBestAverageLevelStatusServer
    );

    const bestAverageLevel = useFetchResource(
        () =>
            getBestAverageLevel({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectBestAverageLevel,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getBestAverageLevel({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectBestAverageLevel,
            [cycleSelected, schoolId]
        );
    };

    const filteredLevel = bestAverageLevel?.value
        ? bestAverageLevel.value[level]
        : null;

    useEffect(() => {
        if (filteredLevel) {
            onDataAvailable(level);
        }
    }, [filteredLevel, level, onDataAvailable]);

    return (
        <Box>
            {/* {fetchingStatus === 'pending' && (
                <SkeletonStudentsCards items={9} />
            )} */}
            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {fetchingStatus === 'fulfilled' && filteredLevel && (
                <Card
                    sx={{
                        background: '#03a9f4',
                        borderRadius: '10px',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        padding: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: '750px',
                        margin: 'auto',
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 700,
                            color: '#fff',
                            marginBottom: '10px',
                            textAlign: 'center',
                        }}
                    >
                        {bestAverageLevel?.title || 'Mejor Promedio de Nivel'}
                    </Typography>
                    <Paper
                        elevation={3}
                        sx={{
                            margin: '16px 0',
                            padding: '16px',
                            width: '100%',
                            backgroundColor: '#f1f1f1',
                            borderRadius: '10px',
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 700,
                                color: '#03a9f4',
                                marginBottom: '10px',
                                textAlign: 'center',
                            }}
                        >
                            {level}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: '#808080' }}
                                >
                                    {filteredLevel.labels[0]}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ color: '#03a9f4', fontWeight: 600 }}
                                >
                                    {filteredLevel.data[0] || 'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: '#808080' }}
                                >
                                    {filteredLevel.labels[2]}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ color: '#03a9f4', fontWeight: 600 }}
                                >
                                    {filteredLevel.data[2] || 'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: '#808080' }}
                                >
                                    {filteredLevel.labels[1]}
                                </Typography>
                                <Box
                                    sx={{
                                        backgroundColor: '#03a9f4',
                                        borderRadius: '10px',
                                        padding: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#fff',
                                        fontWeight: 700,
                                        fontSize: '2rem',
                                    }}
                                >
                                    {filteredLevel.data[1] || 'N/A'}
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Card>
            )}
        </Box>
    );
};

export default BestAverageLevel;
