import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks';

import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Snackbar,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import SyncIcon from '@mui/icons-material/Sync';

import {
    invalidate,
    fetchStudentReport,
    storeReports,
    selectStudentItemFetchStatusSelected,
    selectStudentItemOperationStatusSelected,
    selectStudentItemSelected,
} from '../../../store/slices/professorModule/studentItemsSlice';
import { selectItemSelected } from '../../../store/slices/professorModule/ItemsSlice';
import {
    selectAllCatalogReports,
    selectGroupById,
    selectStudentById,
    selectStudentFiles,
    selectSubjectById,
    selectUserById,
} from '../../../store/slices/professorModule/entitiesSlice';

import { Error } from '../../../components/Feedback';
import { ReportCard, SkeletonBlock } from './components';

/**
 * Pagina para reportar alumnos
 *
 * @returns
 */
const MakeReport = () => {
    ////////// HOOKS //////////

    const auth = useAuth();
    const userId = auth.getUserID();
    const dispatch = useDispatch();
    let { id, studentId } = useParams();

    studentId = parseInt(studentId);

    ///////////// SHARES STATE  ////////////

    const subjectItem = useSelector(selectItemSelected);
    const studentItem = useSelector(selectStudentItemSelected);

    const operationState = useSelector(
        selectStudentItemOperationStatusSelected
    );
    const serverStatus = useSelector(selectStudentItemFetchStatusSelected);

    const subject = useSelector(selectSubjectById(subjectItem.subject_id));

    const group = useSelector(selectGroupById(subject.group_id));
    const allCatalogSubjects = useSelector(selectAllCatalogReports);
    const assesor = useSelector(selectUserById(group.assessor_id));
    const files = useSelector((state) => selectStudentFiles(state, studentId));

    const student = useSelector(selectStudentById(studentItem?.student_id));

    /////////////////// LOCAL STATE //////////////

    const [reportsUI, setReportsUI] = useState([]);
    const [fileID, setFileID] = useState([]);
    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    ///////////////// ACTIONS /////////////////////

    /**
     * Recuperamos toda la informacion del alumno
     */
    useEffect(() => {
        reload();
    }, [id, subjectItem]);

    /**
     * Preparar los reportes
     */
    useEffect(() => {
        const inactiveFiles = files.filter((item) => item.status == 0);

        let repots = allCatalogSubjects.map((item) => ({
            ...item,
            ...{
                reportCheck: 0,
                asessor_id: assesor?.assessor_id,
                description: item.title,
                user_id: userId,
            },
        }));

        let currentFile = inactiveFiles.length > 0 ? inactiveFiles[0] : null;

        setReportsUI(repots);
        setFileID(currentFile?.file_id);
    }, [id, subjectItem, files]);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(studentId));
        reload();
    };

    /**
     * Recargar la informacion adicional
     */
    const reload = () => {
        dispatch(fetchStudentReport(studentId));
    };

    /**
     * Funcion para almacenar todos los reportes
     */
    const handleSaveReports = () => {
        const reports = reportsUI.filter((item) => item.reportCheck);

        dispatch(
            storeReports({
                studentId,
                assessorId: group.assessor_id,
                fileId: fileID,
                reports,
            })
        )
            .unwrap()
            .then((response) => {
                showFeedback('Guardado correctamente');
            })
            .catch(({ feedback }) => {
                showFeedback(feedback.title);
            });
    };

    /**
     * Cuando el reprote es selecionado
     *
     * @param {*} catalog_id
     * @param {*} value
     */
    const handleChangeReport = (catalog_id, value) => {
        const newRepots = reportsUI.map((item) => {
            if (item.catalog_report_id == catalog_id) {
                return { ...item, reportCheck: value };
            }
            return item;
        });
        setReportsUI(newRepots);
    };

    /**
     * Cuando el reprote es selecionado
     *
     * @param {*} catalog_id
     * @param {*} value
     */
    const handleChangeDescripctionReport = (catalog_id, description) => {
        const newRepots = reportsUI.map((item) => {
            if (item.catalog_report_id == catalog_id) {
                return { ...item, description: description };
            }
            return item;
        });
        setReportsUI(newRepots);
    };

    ///////////////////// FEEDBACK ////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message);
        setOpenFeedback(true);
    };

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {serverStatus == 'pending' && <SkeletonBlock />}

            {serverStatus == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {serverStatus == 'fulfilled' && (
                <>
                    <Box
                        sx={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="h4" component="div">
                            Reporte de disciplina
                        </Typography>

                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}
                            >
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>

                    {student && (
                        <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                            <CardContent>
                                <Stack
                                    spacing={2}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Stack
                                        direction="column"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Avatar
                                            alt="Estudiante"
                                            src={student.url_photo_profile}
                                            sx={{ width: 46, height: 46 }}
                                        />
                                        <Typography
                                            variant={'h6'}
                                            component="div"
                                        >
                                            {' '}
                                            {student.name} {student.last_name}{' '}
                                            {student.second_last_name}{' '}
                                        </Typography>
                                        <Box>Alumno</Box>
                                    </Stack>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography
                                            variant={'h6'}
                                            component="div"
                                        >
                                            {' '}
                                            {assesor.name} {assesor.last_name}{' '}
                                            {assesor.second_last_name}{' '}
                                        </Typography>
                                        <Typography component="div">
                                            {' '}
                                            Asesor académico{' '}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>
                    )}

                    {assesor && (
                        <>
                            <Card>
                                <CardHeader title="Reportes disponibles" />

                                <CardContent>
                                    <Stack direction={'column'} spacing={2}>
                                        {reportsUI.map((item) => {
                                            return (
                                                <ReportCard
                                                    key={item.catalog_report_id}
                                                    report={item}
                                                    disabled={
                                                        operationState ==
                                                        'pending'
                                                    }
                                                    onChange={
                                                        handleChangeReport
                                                    }
                                                    onChangeDescription={
                                                        handleChangeDescripctionReport
                                                    }
                                                />
                                            );
                                        })}
                                    </Stack>
                                </CardContent>
                                <CardActions
                                    sx={{
                                        direction: 'row',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                    }}
                                >
                                    <LoadingButton
                                        size="small"
                                        endIcon={<SendIcon />}
                                        loading={operationState == 'pending'}
                                        loadingPosition="end"
                                        variant="contained"
                                        onClick={handleSaveReports}
                                        disabled={
                                            reportsUI.filter(
                                                (item) => item.reportCheck
                                            ).length == 0
                                        }
                                    >
                                        Guardar
                                    </LoadingButton>
                                </CardActions>
                            </Card>
                        </>
                    )}

                    {!assesor && (
                        <Box
                            sx={{
                                height: 400,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h4" component="div">
                                {' '}
                                Grupo sin asesor{' '}
                            </Typography>
                        </Box>
                    )}
                </>
            )}

            <Snackbar
                open={openFeedback}
                autoHideDuration={2500}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Box>
    );
};

export default MakeReport;
