import { combineReducers } from '@reduxjs/toolkit';
import fetch from './fetchSlice';
import items from './itemsSlice';
import ui from './uiSlice';

export default combineReducers({
    fetch,
    items,
    ui,
});
