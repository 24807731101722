import { createSelector, createSlice } from '@reduxjs/toolkit';
import { selectedHomeworkNoticesUI } from './selectors';

const emptyState = {
    noticeSelected: null,
    filters: {},
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {},
    reducers: {
        noticeHomeworkSelected: (state, { payload }) => {
            state.noticeSelected = payload;
        },
        updateHomeworkFilters: (state, { payload }) => {
            const { filter } = payload;
            state.filters = { ...filter };
        },
    },

    extraReducers: (builder) => {
        builder.addCase('app/clear', () => {
            return emptyState;
        });
    },
});

export const { noticeHomeworkSelected, updateHomeworkFilters } =
    uiSlice.actions;

export default uiSlice.reducer;

/////////// SELECTORS ///////////

export const selectSelectedHomeworkNoticeUI = createSelector(
    selectedHomeworkNoticesUI,
    (ui) => {
        return ui.noticeSelected;
    }
);

export const selectHomeworkNoticesFilter = createSelector(
    selectedHomeworkNoticesUI,
    (ui) => {
        return ui.filters;
    }
);
