import React, { useEffect } from 'react'
import { Box, Stack, TextField, Snackbar, Card, CardContent, CardHeader, CardActions } from '@mui/material'
import { useState } from 'react'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useAuth } from '../../../hooks';
import SaveIcon from '@mui/icons-material/Save';
import { selectStatusOperation, updateUserPassword } from '../../../store/slices/auth';
import { LoadingButton } from '@mui/lab';
import useFeedback from '../../../hooks/useFeedback';

/**
 * Componente para cambiar de contraseña
 * 
 * @returns 
 */
const ChangePassword = () => {
    const dispatch = useDispatch()
    const Auth = useAuth()
    const feedbackApp = useFeedback()

    /////////////////// LOCAL STATE ///////////////////////////

    const [credentialState, updateCredentialState] = useState({
        password_old: '',
        password: '',
        password_confirmation: ''
    })

    const [state, setState] = useState('idle')

    ////////////////// SHARED STATE /////////////////

    const statusOperation = useSelector(selectStatusOperation)

    /**
     * Efecto para escuchar el estado de la operacion
     */
    useEffect(() => {
        if ((statusOperation == 'rejected' || statusOperation == 'fulfilled') && state == 'pending') {
            setState('idle')
        }
    }, [statusOperation])

    /**
     * Actualizacion de la contraseña
     * 
     * @param {*} event 
     */
    const onSubmitPassword = (event) => {
        setState('pending')
        dispatch(updateUserPassword({
            userId: Auth.getUserID(),
            credentialState
        }))
            .unwrap()
            .then(response => {
                feedbackApp.showFeedback({
                    title: "Actualizado correctamente"
                })

                updateCredentialState({
                    password_old: '',
                    password: '',
                    password_confirmation: ''
                })
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    /**
     * Actualizacion de los inputs de formulario de contraseña
     * 
     * @param {*} event 
     */
    function onInputPasswordChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        let oldState = {
            password_old: credentialState.password_old,
            password: credentialState.password,
            password_confirmation: credentialState.password_confirmation,
        }

        oldState[name] = value

        updateCredentialState(oldState);
    }

    /////////////////////////////////// VALIDACIONES DE LOS CAMPOS //////////////////////////////////////
    const oldPasswordvalidation = () => {
        return {
            invalid: credentialState.password_old == "",
            message: "Campo requerido"
        }
    }

    const passwordinValidation = () => {
        return {
            invalid: !(credentialState.password != "" && credentialState.password == credentialState.password_confirmation),
            message: "Campo requerido, los valores no coinciden"
        }
    }

    const formPasswordinValid = () => {
        return (oldPasswordvalidation().invalid || passwordinValidation().invalid)
    }

    return (
        <Box>
            <Card>
                <CardHeader
                    title="Cambiar contraseña"
                    titleTypographyProps={{
                        sx: {
                            fontSize: 20
                        }
                    }}
                />
                <CardContent>
                    <Stack direction="column" spacing={2}>
                        <TextField
                            id="old-password" name="password_old" type="password"
                            onChange={onInputPasswordChange} value={credentialState.password_old}
                            fullWidth
                            error={oldPasswordvalidation().invalid}
                            helperText={oldPasswordvalidation().invalid ? oldPasswordvalidation().message : false}
                            label="Contraseña actual" variant="outlined"
                        />
                        <TextField
                            id="new-password" name="password" type="password"
                            fullWidth
                            onChange={onInputPasswordChange} value={credentialState.password}
                            error={passwordinValidation().invalid}
                            helperText={passwordinValidation().invalid ? passwordinValidation().message : false}
                            label="Nueva contraseña"
                            variant="outlined"
                        />

                        <TextField
                            id="new-password" name="password_confirmation" type="password"
                            fullWidth
                            onChange={onInputPasswordChange}
                            value={credentialState.password_confirmation}
                            error={passwordinValidation().invalid}
                            helperText={passwordinValidation().invalid ? passwordinValidation().message : false}
                            label="Vuelva a escribir la nueva contraseña"
                            variant="outlined" />
                    </Stack>
                </CardContent>
                <CardActions
                    sx={{
                        flexDirection: "row",
                        justifyContent: "flex-end"
                    }}
                >
                    <LoadingButton
                        size="small"
                        color="primary"
                        onClick={onSubmitPassword}
                        loading={state == 'pending'}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        disabled={formPasswordinValid()}
                    >
                        Cambiar
                    </LoadingButton>
                </CardActions>
            </Card>
        </Box>
    )
}

export default ChangePassword