import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardActionArea, Stack, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getLevelName, getTurnLevel } from '../../../../libs/utils';
import {
    selectCatalogSubjectById,
    selectGroupById,
    selectSubjectById,
} from '../../../store/meGroups/home/entitiesSlice';
import { selectItem } from '../../../store/meGroups/home/uiSlice';

const theme = createTheme({
    palette: {
        neutral: {
            main: '#FFFFFF',
            contrastText: '#2894f4',
        },
    },
});

/**
 * Card que muestra un grupo
 *
 * @param {*} param0
 *
 * @returns
 */
const GroupCard = ({ id, sx }) => {
    ////////// HOOKS //////////

    const history = useHistory();
    const dispatch = useDispatch();
    const { path } = useRouteMatch();

    //////////// SHARES STATE /////////////////////

    const subject = useSelector(selectSubjectById(id));
    const catalogSubject = useSelector(
        selectCatalogSubjectById(subject.catalog_subject_id)
    );
    const group = useSelector(selectGroupById(subject.group_id));

    const levelName = getLevelName(group.level);
    const turnName = getTurnLevel(group.turn);

    ////////// LOCAL STATE //////////

    const [hovered, setHovered] = useState(false);

    /*
     * Manejo del hover de la carta activo
     */
    const handlerHover = () => {
        setHovered(true);
    };

    /*
     * Manejo del hover de la carta inactivo
     */
    const handlerUnhover = () => {
        setHovered(false);
    };

    /*
     * Función que reedirecciona
     * a las vista de asistencias
     */
    const handlerAssistensList = () => {
        //dispatch(selectItem(id));
        history.push({
            pathname: `${path}/lista-asistencia/${subject.subject_id}`,
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#2894f4',
                    color: 'white',
                    width: 300,
                    '&:hover': {
                        boxShadow: hovered
                            ? '0px 4px 20px rgba(0, 0, 0, 0.2)'
                            : 'none',
                    },
                }}
            >
                <CardActionArea
                    onMouseEnter={handlerHover}
                    onMouseLeave={handlerUnhover}
                    onClick={handlerAssistensList}
                >
                    <Stack
                        sx={{
                            padding: 2,
                            flexGrow: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <Stack spacing={1}>
                            <Typography variant="h6" style={{ color: 'white' }}>
                                {catalogSubject.title}
                            </Typography>
                            <Typography variant="body2">
                                {group.grade}°{group.group} {turnName}{' '}
                                {levelName}
                            </Typography>
                        </Stack>

                        <Stack>
                            <Typography variant="h3" style={{ color: 'white' }}>
                                {subject.average_rating}
                            </Typography>
                            <Typography variant="body2">Promedio</Typography>
                        </Stack>
                    </Stack>
                </CardActionArea>
            </Card>
        </ThemeProvider>
    );
};

export default GroupCard;
