import { createSlice } from '@reduxjs/toolkit';
import { addAsyncCase, initialStateHome } from '../commonState';
import {
    getBestScoreStudents,
    getAssistanceIndex,
    getAverageImcSchool,
    getBestScoreSubject,
    getFrequentReports,
    getGradeAverage,
    getLastAttendanceProfessor,
    getSchoolAverage,
    getStudentsApprovedFailedGenderGrade,
    getStudentsApprovedGender,
    getStudentsDiversity,
    getStudentsFailedGender,
    getTotalAbscence,
    getTotalGroups,
    getTotalIncidents,
    getTotalMaleFemaleStudentRegisterGrade,
    getTotalMaleFemaleStudents,
    getTotalMaleFemaleStudentsRegister,
    getTotalMaleFemaleStudentsWithdrawn,
    getTotalMaleFemaleStudentsWithdrawnGrade,
    getTotalProfessors,
    getTotalRegisteredParents,
    getTotalReports,
    getTotalScholarship,
    getTotalStudents,
    getTotalStudentsApprovedFailed,
    getTotalStudentsRepeated,
    getTotalStudentWithoutGroup,
    getWorstScoreStudent,
    getAllIncidents,
} from './thunks';

//////////////////////////////////////////////////////// fetching ////////////////////////////////////////////////////////

export const fetchSlice = createSlice({
    name: 'components',
    initialState: initialStateHome,
    reducers: {
        invalidate: (state) => {
            if (state) {
                Object.keys(state).forEach((kpiName) => {
                    if (state[kpiName] && state[kpiName].fetch) {
                        state[kpiName].fetch.didInvalidate = true;
                    }
                });
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return initialStateHome;
        });

        addAsyncCase(builder, getBestScoreStudents, 'best_score_students');
        addAsyncCase(builder, getAssistanceIndex, 'assistance_index');
        addAsyncCase(builder, getAverageImcSchool, 'average_imc_school');
        addAsyncCase(builder, getBestScoreSubject, 'best_score_subject');
        addAsyncCase(builder, getFrequentReports, 'frequent_reports');
        addAsyncCase(builder, getGradeAverage, 'grade_average');
        addAsyncCase(builder, getTotalStudents, 'total_students');
        addAsyncCase(builder, getTotalProfessors, 'total_professors');
        addAsyncCase(builder, getSchoolAverage, 'school_average');
        addAsyncCase(builder, getTotalAbscence, 'total_abscence');
        addAsyncCase(builder, getTotalStudentWithoutGroup, 'total_students_without_group');
        addAsyncCase(builder, getTotalReports, 'total_reports');
        addAsyncCase(builder, getTotalScholarship, 'total_scholarship');
        addAsyncCase(builder, getTotalRegisteredParents, 'total_registered_parents');
        addAsyncCase(builder, getTotalIncidents, 'total_incidents');
        addAsyncCase(builder, getLastAttendanceProfessor, 'last_attendance_professor');
        addAsyncCase(builder, getTotalMaleFemaleStudents, 'total_male_female_students');
        addAsyncCase(builder, getStudentsDiversity, 'students_diversity');
        addAsyncCase(builder, getTotalStudentsApprovedFailed, 'total_students_approved_failed');
        addAsyncCase(builder, getTotalGroups, 'total_groups');
        addAsyncCase(builder, getTotalMaleFemaleStudentsRegister, 'total_male_female_students_register');
        addAsyncCase(builder, getTotalMaleFemaleStudentsWithdrawn, 'total_male_female_students_withdrawn');
        addAsyncCase(builder, getStudentsApprovedGender, 'students_approved_gender');
        addAsyncCase(builder, getStudentsFailedGender, 'students_failed_gender');
        addAsyncCase(builder, getTotalMaleFemaleStudentRegisterGrade, 'total_male_female_student_register_grade');
        addAsyncCase(builder, getTotalMaleFemaleStudentsWithdrawnGrade, 'total_male_female_students_withdrawn_grade');
        addAsyncCase(builder, getStudentsApprovedFailedGenderGrade, 'students_approved_failed_gender_grade');
        addAsyncCase(builder, getTotalStudentsRepeated, 'total_students_repeated');
        addAsyncCase(builder, getWorstScoreStudent, 'worst_score_student');
        addAsyncCase(builder, getAllIncidents, 'incidents');
    },
});

export const { invalidate } = fetchSlice.actions;

export const getLoading = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getDataStatus = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getFetchStatus = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getCycleSelected = (state) =>
    state.moduleDirector.home.ui.cycleSelected;
export const getCurrentTab = (state) => state.moduleDirector.home.ui.currentTab;

export default fetchSlice.reducer;

//////////////////////////////////////////////////////// SELECTORS ////////////////////////////////////////////////////////

export const selectBestScoreStudents = (state) => {
    return state.moduleDirector.home.components.best_score_students.data;
};
export const selectWorstScoreStudent = (state) => {
    return state.moduleDirector.home.components.worst_score_student.data;
};
export const selectAssistanceIndex = (state) => {
    return state.moduleDirector.home.components.assistance_index.data;
};
export const selectAverageImcSchool = (state) => {
    return state.moduleDirector.home.components.average_imc_school.data;
};
export const selectBestScoreSubject = (state) => {
    return state.moduleDirector.home.components.best_score_subject.data;
};
export const selectFrequentReports = (state) => {
    return state.moduleDirector.home.components.frequent_reports.data;
};
export const selectGradeAverage = (state) => {
    return state.moduleDirector.home.components.grade_average.data;
};
export const selectTotalStudents = (state) => {
    return state.moduleDirector.home.components.total_students.data;
};

export const selectLastAttendanceProfessor = (state) => {
    return state.moduleDirector.home.components.last_attendance_professor.data;
};

export const selectSchoolAverage = (state) => {
    return state.moduleDirector.home.components.school_average.data;
};

export const selectTotalAbscence = (state) => {
    return state.moduleDirector.home.components.total_abscence.data;
};

export const selectStudentsDiversity = (state) => {
    return state.moduleDirector.home.components.students_diversity.data;
};

export const selectTotalMaleFemaleStudents = (state) => {
    return state.moduleDirector.home.components.total_male_female_students.data;
};

export const selectTotalMaleFemaleStudentsWithdrawn = (state) => {
    return state.moduleDirector.home.components
        .total_male_female_students_withdrawn.data;
};

export const selectTotalGroups = (state) => {
    return state.moduleDirector.home.components.total_groups.data;
};

export const selectTotalRegisteredParents = (state) => {
    return state.moduleDirector.home.components.total_registered_parents.data;
};

export const selectTotalProfessors = (state) => {
    return state.moduleDirector.home.components.total_professors.data;
};

export const selectTotalReports = (state) => {
    return state.moduleDirector.home.components.total_reports.data;
};

export const selectTotalScholarship = (state) => {
    return state.moduleDirector.home.components.total_scholarship.data;
};

export const selectTotalStudentsApprovedFailed = (state) => {
    return state.moduleDirector.home.components.total_students_approved_failed
        .data;
};

export const selectTotalMaleFemaleStudentsRegister = (state) => {
    return state.moduleDirector.home.components
        .total_male_female_students_register.data;
};

export const selectTotalMaleFemaleStudentRegisterGrade = (state) => {
    return state.moduleDirector.home.components
        .total_male_female_student_register_grade.data;
};

export const selectTotalMaleFemaleStudentsWithdrawnGrade = (state) => {
    return state.moduleDirector.home.components
        .total_male_female_students_withdrawn_grade.data;
};

export const selectStudentsApprovedGender = (state) => {
    return state.moduleDirector.home.components.students_approved_gender.data;
};

export const selectStudentsFailedGender = (state) => {
    return state.moduleDirector.home.components.students_failed_gender.data;
};

export const selectStudentsApprovedFailedGenderGrade = (state) => {
    return state.moduleDirector.home.components
        .students_approved_failed_gender_grade.data;
};

export const selectTotalStudentsRepeated = (state) => {
    return state.moduleDirector.home.components.total_students_repeated.data;
};

export const selectTotalStudentWithoutGroup = (state) => {
    return state.moduleDirector.home.components.total_students_without_group
        .data;
};

export const selectTotalIncidents = (state) => {
    return state.moduleDirector.home.components.total_incidents.data;
};
export const selectAllIncidents = (state) => {
    return state.moduleDirector.home.components.incidents.data;
};

export const selectDirectorHome = (store) => store.moduleDirector.home;
