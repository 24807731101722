import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Paper,
    Tooltip,
} from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectTotalMaleFemaleStudentsRegister,
} from '../../../store/home/fetchSlice';
import { getTotalMaleFemaleStudentsRegister } from '../../../store/home/thunks';
import { ResponsivePie } from '@nivo/pie';
import {
    SkeletongGrafics,
    SkeletonLongCard,
} from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeTotalMaleFemaleStudentsRegisterStatusServer } from '../../../store/home/componentStatusSelectors';

const TotalMaleFemaleStudentsRegister = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeTotalMaleFemaleStudentsRegisterStatusServer
    );

    const totalStudentsRegister = useFetchResource(
        () =>
            getTotalMaleFemaleStudentsRegister({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectTotalMaleFemaleStudentsRegister,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getTotalMaleFemaleStudentsRegister({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectTotalMaleFemaleStudentsRegister,
            [cycleSelected, schoolId]
        );
    };

    const processStudentData = (data) => {
        if (!data || !data.value || !Array.isArray(data.value.data)) {
            return [];
        }

        return [
            { id: 'Hombres', value: data.value.data[0] },
            { id: 'Mujeres', value: data.value.data[1] },
        ];
    };

    const processedData = processStudentData(totalStudentsRegister);

    const colorScheme = {
        Hombres: '#1E90FF',
        Mujeres: '#f47edd',
    };

    return (
        <Box>
            {fetchingStatus === 'pending' && <SkeletonLongCard items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        margin: 'auto',
                    }}
                >
                    <CardContent
                        sx={{
                            padding: '16px',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            {totalStudentsRegister?.title ||
                                'Alumnos registrados por género'}
                        </Typography>
                        {processedData.length > 0 ? (
                            <Box height={300}>
                                <ResponsivePie
                                    data={processedData}
                                    margin={{
                                        top: 20,
                                        right: 5,
                                        bottom: 60,
                                        left: 5,
                                    }}
                                    innerRadius={0.5}
                                    padAngle={0.7}
                                    cornerRadius={10}
                                    colors={({ id }) =>
                                        colorScheme[id] || '#ccc'
                                    }
                                    borderWidth={0}
                                    borderColor={{
                                        from: 'color',
                                        modifiers: [['darker', 0.2]],
                                    }}
                                    enableRadialLabels={false}
                                    enableSlicesLabels={true}
                                    enableArcLinkLabels={false}
                                    sliceLabel={({ id, value }) =>
                                        `${id}: ${value}`
                                    }
                                    theme={{
                                        labels: {
                                            text: {
                                                fontSize: 16,
                                                fill: 'white',
                                            },
                                        },
                                        tooltip: {
                                            container: {
                                                background: 'white',
                                                color: 'inherit',
                                                fontSize: 12,
                                                borderRadius: '2px',
                                                boxShadow:
                                                    '0 1px 2px rgba(0, 0, 0, 0.25)',
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        ) : (
                            <Paper
                                elevation={3}
                                sx={{
                                    marginTop: 2,
                                    padding: 2,
                                    textAlign: 'center',
                                }}
                            >
                                <Typography variant="h5" gutterBottom>
                                    Sin datos disponibles
                                </Typography>
                            </Paper>
                        )}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default TotalMaleFemaleStudentsRegister;
