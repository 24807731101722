import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Services from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import { PROFESSORS_SUBJECT_EXPIRE_TIME } from '../../../service/const';

import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import { selectReportGroup } from './selectors';
import { selectSelectedGroup } from '../home/uiSlice';
import { addStudentsViewModels } from '../student';

import {
    selectStudents,
    upsertManyStudents,
} from '../../../store/slices/entities/students';
import { upsertManyFiles } from '../../../store/slices/entities/files';
import { selectFilesByGroup } from '../../../store/slices/entities/selectors';

import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';

const emptyState = [];

export const group = createSlice({
    name: 'group',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, { payload }) => {
            state[payload].fetch.didInvalidate = true;
        },
        addItem: (state, action) => {
            return {
                ...state,
                ...action.payload.reduce((accumulator, item) => {
                    accumulator[item.id] = item;
                    return accumulator;
                }, {}),
            };
        },
        studentSelected: (state, { payload }) => {
            const { studentUUID, groupUUID } = payload;

            state[groupUUID].ui.studentSelected = studentUUID;
        },
        setAllGroupsItems: (state, { payload }) => {
            const { groupUUID, studentsIds } = payload;

            state[groupUUID].items = studentsIds;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        // /**
        //  * Recuperar informacion adicional de la materia
        //  */
        builder.addCase(
            loadReportsStudentsUI.fulfilled,
            (state, { payload }) => {
                const { groupUUID } = payload;

                state[groupUUID].fetch.expireIn = new Date().setMinutes(
                    new Date().getMinutes() + PROFESSORS_SUBJECT_EXPIRE_TIME
                );
                state[groupUUID].fetch.status = 'fulfilled';
                state[groupUUID].fetch.fetchingAt = Date.now();
                state[groupUUID].fetch.didInvalidate = false;
            }
        );
        builder.addCase(loadReportsStudentsUI.pending, (state, { meta }) => {
            const { groupUUID } = meta;

            state[groupUUID].fetch.status = 'pending';
        });
        builder.addCase(loadReportsStudentsUI.rejected, (state, action) => {
            if (action.payload) {
                const { groupUUID } = action.payload;
                //state[groupId].servers.details.feedback = feedback

                state[groupUUID].fetch.status = 'rejected';
            }
        });
    },
});

export const { invalidate, addItem, setAllGroupsItems, studentSelected } =
    group.actions;

//////// SELECTORS /////////

/**
 * @param {*} state
 * seleciiona los viewModels del slice de group
 */
export const selectGroupsViewModels = (state) => state.group;

/**
 * @param {*} store
 * Selecciona el status server del grupo seleccionado
 */
export const selectStatusServer = createSelector(
    selectReportGroup,
    selectSelectedGroup,
    (reportGroup, groupSelected) => {
        return reportGroup[groupSelected].fetch.status;
    }
);

/**
 *
 * @param {*} store
 * selecciona los items entities de grupos
 * en reportes
 */

export const selectAllStudentsItemsEntities = createSelector(
    selectReportGroup,
    selectSelectedGroup,
    (reportGroup, groupSelected) => {
        return reportGroup[groupSelected].items;
    }
);
/**
 *
 * @param {*} state
 * selecciona el slice de student en reportes
 */
export const selectStudentUI = (state) => state.newReports.student;

/**
 * Selecciona los datos para mostrar en la página de grupos
 * en reportes
 */
export const selectAllStudentsItems = (groupId) =>
    createSelector(
        selectAllStudentsItemsEntities,
        selectStudents,
        selectStudentUI,
        selectFilesByGroup(groupId),
        (studentsVMIdEntities, studentsEntities, viewModels, files) => {
            return studentsVMIdEntities.map((i) => {
                let currentViewModel = viewModels[i];
                let filterFiles = files.filter(
                    (file) => file.student_id === currentViewModel.ui.student_id
                );
                let totalReports = filterFiles.reduce(
                    (total, file) => total + file.total_reports,
                    0
                );

                return {
                    ...studentsEntities[currentViewModel.ui.student_id],
                    viewModel: currentViewModel,
                    total_reports: totalReports,
                    total_files: filterFiles.length,
                };
            });
        }
    );

/////////// THUNKS ///////////

export const loadReportsStudentsUI = createAsyncThunk(
    'newReports/group/fetch/data',
    async ({ groupId, schoolId }, thunkAPI) => {
        let FeedbackService = new Feedback();
        const state = thunkAPI.getState();
        const groupUUID = state.newReports.home.ui.groupSelected;

        try {
            let studentsData = [];
            let files = await Services.getFilesByGroup(groupId).then(
                (i) => i.data.data
            );

            let studentsLines = _.chunk(_.uniq(_.map(files, 'student_id')), 30);

            for (let i = 0; i < studentsLines.length; i++) {
                studentsData = await Services.getStudentsBySchool(
                    schoolId,
                    studentsLines[i]
                ).then((i) => i.data.data);
            }

            let viewModels = studentsData.map((student) => {
                return {
                    id: uuidv4(),
                    ui: {
                        student_id: student.student_id,
                    },
                    fetch: {
                        expireIn: null,
                        ferchingAt: null,
                        status: 'pending',
                        didInvalidate: true,
                    },
                    items: [],
                };
            });
            let studentsIds = viewModels.map(({ id }) => {
                return id;
            });
            thunkAPI.dispatch(upsertManyStudents(studentsData));
            thunkAPI.dispatch(upsertManyFiles(files));
            thunkAPI.dispatch(addStudentsViewModels(viewModels));
            thunkAPI.dispatch(setAllGroupsItems({ studentsIds, groupUUID }));
            return {
                studentsData,
                groupUUID,
            };
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue({
                groupUUID,
                feedback: FeedbackService.getMessage(error),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            const state = getState();

            const groupUUID = state.newReports.home.ui.groupSelected;

            let { didInvalidate, expireIn } =
                state.newReports.group[groupUUID].fetch;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
        getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
            return {
                groupUUID: getState().newReports.home.ui.groupSelected,
            };
        },
    }
);

export default group.reducer;
