import React, { useEffect, useState } from 'react';
import {
    Button,
    Switch,
    TextField,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Tooltip,
    IconButton,
    Snackbar,
} from '@mui/material';
import ModalUsuario from '../../components/Modal/ModalUsuarioAgregar';
import ModalUsuarioPassword from '../../components/Modal/ModalUsuarioPassword';
import {
    DataGrid,
    esES,
    GridActionsCellItem,
    GridToolbar,
} from '@mui/x-data-grid';

import { useDispatch, useSelector } from 'react-redux';
import {
    selectSpokeman,
    selectSpokemanBySchool,
} from '../../store/slices/entities/users';
import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { disableUser } from '../../store/slices/usersUI';
import ModalUsuarioCelular from '../../components/Modal/ModalUsuarioCelular';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../../hooks';
import { useHistory } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import GridCellExpand from '../../components/CellContent';
import useFeedback from '../../hooks/useFeedback';

/**
 * Paginade voseros
 *
 * @returns
 */
const UsuariosVocero = () => {
    const dispatch = useDispatch();
    const Auth = useAuth();
    const history = useHistory();
    const feedbackApp = useFeedback();

    ////////////////// SHARED STATE ///////////////
    const voceros = useSelector(
        selectSpokemanBySchool(Auth.getUser().school_id)
    );

    ////////////////// LOCAL STATE ////////////////

    const [openModal, setOpenModal] = useState(false);
    const [openModalPassword, setOpenModalPassword] = useState(false);
    const [usuarioSelected, setUsuarioSelected] = useState([]);
    const [openModalCellphone, setOpenModalCellphone] = useState(false);

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const FullId = params.row.id || ' ';
                return (
                    <div
                        style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
                    >
                        {FullId}
                    </div>
                );
            },
        },

        {
            field: 'enrollment',
            headerName: 'Matrícula',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const FullEnrollment = params.row.enrollment || ' ';
                return (
                    <div
                        style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
                    >
                        {FullEnrollment}
                    </div>
                );
            },
        },
        {
            field: 'nombre',
            headerName: 'Nombre',
            flex: 1,
            minWidth: 250,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const FullName = `${params.row.name || ''} ${
                    params.row.last_name || ''
                } ${params.row.second_last_name || ''}`;
                return (
                    <div
                        style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
                    >
                        {FullName}
                    </div>
                );
            },
        },
        {
            field: 'cellphone',
            headerName: 'Teléfono',
            flex: 0.5,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const FullCellphone = params.row.cellphone || ' ';
                return (
                    <div
                        style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
                    >
                        {FullCellphone}
                    </div>
                );
            },
        },
        {
            field: 'email',
            headerName: 'Correo',
            flex: 1,
            minWidth: 250,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const FullEmail = params.row.email || ' ';
                const emailParts = FullEmail.split('@');
                return (
                    <div
                        style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
                    >
                        {emailParts.length > 1 ? (
                            <>
                                {emailParts[0]}@<br />
                                {emailParts.slice(1).join('@')}
                            </>
                        ) : (
                            FullEmail
                        )}
                    </div>
                );
            },
        },
        {
            field: 'domicilio',
            headerName: 'Domicilio',
            flex: 1,
            minWidth: 250,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.colony || ''} ${params.row.street || ''} ${
                    params.row.outside_number || ''
                } C.P. ${params.row.zipcode || ''}`;
            },
            renderCell: function renderCellExpand(params) {
                return (
                    <GridCellExpand
                        value={params.value || ''}
                        width={params.colDef.computedWidth}
                    />
                );
            },
        },
        {
            field: 'status',
            headerName: 'Activo',
            type: 'singleSelect',
            flex: 0.3,
            minWidth: 50,
            headerAlign: 'center',
            align: 'center',
            valueOptions: ['Activo', 'Inactivo'],
            valueGetter: (params) => {
                return params.value == 1 ? 'Activo' : 'Inactivo';
            },
            renderCell: (params) => {
                return (
                    <Tooltip title="Desactivar/Activar usuario">
                        <Switch
                            checked={params.row.status === 1 ? true : false}
                            onChange={(event) => {
                                setUsuarioSelected(params.row);
                                cambiarStatus(params.row, event.target.value);
                            }}
                            value={params.row.status}
                            disabled={
                                Auth.getUserID(params.row) == Auth.getUserID()
                            }
                        />
                    </Tooltip>
                );
            },
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            sortable: false,
            flex: 0.4,
            minWidth: 50,
            headerAlign: 'center',
            align: 'center',
            type: 'actions',
            getActions: (params) => {
                if (Auth.getUserID(params.row) == Auth.getUserID()) {
                    return [
                        <GridActionsCellItem
                            icon={<AccountBoxIcon color="primary" />}
                            title="Perfil"
                            label="Perfil"
                            onClick={(event) => {
                                history.push({ pathname: `/perfil` });
                            }}
                            showInMenu
                        />,
                    ];
                }

                const actions = [
                    <GridActionsCellItem
                        icon={<EditIcon color="primary" />}
                        title="Editar"
                        label="Editar"
                        onClick={() => {
                            setUsuarioSelected(params.row);
                            setOpenModal(true);
                        }}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<VpnKeyIcon color="primary" />}
                        title="Cambiar contraseña"
                        label="Cambiar contraseña"
                        onClick={() => {
                            setUsuarioSelected(params.row);
                            setOpenModalPassword(true);
                        }}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<PhoneAndroidIcon color="primary" />}
                        title="Cambiar teléfono celular"
                        label="Cambiar teléfono celular"
                        onClick={() => {
                            setUsuarioSelected(params.row);
                            setOpenModalCellphone(true);
                        }}
                        showInMenu
                    />,
                ];

                return actions;
            },
        },
    ];

    /**
     * Cambiar el status de un usuario
     *
     * @param {*} info
     * @param {*} status
     */
    const cambiarStatus = async (info, status) => {
        let statusFinal = status === '1' ? 0 : 1;
        let data = {
            status: statusFinal,
        };

        dispatch(
            disableUser({
                user: info,
                userId: info.user_id,
                data,
            })
        )
            .unwrap()
            .then((response) => {
                feedbackApp.showFeedback({
                    title: 'Usuario actualizado',
                });
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const dataGrid = voceros?.map((row) => ({ id: row.user_id, ...row }));

    ////////////////////// VIEW //////////////////////////

    return (
        <>
            <ModalUsuario
                title="Vocero"
                openModal={openModal}
                setOpenModal={setOpenModal}
                user={usuarioSelected}
                tipoUsuario="SPOKESMAN"
            />

            <ModalUsuarioPassword
                openModalPassword={openModalPassword}
                setOpenModalPassword={setOpenModalPassword}
                user={usuarioSelected}
            />

            <ModalUsuarioCelular
                openModal={openModalCellphone}
                setOpenModal={setOpenModalCellphone}
                user={usuarioSelected}
            />

            <Card sx={{ mt: 5 }}>
                <CardContent
                    sx={{
                        pt: 0,
                        pl: 0,
                        pr: 0,
                        pb: 0,
                        ' & .MuiDataGrid-root': {
                            border: 'none',
                        },
                        ' & .MuiDataGrid-toolbarContainer': {
                            justifyContent: 'flex-end',
                        },
                    }}
                >
                    <DataGrid
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={dataGrid}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        autoHeight
                        components={{ Toolbar: GridToolbar }}
                        disableDensitySelector
                    />
                </CardContent>
                <CardActions
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        size="small"
                        startIcon={<AddIcon />}
                        variant="contained"
                        onClick={() => {
                            setUsuarioSelected(null);
                            setOpenModal(true);
                        }}
                    >
                        Agregar
                    </Button>
                </CardActions>
            </Card>
        </>
    );
};

export default UsuariosVocero;
