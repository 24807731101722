import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField,
    Typography,
    Grid,
    Snackbar,
    Button,
    FormGroup,
    Checkbox,
    Chip,
    FormHelperText,
    Divider,
} from '@mui/material';
import { Box } from '@mui/system';

import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../hooks';
import { selectSettingBySchoolId } from '../../store/slices/entities/settings';
import {
    createUser,
    selectStatusOperation,
    updateUser,
} from '../../store/slices/usersUI';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import useFeedback from '../../hooks/useFeedback';

/**
 * Modal para crear y actualizar usuarios
 *
 * @param {*} param0
 *
 * @returns
 */
const ModalUsuarioAgregar = ({
    title = 'Agregar Usuario',
    openModal,
    setOpenModal,
    tipoUsuario,
    user = null,
}) => {
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpenModal(false);
    };

    //////////// SELECTORES /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(selectStatusOperation);

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    const [userData, setUserData] = useState({
        name: '',
        last_name: '',
        second_last_name: '',
        password: '',
        password_confirmation: '',
        enrollment: null,
        email: '',
        cellphone: '',
        street: '',
        colony: '',
        outside_number: '',
        inside_number: '',
        zipcode: '',
        country_code: '12',
        group_id: '',
        school_id: null,
    });

    const [permissions, setPermissions] = useState({
        ADMINISTER: {
            name: 'ADMINISTER',
            label: 'Administrador',
            position: 1,
            value: false,
        },
        PROFESSOR: {
            name: 'PROFESSOR',
            label: 'Profesor',
            position: 2,
            value: false,
        },
        ASSESSOR: {
            name: 'ASSESSOR',
            label: 'Orientador',
            position: 3,
            value: false,
        },
        PARENT: {
            name: 'PARENT',
            label: 'Padre',
            position: 4,
            value: false,
        },
        SPOKESMAN: {
            name: 'SPOKESMAN',
            label: 'Vosero',
            position: 5,
            value: false,
        },
        DIRECTOR: {
            name: 'DIRECTOR',
            label: 'Director',
            position: 6,
            value: false,
        },
        ADMINISTRATIVE: {
            name: 'ADMINISTRATIVE',
            label: 'Administrativo',
            position: 7,
            value: false,
        },
        TUTOR: {
            name: 'TUTOR',
            label: 'Tutor',
            position: 8,
            value: false,
        },
    });

    /**
     * Funcion para reiniciar los valores del modal
     */
    const resetUserData = () => {
        setUserData({
            name: '',
            last_name: '',
            second_last_name: '',
            password: '',
            password_confirmation: '',
            enrollment: null,
            email: '',
            cellphone: '',
            street: '',
            colony: '',
            outside_number: '',
            inside_number: '',
            zipcode: '',
            country_code: '12',
            group_id: '',
        });
    };

    /**
     * Guardar datos del usuario
     */
    const enviarDatos = () => {
        if (userData.hasOwnProperty('user_id')) {
            let currentPermissons = getPermissionsName(user.group_id);

            let newUnorderPermisions = Object.values(permissions);

            let newPermiss = newUnorderPermisions
                .filter((i) => i.value)
                .map((i) => {
                    return i.name;
                });

            let deletePermissons = newUnorderPermisions
                .filter((i) => !i.value)
                .filter((newP) => {
                    return currentPermissons.find(
                        (currP) => currP.name == newP.name
                    );
                })
                .map((i) => {
                    return i.name;
                });

            let permissionsData = {
                group_id: newPermiss.join(','),
            };

            if (deletePermissons.length > 0) {
                permissionsData['remove_group_id'] = deletePermissons.join(',');
            }

            dispatch(
                updateUser({
                    type: tipoUsuario,
                    userId: userData.user_id,
                    userData,
                    permissions: permissionsData,
                })
            )
                .unwrap()
                .then((response) => {
                    feedbackApp.showFeedback({
                        title: 'Usuario actualizado',
                    });
                    setOpenModal(false);
                })
                .catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title,
                    });
                });
        } else {
            dispatch(
                createUser({
                    type: tipoUsuario,
                    schoolId: schoolId,
                    userData,
                })
            )
                .unwrap()
                .then((response) => {
                    feedbackApp.showFeedback({
                        title: 'Usuario creado',
                    });
                    resetUserData();
                    setOpenModal(false);
                })
                .catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title,
                    });
                });
        }
    };

    useEffect(() => {
        const formatData = async () => {
            if (!openModal) {
                resetUserData();
                setPermissions({
                    ADMINISTER: {
                        name: 'ADMINISTER',
                        label: 'Administrador',
                        position: 1,
                        value: false,
                    },
                    PROFESSOR: {
                        name: 'PROFESSOR',
                        label: 'Profesor',
                        position: 2,
                        value: false,
                    },
                    ASSESSOR: {
                        name: 'ASSESSOR',
                        label: 'Orientador',
                        position: 3,
                        value: false,
                    },
                    PARENT: {
                        name: 'PARENT',
                        label: 'Padre',
                        position: 4,
                        value: false,
                    },
                    SPOKESMAN: {
                        name: 'SPOKESMAN',
                        label: 'Vosero',
                        position: 5,
                        value: false,
                    },
                    DIRECTOR: {
                        name: 'DIRECTOR',
                        label: 'Director',
                        position: 6,
                        value: false,
                    },
                    ADMINISTRATIVE: {
                        name: 'ADMINISTRATIVE',
                        label: 'Administrativo',
                        position: 7,
                        value: false,
                    },
                    TUTOR: {
                        name: 'TUTOR',
                        label: 'Tutor',
                        position: 8,
                        value: false,
                    },
                });
            } else {
                decodePermissions(user?.group_id);
                setUserData({ ...userData, ...user });
            }
        };

        formatData();
    }, [openModal]);

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     *
     * @param {*} event
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setUserData({ ...userData, [name]: value });
    };

    ////////////////////////// VALIDACIONES //////////////////////////////////

    const nameValid = () => {
        return {
            invalid: userData.name == '' || userData.name.length > 50,
            message: 'Campo requerido, Máximo 50 caracteres',
        };
    };

    const lastNameValid = () => {
        return {
            invalid: userData.last_name == '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const secondLastNameValid = () => {
        return {
            invalid: false, //userData.second_last_name == '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const enrollmentValid = () => {
        return {
            invalid: false,
            message: 'Campo requerido o incorrecto',
        };
    };

    /**
     * Validacion para el correo
     *
     * @returns
     */
    const emailValid = () => {
        var mailformat =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const isEmail = mailformat.test(userData.email);

        return {
            invalid: !isEmail && userData.email != '' && userData.email != null,
            message: 'Campo requerido o incorrecto',
        };
    };

    /**
     * Validacion para el numero de telefono
     *
     * @returns
     */
    const cellphoneValid = () => {
        const isPhone = /^[0-9]{10}$/.test(userData.cellphone);

        return {
            invalid: !isPhone,
            message: 'Campo requerido o incorrecto',
        };
    };

    const streetValid = () => {
        return {
            invalid: userData.street == '',
            message: 'Campo requerido o incorrecto',
        };
    };
    const colonyValid = () => {
        return {
            invalid: userData.colony == '',
            message: 'Campo requerido o incorrecto',
        };
    };
    const outsideNumberValid = () => {
        return {
            invalid: userData.outside_number == '',
            message: 'Campo requerido o incorrecto',
        };
    };
    const insideNumberValid = () => {
        return {
            invalid: false, //userData.inside_number == '',
            message: 'Campo requerido o incorrecto',
        };
    };
    const zipcodeValid = () => {
        return {
            invalid: userData.zipcode == '',
            message: 'Campo requerido o incorrecto',
        };
    };
    const countryCodeValid = () => {
        return {
            invalid: userData.country_code == '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const passwordValid = () => {
        return {
            invalid:
                !userData.hasOwnProperty('user_id') &&
                (userData.password == '' || userData.password.length < 6),
            message:
                'Campo requerido, La contraseña debe tener una longitud de 6 caracteres',
        };
    };

    const passwordConfirmationValid = () => {
        let isInvalid =
            !userData.hasOwnProperty('user_id') &&
            (userData.password_confirmation == '' ||
                userData.password != userData.password_confirmation ||
                userData.password_confirmation.length < 6);

        return {
            invalid: isInvalid,
            message:
                'Campo requerido, La contraseña debe tener una longitud de 6 caracteres',
        };
    };

    /**
     * Validacion para los los permisos del usuario
     *
     * @returns
     */
    const permisionsValid = () => {
        let newPermissons = Object.values(permissions).filter((i) => i.value);

        return {
            invalid:
                userData.hasOwnProperty('user_id') && newPermissons.length == 0,
            message: 'El usuario almenos tiene que contar un permiso',
        };
    };

    /**
     * Validacion global para el formulario
     *
     * @returns
     */
    const formInvalid = () => {
        return (
            nameValid().invalid ||
            lastNameValid().invalid ||
            secondLastNameValid().invalid ||
            enrollmentValid().invalid ||
            emailValid().invalid ||
            cellphoneValid().invalid ||
            streetValid().invalid ||
            colonyValid().invalid ||
            outsideNumberValid().invalid ||
            insideNumberValid().invalid ||
            zipcodeValid().invalid ||
            countryCodeValid().invalid ||
            passwordValid().invalid ||
            passwordConfirmationValid().invalid ||
            permisionsValid().invalid
        );
    };

    ////////////////////// VIEW //////////////////////////

    const onChangePermission = (event) => {
        const value = event.target.checked;
        const name = event.target.name;

        console.log(value + ' ' + name);

        setPermissions({
            ...permissions,
            [name]: {
                ...permissions[name],
                value,
            },
        });
    };

    /**
     *
     * @param {*} cadena
     * @returns
     */
    const decodePermissions = async (cadena) => {
        if (cadena == undefined || cadena == null) {
            return;
        }

        let array = cadena + '';

        let permissionsCollect = {};

        for (let i = 0; i < 8; i++) {
            switch (i) {
                case 0:
                    if (array[i] === '1') {
                        permissionsCollect['ADMINISTER'] = {
                            ...permissions['ADMINISTER'],
                            value: true,
                        };
                    }
                    break;
                case 1:
                    if (array[i] === '1') {
                        console.log(permissions['PROFESSOR']);
                        permissionsCollect['PROFESSOR'] = {
                            ...permissions['PROFESSOR'],
                            value: true,
                        };
                    }
                    break;
                case 2:
                    if (array[i] === '1') {
                        permissionsCollect['ASSESSOR'] = {
                            ...permissions['ASSESSOR'],
                            value: true,
                        };
                    }
                    break;
                case 3:
                    if (array[i] === '1') {
                        permissionsCollect['PARENT'] = {
                            ...permissions['PARENT'],
                            value: true,
                        };
                    }
                    break;
                case 4:
                    if (array[i] === '1') {
                        permissionsCollect['SPOKESMAN'] = {
                            ...permissions['SPOKESMAN'],
                            value: true,
                        };
                    }
                    break;
                case 5:
                    if (array[i] === '1') {
                        permissionsCollect['DIRECTOR'] = {
                            ...permissions['DIRECTOR'],
                            value: true,
                        };
                    }
                    break;
                case 6:
                    if (array[i] === '1') {
                        permissionsCollect['ADMINISTRATIVE'] = {
                            ...permissions['ADMINISTRATIVE'],
                            value: true,
                        };
                    }
                    break;
                case 7:
                    if (array[i] === '1') {
                        permissionsCollect['TUTOR'] = {
                            ...permissions['TUTOR'],
                            value: true,
                        };
                    }
                    break;

                default:
                    break;
            }
        }
        console.log(permissionsCollect);
        setPermissions({ ...permissions, ...permissionsCollect });
    };

    /**
     * Retorna una lista con los nombre de los permisos
     *
     * @param {*} groupsIds
     * @returns
     */
    const getPermissionsName = (groupsIds) => {
        if (groupsIds == undefined || groupsIds == null) {
            return;
        }

        let array = groupsIds + '';

        let permissionsCollect = [];

        for (let i = 0; i < 8; i++) {
            switch (i) {
                case 0:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: 'ADMINISTER',
                            position: 1,
                            label: 'Administrador',
                        });
                    }
                    break;
                case 1:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: 'PROFESSOR',
                            position: 2,
                            label: 'Profesor',
                        });
                    }
                    break;
                case 2:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: 'ASSESSOR',
                            position: 3,
                            label: 'Asesor',
                        });
                    }
                    break;
                case 3:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: 'PARENT',
                            position: 4,
                            label: 'Padre',
                        });
                    }
                    break;
                case 4:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: 'SPOKESMAN',
                            position: 5,
                            label: 'Vosero',
                        });
                    }
                    break;
                case 5:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: 'DIRECTOR',
                            position: 6,
                            label: 'Director',
                        });
                    }
                    break;
                case 6:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: 'ADMINISTRATIVE',
                            position: 7,
                            label: 'Director',
                        });
                    }
                    break;
                case 7:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: 'TUTOR',
                            position: 8,
                            label: 'Tutor',
                        });
                    }
                    break;

                default:
                    break;
            }
        }

        return permissionsCollect;
    };

    const permissionEditUI = () => {
        let currentPermissons = getPermissionsName(user.group_id);

        let newUnorderPermisions = Object.values(permissions);

        newUnorderPermisions.sort(function (a, b) {
            return a.position - b.position;
        });

        let newPermissons = newUnorderPermisions
            .filter((i) => i.value)
            .filter((newP) => {
                return !currentPermissons.find(
                    (currP) => currP.name == newP.name
                );
            })
            .map((i) => {
                return <Chip label={i.label} color="success" size="small" />;
            });

        let deletePermissons = newUnorderPermisions
            .filter((i) => !i.value)
            .filter((newP) => {
                return currentPermissons.find(
                    (currP) => currP.name == newP.name
                );
            })
            .map((i) => {
                return <Chip label={i.label} color="error" size="small" />;
            });

        return (
            <>
                <Grid item xs={6}>
                    <Box style={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1" component="div">
                            Nuevos permisos
                        </Typography>
                        {newPermissons.length > 0 && newPermissons}
                        {newPermissons == 0 && (
                            <Typography variant="subtitle1" component="div">
                                Sin permisos
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box style={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1" component="div">
                            Permisos a remover
                        </Typography>
                        {deletePermissons.length > 0 && deletePermissons}
                        {deletePermissons == 0 && (
                            <Typography variant="subtitle1" component="div">
                                Sin permisos
                            </Typography>
                        )}
                    </Box>
                </Grid>
            </>
        );
    };

    return (
        <Dialog
            open={openModal}
            fullWidth={true}
            maxWidth="md"
            disableEscapeKeyDown={statusOperation == 'pending'}
            onClose={handleClose}
        >
            <DialogTitle
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ flexGrow: 1, color: fontColor.value }}
                    >
                        {userData.hasOwnProperty('user_id') &&
                            `Actualizar ${title}`}
                        {!userData.hasOwnProperty('user_id') &&
                            `Agregar ${title}`}
                    </Typography>
                </div>
            </DialogTitle>

            <DialogContent dividers>
                <Box>
                    {(userData.school_id == 0 ||
                        userData.school_id == null ||
                        userData.school_id == schoolId) && (
                        <Box>
                            {userData.hasOwnProperty('user_id') &&
                                tipoUsuario != 'ADMINISTER' && (
                                    <Box sx={{ mb: 2 }}>
                                        <Box>
                                            <Typography
                                                variant="h6"
                                                component="div"
                                                style={{ width: '100%' }}
                                            >
                                                Tipo de usuario
                                            </Typography>
                                            {permisionsValid().invalid && (
                                                <FormHelperText
                                                    style={{ color: 'red' }}
                                                >
                                                    {permisionsValid().message}
                                                </FormHelperText>
                                            )}
                                        </Box>
                                        <Grid
                                            container
                                            xl={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            style={{ paddingLeft: 30 }}
                                        >
                                            <Grid
                                                item
                                                xl={4}
                                                md={4}
                                                sm={12}
                                                xs={12}
                                            >
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    permissions
                                                                        .ADMINISTER
                                                                        .value
                                                                }
                                                                onChange={
                                                                    onChangePermission
                                                                }
                                                                name="ADMINISTER"
                                                            />
                                                        }
                                                        label="Administrador"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    permissions
                                                                        .PROFESSOR
                                                                        .value
                                                                }
                                                                onChange={
                                                                    onChangePermission
                                                                }
                                                                name="PROFESSOR"
                                                            />
                                                        }
                                                        label="Maestro"
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid
                                                item
                                                xl={4}
                                                md={4}
                                                sm={12}
                                                xs={12}
                                            >
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    permissions
                                                                        .PARENT
                                                                        .value
                                                                }
                                                                onChange={
                                                                    onChangePermission
                                                                }
                                                                name="PARENT"
                                                            />
                                                        }
                                                        label="Padre"
                                                    />

                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    permissions
                                                                        .DIRECTOR
                                                                        .value
                                                                }
                                                                onChange={
                                                                    onChangePermission
                                                                }
                                                                name="DIRECTOR"
                                                            />
                                                        }
                                                        label="Director"
                                                    />
                                                </FormGroup>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={4}
                                                md={4}
                                                sm={12}
                                                xs={12}
                                            >
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    permissions
                                                                        .ASSESSOR
                                                                        .value
                                                                }
                                                                onChange={
                                                                    onChangePermission
                                                                }
                                                                name="ASSESSOR"
                                                            />
                                                        }
                                                        label="Orientador"
                                                    />

                                                    {/*
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={permissions.ADMINISTRATIVE.value}
                                                                    onChange={onChangePermission}
                                                                    name="ADMINISTRATIVE"
                                                                />
                                                            }
                                                            label="Administrativo"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={permissions.TUTOR.value}
                                                                    onChange={onChangePermission}
                                                                    name="TUTOR"
                                                                />
                                                            }
                                                            label="Tutor"
                                                        />*/}
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            {permissionEditUI()}
                                        </Grid>
                                        <Divider
                                            sx={{ mt: 2 }}
                                            variant="middle"
                                            orientation="horizontal"
                                            flexItem
                                        />
                                    </Box>
                                )}
                        </Box>
                    )}

                    <Grid container spacing={1}>
                        {!userData.hasOwnProperty('user_id') && (
                            <>
                                <Grid
                                    container
                                    item
                                    xl={4}
                                    md={4}
                                    sm={12}
                                    xs={12}
                                >
                                    <TextField
                                        id="password"
                                        name="password"
                                        label="Contraseña"
                                        type="password"
                                        size="small"
                                        variant="outlined"
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        value={userData.password}
                                        onChange={onInputChange}
                                        error={passwordValid().invalid}
                                        helperText={
                                            passwordValid().invalid
                                                ? passwordValid().message
                                                : false
                                        }
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xl={4}
                                    md={4}
                                    sm={12}
                                    xs={12}
                                >
                                    <TextField
                                        id="password_confirmation"
                                        name="password_confirmation"
                                        label="Repetir contraseña"
                                        type="password"
                                        size="small"
                                        variant="outlined"
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        value={userData.password_confirmation}
                                        onChange={onInputChange}
                                        error={
                                            passwordConfirmationValid().invalid
                                        }
                                        helperText={
                                            passwordConfirmationValid().invalid
                                                ? passwordConfirmationValid()
                                                      .message
                                                : false
                                        }
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xl={4}
                                    md={4}
                                    sm={12}
                                    xs={12}
                                >
                                    <TextField
                                        id="cellphone"
                                        name="cellphone"
                                        label="Teléfono"
                                        type="tel"
                                        size="small"
                                        autoComplete="current-password"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ maxLength: 10 }}
                                        value={userData.cellphone}
                                        onChange={onInputChange}
                                        error={cellphoneValid().invalid}
                                        helperText={
                                            cellphoneValid().invalid
                                                ? cellphoneValid().message
                                                : false
                                        }
                                    />
                                </Grid>
                            </>
                        )}

                        <Grid container item xl={12} md={12} sm={12} xs={12}>
                            <Typography
                                variant="h6"
                                component="div"
                                style={{ width: '100%' }}
                            >
                                Datos generales
                            </Typography>
                        </Grid>

                        <Grid container item xl={4} md={4} sm={12} xs={12}>
                            <TextField
                                id="name"
                                name="name"
                                label="Nombre"
                                type="text"
                                size="small"
                                variant="outlined"
                                inputProps={{ maxLength: 50 }}
                                fullWidth
                                value={userData.name}
                                onChange={onInputChange}
                                error={nameValid().invalid}
                                helperText={
                                    nameValid().invalid
                                        ? nameValid().message
                                        : false
                                }
                            />
                        </Grid>
                        <Grid container item xl={4} md={4} sm={12} xs={12}>
                            <TextField
                                id="last_name"
                                name="last_name"
                                label="Apellido Paterno"
                                type="text"
                                size="small"
                                variant="outlined"
                                inputProps={{ maxLength: 50 }}
                                fullWidth
                                value={userData.last_name}
                                onChange={onInputChange}
                                error={lastNameValid().invalid}
                                helperText={
                                    lastNameValid().invalid
                                        ? lastNameValid().message
                                        : false
                                }
                            />
                        </Grid>
                        <Grid container item xl={4} md={4} sm={12} xs={12}>
                            <TextField
                                id="second_last_name"
                                name="second_last_name"
                                label="Apellido Materno"
                                type="text"
                                size="small"
                                variant="outlined"
                                inputProps={{ maxLength: 50 }}
                                fullWidth
                                value={userData.second_last_name}
                                onChange={onInputChange}
                                error={secondLastNameValid().invalid}
                                helperText={
                                    secondLastNameValid().invalid
                                        ? secondLastNameValid().message
                                        : false
                                }
                            />
                        </Grid>
                        {tipoUsuario != 'PARENT' && (
                            <Grid container item xl={4} md={4} sm={12} xs={12}>
                                <TextField
                                    id="enrollment"
                                    name="enrollment"
                                    label="Matrícula"
                                    type="text"
                                    size="small"
                                    variant="outlined"
                                    inputProps={{ maxLength: 50 }}
                                    fullWidth
                                    value={userData.enrollment}
                                    onChange={onInputChange}
                                    error={enrollmentValid().invalid}
                                    helperText={
                                        enrollmentValid().invalid
                                            ? enrollmentValid().message
                                            : false
                                    }
                                />
                            </Grid>
                        )}
                        <Grid container item xl={4} md={4} sm={12} xs={12}>
                            <TextField
                                id="email"
                                name="email"
                                label="Correo"
                                type="tel"
                                size="small"
                                variant="outlined"
                                fullWidth
                                value={userData.email}
                                onChange={onInputChange}
                                error={emailValid().invalid}
                                helperText={
                                    emailValid().invalid
                                        ? emailValid().message
                                        : false
                                }
                            />
                        </Grid>
                        <Grid container item xl={12} md={12} sm={12} xs={12}>
                            <Typography
                                variant="h6"
                                component="div"
                                style={{ width: '100%' }}
                            >
                                Domicilio
                            </Typography>
                        </Grid>
                        <Grid container item xl={6} md={6} sm={12} xs={12}>
                            <TextField
                                id="street"
                                name="street"
                                label="Calle"
                                type="text"
                                size="small"
                                variant="outlined"
                                inputProps={{ maxLength: 50 }}
                                fullWidth
                                value={userData.street}
                                onChange={onInputChange}
                                error={streetValid().invalid}
                                helperText={
                                    streetValid().invalid
                                        ? streetValid().message
                                        : false
                                }
                            />
                        </Grid>
                        <Grid container item xl={6} md={6} sm={12} xs={12}>
                            <TextField
                                id="colony"
                                name="colony"
                                label="Colonia"
                                type="text"
                                size="small"
                                variant="outlined"
                                inputProps={{ maxLength: 50 }}
                                fullWidth
                                value={userData.colony}
                                onChange={onInputChange}
                                error={colonyValid().invalid}
                                helperText={
                                    colonyValid().invalid
                                        ? colonyValid().message
                                        : false
                                }
                            />
                        </Grid>
                        <Grid container item xl={4} md={4} sm={12} xs={12}>
                            <TextField
                                id="outside_number"
                                name="outside_number"
                                label="Número Exterior"
                                type="text"
                                size="small"
                                autoComplete="current-password"
                                variant="outlined"
                                inputProps={{ maxLength: 25 }}
                                fullWidth
                                value={userData.outside_number}
                                onChange={onInputChange}
                                error={outsideNumberValid().invalid}
                                helperText={
                                    outsideNumberValid().invalid
                                        ? outsideNumberValid().message
                                        : false
                                }
                            />
                        </Grid>
                        <Grid container item xl={4} md={4} sm={12} xs={12}>
                            <TextField
                                id="inside_number"
                                name="inside_number"
                                label="Número Interior"
                                type="text"
                                size="small"
                                variant="outlined"
                                fullWidth
                                inputProps={{ maxLength: 20 }}
                                value={userData.inside_number}
                                onChange={onInputChange}
                                error={insideNumberValid().invalid}
                                helperText={
                                    insideNumberValid().invalid
                                        ? insideNumberValid().message
                                        : false
                                }
                            />
                        </Grid>
                        <Grid container item xl={4} md={4} sm={12} xs={12}>
                            <TextField
                                id="zipcode"
                                name="zipcode"
                                label="Código Postal"
                                type="text"
                                size="small"
                                autoComplete="current-password"
                                variant="outlined"
                                inputProps={{ maxLength: 25 }}
                                fullWidth
                                value={userData.zipcode}
                                onChange={onInputChange}
                                error={zipcodeValid().invalid}
                                helperText={
                                    zipcodeValid().invalid
                                        ? zipcodeValid().message
                                        : false
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>

            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={enviarDatos}
                    loading={statusOperation == 'pending'}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                >
                    {userData.hasOwnProperty('user_id') && `Actualizar`}
                    {!userData.hasOwnProperty('user_id') && `Agregar`}
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        handleClose();
                    }}
                    disabled={statusOperation == 'pending'}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalUsuarioAgregar;
