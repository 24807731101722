import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import Authentication from '../../../../service/Login';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import { selectGroupsById } from '../groups';

let Auth = new Authentication();

const usersAdapter = createEntityAdapter({
    selectId: (user) => user.user_id,
    sortComparer: (a, b) => a.user_id - b.user_id,
});

/**
 * Slice para las configuraciones de las escuelas
 */
export const usersSlice = createSlice({
    name: 'users',
    initialState: usersAdapter.getInitialState(),
    reducers: {
        addManyUsers: usersAdapter.addMany,
        setAllUsers: usersAdapter.setAll,
        /**
         * Agrega y actualiza/elimina valores actuales
         * @param {*} state
         * @param {*} action
         */
        addOneUser: usersAdapter.addOne,
        /**
         * Agrega y actualiza valores actuales,
         * @param {*} state
         * @param {*} action
         */
        upsertOneUser: usersAdapter.upsertOne,
        /**
         * Si el elemento existe realizara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         *
         * @param {*} state
         * @param {*} action
         */
        upsertManyUsers: usersAdapter.upsertMany,
        removeOneUser: usersAdapter.removeOne,
        updateOneUser: usersAdapter.updateOne,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return usersAdapter.getInitialState();
        });
    },
});

/**
 * Acciones generadas por la librerias
 */
export const {
    addManyUsers,
    addOneUser,
    upsertOneUser,
    upsertManyUsers,
    setAllUsers,
    removeOneUser,
    updateOneUser,
} = usersSlice.actions;

/**
 * Exportaciones por defecto del reducer generado por la libreria
 */
export default usersSlice.reducer;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = usersAdapter.getSelectors(
    (state) => state.entities.users
);

/**
 * Selector para recuperar todas la entidades
 *
 * @param {*} state
 * @returns
 */
export const selectEntitiesUsers = (state) =>
    globalizedSelectors.selectEntities(state);

/**
 * Selector para recuperar todos los usuarios
 *
 * @param {*} state
 * @returns
 */
export const selectAllUsers = (state) => globalizedSelectors.selectAll(state);

/**
 * Selector para seleccionar un usuario por ID
 *
 * @param number Identificador del usuario
 *
 * @returns any
 */
export const selectUserById = (id) => (state) =>
    globalizedSelectors.selectById(state, id);

/**
 * Selector para recuperar todos los usuarios de tipo profesores
 *
 * @param {*} state
 *
 * @returns any[]
 */
export const selectProfessors = (state) => {
    return selectAllUsers(state).filter((i) => {
        Auth.setUser(i);

        return Auth.isProfessor();
    });
};

/**
 * Selector para recuperar los usuarios de tipo profesor de una escuela
 *
 * @param number Identificador de la escuela
 *
 * @returns any[]
 */
export const selectProfessorsBySchool = (schoolId) => (state) => {
    return _.filter(selectProfessors(state), { school_id: schoolId });
};

/**
 * Selector para recuperar todos los usuarios de tipo padre
 *
 * @param {*} state
 *
 * @returns any[]
 */
export const selectParents = (state) => {
    return selectAllUsers(state).filter((i) => {
        Auth.setUser(i);

        return Auth.isParent();
    });
};

/**
 * Selector para recuperar todos los usuarios de tipo administrador
 *
 * @param {*} state
 *
 * @returns any[]
 */
export const selectAdministers = (state) => {
    return selectAllUsers(state).filter((i) => {
        Auth.setUser(i);

        return Auth.isAdminister();
    });
};

/**
 * Selector para recuperar los usuarios de tipo administrador de una escuela
 *
 * @param number Identificador de la escuela
 *
 * @returns any[]
 */
export const selectAdministersBySchool = (schoolId) => (state) =>
    _.filter(selectAdministers(state), { school_id: schoolId });

/**
 * Selector para recuperar todos los usuarios de tipo administrativo
 *
 * @param {*} state
 *
 * @returns any[]
 */
export const selectAdministratives = (state) => {
    return selectAllUsers(state).filter((i) => {
        Auth.setUser(i);

        return Auth.isAdministrative();
    });
};

/**
 * Selector para recuperar todos los usuarios de tipo asesor
 *
 * @param {*} state
 *
 * @returns any[]
 */
export const selectAssessors = (state) => {
    return selectAllUsers(state).filter((i) => {
        Auth.setUser(i);

        return Auth.isAsessor();
    });
};

/**
 * Selector para recuperar los usuarios de tipo asesor de una escuela
 *
 * @param number Identificador de la escuela
 *
 * @returns
 */
export const selectAssessorsBySchool = (schoolId) => (state) =>
    _.filter(selectAssessors(state), { school_id: schoolId });

/**
 * Selector para recuperar los usuarios de tipo asesor de un grupo
 *
 * @param number Identificador del grupo
 *
 * @returns
 */
export const selectAssesorByGroup = (groupId) =>
    createSelector(
        selectGroupsById(groupId),
        selectAssessors,
        (group, assesors) => {
            return assesors.find(
                (assesor) => assesor.assessor_id === group.assessor_id
            );
        }
    );

/**
 * Selector para recuperar todos los usuarios de tipo voseros
 *
 * @param {*} state
 *
 * @returns any[]
 */
export const selectSpokeman = (state) => {
    return selectAllUsers(state).filter((i) => {
        Auth.setUser(i);

        return Auth.isSpokesman();
    });
};

/**
 * Selector para recuperar los usurios de tipo vosero por escuela
 *
 * @param {*} state
 *
 * @returns any[]
 */
export const selectSpokemanBySchool = (schoolId) => (state) =>
    _.filter(selectSpokeman(state), { school_id: schoolId });

/**
 * Selector para recuperar todos los usuarios de tipo director
 *
 * @param {*} state
 *
 * @returns any[]
 */
export const selectDirectors = (state) => {
    return selectAllUsers(state).filter((i) => {
        Auth.setUser(i);

        return Auth.isDirector();
    });
};

/**
 * Selector para recuperar solos los usuarios de tipo director por escuela
 *
 * @param number Identificador de la escuela
 *
 * @returns
 */
export const selectDirectorsBySchool = (schoolId) => (state) =>
    _.filter(selectDirectors(state), { school_id: schoolId });
