import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSchoolById } from '../store/slices/entities/schools';
import { useAuth, useFeedback } from '../hooks';
import Services from '../service/Connection';
import SchoolGeneralInfoCard from './SchoolGeneralInfoCard';
import SchoolLocationInfoCard from './SchoolLocationInfoCard';
import {
    updateGeneralInfo,
    updateLocationInfo,
} from '../store/slices/settingsUI';

const SchoolGeneralDataCard = () => {
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const school = useSelector(selectSchoolById(schoolId));
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    const [loading, setLoading] = useState(false);

    const [schoolsData, setSchoolData] = useState({
        cellphone: '',
        city_id: null,
        clave: '',
        colony: '',
        control: null,
        inside_number: '',
        level: null,
        location: null,
        name: '',
        maximum_student_capacity: 0,
        reserved_students_capacity: 0,
        school_type_id: null,
        sector: '',
        zone: '',
        region: '',
        oci: '',
        foundation_date: null,
        geographical_scope: null,
        outside_number: '',
        school_id: null,
        street: '',
        sustenance: null,
        zipcode: '',
    });

    useEffect(() => {
        let schoolMixed = { ...schoolsData, ...school };
        setSchoolData(schoolMixed);
    }, [school]);

    const handleGeneralInfoUpdate = async (data) => {
        const updatedData = { ...schoolsData, ...data };
        setSchoolData(updatedData);

        await dispatch(updateGeneralInfo(updatedData))
            .unwrap()
            .then((res) => {
                feedbackApp.showFeedback({
                    title: 'Datos de la escuela guardados correctamente',
                });
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const handleLocationInfoUpdate = async (data) => {
        const updatedData = { ...schoolsData, ...data };
        setSchoolData(updatedData);

        await dispatch(updateLocationInfo(updatedData))
            .unwrap()
            .then((res) => {
                feedbackApp.showFeedback({
                    title: 'Datos de la ubicación guardados correctamente',
                });
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    return (
        <>
            <SchoolGeneralInfoCard
                schoolsData={schoolsData}
                onUpdate={handleGeneralInfoUpdate}
            />
            <SchoolLocationInfoCard
                schoolsData={schoolsData}
                onUpdate={handleLocationInfoUpdate}
                loading={loading}
            />
        </>
    );
};

export default SchoolGeneralDataCard;
