import { combineReducers } from "@reduxjs/toolkit";

import items from "./ItemsSlice";
import ui from "./uiSlice";
import fetch from "./fetchSlice";
import entities from "./entitiesSlice";
//import studentsItems from "./studentItemsSlice";

export default combineReducers({
    items,
   // studentsItems,
    fetch,
    ui,
    entities
})