import {
    Card,
    CardContent,
    Typography,
    Chip,
    Box,
} from '@mui/material';
import { PercentOutlined } from '@mui/icons-material';
import { useAuth } from '../../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';
import { Avatar } from 'antd';
import { fetchLastStudentWithDiscount, selectLastStudentWithDiscount } from '../../../store/home/tables';
import useThemeColors from '../../../../theme/themes';

export const TopStudentsDiscount = () => {
    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = useThemeColors();


    useEffect(() => {
        if (schoolId) {
            dispatch(fetchLastStudentWithDiscount(schoolId));
        }
    }, [dispatch, schoolId]);

    const lastStudentWithDiscount = useSelector(selectLastStudentWithDiscount).value;

    const rows = Array.isArray(lastStudentWithDiscount) ? lastStudentWithDiscount.slice(0, 5) : [];

    const getChipProps = (conceptTitle) => {
        switch (conceptTitle) {
            default:
                return { color: 'default', label: conceptTitle };
        }
    };

    return (
        <Card
            sx={{
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: theme.palette.background.default,
                color: theme.palette.neutral.main,
                maxWidth: '100%',
                maxHeight:'100%',

                mb: 2,
            }}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center" 
                padding={2}
            >
                <Box
                    sx={{
                        backgroundColor:colors.velvetNight[400],
                        borderRadius: '100%',
                        width: '2rem',
                        height: '2rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mr: 1.5,  
                    }}
                >
                    <PercentOutlined
                        sx={{
                            width: '1.5rem',
                            color: '#ffffff',
                        }}
                    />
                </Box>
                <Typography variant="h6" className="card-value">
                    Estudiantes con Descuentos
                </Typography>
            </Box>
            <CardContent>
                <Box>
                    {rows.map((row) => (
                        <Box
                            key={row.student_name}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={3}
                            mt={3}

                            sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
                        >
                            <Box display="flex" alignItems="center">
                                <Avatar 
                                    src={row.avatar} 
                                    sx={{ 
                                        width: 60,   
                                        height: 60, 
                                        marginRight: 2, 
                                    }} 
                                />
                                <Box ml={2}>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        {row.student_name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {row.label}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Chip
                                    label={getChipProps(row.concept_title).label}
                                    color={getChipProps(row.concept_title).color}
                                    sx={{ fontWeight: 'bold' }}
                                />
                            </Box>
                        </Box>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
};

export default TopStudentsDiscount;
