///////////////////////////////////////////// FETCH //////////////////////////////////////////////////

export const selectComponentGroupsBestAssistanceGroupFetch = (store) =>
    store.moduleDirector.groups.components.best_assistance_group.fetch;

export const selectComponentGroupsMostAbscensesGroupFetch = (store) =>
    store.moduleDirector.groups.components.most_abscenses_group.fetch;

export const selectComponentGroupsMostReportsGroupFetch = (store) =>
    store.moduleDirector.groups.components.most_reports_group.fetch;

export const selectComponentGroupsImcFetch = (store) =>
    store.moduleDirector.groups.components.imc.fetch;

export const selectComponentGroupsScoresMainFetch = (store) =>
    store.moduleDirector.groups.components.scores_main.fetch;

export const selectComponentGroupsScoresSpecialFetch = (store) =>
    store.moduleDirector.groups.components.scores_special.fetch;

export const selectComponentGroupsScoresExtracurricularFetch = (store) =>
    store.moduleDirector.groups.components.scores_extracurricular.fetch;

export const selectComponentGroupsAssistsAbsencesFetch = (store) =>
    store.moduleDirector.groups.components.assists_absences.fetch;

export const selectComponentGroupsReportsFetch = (store) =>
    store.moduleDirector.groups.components.reports.fetch;

export const selectComponentGroupsTotalStudentsDiversityFetch = (store) =>
    store.moduleDirector.groups.components.total_students_diversity.fetch;

export const selectComponentGroupsMaleFemaleFetch = (store) =>
    store.moduleDirector.groups.components.male_female.fetch;

export const selectComponentGroupsBestScoreGroupFetch = (store) =>
    store.moduleDirector.groups.components.best_score_group.fetch;

export const selectComponentGroupsWorstScoreGroupFetch = (store) =>
    store.moduleDirector.groups.components.worst_score_group.fetch;

export const selectComponentGroupsAllScholarshipStudentsFetch = (store) =>
    store.moduleDirector.groups.components.all_scholarship_students.fetch;

///////////////////////////////////////STATUS SERVER///////////////////////////////////////////

export const selectComponentGroupsBestAssistanceGroupStatusServer = (store) =>
    store.moduleDirector.groups.components.best_assistance_group.fetch
        .statusServer;

export const selectComponentGroupsMostAbscensesGroupStatusServer = (store) =>
    store.moduleDirector.groups.components.most_abscenses_group.fetch
        .statusServer;

export const selectComponentGroupsMostReportsGroupStatusServer = (store) =>
    store.moduleDirector.groups.components.most_reports_group.fetch
        .statusServer;

export const selectComponentGroupsImcStatusServer = (store) =>
    store.moduleDirector.groups.components.imc.fetch.statusServer;

export const selectComponentGroupsScoresMainStatusServer = (store) =>
    store.moduleDirector.groups.components.scores_main.fetch.statusServer;

export const selectComponentGroupsScoresSpecialStatusServer = (store) =>
    store.moduleDirector.groups.components.scores_special.fetch.statusServer;

export const selectComponentGroupsScoresExtracurricularStatusServer = (store) =>
    store.moduleDirector.groups.components.scores_extracurricular.fetch
        .statusServer;

export const selectComponentGroupsAssistsAbsencesStatusServer = (store) =>
    store.moduleDirector.groups.components.assists_absences.fetch.statusServer;

export const selectComponentGroupsReportsStatusServer = (store) =>
    store.moduleDirector.groups.components.reports.fetch.statusServer;

export const selectComponentGroupsTotalStudentsDiversityStatusServer = (
    store
) =>
    store.moduleDirector.groups.components.total_students_diversity.fetch
        .statusServer;

export const selectComponentGroupsMaleFemaleStatusServer = (store) =>
    store.moduleDirector.groups.components.male_female.fetch.statusServer;

export const selectComponentGroupsBestScoreGroupStatusServer = (store) =>
    store.moduleDirector.groups.components.best_score_group.fetch.statusServer;

export const selectComponentGroupsWorstScoreGroupStatusServer = (store) =>
    store.moduleDirector.groups.components.worst_score_group.fetch.statusServer;

export const selectComponentGroupsAllScholarshipStudentsStatusServer = (
    store
) =>
    store.moduleDirector.groups.components.all_scholarship_students.fetch
        .statusServer;
