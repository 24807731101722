import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

/**
 * Adaptador para grupos
 */
const studentsAdapter = createEntityAdapter({
    selectId: (group) => group.student_id,
    sortComparer: (a, b) => a.student_id - b.student_id 
})

/**
 * Slice para la entidades de alumnos
 */
const  studentsSlice = createSlice({
    name: "noticesModalUI/entities/students",
    initialState: studentsAdapter.getInitialState(),
    reducers: {
        addOneStudent: studentsAdapter.addOne,
        addManyStudents: studentsAdapter.addMany,
        upsertManyStudents: studentsAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
                    
            return studentsAdapter.getInitialState()
        })
        /**
         * Limpiar los datos al cerar el modal
         */
        builder.addCase('noticesModalUI/reset', (state, action) => studentsAdapter.getInitialState())
    }
})

export const { addOneStudent, addManyStudents, upsertManyStudents } = studentsSlice.actions

//////////////////////// SELECTORES ///////////////////////

const globalizedSelectors = studentsAdapter.getSelectors((state) => state.noticesModalUI.entities.students)

/**
 * Selecciona todos los grupos
 */
export const selectAllStudents = (store) => globalizedSelectors.selectAll(store)


export default studentsSlice.reducer