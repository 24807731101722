import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../hooks';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Services from '../../../service/Connection';
import { selectThemeBySchool } from '../../../store/slices/entities/settings';
import {
    createModule,
    selectStatusOperation,
} from '../../../store/slices/groupsUI/serverSlice';
import { selectSubjectsCatalogsByLevel } from '../../../store/slices/groupsUI/itemSlice';
import SkeletonPage from './SkeletonPage';
import ModuleEdit from './ModuleEdit';
import ModuleSelectorModal from './ModuleSelectorModal';
import * as _ from 'lodash';
import { Error } from '../../../components/Feedback';
import { getLevelName, getTurnLevel } from '../../../libs/utils';

const AddExistingModuleModal = ({ open, setOpen, group = {} }) => {
    //////////// HOOKS ////////////////////

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    //////////// SHARED STATE  /////////////

    const subjectsCatalogs = useSelector(
        selectSubjectsCatalogsByLevel(group.level)
    );

    const { colorTitleBar, fontColor } = useSelector(
        selectThemeBySchool(schoolId)
    );

    const saveOperationstatus = useSelector(selectStatusOperation);

    //////////// LOCAL STATE ////////////////////

    const [modulesAvailables, setModulesAvailables] = useState([]);
    const [subjectUnavailable, setSubjectUnavailable] = useState([]);

    const [typeView, setTypeView] = useState('resume');

    const [moduleSelected, setModuleSelected] = useState({
        title: '',
        subjects: [],
    });

    const [loadingStatus, setLoadingStatus] = useState('idle');

    const invalidForm =
        moduleSelected.title === '' || moduleSelected.subjects.length < 2;

    /////////////////// ACTIONS /////////////////

    useEffect(() => {
        if (open) {
            startRecoverDataFlow();
        }
    }, [open]);

    //////////////////// Handlers ///////////////////////////

    const handleSubjectsSelected = (module) => {
        setModuleSelected(module);
    };

    const handleSelectedModule = (module) => {
        setModuleSelected({
            ...module,
            subjects: module.subjects,
        });
        setTypeView('transfer');
    };

    const handleNewModule = () => {
        dispatch(
            createModule({
                groupId: group.group_id,
                data: [
                    {
                        name: moduleSelected.name,
                        subjects: moduleSelected.subjects,
                    },
                ],
            })
        )
            .unwrap()
            .then((data) => {
                feedbackApp.showFeedback({
                    title: 'Módulo creado',
                });

                startRecoverDataFlow();

                resetForm();
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }

        setOpen(false);
        resetForm();
    };

    const reload = () => {
        startRecoverDataFlow();
    };

    const resetForm = () => {
        setModulesAvailables([]);
        setSubjectUnavailable([]);

        setModuleSelected({
            title: '',
            subjects: [],
        });

        setTypeView('resume');
    };

    /* Regresar a la vista de cartas modulo */
    const handleBack = () => {
        setModuleSelected({
            title: '',
            subjects: [],
        });
        setTypeView('resume');
    };

    const loadUI = async () => {
        let mainSubjects = await Services.getSubjectsByGroup(
            group.group_id
        ).then((i) => i.data.data);
        let currentModules = await Services.getModulesByGroup(
            group.group_id
        ).then((i) => i.data.data);

        for (let module of currentModules) {
            let subjects = await Services.getSubjectsByModule(
                module.group_id
            ).then((i) => i.data.data);

            module.subjects = _.map(subjects, 'catalog_subject_id');
        }

        let allModulesByLevelGrade =
            await Services.getAllSchoolModulesByLevelGrade(
                schoolId,
                group?.level,
                group?.grade
            ).then((i) => i.data.data);

        for (const module of allModulesByLevelGrade) {
            let subjects = await Services.getSubjectsByModule(
                module.group_id
            ).then((i) => i.data.data);

            module.subjects = _.map(subjects, 'catalog_subject_id');
        }

        const modulesAvailables = _.chain(allModulesByLevelGrade)
            .filter((module) => {
                let hasMainSubjects = mainSubjects.some((subject) =>
                    module.subjects.includes(subject.catalog_subject_id)
                );

                const hasSubjectsInSomeModule = currentModules.some(
                    (currentModule) =>
                        currentModule.subjects.some((catalogSubjectId) =>
                            module.subjects.includes(catalogSubjectId)
                        )
                );

                return !(hasMainSubjects || hasSubjectsInSomeModule);
            })
            .uniqWith((a, b) =>
                a.subjects.some((subjetId) => b.subjects.includes(subjetId))
            )
            .value();

        return {
            modulesAvailables,
            subjectsNotAbailables: mainSubjects,
        };
    };

    const startRecoverDataFlow = () => {
        setLoadingStatus('pending');

        loadUI()
            .then((data) => {
                setModulesAvailables(data.modulesAvailables);

                setSubjectUnavailable(data.subjectsNotAbailables);

                setLoadingStatus('fullfiled');
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title: 'Estamos teniendo problemas',
                });
                setLoadingStatus('rejected');
            });
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="md"
            disableEscapeKeyDown={saveOperationstatus === 'pending'}
            onClose={handleClose}
            PaperProps={{
                style: {
                    width: '90%',
                    height: '80vh',
                },
            }}
        >
            <DialogTitle
                style={{
                    backgroundColor: colorTitleBar.value,
                    color: fontColor.value,
                }}
            >
                <Typography>Asignar módulo existente</Typography>
                <span style={{ fontSize: '15px', color: '#fff' }}>
                    Grupo {group?.grade}° {group?.group}{' '}
                    {getTurnLevel(group?.turn)} {getLevelName(group?.level)}
                </span>
            </DialogTitle>
            <DialogContent sx={{ pt: '20px !important' }}>
                {loadingStatus === 'pending' && (
                    <Box sx={{ m: 2 }}>
                        <SkeletonPage />
                    </Box>
                )}

                {loadingStatus === 'fullfiled' && (
                    <>
                        {typeView === 'resume' && (
                            <ModuleSelectorModal
                                modulesByLevelGrade={modulesAvailables}
                                subjects={subjectsCatalogs}
                                onSelectedModule={handleSelectedModule}
                            />
                        )}

                        {typeView === 'transfer' && (
                            <ModuleEdit
                                module={moduleSelected}
                                unavailableSubjects={subjectUnavailable}
                                subjectsByLevel={subjectsCatalogs}
                                onChange={handleSubjectsSelected}
                            />
                        )}
                    </>
                )}

                {loadingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}
            </DialogContent>
            <DialogActions>
                {typeView === 'transfer' && (
                    <>
                        <LoadingButton
                            color="primary"
                            loading={saveOperationstatus === 'pending'}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            onClick={handleNewModule}
                            variant="contained"
                            disabled={invalidForm}
                        >
                            Finalizar
                        </LoadingButton>

                        <Button
                            onClick={handleBack}
                            disabled={saveOperationstatus === 'pending'}
                        >
                            Atrás
                        </Button>
                    </>
                )}

                {typeView === 'resume' && (
                    <Button onClick={handleClose}>Cerrar</Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
export default AddExistingModuleModal;
