import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    createTheme,
    Stack,
    Switch,
    ThemeProvider,
    Tooltip,
    Typography,
    IconButton,
} from '@mui/material';
import useFeedback from '../../../hooks/useFeedback';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import SyncIcon from '@mui/icons-material/Sync';
import SkeletonBlock from './components/SkeletonBlock';
import { Error } from '../../../components/Feedback';
import { SubjectPreviewCard } from '../../components/';
import {
    selectGroupById,
    selectStudentsByGroup,
    selectSubjectById,
} from '../../../store/slices/professorModule/entitiesSlice';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import {
    fetchSubjectAditionalData,
    invalidate,
    selectItemFetchingStatusSelected,
    selectItemOperationStatusSelected,
    selectItemSelected,
    storeAssitsList,
} from '../../../store/slices/professorModule/ItemsSlice';
import { DateTime } from 'luxon';
import { useAuth } from '../../../hooks';
import Services from '../../../service/Connection';
import {
    selectAssistsBy,
    selectAssistsById,
} from '../../../store/slices/entities/assists';
import { selectAllStudentsItems } from '../../../store/slices/studentsUI/ItemsSlice';

function getCurrentDate() {
    const fecha = DateTime.local();
    const formattedDate = fecha.setLocale('es').toFormat("dd 'de' LLLL yyyy");
    return formattedDate;
}

const JustifyAssistsPage = () => {
    ////////// HOOKS //////////

    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const currDate = getCurrentDate();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    ////////////// SHARED STATE //////////////////

    const students = useSelector(selectAllStudentsItems);
    const fetchingStatus = useSelector(selectItemFetchingStatusSelected);
    const subject = useSelector(selectSubjectById(id));
    const groupsStudentsList = useSelector(
        selectStudentsByGroup(subject.group_id)
    );

    /////////////////////// LOCAL STATE ////////////////////////////

    const feedbackApp = useFeedback();
    const [assist, setAssist] = useState([]);
    const [assistan, setAssistan] = useState([]);
    const [reportData, setReportData] = useState({
        report: null,
        title: null,
        query: { subject_id: null, group_id: null },
    });

    /*
     * Definicion de las columnas
     */
    const studentColumns = [
        {
            field: 'url_photo_profile',
            headerName: 'Alumno',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            renderCell: (props) => (
                <Avatar
                    alt="Estudiante"
                    src={props.value}
                    sx={{ display: { xs: 'none', sm: 'flex' } }}
                />
            ),
        },
        {
            field: 'Nombre',
            headerName: 'Nombre',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            valueGetter: (params) => {
                return `${params.row.last_name} ${params.row.second_last_name} ${params.row.name}`;
            },
        },
        {
            field: 'attendance',
            headerName: 'Asistencia',
            width: 150,
            renderCell: (params) => (
                <Chip
                    label={params.value ? 'Asistencia' : 'Falta'}
                    size="small"
                    color={params.value ? 'success' : 'error'}
                />
            ),
        },
        {
            field: 'actions',
            headerName: 'Acciones',
            width: 150,
            renderCell: (params) => (
                <IconButton onClick={() => handleJustify(params.row.id)}>
                    {params.row.attendance ? (
                        <AssignmentTurnedInIcon color="success" />
                    ) : (
                        <ErrorIcon color="error" />
                    )}
                </IconButton>
            ),
        },
    ];

    //////////////////// ACTIONS ////////////////////////

    const handleJustify = (studentId) => {
        //console.log(`Justificar falta para el estudiante con ID: ${studentId}`);
    };

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(id));
        reload();
    };

    /**
     * Recargar la informacion adicional
     */
    const reload = () => {
        dispatch(
            fetchSubjectAditionalData({
                groupId: subject.group_id,
                subjectId: subject.subject_id,
                curretnDate: DateTime.local().toFormat('yyyy-MM-dd'),
            })
        );
    };

    /**
     * Recargar los datos
     */
    useEffect(() => {
        if (id) {
            reload();
        }
    }, [id]);

    useEffect(() => {
        const fetchAssist = async () => {
            try {
                if (schoolId) {
                    const response =
                        await Services.getAssistensByStudent(schoolId);
                    setAssistan(response.data.data);
                }
            } catch (error) {
                console.error('Error al obtener Asistencias:', error);
            }
        };

        fetchAssist();
    }, [schoolId]);

    useEffect(() => {
        const fetchAssist = async () => {
            try {
                if (students.student_id) {
                    const response = await Services.getAssistensByStudent(
                        students.student_id
                    );
                    setAssist(response.data.data);
                }
            } catch (error) {
                console.error('Error al obtener directores:', error);
            }
        };

        fetchAssist();
    }, []);

    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {fetchingStatus == 'pending' && <SkeletonBlock />}
            {fetchingStatus == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {fetchingStatus == 'fulfilled' && (
                <>
                    <Box
                        sx={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="h4" component="div">
                            Control de justificaciones del dia: {currDate}{' '}
                        </Typography>

                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}
                            >
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>

                    <SubjectPreviewCard subjectId={subject.subject_id} />
                    <Stack direction="row" spacing={2}>
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                onClick={() => window.history.back()}
                            >
                                Lista de Asistencias
                            </Button>
                        </Box>
                    </Stack>
                    <Card sx={{ mt: 3 }}>
                        <CardContent
                            sx={{
                                pt: 0,
                                pl: 0,
                                pr: 0,
                                pb: 0,
                                ' & .MuiDataGrid-root': {
                                    border: 'none',
                                },
                            }}
                        >
                            <DataGrid
                                getRowId={(row) => row.student_id}
                                localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                        .localeText
                                }
                                rows={students}
                                disableSelectionOnClick
                                columns={studentColumns}
                                components={{
                                    Toolbar: () => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <GridToolbar />
                                        </div>
                                    ),
                                }}
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                }}
                                pageSize={100}
                                rowsPerPageOptions={[100]}
                                disableColumnMenu
                                autoHeight
                                disableDensitySelector
                            />
                        </CardContent>
                        <CardActions
                            sx={{
                                direction: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        ></CardActions>
                    </Card>
                </>
            )}
        </Box>
    );
};

export default JustifyAssistsPage;
