import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Feedback from '../../../service/Feedback';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import { selectPaymentCatConcepts } from './selectors';
import Connection from '../../../service/Connection';
import { upsertOnePayment } from '../../../store/slices/entities/payments/income';
import { upsertOneCatalogDiscount } from '../../../store/slices/entities/payments/cat_discounts';
import {
    addManyCataloConcepts,
    addOneCataloConcept,
    removeOneCataloConcept,
    upsertManyCataloConcepts,
    upsertOneCataloConcept,
} from '../../../store/slices/entities/payments/cat_concepts';
import { addManyConcepts } from '../../../store/slices/entities/payments/concepts';

const emptyState = {
    create: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    update: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    delete: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
};

export const operationsSlice = createSlice({
    name: 'operations/cat_concepts',
    initialState: emptyState,
    reducers: {},

    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        //////////////// Registro de un catalogo de conceptos ///////////T///////////

        builder.addCase(CreateCatConcepts.rejected, (state, action) => {
            state.create.status = 'rejected';
            state.create.feedback = action.payload.feedback;
        });
        builder.addCase(CreateCatConcepts.fulfilled, (state) => {
            state.create.status = 'fulfilled';
        });
        builder.addCase(CreateCatConcepts.pending, (state) => {
            state.create.status = 'pending';
        });

        //////////////// Eliminacion de un catalogo de conceptos  ///////////T///////////

        builder.addCase(DeleteCatConcepts.rejected, (state, action) => {
            state.delete.status = 'rejected';
            state.delete.feedback = action.payload.feedback;
        });
        builder.addCase(DeleteCatConcepts.fulfilled, (state) => {
            state.delete.status = 'fulfilled';
        });
        builder.addCase(DeleteCatConcepts.pending, (state) => {
            state.delete.status = 'pending';
        });

        //////////////// actualizacion de un catalogo de conceptos  ///////////T///////////

        builder.addCase(UpdateCatConcepts.rejected, (state, action) => {
            state.update.status = 'rejected';
            state.update.feedback = action.payload.feedback;
        });
        builder.addCase(UpdateCatConcepts.fulfilled, (state) => {
            state.update.status = 'fulfilled';
        });
        builder.addCase(UpdateCatConcepts.pending, (state) => {
            state.update.status = 'pending';
        });
    },
});

//export const {} = operationsSlice.actions;

export default operationsSlice.reducer;

export const selectOperations = createSelector(
    selectPaymentCatConcepts,
    (reports) => reports.operations
);

export const selectCreate = createSelector(
    selectOperations,
    (operations) => operations.create
);

export const selectCreateOperation = createSelector(
    selectCreate,
    (create) => create.status
);

export const selectStatusOperation = (state) => state.statusOperation;

/**
 * Agregar un nuevo Pago
 */

export const CreateCatConcepts = createAsyncThunk(
    'cat_Concepts/create',
    async ({ schoolId, data }, thunkAPI) => {
        let FeedbackService = new Feedback();
        console.log(data);
        try {
            const response = await Connection.addConceptCatalog(schoolId, {
                data,
            }).then((res) => res.data.data);
console.log(response, 'response');
            thunkAPI.dispatch(upsertManyCataloConcepts(response));

            return { response };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Eliminar un catalogo de conceptos
 */

export const DeleteCatConcepts = createAsyncThunk(
    'cat_Concepts/delete',
    async ({ catalogconcepts_id, data }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response =
                await Connection.deleteConceptCatalog(catalogconcepts_id);

            const cat_concept = response.data.data;

            thunkAPI.dispatch(upsertOneCataloConcept(cat_concept));
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar un catalogo de conceptos
 */

export const UpdateCatConcepts = createAsyncThunk(
    'cat_Concepts/update',
    async ({ schoolId, data }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
