import { combineReducers } from '@reduxjs/toolkit';
import fetch from './fetchSlice';
import operations from './operations';
import ui from './ui';

export default combineReducers({
    fetch,
    operations,
    ui,
});
