import { createSlice } from '@reduxjs/toolkit';
import * as constants from '../../../service/const';
import _ from 'lodash';
import { addAsyncCase, initialStateProfessors } from '../commonState';
import {
    getAllProfessors,
    getLagAssists,
    getLagCalifications,
    getLastIncident,
    getProfessorMostReports,
    getProfessorsWithoutGroup,
    getTotalReports,
} from './thunks';

export const fetchSlice = createSlice({
    name: 'components',
    initialState: initialStateProfessors,
    reducers: {
        invalidate: (state) => {
            if (state) {
                Object.keys(state).forEach((kpiName) => {
                    if (state[kpiName] && state[kpiName].fetch) {
                        state[kpiName].fetch.didInvalidate = true;
                    }
                });
            }
        },
    },

    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return initialStateProfessors;
        });

        addAsyncCase(builder, getLagAssists, 'lag_assists');
        addAsyncCase(builder, getLagCalifications, 'lag_califications');
        addAsyncCase(
            builder,
            getProfessorsWithoutGroup,
            'professors_without_group'
        );
        addAsyncCase(
            builder,
            getProfessorMostReports,
            'professor_most_reports'
        );
        addAsyncCase(builder, getTotalReports, 'all_professor_reports');
        addAsyncCase(builder, getAllProfessors, 'professors');
        addAsyncCase(builder, getLastIncident, 'last_incident');
    },
});

export const { invalidate } = fetchSlice.actions;

export const getLoading = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getDataStatus = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getFetchStatus = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getCycleSelected = (state) =>
    state.moduleDirector.professors.ui.cycleSelected;
export const getCurrentTab = (state) =>
    state.moduleDirector.professors.ui.currentTab;

export default fetchSlice.reducer;

///////////////////////////////////////////////////////////////////////////////////////////////////////
export const selectLagAssists = (state) => {
    return state.moduleDirector.professors.components.lag_assists.data;
};

export const selectLagCalifications = (state) => {
    return state.moduleDirector.professors.components.lag_califications.data;
};

export const selectProfessorsWithoutGroup = (state) => {
    return state.moduleDirector.professors.components.professors_without_group
        .data;
};

export const selectProfessorMostReports = (state) => {
    return state.moduleDirector.professors.components.professor_most_reports
        .data;
};

export const selectTotalReports = (state) => {
    return state.moduleDirector.professors.components.all_professor_reports
        .data;
};

export const selectProfessors = (state) => {
    return state.moduleDirector.professors.components.professors.data;
};

export const selectLastIncident = (state) => {
    return state.moduleDirector.professors.components.last_incident.data;
};

export const selectDirectorProfessorsStatusServer = (store) =>
    store.moduleDirector.professors.components.status.statusServer;

export const selectDirectorProfessors = (store) =>
    store.moduleDirector.professors;
