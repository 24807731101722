import { useEffect, useState } from 'react';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    IconButton,
    Stack,
} from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { ImportsToolbar } from './ImportsToolbar';
import { ImportModal } from './ImportModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllImports } from '../../../store/slices/entities/imports';
import { selectAdministers } from '../../../store/slices/entities/users';
import { UpdateImportModal } from './UpdateImportModal';
import ReplayIcon from '@mui/icons-material/Replay';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import DefaultMaleImage from '../../../assets/images/default-image-niño.svg';
import DefaultFemaleImage from '../../../assets/images/default-image-niña.svg';
import { DateTime } from 'luxon';
import { selectsettingsUI, updateImportCenterFilter } from '../../../store/slices/settingsUI';

const ImportsCard = () => {

    const dispatch = useDispatch();

    const importData = useSelector(selectAllImports);
    const adminstrators = useSelector(selectAdministers);
    const filtersUI = useSelector(selectsettingsUI);

    const filterImportCenter = filtersUI.importCenter.filter;

    const [openModal, setOpenModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [imports, setImports] = useState([]);
    const [importObject, setImportObject] = useState(null);

    useEffect(() => {
        const importsWithAdminNames = importData.map((importObj) => {
            const userId = importObj.user_id;

            return {
                ...importObj,
                administrator: adminstrators.find(
                    (admin) => Number(admin.user_id) === Number(userId)
                ),
            };
        });
        setImports(importsWithAdminNames);
    }, [importData, adminstrators]);

    /**
     * Import status definitions
     */
    let allImportStatus = {
        1: {
            color: '#ddc005',
            text: 'En espera',
            icon: <AccessTimeIcon />,
            class: 'left-status-onhold',
        },
        2: {
            color: '#036dba',
            text: 'Procesando',
            icon: <CloudSyncIcon />,
            class: 'left-status-processing',
        },
        3: {
            color: 'green',
            text: 'Importada',
            icon: <DoneIcon />,
            class: 'left-status-imported',
        },
        4: {
            color: 'red',
            text: 'Fallido',
            icon: <ErrorIcon />,
            class: 'left-status-failed',
        },
    };

    /**
     * Import types definitions
     */
    let importTypes = {
        students: {
            title: 'Alumnos',
        },
        assists: {
            title: 'Asistencias',
        },
        califications: {
            title: 'Calificaciones',
        },
        'group-scores': {
            title: 'Calificaciones por grupo',
        },
        'massive-sms': {
            title: 'SMS Masivo',
        },
    };

    /**
     * columns definitions
     */
    const columns = [
        {
            field: 'folio',
            headerName: 'Folio',
            flex: 0.4,
            minWidth: 40,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'status',
            headerName: 'Estado',
            flex: 0.4,
            minWidth: 40,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ value }) => {
                let importStatus = allImportStatus[value];

                return (
                    <Stack
                        direction={'row'}
                        sx={{ color: importStatus.color }}
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        {importStatus.icon}
                    </Stack>
                );
            },
        },
        {
            field: 'administrator_name',
            headerName: 'Importador',
            type: 'singleSelect',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => {
                if (row.administrator) {
                    const { gender, url_photo_profile } = row.administrator;

                    const generoS =
                        gender === 'H' ? DefaultMaleImage : DefaultFemaleImage;
                    const comparado =
                        url_photo_profile == null ? generoS : url_photo_profile;

                    return (
                        <>
                            <Avatar
                                alt="Imagen de usuario"
                                src={comparado}
                                sx={{ width: 24, height: 24 }}
                            />

                            {`${row.administrator.name} ${row.administrator.last_name}`}
                        </>
                    );
                }

                return (
                    <>
                        <Avatar
                            alt="Imagen de usuario"
                            src={DefaultMaleImage}
                            sx={{ width: 24, height: 24 }}
                        />
                        Usuario no encontrado
                    </>
                );
            },
        },
        {
            field: 'created_at',
            headerName: 'Fecha de importación',
            type: 'date',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => {
                return DateTime.fromSQL(value)
                    .setLocale('es')
                    .toLocaleString({ locale: 'es', ...DateTime.DATETIME_MED });
            },
        },
        {
            field: 'updated_at',
            type: 'date',
            headerName: 'Fecha de actualización',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => {
                return DateTime.fromSQL(value)
                    .setLocale('es')
                    .toLocaleString({ locale: 'es', ...DateTime.DATETIME_MED });
            },
        },
        {
            field: 'import_type',
            headerName: 'Tipo',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return importTypes[params.value].title;
            },
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            flex: 1,
            maxWidth: 180,
            editable: false,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.status != 4) {
                    return <></>;
                }

                return (
                    <IconButton
                        onClick={() => {
                            updateImport(params.row);
                        }}
                    >
                        <ReplayIcon />
                    </IconButton>
                );
            },
        },
    ];

    const createNewImport = () => {
        setOpenModal(true);
    };

    const updateImport = (imp) => {
        setImportObject(imp);
        setOpenUpdateModal(true);
    };

    const handleImportCenterFilterChange = (event) => {
        let newFilterValues = {};

        event.items.forEach(filter => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value
            };
        });
        dispatch(updateImportCenterFilter({ filter: newFilterValues }));
    };

    return (
        <>
            <Card sx={{ mt: 3 }}>
                <CardContent
                    sx={{
                        pl: 0,
                        pr: 0,
                        pb: 0,
                        ' & .MuiDataGrid-root': {
                            border: 'none',
                        },
                    }}
                >
                    <DataGrid
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={imports}
                        columns={columns}
                        getRowId={(row) => row.import_id}
                        pageSize={10}
                        disableSelectionOnClick
                        autoHeight
                        components={{ Toolbar: ImportsToolbar }}
                        componentsProps={{
                            panel: {
                                placement: 'bottom-end',
                            },
                        }}
                        disableDensitySelector
                        onFilterModelChange={(event) =>
                            handleImportCenterFilterChange(event)
                        }
                        initialState={{
                            filter: {
                                filterModel: {
                                    items: filterImportCenter ? [
                                        {
                                            columnField: filterImportCenter.columnField || '',
                                            operatorValue: filterImportCenter.operatorValue || '',
                                            value: filterImportCenter.value || '',
                                        }
                                    ] : [],
                                },
                            },
                        }}
                        sx={{
                            ' & .left-status-onhold': {
                                borderLeftColor: '#ddc005',
                                borderLeftStyle: 'solid',
                            },
                            ' & .left-status-processing': {
                                borderLeftColor: '#036dba',
                                borderLeftStyle: 'solid',
                            },
                            ' & .left-status-imported': {
                                borderLeftColor: 'green',
                                borderLeftStyle: 'solid',
                            },
                            ' & .left-status-failed': {
                                borderLeftColor: 'red',
                                borderLeftStyle: 'solid',
                            },
                            ' & .left-status-unknown': {
                                borderLeftColor: 'pink',
                                borderLeftStyle: 'solid',
                            },
                        }}
                        getCellClassName={(params) => {
                            if (params.field == 'folio') {
                                let importStatus =
                                    allImportStatus[params.row.status];

                                return importStatus.class;
                            }

                            return '';
                        }}
                    />
                </CardContent>
                <CardActions
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Stack
                        direction={'row'}
                        spacing={0.5}
                        justifyContent="center"
                        alignItems="center"
                    >
                        {Object.values(allImportStatus).map((status) => {
                            return (
                                <Stack
                                    sx={{ color: status.color }}
                                    direction={'row'}
                                    spacing={0.5}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {status.icon} {status.text}
                                </Stack>
                            );
                        })}
                        <ReplayIcon /> Reintentar
                    </Stack>

                    <Button
                        onClick={() => {
                            createNewImport();
                        }}
                        variant="contained"
                        size="small"
                        startIcon={<AddIcon />}
                    >
                        Agregar
                    </Button>
                </CardActions>
            </Card>

            <ImportModal openModal={openModal} setOpenModal={setOpenModal} />
            <UpdateImportModal
                openUpdateModal={openUpdateModal}
                setOpenUpdateModal={setOpenUpdateModal}
                importData={importObject}
            />
        </>
    );
};

export default ImportsCard;
