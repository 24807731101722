import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import * as constants from '../../../service/const';
import {
    getCalificationRound,
    getLevelName,
    getTurnLevel,
} from '../../../libs/utils';
import Feedback from '../../../service/Feedback';
import _ from 'lodash';
import { DateTime } from 'luxon';

/**
 * Adaptadores
 */
const cyclesAdapter = createEntityAdapter({
    selectId: (item) => item.cycle_id,
    sortComparer: (a, b) => a.cycle_id - b.cycle_id,
});

const partialsAdapter = createEntityAdapter({
    selectId: (item) => item.partial_id,
    sortComparer: (a, b) => a.partial_id - b.partial_id,
});

const professorsAdapter = createEntityAdapter({
    selectId: (item) => item.user_id,
    sortComparer: (a, b) => a.user_id - b.user_id,
});

const assessorsAdapter = createEntityAdapter({
    selectId: (item) => item.user_id,
    sortComparer: (a, b) => a.user_id - b.user_id,
});

const usersAdapter = createEntityAdapter({
    selectId: (item) => item.user_id,
    sortComparer: (a, b) => a.user_id - b.user_id,
});

const catalogSubjectsAdapter = createEntityAdapter({
    selectId: (item) => item.catalog_subject_id,
    sortComparer: (a, b) => a.catalog_subject_id - b.catalog_subject_id,
});

const subjectsAdapter = createEntityAdapter({
    selectId: (item) => item.subject_id,
    sortComparer: (a, b) => a.subject_id - b.subject_id,
});

const calificationsAdapter = createEntityAdapter({
    selectId: (item) => item.calification_id,
    sortComparer: (a, b) => a.calification_id - b.calification_id,
});

const reportsAdapter = createEntityAdapter({
    selectId: (item) => item.report_id,
    sortComparer: (a, b) => a.report_id - b.report_id,
});
const filesAdapter = createEntityAdapter({
    selectId: (item) => item.file_id,
    sortComparer: (a, b) => a.file_id - b.file_id,
});

const groupsAdapter = createEntityAdapter({
    selectId: (item) => item.group_id,
    sortComparer: (a, b) => a.group_id - b.group_id,
});
const studentsAdapter = createEntityAdapter({
    selectId: (item) => item.student_id,
    sortComparer: (a, b) => a.student_id - b.student_id,
});

let initialState = {
    ui: {
        currentTab: '/inicio',
        cycleSelected: {
            cycle_id: -1,
        },
    },
    server: {
        expireIn: null,
        ferchingAt: null,
        statusServer: 'idle',
        statusOperation: 'idle',
        didInvalidate: true,
        feedback: {
            title: null,
            message: null,
            payload: null,
        },
    },
    entities: {
        cycles: cyclesAdapter.getInitialState(),
        partials: partialsAdapter.getInitialState(),
        professors: professorsAdapter.getInitialState(),
        assessors: assessorsAdapter.getInitialState(),
        users: usersAdapter.getInitialState(),
        catalogSubjects: catalogSubjectsAdapter.getInitialState(),
        subjects: subjectsAdapter.getInitialState(),
        califications: calificationsAdapter.getInitialState(),
        reports: reportsAdapter.getInitialState(),
        files: filesAdapter.getInitialState(),
        groups: groupsAdapter.getInitialState(),
        students: studentsAdapter.getInitialState(),
        school: {
            group_id: null,
            school_id: null,
            assessor_id: null,
            grade: '',
            group: '',
            turn: null,
            level: null,
            students: 0,
            special: 0,
            average_rating: 0,
            total_absence: 0,
            total_presence: 0,
            total_reports: 0,
            student_population: 0,
            teaching_population: 0,
            average_absence: 0,
            average_presence: 0,
        },
    },
};

export const backgroundSlice = createSlice({
    name: 'background',
    initialState: initialState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.server.didInvalidate = true;
        },
        setStudents: (state, action) => {
            studentsAdapter.setAll(state.entities.students, action.payload);
        },
        setCatalogSubjects: (state, action) => {
            catalogSubjectsAdapter.setAll(
                state.entities.catalogSubjects,
                action.payload
            );
        },
        setSubjects: (state, action) => {
            subjectsAdapter.setAll(state.entities.subjects, action.payload);
        },
        setCalifications: (state, action) => {
            calificationsAdapter.setAll(
                state.entities.califications,
                action.payload
            );
        },
        setGroups: (state, action) => {
            groupsAdapter.setAll(state.entities.groups, action.payload);
        },
        setSchool: (state, action) => {
            state.entities.school = action.payload;
        },
        setAssessors: (state, action) => {
            assessorsAdapter.setAll(state.entities.assessors, action.payload);
        },
        setProfessors: (state, action) => {
            professorsAdapter.setAll(state.entities.professors, action.payload);
        },
        setCycles: (state, action) => {
            cyclesAdapter.setAll(state.entities.cycles, action.payload);
        },
        setCurrentTab: (state, action) => {
            state.ui.currentTab = action.payload;
        },
        setSchoolCycleSelected: (state, action) => {
            state.ui.cycleSelected = action.payload;
        },
        setPartials: (state, action) => {
            partialsAdapter.setAll(state.entities.partials, action.payload);
        },
        setAllEntities: (state, action) => {
            subjectsAdapter.setAll(
                state.entities.subjects,
                action.payload.subjects
            );
            groupsAdapter.setAll(state.entities.groups, action.payload.groups);
            studentsAdapter.setAll(
                state.entities.students,
                action.payload.students
            );
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return initialState;
        });
        /**
         * Sin falla o si se realiza la recuperacion de los datos pones el boton de nuevo para se precionado
         */
        builder.addCase(fetchAllResources.rejected, (state, action) => {
            state.server.statusServer = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(fetchAllResources.fulfilled, (state, action) => {
            state.server.expireIn = new Date().setMinutes(
                new Date().getMinutes() + constants.BACKGROUND_EXPIRE_TIME
            );
            state.server.ferchingAt = Date.now();
            state.server.didInvalidate = false;

            state.server.statusServer = 'fulfilled';
        });

        builder.addCase(fetchAllResources.pending, (state, action) => {
            state.server.statusServer = 'pending';
        });
    },
});

export const {
    setStudents,
    setCatalogSubjects,
    setSubjects,
    setCalifications,
    setGroups,
    setCycles,
    setSchoolCycleSelected,
    setCurrentTab,
    setPartials,
    setAssessors,
    setProfessors,
    setSchool,
    invalidate,
    setAllEntities,
} = backgroundSlice.actions;

////////////////////          ESTADOS                   //////////////////////////

/**
 * Selector para recuperar el estado de la recuperacion de los datos
 *
 * @param {*} state
 *
 * @returns
 */
export const getLoading = (state) => state.background.server.statusServer;
export const getDataStatus = (state) => state.background.server.statusServer;

export const getFetchStatus = (state) => state.background.server.statusServer;

/**
 * Selector para tops de calificaciones por materia
 *
 * @param {*} state
 *
 * @returns
 */
export const getTopScoreSubject = (state) => {
    let result = [];

    const allSubjects = selectAllSubjects(state);
    const entitiesCatalogSubject = selectAllCatalogSubjects(state);

    for (let catalogSubject of entitiesCatalogSubject) {
        let subjects = allSubjects.filter(
            (subject) =>
                subject.catalog_subject_id == catalogSubject.catalog_subject_id
        );

        let totalAverageRating = 0;

        for (const subject of subjects) {
            totalAverageRating += subject.average_rating;
        }

        let total =
            totalAverageRating == 0
                ? 0
                : getCalificationRound(totalAverageRating / subjects.length, 1);

        if (total == 0) {
            continue;
        }

        result.push({
            catalog_subject_id: catalogSubject.catalog_subject_id,
            title: `(${catalogSubject.folio}) ${catalogSubject.title}`,
            folio: catalogSubject.folio,
            average_rating: total,
            levelName: getLevelName(catalogSubject.level),
        });
    }

    result.sort((a, b) => {
        return a.folio.localeCompare(b.folio);
    });

    result.sort((a, b) => {
        if (a.average_rating > b.average_rating) {
            return -1;
        }
        if (a.average_rating < b.average_rating) {
            return 1;
        }
        return 0;
    });

    return result.slice(0, 7);
};

/**
 * Selector para tops de faltas por materia
 *
 * @param {*} state
 * @returns
 */
export const getTopAbsenceSubjects = (state) => {
    let result = [];

    const allSubjects = selectAllSubjects(state);
    const allCatalogSubject = selectAllCatalogSubjects(state);

    for (const catalogSubject of allCatalogSubject) {
        let subjects = allSubjects.filter(
            (subject) =>
                subject.catalog_subject_id == catalogSubject.catalog_subject_id
        );

        let totalAbsence = 0;

        for (const subject of subjects) {
            totalAbsence += subject.total_absence;
        }

        if (totalAbsence == 0) {
            continue;
        }

        result.push({
            catalog_subject_id: catalogSubject.catalog_subject_id,
            title: `(${catalogSubject.folio}) ${catalogSubject.title}`,
            total_absence: totalAbsence,
            levelName: getLevelName(catalogSubject.level),
        });
    }

    result.sort((a, b) => {
        if (a.total_absence > b.total_absence) {
            return -1;
        }
        if (a.total_absence < b.total_absence) {
            return 1;
        }
        return 0;
    });

    return result.slice(0, 7);
};

/**
 * Selector para tops de faltas por grupo
 *
 * @param {*} state
 * @returns
 */
export const getTopAbsenceGroups = (state) => {
    let result = [];
    const allGroups = selectAllGroups(state);

    for (const group of allGroups) {
        //let subjects = state.subjects.filter(subject => subject.catalog_subject_id == catalogSubject.catalog_subject_id)

        result.push({
            group_id: group.group_id,
            title: `${group.grade}°${group.group} ${getTurnLevel(group.turn)}`,
            total_absence: group.total_absence,
            levelName: getLevelName(group.level),
        });
    }

    result.sort((a, b) => {
        if (a.total_absence > b.total_absence) {
            return -1;
        }
        if (a.total_absence < b.total_absence) {
            return 1;
        }
        return 0;
    });

    return result.slice(0, 7);
};

/**
 * Selector para tops de calificaciones por grupo
 *
 * @param {*} state
 * @returns
 */
export const getTopScoreGroups = (state) => {
    let result = [];

    const allGroups = selectAllGroups(state);

    for (const group of allGroups) {
        if (group.average_rating == 0) {
            continue;
        }

        result.push({
            group_id: group.group_id,
            title: `${group.grade}°${group.group} ${getTurnLevel(group.turn)}`,
            average_rating: group.average_rating,
            levelName: getLevelName(group.level),
        });
    }

    result.sort((a, b) => {
        if (a.average_rating > b.average_rating) {
            return -1;
        }
        if (a.average_rating < b.average_rating) {
            return 1;
        }
        return 0;
    });

    return result.slice(0, 7);
};

/**
 * Selector para tops de las peores calificaciones
 *
 * @param {*} state
 * @returns
 */
export const getTopBadScoreGroups = (state) => {
    let result = [];

    const allGroups = selectAllGroups(state);

    for (const group of allGroups) {
        if (group.average_rating == 0) {
            continue;
        }

        result.push({
            group_id: group.group_id,
            title: `${group.grade}°${group.group} ${getTurnLevel(group.turn)}`,
            average_rating: group.average_rating,
            levelName: getLevelName(group.level),
        });
    }

    result.sort((a, b) => {
        if (a.average_rating > b.average_rating) {
            return 1;
        }
        if (a.average_rating < b.average_rating) {
            return -1;
        }
        return 0;
    });

    return result.slice(0, 7);
};
/**
 * Selector para tops de grupos con mas reportes
 *
 * @param {*} state
 * @returns
 */
export const getTopReportsGroups = (state) => {
    let result = [];

    const allGroups = selectAllGroups(state);

    for (const group of allGroups) {
        if (group.average_rating == 0) {
            continue;
        }

        result.push({
            group_id: group.group_id,
            title: `${group.grade}°${group.group} ${getTurnLevel(group.turn)}`,
            total_reports: group.total_reports,
            levelName: getLevelName(group.level),
        });
    }

    result.sort((a, b) => {
        if (a.total_reports > b.total_reports) {
            return -1;
        }
        if (a.total_reports < b.total_reports) {
            return 1;
        }
        return 0;
    });

    return result.slice(0, 7);
};

/**
 * Selector para recuperar el informe de la tabla de grupos
 *
 * @param {*} state
 * @param {*} action
 */
export const getGroupsReport = (state) => {
    let data = [];

    const allGroups = selectAllGroups(state);
    const allAssessors = selectAllAssessors(state);
    const allSubjects = selectAllSubjects(state);
    const allCatalogSubjects = selectAllCatalogSubjects(state);
    const allProfessors = selectAllProfessors(state);

    for (const group of allGroups) {
        let AssessorName = 'Sin asesor';

        let assesor = null;

        if (group.assessor_id != 0 && group.assessor_id != null) {
            assesor = allAssessors.find(
                (item) => item.assessor_id == group.assessor_id
            );
            AssessorName = `${assesor.name}`;
        }

        let subjects = allSubjects.filter(
            (item) => item.group_id == group.group_id
        );

        subjects = subjects.map((subject) => {
            let newSubject = Object.assign({}, subject);

            let catalogSubject = allCatalogSubjects.find(
                (item) => item.catalog_subject_id == subject.catalog_subject_id
            );

            newSubject.catalog_subject = catalogSubject;

            let professor = null;

            if (subject.professor_id != 0 && subject.professor_id != null) {
                professor = allProfessors.find(
                    (item) => item.professor_id == subject.professor_id
                );
            }

            newSubject.professor = professor;

            return newSubject;
        });

        let aditionalData = {
            id: group.group_id,
            title: `${group.grade}°${group.group} ${getTurnLevel(group.turn)}`,
            levelName: getLevelName(group.level),
            assessor_id: AssessorName,
            special: group.group_type !== 1 ? 'Si' : 'No',
            payload: {
                assesor,
                subjects,
            },
        };

        data.push({ ...group, ...aditionalData });
    }

    return data;
};

/**
 * Selector para recuperar el informe de la tabla de materias
 *
 * @param {*} state
 */
export const getSubjectsReport = (state) => {
    let data = [];

    const allSubjects = selectAllSubjects(state);

    for (const subject of allSubjects) {
        data.push({ ...subject, ...getSubjectFormated(subject, state) });
    }

    return data;
};

/**
 * Obtenemos a un materia con formato
 *
 * @param {*} profesor
 * @param {*} state
 * @returns
 */
function getSubjectFormated(subject, state) {
    const allGroups = selectAllGroups(state);
    const allCatalogSubjects = selectAllCatalogSubjects(state);
    const allProfessors = selectAllProfessors(state);

    let professorName = 'Sin profesor';
    let professor = null;

    if (subject.professor_id != 0 && subject.professor_id != null) {
        professor = allProfessors.find(
            (item) => item.professor_id == subject.professor_id
        );
        professorName = `${professor?.name}` || 'No profesores';
    }

    let groupName = 'Sin grupo';
    let group = null;

    if (subject.group_id != 0 && subject.group_id != null) {
        group = allGroups.find((item) => item.group_id == subject.group_id);
        groupName = `${group.grade}°${group.group} ${getTurnLevel(group.turn)}`;
    }

    let catalogSubject = allCatalogSubjects.find(
        (item) => item.catalog_subject_id == subject.catalog_subject_id
    );

    let aditionalData = {
        title: catalogSubject?.title,
        level: getLevelName(catalogSubject?.level),
        turn_name: group ? getTurnLevel(group?.turn) : 'Sin turno',
        professor_name: professorName,
        group_name: groupName,
        payload: {
            group,
            professor,
            catalogSubject,
        },
    };

    return { ...subject, ...aditionalData };
}

/**
 * Selector para recuperar el informe de la tabla de profesores
 *
 * @param {*} state
 *
 * @returns
 */
export const getProfessorsReport = (state) => {
    let data = [];

    const allProfessors = selectAllProfessors(state);

    for (const profesor of allProfessors) {
        data.push({
            ...profesor,
            subjects: profesor.subjects,
            groups: profesor.groups,
            assists_list_percent: profesor.assists_list_percent,
            reports: profesor.reports,
            last_score_created: profesor.last_score_created,
        });
    }

    return data;
};
/**
 * Selector para recuperar el informe de la tabla de alumnos
 *
 * @param {*} state
 *
 * @returns
 */
export const getStudentsReport = (state) => {
    let data = [];

    let allStudents = selectAllStudents(state);

    for (const student of allStudents) {
        data.push(getStudentsFormated(student, state));
    }

    return data;
};

export const selectActiveStudents = (state) => {
    let data = [];

    let allStudents = selectAllStudents(state);

    let activeStudents = allStudents.filter(
        (student) => student.status === 1 && student.groups > 0
    );

    for (const student of activeStudents) {
        data.push(getStudentsFormated(student, state));
    }

    return data;
};

/**
 * Obtenemos a un alumno con formato
 *
 * @param {*} profesor
 * @param {*} state
 * @returns
 */
function getStudentsFormated(student, state) {
    let group = null;
    let groupName = 'Sin Grupo';

    if (student.group_id) {
        group = selectAllGroups(state).find(
            (item) => item.group_id == student.group_id
        );
        groupName = `${group.grade}°${group.group} ${getTurnLevel(group.turn)}`;
    }

    let aditionData = {
        group: group,
        groupName: groupName,
    };

    return { ...student, ...aditionData };
}

/**
 * Selectores para las entidades
 *
 * @param {*} state
 * @returns
 */
const groupsGlobalizedSelector = groupsAdapter.getSelectors(
    (state) => state.background.entities.groups
);
export const getGroups = (state) => groupsGlobalizedSelector.selectAll(state);
export const selectGroupsByLevel = (level) => (store) =>
    _.filter(selectAllGroups(store), { level });
export const selectAllGroups = (state) =>
    groupsGlobalizedSelector.selectAll(state);

const studentsGlobalizedSelector = studentsAdapter.getSelectors(
    (state) => state.background.entities.students
);
export const getStudents = (state) =>
    studentsGlobalizedSelector.selectAll(state);
export const selectAllStudents = (state) =>
    studentsGlobalizedSelector.selectAll(state);

const assessorsGlobalizedSelector = assessorsAdapter.getSelectors(
    (state) => state.background.entities.assessors
);
export const selectAllAssessors = (state) =>
    assessorsGlobalizedSelector.selectAll(state);

const subjectsGlobalizedSelector = subjectsAdapter.getSelectors(
    (state) => state.background.entities.subjects
);
export const selectAllSubjects = (state) =>
    subjectsGlobalizedSelector.selectAll(state);
export const getSubjects = (state) =>
    subjectsGlobalizedSelector.selectAll(state);

const catalogSubjectsGlobalizedSelector = catalogSubjectsAdapter.getSelectors(
    (state) => state.background.entities.catalogSubjects
);
export const selectAllCatalogSubjects = (state) =>
    catalogSubjectsGlobalizedSelector.selectAll(state);
export const selectEntitiesCatalogSubjects = (state) =>
    catalogSubjectsGlobalizedSelector.selectEntities(state);
export const getCatalogSubjects = (state) =>
    catalogSubjectsGlobalizedSelector.selectAll(state);

const professorsGlobalizedSelector = professorsAdapter.getSelectors(
    (state) => state.background.entities.professors
);
export const selectAllProfessors = (state) =>
    professorsGlobalizedSelector.selectAll(state);

const cylesGlobalizedSelector = cyclesAdapter.getSelectors(
    (state) => state.background.entities.cycles
);
export const getCycles = (state) => cylesGlobalizedSelector.selectAll(state);

const partialsGlobalizedSelector = partialsAdapter.getSelectors(
    (state) => state.background.entities.partials
);
export const getPartails = (state) =>
    partialsGlobalizedSelector.selectAll(state);

export const selectSchoolById = (id) => (state) =>
    state.background.entities.school;

/**
 * Selectores para la UI
 *
 * @param {*} state
 * @returns
 */
export const getCycleSelected = (state) => state.background.ui.cycleSelected;
export const getCurrentTab = (state) => state.background.ui.currentTab;

/**
 * Selectores para recuperar el grupo con la mejor
 * calificacion
 *
 * @param {*} state
 *
 * @returns
 */
export const getBestScoreGroupCard = (state) => {
    let groups = getGroupsReport(state);

    const bestScoreGroup = sortByFiled(groups, 'average_rating', 'desc');

    let groupData = {
        group_id: null,
        school_id: null,
        assessor_id: null,
        grade: '',
        group: '',
        turn: null,
        level: null,
        students: 0,
        special: 0,
        average_rating: 0,
        total_absence: 0,
        total_presence: 0,
        total_reports: 0,
        title: 'Sin grupo',
        levelName: '',
        payload: null,
    };

    ///// Grupo con mejor calificacion

    let bestScoreGroupData = { ...groupData };

    if (bestScoreGroup) {
        bestScoreGroupData = bestScoreGroup;
    }

    return bestScoreGroupData;
};

/**
 * Selectores para recuperar el grupo con la mejor
 * asistencia
 *
 * @param {*} state
 *
 * @returns
 */
export const getBestAssistsGroupCard = (state) => {
    let groups = getGroupsReport(state);

    const bestAssistsGroup = sortByFiled(groups, 'total_presence', 'desc');

    let groupData = {
        group_id: null,
        school_id: null,
        assessor_id: null,
        grade: '',
        group: '',
        turn: null,
        level: null,
        students: 0,
        special: 0,
        average_rating: 0,
        total_absence: 0,
        total_presence: 0,
        total_reports: 0,
        title: 'Sin grupo',
        levelName: '',
        payload: null,
    };

    let bestAssistsGroupData = { ...groupData };

    if (bestAssistsGroup) {
        bestAssistsGroupData = bestAssistsGroup;
    }

    return bestAssistsGroupData;
};

/**
 * Selectores para recuperar el grupo con la mayor
 * faltas
 *
 * @param {*} state
 *
 * @returns
 */
export const getWorstAbsenceGroupCard = (state) => {
    let groups = getGroupsReport(state);

    const worstAbsenceGroup = sortByFiled(groups, 'total_absence', 'desc');

    let groupData = {
        group_id: null,
        school_id: null,
        assessor_id: null,
        grade: '',
        group: '',
        turn: null,
        level: null,
        students: 0,
        special: 0,
        average_rating: 0,
        total_absence: 0,
        total_presence: 0,
        total_reports: 0,
        title: 'Sin grupo',
        levelName: '',
        payload: null,
    };

    let worstAbsenceGroupData = { ...groupData };

    if (worstAbsenceGroup) {
        worstAbsenceGroupData = worstAbsenceGroup;
    }

    return worstAbsenceGroupData;
};

/**
 * Selectores para recuperar el grupo con la mayor
 * reportes
 *
 * @param {*} state
 *
 * @returns
 */
export const getBestReportsGroupCard = (state) => {
    let groups = getGroupsReport(state);

    const bestReportsGroup = sortByFiled(groups, 'total_reports', 'desc');

    let groupData = {
        group_id: null,
        school_id: null,
        assessor_id: null,
        grade: '',
        group: '',
        turn: null,
        level: null,
        students: 0,
        special: 0,
        average_rating: 0,
        total_absence: 0,
        total_presence: 0,
        total_reports: 0,
        title: 'Sin grupo',
        levelName: '',
        payload: null,
    };

    let bestReportsGroupData = { ...groupData };

    if (bestReportsGroup) {
        bestReportsGroupData = bestReportsGroup;
    }

    return bestReportsGroupData;
};

/**
 * Selectores para recuperar la materia con la mayor
 * calificacion
 *
 * @param {*} state
 *
 * @returns
 */
export const getSestScoreSubjectCard = (state) => {
    let subjects = getSubjectsReport(state);

    const bestScoreSubject = sortByFiled(subjects, 'average_rating', 'desc');

    let SubjectData = {
        subject_id: null,
        catalog_subject_id: null,
        professor_id: null,
        group_id: null,
        average_rating: 0,
        total_absence: 0,
        total_presence: 0,
        special: 0,
        title: 'Sin materia',
        level: '',
        turn_name: '',
        professor_name: '',
        group_name: '',
        payload: null,
    };

    /// materia con mejor calificacion

    let bestScoreSubjectData = { ...SubjectData };

    if (bestScoreSubject) {
        bestScoreSubjectData = bestScoreSubject;
    }

    return bestScoreSubjectData;
};

/**
 * Selectores para recuperar la materia con la mayor
 * asistencia
 *
 * @param {*} state
 *
 * @returns
 */
export const getBestAssistsSubjectCard = (state) => {
    let subjects = getSubjectsReport(state);

    const bestAssistsSubject = sortByFiled(subjects, 'total_presence', 'desc');

    let SubjectData = {
        subject_id: null,
        catalog_subject_id: null,
        professor_id: null,
        group_id: null,
        average_rating: 0,
        total_absence: 0,
        total_presence: 0,
        special: 0,
        title: 'Sin materia',
        level: '',
        turn_name: '',
        professor_name: '',
        group_name: '',
        payload: null,
    };

    let bestAssistsSubjectData = { ...SubjectData };

    if (bestAssistsSubject) {
        bestAssistsSubjectData = bestAssistsSubject;
    }

    return bestAssistsSubjectData;
};

/**
 * Selectores para recuperar la materia con peor
 * asistencia
 *
 * @param {*} state
 *
 * @returns
 */
export const getWorstAbsenceSubjectCard = (state) => {
    let subjects = getSubjectsReport(state);

    const worstAbsenceSubject = sortByFiled(subjects, 'total_absence', 'desc');

    let SubjectData = {
        subject_id: null,
        catalog_subject_id: null,
        professor_id: null,
        group_id: null,
        average_rating: 0,
        total_absence: 0,
        total_presence: 0,
        special: 0,
        title: 'Sin materia',
        level: '',
        turn_name: '',
        professor_name: '',
        group_name: '',
        payload: null,
    };

    let worstAbsenceSubjectData = { ...SubjectData };

    if (worstAbsenceSubject) {
        worstAbsenceSubjectData = worstAbsenceSubject;
    }

    return worstAbsenceSubjectData;
};

/**
 * Selectores para recuperar el profesor que no ha nombrado asistencia
 *
 * @param {*} state
 *
 * @returns
 */
export const getLastAssitsListProfessorsCard = (state) => {
    const allProfessors = selectAllProfessors(state);

    /*
     * Filtrado para solo traer maestros con
     * activos y con al menos un grupo y una materia
     */
    const activeProfessors = allProfessors.filter(
        (professor) =>
            professor.status === 1 &&
            professor.groups > 0 &&
            professor.subjects > 0
    );
    const LastAssitsListProfessors = sortByFiled(
        activeProfessors,
        'assists_list_percent',
        'asc'
    );
    let profesorData = {
        professor_id: null,
        credential_id: null,
        school_id: null,
        enrollment: '',
        name: 'Sin profesor',
        last_name: '',
        second_last_name: '',
        email: '',
        url_photo_profile: null,
        cellphone: '',
        has_smartphone: 1,
        send_sms: 0,
        country_code: '',
        colony: '',
        inside_number: null,
        outside_number: '',
        street: '',
        zipcode: '',
        city_id: 0,
        status: 0,
        group_id: '',
        created_by: '',
        push_id: '',
        push_token: '',
        subjects: 0,
        groups: 0,
        assists_list_percent: 0,
        reports: 0,
        last_score_created: 'Sin registro',
        payload: null,
    };

    let lastAssitsListProfessorsData = { ...profesorData };

    if (LastAssitsListProfessors) {
        lastAssitsListProfessorsData = LastAssitsListProfessors;
    }

    return lastAssitsListProfessorsData;
};

/**
 * Selectores para recuperar el profesor en resago de calificaciones
 *
 * @param {*} state
 *
 * @returns
 */
export const getLastCalificationProfessorsCard = (state) => {
    const allProfessors = selectAllProfessors(state);
    /*
     * Filtrado para solo traer maestros con
     * activos y con al menos un grupo y una materia
     */
    const activeProfessors = allProfessors.filter(
        (professor) =>
            professor.status === 1 &&
            professor.groups > 0 &&
            professor.subjects > 0
    );
    const lastCalificationProfessors = sortByDate(
        activeProfessors,
        'last_score_created',
        'asc'
    )[0];

    let profesorData = {
        professor_id: null,
        credential_id: null,
        school_id: null,
        enrollment: '',
        name: 'Sin profesor',
        last_name: '',
        second_last_name: '',
        email: '',
        url_photo_profile: null,
        cellphone: '',
        has_smartphone: 1,
        send_sms: 0,
        country_code: '',
        colony: '',
        inside_number: null,
        outside_number: '',
        street: '',
        zipcode: '',
        city_id: 0,
        status: 0,
        group_id: '',
        created_by: '',
        push_id: '',
        push_token: '',
        subjects: 0,
        groups: 0,
        assists_list_percent: 0,
        reports: 0,
        last_score_created: 'Sin registro',
        payload: null,
    };

    let lastCalificationProfessorsCardData = { ...profesorData };

    if (lastCalificationProfessors) {
        lastCalificationProfessorsCardData = lastCalificationProfessors;
    }

    return lastCalificationProfessorsCardData;
};

export const getGenericStats = (state) => state.background.genericStats;

/**
 * Selectore para recuperar el alumnos con mejor calificacion
 * por nivel
 *
 * @param {*} levelID
 * @returns
 */
export const getBestScoreStudents = (levelID) => {
    return (state) => {
        let students = getAllStudentsFormated(state);

        let bestStudent = sortByFiled(students, 'average_rating', 'desc');

        if (levelID == -1) {
            return bestStudent;
        }

        let studentsByLevel = filterStundetByLevel(students, { key: levelID });

        if (studentsByLevel.length > 0) {
            bestStudent = sortByFiled(
                studentsByLevel,
                'average_rating',
                'desc'
            );
        }

        return bestStudent;
    };
};

/**
 * Selectore para recuperar el alumnos con peor asistencia
 * por nivel
 *
 * @param {*} levelID
 * @returns
 */
export const getWorstAbsenceStudents = (levelID) => {
    return (state) => {
        let students = getAllStudentsFormated(state);

        let worstStudent = sortByFiled(students, 'total_absence', 'desc');

        if (!levelID) {
            return worstStudent;
        }

        let studentsByLevel = filterStundetByLevel(students, { key: levelID });

        if (studentsByLevel.length > 0) {
            worstStudent = sortByFiled(
                studentsByLevel,
                'total_absence',
                'desc'
            );
        }

        return worstStudent;
    };
};

export const getGroupsReportBy = (groupID) => {
    return (state) => {
        let data = getGroupsReport(state).find(
            (item) => item.group_id == groupID
        );

        return data;
    };
};

export const getGroupsByLevel = (level) => {
    return (state) => {
        let data = getGroupsReport(state).filter((item) => item.level == level);

        return data;
    };
};

export const getSubjectReportBy = (subjectID) => {
    return (state) => {
        let data = getSubjectsReport(state).find(
            (item) => item.subject_id == subjectID
        );

        return data;
    };
};

export const getPartialsByLevel = (level) => {
    return (state) => {
        let data = state.background.partials.filter(
            (item) => item.level == level
        );

        return data;
    };
};

export const getSubjectReportByGroup = (groupId) => {
    return (state) => {
        let data = getSubjectsReport(state).filter(
            (item) => item.group_id == groupId
        );

        return data;
    };
};

export const getProfessorsReportByID = (professorId) => {
    return (state) => {
        let data = getProfessorsReport(state).find(
            (item) => item.professor_id == professorId
        );

        return data;
    };
};

export const getSubjectReportByProfessor = (professorId) => {
    return (state) => {
        let data = getSubjectsReport(state).filter(
            (item) => item.professor_id == professorId
        );

        return data;
    };
};

export const getStudentByID = (studentId) => {
    return (state) => {
        let data = getStudentsReport(state).find(
            (item) => item.student_id == studentId
        );

        return data;
    };
};

/**
 * Selectors que recupera niveles donde
 * se tiene grupo
 *
 * @param {*} studentId
 *
 * @returns
 */
export const selectLevelsAvaiables = (state) => {
    const allGroups = selectAllGroups(state);

    const levelsWithGroups = _.map(constants.levelsNames, (item) => {
        const size = _.filter(allGroups, { level: item.key }).length;

        return {
            ...item,
            size,
        };
    });

    return levelsWithGroups.filter((i) => i.size > 0);
};

/**
 * Funcion que permite dar formato a todas los alumnos
 *
 * @param {*} state
 * @returns
 */
function getAllStudentsFormated(state) {
    let data = [];

    const allStudents = selectAllStudents(state);

    for (const student of allStudents) {
        data.push(getStudentsFormated(student, state));
    }

    return data;
}

export default backgroundSlice.reducer;

////////////////////      TRUNKS ASUNCRONOS     ///////////////////////

/**
 * Recuperar todas las actividades
 */
export const fetchAllResources = createAsyncThunk(
    'background/getAll',
    async (data, thunkAPI) => {
        const { school_id, cycle } = data;
        let FeedbackService = new Feedback();

        let snaphot = null;

        if (cycle.cycle_id != -1) {
            snaphot = cycle.snaphot;
        }
        try {
            let cycles = await getResourcesPaginatedFromServer(
                'getCyclesBySchool',
                [school_id]
            );
            let students = await getResourcesPaginatedFromServer(
                'StudentsBySchool',
                [school_id],
                snaphot
            );
            let groups = await getResourcesPaginatedFromServer(
                'groupsBySchool',
                [school_id],
                snaphot
            );
            let subjects = await getResourcesPaginatedFromServer(
                'getSubjectsBySchool',
                [school_id],
                snaphot
            );
            let catalogoSubject = await getResourcesPaginatedFromServer(
                'getSubjectCatalogBySchool',
                [school_id],
                snaphot
            );
            let assessors = await getResourcesPaginatedFromServer(
                'getAssesorsBySchool',
                [school_id],
                snaphot
            );
            let professors = await getResourcesPaginatedFromServer(
                'professorsBySchoolDirector',
                [school_id],
                snaphot
            );
            let partials = await getResourcesPaginatedFromServer(
                'getPartials',
                [school_id]
            );

            let schoolRequest;

            if (snaphot) {
                schoolRequest = Connection.getSchoolByIdDirector(school_id, {
                    snaphot,
                });
            } else {
                schoolRequest = Connection.getSchoolByIdDirector(school_id);
            }

            let school = await schoolRequest.then(
                (response) => response.data.data
            );

            thunkAPI.dispatch(setSchool(school));
            thunkAPI.dispatch(setCycles(cycles));
            thunkAPI.dispatch(setCatalogSubjects(catalogoSubject));
            thunkAPI.dispatch(setPartials(partials));
            thunkAPI.dispatch(setProfessors(professors));
            thunkAPI.dispatch(setAssessors(assessors));

            thunkAPI.dispatch(
                setAllEntities({
                    subjects,
                    groups,
                    students,
                })
            );

            return { data: 'ok' };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = getState().background.server;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

/**
 * Recuperar recursos del servidor paginados
 *
 * @param {*} schoolId
 *
 * @returns
 */
async function getResourcesPaginatedFromServer(methd, params, snaphot = null) {
    let lastPage = 0;
    let currentPage = 0;

    let students = [];

    let parameter = {
        per_page: 50,
        page: currentPage,
        //filters: { status: 1 },
    };

    if (snaphot) {
        parameter = { ...parameter, snaphot };
    }

    do {
        currentPage++;

        parameter.page = currentPage;

        let studentsResponse = await Connection[methd](
            ...params,
            parameter
        ).then((res) => res.data);

        let studentsPaginated = studentsResponse.data;
        let meta = studentsResponse.meta;

        lastPage = meta.last_page;

        students = students.concat(studentsPaginated);
    } while (currentPage < lastPage);

    return students;
}

/**
 * Ordena la lista enviada por parametro usando un campo
 *
 * @param {*} groups
 * @param {*} field
 * @returns
 */
function sortByFiled(groups, field, orderType = 'ACS') {
    groups.sort((a, b) => {
        if (orderType == 'desc') {
            if (a[field] > b[field]) {
                return -1;
            }
            if (a[field] < b[field]) {
                return 1;
            }
        } else {
            if (a[field] > b[field]) {
                return 1;
            }
            if (a[field] < b[field]) {
                return -1;
            }
        }
        return 0;
    });

    return groups[0];
}

/**
 * Ordena la lista enviada por parametro usando un campo
 *
 * @param {*} groups
 * @param {*} field
 * @returns
 */
function sortByDate(list, field, orderType = 'ACS') {
    list.sort((itemA, itemB) => {
        let a = DateTime.fromSQL(itemA[field]);
        let b = DateTime.fromSQL(itemB[field]);

        if (orderType == 'desc') {
            if (a > b) {
                return -1;
            }
            if (a < b) {
                return 1;
            }
        } else {
            if (a > b) {
                return 1;
            }
            if (a < b) {
                return -1;
            }
        }
        return 0;
    });

    return list;
}

function filterStundetByLevel(students, level) {
    const studentsByLevel = students.filter((item) => {
        if (!item.group) {
            return false;
        }

        return item.group.level == level.key;
    });

    return studentsByLevel;
}
