import { Skeleton } from '@mui/material';
import React from 'react';

export const SkeletonFilter = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                height={200}
                sx={{ m: 2, p: 2 }}
                animation="wave"
            />
        </>
    );
};

export const SkeletonDataGrid = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                height={600}
                sx={{ m: 2, p: 2 }}
                animation="wave"
            />
        </>
    );
};
