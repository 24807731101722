import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Services from '../service/Connection';
import { useAuth, useFeedback } from '../hooks';
import { useSelector } from 'react-redux';
import LockIcon from '@mui/icons-material/Lock';
import Feedback from '../service/Feedback';
import { selectThemeBySchool } from '../store/slices/entities/settings';

/**
 * Componente encargado de la comparacion de
 * contraseñas para proceso delicados
 */
const RequestPermission = ({
    open,
    title,
    content,
    OnPermissionGrantedResolved,
}) => {
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    let FeedbackService = new Feedback();
    const feedbackApp = useFeedback();

    ////////////////// SHARED STATE ///////////////

    const { colorTitleBar, fontColor } = useSelector(
        selectThemeBySchool(schoolId)
    );

    ////////////////// LOCAL STATE ////////////////

    const [password, setPassword] = useState('');
    const [statusOperation, setStatusOperation] = useState('idle');

    /////////////////// ACTIONS //////////////////////

    useEffect(() => {
        if (!open) {
            setPassword('');
        }
    }, [open]);

    /**
     * Escucha eventos del teclado en input de contraseña
     */
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onCheckPassword();
        }
    };

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     */
    const onInputChange = (event) => {
        const value = event.target.value;

        setPassword(value);
    };

    /**
     * Solicitud cancelada por el usuario
     */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }

        OnPermissionGrantedResolved('user-cancel');
    };

    /**
     * Verifica que la contraseña enviada sea igual que en el backend
     */
    const onCheckPassword = () => {
        setStatusOperation('pending');

        Services.grantPermission({ password })
            .then((i) => {
                setStatusOperation('fulfilled');
                OnPermissionGrantedResolved('allow');

                feedbackApp.showFeedback({
                    title: 'Permisos concedidos',
                });
            })
            .catch((err) => {
                setStatusOperation('rejected');
                let feedbackError = FeedbackService.getMessage(err);

                feedbackApp.showFeedback({
                    title: feedbackError.title,
                });
            });
    };

    //////////////////////// VALIDATIONS ////////////////////

    const passwordValid = () => {
        return {
            invalid: password == '',
            message: 'Campo requerido',
        };
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            disableEscapeKeyDown={statusOperation == 'pending'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle
                id="alert-dialog-title"
                style={{
                    backgroundColor: colorTitleBar.value,
                    color: fontColor.value,
                }}>
                <Typography variant="h6" component="div">
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ mt: 2 }}>
                    {content}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Contraseña"
                    fullWidth
                    variant="outlined"
                    password
                    type="password"
                    value={password}
                    onChange={onInputChange}
                    error={passwordValid().invalid}
                    helperText={
                        passwordValid().invalid
                            ? passwordValid().message
                            : false
                    }
                    onKeyDown={handleKeyDown}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={onCheckPassword}
                    loading={statusOperation == 'pending'}
                    loadingPosition="start"
                    startIcon={<LockIcon />}
                    variant="contained"
                    disabled={passwordValid().invalid}>
                    Solicitar
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="text"
                    onClick={handleClose}
                    disabled={statusOperation == 'pending'}>
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RequestPermission;
