import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography,
    Skeleton,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useAuth } from '../../../hooks';
import useFeedback from '../../../hooks/useFeedback';
import { useDispatch, useSelector } from 'react-redux';
import { selectThemeBySchool } from '../../../store/slices/entities/settings';
import {
    createModule,
    selectStatusOperation,
} from '../../../store/slices/groupsUI/serverSlice';
import { Transfer } from 'antd';
import { selectSubjectcatalogsByLevel } from '../../../store/slices/entities/subjectcatalogs';
import Services from '../../../service/Connection';
import { Error } from '../../../components/Feedback';
import * as _ from 'lodash';
import { getLevelName, getTurnLevel } from '../../../libs/utils';

/**
 * Modal para crear un grupo
 */
const AddNewModuleModal = ({ open, setOpen, group = null }) => { 
    //////////// HOOKS ////////////////////

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    //////////// SHARED STATE  /////////////

    const subjects = useSelector(selectSubjectcatalogsByLevel(group?.level));

    const { colorTitleBar, fontColor } = useSelector(
        selectThemeBySchool(schoolId)
    );

    const saveStatus = useSelector(selectStatusOperation);

    //////////// LOCAL STATE ////////////////////

    const [subjectTargetKeys, setSubjectTargetKeys] = useState([]);
    const [subjectSelectedKeys, setSubjectSelectedKeys] = useState([]);

    const [loadingStatus, setLoadingStatus] = useState('idle');

    const [moduleName, setModuleName] = useState('');
    const [targetKeys, setTargetKeys] = useState([]);

    const [mainSubjects, setMainSubjects] = useState([]);
    const [moduleSubjects, setModulSubjects] = useState([]);

    //////////// DATA PROCESSING //////////////////////

    const mainSubjectsIds = _.map(mainSubjects, 'catalog_subject_id');
    const moduleSubjectIds = _.map(moduleSubjects, 'catalog_subject_id');

    const subjectsAvailable = subjects.filter(
        ({ catalog_subject_id }) =>
            !(
                mainSubjectsIds.includes(catalog_subject_id) ||
                moduleSubjectIds.includes(catalog_subject_id)
            )
    );

    /////////////////// ACTIONS /////////////////

    const isSaveDisabled = !moduleName || subjectTargetKeys.length < 2;

    const saveModule = async () => {
        dispatch(
            createModule({
                groupId: group.group_id,
                data: [
                    {
                        name: moduleName,
                        subjects: targetKeys,
                    },
                ],
                schoolId: schoolId,
            })
        )
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Módulo creado',
                });

                handleClose();
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    //////////////////// Handlers ///////////////////////////

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false);
        resetForm();
    };

    const reload = () => {
        startRecoverDataFlow();
    };

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        if (name == 'moduleName') {
            setModuleName(value);
        }
    };

    const loadUi = async () => {
        const mainSubjects = await Services.getSubjectsByGroup(
            group.group_id
        ).then((res) => res.data.data);

        const modules = await Services.getModulesByGroup(group.group_id).then(
            (res) => res.data.data
        );

        let modulesSubjects = [];

        for (const module of modules) {
            const subjects = await Services.getSubjectsByModule(
                module.group_id
            ).then((res) => res.data.data);

            modulesSubjects = [...modulesSubjects, ...subjects];
        }

        return {
            mainSubjects,
            modulesSubjects,
            modules,
        };
    };

    const startRecoverDataFlow = () => {
        setLoadingStatus('pending');

        loadUi()
            .then((data) => {
                setMainSubjects(data.mainSubjects);

                setModulSubjects(data.modulesSubjects);

                setLoadingStatus('fullfiled');
            })
            .catch((error) => {
                setLoadingStatus('rejected');

                feedbackApp.showFeedback({
                    title: 'Estamos teniendo problemas',
                });
            });
    };

    const resetForm = () => {
        setModuleName('');
        setSubjectTargetKeys([]);
        setSubjectSelectedKeys([]);
    };

    useEffect(() => {
        if (open) {
            startRecoverDataFlow();
        }
    }, [open]);

    ///////////////////////// VALIDATION ///////////////////////////

    const moduleNameValid = () => {
        return {
            invalid: moduleName === '',
            message: 'Campo requerido, Máximo 50 caracteres',
        };
    };

    ///////////////////////// TRANFER ///////////////////////////

    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setSubjectTargetKeys(nextTargetKeys);
        setTargetKeys(nextTargetKeys);
    };

    /**
     * Filtro para la busqueda
     */

    const removeAccents = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    const filterOption = (inputValue, option) => {
        const search = removeAccents(
            inputValue.toLowerCase().replace(/\s/g, '')
        );

        const titleNoSpace = removeAccents(
            `${option.folio} ${option.title} ${getLevelName(option.level)}`
                .toLowerCase()
                .replace(/\s/g, '')
        );
        const titleMatch = titleNoSpace.includes(search);

        return titleMatch;
    };

    //////////////////////// COMPONENTS ////////////////////////////

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="md"
            disableEscapeKeyDown={saveStatus === 'pending'}
            onClose={handleClose}>
            <DialogTitle
                style={{
                    backgroundColor: colorTitleBar.value,
                    color: fontColor.value,
                }}>
                <Typography>Agregar nuevo módulo</Typography>
                <span style={{ fontSize: '15px', color: '#fff' }}>
                    Grupo {group?.grade}° {group?.group}{' '}
                    {getTurnLevel(group?.turn)} {getLevelName(group?.level)}
                </span>
            </DialogTitle>
            <DialogContent>
                {loadingStatus === 'fullfiled' && (
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        sx={{ mt: 2 }}>
                        <TextField
                            label="Nombre del módulo"
                            name="moduleName"
                            variant="outlined"
                            value={moduleName}
                            onChange={onInputChange}
                            style={{ width: '80%', marginBottom: '10px' }}
                            error={moduleNameValid().invalid}
                            helperText={
                                moduleNameValid().invalid
                                    ? moduleNameValid().message
                                    : false
                            }
                        />

                        <Transfer
                            showSearch
                            listStyle={{
                                width: 300,
                                height: 300,
                            }}
                            dataSource={subjectsAvailable.map((i) => {
                                return {
                                    ...i,
                                    key: i.catalog_subject_id,
                                };
                            })}
                            locale={{
                                notFoundContent: [
                                    'Sin Materias',
                                    'Sin Materias',
                                ],
                                searchPlaceholder: 'Buscar aquí',
                                itemUnit: 'Materias',
                                itemsUnit: 'Materias',
                            }}
                            filterOption={filterOption}
                            targetKeys={subjectTargetKeys}
                            selectedKeys={subjectSelectedKeys}
                            onChange={onChange}
                            onSelectChange={(
                                sourceSelectedKeys,
                                targetSelectedKeys
                            ) => {
                                setSubjectSelectedKeys([
                                    ...sourceSelectedKeys,
                                    ...targetSelectedKeys,
                                ]);
                            }}
                            render={(item) => {
                                return (
                                    item.folio +
                                    ' - ' +
                                    item.title +
                                    ' - ' +
                                    getLevelName(item.level)
                                );
                            }}
                        />
                    </Stack>
                )}

                {loadingStatus === 'pending' && (
                    <Stack spacing={2} sx={{ padding: 2 }}>
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton variant="rectangular" height={30} />
                    </Stack>
                )}

                {loadingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    color="primary"
                    onClick={saveModule}
                    loading={saveStatus === 'pending'}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={isSaveDisabled}>
                    Finalizar
                </LoadingButton>
                <Button
                    onClick={handleClose}
                    disabled={saveStatus === 'pending'}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default AddNewModuleModal;
