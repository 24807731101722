import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const noticesAdapter = createEntityAdapter({
    selectId: (notice) => notice.notice_id,
    sortComparer: (a, b) => a.notice_id - b.notice_id,
});

/**
 * Slice para los parciales
 */
export const noticesSlice = createSlice({
    name: 'notices',
    initialState: noticesAdapter.getInitialState(),
    reducers: {
        /**
         * Si la materia no se encuentra en las entidades es agregados
         *
         * @param {*} state
         * @param {*} action
         */
        addOneNotice: noticesAdapter.addOne,
        /**
         * Agrega muchos elementos, si esque estos aun no se encuentran agregados
         *
         * @param {*} state
         * @param {*} action
         */
        addManyNotices: noticesAdapter.addMany,
        /**
         * Remplazar todos los parciales
         *
         * @param {*} state
         * @param {*} action
         */
        setNotices: noticesAdapter.setAll,
        updateNotice: noticesAdapter.updateOne,
        /**
         * Elimina un parcial de la coleccion
         * @param {*} state
         * @param {*} action
         */
        removeOneNotice: noticesAdapter.removeOne,
        /**
         * Si el elemento existe realziara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         *
         * @param {*} state
         * @param {*} action
         */
        upsertOneNotice: noticesAdapter.upsertOne,
        /**
         * Si el elemento existe realizara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         *
         * @param {*} state
         * @param {*} action
         */
        upsertManyNotices: noticesAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return noticesAdapter.getInitialState();
        });
    },
});

/**
 * Acciones generadas por la librerias
 */
export const {
    setNotices,
    addManyNotices,
    removeOneNotice,
    updateNotice,
    addOneNotice,
    upsertOneNotice,
    upsertManyNotices,
} = noticesSlice.actions;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = noticesAdapter.getSelectors(
    (state) => state.entities.notices
);

/**
 * Selector para recuperar todos los parciales de la coleccion
 *
 * @param {*} state
 * @returns
 */
export const selectNotices = (state) =>
    globalizedSelectors.selectEntities(state);

/**
 * Selector para recuperar parciales de una escuela especifica
 *
 * @param {*} schoolId
 * @returns
 */
export const selectNoticesDataById = (subjectId) => {
    return (state) => {
        let notice = state.entities.subjects.byId[subjectId];

        if (!notice) {
            return null;
        }

        return {
            ...notice,
        };
    };
};

export default noticesSlice.reducer;
