import React, { useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

///////////////// VIEWS //////////////////////

import Alumnos from './../views/Alumnos/Alumnos';
import AlumnosGroup from './../views/AlumnosGroup/AlumnosGroup';
import Groups from './../views/Groups/Groups';
import Configuraciones from './../views/Configuraciones/Configuraciones';
import Avisos from './../views/Avisos/Avisos';
import Materias from './../views/Materias/Materias';
import Usuarios from './../views/Usuarios/Usuarios';
import Scores from './../views/Scores';
import ScoresGroups from './../views/Scores/pages/Groups';
import AvisoPrivacidad from './../views/AvisoPrivacidad/AvisoPrivacidad';
import TerminosCondiciones from './../views/TerminosCondiciones';
import ScoreStudents from './../views/Scores/pages/Students';
import DirectorHome from './../views/Director';
import Perfil from './../views/Perfil';
import Calendar from './../views/Calendario';
import ParentHome from './../views/ParentHome';
import RecoverAccount from './../views/RecuperarCuenta/RecoverAccount';
import ResetPassword from './../views/RecuperarCuenta/ResetPassword';
import Testing from './../views/Testing';
import Supervisiones from './../views/Supervisiones';
import Detalles from './../views/Detalles';

import Login from '../public/pages/Login';
import Funcionalidades from '../public/pages/functionalities';
import Contacto from '../public/pages/Contact';
import Beneficios from '../public/pages/Benefits';
import NewRecoverPassword from '../public/pages/Recovery';
import NewInicio from '../public/pages/Home';

/////////////// COMPONENTS ////////////////////

import Layout from './Layout/Layout';
import { AuthProvider } from './../providers';
import PrivateRoute from './PrivateRoute';
import FeedbackProvider from '../providers/FeedbackProvider';
import WorkingMiddleware from './WorkingMiddleware';
import Working from '../views/Working';
import ScoreEdit from '../moduleProfessors/pages/ScoreEdit';
import JustifyAssistsPage from '../moduleProfessors/pages/JustifyAssistsPage';
import AssistanceList from '../moduleProfessors/pages/AssistanceList';
import MakeReport from '../moduleProfessors/pages/MakeReport';
import Home from '../moduleProfessors/pages/Home';
import HomeProfessors from '../Professors/pages/Home/HomeProfessors';
import ClassroomSubjectAssistance from '../Professors/pages/ClassroomSubjects/AssistanceList/ClassroomSubjectAssistance';
import ClassroomSubjectScores from '../Professors/pages/ClassroomSubjects/Scores/ClassroomSubjectScores';
import ClassroomSubjectsJustify from '../Professors/pages/ClassroomSubjects/JustifyAssists/ClassroomSubjectsJustify';
import ClassroomGroupAssistance from '../Professors/pages/ClassroomGroups/AssitanceList/ClassroomGroupAssistance';
import ClassroomGroupScores from '../Professors/pages/ClassroomGroups/Scores/ClassroomGroupScores';
import ClassroomGroupJustify from '../Professors/pages/ClassroomGroups/JustifyAssists/ClassroomGroupJustify';
import ReportsHome from '../moduleReports/pages/Home';
import ReportsGroupsDetails from '../moduleReports/pages/GroupsDetails';
import ReportsStudentsDetails from '../moduleReports/pages/StudentsDetails';
import FeatureFlags from '../../src/service/FeatureFlags';
import PaymentsTabs from '../PaymentModule/components/tabs';
import ThemeProviderWrapper from '../theme/reverseTokens';
import NoticesHome from '../Notices/pages/Home/NoticesHome';
import DirectorNewHome from '../moduleDirector/pages/Home';
import DirectorGroups from '../moduleDirector/pages/Groups';
import DirectorSubjects from '../moduleDirector/pages/Subjects';
import DirectorProfessors from '../moduleDirector/pages/Professors';
import DirectorStudents from '../moduleDirector/pages/Students';
import DashboardPayments from '../PaymentModule/pages/Dashboard';
import Surcharges from '../PaymentModule/pages/surcharges';
import Income from '../PaymentModule/pages/Income';
import Discounts from '../PaymentModule/pages/discounts';
import Concepts from '../PaymentModule/pages/Concepts';
import Agreements from '../PaymentModule/pages/Agreements';
import Regularizations from '../Regularizations/pages/Regularizations';

/**
 * Componente de mantiene todo el arbol del dashboard
 *
 * @returns
 */
function Dashboard() {
    const featureFlagNewDirectors =
        FeatureFlags.isFeatureFlagActive('NEW_DIRECTORS');
    const featureFlagPaymentsModule = FeatureFlags.isFeatureFlagActive(
        'NEW_PAYMENTS_MODULE'
    );
    const featureFlagNewIndex = FeatureFlags.isFeatureFlagActive('NEW_INDEX');
    const featureFlagProfessors = FeatureFlags.isFeatureFlagActive(
        'NEW_PROFESSOR_MODULE'
    );

    const featureFlagNotices =
        FeatureFlags.isFeatureFlagActive('NEW_NOTICES_MODULE');

    const featureFlagRegularizationsModule = FeatureFlags.isFeatureFlagActive(
        'NEW_REGULARIZATIONS_MODULE'
    );

    // const mode = useSelector((state) => state.entities.settings.mode);
    // const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return (
        <div className="App">
            <Router>
                <AuthProvider>
                    <FeedbackProvider>
                        <Switch>
                            <Route path="/Beneficios">
                                <Beneficios></Beneficios>
                            </Route>
                            <Route path="/Funcionalidades">
                                <Funcionalidades></Funcionalidades>
                            </Route>
                            <Route path="/Contacto">
                                <Contacto></Contacto>
                            </Route>
                            <Route path="/login">
                                <Login></Login>
                            </Route>
                            <Route path="/NewRecoverPassword">
                                <NewRecoverPassword></NewRecoverPassword>
                            </Route>
                            <Route exact path="/">
                                <NewInicio></NewInicio>
                            </Route>
                            <Route path="/aviso-privacidad">
                                <AvisoPrivacidad></AvisoPrivacidad>
                            </Route>
                            <Route path="/TerminosCondiciones">
                                <TerminosCondiciones></TerminosCondiciones>
                            </Route>
                            <Route path="/recuperar_cuenta">
                                <RecoverAccount></RecoverAccount>
                            </Route>
                            <Route path="/restablecer_cuenta">
                                <ResetPassword></ResetPassword>
                            </Route>
                            <Route path="/working">
                                <Working />
                            </Route>

                            <WorkingMiddleware>
                                <Layout>
                                    <PrivateRoute path="/configuracion">
                                        <Configuraciones></Configuraciones>
                                    </PrivateRoute>
                                    <PrivateRoute path="/usuarios">
                                        <Usuarios></Usuarios>
                                    </PrivateRoute>
                                    <PrivateRoute path="/alumnos">
                                        <Alumnos></Alumnos>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/alumnos-grupo/:id">
                                        <AlumnosGroup></AlumnosGroup>
                                    </PrivateRoute>
                                    <PrivateRoute path="/grupos">
                                        <Groups></Groups>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/reportes">
                                        <ReportsHome></ReportsHome>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/reportes/:id">
                                        <ReportsGroupsDetails></ReportsGroupsDetails>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/reportes/:groupId/alumno/:id">
                                        <ReportsStudentsDetails></ReportsStudentsDetails>
                                    </PrivateRoute>
                                    <PrivateRoute path="/avisos">
                                        {featureFlagNotices ? (
                                            <NoticesHome />
                                        ) : (
                                            <Avisos />
                                        )}
                                    </PrivateRoute>
                                    <PrivateRoute path="/padres">
                                        <ParentHome />
                                    </PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/calificaciones/:groupId/alumno/:id">
                                        <ScoreStudents></ScoreStudents>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/calificaciones/:id">
                                        <ScoresGroups></ScoresGroups>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/calificaciones">
                                        <Scores></Scores>
                                    </PrivateRoute>

                                    <PrivateRoute path="/materias">
                                        <Materias></Materias>
                                    </PrivateRoute>
                                    {/* <PrivateRoute path="/director">
                                        <DirectorHome />
                                    </PrivateRoute> */}
                                    <PrivateRoute path="/perfil">
                                        <Perfil></Perfil>
                                    </PrivateRoute>
                                    <PrivateRoute path="/calendario">
                                        <Calendar></Calendar>
                                    </PrivateRoute>
                                    <PrivateRoute path="/inicio-maestros/reportar/:id/alumno/:studentId">
                                        <MakeReport />
                                    </PrivateRoute>
                                    <PrivateRoute path="/inicio-maestros/lista-asistencia/:id">
                                        <AssistanceList />
                                    </PrivateRoute>
                                    <PrivateRoute path="/inicio-maestros/calificaciones/:id">
                                        <ScoreEdit />
                                    </PrivateRoute>
                                    <PrivateRoute path="/inicio-maestros/justify-assists-page/:id">
                                        <JustifyAssistsPage />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/inicio-maestros">
                                        {featureFlagProfessors ? (
                                            <HomeProfessors />
                                        ) : (
                                            <Home />
                                        )}
                                    </PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/inicio-maestros/classroom-subject/:id">
                                        <ClassroomSubjectAssistance />
                                    </PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/inicio-maestros/classroom-subject/:id/scores/:subjectId">
                                        <ClassroomSubjectScores />
                                    </PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/inicio-maestros/classroom-subject/:id/justify/:subjectId">
                                        <ClassroomSubjectsJustify />
                                    </PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/inicio-maestros/classroom-group/:id">
                                        <ClassroomGroupAssistance />
                                    </PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/inicio-maestros/classroom-group/:id/scores/:subjectId">
                                        <ClassroomGroupScores />
                                    </PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/inicio-maestros/classroom-group/:id/justify">
                                        <ClassroomGroupJustify />
                                    </PrivateRoute>
                                    <PrivateRoute path="/testing">
                                        <Testing />
                                    </PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/Supervisiones/Detalles/:id">
                                        <Detalles />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/Supervisiones">
                                        <Supervisiones />
                                    </PrivateRoute>
                                    {featureFlagPaymentsModule && (
                                        <ThemeProviderWrapper>
                                            <PrivateRoute
                                                exact
                                                path="/Finanzas">
                                                <DashboardPayments />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                exact
                                                path="/Finanzas/inicio">
                                                <DashboardPayments />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                exact
                                                path="/Finanzas/convenios">
                                                <Agreements />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                exact
                                                path="/Finanzas/conceptos">
                                                <Concepts />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                exact
                                                path="/Finanzas/descuentos">
                                                <Discounts />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                exact
                                                path="/Finanzas/pagos">
                                                <Income />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                exact
                                                path="/Finanzas/recargos">
                                                <Surcharges />
                                            </PrivateRoute>
                                        </ThemeProviderWrapper>
                                    )}
                                    <>
                                        <PrivateRoute
                                            exact
                                            path="/NewDirector/inicio">
                                            <DirectorNewHome />
                                        </PrivateRoute>
                                        <PrivateRoute
                                            exact
                                            path="/NewDirector/groups">
                                            <DirectorGroups />
                                        </PrivateRoute>
                                        <PrivateRoute
                                            exact
                                            path="/NewDirector/subjects">
                                            <DirectorSubjects />
                                        </PrivateRoute>
                                        <PrivateRoute
                                            exact
                                            path="/NewDirector/professors">
                                            <DirectorProfessors />
                                        </PrivateRoute>
                                        <PrivateRoute
                                            exact
                                            path="/NewDirector/students">
                                            <DirectorStudents />
                                        </PrivateRoute>

                                        {featureFlagRegularizationsModule && (
                                            <PrivateRoute
                                                exact
                                                path="/regularizaciones">
                                                <Regularizations />
                                            </PrivateRoute>
                                        )}
                                    </>
                                </Layout>
                            </WorkingMiddleware>
                        </Switch>
                    </FeedbackProvider>
                </AuthProvider>
            </Router>
        </div>
    );
}

export default Dashboard;
