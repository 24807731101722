import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const agreementsAdapter = createEntityAdapter({
    selectId: (agreement) => agreement.agreement_id,
    sortComparer: (a, b) => a.agreement_id - b.agreement_id,
});

/**
 * Slice para los acuerdos
 */
export const AgreementSlice = createSlice({
    name: 'agreements',
    initialState: agreementsAdapter.getInitialState(),
    reducers: {
        /**
         * Reemplazar todos los acuerdos
         *
         * @param {*} state
         * @param {*} action
         */
        setAllAgreements: agreementsAdapter.setAll,
        /**
         * Agrega acuerdos a la colección
         *
         * @param {*} state
         * @param {*} action
         */
        addManyAgreements: agreementsAdapter.addMany,
        /**
         * Agrega un acuerdo a la colección
         *
         * @param {*} state
         * @param {*} action
         */
        addOneAgreement: agreementsAdapter.addOne,
        /**
         * Elimina un acuerdo de la colección
         * @param {*} state
         * @param {*} action
         */
        removeOneAgreement: agreementsAdapter.removeOne,
        /**
         * Si el elemento existe realizará una actualización superficial
         * y los campos serán fusionados, si el elemento no está presente este
         * será agregado a las entidades
         *
         * @param {*} state
         * @param {*} action
         */
        upsertOneAgreement: agreementsAdapter.upsertOne,
        /**
         * Si el elemento existe realizará una actualización superficial
         * y los campos serán fusionados, si el elemento no está presente este
         * será agregado a las entidades
         *
         * @param {*} state
         * @param {*} action
         */
        upsertManyAgreements: agreementsAdapter.upsertMany,
        updateManyAgreements: agreementsAdapter.updateMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return agreementsAdapter.getInitialState();
        });
    },
});

/**
 * Acciones generadas por la librería
 */
export const {
    addManyAgreements,
    removeOneAgreement,
    upsertOneAgreement,
    addOneAgreement,
    upsertManyAgreements,
    setAllAgreements,
    updateManyAgreements,
} = AgreementSlice.actions;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = agreementsAdapter.getSelectors(
    (state) => state.entities.agreements
);

export default AgreementSlice.reducer;
