import { Card, CardContent, CardHeader, Divider, Grid, Stack } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../hooks";
import useFeedback from "../../../hooks/useFeedback";
import { selectSettingBySchoolId } from "../../../store/slices/entities/settings";
import InputChange from "./InputChange"
import { saveSetting, selectStatusOperation } from '../../../store/slices/settingsUI';

/**
 * Tarjeta que contiene las configuraciones de notificaciones
 * 
 * @returns 
 */
const NotificationsSettingsCard = () => {

    const feedbackApp = useFeedback()
    const dispatch = useDispatch();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    ///////////// SHARE STATE ////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))
    const statusOperation = useSelector(selectStatusOperation)

    //////////// LOCAL STATE ////////////

    /**
     * Estado de las configuraciones para las notificaciones
     */
    let filesReportLimiteActive = config.find(res => res.key === 'files-report-limite-active')
    let calificationMinimum = config.find(res => res.key === 'calification-minimum')
    let calificationMinimumPositive = config.find(res => res.key === 'calification-minimum-positive')
    let remainingDays = config.find(res => res.key === 'days-startend-campaign-scores')
    let recoveryDays = config.find(res => res.key === 'recovery-days')

    //////////// ACTIONS /////////////

    /**
     * Funcion para actualizar las configuraciones
     * 
     * @param string value
     * @param string settingKey
     */
    const onUpdateInputSetting = (value, settingKey) => {
        let setting = config.find(res => res.key === settingKey)

        dispatch(saveSetting({ settingId: setting.configuration_id, value }))
            .unwrap()
            .then((res) => {
                feedbackApp.showFeedback({
                    title: 'Configuración actualizada correctamente',
                })
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    return (
        <Card
            sx={{
                height: 376,
            }}
        >
            <CardHeader
                title="Ajustes"
                titleTypographyProps={{
                    sx: {
                        fontSize: 18
                    }
                }}
                sx={{
                    paddingBottom: 1
                }}
            />
            <CardContent>


                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <InputChange
                            settingKey={'files-report-limite-active'}
                            value={filesReportLimiteActive?.value}
                            onChangeSetting={onUpdateInputSetting}
                            inputProps={{
                                id: "reporte",
                                name: "reporte",
                                label: "Notificación de reportes",
                                variant: "outlined",
                            }}
                            status={statusOperation}
                            tooltip={filesReportLimiteActive?.description}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputChange
                            settingKey={'calification-minimum'}
                            value={calificationMinimum?.value}
                            onChangeSetting={onUpdateInputSetting}
                            inputProps={{
                                id: "calificacionMin",
                                name: "calificacionMin",
                                label: "Calificación aprobatoria",
                                variant: "outlined",
                            }}
                            status={statusOperation}
                            tooltip={calificationMinimum?.description}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputChange
                            settingKey={'calification-minimum-positive'}
                            value={calificationMinimumPositive?.value}
                            onChangeSetting={onUpdateInputSetting}
                            inputProps={{
                                id: "calificacionMax",
                                name: "calificacionMax",
                                label: "Calificación de excelencia",
                                variant: "outlined",
                            }}
                            status={statusOperation}
                            tooltip={calificationMinimumPositive?.description}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputChange
                            settingKey={'days-startend-campaign-scores'}
                            value={remainingDays?.value}
                            onChangeSetting={onUpdateInputSetting}
                            inputProps={{
                                id: "reminder",
                                name: "reminder",
                                label: "Aviso previo a captura de calificaciones",
                                variant: "outlined",
                            }}
                            status={statusOperation}
                            tooltip={remainingDays?.description}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputChange
                            settingKey={'recovery-days'}
                            value={recoveryDays?.value}
                            onChangeSetting={onUpdateInputSetting}
                            inputProps={{
                                id: "recoveryDays",
                                name: "recoveryDays",
                                label: "Días adicionales de recuperación ",
                                variant: "outlined",
                            }}
                            status={statusOperation}
                            tooltip={recoveryDays?.description}
                        />
                    </Grid>
                </Grid>

            </CardContent>
        </Card >
    )
}

export default NotificationsSettingsCard