import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import {
    selectAllUsers,
    upsertManyUsers,
} from '../../../store/slices/entities/users';
import { selectAllNoticesItems } from '../home/itemsSlice';
import { selectSelectedNoticeUI } from '../home/uiSlice';
import { selectNoticeDetails } from './selectors';
import Feedback from '../../../service/Feedback';
import Connection from '../../../service/Connection';
import { PROFESSORS_SUBJECT_EXPIRE_TIME } from '../../../service/const';

let emptyState = [];

export const noticeDetails = createSlice({
    name: 'noticeDetails',
    initialState: emptyState,
    reducers: {
        invalidateSent: (state, { payload }) => {
            state[payload].operations.sent.didInvalidate = true;
        },
        invalidateNoticed: (state, { payload }) => {
            state[payload].operations.notcied.didInvalidate = true;
        },
        invalidateRead: (state, { payload }) => {
            state[payload].operations.read.didInvalidate = true;
        },
        addItem: (state, action) => {
            return {
                ...state,
                ...action.payload.reduce((accumulator, item) => {
                    accumulator[item.id] = item;
                    return accumulator;
                }, {}),
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        //Recuperar información de notificaciones enviadas

        builder.addCase(loadSentUsersData.fulfilled, (state, { payload }) => {
            const { noticeUUID } = payload;

            state[noticeUUID].operations.sent.expireIn = new Date().setMinutes(
                new Date().getMinutes() + PROFESSORS_SUBJECT_EXPIRE_TIME
            );
            state[noticeUUID].operations.sent.status = 'fulfilled';
            state[noticeUUID].operations.sent.fetchingAt = Date.now();
            state[noticeUUID].operations.sent.didInvalidate = false;
        });

        builder.addCase(loadSentUsersData.pending, (state, { meta }) => {
            const { noticeUUID } = meta;
            state[noticeUUID].operations.sent.status = 'pending';
        });

        builder.addCase(loadSentUsersData.rejected, (state, { payload }) => {
            const { noticeUUID } = payload;
            state[noticeUUID].operations.sent.status = 'rejected';
        });

        //Recuperar información de notificaciones recibidas

        builder.addCase(
            loadNoticedUsersData.fulfilled,
            (state, { payload }) => {
                const { noticeUUID } = payload;

                state[noticeUUID].operations.noticed.expireIn =
                    new Date().setMinutes(
                        new Date().getMinutes() + PROFESSORS_SUBJECT_EXPIRE_TIME
                    );
                state[noticeUUID].operations.noticed.status = 'fulfilled';
                state[noticeUUID].operations.noticed.fetchingAt = Date.now();
                state[noticeUUID].operations.noticed.didInvalidate = false;
            }
        );

        builder.addCase(loadNoticedUsersData.pending, (state, { meta }) => {
            const { noticeUUID } = meta;
            state[noticeUUID].operations.noticed.status = 'pending';
        });

        builder.addCase(loadNoticedUsersData.rejected, (state, { payload }) => {
            const { noticeUUID } = payload;
            state[noticeUUID].operations.noticed.status = 'rejected';
        });

        //Recuperar información de notificaciones leídas

        builder.addCase(loadReadUsersData.fulfilled, (state, { payload }) => {
            const { noticeUUID } = payload;

            state[noticeUUID].operations.read.expireIn = new Date().setMinutes(
                new Date().getMinutes() + PROFESSORS_SUBJECT_EXPIRE_TIME
            );
            state[noticeUUID].operations.read.status = 'fulfilled';
            state[noticeUUID].operations.read.fetchingAt = Date.now();
            state[noticeUUID].operations.read.didInvalidate = false;
        });

        builder.addCase(loadReadUsersData.pending, (state, { meta }) => {
            const { noticeUUID } = meta;
            state[noticeUUID].operations.read.status = 'pending';
        });

        builder.addCase(loadReadUsersData.rejected, (state, { payload }) => {
            const { noticeUUID } = payload;
            state[noticeUUID].operations.read.status = 'rejected';
        });
    },
});

export const { addItem, invalidateSent, invalidateNoticed, invalidateRead } =
    noticeDetails.actions;

export default noticeDetails.reducer;

export const loadSentUsersData = createAsyncThunk(
    'notices/load/sentNotices',
    async (data, thunkAPI) => {
        const { usersIds } = data;

        let FeedbackService = new Feedback();
        const state = thunkAPI.getState();
        const noticeUUID = state.notices.home.ui.noticeSelected;

        try {
            let users = await Connection.getUsers(usersIds).then(
                (i) => i.data.data
            );
            thunkAPI.dispatch(upsertManyUsers(users));

            return {
                users,
                noticeUUID,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                noticeUUID,
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            const state = getState();
            const noticeUUID = state.notices.home.ui.noticeSelected;

            let { didInvalidate, expireIn } =
                state.notices.noticeDetails[noticeUUID].operations.sent;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
        getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
            return {
                noticeUUID: getState().notices.home.ui.noticeSelected,
            };
        },
    }
);

export const loadNoticedUsersData = createAsyncThunk(
    'notices/load/noticedNotices',
    async (data, thunkAPI) => {
        const { usersIds } = data;

        let FeedbackService = new Feedback();
        const state = thunkAPI.getState();
        const noticeUUID = state.notices.home.ui.noticeSelected;

        try {
            let users = await Connection.getUsers(usersIds).then(
                (i) => i.data.data
            );
            thunkAPI.dispatch(upsertManyUsers(users));

            return {
                users,
                noticeUUID,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                noticeUUID,
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            const state = getState();
            const noticeUUID = state.notices.home.ui.noticeSelected;

            let { didInvalidate, expireIn } =
                state.notices.noticeDetails[noticeUUID].operations.noticed;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
        getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
            return {
                noticeUUID: getState().notices.home.ui.noticeSelected,
            };
        },
    }
);

export const loadReadUsersData = createAsyncThunk(
    'notices/load/readdNotices',
    async (data, thunkAPI) => {
        const { usersIds } = data;

        let FeedbackService = new Feedback();
        const state = thunkAPI.getState();
        const noticeUUID = state.notices.home.ui.noticeSelected;

        try {
            let users = await Connection.getUsers(usersIds).then(
                (i) => i.data.data
            );
            thunkAPI.dispatch(upsertManyUsers(users));

            return {
                users,
                noticeUUID,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                noticeUUID,
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            const state = getState();
            const noticeUUID = state.notices.home.ui.noticeSelected;

            let { didInvalidate, expireIn } =
                state.notices.noticeDetails[noticeUUID].operations.read;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
        getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
            return {
                noticeUUID: getState().notices.home.ui.noticeSelected,
            };
        },
    }
);

export const selectSelectedNoticeOperations = createSelector(
    selectNoticeDetails,
    selectSelectedNoticeUI,
    (noticesDetails, selectedNoticeUUID) => {
        return noticesDetails[selectedNoticeUUID].operations;
    }
);

export const selectSelectedNoticeTypeOperation = (type) =>
    createSelector(selectSelectedNoticeOperations, (operations) => {
        if (type === 'sent') {
            return operations.sent;
        } else if (type === 'noticed') {
            return operations.noticed;
        } else {
            return operations.read;
        }
    });

export const selecStatusOperation = (type) =>
    createSelector(selectSelectedNoticeTypeOperation(type), (operation) => {
        return operation.status;
    });

export const selectSelectedViewModelUsersIds = (vmUUID, type) =>
    createSelector(selectAllNoticesItems, (noticesDetails) => {
        let selectedNotices = noticesDetails.find(
            (notice) => notice.viewModel.id === vmUUID
        );

        let sentUsersIds = selectedNotices.sentNotifications.map(
            (notification) => notification.user_id
        );

        let noticesUsersIds = selectedNotices.noticedNotifications.map(
            (notification) => notification.user_id
        );

        let readUsersIds = selectedNotices.readNotifications.map(
            (notification) => notification.user_id
        );

        if (type === 'sent') {
            return {
                usersIds: sentUsersIds,
            };
        } else if (type === 'noticed') {
            return {
                usersIds: noticesUsersIds,
            };
        } else {
            return {
                usersIds: readUsersIds,
            };
        }
    });

export const selectUsersByNotifIds = (usersIds) =>
    createSelector(selectAllUsers, (allUsers) => {
        return allUsers.filter((user) =>
            usersIds.some((id) => id === user.user_id)
        );
    });
