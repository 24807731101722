import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    TablePagination,
    Card,
    CardContent,
} from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectTotalMaleFemaleStudentsWithdrawnGrade,
} from '../../../store/home/fetchSlice';
import { getTotalMaleFemaleStudentsWithdrawnGrade } from '../../../store/home/thunks';
import { SkeletongGrafics } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeTotalMaleFemaleStudentsWithdrawnGradeStatusServer } from '../../../store/home/componentStatusSelectors';

const TotalStudentsWithDrawnGradeGrafic = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeTotalMaleFemaleStudentsWithdrawnGradeStatusServer
    );

    const totalMaleFemaleStudentsWithdrawnGrade = useFetchResource(
        () =>
            getTotalMaleFemaleStudentsWithdrawnGrade({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectTotalMaleFemaleStudentsWithdrawnGrade,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getTotalMaleFemaleStudentsWithdrawnGrade({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectTotalMaleFemaleStudentsWithdrawnGrade,
            [cycleSelected, schoolId]
        );
    };

    const processStudentData = (data) => {
        if (!data || !Array.isArray(data.value)) {
            return [];
        }

        return data.value
            .map((item) => {
                if (item.data && item.data.length === 3) {
                    return {
                        grado: item.data[0],
                        hombres:
                            item.data[1] !== 'N/A' &&
                            item.data[1] !== null &&
                            item.data[1] !== '' &&
                            item.data[1] !== 0
                                ? item.data[1]
                                : 0,
                        mujeres:
                            item.data[2] !== 'N/A' &&
                            item.data[2] !== null &&
                            item.data[2] !== '' &&
                            item.data[2] !== 0
                                ? item.data[2]
                                : 0,
                    };
                } else {
                    console.warn('Invalid data format:', item);
                    return null;
                }
            })
            .filter((item) => item !== null);
    };

    const processedData = processStudentData(
        totalMaleFemaleStudentsWithdrawnGrade
    );

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedData = processedData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const getColor = (bar) => {
        if (bar.id === 'hombres') return '#1E90FF';
        if (bar.id === 'mujeres') return '#f47edd';
        return '#cccccc';
    };

    return (
        <Box>
            {fetchingStatus === 'pending' && <SkeletongGrafics items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        position: 'relative',
                        overflow: 'visible',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        margin: 'auto',
                    }}
                >
                    <CardContent sx={{ width: '100%', padding: '16px' }}>
                        <Typography variant="h6" gutterBottom>
                            {totalMaleFemaleStudentsWithdrawnGrade?.title ||
                                'Alumnos dados de baja por grado en la escuela'}
                        </Typography>
                        <Box
                            sx={{
                                height: '255px',
                                minWidth: '200px',
                            }}
                        >
                            <ResponsiveBar
                                data={paginatedData}
                                keys={['hombres', 'mujeres']}
                                indexBy="grado"
                                margin={{
                                    top: 10,
                                    right: 20,
                                    bottom: 50,
                                    left: 30,
                                }}
                                padding={0.2}
                                colors={getColor}
                                borderRadius={5}
                                groupMode="grouped"
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 45,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: 60,
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Cantidad de Alumnos',
                                    legendPosition: 'middle',
                                    legendOffset: -50,
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor="white"
                                layers={[
                                    'grid',
                                    'axes',
                                    'bars',
                                    'markers',
                                    'legends',
                                    (props) =>
                                        props.bars.map((bar) => {
                                            const isSmallValue =
                                                bar.data.value <= 10;

                                            return (
                                                <g
                                                    transform={`translate(${bar.x}, ${bar.y})`}
                                                    key={bar.key}
                                                >
                                                    <rect
                                                        width={bar.width}
                                                        height={bar.height}
                                                        fill={bar.color}
                                                    />
                                                    {bar.data.value > 10 && (
                                                        <text
                                                            x={bar.width / 2}
                                                            y={bar.height / 2}
                                                            textAnchor="middle"
                                                            dominantBaseline="central"
                                                            style={{
                                                                fill: 'white',
                                                                fontSize: 16,
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            {bar.data.value}
                                                        </text>
                                                    )}
                                                    {isSmallValue && (
                                                        <text
                                                            x={bar.width / 2}
                                                            y={-5}
                                                            textAnchor="middle"
                                                            style={{
                                                                fill: 'black',
                                                                fontSize: 16,
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            {bar.data.value}
                                                        </text>
                                                    )}
                                                </g>
                                            );
                                        }),
                                ]}
                                theme={{
                                    axis: {
                                        ticks: {
                                            text: { fontSize: 12 },
                                        },
                                    },
                                    tooltip: {
                                        container: {
                                            background: 'white',
                                            color: 'inherit',
                                            fontSize: 16,
                                            borderRadius: '2px',
                                            boxShadow:
                                                '0 1px 2px rgba(0, 0, 0, 0.25)',
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default TotalStudentsWithDrawnGradeGrafic;
