import React from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../../hooks';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography,
} from '@mui/material';
import { selectOneSettingBySchoolId } from '../../../../../store/slices/entities/settings';

const SubjectModal = ({ open, onClose, subjects, uuid, type, setType }) => {
    ////////// HOOKS //////////
    const Auth = useAuth();
    const history = useHistory();
    const schoolId = Auth.getUser().school_id;

    ////////// SHARED STATE //////////

    const titleBar = useSelector(
        selectOneSettingBySchoolId(schoolId, 'theme-color-title-bar')
    );
    const font = useSelector(
        selectOneSettingBySchoolId(schoolId, 'theme-color-font')
    );

    ////////// HANDLERS //////////

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    const handleGroupScoresList = (subjectId) => {
        if (type === 'scores') {
            history.push({
                pathname: `/inicio-maestros/classroom-group/${uuid}/scores/${subjectId}`,
            });
            setType('');
        } else {
            history.push({
                pathname: `/inicio-maestros/classroom-group/${uuid}/justify/${subjectId}`,
            });
            setType('');
        }
    };

    return (
        <Dialog open={open} fullWidth={true} maxWidth="sm" onClose={onClose}>
            <DialogTitle
                style={{
                    backgroundColor: titleBar.value,
                    color: font.value,
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ flexGrow: 1, color: '#fff' }}
                    >
                        Seleccionar Materia <br />
                        <span
                            style={{ fontSize: '15px', color: '#fff' }}
                        ></span>
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <Box sx={{ flexGrow: 1, padding: 2 }}>
                    {subjects.map((subject) => {
                        return (
                            <>
                                <Button
                                    color="inherit"
                                    variant="text"
                                    fullWidth
                                    onClick={() =>
                                        handleGroupScoresList(
                                            subject.subject_id
                                        )
                                    }
                                >
                                    {subject.subjectCatalog.title}
                                </Button>

                                <Divider
                                    sx={{
                                        borderBottomWidth: '1px',
                                        bgcolor: 'black',
                                    }}
                                />
                            </>
                        );
                    })}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleClose}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SubjectModal;
