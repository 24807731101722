import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Box,
    InputLabel,
    Select,
    TextField,
    Typography,
    FormControl,
    MenuItem,
    Button,
    FormHelperText,
    Divider,
    StepIcon,
    Step,
    StepLabel,
    Stepper,
    Stack,
    StepContent,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectStatusOperation,
    updateGroup,
} from '../../../store/slices/groupsUI/serverSlice';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useAuth } from '../../../hooks';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import {
    assistanceModality,
    levelsNames,
    turnsNames,
} from '../../../service/const';
import useFeedback from '../../../hooks/useFeedback';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

/**
 * Modal para actualizar un grupo
 *
 * @param {*} param0
 *
 * @returns
 */
const UpdateGroupStepperModal = ({ open, setOpen, groupId = null }) => {
    //////////// HOOKS ////////////////////

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    //////////// LOCAL STATE ////////////////////

    const [groupItem, setGroupItem] = useState({
        group_id: null,
        grade: '',
        group: '',
        turn: '',
        level: '',
        special: '',
        annexed: false,
        annexed_name: '',
        combined: '',
        share_students: false,
        group_annexed_id: null,
        group_type: null,
    });

    const [activeStep, setActiveStep] = useState(0);

    const steps = [
        {
            label: 'Información del grupo',
            description: 'información detallada del grupo',
        },
    ];

    //////////// SHARED STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(selectStatusOperation);

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    /////////////////// ACTIONS /////////////////

    /**
     * Actualizar grupo
     */
    const updateGroups = async () => {
        const { group_type } = groupItem;

        const adjustedGroupItem = {
            ...groupItem,
            annexed: group_type === 2,
            special: group_type !== 1 ? 1 : 0,
            annexed_name: groupItem.annexed_name,
            combined: group_type !== 1 ? 1 : groupItem.combined,
        };

        dispatch(
            updateGroup({
                groupId: groupItem.group_id,
                data: adjustedGroupItem,
            })
        )
            .unwrap()
            .then(({ message }) => {
                let initialMessage = 'Grupo actualizado';

                if (message) {
                    initialMessage = message;
                }

                feedbackApp.showFeedback({
                    title: initialMessage,
                });

                handleClose();
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    //////////////////// Handlers ///////////////////////////

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false);
    };

    /**
     * Efecto escuchando a show para limpiar los valores
     */

    useEffect(() => {
        if (!open) {
            setGroupItem({
                group_id: null,
                grade: '',
                group: '',
                turn: '',
                level: '',
                special: '',
                annexed: false,
                annexed_name: '',
                combined: '',
                share_students: false,
                group_annexed_id: null,
                group_type: null,
            });
        } else {
            setGroupItem({ ...groupItem, ...groupId });
        }
    }, [open]);

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     *
     * @param {*} event
     */
    const onInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'grade') {
            if (/^\d{0,2}$/.test(value)) {
                setGroupItem({ ...groupItem, [name]: value });
            }
        } else {
            setGroupItem({ ...groupItem, [name]: value });
        }
    };

    //////////////////////// COMPONENTS ////////////////////////////

    const typeOfGroup = () => {
        let typeGroup =
            groupItem.type_group === 2
                ? 'especial'
                : groupItem.type_group === 3
                ? 'extraescolar'
                : 'principal';

        return (
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                Actualizar grupo {`${typeGroup}`}
            </Typography>
        );
    };

    //////////////////////// VALIDACIONES ////////////////////////

    const DATA_REQUIRED = '';
    const REQUIRED_FIELD_MESSAGE = 'Campo requerido';

    const levelValid = () => {
        return {
            invalid: groupItem.level === DATA_REQUIRED,
            message: REQUIRED_FIELD_MESSAGE,
        };
    };

    const gradeValid = () => {
        return {
            invalid: groupItem.grade === DATA_REQUIRED,
            message: REQUIRED_FIELD_MESSAGE,
        };
    };

    const groupValid = () => {
        return {
            invalid: groupItem.group === DATA_REQUIRED,
            message: 'Campo requerido, Máximo 50 caracteres',
        };
    };

    const turnValid = () => {
        return {
            invalid: groupItem.turn === DATA_REQUIRED,
            message: REQUIRED_FIELD_MESSAGE,
        };
    };

    const combinedValid = () => {
        if (groupItem.groupType !== 1) {
            return { invalid: false, message: '' };
        }
        return {
            invalid: groupItem.combined === DATA_REQUIRED,
            message: REQUIRED_FIELD_MESSAGE,
        };
    };

    const annexednameValid = () => {
        return {
            invalid: groupItem.annexed_name === DATA_REQUIRED,
            message: 'Campo requerido, Máximo 20 caracteres',
        };
    };

    const StepTwoisInvalid = () => {
        const { group_type } = groupItem;
        const validations = {
            1: () =>
                levelValid().invalid ||
                gradeValid().invalid ||
                groupValid().invalid ||
                turnValid().invalid ||
                combinedValid().invalid,
            2: () =>
                levelValid().invalid ||
                gradeValid().invalid ||
                groupValid().invalid ||
                turnValid().invalid ||
                annexednameValid().invalid ||
                combinedValid().invalid,
            3: () =>
                levelValid().invalid ||
                gradeValid().invalid ||
                groupValid().invalid ||
                turnValid().invalid ||
                combinedValid().invalid,
        };

        const validationFunction = validations[group_type] || (() => false);
        return validationFunction();
    };

    const renderForm = () => {
        switch (activeStep) {
            case 0: {
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} mb={2} mt={3}>
                                <TextField
                                    id="grade"
                                    name="grade"
                                    label="Grado"
                                    type="text"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{
                                        maxLength: 2,
                                        autoComplete: 'turnoff',
                                    }}
                                    value={groupItem.grade}
                                    onChange={onInputChange}
                                    error={gradeValid().invalid}
                                    helperText={
                                        gradeValid().invalid
                                            ? gradeValid().message
                                            : false
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} mb={2} mt={3}>
                                <TextField
                                    id="group"
                                    name="group"
                                    label="Grupo"
                                    type="text"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{
                                        maxLength: 50,
                                        autoComplete: 'turnoff',
                                    }}
                                    value={groupItem.group}
                                    onChange={onInputChange}
                                    error={groupValid().invalid}
                                    helperText={
                                        groupValid().invalid
                                            ? groupValid().message
                                            : false
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} mb={2}>
                                <FormControl
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    error={levelValid().invalid}
                                >
                                    <InputLabel id="lbl-nivel">
                                        Nivel
                                    </InputLabel>
                                    <Select
                                        labelId="lbl-nivel"
                                        id="level"
                                        value={groupItem.level}
                                        onChange={onInputChange}
                                        label="Nivel"
                                        name="level"
                                        fullWidth
                                        disabled={groupItem.group_id !== null}
                                    >
                                        <MenuItem value={-1} selected disabled>
                                            Selecciona una opción
                                        </MenuItem>
                                        {levelsNames.map((i) => (
                                            <MenuItem key={i.key} value={i.key}>
                                                {i.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {levelValid().invalid && (
                                        <FormHelperText>
                                            {levelValid().message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} mb={2}>
                                <FormControl
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    error={turnValid().invalid}
                                >
                                    <InputLabel id="lbl-turno">
                                        Turno
                                    </InputLabel>
                                    <Select
                                        labelId="lbl-turno"
                                        id="turn"
                                        name="turn"
                                        value={groupItem.turn}
                                        onChange={onInputChange}
                                        label="Turno"
                                        fullWidth
                                    >
                                        <MenuItem value={-1} selected disabled>
                                            Selecciona una opción
                                        </MenuItem>
                                        {turnsNames.map((i) => (
                                            <MenuItem key={i.key} value={i.key}>
                                                {i.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {turnValid().invalid && (
                                        <FormHelperText>
                                            {turnValid().message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {groupItem.group_type === 2 && (
                                <>
                                    <Grid item xs={12} sm={6} mb={2}>
                                        <TextField
                                            id="annexed_name"
                                            name="annexed_name"
                                            label="Grupo especial"
                                            type="text"
                                            variant="outlined"
                                            value={groupItem.annexed_name}
                                            onChange={onInputChange}
                                            fullWidth
                                            inputProps={{
                                                maxLength: 50,
                                                autoComplete: 'turnoff',
                                            }}
                                            error={annexednameValid().invalid}
                                            helperText={
                                                annexednameValid().invalid
                                                    ? annexednameValid().message
                                                    : false
                                            }
                                        />
                                    </Grid>
                                </>
                            )}
                            {groupItem.group_type === 1 && (
                                <Grid item xs={12} sm={6} mb={2}>
                                    <FormControl
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        error={combinedValid().invalid}
                                    >
                                        <InputLabel id="lbl-combinado">
                                            Modalidad de lista de asistencia
                                        </InputLabel>
                                        <Select
                                            labelId="lbl-combinado"
                                            id="combined"
                                            name="combined"
                                            value={groupItem.combined}
                                            onChange={onInputChange}
                                            label="Modalidad de lista de asistencia"
                                            defaultValue=""
                                            fullWidth
                                        >
                                            <MenuItem
                                                value={-1}
                                                selected
                                                disabled
                                            >
                                                Selecciona una opción
                                            </MenuItem>
                                            {assistanceModality.map(
                                                (option) => (
                                                    <MenuItem
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        {combinedValid().invalid && (
                                            <FormHelperText>
                                                {combinedValid().message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </>
                );
            }
            default:
                return null;
        }
    };

    return (
        <>
            <Dialog
                open={open}
                fullWidth
                maxWidth="md"
                disableEscapeKeyDown={statusOperation === 'pending'}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: '90%',
                        height: '80vh',
                    },
                }}
            >
                <DialogTitle
                    style={{
                        backgroundColor: titleBarBackground.value,
                        color: fontColor.value,
                    }}
                >
                    <div style={{ display: 'flex' }}>{typeOfGroup()}</div>
                </DialogTitle>
                <DialogContent
                    dividers
                    style={{ overflow: activeStep === 3 ? 'auto' : 'hidden' }}
                >
                    <Stack
                        direction="row"
                        divider={
                            <Divider
                                orientation="vertical"
                                flexItem
                                variant="middle"
                            />
                        }
                        spacing={4.7}
                        sx={{ height: 550 }}
                    >
                        <Stack
                            direction="column"
                            spacing={1}
                            sx={{ minWidth: 153, maxWidth: 153 }}
                        >
                            <Stepper
                                activeStep={activeStep}
                                orientation="vertical"
                            >
                                {steps.map((label, index) => {
                                    let stepValid = true;
                                    let stepText = '';
                                    if (index === 1) {
                                        stepValid = !StepTwoisInvalid();
                                        stepText = StepTwoisInvalid()
                                            ? 'Faltan campos por llenar'
                                            : 'Todos los campos están completos';
                                    }

                                    return (
                                        <Step
                                            key={index}
                                            completed={index < activeStep}
                                            error={
                                                index === activeStep &&
                                                !stepValid
                                            }
                                        >
                                            <StepLabel
                                                StepIconProps={{
                                                    completed:
                                                        index < activeStep,
                                                    error:
                                                        index === activeStep &&
                                                        !stepValid,
                                                }}
                                                StepIconComponent={(props) => {
                                                    if (props.error) {
                                                        return (
                                                            <ErrorIcon
                                                                style={{
                                                                    color: 'red',
                                                                }}
                                                            />
                                                        );
                                                    } else if (
                                                        props.completed
                                                    ) {
                                                        return (
                                                            <CheckIcon
                                                                style={{
                                                                    color: 'green',
                                                                }}
                                                            />
                                                        );
                                                    } else {
                                                        return (
                                                            <StepIcon
                                                                {...props}
                                                            />
                                                        );
                                                    }
                                                }}
                                            >
                                                <Typography
                                                    variant="h7"
                                                    color="primary"
                                                >
                                                    {label.label}
                                                </Typography>
                                                {index === activeStep && (
                                                    <Typography
                                                        variant="body2"
                                                        style={{
                                                            color: stepValid
                                                                ? 'green'
                                                                : 'red',
                                                        }}
                                                    >
                                                        {stepText}
                                                    </Typography>
                                                )}
                                            </StepLabel>
                                            <StepContent>
                                                <Typography>
                                                    {label.description}
                                                </Typography>
                                            </StepContent>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Stack>
                        <Box sx={{ pt: 3 }}>{renderForm()}</Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                    <LoadingButton
                        size="small"
                        color="primary"
                        onClick={updateGroups}
                        loading={statusOperation === 'pending'}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        disabled={StepTwoisInvalid()}
                    >
                        Actualizar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default UpdateGroupStepperModal;
