import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Services from '../../../service/Connection';
import { GROUPSUI_EXPIRE_TIME } from '../../../service/const';
import Feedback from '../../../service/Feedback';
import * as _ from 'lodash';
import Authentication from '../../../service/Login';
import { addOneSubject, upsertManySubjects } from '../entities/subjects';
import {
    addOneGroup,
    selectGroupsById,
    upsertManyGroup,
    upsertOneGroup,
} from '../entities/groups';
import { upsertManyUsers } from '../entities/users';
import { addOneItem, setAllItems } from './itemSlice';
import { addManyModules } from '../entities/modules';
import { updateGroup as updateOneGroup } from '../entities/groups';
import {
    addManySubjectcatalogs,
    upsertManySubjectcatalog,
} from '../entities/subjectcatalogs';

const emptyState = {
    expireIn: null,
    ferchingAt: null,
    statusServer: 'idle',
    statusOperation: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

/**
 * Slice para el settings UI
 */
export const groupsUIServerSlice = createSlice({
    name: 'groupsUI/server',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        const pendingServerStatus = (state, action) => {
            state.statusServer = 'pending';
        };

        /**
         * Termina la carga de informacion
         */
        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.expireIn = new Date().setMinutes(
                new Date().getMinutes() + GROUPSUI_EXPIRE_TIME
            );
            state.ferchingAt = Date.now();
            state.didInvalidate = false;

            state.statusServer = 'fulfilled';
        });
        builder.addCase(loadUI.pending, pendingServerStatus);
        builder.addCase(loadUI.rejected, (state, action) => {
            state.statusServer = 'rejected';
            state.feedback = action.payload.feedback;
        });

        /////////////////////// ACTUALIZAR GRUPO /////////////////////////

        builder.addCase(updateGroup.rejected, (state, action) => {
            state.statusOperation = 'rejected';
            state.feedback = action.payload.feedback;
        });
        builder.addCase(updateGroup.fulfilled, (state, action) => {
            state.statusOperation = 'fulfilled';
        });
        builder.addCase(updateGroup.pending, (state, action) => {
            state.statusOperation = 'pending';
        });

        /////////////////////// CREAR Grupo/////////////////////////

        builder.addCase(createGroup.rejected, (state, action) => {
            state.statusOperation = 'rejected';
            state.feedback = action.payload.feedback;
        });
        builder.addCase(createGroup.fulfilled, (state, action) => {
            state.statusOperation = 'fulfilled';
        });
        builder.addCase(createGroup.pending, (state, action) => {
            state.statusOperation = 'pending';
        });

        /////////////////////// CREAR MODULO /////////////////////////

        builder.addCase(createModule.rejected, (state, action) => {
            state.statusOperation = 'rejected';
            state.feedback = action.payload.feedback;
        });
        builder.addCase(createModule.fulfilled, (state, action) => {
            state.statusOperation = 'fulfilled';
        });
        builder.addCase(createModule.pending, (state, action) => {
            state.statusOperation = 'pending';
        });
    },
});

export const { invalidate } = groupsUIServerSlice.actions;

export default groupsUIServerSlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 *
 * @param {*} state
 * @returns
 */

export const selectGroupsData = (state) => state.groupsUI.items;

export const selectGroupsServer = (state) => state.groupsUI.server;

export const selectStatusServer = (state) => state.groupsUI.server.statusServer;

export const selectStatusOperation = (state) =>
    state.groupsUI.server.statusOperation;

//////////////// TRUNCKS /////////////////

function getEmptyItem(groupId) {
    return {
        data: {},
        servers: {
            groups: {
                expireIn: null,
                ferchingAt: null,
                statusServer: 'idle',
                statusOperation: 'idle',
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
            subjects: {
                expireIn: null,
                ferchingAt: null,
                statusServer: 'idle',
                statusOperation: 'idle',
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
            assessor: {
                expireIn: null,
                ferchingAt: null,
                statusServer: 'idle',
                statusOperation: 'idle',
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
            students: {
                expireIn: null,
                ferchingAt: null,
                statusServer: 'idle',
                statusOperation: 'idle',
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
            modules: {
                expireIn: null,
                ferchingAt: null,
                statusServer: 'idle',
                statusOperation: 'idle',
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
        },
        ui: {
            group_id: groupId,
        },
    };
}
/**
 * Cargar informacion de la UI de Grupos
 */
export const loadUI = createAsyncThunk(
    'groupsUI/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        let Auth = new Authentication();
        const state = thunkAPI.getState();

        try {
            let lastPage = 0;
            let currentPage = 0;
            let groups = [];
            let assessors = await Services.getAssesorsBySchool(schoolId).then(
                (res) => res.data.data
            );

            do {
                currentPage++;

                let groupsResponse = await Services.groupsBySchool(schoolId, {
                    per_page: 50,
                    page: currentPage,
                }).then((res) => res.data);
                let groupsPaginated = groupsResponse.data;
                let meta = groupsResponse.meta;

                lastPage = meta.last_page;

                groups = groups.concat(groupsPaginated);
            } while (currentPage < lastPage);
            const orderGroups = groups
                .sort((a, b) => {
                    // Ordenar por Grado
                    const gradeComparison = a.grade - b.grade;
                    if (gradeComparison !== 0) {
                        return gradeComparison;
                    }

                    // Ordenar por Grupo
                    const groupComparison = a.group.localeCompare(b.group);
                    if (groupComparison !== 0) {
                        return groupComparison;
                    }

                    // Ordenar por Turno (Matutino, Vespertino, Nocturno)
                    const turnOrder = {
                        Matutino: 1,
                        Vespertino: 2,
                        Nocturno: 3,
                    };
                    return turnOrder[a.turn] - turnOrder[b.turn];
                })
                .filter((group) => group.group_type !== 4);

            thunkAPI.dispatch(upsertManyGroup(orderGroups));
            thunkAPI.dispatch(upsertManyUsers(assessors));

            const items = orderGroups.reduce((preveState, curr) => {
                preveState[curr.group_id] = getEmptyItem(curr.group_id);
                return preveState;
            }, {});

            thunkAPI.dispatch(setAllItems(items));
            return {
                orderGroups,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = getState().groupsUI.server;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

/**
 * Actualizar datos de un grupo
 */
export const updateGroup = createAsyncThunk(
    'groupsUI/server/group/update',
    async ({ groupId, data }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            let group = await Services.updateGroupById(groupId, data).then(
                (i) => i.data.data
            );

            thunkAPI.dispatch(upsertOneGroup(group));

            return group;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
/**
 * Crea un grupo
 */
export const createGroup = createAsyncThunk(
    'groupsUI/server/group/create',
    async ({ schoolId, data, subjects }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let message = null;

        try {
            let group = await Services.setGroupBySchool(schoolId, data).then(
                (i) => i.data.data
            );

            const allSubjects = _.flatMap(data.modules, 'subjects').concat(
                data.subjects
            );

            let subjectsByGroup = await Services.getSubjectByGroup(
                group.group_id
            ).then((i) => i.data.data);

            thunkAPI.dispatch(addOneGroup(group));
            thunkAPI.dispatch(upsertManySubjects(subjectsByGroup));
            thunkAPI.dispatch(addManySubjectcatalogs(allSubjects));

            thunkAPI.dispatch(addOneItem(getEmptyItem(group.group_id)));

            return {
                group,
                message,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Crea modulo
 */
export const createModule = createAsyncThunk(
    'groupsUI/server/module/create',
    async ({ groupId, data, schoolId }, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const moduleResponse = await Services.setModulesByGroup(
                groupId,
                data
            ).then((i) => i.data.data);

            const moduleSubjects = await Services.getSubjectsByModule(
                moduleResponse[0].group_id
            ).then((i) => i.data.data);

            thunkAPI.dispatch(upsertManySubjects(moduleSubjects));
            thunkAPI.dispatch(addManyModules(moduleResponse));
            thunkAPI.dispatch(
                updateOneGroup({
                    id: groupId,
                    changes: {
                        total_modules:
                            selectGroupsById(groupId)(thunkAPI.getState())
                                .total_modules + 1,
                        subjects:
                            selectGroupsById(groupId)(thunkAPI.getState())
                                .subjects + moduleSubjects.length,
                    },
                })
            );

            let group = await Services.getGroupById(groupId).then(
                (i) => i.data.data
            );

            thunkAPI.dispatch(upsertOneGroup(group));

            return {
                module: moduleResponse,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
