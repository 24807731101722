import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    Tooltip,
    IconButton,
    Snackbar,
    Skeleton,
    Stack,
} from '@mui/material';
import { Box } from '@mui/system';
import ChangeProfessorSubjectModal from './ChangeProfessorSubjectModal';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import * as Style from '../../../styles/index';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteSubjectModal from './DeleteSubjectModal';
import { useEffect } from 'react';
import {
    loadGroupSubjectsUI,
    selectGroupByItemSelected,
    selectMainAndModuleSubjectsWithDetailsByMainGroup,
    selectSubjectstatusServerItemSelected,
} from '../../../store/slices/groupsUI/itemSlice';
import { Error } from '../../../components/Feedback';
import { selectGroupItem } from '../../../store/slices/groupsUI/uiSlice';
import { DataGrid, esES } from '@mui/x-data-grid';
import { GroupsToolBarOnSubjects } from './GroupsToolBar';
import { selectModulesByGroup } from '../../../store/slices/entities/modules';

/**
 * Modal para mostrar los materias de un grupo
 *
 * @param {*} param0
 *
 * @returns
 */
const SubjectModal = ({ open, setOpen }) => {
    const classes = Style.tableModalStyle();

    const dispatch = useDispatch();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    ///////////////////// SHARED STATE //////////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    const groupSelected = useSelector(selectGroupByItemSelected);
    const statusServer = useSelector(selectSubjectstatusServerItemSelected);
    let subjects = useSelector(
        selectMainAndModuleSubjectsWithDetailsByMainGroup(
            groupSelected?.group_id
        )
    );
    let modules = useSelector(selectModulesByGroup(groupSelected?.group_id));

    const ordenatedSubjects = subjects.slice().sort((subjectA, subjectB) => {
        const titleA = subjectA.catalog.title.toLowerCase();
        const titleB = subjectB.catalog.title.toLowerCase();
        if (titleA < titleB) {
            return -1;
        }
        if (titleA > titleB) {
            return 1;
        }

        if (subjectA.group_id === subjectB.group_id) {
            return 0;
        }
        if (subjectA.group_id === groupSelected.group_id) {
            return -1;
        }
        if (subjectB.group_id === groupSelected.group_id) {
            return 1;
        }
        return 0;
    });

    const [openMaestros, setOpenMaestros] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [subjectId, setSubjectId] = useState(null);
    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [groupId, setGroupId] = useState('');

    /**
     * Iniciamos el flujo de recuperacion de los grupos
     * del alumno seleccionado
     */
    useEffect(() => {
        if (open) {
            dispatch(loadGroupSubjectsUI(null));
        }
    }, [open]);

    //////////////////////// FUNCIONES /////////////////////

    /**
     * Funcion para cerrar el modal
     */
    const closeModal = () => {
        setOpen(false);
        dispatch(selectGroupItem(null));
    };

    const reload = () => {
        dispatch(loadGroupSubjectsUI())
            .unwrap()
            .then((response) => {});
    };

    /**
     * Abir modal para eliminar la materia
     *
     * @param {*} subject
     */
    const onOpenDeleteSubjectModal = (subject) => {
        setOpenDeleteModal(true);
        setSubjectId(subject.subject_id);
        setGroupId(groupSelected.group_id);
    };

    /**
     * Abrir modal para actualizar profesor de una materia
     */
    const onShowChangeProfessorSubjectModal = (subject) => {
        setOpenMaestros(true);
        setSubjectId(subject.subject_id);
        setGroupId(groupSelected.group_id);
    };

    /**
     * La materia fue eliminada correctamente
     */
    const onSubjectDeleted = () => {
        showFeedback('La materia fue eliminada correctamente');
    };

    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message);
        setOpenFeedback(true);
    };

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const columns = [
        {
            field: 'group',
            headerName: 'Grupo',
            flex: 0.4,
            minWidth: 20,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            disableColumnMenu: true,
            // valueGetter: (params) => {
            // const group = params.row.group_id;
            // const selectedGroup = groupSelected.group_id;

            // const moduleMatched = modules.find(mod => mod.group_annexed_id === group && mod.group_id === selectedGroup);

            // if (group === selectedGroup || moduleMatched) {
            //     return `${groupSelected.grade}° ${groupSelected.group} ${getLevelName(groupSelected.level)} ${getTurnLevel(groupSelected.turn)}`;
            // } else {
            //     return '';
            // }
            // },
            renderCell: (params) => (
                <div style={{ whiteSpace: 'pre-line' }}>{params.value}</div>
            ),
            hide: true,
            filterable: false,
        },
        {
            field: 'folio',
            headerName: 'Folio',
            flex: 0.4,
            minWidth: 20,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params.row.catalog?.folio,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'pre-line' }}>{params.value}</div>
            ),
        },
        {
            field: 'title',
            headerName: 'Materia',
            flex: 0.4,
            minWidth: 20,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params.row.catalog?.title,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'pre-line' }}>{params.value}</div>
            ),
        },
        {
            field: 'module',
            headerName: 'Módulo',
            flex: 0.4,
            minWidth: 20,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                const filteredSUB = modules.filter(
                    (mod) => mod.group_id === params.row.group_id
                );
                return filteredSUB.length === 0 ||
                    filteredSUB[0].annexed_name === undefined
                    ? ''
                    : `${filteredSUB[0].annexed_name}`;
            },
            renderCell: (params) => (
                <div style={{ whiteSpace: 'pre-line' }}>{params.value}</div>
            ),
        },
        {
            field: 'professor',
            headerName: 'Profesor',
            flex: 0.4,
            minWidth: 20,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                params.row.professor === undefined
                    ? 'Sin Maestro'
                    : `${params.row.professor.name} ${params.row.professor.last_name} ${params.row.professor.second_last_name}`,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'pre-line' }}>
                    <Button
                        style={{ textTransform: 'none' }}
                        onClick={() => {
                            onShowChangeProfessorSubjectModal(params.row);
                        }}
                    >
                        {params.row.professor === undefined
                            ? 'Sin Maestro'
                            : `${params.row.professor.name} ${params.row.professor.last_name} ${params.row.professor.second_last_name}`}
                    </Button>
                </div>
            ),
        },
        {
            field: 'actions',
            headerName: 'Acciones',
            flex: 0.4,
            minWidth: 20,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <Tooltip title="Eliminar materia">
                    <IconButton
                        style={{ textTransform: 'none', marginLeft: 10 }}
                        variant="outlined"
                        color="error"
                        onClick={() => {
                            onOpenDeleteSubjectModal(params.row);
                        }}
                    >
                        <DeleteOutlineIcon />
                    </IconButton>
                </Tooltip>
            ),
        },
    ];

    return (
        <>
            {openMaestros && (
                <ChangeProfessorSubjectModal
                    open={openMaestros}
                    setOpen={setOpenMaestros}
                    subjectId={subjectId}
                    groupId={groupId}
                />
            )}

            {openDeleteModal && (
                <DeleteSubjectModal
                    open={openDeleteModal}
                    setOpen={() => {
                        setOpenDeleteModal(false);
                    }}
                    subjectId={subjectId}
                    onDeleted={onSubjectDeleted}
                    groupId={groupId}
                />
            )}

            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="md"
                classes={{ paper: classes.dialogWrapper }}
                onClose={() => {
                    closeModal();
                }}
            >
                <DialogTitle
                    className={classes.dialogTitle}
                    style={{
                        backgroundColor: titleBarBackground.value,
                        color: fontColor.value,
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography
                            variant="h6"
                            component="div"
                            style={{ flexGrow: 1 }}
                        >
                            Materias <br />
                            <span style={{ fontSize: '15px' }}>
                                Grupo {groupSelected?.grade}°{' '}
                                {groupSelected?.group}{' '}
                                {getTurnLevel(groupSelected?.turn)}{' '}
                                {getLevelName(groupSelected?.level)}
                            </span>
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {statusServer == 'pending' && (
                        <Stack spacing={2} sx={{ padding: 2 }}>
                            <Skeleton variant="rectangular" height={30} />
                            <Skeleton variant="rectangular" height={30} />
                            <Skeleton variant="rectangular" height={30} />
                            <Skeleton variant="rectangular" height={30} />
                        </Stack>
                    )}

                    {statusServer == 'rejected' && (
                        <Error
                            onRetry={reload}
                            message={'Estamos teniendo problemas'}
                        />
                    )}

                    {statusServer == 'fulfilled' && (
                        <Box
                            sx={{
                                '& .super-app-theme--header': {
                                    backgroundColor: titleBarBackground.value,
                                    color: fontColor.value,
                                },
                                '& .MuiDataGrid-root': {
                                    border: 0,
                                },
                            }}
                        >
                            <Box style={{ textAlign: 'center' }}>
                                {subjects.length ? (
                                    <DataGrid
                                        localeText={
                                            esES.components.MuiDataGrid
                                                .defaultProps.localeText
                                        }
                                        getRowId={({ catalog_subject_id }) =>
                                            catalog_subject_id
                                        }
                                        slotProps={{
                                            toolbar: {
                                                csvOptions: {
                                                    fields: [
                                                        'group',
                                                        'folio',
                                                        'module',
                                                        'professor',
                                                    ],
                                                },
                                            },
                                        }}
                                        rows={ordenatedSubjects}
                                        columns={columns}
                                        pageSize={20}
                                        rowsPerPageOptions={[10]}
                                        components={{
                                            Toolbar: () => (
                                                <GroupsToolBarOnSubjects
                                                    columns={columns}
                                                />
                                            ),
                                        }}
                                        componentsProps={{
                                            panel: {
                                                placement: 'bottom',
                                                sx: {
                                                    '& .MuiDataGrid-columnsPanelRow:first-of-type':
                                                        {
                                                            display: 'none',
                                                        },
                                                },
                                            },
                                        }}
                                        disableSelectionOnClick
                                        autoHeight
                                    />
                                ) : (
                                    <Box sx={{ mt: 2, mb: 2 }}>
                                        <Typography
                                            variant="h5"
                                            component="div"
                                            style={{
                                                flexGrow: 1,
                                                textAlign: 'center',
                                            }}
                                        >
                                            Sin Materias
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={openFeedback}
                autoHideDuration={2500}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </>
    );
};

export default SubjectModal;
