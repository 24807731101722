import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import Authentication from '../../../../service/Login';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';

/**
 * Adaptador para los parentescos
 */
const relationshipsAdapter = createEntityAdapter({
    selectId: (relation) => relation.relationship_id,
    sortComparer: (a, b) => a.relationship_id - b.relationship_id,
});

/**
 * Slice para la entidades de parentescos
 */
export const relationshipsSlice = createSlice({
    name: 'relationships',
    initialState: relationshipsAdapter.getInitialState(),
    reducers: {
        addOneRelationship: relationshipsAdapter.addOne,
        addManyRelationships: relationshipsAdapter.addMany,
        upsertManyRelationships: relationshipsAdapter.upsertMany,
        upsertOneRelationship: relationshipsAdapter.upsertOne,
        removeOneRelationship: relationshipsAdapter.removeOne,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return relationshipsAdapter.getInitialState();
        });
    },
});

/**
 * Acciones generadas por la librerias
 */
export const {
    addOneRelationship,
    addManyRelationships,
    upsertManyRelationships,
    upsertOneRelationship,
    removeOneRelationship,
} = relationshipsSlice.actions;

/**
 * Exportaciones por defecto del reducer generado por la libreria
 */
export default relationshipsSlice.reducer;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = relationshipsAdapter.getSelectors(
    (state) => state.entities.relationships
);

/**
 * Selecciona todos los grupos
 */
export const selectAllRelationships = (store) =>
    globalizedSelectors.selectAll(store);

/**
 * Selecciona todos los grupos
 */
export const selectRelationshipsById = (id) => (store) =>
    globalizedSelectors.selectById(store, id);

export const selectRelationshipByStudentId = (studentId) =>
    createSelector(selectAllRelationships, (relationships) => {
        let relationshipsByStudentId = relationships.filter((r) => {
            return r.student_id === Number(studentId);
        });
        return relationshipsByStudentId;
    });

//////////////////// TRUNKS /////////////////////
