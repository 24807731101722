import React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createCatSurcharge } from '../../../store/cat_surcharges/operations';
import { useAuth, useFeedback } from '../../../../hooks';

const CreateCatalog = ({ open, onclose }) => {
    ///////////// SHARED STATE /////////////

    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const feedbackApp = useFeedback();

    ///////////// FORM CONFIGURATION /////////////

    const formik = useFormik({
        initialValues: {
            title: '',
            value: '',
            value_modality: '', // 1 para monetario, 2 para porcentaje
        },
        validationSchema: Yup.object({
            title: Yup.string().required('El título es obligatorio'),
            value: Yup.number()
                .required('El valor es obligatorio')
                .positive('El valor debe ser positivo')
                .when('value_modality', {
                    is: 2, // Si value_modality es 2 (porcentaje)
                    then: (schema) =>
                        schema.max(100, 'El valor no puede ser mayor que 100%'),
                    otherwise: (schema) => schema, // Para modalidad monetaria, no se aplica límite
                }),
            value_modality: Yup.number()
                .required('La modalidad es obligatoria')
                .oneOf([1, 2], 'Modalidad inválida'),
        }),
        onSubmit: async (values, { resetForm }) => {
            await dispatch(createCatSurcharge({ schoolId, data: values }))
                .unwrap()
                .then(() => {
                    feedbackApp.showFeedback({
                        title: 'Catalogo de Recargos creado',
                    });
                    resetForm();
                    onclose();
                })
                .catch((error) => {
                    feedbackApp.showFeedback({
                        title: error.feedback.title,
                    });
                });
        },
    });

    ///////////// RENDER /////////////

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onclose();
                }
            }}
            maxWidth="lg"
            fullWidth
            disableEscapeKeyDown
            sx={{
                '& .MuiDialog-paper': {
                    padding: 5,
                    width: '60%',
                    borderRadius: 2,
                },
            }}>
            <DialogTitle
                sx={{
                    textAlign: 'center',
                    position: 'relative',
                }}>
                <Typography variant="h4" component="div">
                    Registrar un nuevo catalogo de recargo
                </Typography>
                <Typography variant="body2">
                    Aquí podrás registrar un nuevo catalogo de recargo
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        formik.resetForm();
                        onclose();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 6,
                        top: 6,
                        color: theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Título del catalogo de Recargo"
                    variant="outlined"
                    id="title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    margin="dense"
                />

                <FormControl
                    fullWidth
                    margin="dense"
                    error={
                        formik.touched.value_modality &&
                        Boolean(formik.errors.value_modality)
                    }>
                    <InputLabel id="value_modality-label">
                        Modalidad de Valor
                    </InputLabel>
                    <Select
                        labelId="value_modality-label"
                        id="value_modality"
                        name="value_modality"
                        value={formik.values.value_modality}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Modalidad de Valor">
                        <MenuItem value={1}>Monetario</MenuItem>
                        <MenuItem value={2}>Porcentaje</MenuItem>
                    </Select>
                    {formik.touched.value_modality &&
                        formik.errors.value_modality && (
                            <Typography
                                variant="body2"
                                color="error"
                                sx={{ mt: 1 }}>
                                {formik.errors.value_modality}
                            </Typography>
                        )}
                </FormControl>

                <TextField
                    fullWidth
                    id="value"
                    name="value"
                    placeholder="0.00"
                    type="number"
                    label="Valor"
                    variant="outlined"
                    value={formik.values.value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.value && Boolean(formik.errors.value)}
                    helperText={formik.touched.value && formik.errors.value}
                    margin="dense"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {formik.values.value_modality === 2 ? '%' : '$'}
                            </InputAdornment>
                        ),
                    }}
                    disabled={!formik.values.value_modality}
                />
            </DialogContent>

            <DialogActions>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={formik.handleSubmit}
                        color="primary"
                        disabled={
                            formik.isSubmitting ||
                            !(formik.isValid && formik.dirty)
                        }>
                        {formik.isSubmitting ? (
                            <CircularProgress size={24} />
                        ) : (
                            'Registrar Catalogo de Recargo'
                        )}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default CreateCatalog;
