import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import Connection from '../../../../service/Connection';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../../service/const';

const initialState = {
    Total_Discounts_Lost: {
        fetch: {
            expireIn: null,
            fetchingAt: null,
            status: 'idle',
            operation: 'idle',
            didInvalidate: true,
            feedback: {
                title: null,
                message: null,
                payload: null,
            },
        },
        data: [],
    },
    Total_Gains: {
        fetch: {
            expireIn: null,
            fetchingAt: null,
            status: 'idle',
            operation: 'idle',
            didInvalidate: true,
            feedback: {
                title: null,
                message: null,
                payload: null,
            },
        },
        data: [],
    },
    Concepts_Without_Payments: {
        fetch: {
            expireIn: null,
            fetchingAt: null,
            status: 'idle',
            operation: 'idle',
            didInvalidate: true,
            feedback: {
                title: null,
                message: null,
                payload: null,
            },
        },
        data: [],
    },
};

const kpiSlice = createSlice({
    name: 'kpi',
    initialState,
    reducers: {
        invalidate: (state) => {
            state.didInvalidate = true;
        },
    },
    // TotalDiscountLoss 
    extraReducers: (builder) => {
        builder.addCase(fetchTotalDiscountLoss.fulfilled, (state, action) => {
            state.Total_Discounts_Lost.fetch = {
                ...state.Total_Discounts_Lost.fetch,
                expireIn: new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                ),
                fetchingAt: Date.now(),
                status: 'fulfilled',
                didInvalidate: false,
            };

            state.Total_Discounts_Lost.data = action.payload.data.components;
        });
        builder.addCase(fetchTotalDiscountLoss.pending, (state) => {
            state.Total_Discounts_Lost.fetch.status = 'pending';
        });
        builder.addCase(fetchTotalDiscountLoss.rejected, (state, action) => {
            state.Total_Discounts_Lost.fetch.status = 'rejected';
            state.Total_Discounts_Lost.fetch.feedback =
                    action.payload.feedback;

        });


        // ConceptsWithoutPayment 
        builder.addCase(fetchConceptsWithoutPayment.fulfilled, (state, action) => {
            state.Concepts_Without_Payments.fetch = {
                ...state.Concepts_Without_Payments.fetch,
                expireIn: new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                ),
                fetchingAt: Date.now(),
                status: 'fulfilled',
                didInvalidate: false,
            };

            state.Concepts_Without_Payments.data = action.payload.data.components;
        });
        builder.addCase(fetchConceptsWithoutPayment.pending, (state) => {
            state.Concepts_Without_Payments.fetch.status = 'pending';
        });
        builder.addCase(fetchConceptsWithoutPayment.rejected, (state, action) => {
            state.Concepts_Without_Payments.fetch.status = 'rejected';
            state.Concepts_Without_Payments.fetch.feedback =
                    action.payload.feedback;


        });

        //totalGains 
        builder.addCase(fetchTotalGains.fulfilled, (state, action) => {
            state.Total_Gains.fetch = {
                ...state.Total_Gains.fetch,
                expireIn: new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                ),
                fetchingAt: Date.now(),
                status: 'fulfilled',
                didInvalidate: false,
            };

            state.Total_Gains.data = action.payload.data.components;
        });
        builder.addCase(fetchTotalGains.pending, (state) => {
            state.Total_Gains.fetch.status = 'pending';
        });
        builder.addCase(fetchTotalGains.rejected, (state, action) => {
            state.Total_Gains.fetch.status = 'rejected';
            state.Total_Gains.fetch.feedback =
            action.payload.feedback;

        });





    },
});

export const { invalidate: invalidateKpis } = kpiSlice.actions;

export default kpiSlice.reducer;

///////// THUNKS  ////////////

export const fetchTotalDiscountLoss = createAsyncThunk(
    'fetch/totalDiscountLoss',
    async (schoolId) => {
        const response = await Connection.getPaymentsInformation({
            school_id: schoolId,
            data: {
                page: 'home',
                type: 'kpi',
                idKey: 'total_discount_loss',
            },
        });
        const discountLoss = response.data;
       
        return discountLoss;
    },
    {
        condition: (schoolId, { getState }) => {
            const { didInvalidate, expireIn } = selectTotalDiscountLoss(getState());
            const now = Date.now();

            // Si el estado no está invalidado y el tiempo de expiración no ha pasado, no hacer la petición
            if (!didInvalidate && expireIn && expireIn > now) {
                return false;
            }

            return true; // Permite la petición si el estado está invalidado o ha expirado
        },
    }
);

export const fetchConceptsWithoutPayment = createAsyncThunk(
    'fetch/conceptsWithoutPayment',
    async (schoolId) => {
        const response = await Connection.getPaymentsInformation({
            school_id: schoolId,
            data: {
                page: 'home',
                type: 'kpi',
                idKey: 'total_concepts_wiouth_payments',
            },
        });
        const conceptsWithoutPayment= response.data;
        
        return conceptsWithoutPayment;
    },
    {
        condition: (schoolId, { getState }) => {
            const { didInvalidate, expireIn } = selectConceptsWithoutPayment(getState());
            const now = Date.now();

            // Si el estado no está invalidado y el tiempo de expiración no ha pasado, no hacer la petición
            if (!didInvalidate && expireIn && expireIn > now) {
                return false;
            }

            return true; // Permite la petición si el estado está invalidado o ha expirado
        },
    }
);
export const fetchTotalGains = createAsyncThunk(
    'fetch/totalGains',
    async (schoolId) => {
        const response = await Connection.getPaymentsInformation({
            school_id: schoolId,
            data: {
                page: 'home',
                type: 'kpi',
                idKey: 'total_gains',
            },
        });
        const totalGainsData = response.data;

            console.log(totalGainsData);

            return  totalGainsData ;
    },
    {
        condition: (schoolId, { getState }) => {
            const { didInvalidate, expireIn } = selectTotalGains(getState());
            const now = Date.now();

            // Si el estado no está invalidado y el tiempo de expiración no ha pasado, no hacer la petición
            if (!didInvalidate && expireIn && expireIn > now) {
                return false;
            }

            return true; // Permite la petición si el estado está invalidado o ha expirado
        },
    }
);
///////////// SELECTORS  ////////////

export const selectKpi = (state) => state.paymentsModule.home.kpis;

export const selectKpiStatus = (state) => selectKpi(state).status;

export const selectConceptsWithoutPayment = createSelector(
    [selectKpi],
    (kpi) => kpi.Concepts_Without_Payments.data
);

export const selectTotalGains = createSelector(
    [selectKpi],
    (kpi) => kpi.Total_Gains.data
);

export const selectTotalDiscountLoss = createSelector(
    [selectKpi],
    (kpi) => kpi.Total_Discounts_Lost.data
);

export const selectKpiFetchState = (state) =>
    state.paymentsModule.home.kpis.fetch;