import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Feedback from '../../../service/Feedback';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import Connection from '../../../service/Connection';
import { selectPaymentCatSurcharge } from './selectors';
import {
    upsertManyCatalogSurcharges,
    upsertOneCatalogSurcharge,
} from '../../../store/slices/entities/payments/cat_surcharges';

const emptyState = {
    create: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    delete: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    update: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
};

export const operationsSlice = createSlice({
    name: 'operations/cat_surcharges',
    initialState: emptyState,
    reducers: {},

    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        // Handle CreateConcept actions
        builder.addCase(createCatSurcharge.rejected, (state, action) => {
            state.create.status = 'rejected';
            state.create.feedback = action.payload.feedback;
        });
        builder.addCase(createCatSurcharge.fulfilled, (state) => {
            state.create.status = 'fulfilled';
        });
        builder.addCase(createCatSurcharge.pending, (state) => {
            state.create.status = 'pending';
        });

        //handle DeleteConcept actions
        builder.addCase(DeleteCatSurcharges.rejected, (state, action) => {
            state.delete.status = 'rejected';
            state.delete.feedback = action.payload.feedback;
        });
        builder.addCase(DeleteCatSurcharges.fulfilled, (state) => {
            state.delete.status = 'fulfilled';
        });
        builder.addCase(DeleteCatSurcharges.pending, (state) => {
            state.delete.status = 'pending';
        });

        // Handle UpdateConcept actions
        builder.addCase(UpdateCatSurcharges.rejected, (state, action) => {
            state.update.status = 'rejected';
            state.update.feedback = action.payload.feedback;
        });
        builder.addCase(UpdateCatSurcharges.fulfilled, (state) => {
            state.update.status = 'fulfilled';
        });
        builder.addCase(UpdateCatSurcharges.pending, (state) => {
            state.update.status = 'pending';
        });
    },
});

//export const {} = operationsSlice.actions;

export default operationsSlice.reducer;

export const selectOperations = createSelector(
    selectPaymentCatSurcharge,
    (reports) => reports.operations
);

export const selectCreate = createSelector(
    selectOperations,
    (operations) => operations.create
);

export const selectCreateOperation = createSelector(
    selectCreate,
    (create) => create.status
);

export const selectStatusOperation = (state) =>
    state.createConcepts.operations.create.statusOperation;

/**
 * Agregar un concepto
 */

export const createCatSurcharge = createAsyncThunk(
    'Concepts/createSurcharge',
    async ({ schoolId, data }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.addSurchargesCatalog(
                schoolId,
                data
            );

            const Surcharges = response.data;

            thunkAPI.dispatch(upsertManyCatalogSurcharges(Surcharges));

            return { Surcharges };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Eliminar  conceptos
 */

export const DeleteCatSurcharges = createAsyncThunk(
    'Concepts/DeleteSurcharges',
    async (data, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.deleteSurchargeCatalog(data);
            const deletedSurchargesIds = response.data.data;

            thunkAPI.dispatch(upsertOneCatalogSurcharge(deletedSurchargesIds));

            return { data };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar conceptos
 */

export const UpdateCatSurcharges = createAsyncThunk(
    'Concepts/UpdateSurcharges',
    async (data, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.updateCatalogSurcharges({ data });
            const Surcharges = response.data.data;

            thunkAPI.dispatch(upsertManyCatalogSurcharges(Surcharges));

            return { Surcharges };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
