import * as _ from 'lodash';
import React, { useState, useEffect } from 'react';
import {
    Alert,
    Avatar,
    Box,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    MenuItem,
    TextField,
    Typography,
    Select,
    Stack,
} from '@mui/material';
import AddStudentStepperModal from '../components/AddStudentStepperModal';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../hooks';
import { LoadingButton } from '@mui/lab';
import Services from '../../../service/Connection';
import {
    selectActivateTracedStudentStatus,
    assignStudentToGroups,
} from '../../../store/slices/studentsUI/operationsSlice';
import useFeedback from '../../../hooks/useFeedback';
import { selectAllGroups } from '../../../store/slices/entities/groups';
import { Transfer } from 'antd';
import { getTurnLevel, getLevelName } from '../../../libs/utils';
import { selectThemeBySchool } from '../../../store/slices/entities/settings';

////////////////////////////////////// ICONS /////////////////////////////////////////////////

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import GroupsIcon from '@mui/icons-material/Groups';
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';

/**
 * Modal para actualizar o agregar alumno
 *
 * @param {*} param0
 *
 * @returns
 */
const AddTracedStudentModal = ({ openTraced, setOpenTraced }) => {
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const feedbackApp = useFeedback();
    const dispatch = useDispatch();

    ////////////////// SHARED STATE /////////////////

    const groups = useSelector(selectAllGroups);
    const statusOperation = useSelector(selectActivateTracedStudentStatus);
    const { colorTitleBar, fontColor } = useSelector(
        selectThemeBySchool(schoolId)
    );

    const principalGroups = groups
        .filter((group) => group.group_type === 1)
        .sort((a, b) => {
            if (a.group > b.group) return 1;
            if (a.group < b.group) return -1;
            return 0;
        });
    const specialGroups = groups.filter(
        (group) => ![1, 4].includes(group.group_type)
    );

    const uniqueLevelsLodash = _.uniqBy(principalGroups, 'level').map(
        (option) => option.level
    );

    ////////////////////////////////////// LOCAL STATE /////////////////////////////////////////////////

    const [idInput, setIdInput] = useState('');
    const [student, setStudent] = useState(null);
    const [error, setError] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState('idle');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [groupTargetKeys, setGroupTargetKeys] = useState([]);
    const [groupSelectedKeys, setGroupSelectedKeys] = useState([]);
    const [openAddStudentModal, setOpenAddStudentModal] = useState(false);

    ////////////////////////////////////// ACTIONS /////////////////////////////////////////////////

    /**
     * Realizar la busqueda del alumno
     */
    const searchTracedStudent = async () => {
        const isNumeric = /^\d+$/.test(idInput);
        const isValidCURP = /^[A-Z]{4}\d{6}[HM][A-Z]{5}[A-Z\d]{2}$/.test(
            idInput
        );

        if (!isNumeric && !isValidCURP) {
            setError(true);
            setLoadingStatus('idle');
            return;
        }
        try {
            setLoadingStatus('pending');
            let student;

            if (isNumeric) {
                student = await Services.getStudentById(idInput, {
                    'not-calculate-properties': true,
                }).then((response) => response.data.data);
            } else if (isValidCURP) {
                student = await Services.searchStudentByCURP(idInput).then(
                    (response) => response.data.data[0]
                );
            }

            if (!student) {
                setError(true);
                setLoadingStatus('idle');
                return;
            }

            const group = await Services.getGroupByStudentFilter(
                student.student_id,
                {
                    'not-calculate-properties': true,
                }
            ).then((res) => res.data.data);
            const parents = await Services.getParentsByStudent(
                student.student_id,
                {
                    'not-calculate-properties': true,
                }
            ).then((res) => res.data.data);
            const school = await Services.getSchoolById(student.school_id, {
                'not-calculate-properties': true,
            }).then((res) => res.data.data);

            const parentsNames = parents.map((parent) => {
                return `${parent.name} ${parent.last_name} ${parent.second_last_name}`;
            });

            let mergedData = {
                ...student,
                group: group,
                parents: parents,
                school: school,
                parentsNames: parentsNames.join(),
            };
            setIdInput('');
            setSelectedGroup('');
            setGroupSelectedKeys([]);
            setGroupTargetKeys([]);
            setStudent(mergedData);
            setError(false);
            setLoadingStatus('fullfiled');
        } catch {
            setStudent(null);
            setError(true);
            setLoadingStatus('idle');
        }
    };

    /**
     * Registrar nuevo alumno en la escuela
     */
    const saveStudent = () => {
        if (schoolId === student.school_id) {
            feedbackApp.showFeedback({
                title: 'No es posible realizar este proceso para estudiantes de tu misma escuela',
            });
        } else {
            setLoadingStatus('pending');
            dispatch(
                assignStudentToGroups({
                    student,
                    studentId: student.student_id,
                    principalGroupId: selectedGroup,
                    specialGroups: groupTargetKeys,
                    schoolId,
                })
            )
                .unwrap()
                .then((data) => {
                    setOpenTraced(false);
                    feedbackApp.showFeedback({
                        title: 'Alumno agregado a grupo',
                    });
                    setLoadingStatus('idle');
                })

                .catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback ? feedback.title : 'Error desconocido',
                    });
                    setOpenTraced(false);
                    setLoadingStatus('idle');
                });
        }
    };

    useEffect(() => {
        if (openTraced) {
            setIdInput('');
            setError(null);
            setStudent(null);
            setSelectedGroup('');
            setGroupTargetKeys([]);
            setGroupSelectedKeys([]);
        }
    }, [openTraced]);

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpenTraced(false);
    };

    const handleChange = (event) => {
        setSelectedGroup(event.target.value);
    };

    const showAddStudentModal = () => {
        setOpenTraced(false);
        setOpenAddStudentModal(true);
    };

    ////////////////////////////////////// TRANSFER /////////////////////////////////////////////////

    /**
     * Una funciona que es llamada cuando la tranferencias entre columnas se ha completado
     *
     * @param {*} nextTargetKeys
     * @param {*} direction
     * @param {*} moveKeys
     */
    const onChange = (nextTargetKeys) => {
        setGroupTargetKeys(nextTargetKeys);
    };

    /**
     *  Funcion que es ejecutada cuendo los elementos selecionados han cambiado
     *
     * @param {*} sourceSelectedKeys
     * @param {*} targetSelectedKeys
     */
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setGroupSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    /**
     * Filtro para la busqueda
     */

    const removeAccents = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    const filterOption = (inputValue, option) => {
        const search = removeAccents(
            inputValue.toLowerCase().replace(/\s/g, '')
        );

        const titleNoSpace = removeAccents(
            `${option.grade} ${option.group} ${getTurnLevel(option.turn)}`
                .toLowerCase()
                .replace(/\s/g, '')
        );
        const titleMatch = titleNoSpace.includes(search);

        return titleMatch;
    };

    const OptionStepperSubjects = () => {
        const selectedLevel = principalGroups.find(
            (group) => group.group_id === selectedGroup
        )?.level;

        const filteredSpecialGroups = specialGroups.filter(
            (group) => group.level === selectedLevel
        );

        return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>
                    <Stack mt={-3}>
                        <Divider sx={{ mt: 4 }}>
                            <Typography
                                variant="h6"
                                component="div"
                                style={{ textAlign: 'center' }}
                            >
                                Asignar grupo adicional
                            </Typography>
                        </Divider>
                        <Container>
                            <Transfer
                                style={{ marginTop: '20px' }}
                                showSearch
                                filterOption={filterOption}
                                listStyle={{
                                    width: 265,
                                    height: 350,
                                }}
                                dataSource={filteredSpecialGroups.map(
                                    (group) => ({
                                        ...group,
                                        key: group.group_id,
                                    })
                                )}
                                locale={{
                                    notFoundContent: [
                                        'Sin grupos especiales',
                                        'Sin grupos especiales',
                                    ],
                                    searchPlaceholder: 'Buscar aquí',
                                    itemUnit: 'Grupo',
                                    itemsUnit: 'Grupos',
                                }}
                                titles={['Disponibles', 'Asignadas']}
                                targetKeys={groupTargetKeys}
                                selectedKeys={groupSelectedKeys}
                                onChange={onChange}
                                onSelectChange={onSelectChange}
                                searchPlaceholder="Buscar grupos"
                                render={(item) =>
                                    `${item.grade} ${
                                        item.group
                                    } - ${getTurnLevel(item.turn)}`
                                }
                            />
                        </Container>
                    </Stack>
                </Box>
            </Box>
        );
    };

    const getUserAlertContent = () => {
        if (loadingStatus === 'fullfiled') {
            if (student?.status === 0 && student?.school_id === schoolId) {
                return (
                    <Alert severity="info" sx={{ mt: 2 }}>
                        El Alumno con el ID{' '}
                        <strong>{student?.student_id}</strong> (
                        <strong>{student?.name}</strong>{' '}
                        <strong>{student?.last_name}</strong>) y CURP:{' '}
                        <strong>{student?.curp}</strong> ya se encuentra
                        inactivo en esta misma escuela:{' '}
                        <strong>{student?.school.name}</strong>, CCT:{' '}
                        <strong>{student?.school.clave}</strong> en{' '}
                        <strong>{student?.school.location}</strong> Tel.{' '}
                        <strong>{student?.school.cellphone}</strong>. Para
                        activarlo por favor utiliza la columna de acciones en la
                        tabla de alumnos.
                    </Alert>
                );
            } else if (
                student?.status === 1 &&
                student?.school_id !== schoolId
            ) {
                return (
                    <Alert severity="warning" sx={{ mt: 2 }}>
                        El Alumno con el ID{' '}
                        <strong>{student?.student_id}</strong> (
                        <strong>{student?.name}</strong>{' '}
                        <strong>{student?.last_name}</strong>) y CURP:{' '}
                        <strong>{student?.curp}</strong> ya se encuentra activo
                        en la escuela: <strong>{student?.school.name}</strong>,
                        CCT: <strong>{student?.school.clave}</strong> en{' '}
                        <strong>{student?.school.location}</strong> Tel.{' '}
                        <strong>{student?.school.cellphone}</strong>. Debe estar
                        inactivo para poder darlo de alta en tu escuela.
                    </Alert>
                );
            } else if (
                student?.status === 1 &&
                student?.school_id === schoolId
            ) {
                return (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        El Alumno con el ID{' '}
                        <strong>{student?.student_id}</strong> (
                        <strong>{student?.name}</strong>{' '}
                        <strong>{student?.last_name}</strong>) y CURP:{' '}
                        <strong>{student?.curp}</strong> ya se encuentra activo
                        en esta misma escuela:{' '}
                        <strong>{student?.school.name}</strong>, CCT:{' '}
                        <strong>{student?.school.clave}</strong> en{' '}
                        <strong>{student?.school.location}</strong> Tel.{' '}
                        <strong>{student?.school.cellphone}</strong>. Es
                        necesario que el estudiante esté inactivo para proceder
                        con su registro en una escuela distinta a la actual.
                    </Alert>
                );
            }
        }
        return 'none';
    };

    const displayStyle = getUserAlertContent() === 'none' ? 'none' : '';

    return (
        <>
            <Dialog
                open={openTraced}
                fullWidth={true}
                maxWidth={student ? 'md' : 'sm'}
                disableEscapeKeyDown={loadingStatus === 'pending'}
                onClose={handleClose}
            >
                <DialogTitle
                    style={{
                        backgroundColor: colorTitleBar.value,
                        color: fontColor.value,
                    }}
                >
                    <div style={{ display: 'flex', color: fontColor.value }}>
                        <Typography
                            variant="h6"
                            component="div"
                            style={{ flexGrow: 1 }}
                        >
                            Agregar alumno <br />
                            <span style={{ fontSize: '15px' }}>
                                Existente en CURA
                            </span>
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent dividers sx={{ overflowX: 'hidden' }}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mb: 2 }}
                    >
                        <TextField
                            label="Buscar por ID o CURP"
                            value={idInput}
                            onChange={(e) => setIdInput(e.target.value)}
                            fullWidth
                            autoFocus
                        />
                        <LoadingButton
                            startIcon={<SearchIcon />}
                            loading={loadingStatus === 'pending'}
                            loadingPosition="start"
                            variant="contained"
                            onClick={searchTracedStudent}
                            sx={{ mt: { xs: 1, sm: 0 }, ml: { xs: 0, sm: 2 } }}
                        >
                            Buscar
                        </LoadingButton>
                    </Stack>
                    {error === false ? (
                        <Box>
                            {student?.status === 0 &&
                            student?.school_id !== schoolId ? (
                                <Box>
                                    <Stack
                                        sx={{ textAlign: 'center', mr: -22 }}
                                        divider={
                                            <Divider
                                                orientation="vertical"
                                                flexItem
                                            />
                                        }
                                        direction={{ xs: 'column', sm: 'row' }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <div>
                                            <Avatar
                                                alt="Foto del Alumno"
                                                src={student.url_photo_profile}
                                                sx={{
                                                    width: 150,
                                                    height: 150,
                                                    marginTop: 2,
                                                }}
                                            />
                                            <Typography sx={{ ml: -4, mt: 2 }}>
                                                {`${student.name} ${student.last_name} ${student.second_last_name}`}
                                            </Typography>
                                            <Typography sx={{ ml: -4, mt: 2 }}>
                                                {`ID: ${student.student_id}`}
                                            </Typography>
                                            <Typography sx={{ ml: -4, mt: 2 }}>
                                                {`CURP: ${student.curp}`}
                                            </Typography>
                                        </div>
                                        <List
                                            sx={{
                                                width: '100%',
                                                maxWidth: 360,
                                                bgcolor: 'background.paper',
                                            }}
                                        >
                                            <ListItem sx={{ marginBottom: -2 }}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <SchoolIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Escuela"
                                                    secondary={`${student.school.clave} - ${student.school.name}`}
                                                />
                                            </ListItem>
                                            <ListItem sx={{ marginBottom: -2 }}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <PeopleIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Padres"
                                                    secondary={
                                                        student.parents.length >
                                                        0
                                                            ? student.parentsNames
                                                            : 'El alumno no tiene padres vinculados'
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem sx={{ marginBottom: -2 }}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <GroupsIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <FormControl
                                                    sx={{
                                                        m: 1,
                                                        minWidth: 270,
                                                        mb: 1,
                                                    }}
                                                >
                                                    <InputLabel
                                                        htmlFor="grouped-select"
                                                        shrink={
                                                            selectedGroup !== ''
                                                        }
                                                    >
                                                        Seleccionar grupo
                                                        principal
                                                    </InputLabel>
                                                    <Select
                                                        id="grouped-select"
                                                        label="Seleccionar grupo principal"
                                                        value={selectedGroup}
                                                        onChange={handleChange}
                                                        displayEmpty
                                                    >
                                                        {uniqueLevelsLodash
                                                            .sort(
                                                                (a, b) => a - b
                                                            )
                                                            .map((level) => {
                                                                const uniqueGroups =
                                                                    new Set();

                                                                const groupsByLevel =
                                                                    principalGroups
                                                                        .filter(
                                                                            (
                                                                                group
                                                                            ) =>
                                                                                group.level ===
                                                                                level
                                                                        )
                                                                        .sort(
                                                                            (
                                                                                a,
                                                                                b
                                                                            ) => {
                                                                                if (
                                                                                    a.turn !==
                                                                                    b.turn
                                                                                ) {
                                                                                    return String(
                                                                                        a.turn
                                                                                    ).localeCompare(
                                                                                        String(
                                                                                            b.turn
                                                                                        )
                                                                                    );
                                                                                } else if (
                                                                                    a.grade !==
                                                                                    b.grade
                                                                                ) {
                                                                                    return (
                                                                                        a.grade -
                                                                                        b.grade
                                                                                    );
                                                                                } else {
                                                                                    return String(
                                                                                        a.group
                                                                                    ).localeCompare(
                                                                                        String(
                                                                                            b.group
                                                                                        )
                                                                                    );
                                                                                }
                                                                            }
                                                                        )
                                                                        .filter(
                                                                            (
                                                                                group
                                                                            ) => {
                                                                                const key = `${group.grade}-${group.group}-${group.turn}`;
                                                                                if (
                                                                                    uniqueGroups.has(
                                                                                        key
                                                                                    )
                                                                                ) {
                                                                                    return false;
                                                                                }
                                                                                uniqueGroups.add(
                                                                                    key
                                                                                );
                                                                                return true;
                                                                            }
                                                                        );

                                                                return [
                                                                    <ListSubheader
                                                                        key={
                                                                            level
                                                                        }
                                                                        value={
                                                                            level
                                                                        }
                                                                    >
                                                                        {getLevelName(
                                                                            level
                                                                        )}
                                                                    </ListSubheader>,
                                                                    ...groupsByLevel.map(
                                                                        (
                                                                            group
                                                                        ) => (
                                                                            <MenuItem
                                                                                key={
                                                                                    group.group_id
                                                                                }
                                                                                value={
                                                                                    group.group_id
                                                                                }
                                                                            >
                                                                                {`${
                                                                                    group.grade
                                                                                } - ${
                                                                                    group.group
                                                                                } - ${getTurnLevel(
                                                                                    group.turn
                                                                                )}`}
                                                                            </MenuItem>
                                                                        )
                                                                    ),
                                                                ];
                                                            })}
                                                    </Select>
                                                </FormControl>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <OptionStepperSubjects />
                                    </Box>
                                </Box>
                            ) : (
                                <Grid
                                    sx={{
                                        marginTop: 3,
                                        paddingRight: 5,
                                        display: displayStyle,
                                    }}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    {getUserAlertContent()}
                                </Grid>
                            )}
                        </Box>
                    ) : (
                        <Grid
                            sx={{
                                marginTop: 3,
                                paddingRight: 5,
                                display: error === true ? '' : 'none',
                            }}
                            container
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Alert severity="error">
                                No se ha encontrado alumno con los datos
                                proporcionados.
                            </Alert>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    {student?.status === 0 &&
                        student?.school_id !== schoolId &&
                        error !== true && (
                            <LoadingButton
                                size="small"
                                color="primary"
                                startIcon={<SaveIcon />}
                                loading={loadingStatus === 'pending'}
                                loadingPosition="start"
                                variant="contained"
                                disabled={
                                    student?.status === 1 &&
                                    schoolId === student?.school_id
                                }
                                onClick={() => {
                                    saveStudent();
                                }}
                            >
                                Guardar
                            </LoadingButton>
                        )}
                    {error === true && (
                        <LoadingButton
                            size="small"
                            color="primary"
                            loading={loadingStatus === 'pending'}
                            loadingPosition="start"
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={showAddStudentModal}
                            disabled={statusOperation === 'pending'}
                        >
                            Agregar Nuevo
                        </LoadingButton>
                    )}
                    <LoadingButton
                        size="small"
                        color="primary"
                        loading={loadingStatus === 'pending'}
                        loadingPosition="start"
                        variant="contained"
                        onClick={() => {
                            setOpenTraced(false);
                        }}
                        disabled={statusOperation === 'pending'}
                    >
                        Cerrar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <AddStudentStepperModal
                open={openAddStudentModal}
                setOpen={setOpenAddStudentModal}
                initialCURP={idInput}
            />
        </>
    );
};

export default AddTracedStudentModal;
