import React, { useEffect, useState } from "react";
import {
    Stepper,
    Step,
    Button,
    Stack,
    Container,
    StepButton,
    Tooltip,
    IconButton
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import SendIcon from '@mui/icons-material/Send';
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import UsersStep from "./components/UsersStep";
import SendNoticeStep from "./components/SendNoticeStep";
import useFeedback from "../../../hooks/useFeedback";
import { useAuth } from "../../../hooks";
import {
    finalStep, nextStep, previusStep, selectCompletedSteps, selectCurrentStep,
    selectUsersSelected
} from "../../../store/slices/noticesModalUI/modes/usersModeSlice";
import { selectStatusOperation, storeNotice } from "../../../store/slices/noticesModalUI/uiSlice";


/**
 * Componente encargado de gestionar el proceso de envio de
 * notificacines usando el enfoque de alumnos
 * 
 * @param onSave Callback que sera llamado cada vez que se realize un registro 
 * 
 * @returns 
 */
const UsersDistributionMode = ({ onSave }) => {

    const Auth = useAuth()
    const dispatch = useDispatch()
    const feedbackApp = useFeedback()

    ///////////////////// SHARED STATE //////////////////////

    const activeStep = useSelector(selectCurrentStep)
    const completed = useSelector(selectCompletedSteps)

    /**
     * Elementos de los pasos que ha sido seleccionados 
     */
    let usersSelected = useSelector(selectUsersSelected)

    let statusOperation = useSelector(selectStatusOperation)

    ///////////////////// LOCAL STATE ///////////////////////

    /**
     * Pasos disponibles atravez del flujo
     */
    const steps = {
        0: {
            key: 0,
            label: 'Usuarios',
            start: true,
            last: false,
        },
        1: {
            key: 1,
            label: 'Finalización',
            start: false,
            last: true
        }
    };

    /////////////////// FUNCTIONS ///////////////////////////

    /**
     * Funcion para el paso anterior
     */
    const handlerPreviusStep = () => {
        dispatch(previusStep())
    };

    /**
     * Funcion para el siguiente paso
     */
    const handlernextStep = () => {
        dispatch(nextStep(steps[activeStep]))
    }

    /**
     * Ir a paso para enviar la notificacion
     */
    const onGoFinalizeStep = () => {
        dispatch(finalStep(steps[activeStep]))
    }

    /**
     * Enviar notificaciones 
     * 
     * @returns 
     */
    const sendNotice = () => {
        dispatch(storeNotice({ userId: Auth.getUserID(), distributionMode: 'users' }))
            .unwrap()
            .then(response => {
                feedbackApp.showFeedback({
                    title: "Aviso enviado correctamente"
                })
                onSave("Aviso enviado correctamente")
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
                onSave(feedback.title)
            })
    }


    /////////////////////// VALIDACIONES ////////////////////

    /**
     * Validacion para el boron de siguiente
     * 
     * @returns 
     */
    const disabledHandler = () => {

        if (steps[activeStep].last) {
            return true
        }

        if (activeStep == 0 && usersSelected.length == 0) {
            return true
        }

        return false
    }


    return (
        <>
            <Stack
                direction={"row"}
                justifyContent="space-evenly"
                alignItems="center"
                sx={{ mb: 2 }}
            >
                <Stepper
                    nonLinear activeStep={activeStep}
                    sx={{ flexGrow: 2 }}
                >
                    {Object.values(steps).map((step) => (
                        <Step key={step.key} completed={completed[step.key]}>
                            <StepButton color="inherit">
                                {step.label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems={"center"}
                    style={{ textAlign: "right", flexGrow: 1 }}
                >

                    <Tooltip title="Regresar">
                        <IconButton
                            aria-label="Regresar"
                            color="primary"
                            onClick={() => handlerPreviusStep()}
                            disabled={steps[activeStep].start}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>
                    {/*}
                        <Tooltip title="Siguiente">
                            <IconButton
                                aria-label="Siguiente"
                                color="primary"
                                onClick={() => handlernextStep()}
                                disabled={disabledHandler()}
                        >
                                <ArrowForwardIcon />
                            </IconButton>
                        </Tooltip>
                        */}

                    {
                        !steps[activeStep].last && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onGoFinalizeStep}
                                size="small"
                                startIcon={<DoneIcon />}
                                disabled={disabledHandler()}
                            >
                                Finalizar
                            </Button>
                        )
                    }

                    {
                        steps[activeStep].last && (
                            <LoadingButton
                                endIcon={<SendIcon />}
                                loadingPosition="end"
                                size="small"
                                variant="contained"
                                onClick={sendNotice}
                                loading={statusOperation == "pending"}
                            >
                                Enviar
                            </LoadingButton>
                        )
                    }
                </Stack>
            </Stack>

            <Container>
                {
                    activeStep == 0 && (<UsersStep />)
                }

                {
                    activeStep == 1 && (<SendNoticeStep />)
                }
            </Container>
        </>
    )
}


export default UsersDistributionMode