///////////////////////////////////////////// FETCH //////////////////////////////////////////////////

export const selectComponentHomeBestScoreStudentsFetch = (store) =>
    store.moduleDirector.home.components.best_score_students.fetch;

export const selectComponentHomeAssistanceIndexFetch = (store) =>
    store.moduleDirector.home.components.assistance_index.fetch;

export const selectComponentHomeAverageImcSchoolFetch = (store) =>
    store.moduleDirector.home.components.average_imc_school.fetch;

export const selectComponentHomeBestScoreSubjectFetch = (store) =>
    store.moduleDirector.home.components.best_score_subject.fetch;

export const selectComponentHomeFrequentReportsFetch = (store) =>
    store.moduleDirector.home.components.frequent_reports.fetch;

export const selectComponentHomeGradeAverageFetch = (store) =>
    store.moduleDirector.home.components.grade_average.fetch;

export const selectComponentHomeTotalStudentsFetch = (store) =>
    store.moduleDirector.home.components.total_students.fetch;

export const selectComponentHomeTotalProfessorsFetch = (store) =>
    store.moduleDirector.home.components.total_professors.fetch;

export const selectComponentHomeSchoolAverageFetch = (store) =>
    store.moduleDirector.home.components.school_average.fetch;

export const selectComponentHomeTotalAbscenceFetch = (store) =>
    store.moduleDirector.home.components.total_abscence.fetch;

export const selectComponentHomeTotalStudentWithoutGroupFetch = (store) =>
    store.moduleDirector.home.components.total_students_without_group.fetch;

export const selectComponentHomeTotalReportsFetch = (store) =>
    store.moduleDirector.home.components.total_reports.fetch;

export const selectComponentHomeTotalScholarshipFetch = (store) =>
    store.moduleDirector.home.components.total_scholarship.fetch;

export const selectComponentHomeTotalRegisteredParentsFetch = (store) =>
    store.moduleDirector.home.components.total_registered_parents.fetch;

export const selectComponentHomeTotalIncidentsFetch = (store) =>
    store.moduleDirector.home.components.total_incidents.fetch;

export const selectComponentHomeLastAttendanceProfessorFetch = (store) =>
    store.moduleDirector.home.components.last_attendance_professor.fetch;

export const selectComponentHomeStudentsDiversityFetch = (store) =>
    store.moduleDirector.home.components.students_diversity.fetch;

export const selectComponentHomeTotalStudentsApprovedFailedFetch = (store) =>
    store.moduleDirector.home.components.total_students_approved_failed.fetch;

export const selectComponentHomeTotalGroupsFetch = (store) =>
    store.moduleDirector.home.components.total_groups.fetch;

export const selectComponentHomeTotalMaleFemaleStudentsRegisterFetch = (
    store
) =>
    store.moduleDirector.home.components.total_male_female_students_register
        .fetch;

export const selectComponentHomeTotalMaleFemaleStudentsWithdrawnFetch = (
    store
) =>
    store.moduleDirector.home.components.total_male_female_students_withdrawn
        .fetch;

export const selectComponentHomeStudentsApprovedGenderFetch = (store) =>
    store.moduleDirector.home.components.students_approved_gender.fetch;

export const selectComponentHomeStudentsFailedGenderFetch = (store) =>
    store.moduleDirector.home.components.students_failed_gender.fetch;

export const selectComponentHomeTotalMaleFemaleStudentRegisterGradeFetch = (
    store
) =>
    store.moduleDirector.home.components
        .total_male_female_student_register_grade.fetch;

export const selectComponentHomeTotalMaleFemaleStudentsWithdrawnGradeFetch = (
    store
) =>
    store.moduleDirector.home.components
        .total_male_female_students_withdrawn_grade.fetch;

export const selectComponentHomeTotalMaleFemaleStudentsFetch = (store) =>
    store.moduleDirector.home.components.total_male_female_students.fetch;

export const selectComponentHomeStudentsApprovedFailedGenderGradeFetch = (
    store
) =>
    store.moduleDirector.home.components.students_approved_failed_gender_grade
        .fetch;

export const selectComponentHomeTotalStudentsRepeatedFetch = (store) =>
    store.moduleDirector.home.components.total_students_repeated.fetch;

export const selectComponentHomeWorstScoreStudentFetch = (store) =>
    store.moduleDirector.home.components.worst_score_student.fetch;

export const selectComponentHomeAllIncidentsFetch = (store) =>
    store.moduleDirector.home.components.incidents.fetch;

///////////////////////////////////////STATUS SERVER///////////////////////////////////////////

export const selectComponentHomeBestScoreStudentsStatusServer = (store) =>
    store.moduleDirector.home.components.best_score_students.fetch.statusServer;

export const selectComponentHomeAssistanceIndexStatusServer = (store) =>
    store.moduleDirector.home.components.assistance_index.fetch.statusServer;

export const selectComponentHomeAverageImcSchoolStatusServer = (store) =>
    store.moduleDirector.home.components.average_imc_school.fetch.statusServer;

export const selectComponentHomeBestScoreSubjectStatusServer = (store) =>
    store.moduleDirector.home.components.best_score_subject.fetch.statusServer;

export const selectComponentHomeFrequentReportsStatusServer = (store) =>
    store.moduleDirector.home.components.frequent_reports.fetch.statusServer;

export const selectComponentHomeGradeAverageStatusServer = (store) =>
    store.moduleDirector.home.components.grade_average.fetch.statusServer;

export const selectComponentHomeTotalStudentsStatusServer = (store) =>
    store.moduleDirector.home.components.total_students.fetch.statusServer;

export const selectComponentHomeTotalProfessorsStatusServer = (store) =>
    store.moduleDirector.home.components.total_professors.fetch.statusServer;

export const selectComponentHomeSchoolAverageStatusServer = (store) =>
    store.moduleDirector.home.components.school_average.fetch.statusServer;

export const selectComponentHomeTotalAbscenceStatusServer = (store) =>
    store.moduleDirector.home.components.total_abscence.fetch.statusServer;

export const selectComponentHomeTotalStudentWithoutGroupStatusServer = (
    store
) =>
    store.moduleDirector.home.components.total_students_without_group.fetch
        .statusServer;

export const selectComponentHomeTotalReportsStatusServer = (store) =>
    store.moduleDirector.home.components.total_reports.fetch.statusServer;

export const selectComponentHomeTotalScholarshipStatusServer = (store) =>
    store.moduleDirector.home.components.total_scholarship.fetch.statusServer;

export const selectComponentHomeTotalRegisteredParentsStatusServer = (store) =>
    store.moduleDirector.home.components.total_registered_parents.fetch
        .statusServer;

export const selectComponentHomeTotalIncidentsStatusServer = (store) =>
    store.moduleDirector.home.components.total_incidents.fetch.statusServer;

export const selectComponentHomeLastAttendanceProfessorStatusServer = (store) =>
    store.moduleDirector.home.components.last_attendance_professor.fetch
        .statusServer;

export const selectComponentHomeTotalMaleFemaleStudentsStatusServer = (store) =>
    store.moduleDirector.home.components.total_male_female_students.fetch
        .statusServer;

export const selectComponentHomeStudentsDiversityStatusServer = (store) =>
    store.moduleDirector.home.components.students_diversity.fetch.statusServer;

export const selectComponentHomeTotalStudentsApprovedFailedStatusServer = (
    store
) =>
    store.moduleDirector.home.components.total_students_approved_failed.fetch
        .statusServer;

export const selectComponentHomeTotalGroupsStatusServer = (store) =>
    store.moduleDirector.home.components.total_groups.fetch.statusServer;

export const selectComponentHomeTotalMaleFemaleStudentsRegisterStatusServer = (
    store
) =>
    store.moduleDirector.home.components.total_male_female_students_register
        .fetch.statusServer;

export const selectComponentHomeTotalMaleFemaleStudentsWithdrawnStatusServer = (
    store
) =>
    store.moduleDirector.home.components.total_male_female_students_withdrawn
        .fetch.statusServer;

export const selectComponentHomeStudentsApprovedGenderStatusServer = (store) =>
    store.moduleDirector.home.components.students_approved_gender.fetch
        .statusServer;

export const selectComponentHomeStudentsFailedGenderStatusServer = (store) =>
    store.moduleDirector.home.components.students_failed_gender.fetch
        .statusServer;

export const selectComponentHomeTotalMaleFemaleStudentRegisterGradeStatusServer =
    (store) =>
        store.moduleDirector.home.components
            .total_male_female_student_register_grade.fetch.statusServer;

export const selectComponentHomeTotalMaleFemaleStudentsWithdrawnGradeStatusServer =
    (store) =>
        store.moduleDirector.home.components
            .total_male_female_students_withdrawn_grade.fetch.statusServer;

export const selectComponentHomeStudentsApprovedFailedGenderGradeStatusServer =
    (store) =>
        store.moduleDirector.home.components
            .students_approved_failed_gender_grade.fetch.statusServer;

export const selectComponentHomeTotalStudentsRepeatedStatusServer = (store) =>
    store.moduleDirector.home.components.total_students_repeated.fetch
        .statusServer;

export const selectComponentHomeWorstScoreStudentStatusServer = (store) =>
    store.moduleDirector.home.components.worst_score_student.fetch.statusServer;

export const selectComponentHomeAllIncidentsStatusServer = (store) =>
    store.moduleDirector.home.components.incidents.fetch.statusServer;
