import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Services from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import Authentication from '../../../service/Login';
import { PROFESSORS_SUBJECT_EXPIRE_TIME } from '../../../service/const';

import {
    selectAllReports,
    upsertManyReports,
} from '../../../store/slices/entities/reports';
import {
    selectAssesorByGroup,
    selectParents,
    selectProfessors,
    upsertManyUsers,
} from '../../../store/slices/entities/users';
import {
    selectRelationshipByStudentId,
    upsertManyRelationships,
} from '../../../store/slices/entities/relationships';
import {
    selectAllFiles,
    upsertManyFiles,
} from '../../../store/slices/entities/files';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import { selectStudentById } from '../../../store/slices/entities/students';
import { selectGroupsById } from '../../../store/slices/entities/groups';
import {
    selectAllNotes,
    upsertManyNotes,
} from '../../../store/slices/entities/notes';

import { selectSelectedGroup } from '../home/uiSlice';
import { selectReportGroup } from '../group/selectors';
import { selectReportStudent } from './selectors';
import * as _ from 'lodash';

const emptyState = [];

export const student = createSlice({
    name: 'student',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, { payload }) => {
            state[payload].fetch.didInvalidate = true;
        },
        addStudentsViewModels: (state, action) => {
            return {
                ...state,
                ...action.payload.reduce((accumulator, item) => {
                    accumulator[item.id] = item;
                    return accumulator;
                }, {}),
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        // /**
        //  * Recuperar informacion adicional de la materia
        //  */
        builder.addCase(
            loadReportsDetailsUI.fulfilled,
            (state, { payload }) => {
                const { studentUUID } = payload;

                state[studentUUID].fetch.expireIn = new Date().setMinutes(
                    new Date().getMinutes() + PROFESSORS_SUBJECT_EXPIRE_TIME
                );
                state[studentUUID].fetch.status = 'fulfilled';
                state[studentUUID].fetch.fetchingAt = Date.now();
                state[studentUUID].fetch.didInvalidate = false;
            }
        );
        builder.addCase(loadReportsDetailsUI.pending, (state, { meta }) => {
            const { studentUUID } = meta;

            state[studentUUID].fetch.status = 'pending';
        });
        builder.addCase(loadReportsDetailsUI.rejected, (state, action) => {
            if (action.payload) {
                const { studentUUID } = action.payload;
                //state[groupId].servers.details.feedback = feedback

                state[studentUUID].fetch.status = 'rejected';
            }
        });
    },
});

export const { invalidate, addStudentsViewModels } = student.actions;

//////// SELECTORS /////////

/**
 * @param {*} state
 * seleccionar viewModels del slice
 * de estudiante
 */
export const selectStudentsViewModels = (state) => state.student;
/**
 *
 * @param {*} store
 * @returns Retorna el estado de la petición
 * para lazy loading
 */
export const selectStatusServer = createSelector(
    selectSelectedGroup,
    selectReportGroup,
    selectReportStudent,
    (selectedGroup, reportGroup, reportStudent) => {
        let studentUUID = reportGroup[selectedGroup].ui.studentSelected;

        return reportStudent[studentUUID].fetch.status;
    }
);

/**
 * @param {*} store
 * selecciona el estudiante seleccionado
 */
export const selectStudentSelected = createSelector(
    selectSelectedGroup,
    selectReportGroup,
    (selectedGroup, reportGroup) => {
        return reportGroup[selectedGroup].ui.studentSelected;
    }
);

/**
 *
 * @param {*} studentId
 * @param {*} groupId
 * @returns Selecciona los datos necesarios para la vista de ReportsStudentsDetails
 */
export const selectReportsData = (studentId, groupId) =>
    createSelector(
        selectMergedFilesAndReportsByStudentId(studentId, groupId),
        selectAssesorByGroup(groupId),
        selectStudentById(studentId),
        selectMergedParentAndRelationshipByStudentId(studentId),
        selectGroupsById(groupId),
        (
            filesAndReportsByStudent,
            assesorByGroup,
            studentData,
            parentsByStudent,
            group
        ) => {
            return {
                filesAndReportsByStudent,
                assesorByGroup,
                studentData,
                parentsByStudent,
                group,
            };
        }
    );

/**
 *
 * @param {*} studentId
 * @returns  Selecciona los padres del alumno por ID del alumno
 */
export const selectMergedParentAndRelationshipByStudentId = (studentId) =>
    createSelector(
        selectParents,
        selectRelationshipByStudentId(studentId),
        (parents, relationshipsByStudent) => {
            const mergedParentsAndRelationship = parents
                .filter((parent) =>
                    relationshipsByStudent.some(
                        (relation) => relation.parent_id === parent.user_id
                    )
                )
                .map((parent) => ({
                    ...parent,
                    relationship: relationshipsByStudent.find(
                        (relation) => relation.parent_id === parent.user_id
                    ),
                }));

            return mergedParentsAndRelationship;
        }
    );

/**
 *
 * @param {*} studentId
 * @returns Selecciona expedientes en base al Id del estudiante
 */
export const selectFilesByStudenId = (studentId) =>
    createSelector(selectAllFiles, (files) => {
        return files.filter((file) => file.student_id === Number(studentId));
    });

/**
 *
 * @param {*} studentId
 * @returns Selecciona reportes en base al Id del estudiante
 */
export const selectReportsByStudents = (studentId) =>
    createSelector(
        selectFilesByStudenId(studentId),
        selectAllReports,
        (filesByStudent, reports) => {
            return Object.entries(reports)
                .filter(([, report]) =>
                    filesByStudent.some(
                        (file) => file.file_id === report.file_id
                    )
                )
                .map(([, report]) => report);
        }
    );

/**
 *
 * @param {*} studentId
 * @returns Selecciona los expedientes y les anida su respectivos
 * reportes al igual que al resporte se le añade la entidad
 * de profesor
 */
export const selectMergedFilesAndReportsByStudentId = (studentId, groupId) =>
    createSelector(
        selectFilesByStudenId(studentId),
        selectReportsByStudents(studentId),
        selectProfessors,
        selectAllNotes,
        (files, reports, professors, notes) => {
            const mergedFilesWithReports = files
                .filter((element) => element.group_id === Number(groupId))
                .map((file) => {
                    const fileReports = reports.filter(
                        (report) => report.file_id === file.file_id
                    );
                    const fileNotes = notes.filter(
                        (note) => note.file_id === file.file_id
                    );
                    const reportsWithProfessors = fileReports.map((report) => {
                        const professor = professors.find(
                            (prof) => prof.user_id === report.profesor_id
                        );
                        return {
                            ...report,
                            professor: professor,
                        };
                    });
                    return {
                        ...file,
                        reports: reportsWithProfessors,
                        notes: fileNotes,
                    };
                });

            return mergedFilesWithReports;
        }
    );

/////////// THUNKS ///////////

/**
 * Thunk para cargar los datos de reporte por alumno
 */

export const loadReportsDetailsUI = createAsyncThunk(
    'newReports/student/fetch/data',
    async ({ studentId, schoolId }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        const state = thunkAPI.getState();

        const groupUUID = state.newReports.home.ui.groupSelected;
        const studentUUID =
            state.newReports.group[groupUUID].ui.studentSelected;

        try {
            let reports = await Services.getReportsByStudentId(studentId).then(
                (i) => i.data.data
            );
            let professors = await Services.professorsBySchool(schoolId).then(
                (i) => i.data.data
            );

            let reporters = [];
            for (let report of reports) {
                let reporter = reporters.find(
                    (i) => i.professor_id === report.profesor_id
                );

                if (!reporter) {
                    let reporter = professors.find(
                        (i) => i.professor_id === report.profesor_id
                    );

                    reporters.push(reporter);
                }
            }
            let assesors = await Services.getAssesorsActivedBySchool(
                schoolId
            ).then((i) => i.data.data);

            let parents = await Services.getParentsByStudent(studentId).then(
                (i) => i.data.data
            );

            let relationships = await Services.getRelationshipsByStudents(
                studentId
            ).then((i) => i.data.data);

            let files = await Services.getFilesByStudent(studentId).then(
                (i) => i.data.data
            );

            let notes = [];
            for (let file of files) {
                let note = await Services.getNotesByFileId(file.file_id).then(
                    (i) => i.data.data
                );

                notes.push(note);
            }
            let validNotes = _.flatten(notes);

            let fullUsers = reporters.concat(assesors, parents);

            let users = _.uniqBy(
                fullUsers.map((i) => {
                    let userId = Auth.getUserID(i);

                    return {
                        ...i,
                        user_id: userId,
                    };
                }),
                'user_id'
            );

            thunkAPI.dispatch(upsertManyReports(reports));
            thunkAPI.dispatch(upsertManyUsers(users));
            thunkAPI.dispatch(upsertManyRelationships(relationships));
            thunkAPI.dispatch(upsertManyFiles(files));
            thunkAPI.dispatch(upsertManyNotes(validNotes));

            return {
                reports,
                assesors,
                parents,
                relationships,
                studentUUID,
            };
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(error),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            const state = getState();

            const groupUUID = state.newReports.home.ui.groupSelected;
            const studentUUID =
                state.newReports.group[groupUUID].ui.studentSelected;

            let { didInvalidate, expireIn } =
                state.newReports.student[studentUUID].fetch;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
        getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
            const state = getState();

            const groupUUID = state.newReports.home.ui.groupSelected;
            return {
                studentUUID:
                    getState().newReports.group[groupUUID].ui.studentSelected,
            };
        },
    }
);

export default student.reducer;
