import React, { useEffect, useState } from 'react';
import { Typography, IconButton, Box } from '@mui/material';

import {
    DataGrid,
    esES,
    GridToolbarContainer,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { getLevelName, getTurnLevel } from '../../../../libs/utils';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import _ from 'lodash';
import { getNameSchoolLevel, getTurnsLabel } from '../../../../helper';
import { SCHOOL_LEVELS, TURNS_TYPES } from '../../../../constants';

/**
 * Componente para seleccionar grupos a intercambiar
 *
 * @param initialState Estado inicial del componente
 * @param groups Lista con todos los grupos incluso la lista groupsSelected
 * @param groupsSelected Lista de grupos seleccionados
 * @param disabled Permite deshabilitar la interaccion
 * @param onSelectGroups Callback para notificar que un grupo ha sido seleccionado
 *
 * @returns
 */
const GroupsSelector = ({
    initialState = { current: null, new: null },
    groups,
    groupsCurrentSelected,
    disabled,
    onSelectGroups,
}) => {
    ///////////////// LOCAL STATE /////////////////////

    /**
     * Grupos que el usuario va seleccionando
     */
    const [groupsSelected, setGroupsSelected] = useState(initialState);

    /**
     * Grupos actuales del alumno
     */
    const [currentGroups, setCurrentGroups] = useState([]);

    /**
     * Grupos disponibles
     */
    let [availablesGroups, setAvailablesGroups] = useState([]);

    /**
     * Definicion de las columnas de los grupos disponibles
     */
    const availableGroupscolumns = [
        {
            field: 'grade',
            headerName: 'Grado',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'group',
            headerName: 'Grupo',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'turn',
            headerName: 'Turno',
            type: 'singleSelect',
            flex: 0.5,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueOptions: TURNS_TYPES.map((i) => i.title),
            valueGetter: ({ value }) => {
                return getTurnsLabel(value);
            },
        },
        {
            field: 'level',
            headerName: 'Nivel',
            type: 'singleSelect',
            flex: 0.6,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueOptions: SCHOOL_LEVELS.map((i) => i.title),
            valueGetter: ({ value }) => {
                return getNameSchoolLevel(value);
            },
        },
        {
            field: 'subjects',
            headerName: 'Materias',
            type: 'number',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'students',
            headerName: 'Alumnos',
            type: 'number',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'group_id',
            headerName: '',
            filterable: false,
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <IconButton
                        size="small"
                        variant="contained"
                        onClick={() => {
                            onSelectGroup(params.row, 'available');
                        }}
                        disabled={params.row.selected || disabled}
                    >
                        <TouchAppIcon
                            color={params.row.selected ? 'primary' : 'disabled'}
                        />
                    </IconButton>
                );
            },
        },
    ];

    /**
     * Definicion de las columnas de los grupos actuales
     */
    const currentGroupsColumns = [
        {
            field: 'grade',
            headerName: 'Grado',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'group',
            headerName: 'Grupo',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'turn',
            headerName: 'Turno',
            flex: 0.5,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: ({ value }) => {
                return getTurnLevel(value);
            },
        },
        {
            field: 'level',
            headerName: 'Nivel',
            flex: 0.6,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: ({ value }) => {
                return getLevelName(value);
            },
        },
        {
            field: 'subjects',
            headerName: 'Materias',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'students',
            headerName: 'Alumnos',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'group_id',
            headerName: '',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <IconButton
                        size="small"
                        variant="contained"
                        onClick={() => {
                            onSelectGroup(params.row, 'current');
                        }}
                        disabled={params.row.selected || disabled}
                    >
                        <TouchAppIcon
                            color={params.row.selected ? 'primary' : 'disabled'}
                        />
                    </IconButton>
                );
            },
        },
    ];

    ///////////////// ACTIONS ///////////////////////

    /**
     * Seleccionar grupo
     *
     * @param {*} group Grupo a seleccionar
     * @param {*} category Categoria donde fue seleccionado el grupo
     */
    const onSelectGroup = (group, category) => {
        let newState = null;

        if (category == 'available') {
            let availablesGroupsAffeted = availablesGroups.map((i) => ({
                ...i,
                selected: i.group_id == group.group_id ? true : false,
            }));

            setAvailablesGroups(availablesGroupsAffeted);

            newState = {
                ...groupsSelected,
                new: group,
            };
        }

        if (category == 'current') {
            let currentGroupsAffected = currentGroups.map((i) => ({
                ...i,
                selected: i.group_id == group.group_id ? true : false,
            }));

            setCurrentGroups(currentGroupsAffected);

            newState = {
                ...groupsSelected,
                current: group,
            };
        }

        setGroupsSelected(newState);
        onSelectGroups(newState);
    };

    /**
     * Notificar de cambios el los grupos
     */
    /*useEffect(() => {
       
    }, [groupsSelected])*/

    /**
     * Efecto para agregar campos extras y no afectar a las
     * propeidades de los grupos enviados por el padre
     */
    useEffect(() => {
        setCurrentGroups(
            groupsCurrentSelected.map((i) => ({
                ...i,
                selected: groupsSelected.current?.group_id == i.group_id,
            }))
        );

        const availableGroups = _.differenceWith(
            groups,
            groupsCurrentSelected,
            (arrVal, othVal) => arrVal.group_id == othVal.group_id
        ).map((i) => ({
            ...i,
            selected: groupsSelected.new?.group_id == i.group_id,
        }));

        setAvailablesGroups(availableGroups);
    }, [groups, groupsCurrentSelected]);

    ////////////////////// VIEW //////////////////////////

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    return (
        <>
            {currentGroups.length > 0 && (
                <Box sx={{ textAlign: 'center', mb: 1 }}>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        Grupos actuales
                    </Typography>

                    <DataGrid
                        getRowId={(i) => i.group_id}
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={currentGroups}
                        columns={currentGroupsColumns}
                        pageSize={8}
                        rowsPerPageOptions={[8]}
                        autoHeight
                        density="compact"
                        disableSelectionOnClick
                        hideFooter
                    />
                </Box>
            )}

            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h6" sx={{ mb: 1 }}>
                    Grupos disponibles
                </Typography>

                <DataGrid
                    getRowId={(i) => i.group_id}
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={availablesGroups}
                    columns={availableGroupscolumns}
                    pageSize={3}
                    rowsPerPageOptions={[3]}
                    autoHeight
                    components={{ Toolbar: CustomToolbar }}
                    density="compact"
                    disableSelectionOnClick
                />
            </Box>
        </>
    );
};

export default GroupsSelector;
