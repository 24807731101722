import { useSelector } from 'react-redux';
import useThemeColors from './themes';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { useMemo } from 'react';

function reverseTokens(tokens) {
    const reversedTokens = {};
    Object.entries(tokens).forEach(([key, val]) => {
        const keys = Object.keys(val);
        const values = Object.values(val);
        const length = keys.length;
        const reversedObj = {};
        for (let i = 0; i < length; i++) {
            reversedObj[keys[i]] = values[length - i - 1];
        }
        reversedTokens[key] = reversedObj;
    });
    return reversedTokens;
}

const createThemeSettings = (mode, tokensDark, tokensLight) => ({
    palette: {
        mode,
        ...(mode === 'dark'
            ? {
                  primary: {
                      main: tokensDark.primary[500],
                  },

                  background: {
                      default: '#28243d',
                      paper: '#28243d',
                  },
                  neutral: {
                      main: tokensDark.neutral[100],
                  },
                  text: {
                      primary: '#fff',
                      secondary: tokensDark.grey[200],
                  },
              }
            : {
                  primary: {
                      main: tokensLight.primary[500],
                  },
                  background: {
                      default: '#f4f5fa',
                      paper: '#f4f5fa',
                  },
                  neutral: {
                      main: tokensLight.neutral[800],
                  },
                  text: {
                      primary: '#000',
                      secondary: tokensLight.grey[100],
                  },
              }),
    },
    typography: {
        fontFamily: 'Plus Jakarta Sans, sans-serif',
        h1: {
            fontSize: '3rem',
        },
        h2: {
            fontSize: '2.5rem',
        },
        h3: {
            fontSize: '2rem',
        },
        h4: {
            fontSize: '1.5rem',
        },
        h5: {
            fontSize: '1.25rem',
        },
        h6: {
            fontSize: '1rem',
        },
    },
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border: 'none',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '0.875rem',
                    padingRight: '-10%',
                    width: '100%',
                    '& .MuiDataGrid-virtualScroller': {
                        '&::-webkit-scrollbar': {
                            width: '8px',
                            height: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#bcb3b3',
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#bcb3b3',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#f1f1f1',
                        },
                    },
                },
                cell: {
                    borderBottom: '1px solid #e0e0e0',
                },
                columnHeaders: {
                    borderBottom: '1px solid #e0e0e0',
                    color: '#5f6368',
                    fontWeight: '600',
                },
                columnHeaderTitle: {
                    fontWeight: 'bold',
                    fontSize: '0.875rem',
                },
                row: {
                    '&:nth-of-type(odd)': {},
                    '&:nth-of-type(even)': {},
                },
                footerContainer: {
                    justifyContent: 'space-between',
                    padding: '8px 16px',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: '16px',
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: '8px',
                },
                contained: {
                    color: '#fff',
                },
                outlined: {
                    borderColor: '#e0e0e0',
                    color: '#5f6368',
                },
            },
        },
    },
});

const ThemeProviderWrapper = ({ children }) => {
    const mode = useSelector((state) => state.theme.mode);
    const tokensDark = useThemeColors();
    const tokensLight = reverseTokens(tokensDark);

    const theme = useMemo(
        () => createTheme(createThemeSettings(mode, tokensDark, tokensLight)),
        [mode, tokensDark, tokensLight]
    );

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeProviderWrapper;
