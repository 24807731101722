import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import { selectPaymentCatConcepts } from './selectors';
import {
    setAllCatalogConcepts,
    upsertManyCataloConcepts,
} from '../../../store/slices/entities/payments/cat_concepts';

// Estado inicial del slice
const emptyState = {
    expireIn: null,
    fetchingAt: null,
    status: 'idle',
    operation: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

// Thunk para realizar la petición asincrónica de conceptos de pago
export const fetchCatConcepts = createAsyncThunk(
    'cat_concepts/fetch',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            /* Se obtienen los conceptos de pago asociados a la escuela */
            const fetchCatalog =
                await Connection.getSchoolsByConceptsCatalog(schoolId);

            const concept_catalog = fetchCatalog.data.data;

            thunkAPI.dispatch(setAllCatalogConcepts(concept_catalog));

            return { concept_catalog };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

// Slice para los conceptos de pago
const catConceptsSlice = createSlice({
    name: 'cat_concepts',
    initialState: emptyState,
    reducers: {
        // Acción para invalidar los datos actuales
        invalidate: (state) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        builder
            // Manejo del estado pendiente de la petición
            .addCase(fetchCatConcepts.pending, (state) => {
                state.status = 'pending';
            })
            // Manejo del estado cumplido de la petición
            .addCase(fetchCatConcepts.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.data = action.payload.data;
                state.didInvalidate = false;
                state.fetchingAt = Date.now();
                state.expireIn = new Date().setMinutes(
                    new Date().getMinutes() + 30
                );
            })
            // Manejo del estado rechazado de la petición
            .addCase(fetchCatConcepts.rejected, (state, action) => {
                state.status = 'rejected';
            });
    },
});

export const { invalidate } = catConceptsSlice.actions;

export default catConceptsSlice.reducer;

//////////////////////////////////// SELECTORES ////////////////////////////////////
/**
 * Selector para recuperar el estado de la petición de conceptos de pago
 */
export const selectFetch = createSelector(
    selectPaymentCatConcepts,
    (state) => state.fetch
);
