import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography,
    Card,
    CardContent,
    Grid,
    Box,
    CircularProgress,
} from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { Tag } from 'antd'; // Importamos los tags de Ant Design
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectProfessors,
} from '../../../store/professors/fetchSlice';
import { getAllProfessors } from '../../../store/professors/thunks';
import DEFAULT_AVATAR_URL from './../../../../assets/images/Unknown_person.jpeg';
import { selectComponentAllProfessorsStatusServer } from '../../../store/professors/componentsStatusSelectors';
import { SkeletonProfessorsTable } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';

const AllProfessors = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentAllProfessorsStatusServer
    );

    const allProfessors = useFetchResource(
        () =>
            getAllProfessors({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectProfessors,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getAllProfessors({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectProfessors,
            [cycleSelected, schoolId]
        );
    };

    const headers = allProfessors.value ? Object.keys(allProfessors.value) : [];
    const data = allProfessors.value ? allProfessors.value : {};

    const rows =
        data[headers[0]]?.map((_, index) => {
            let row = { id: index + 1 };
            headers.forEach((header) => {
                if (header === 'Fotografía') {
                    row[header] =
                        data[header][index]?.value || DEFAULT_AVATAR_URL;
                } else {
                    row[header] = data[header][index]?.value || '';
                }
            });
            return row;
        }) || [];

    const columns = [
        {
            field: 'Fotografía',
            headerName: 'Fotografía',
            flex: 0.4,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="Foto del profesor"
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: '50%',
                        objectFit: 'cover',
                    }}
                />
            ),
        },
        {
            field: 'Nombre',
            headerName: 'Nombre',
            flex: 1,
            renderCell: (params) => (
                <Box
                    sx={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        lineHeight: '1.2',
                    }}
                >
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'Promedio de asistencias',
            headerName: 'Promedio de asistencias',
            flex: 0.6,
            renderCell: (params) => (
                <Tag
                    color="error"
                    style={{
                        width: '100px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                    }}
                >
                    {params.value || '0'}
                </Tag>
            ),
        },
        {
            field: 'Total de materias',
            headerName: 'Total de materias',
            flex: 0.5,
            renderCell: (params) => (
                <Tag
                    color="blue"
                    style={{
                        width: '100px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                    }}
                >
                    {params.value || '0'}
                </Tag>
            ),
        },
        {
            field: 'Total de grupos',
            headerName: 'Total de grupos',
            flex: 0.5,
            renderCell: (params) => (
                <Tag
                    color="blue"
                    style={{
                        width: '100px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                    }}
                >
                    {params.value}
                </Tag>
            ),
        },
        {
            field: 'Total de reportes',
            headerName: 'Total de reportes',
            flex: 0.5,
            renderCell: (params) => (
                <Tag
                    color="error"
                    style={{
                        width: '100px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                    }}
                >
                    {params.value}
                </Tag>
            ),
        },
        {
            field: 'Total de incidentes',
            headerName: 'Total de incidentes',
            flex: 0.5,
            renderCell: (params) => (
                <Tag
                    color="error"
                    style={{
                        width: '100px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                    }}
                >
                    {params.value}
                </Tag>
            ),
        },
    ];

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbar {...props} />
            </GridToolbarContainer>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonProfessorsTable items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        overflow: 'hidden',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        borderColor: '#e0e0e0',
                        mb: 4,
                    }}
                >
                    <CardContent
                        sx={{
                            width: '100%',
                            padding: '24px',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                    gutterBottom
                                >
                                    {allProfessors.title ||
                                        'Información general de los profesores'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {allProfessors.loading ? (
                                    <Box display="flex" justifyContent="center">
                                        <CircularProgress />
                                    </Box>
                                ) : allProfessors.error ? (
                                    <Typography color="error">
                                        Error: {allProfessors.error.message}
                                    </Typography>
                                ) : (
                                    <Box sx={{ height: 700, width: '100%' }}>
                                        <DataGrid
                                            localeText={
                                                esES.components.MuiDataGrid
                                                    .defaultProps.localeText
                                            }
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            rowsPerPageOptions={[]}
                                            disableDensitySelector
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                            componentsProps={{
                                                panel: {
                                                    placement: 'bottom-end',
                                                },
                                            }}
                                            sx={{
                                                '&.MuiDataGrid-root': {
                                                    border: 'none',
                                                },
                                            }}
                                        />
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default AllProfessors;
