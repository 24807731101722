import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectTotalMaleFemaleStudentRegisterGrade,
} from '../../../store/home/fetchSlice';
import { getTotalMaleFemaleStudentRegisterGrade } from '../../../store/home/thunks';
import { SkeletongGrafics } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeTotalMaleFemaleStudentRegisterGradeStatusServer } from '../../../store/home/componentStatusSelectors';

const TotalMaleFemaleStudentsRegisterGrade = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeTotalMaleFemaleStudentRegisterGradeStatusServer
    );

    const totalStudentsRegisterGrade = useFetchResource(
        () =>
            getTotalMaleFemaleStudentRegisterGrade({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectTotalMaleFemaleStudentRegisterGrade,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getTotalMaleFemaleStudentRegisterGrade({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectTotalMaleFemaleStudentRegisterGrade,
            [cycleSelected, schoolId]
        );
    };

    const processStudentData = (data) => {
        if (!data || !Array.isArray(data.value)) {
            return [];
        }

        return data.value.map((item) => ({
            grado: item.data[0],
            hombres: item.data[1],
            mujeres: item.data[2],
        }));
    };

    const processedData = processStudentData(totalStudentsRegisterGrade);

    const getColor = (bar) => {
        if (bar.id === 'hombres') return '#1E90FF';
        if (bar.id === 'mujeres') return '#f47edd';
        return '#cccccc';
    };

    return (
        <Box>
            {fetchingStatus === 'pending' && <SkeletongGrafics items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        position: 'relative',
                        overflow: 'visible',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        margin: 'auto',
                    }}
                >
                    <CardContent sx={{ width: '100%', padding: '16px' }}>
                        <Typography variant="h6" gutterBottom>
                            {totalStudentsRegisterGrade?.title}
                        </Typography>
                        <Box
                            sx={{
                                height: '255px',
                                minWidth: '200px',
                            }}
                        >
                            <ResponsiveBar
                                data={processedData}
                                keys={['hombres', 'mujeres']}
                                indexBy="grado"
                                margin={{
                                    top: 10,
                                    right: 30,
                                    bottom: 50,
                                    left: 60,
                                }}
                                padding={0.3}
                                layout="horizontal"
                                colors={(bar) =>
                                    bar.id === 'hombres'
                                        ? 'url(#gradBlue)'
                                        : 'url(#gradPink)'
                                }
                                defs={[
                                    {
                                        id: 'gradBlue',
                                        type: 'linearGradient',
                                        colors: [
                                            { offset: 0, color: '#1E90FF' },
                                            {
                                                offset: 100,
                                                color: '#00BFFF',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'gradPink',
                                        type: 'linearGradient',
                                        colors: [
                                            { offset: 0, color: '#f47edd' },
                                            {
                                                offset: 100,
                                                color: '#FF69B4',
                                            },
                                        ],
                                    },
                                ]}
                                borderRadius={8}
                                borderWidth={2}
                                borderColor="#ffffff"
                                enableLabel={true}
                                labelTextColor={({ data }) =>
                                    data.value < 10 ? '#000000' : '#ffffff'
                                }
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                axisBottom={null}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legendPosition: 'middle',
                                    legendOffset: -40,
                                }}
                                axisTop={null}
                                axisRight={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    legendPosition: 'middle',
                                    legendOffset: 60,
                                }}
                                animate={true}
                                motionStiffness={100}
                                motionDamping={10}
                                tooltip={({ id, value, color }) => (
                                    <div
                                        style={{
                                            padding: '5px 10px',
                                            color,
                                            background: '#fff',
                                            border: `1px solid ${color}`,
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <strong>{id}:</strong> {value}
                                    </div>
                                )}
                                theme={{
                                    axis: {
                                        ticks: {
                                            text: { fontSize: 12 },
                                        },
                                    },
                                    tooltip: {
                                        container: {
                                            fontSize: '16px',
                                            background: '#333',
                                            color: '#fff',
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default TotalMaleFemaleStudentsRegisterGrade;
