import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Services from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import * as _ from 'lodash';
import {
    selectStudentById,
    updateStudent,
    upsertOneStudent,
} from '../entities/students';
import {
    addOneGroupsStudents,
    removeOneGroupsStudents,
    selectGroupsStudentsByStudentId,
} from '../entities/groups_students';
import { selectAllGroups, upsertOneGroup } from '../entities/groups';
import { removeOneRelationship } from '../entities/relationships';
import { addOneItem } from './ItemsSlice';
import { getEmptyItem } from './serverSlice';

const emptyState = {
    exchangeSpecialGroup: 'idle',
    assignMainGroup: 'idle',
    addSpecialGroups: 'idle',
    fetchSpecialGroupsGroups: 'idle',
    fetchSpecialGroupsSubjects: 'idle',
    activateStudent: 'idle',
    activateTracedStudent: 'idle',
    assignStudentToGroups: 'idle',
    deleteRelationship: 'idle',
};

/**
 * Slice para el settings UI
 */
export const operationsSlice = createSlice({
    name: 'studentsUI/operations',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        /////////////////////// Intercambiar grupo especial /////////////////////////

        builder.addCase(exchangeSpecialGroup.rejected, (state, action) => {
            state.exchangeSpecialGroup = 'rejected';
            //state.feedback = action.payload.feedback
        });
        builder.addCase(exchangeSpecialGroup.fulfilled, (state, action) => {
            state.exchangeSpecialGroup = 'fulfilled';
        });
        builder.addCase(exchangeSpecialGroup.pending, (state, action) => {
            state.exchangeSpecialGroup = 'pending';
        });

        /**
         * Recuperar los grupos especiales
         */
        builder.addCase(fetchSpecialGroupsGroups.rejected, (state, action) => {
            state.fetchSpecialGroupsGroups = 'rejected';
            //state.feedback = action.payload.feedback
        });
        builder.addCase(fetchSpecialGroupsGroups.fulfilled, (state, action) => {
            state.fetchSpecialGroupsGroups = 'fulfilled';
        });
        builder.addCase(fetchSpecialGroupsGroups.pending, (state, action) => {
            state.fetchSpecialGroupsGroups = 'pending';
        });

        /**
         * Recuperar las materias de los grupos
         */
        builder.addCase(
            fetchSpecialGroupsSubjects.rejected,
            (state, action) => {
                state.fetchSpecialGroupsSubjects = 'rejected';
                //state.feedback = action.payload.feedback
            }
        );
        builder.addCase(
            fetchSpecialGroupsSubjects.fulfilled,
            (state, action) => {
                state.fetchSpecialGroupsSubjects = 'fulfilled';
            }
        );
        builder.addCase(fetchSpecialGroupsSubjects.pending, (state, action) => {
            state.fetchSpecialGroupsSubjects = 'pending';
        });

        /////////////////////// Agregar o intercambiar grupo especial /////////////////////////

        builder.addCase(assignMainGroup.rejected, (state, action) => {
            state.assignMainGroup = 'rejected';
            //state.feedback = action.payload.feedback
        });
        builder.addCase(assignMainGroup.fulfilled, (state, action) => {
            state.assignMainGroup = 'fulfilled';
        });
        builder.addCase(assignMainGroup.pending, (state, action) => {
            state.assignMainGroup = 'pending';
        });

        builder.addCase(addAndRemoveSpecialGroups.rejected, (state, action) => {
            state.addSpecialGroups = 'rejected';
            //state.feedback = action.payload.feedback
        });
        builder.addCase(
            addAndRemoveSpecialGroups.fulfilled,
            (state, action) => {
                state.addSpecialGroups = 'fulfilled';
            }
        );
        builder.addCase(addAndRemoveSpecialGroups.pending, (state, action) => {
            state.addSpecialGroups = 'pending';
        });

        /////////////////////// ACTIVAR ALUMNOS /////////////////////////

        builder.addCase(activateStudent.rejected, (state, action) => {
            state.activateStudent = 'rejected';
            //state.feedback = action.payload.feedback
        });
        builder.addCase(activateStudent.fulfilled, (state, action) => {
            state.activateStudent = 'fulfilled';
        });
        builder.addCase(activateStudent.pending, (state, action) => {
            state.activateStudent = 'pending';
        });

        /////////////////////// ACTIVAR ALUMNOS RASTREADOS /////////////////////////

        builder.addCase(activateTracedStudent.rejected, (state, action) => {
            state.activateTracedStudent = 'rejected';
            //state.feedback = action.payload.feedback
        });
        builder.addCase(activateTracedStudent.fulfilled, (state, action) => {
            state.activateTracedStudent = 'fulfilled';
        });
        builder.addCase(activateTracedStudent.pending, (state, action) => {
            state.activateTracedStudent = 'pending';
        });

        /////////////////////// ASIGNAR ALUMNOS ACTIVADOS A GRUPOS /////////////////////////

        builder.addCase(assignStudentToGroups.rejected, (state, action) => {
            state.assignStudentToGroups = 'rejected';
            //state.feedback = action.payload.feedback
        });
        builder.addCase(assignStudentToGroups.fulfilled, (state, action) => {
            state.assignStudentToGroups = 'fulfilled';
        });
        builder.addCase(assignStudentToGroups.pending, (state, action) => {
            state.assignStudentToGroups = 'pending';
        });

        /////////////////////// ELIMINAR RELACION DE PADRES/HIJOS /////////////////////////

        builder.addCase(deleteRelationship.rejected, (state, action) => {
            state.deleteRelationship = 'rejected';
            //state.feedback = action.payload.feedback
        });
        builder.addCase(deleteRelationship.fulfilled, (state, action) => {
            state.deleteRelationship = 'fulfilled';
        });
        builder.addCase(deleteRelationship.pending, (state, action) => {
            state.deleteRelationship = 'pending';
        });
    },
});

export const { invalidate } = operationsSlice.actions;

export default operationsSlice.reducer;

//////////////////// SELECTORES //////////////////

/**
 * Recuperamos las configuraciones de la escuela
 *
 * @param {*} state
 * @returns
 */
export const selectOperations = (state) => state.studentsUI.operations;

///////////////////////// MODAL PARA INTERCAMBIAR GRUPOS ESPECUALES /////////////////////////
/**
 * Selector para recuperar el status de la operacion de
 * intercambiar grupos especiales
 *
 * @param {*} state
 * @returns
 */
export const selectExchangeSpecialGroupStatus = (state) =>
    selectOperations(state).exchangeSpecialGroup;

/**
 * Selector para recuperar el status de la operacion de
 * recuperar grupos especiales
 *
 * @param {*} state
 * @returns
 */
export const selectFetchSpecialGroupStatus = (state) =>
    selectOperations(state).fetchSpecialGroupsGroups;

/**
 * Selector para recuperar el status de la operacion de
 * recuperar grupos especiales
 *
 * @param {*} state
 * @returns
 */
export const selectFetchSpecialGroupsSubjects = (state) =>
    selectOperations(state).fetchSpecialGroupsSubjects;

//////////////////////////// MODAL PARA ASIGNAR UN GRUPO PRINCIPAL //////////////////

/**
 * Selector para recuperar el status de la operacion de
 * intercambiar grupos especiales
 *
 * @param {*} state
 * @returns
 */

export const selectAssignMainGroupStatus = (state) =>
    selectOperations(state).assignMainGroup;

/**
 * Selector para recuperar el status de la operacion de
 * agregar grupos especiales
 *
 * @param {*} state
 * @returns
 */

export const selectAddSpecialGroupsStatus = (state) =>
    selectOperations(state).addSpecialGroups;

/**
 * Selector para recuperar el status de la operacion de
 * activar alumnos
 *
 * @param {*} state
 * @returns
 */
export const selectActivateStudentStatus = (state) =>
    selectOperations(state).activateStudent;

/**
 * Selector para recuperar el status de la operacion de
 * activar alumnos rastreados
 *
 * @param {*} state
 * @returns
 */
export const selectActivateTracedStudentStatus = (state) =>
    selectOperations(state).activateTracedStudent;

/**
 * Selector para recuperar el status de la operacion de
 * eliminar relacion padre/hijo
 *
 * @param {*} state
 * @returns
 */
export const selectActivateRemoveOneRelationship = (state) =>
    selectOperations(state).deleteRelationship;

//////////////// TRUNCKS /////////////////

/**
 * Cargar informacion de la UI de alumnos
 */
/*
export const loadUI = createAsyncThunk(
    'studentsUI/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        try {

            let lastPage = 0
            let currentPage = 0

            let students = []

            do {
                currentPage++

                let studentsResponse = await Services.StudentsBySchool(schoolId, {
                    per_page: 50,
                    page: currentPage,
                    //filters: {status:1}
                }).then(res => res.data)
                let studentsPaginated = studentsResponse.data
                let meta = studentsResponse.meta

                lastPage = meta.last_page

                students = students.concat(studentsPaginated)

            } while (currentPage < lastPage)

            thunkAPI.dispatch(upsertManyStudents(students))

            const items = students.reduce((preveState, curr) => {
                preveState[curr.student_id] = getEmptyItem(curr.student_id)
                return preveState
            }, {})

            thunkAPI.dispatch(setAllItems(items))

            return {
                students
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        let { didInvalidate, expireIn } = getState().studentsUI.server

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }

    }
}
)
*/

/**
 * Recuperar grupos especiales
 */
export const fetchSpecialGroupsGroups = createAsyncThunk(
    'studentsUI/operations/specialGroups/fetch/groups',
    async ({ schoolId, studentId }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
       
            const allGroups = await Services.groupsBySchool(schoolId, {
                filters: { group_type: [2,3] },
            }).then((i) => i.data.data);

            const studentGroups = await Services.getSpecialGroupsByStudent(
                studentId
            ).then((i) => i.data.data);

            return {
                allGroups,
                studentGroups,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Intercambiar grupos de un alumno
 */
export const exchangeSpecialGroup = createAsyncThunk(
    'studentsUI/operations/spacialGroups/change',
    async ({ student, sourcegroup, targetGroup, targetSubjects }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const state = thunkAPI.getState();

            /**
             * Relacion de alumnos con grupos
             */
            const groupsStudents = selectGroupsStudentsByStudentId(
                student.student_id
            )(state);

            const groups = selectAllGroups(state);

            /**
             * Definimos el formato de las materias que seran
             * transferidas
             */
            let transferDataOf = null;

            if (sourcegroup.subjects != 1 || targetGroup.subjects != 1) {
                transferDataOf = targetSubjects.map((i) => ({
                    source_id: i.source_subject_id,
                    target_id: i.subject_id,
                }));
            }

            /**
             * Comunicacion con el service
             */
            const groupAssigned = await Services.changeStudentGroup(
                student.student_id,
                sourcegroup.group_id,
                targetGroup.group_id,
                { subjects: transferDataOf }
            ).then((i) => i.data.data);

            const studentUpdated = await Services.getStudentById(
                student.student_id
            ).then((i) => i.data.data);

            const sourceGroupUpdated = await Services.getGroupById(
                sourcegroup.group_id
            ).then((i) => i.data.data);

            /**
             * Buscar el grupo especial al que pertenesca el alumno
             */
            const studentGroupToRemove = _.find(groupsStudents, {
                student_id: studentUpdated.student_id,
                group_id: sourcegroup.group_id,
            });

            /**
             * Eliminamos la relacion del alumno con el grupo anterior
             * y tambien le restamos un alumno
             */
            if (studentGroupToRemove) {
                let currGroup = _.find(groups, {
                    group_id: studentGroupToRemove.group_id,
                });

                if (currGroup) {
                    thunkAPI.dispatch(
                        upsertOneGroup({
                            ...currGroup,
                            ...sourceGroupUpdated,
                            //students: currGroup.students - 1
                        })
                    );
                }

                thunkAPI.dispatch(
                    removeOneGroupsStudents(studentGroupToRemove.id)
                );
            }

            /**
             *  Actualizar alumnos con datos del nuevo grupo
             */
            thunkAPI.dispatch(upsertOneGroup(groupAssigned));
            thunkAPI.dispatch(
                upsertOneStudent({ ...student, ...studentUpdated })
            );
            thunkAPI.dispatch(
                addOneGroupsStudents({
                    student_id: student.student_id,
                    group_id: groupAssigned.group_id,
                })
            );

            return {
                studentUpdated,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Intercambiar grupos de un alumno
 */
export const fetchSpecialGroupsSubjects = createAsyncThunk(
    'studentsUI/operations/specialGroups/fetch/subjects',
    async ({ sourceGroupId, targetGroupId, schoolId }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const sourceGroupSubjects = await Services.getSubjectByGroup(
                sourceGroupId
            ).then((i) => i.data.data);

            const targetGroupSubjects = await Services.getSubjectByGroup(
                targetGroupId
            ).then((i) => i.data.data);

            let allcatalogIds = _.uniq([
                ..._.map(sourceGroupSubjects, 'catalog_subject_id'),
                ..._.map(targetGroupSubjects, 'catalog_subject_id'),
            ]);

            const catalogSubjects = await Services.getSubjectCatalogBySchool(
                schoolId,
                {
                    filters: { catalog_subject_id: allcatalogIds },
                }
            ).then((i) => i.data.data);

            return {
                sourceGroupSubjects,
                targetGroupSubjects,
                catalogSubjects,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/////////////////////// MODAL AGERGAR GRUPO ////////////////

/**
 * Agregar o intercambiar grupo
 */
export const assignMainGroup = createAsyncThunk(
    'studentsUI/operations/assign-main-group',
    async ({ student, groupSelected }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const state = thunkAPI.getState();
            /**
             * Relacion de alumnos con grupos
             */
            const groupsStudents = selectGroupsStudentsByStudentId(
                student.student_id
            )(state);

            const groups = selectAllGroups(state);

            /**
             * Comunicacion con el service
             */

            let groupRequet = null;

            if (student.group_id) {
                groupRequet = Services.changeStudentGroup(
                    student.student_id,
                    student.group_id,
                    groupSelected.group_id
                );
            } else {
                groupRequet = Services.setStudentsToGroup(
                    student.student_id,
                    groupSelected.group_id
                );
            }

            const group = await groupRequet.then((i) => i.data.data);

            /**
             * Buscar el grupo  al que pertenesca el alumno
             */
            let currGroup = _.find(groups, { group_id: student.group_id });

            let studentUpdate = { ...student };

            /**
             * Eliminamos la relacion del alumno con el grupo anterior
             * y tambien le restamos un alumno
             */
            if (currGroup) {
                thunkAPI.dispatch(
                    upsertOneGroup({
                        ...currGroup,
                        students: currGroup.students - 1,
                    })
                );

                // ELIMINAR RELACION DE GRUPOS Y ALUMNOS

                const groupStudentPivot = _.find(groupsStudents, {
                    group_id: currGroup.group_id,
                    student_id: student.student_id,
                });

                //thunkAPI.dispatch(removeOneGroupsStudents(groupStudentPivot.id));

                if (groupStudentPivot) {
                    thunkAPI.dispatch(
                        removeOneGroupsStudents(groupStudentPivot.id)
                    );

                    // ACTUALIZAR GRUPOS Y MATERIAS DEL ALUMNO
                    studentUpdate.groups -= 1;
                    studentUpdate.amount_subjects -= currGroup.subjects;
                } else {
                    console.error(
                        'No se encontró la relación entre grupos y alumnos. No se realizará la eliminación.'
                    );
                }

                // ACTUALIZAR GRUPOS Y MATERIAS DEL ALUMNO

                //studentUpdate.groups -= 1;
                //studentUpdate.amount_subjects -= currGroup.subjects;
            }

            /**
             *  Actualizar alumnos con datos del nuevo grupo
             */
            studentUpdate.groups += 1;
            studentUpdate.amount_subjects += group.subjects;
            studentUpdate.group_id = group.group_id;

            thunkAPI.dispatch(upsertOneGroup(group));
            thunkAPI.dispatch(upsertOneStudent(studentUpdate));
            thunkAPI.dispatch(
                addOneGroupsStudents({
                    student_id: student.student_id,
                    group_id: groupSelected.group_id,
                })
            );
            return {
                studentUpdate,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Agregar y elimina grupos especiales
 */
export const addAndRemoveSpecialGroups = createAsyncThunk(
    'studentsUI/operations/add-create-special-group',
    async ({ student, groupsToCreate, groupsToDelete }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const state = thunkAPI.getState();

            /**
             * Relacion de alumnos con grupos
             */
            const groupsStudents = selectGroupsStudentsByStudentId(
                student.student_id
            )(state);

            let groupsDeleted = [];
            let groupsCreated = [];

            /**
             * Eliminar grupos
             */
            for (const group of groupsToDelete) {
                try {
                    let groupAffected = await Services.deleteStudentFromGroup(
                        student.student_id,
                        group.group_id
                    ).then((i) => i.data.data);

                    groupsDeleted.push(groupAffected);
                } catch (error) {
                    // TODO NO LANZAR LA EXCEPCION
                }
            }

            /**
             * Agregar grupos
             */
            for (const group of groupsToCreate) {
                try {
                    let groupAffected = await Services.setStudentsToGroup(
                        student.student_id,
                        group.group_id
                    ).then((i) => i.data.data);

                    groupsCreated.push(groupAffected);
                } catch (error) {
                    // TODO NO LANZAR LA EXCEPCION
                }
            }

            /**
             * Actualizar grupos eliminados
             */
            for (const group of groupsDeleted) {
                thunkAPI.dispatch(upsertOneGroup(group));

                const groupStudentToDelete = _.find(groupsStudents, {
                    group_id: group.group_id,
                    student_id: student.student_id,
                });

                if (groupStudentToDelete) {
                    thunkAPI.dispatch(
                        removeOneGroupsStudents(groupStudentToDelete.id)
                    );
                }
            }
            /**
             * Actualizar grupos agergados
             */
            for (const group of groupsCreated) {
                thunkAPI.dispatch(
                    addOneGroupsStudents({
                        student_id: student.student_id,
                        group_id: group.group_id,
                    })
                );

                thunkAPI.dispatch(upsertOneGroup(group));
            }

            const studentUpdated = await Services.getStudentById(
                student.student_id
            ).then((i) => i.data.data);

            /**
             *  Actualizar alumnos con datos del nuevo grupo
             */
            thunkAPI.dispatch(
                upsertOneStudent({
                    ...student,
                    groups:
                        student.groups +
                        groupsCreated.length -
                        groupsDeleted.length,
                    amount_subjects:
                        student.amount_subjects +
                        _.sumBy(groupsCreated, 'subjects') -
                        _.sumBy(groupsDeleted, 'subjects'),
                    has_special_groups: studentUpdated.has_special_groups,
                })
            );

            return {
                groupsDeleted,
                groupsCreated,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Activar alumnos
 */
export const activateStudent = createAsyncThunk(
    'studentsUI/operations/student/activate',
    async ({ studentId }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            /**
             * Comunicacion con el service
             */
            const student = await Services.updateStudent(studentId, {
                status: 1,
            }).then((i) => i.data.data);

            thunkAPI.dispatch(
                updateStudent({
                    id: studentId,
                    changes: {
                        ...student,
                    },
                })
            );

            return {
                student,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Activar alumnos dados de baja
 */
export const activateTracedStudent = createAsyncThunk(
    'studentsUI/operations/student/activateTraced',
    async ({ studentId, schoolId }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            /**
             * Comunicacion con el service
             */
            const student = await Services.updateStudent(studentId, {
                status: 1,
                school_id: schoolId,
            }).then((i) => i.data.data);

            thunkAPI.dispatch(upsertOneStudent(student));
            thunkAPI.dispatch(addOneItem(getEmptyItem(student.student_id)));

            return {
                student,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Asignar alumno a un grupo principal y/o especifico
 */
export const assignStudentToGroups = createAsyncThunk(
    'studentsUI/operations/student/assignToGroups',
    async (
        { studentId, schoolId, principalGroupId, specialGroups },
        thunkAPI
    ) => {
        let FeedbackService = new Feedback();
        let message = '';

        if (principalGroupId) {
            specialGroups.push(principalGroupId);
        }

        //ACTUALIZA Y DA DE ALTA AL ESTUDIANTE
        try {
            const studentResponse = await Services.updateStudent(studentId, {
                status: 1,
                school_id: schoolId,
            }).then((i) => i.data.data);

            let groupsData = [];
            //Si hay grupos seleccioados se agregan al estudiante
            if (specialGroups.length > 0) {
                for (const group of specialGroups) {
                    try {
                        let groupResponse = await Services.setStudentsToGroup(
                            studentId,
                            group
                        ).then((i) => i.data.data);
                        groupsData.push(groupResponse);
                    } catch (error) {
                        message =
                            'Alumno dado de alta, puede que algún grupo se no haya registradi';
                    }
                }
            }

            //Recuperamos el estudiante con nuevos datos
            let updatedStudent = await Services.getStudentById(
                studentResponse.student_id
            ).then((i) => i.data.data);

            // ACTUALIZAR STORE ESTUDIANTE Y GRUPOS
            thunkAPI.dispatch(upsertOneStudent(updatedStudent));
            thunkAPI.dispatch(
                addOneItem(getEmptyItem(updatedStudent.student_id))
            );

            groupsData.forEach((group) => {
                thunkAPI.dispatch(
                    addOneGroupsStudents({
                        student_id: updatedStudent.student_id,
                        group_id: group.group_id,
                    })
                );
            });

            return {
                studentResponse,
                groupsData,
                message,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Borrar parentesco en alumnos
 */
export const deleteRelationship = createAsyncThunk(
    'studentsUI/operations/student/removeRelationship',
    async (relationshipId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            /**
             * Comunicacion con el service
             */
            const relationship = await Services.removeRelationship(
                relationshipId
            ).then((i) => i.data.data);

            let student = selectStudentById(relationship.student_id)(
                thunkAPI.getState()
            );

            thunkAPI.dispatch(
                removeOneRelationship(relationship.relationship_id)
            );
            thunkAPI.dispatch(
                updateStudent({
                    id: student.student_id,
                    changes: {
                        relationships: student.relationships - 1,
                        total_relationships: student.relationships - 1,
                    },
                })
            );
            return {
                relationship,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
