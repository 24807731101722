import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const surchargeCatalogAdapter = createEntityAdapter({
    selectId: (surcharge) => surcharge.surcharge_catalog_id,
    sortComparer: (a, b) => a.surcharge_id - b.surcharge_catalog_id,
});

export const SurchargeCatalogSlice = createSlice({
    name: 'surcharge_catalog',
    initialState: surchargeCatalogAdapter.getInitialState(),
    reducers: {
        setAllCatalogSurcharges: surchargeCatalogAdapter.setAll,
        addManyCatalogSurcharges: surchargeCatalogAdapter.addMany,
        addOneCatalogSurcharge: surchargeCatalogAdapter.addOne,
        removeOneCatalogSurcharge: surchargeCatalogAdapter.removeOne,
        upsertOneCatalogSurcharge: surchargeCatalogAdapter.upsertOne,
        upsertManyCatalogSurcharges: surchargeCatalogAdapter.upsertMany,
        updateManyCatalogSurcharges: surchargeCatalogAdapter.updateMany,
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return surchargeCatalogAdapter.getInitialState();
        });
    },
});

export const {
    setAllCatalogSurcharges,
    addManyCatalogSurcharges,
    addOneCatalogSurcharge,
    removeOneCatalogSurcharge,
    upsertOneCatalogSurcharge,
    upsertManyCatalogSurcharges,
    updateManyCatalogSurcharges,
} = SurchargeCatalogSlice.actions;

const globalizedSelectors = surchargeCatalogAdapter.getSelectors(
    (state) => state.entities.surcharge_catalog
);

export default SurchargeCatalogSlice.reducer;
