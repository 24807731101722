import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Feedback from '../../../service/Feedback';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import Connection from '../../../service/Connection';
import {
    removeManyConcepts,
    upsertManyConcepts,
} from '../../../store/slices/entities/payments/concepts';
import { selectPaymentCatDiscounts } from './selectors';
import {
    removeOneCatalogDiscount,
    upsertOneCatalogDiscount,
} from '../../../store/slices/entities/payments/cat_discounts';

const emptyState = {
    fetch: {
        expireIn: null,
        fetchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    create: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    delete: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    update: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
};

export const operationsSlice = createSlice({
    name: 'operations/cat_discounts',
    initialState: emptyState,
    reducers: {},

    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        // Handle fetchCreateConcept actions
        builder.addCase(fetchCreateCatDiscount.rejected, (state, action) => {
            state.fetch.status = 'rejected';
            state.fetch.feedback = action.payload.feedback;
        });
        builder.addCase(fetchCreateCatDiscount.fulfilled, (state) => {
            state.fetch.status = 'fulfilled';
        });
        builder.addCase(fetchCreateCatDiscount.pending, (state) => {
            state.fetch.status = 'pending';
        });

        // Handle CreateConcept actions
        builder.addCase(createCatDiscount.rejected, (state, action) => {
            state.create.status = 'rejected';
        });
        builder.addCase(createCatDiscount.fulfilled, (state) => {
            state.create.status = 'fulfilled';
        });
        builder.addCase(createCatDiscount.pending, (state) => {
            state.create.status = 'pending';
        });

        //handle DeleteConcept actions
        builder.addCase(DeleteCatDiscounts.rejected, (state, action) => {
            state.delete.status = 'rejected';
        });
        builder.addCase(DeleteCatDiscounts.fulfilled, (state) => {
            state.delete.status = 'fulfilled';
        });
        builder.addCase(DeleteCatDiscounts.pending, (state) => {
            state.delete.status = 'pending';
        });

        // Handle UpdateConcept actions
        builder.addCase(UpdateCatDiscounts.rejected, (state, action) => {
            state.update.status = 'rejected';
        });
        builder.addCase(UpdateCatDiscounts.fulfilled, (state) => {
            state.update.status = 'fulfilled';
        });
        builder.addCase(UpdateCatDiscounts.pending, (state) => {
            state.update.status = 'pending';
        });
    },
});

//export const {} = operationsSlice.actions;

export default operationsSlice.reducer;

export const selectOperations = createSelector(
    selectPaymentCatDiscounts,
    (reports) => reports.operations
);

export const selectCreate = createSelector(
    selectOperations,
    (operations) => operations.create
);

export const selectCreateOperation = createSelector(
    selectCreate,
    (create) => create.status
);

export const selectStatusOperation = (state) =>
    state.createConcepts.operations.create.statusOperation;

/**
 * fetcha para la creación de un concepto
 */

export const fetchCreateCatDiscount = createAsyncThunk(
    'Concepts/fetchCreateCatDiscount',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            return {};
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Agregar un concepto
 */

export const createCatDiscount = createAsyncThunk(
    'Concepts/createCatDiscount',
    async ({ schoolId, data }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.addDiscountCatalog(
                schoolId,
                data
            );

            const CatDiscounts = response.data.data;

            thunkAPI.dispatch(upsertOneCatalogDiscount(CatDiscounts));
            return { CatDiscounts };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Eliminar  conceptos
 */

export const DeleteCatDiscounts = createAsyncThunk(
    'Concepts/DeleteCatDiscounts',
    async (discount_catalog_id, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const response =
                await Connection.deleteDiscountCatalog(discount_catalog_id);

            const data = response.data.data;
            thunkAPI.dispatch(
                removeOneCatalogDiscount(data.discount_catalog_id)
            );

            return { data };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar conceptos
 */

export const UpdateCatDiscounts = createAsyncThunk(
    'Concepts/UpdateCatDiscounts',
    async (data, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.updateDiscountCatalog({ data });
            const CatDiscounts = response.data.data;

            thunkAPI.dispatch(upsertManyConcepts(CatDiscounts));

            return { CatDiscounts };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
