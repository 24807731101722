import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Avatar,
    Tooltip,
} from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import { getWorstScoreStudent } from '../../../store/home/thunks';
import {
    getCycleSelected,
    selectWorstScoreStudent,
} from '../../../store/home/fetchSlice';
import DEFAULT_AVATAR_URL from './../../../../assets/images/Unknown_person.jpeg';
import { SkeletonCard, SkeletonLongCard } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeWorstScoreStudentStatusServer } from '../../../store/home/componentStatusSelectors';

const WorstScoreStudentCard = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeWorstScoreStudentStatusServer
    );

    const worstScoreStudent = useFetchResource(
        () =>
            getWorstScoreStudent({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectWorstScoreStudent,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getWorstScoreStudent({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectWorstScoreStudent,
            [cycleSelected, schoolId]
        );
    };

    const avatarSrc = worstScoreStudent.value?.avatar || DEFAULT_AVATAR_URL;

    return (
        <Box>
            {fetchingStatus === 'pending' && <SkeletonCard items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Tooltip
                    title={
                        worstScoreStudent?.description ||
                        'Peor estudiante en la escuela'
                    }
                    followCursor
                >
                    <Card
                        sx={{
                            backgroundColor: 'rgb(250, 137, 107)',
                            borderRadius: '10px',
                            textAlign: 'center',
                            minHeight: '200px',
                            minWidth: '200px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: 'none',
                            border: '1px solid rgba(0, 0, 0, 0.1)',
                            margin: 'auto',
                        }}
                    >
                        <Avatar
                            src={avatarSrc}
                            alt="Avatar Image"
                            sx={{
                                width: 60,
                                height: 60,
                                marginBottom: '10px',
                                borderRadius: '50%',
                                border: '3px solid #ffffff',
                                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            }}
                        />
                        <CardContent
                            style={{
                                padding: '0',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                width: '100%',
                                height: 'auto',
                            }}
                        >
                            <Typography
                                variant="h6"
                                style={{
                                    color: 'rgb(253, 237, 232)',
                                    marginBottom: '8px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {'Peor promedio'}
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{
                                    color: 'rgb(253, 237, 232)',
                                    marginBottom: '4px',
                                }}
                            >
                                {worstScoreStudent.value?.data[0] ||
                                    'Nombre del estudiante'}
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{
                                    color: 'rgb(253, 237, 232)',
                                    marginBottom: '4px',
                                }}
                            >
                                {worstScoreStudent.value?.data[1] || 'Grupo'}
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{
                                    color: 'rgb(253, 237, 232)',
                                }}
                            >
                                {worstScoreStudent.value?.data[2] || 'Promedio'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Tooltip>
            )}
        </Box>
    );
};

export default WorstScoreStudentCard;
