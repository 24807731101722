import { Grid, Skeleton } from "@mui/material"

export default () => {

    return (
        <>
            <Skeleton variant="rectangular" height={50} sx={{ mb: 2 }} animation="wave" />
            <Grid
                container
                spacing={2}
            >

                <Grid item xs={12} sm={6} lg={3}>
                    <Skeleton variant="rectangular" height={376} animation="wave" />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Skeleton variant="rectangular" height={376} />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Skeleton variant="rectangular" height={376} animation="wave" />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Skeleton variant="rectangular" height={376} />
                </Grid>
            </Grid>

            <Skeleton variant="rectangular" height={400} sx={{ mt: 2 }} animation="wave" />
            <Skeleton variant="rectangular" height={400} sx={{ mt: 3 }} animation="wave" />
        </>
    )
}
