import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Feedback from '../../../service/Feedback';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import Connection from '../../../service/Connection';
import { selectPaymentSurcharges } from './selectors';
import {
    addManySurcharges,
    upsertManySurcharges,
} from '../../../store/slices/entities/payments/surcharges';
import { upsertManyConcepts } from '../../../store/slices/entities/payments/concepts';

const emptyState = {
    create: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    delete: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    update: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
};

export const operationsSlice = createSlice({
    name: 'operations/surcharges',
    initialState: emptyState,
    reducers: {},

    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        // Handle CreateConcept actions
        builder.addCase(createSurcharge.rejected, (state, action) => {
            state.create.status = 'rejected';
            state.create.feedback = action.payload.feedback;
        });
        builder.addCase(createSurcharge.fulfilled, (state) => {
            state.create.status = 'fulfilled';
        });
        builder.addCase(createSurcharge.pending, (state) => {
            state.create.status = 'pending';
        });

        //handle DeleteConcept actions
        builder.addCase(DeleteSurcharges.rejected, (state, action) => {
            state.delete.status = 'rejected';
            state.delete.feedback = action.payload.feedback;
        });
        builder.addCase(DeleteSurcharges.fulfilled, (state) => {
            state.delete.status = 'fulfilled';
        });
        builder.addCase(DeleteSurcharges.pending, (state) => {
            state.delete.status = 'pending';
        });

        // Handle UpdateConcept actions
        builder.addCase(UpdateSurcharges.rejected, (state, action) => {
            state.update.status = 'rejected';
            state.update.feedback = action.payload.feedback;
        });
        builder.addCase(UpdateSurcharges.fulfilled, (state) => {
            state.update.status = 'fulfilled';
        });
        builder.addCase(UpdateSurcharges.pending, (state) => {
            state.update.status = 'pending';
        });
    },
});

//export const {} = operationsSlice.actions;

export default operationsSlice.reducer;

export const selectOperations = createSelector(
    selectPaymentSurcharges,
    (reports) => reports.operations
);

export const selectCreate = createSelector(
    selectOperations,
    (operations) => operations.create
);

export const selectCreateOperation = createSelector(
    selectCreate,
    (create) => create.status
);

export const selectStatusOperation = (state) =>
    state.createConcepts.operations.create.statusOperation;

/**
 * Agregar un concepto
 */

export const createSurcharge = createAsyncThunk(
    'Concepts/createSurcharge',
    async ({ data, schoolId }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.addSurcharges({ data });

            const Surcharges = response.data.data;

            const responseConcepts =
                await Connection.getConceptsBySchool(schoolId);

            const Concepts = responseConcepts.data.data;

            thunkAPI.dispatch(upsertManyConcepts(Concepts));

            thunkAPI.dispatch(addManySurcharges(Surcharges));

            return { Surcharges };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Eliminar  conceptos
 */

export const DeleteSurcharges = createAsyncThunk(
    'Concepts/DeleteSurcharges',
    async (data, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.deleteSurcharge({ data });
            const deletedSurchargesIds = response.data.data;

            thunkAPI.dispatch(upsertManySurcharges(deletedSurchargesIds));

            return { data };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar conceptos
 */

export const UpdateSurcharges = createAsyncThunk(
    'Concepts/UpdateSurcharges',
    async (data, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.updateSurcharge({ data });
            const Surcharges = response.data.data;

            thunkAPI.dispatch(upsertManySurcharges(Surcharges));

            return { Surcharges };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
