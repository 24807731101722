import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
    useTheme,
    Avatar,
    Tooltip,
    Divider,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Delete } from '@mui/icons-material';
import student from '../../../../assets/images/default-image-niña.svg';
import student2 from '../../../../assets/images/default-image-niño.svg';
import { UpdateDiscounts } from '../../../store/discounts/operations';
import Feedback from '../../../../service/Feedback';
import { useFeedback } from '../../../../hooks';

const ModalUpdateDiscount = ({ open, onclose, selectedRows }) => {
    ///////////////// LOCAL STATE /////////////
    const [discounts, setdiscounts] = useState(selectedRows);
    const [initialValues, setInitialValues] = useState({});
    ///////////// SHARED STATE /////////////

    const theme = useTheme();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    ///////////// FORM SETUP /////////////
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(
            discounts.reduce((acc, concept) => {
                acc[concept.discount_id] = Yup.object().shape({
                    description: Yup.string().required(
                        'Descripción es requerida'
                    ),
                });
                return acc;
            }, {})
        ),
        onSubmit: (values) => {
            const updatediscounts = Object.keys(values).map((id) => ({
                ...values[id],
                discount_id: Number(id),
            }));
            dispatch(UpdateDiscounts(updatediscounts))
                .then(() => {
                    feedbackApp.showFeedback({
                        title: 'Descuentos Actualizados',
                    });
                    onclose();
                })
                .catch((error) => {
                    feedbackApp.showFeedback({
                        title: error.feedback.title,
                    });
                });
        },
    });

    ///////////// USE EFFECTS /////////////
    useEffect(() => {
        setdiscounts(selectedRows);
        const newInitialValues = selectedRows.reduce(
            (acc, concept) => ({
                ...acc,
                [concept.discount_id]: {
                    description: concept.description || '',
                },
            }),
            {}
        );
        setInitialValues(newInitialValues);
        formik.resetForm({ values: newInitialValues });
    }, [selectedRows]);

    ///////////// HANDLERS /////////////
    const handleFieldChange = (conceptId, field) => (event) => {
        const newValue = event.target.value;
        formik.setFieldValue(`${conceptId}.${field}`, newValue);
    };

    const handleRemoveConcept = (conceptId) => () => {
        setdiscounts(discounts.filter((c) => c.discount_id !== conceptId));
        formik.setFieldValue(conceptId, undefined);
    };

    const handleClose = () => {
        onclose();
        formik.resetForm();
    };

    ///////////// RENDER /////////////
    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleClose();
                }
            }}
            maxWidth="lg"
            fullWidth
            disableEscapeKeyDown
            sx={{
                '& .MuiDialog-paper': {
                    padding: 5,
                    borderRadius: 2,
                },
            }}>
            <DialogTitle
                sx={{
                    textAlign: 'center',
                    paddingBottom: 5,
                    position: 'relative',
                }}>
                <Typography variant="h4" component="div">
                    Actualizar Descuentos
                </Typography>
                <Typography variant="body2">
                    Aquí podrás actualizar los Descuentos
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 6,
                        top: 6,
                        color: theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    {discounts.map((concept) => {
                        const defaultAvatar =
                            concept.user.gender === 'M' ? student : student2;
                        const avatarSrc =
                            concept.user.url_photo_profile || defaultAvatar;

                        return (
                            <>
                                <Box key={concept.discount_id} sx={{ mb: 3 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: 2,
                                        }}>
                                        <Avatar
                                            src={avatarSrc}
                                            alt={`${concept.user.name} ${concept.user.last_name}`}
                                            sx={{
                                                width: 56,
                                                height: 56,
                                                mr: 2,
                                            }}
                                        />
                                        <Box>
                                            <Typography variant="body1">
                                                {`${concept.user.name} ${concept.user.last_name}`}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                noWrap>
                                                ID: {concept.user.student_id}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                noWrap>
                                                Teléfono:
                                                {concept.user.cellphone}
                                            </Typography>
                                        </Box>
                                        <IconButton
                                            onClick={handleRemoveConcept(
                                                concept.discount_id
                                            )}
                                            sx={{
                                                ml: 'auto',
                                                color: theme.palette.error.main,
                                            }}>
                                            <Delete />
                                        </IconButton>
                                    </Box>
                                    <Divider sx={{ mb: 2 }} />
                                    <TextField
                                        label="Descripción"
                                        value={
                                            formik.values[concept.discount_id]
                                                ?.description || ''
                                        }
                                        onChange={formik.handleChange}
                                        name={`${concept.discount_id}.description`}
                                        fullWidth
                                        sx={{ mb: 2 }}
                                        error={Boolean(
                                            formik.touched[concept.discount_id]
                                                ?.description &&
                                                formik.errors[
                                                    concept.discount_id
                                                ]?.description
                                        )}
                                        helperText={
                                            formik.touched[concept.discount_id]
                                                ?.description &&
                                            formik.errors[concept.discount_id]
                                                ?.description
                                        }
                                    />
                                </Box>
                            </>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!(formik.isValid && formik.dirty)}
                            type="submit"
                            sx={{
                                borderRadius: '50px',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                padding: '8px 24px',
                            }}>
                            Actualizar
                        </Button>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ModalUpdateDiscount;
