import {
    Box, Grid, Card, Stack, Typography, Tab, CardContent,
    Divider, CardHeader, Avatar, ListItemText, List, ListItem, TablePagination
} from "@mui/material";
import * as  _ from "lodash";
import React, { useState, useEffect } from 'react';
import { esES, DataGrid } from "@mui/x-data-grid";
import { DetallesSupervisorToolBar } from "../Supervisiones/components";
import Services from "./../../service/Connection";
import { useParams } from 'react-router';
import { ColorBox } from '../../components/Chart/Cards';
import { Masonry, TabPanel, TabList, TabContext } from '@mui/lab';
import SkeletonPage from './components/SkeletonPage';
import { Error } from "../../components/Feedback";
import Survey from "./components/Survey";
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';

/**
 * 
 * Pagina Detalles de los Supervisores
 * 
 * @returns
 */

const Detalles = () => {

    const [school, setSchool] = useState(null);
    const [totals, setTotals] = useState(null);
    const [users, setUsersDetails] = useState([]);
    const [profesors, setProfessorsDetails] = useState([]);
    const [assesors, setAssesorsDetails] = useState([]);
    const [directors, setDirectorsDetails] = useState([]);
    const [administrators, setAdministratorsDetails] = useState([]);
    const [value, setValue] = useState('1');
    const [loading, setLoading] = useState("pending");
    const [global, setGlobalData] = useState([]);
    const [specify, setSpecifyData] = useState([])

    let { id } = useParams();

    ///////////////// HOOKS EFFECTS ////////////////////
    useEffect(() => {
        let School_data = JSON.parse(localStorage.getItem("DatosEscuela"));
        setTotals(School_data)

        Services.getSchoolById(id).then(res => res.data.data).then(school => {
            setSchool(school)
        })

        Services.getUsersDetailsSupervisor(id).then(({ data }) => {

            let { users, survey_answers } = data

            setUsersDetails(users.parents)
            setProfessorsDetails(users.professors)
            setAssesorsDetails(users.assessors)
            setDirectorsDetails(users.directors)
            setAdministratorsDetails(users.administrators)

            let allQuestions = getTextquestionwithanswer(survey_answers.questions, survey_answers.answers).concat(
                getNontextquestionswithanswer(survey_answers.questions, survey_answers.answers, survey_answers.cataloganswer))


            setGlobalData(allQuestions.filter(encuestaglobal => encuestaglobal.school_id == null))
            setSpecifyData(allQuestions.filter(encuestaglobal => encuestaglobal.school_id != null))

            setLoading("fullfiled")

        }).catch(() => {
            setLoading("rejected")
        })
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    /**
     * Funcion para recuperar las preguntas con sus respuestas de tipo texto
     * 
     * @param {[]} questions Arreglo de Preguntas
     * @param {[]} answers Arreglo de Respuestas
     * 
     * @returns {getTextquestionwithanswer[]} Arreglo con preguntas y respuestas
     * 
     */
    const getTextquestionwithanswer = (questions, answers) => questions.filter(pregunta => pregunta.type == 1).map(pregunta =>
    ({
        ...pregunta,
        respuesta: answers.filter(respuesta => respuesta.question_id == pregunta.question_id)
    }))

    /**
     * Funcion para recuperar las preguntas con sus respuestas que no son de tipo texto
     * 
     * @param {[]} questions Arreglo de Preguntas
     * @param {[]} answers Arreglo de Respuestas
     * @param {[]} catalogs Arreglo del Catalogo
     * 
     * @returns {getNontextquestionswithanswer[]} Arreglo con preguntas y respuestas con catalogo Nontextquestionswithanswer
     * 
     */
    const getNontextquestionswithanswer = (questions, answers, catalogs) =>
        questions.filter(pregunta => pregunta.type != 1).map(pregunta => {

            let answersOfQuestion = answers.filter(respuesta => respuesta.question_id == pregunta.question_id)
            let catalogOfQuestion = catalogs.filter(catalogo => catalogo.question_id == pregunta.question_id)
            let CatalogwithAnswer = catalogOfQuestion.map(catalog => {

                return ({
                    ...catalog,
                    respuesta: answersOfQuestion.filter(respuesta => respuesta.catalog_answer_id == catalog.catalog_answer_id)
                })
            })

            return ({
                ...pregunta,
                catalogo: CatalogwithAnswer
            })
        })


    ///////////////// TABLEFILLED ////////////////////
    const details_columns_parents = [
        {
            field: 'user_id',
            headerName: 'ID',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.user_id}`
            }
        },
        {
            field: 'name',
            headerName: 'Nombre',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.name || ''} ${params.row.last_name || ''} ${params.row.second_last_name || ''}`
            }
        },
        {
            field: 'trained',
            headerName: 'Capacitado',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.trained == true) {
                    return <strong style={{ color: 'green' }}>✔</strong>
                }
                return <strong style={{ color: 'red' }}>✘</strong>;
            }
        },
        {
            field: 'with_app',
            headerName: 'Con App',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.with_app == true) {
                    return <strong style={{ color: 'green' }}>✔</strong>
                }
                return <strong style={{ color: 'red' }}>✘</strong>;
            }
        },
    ];

    const details_columns_professors = [
        {
            field: 'user_id',
            headerName: 'ID',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.user_id}`;
            }
        },
        {
            field: 'name',
            headerName: 'Nombre',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.name || ''} ${params.row.last_name || ''} ${params.row.second_last_name || ''}`;
            }
        },
        {
            field: 'trained',
            headerName: 'Capacitados',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.trained == true) {
                    return <strong style={{ color: 'green' }}>✔</strong>
                }
                return <strong style={{ color: 'red' }}>✘</strong>;
            }
        },
        {
            field: 'with_app',
            headerName: 'Con App',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.with_app == true) {
                    return <strong style={{ color: 'green' }}>✔</strong>
                }
                return <strong style={{ color: 'red' }}>✘</strong>;
            }
        },
    ];

    const details_columns_assesors = [
        {
            field: 'user_id',
            headerName: 'ID',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.user_id}`;
            }
        },
        {
            field: 'name',
            headerName: 'Nombre',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.name || ''} ${params.row.last_name || ''} ${params.row.second_last_name || ''}`;
            }
        },
        {
            field: 'trained',
            headerName: 'Capacitado',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.trained == true) {
                    return <strong style={{ color: 'green' }}>✔</strong>
                }
                return <strong style={{ color: 'red' }}>✘</strong>;
            }
        },
        {
            field: 'with_app',
            headerName: 'Con App',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.with_app == true) {
                    return <strong style={{ color: 'green' }}>✔</strong>
                }
                return <strong style={{ color: 'red' }}>✘</strong>;
            }
        },
    ];

    const details_columns_directors = [
        {
            field: 'user_id',
            headerName: 'ID',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.user_id}`;
            }
        },
        {
            field: 'name',
            headerName: 'Nombre',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.name || ''} ${params.row.last_name || ''} ${params.row.second_last_name || ''}`;
            }
        },
        {
            field: 'trained',
            headerName: 'Capacitado',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.trained == true) {
                    return <strong style={{ color: 'green' }}>✔</strong>
                }
                return <strong style={{ color: 'red' }}>✘</strong>;
            }
        },
        {
            field: 'with_app',
            headerName: 'Con App',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.with_app == true) {
                    return <strong style={{ color: 'green' }}>✔</strong>
                }
                return <strong style={{ color: 'red' }}>✘</strong>;
            }
        },
    ];

    const details_columns_administrator = [
        {
            field: 'school_id',
            headerName: 'ID',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.user_id}`;
            }
        },
        {
            field: 'name',
            headerName: 'Nombre',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.name || ''} ${params.row.last_name || ''} ${params.row.second_last_name || ''}`;
            }
        },
        {
            field: 'trained',
            headerName: 'Capacitado',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.trained == true) {
                    return <strong style={{ color: 'green' }}>✔</strong>
                }
                return <strong style={{ color: 'red' }}>✘</strong>;
            }
        },
        {
            field: 'with_app',
            headerName: 'Con App',
            type: 'string',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.with_app == true) {
                    return <strong style={{ color: 'green' }}>✔</strong>
                }
                return <strong style={{ color: 'red' }}>✘</strong>;
            }
        },
    ];

    const escudo = {
        width: 200,
        height: 200,
        fontSize: 80,
        bgcolor: '#ab47bc',
    };

    return (
        <Box sx={{
            flexGrow: 1,
            paddingTop: {
                xs: 1,
                sm: 2,
                md: 2,
            },
            paddingLeft: {
                xs: 1,
                sm: 2,
                md: 5,
            },
            paddingRight: {
                xs: 1,
                sm: 2,
                md: 5,
            },
            paddingBottom: {
                xs: 1,
                sm: 2,
                md: 5,
            },
        }}>
            {loading == "pending" && (<SkeletonPage />)}
            {loading == "rejected" && (<Error message={"Estamos teniendo problemas"} />)}
            {loading == "fullfiled" && (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ height: 376 }}>
                                <CardHeader
                                    title="Detalles de la Escuela"
                                    titleTypographyProps={{ sx: { fontSize: 24 } }}
                                    sx={{ paddingBottom: 1 }}
                                />
                                <CardContent>
                                    <List sx={{ width: '100%', maxWidth: 820, bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemText
                                                primary=""
                                                secondary={
                                                    <React.Fragment >
                                                        <Typography
                                                            sx={{ fontSize: 18, display: 'flex', alignItems: 'center', }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            <MenuBookOutlinedIcon sx={{ marginRight: 1 }} />
                                                            <span> ESCUELA</span>
                                                        </Typography>
                                                        <Typography
                                                            sx={{ display: 'flex', alignItems: 'center', fontSize: 16 }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            {" — "}{school?.name}
                                                        </Typography>

                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <Divider variant="fullWidth" component="li" />
                                        <ListItem alignItems="flex-start">
                                            <ListItemText

                                                primary=""
                                                secondary={
                                                    <React.Fragment >
                                                        <Typography
                                                            sx={{ display: 'flex', alignItems: 'center', fontSize: 18 }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            <KeyOutlinedIcon sx={{ marginRight: 1 }} />
                                                            <span> CCT</span>
                                                        </Typography>
                                                        <Typography
                                                            sx={{ display: 'flex', alignItems: 'center', fontSize: 16 }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            {" — "}{school?.clave}
                                                        </Typography>

                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <Divider variant="fullWidth" component="li" />
                                        <ListItem alignItems="flex-start">
                                            <ListItemText

                                                primary=""
                                                secondary={
                                                    <React.Fragment >
                                                        <Typography
                                                            sx={{ display: 'flex', alignItems: 'center', fontSize: 18 }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            <LocalPhoneOutlinedIcon sx={{ marginRight: 1 }} />
                                                            <span> TELÉFONO</span>
                                                        </Typography>
                                                        <Typography
                                                            sx={{ display: 'inline', fontSize: 16 }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            {" — "}{school?.cellphone}
                                                        </Typography>

                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <Divider variant="fullWidth" component="li" />
                                        <ListItem alignItems="flex-start">
                                            <ListItemText

                                                primary={
                                                    <React.Fragment >
                                                        <Typography
                                                            sx={{ display: 'inline', fontSize: 18 }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            <AddLocationAltOutlinedIcon sx={{ marginRight: 1 }} />
                                                            <span> DIRECCIÓN</span>
                                                        </Typography>
                                                        <Typography
                                                            sx={{ display: 'inline', fontSize: 16 }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            {" — "}{school?.street}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                secondary={
                                                    <React.Fragment >
                                                        <Typography
                                                            sx={{ display: 'inline', fontSize: 18 }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                        </Typography>
                                                        <Typography
                                                            sx={{ display: 'inline', fontSize: 16, ml: 6, }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            {"LOCACIÓN — "}{school?.location}
                                                        </Typography>

                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <Divider variant="fullWidth" component="li" />
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ height: 376 }}>
                                <CardHeader
                                    title="Imagen de la Escuela"
                                    titleTypographyProps={{ sx: { fontSize: 24 } }}
                                    sx={{ paddingBottom: 2 }}
                                />
                                <CardContent>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Avatar
                                            alt="Escudo escuela"
                                            //src={value}
                                            sx={escudo}
                                        >
                                        </Avatar>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Masonry columns={{ xs: 1, sm: 2, md: 2 }} spacing={3} sx={{ marginTop: 2, marginLeft: 0.2 }}>
                        <ColorBox value={school?.student_population} label="Total de alumnos" mainColor={"#ab47bc"} />
                        <ColorBox value={totals?.total_users} label="Total de usuarios" mainColor={"#ab47bc"} />
                        <ColorBox value={totals?.total_users_trained} label="Total de capacitados" mainColor={"#ab47bc"} />
                        <ColorBox value={totals?.total_users_apk} label="total de descargados" mainColor={"#ab47bc"} />
                    </Masonry>
                    <Grid marginBottom={2}>
                        <Card>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="Padres" value="1" />
                                        <Tab label="Profesores" value="2" />
                                        <Tab label="Orientadores" value="3" />
                                        <Tab label="Director" value="4" />
                                        <Tab label="Administradores" value="5" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <DataGrid
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        getRowId={({ user_id }) => user_id}
                                        rows={users}
                                        columns={details_columns_parents}
                                        pageSize={10}
                                        disableSelectionOnClick
                                        autoHeight
                                        components={{ Toolbar: DetallesSupervisorToolBar }}
                                        componentsProps={{
                                            panel: {
                                                placement: "bottom-end"
                                            },
                                        }}
                                        disableDensitySelector
                                    />
                                </TabPanel>
                                <TabPanel value="2">
                                    <DataGrid
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        getRowId={({ user_id }) => user_id}
                                        rows={profesors}
                                        columns={details_columns_professors}
                                        pageSize={10}
                                        disableSelectionOnClick
                                        autoHeight
                                        components={{ Toolbar: DetallesSupervisorToolBar }}
                                        componentsProps={{
                                            panel: {
                                                placement: "bottom-end"
                                            },
                                        }}
                                        disableDensitySelector
                                    />
                                </TabPanel>
                                <TabPanel value="3">
                                    <DataGrid
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        getRowId={({ user_id }) => user_id}
                                        rows={assesors}
                                        columns={details_columns_assesors}
                                        pageSize={10}
                                        disableSelectionOnClick
                                        autoHeight
                                        components={{ Toolbar: DetallesSupervisorToolBar }}
                                        componentsProps={{
                                            panel: {
                                                placement: "bottom-end"
                                            },
                                        }}
                                        disableDensitySelector
                                    />
                                </TabPanel>
                                <TabPanel value="4">
                                    <DataGrid
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        getRowId={({ user_id }) => user_id}
                                        rows={directors}
                                        columns={details_columns_directors}
                                        pageSize={10}
                                        disableSelectionOnClick
                                        autoHeight
                                        components={{ Toolbar: DetallesSupervisorToolBar }}
                                        componentsProps={{
                                            panel: {
                                                placement: "bottom-end"
                                            },
                                        }}
                                        disableDensitySelector
                                    />
                                </TabPanel>
                                <TabPanel value="5">
                                    <DataGrid
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        getRowId={({ user_id }) => user_id}
                                        rows={administrators}
                                        columns={details_columns_administrator}
                                        pageSize={10}
                                        disableSelectionOnClick
                                        autoHeight
                                        components={{ Toolbar: DetallesSupervisorToolBar }}
                                        componentsProps={{
                                            panel: {
                                                placement: "bottom-end"
                                            },
                                        }}
                                        disableDensitySelector
                                    />
                                </TabPanel>
                            </TabContext>
                        </Card>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Survey questions={global} title="Encuesta Global" ></Survey>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Survey questions={specify} title="Encuesta Específica " ></Survey>
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
    );
};

export default Detalles; 
