import { createSelector } from '@reduxjs/toolkit';
import { selectCatConcepts } from '../cat_concepts/selectors';
import {
    selectEntitiesStudents,
    selectStudents,
} from '../../../store/slices/entities/students';
import { selectAllGroups } from '../../../store/slices/entities/groups';
import { selectAgreements } from '../agreements/selectors';
import { da } from 'date-fns/locale';

/**
 * Selector para recuperar home de reportes
 */
export const selectPaymentConcepts = (state) => state.paymentsModule.concepts;

export const selectConcepts = (state) => state.entities.concepts.entities;

export const selectTableData = createSelector(
    [selectConcepts, selectCatConcepts, selectStudents],
    (concepts, catConcepts, student) => {
        const conceptsArray = Object.values(concepts);
        return conceptsArray.map((concept) => {
            const user = student[concept.student_id];
            const catConcept = catConcepts.find(
                (catConcept) =>
                    catConcept.concept_catalog_id === concept.concept_catalog_id
            );
            return {
                ...concept,
                user,
                catConcept,
            };
        });
    }
);
export const selectGroupsWithStudents = (state) => {
    const allGroups = selectAllGroups(state);
    const allStudents = Object.values(selectEntitiesStudents(state));

    const groupsWithStudents = allGroups.map((group) => {
        const studentsInGroup = allStudents.filter(
            (student) => student.group_id === group.group_id
        );

        return {
            ...group,
            students: studentsInGroup,
        };
    });

    return groupsWithStudents;
};

const selectFilteredConcepts = createSelector(
    [selectConcepts, selectAgreements, (state, catalogId) => catalogId],
    (concepts, agremmets, catalogId) => {
        const conceptsArray = Object.values(concepts);
        const agremmetsArray = Object.values(agremmets);

        return conceptsArray
            .filter(
                (concept) =>
                    concept.concept_catalog_id === catalogId &&
                    concept.status === 2 &&
                    new Date(concept.limit_date) <= new Date()
            )
            .filter((concept) => {
                const agreement = agremmetsArray.find(
                    (agreement) => agreement.concept_id === concept.concept_id
                );

                if (!concept.in_agreement) {
                    return new Date(concept.limit_date) < new Date();
                } else if (agreement) {
                    return new Date(agreement.next_payment_date) < new Date();
                }
            });
    }
);

// Selector para obtener los estudiantes basados en los conceptos filtrados
export const selectStudentsInFilteredConcepts = createSelector(
    [selectFilteredConcepts, selectStudents],
    (filteredConcepts, students) => {
        const studentsArray = Object.values(students);

        const studentIds = filteredConcepts.map(
            (concept) => concept.student_id
        );
        return studentsArray.filter((student) =>
            studentIds.includes(student.student_id)
        );
    }
);

export const selectFilteredConceptsForDiscounts = createSelector(
    [selectConcepts, (_, catalogId) => catalogId],
    (concepts, catalogId) => {
        const conceptsArray = Object.values(concepts);

        return conceptsArray.filter(
            (concept) =>
                concept.concept_catalog_id === catalogId &&
                concept.status === 2 &&
                !(
                    (concept.in_agreement === true &&
                        concept.in_discount === true) ||
                    (concept.in_agreement === false &&
                        concept.in_discount === true)
                )
        );
    }
);
// Selector para obtener los estudiantes basados en los conceptos filtrados
export const selectStudentsInFilteredConceptsDiscounts = createSelector(
    [selectFilteredConceptsForDiscounts, selectStudents],
    (filteredConcepts, students) => {
        const studentsArray = Object.values(students);

        const studentIds = filteredConcepts.map(
            (concept) => concept.student_id
        );
        return studentsArray.filter((student) =>
            studentIds.includes(student.student_id)
        );
    }
);
