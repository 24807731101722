import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Typography, Tooltip } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectTotalStudentsRepeated,
} from '../../../store/home/fetchSlice';
import { getTotalStudentsRepeated } from '../../../store/home/thunks';
import { SkeletongGrafics } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeTotalStudentsRepeatedStatusServer } from '../../../store/home/componentStatusSelectors';

const TotalStudentsRepeated = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeTotalStudentsRepeatedStatusServer
    );

    const StudentsRepeated = useFetchResource(
        () =>
            getTotalStudentsRepeated({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectTotalStudentsRepeated,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getTotalStudentsRepeated({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectTotalStudentsRepeated,
            [cycleSelected, schoolId]
        );
    };

    const data = [
        {
            group: StudentsRepeated?.value?.H?.data[0]?.label || 'Grupo 1',
            Hombres: Math.round(StudentsRepeated?.value?.H?.data[0]?.data) || 0,
            Mujeres: Math.round(StudentsRepeated?.value?.M?.data[0]?.data) || 0,
        },
    ];

    return (
        <Box>
            {fetchingStatus === 'pending' && <SkeletongGrafics items={9} />}
            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        position: 'relative',
                        overflow: 'visible',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        margin: 'auto',
                    }}
                >
                    <CardContent sx={{ padding: '16px' }}>
                        <Typography variant="h6" gutterBottom>
                            {StudentsRepeated?.title}
                        </Typography>
                        <Box height={300}>
                            <ResponsiveBar
                                data={data}
                                keys={['Hombres', 'Mujeres']}
                                indexBy="group"
                                sx={{ with: '100%', height: '100%' }}
                                margin={{
                                    top: 10,
                                    right: 20,
                                    bottom: 50,
                                    left: 30,
                                }}
                                padding={0.3}
                                colors={({ id }) =>
                                    id === 'Hombres' ? '#1E90FF' : '#f47edd'
                                }
                                labelTextColor="white"
                                borderColor={{
                                    from: 'color',
                                    modifiers: [['darker', 1.6]],
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legendPosition: 'middle',
                                    legendOffset: 32,
                                }}
                                theme={{
                                    labels: {
                                        text: {
                                            fontSize: 16,
                                            fill: 'white',
                                        },
                                    },
                                    tooltip: {
                                        container: {
                                            background: 'white',
                                            color: 'inherit',
                                            fontSize: 12,
                                            borderRadius: '2px',
                                            boxShadow:
                                                '0 1px 2px rgba(0, 0, 0, 0.25)',
                                        },
                                    },
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Cantidad',
                                    legendPosition: 'middle',
                                    legendOffset: -40,
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelFormat={(d) => `${d}`}
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default TotalStudentsRepeated;
