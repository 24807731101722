import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks';
import { useSelector } from 'react-redux';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Stack,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import {
    selectAllCatalogReports,
    selectGroupById,
    selectUserById,
} from '../../../../store/slices/professorModule/entitiesSlice';
import { selectSettingBySchoolId } from '../../../../store/slices/entities/settings';
import Connection from '../../../../service/Connection';
import Feedback from '../../../../service/Feedback';
import useFeedback from '../../../../hooks/useFeedback';

export const ReportCreateModal = ({
    openModal,
    setOpenModal,
    students,
    groupId,
}) => {
    ////////// HOOKS //////////

    const Auth = useAuth();

    ////////// LOCAL STATE //////////

    const [studentsArray, setStudentsArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anyOtherReportActivated, setAnyOtherReportActivated] =
        useState(false);

    ////////// SHARED STATE //////////

    const schoolId = Auth.getUser().school_id;
    const userId = Auth.getUserID();
    const config = useSelector(selectSettingBySchoolId(schoolId));

    const feedbackApp = useFeedback();

    const group = useSelector(selectGroupById(groupId));
    const allReports = useSelector(selectAllCatalogReports);
    const assesor = useSelector(selectUserById(group.assessor_id));

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    /*
     * UseEffect que se ultiliza para
     * dar formato a la data de reportes
     * e identificar cuales son de tipo "otro"
     */

    useEffect(() => {
        const otherReportIndex = allReports.findIndex(
            (report) => report.title.toLowerCase() === 'otro'
        );

        const otherReport =
            otherReportIndex !== -1
                ? allReports.splice(otherReportIndex, 1)[0]
                : null;

        if (otherReport) {
            allReports.push(otherReport);
        }

        const updatedAlumnos = students.map((alumno) => ({
            ...alumno,
            reports: allReports.map((report) => ({
                ...report,
                reportCheck: false,
                asessor_id: assesor?.assessor_id,
                description: report.title,
                user_id: userId,
                otherDescription:
                    report.title.toLowerCase() === 'otro' ? '' : '',
            })),
        }));

        setStudentsArray(updatedAlumnos);
    }, [openModal, groupId, allReports, assesor]);

    /*
     * Función que se ultiliza para
     * manejar el cambio de estado
     * de los switches de reporte
     */

    const handlerSwitchChange = (studentIndex, reportIndex) => {
        const updatedStudentsArray = [...studentsArray];
        updatedStudentsArray[studentIndex].reports[reportIndex].reportCheck =
            !updatedStudentsArray[studentIndex].reports[reportIndex]
                .reportCheck;

        const reportTitle =
            updatedStudentsArray[studentIndex].reports[
                reportIndex
            ].title.toLowerCase();

        if (reportTitle === 'otro') {
            const anyOtherActivated = updatedStudentsArray.some((student) =>
                student.reports.some(
                    (report) =>
                        report.title.toLowerCase() === 'otro' &&
                        report.reportCheck
                )
            );
            setAnyOtherReportActivated(anyOtherActivated);
        }

        setStudentsArray(updatedStudentsArray);
    };

    /*
     * Función que se ultiliza para
     * manejar el cambio de estado
     * del input de descripción en
     * caso de que el switch sea tipo "otro"
     */

    const handlerDescriptionChange = (
        studentIndex,
        reportIndex,
        newDescription
    ) => {
        const updatedStudentsArray = [...studentsArray];
        updatedStudentsArray[studentIndex].reports[
            reportIndex
        ].otherDescription = newDescription;
        setStudentsArray(updatedStudentsArray);
    };

    /*
     * Función que se ultiliza para
     * enviar la información capturada
     * dentro del modal
     */

    const handlerSaveReports = () => {
        setLoading(true);
        const allReportsRequests = [];

        for (let i = 0; i < studentsArray.length; i++) {
            const activeReports = studentsArray[i].reports.filter(
                (item) => item.reportCheck
            );

            if (activeReports.length > 0) {
                const studentId = studentsArray[i].student_id;

                const reportsRequest = activeReports.map((item) => {
                    const description =
                        item.title.toLowerCase() === 'otro'
                            ? item.otherDescription
                            : item.description;

                    return {
                        user_id: item.user_id,
                        studentId,
                        payload: {
                            catalog_report_id: item.catalog_report_id,
                            description,
                            group_id: group.group_id,
                        },
                    };
                });

                allReportsRequests.push(...reportsRequest);
            }
        }

        storeReport(allReportsRequests)
            .then(() => {
                setLoading(false);
                setOpenModal(false);
                feedbackApp.showFeedback({
                    title: 'Reportes guardados',
                });
            })
            .catch((err) => {
                setLoading(false);
                let FeedbackService = new Feedback();
                feedbackApp.showFeedback({
                    title: FeedbackService.getMessage(err).message,
                });
            });
    };

    const storeReport = async (allReportsRequests) => {
        for (let i = 0; i < allReportsRequests.length; i++) {
            let request = allReportsRequests[i];
            await Connection.storeReports(
                request.user_id,
                request.studentId,
                request.payload
            );
        }
    };

    return (
        <Dialog open={openModal} fullWidth>
            <DialogTitle
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ flexGrow: 1, color: '#fff' }}
                    >
                        Observaciones y Reportes <br />
                        <span style={{ fontSize: '13px', color: '#fff' }}>
                            Selecciona el tipo de Reporte u Observación con un
                            click y presiona guardar reportes.
                        </span>
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent>
                {studentsArray.map((student, studentIndex) => (
                    <Accordion key={studentIndex}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography
                                sx={{
                                    color: 'text.primary',
                                }}
                            >{`${student.last_name} ${student.second_last_name} ${student.name}`}</Typography>
                        </AccordionSummary>
                        <Stack direction="column">
                            {student.reports.map((report, reportIndex) => (
                                <AccordionDetails
                                    key={reportIndex}
                                    sx={{ ml: 1, padding: 0 }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={report.reportCheck}
                                                onChange={() =>
                                                    handlerSwitchChange(
                                                        studentIndex,
                                                        reportIndex
                                                    )
                                                }
                                            />
                                        }
                                        label={report.title}
                                    />
                                    {report.title.toLowerCase() === 'otro' &&
                                        report.reportCheck &&
                                        anyOtherReportActivated && (
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                            >
                                                <TextField
                                                    label="Descripción"
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    value={
                                                        report.otherDescription
                                                    }
                                                    onChange={(e) =>
                                                        handlerDescriptionChange(
                                                            studentIndex,
                                                            reportIndex,
                                                            e.target.value
                                                        )
                                                    }
                                                    inputProps={{
                                                        maxLength: 200,
                                                    }}
                                                />
                                                <Typography
                                                    variant="caption"
                                                    color="textSecondary"
                                                >
                                                    {
                                                        report.otherDescription
                                                            .length
                                                    }
                                                    /200 caracteres
                                                </Typography>
                                            </Box>
                                        )}
                                </AccordionDetails>
                            ))}
                        </Stack>
                    </Accordion>
                ))}
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={() => {
                        handlerSaveReports();
                    }}
                    loading={loading}
                    variant="contained"
                    disabled={
                        !studentsArray.some((student) =>
                            student.reports.some(
                                (report) => report.reportCheck === true
                            )
                        ) ||
                        studentsArray.some((student) =>
                            student.reports.some(
                                (report) =>
                                    report.title.toLowerCase() === 'otro' &&
                                    report.reportCheck &&
                                    !report.otherDescription.trim()
                            )
                        )
                    }
                >
                    {'Reportar'}
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        setOpenModal(false);
                    }}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
