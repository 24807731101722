import { createSlice } from '@reduxjs/toolkit';

const emptyState = {
    loading: false,
    error: null,
    currentTab: '/students',
    cycleSelected: {
        cycle_id: -1,
    },
};

const uiSlice = createSlice({
    name: 'ui',
    initialState: emptyState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload;
        },
        setSchoolCycleSelected: (state, action) => {
            state.cycleSelected = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });
    },
});

export const { setLoading, setError, setCurrentTab, setSchoolCycleSelected } =
    uiSlice.actions;
export default uiSlice.reducer;
