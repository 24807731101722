const { Box } = require('@mui/material');
const { styled } = require('@mui/system');

const FlexBetween = styled(Box)({
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'space-between',
    width: '100%',
});

export default FlexBetween;
