import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardActions,
    Button,
    Avatar,
    Tooltip,
} from '@mui/material';
import DistributionNoticeModal from '../../components/DistributionNoticeModal';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from '../../hooks';
import {
    getNotices,
    selectStatusServer,
} from '../../store/slices/noticesModule/selectors';
import { Error } from '../../components/Feedback';
import moment from 'moment';
import 'moment/locale/es';
import AddIcon from '@mui/icons-material/Add';
import { getNoticeTypeItem } from '../../libs/utils';
import { loadUI } from '../../store/slices/noticesModule/thunks';
import { invalidate } from '../../store/slices/noticesModule';
import GridCellExpand from '../../components/CellContent';
import SyncIcon from '@mui/icons-material/Sync';
import SkeletonPage from './components/SkeletonPage';
import useFeedback from '../../hooks/useFeedback';
import { NOTICE_TYPES } from '../../constants';
import { AvisosToolBar } from './components/AvisosToolBar';
import { selectColumnField, selectNoticeFiltersUI, selectOperatorValue, selectValue } from '../../store/slices/noticesModalUI/uiSlice';

/**
 * Pagina de avisos
 *
 * @returns
 */
const Avisos = () => {
    moment.locale('es');
    const dispatch = useDispatch();
    const Auth = useAuth();
    const feedbackApp = useFeedback();

    ///////////////// SHARED STATE ////////////////

    const serverStatus = useSelector(selectStatusServer);
    const noticesAll = useSelector(getNotices);
    const filters = useSelector(selectNoticeFiltersUI);
    const filter = filters.filter;

    //////////////// LOCAL STATE ////////////////

    const [openModal, setOpenModal] = useState(false);

    ///////////////// ACTIONS //////////////////

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate());
        reload();
    };

    /**
     * Funcion para recargar los datos del usuario
     */
    const reload = () => {
        dispatch(
            loadUI({
                professorId: Auth.getUserID(),
                schoolId: Auth.user.school_id,
            })
        )
            .unwrap()
            .then((response) => { })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const handleFilterChange = (event) => {
        let newFilterValues = {};

        event.items.forEach(filter => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value
            };
        });
        dispatch(selectColumnField(newFilterValues.columnField));
        dispatch(selectOperatorValue(newFilterValues.operatorValue));
        dispatch(selectValue(newFilterValues.value));
    };

    /////////////////////// EFECTOS SECUNDARIOS //////////////////////////

    useEffect(() => {
        dispatch(
            loadUI({
                professorId: Auth.getUserID(),
                schoolId: Auth.user.school_id,
            })
        );
    }, []);

    const columns = [
        /*{
            field: 'estatus',
            headerName: 'Estatus',
            flex: 0.5,
            minWidth: 50,
            editable:false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                  let status = getNoticeStatusItem(params.row.payload.status)
       
                return (
                  <Tooltip title={status.title} color={status.color}>
                         {status.icon}
                  </Tooltip>
                )
            },
        },*/
        {
            field: 'type',
            headerName: 'Tipo',
            type: 'singleSelect',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueOptions: NOTICE_TYPES.map((i) => i.title),
            valueGetter: (params) => {
                let typeItem = getNoticeTypeItem(params.row.type);

                return typeItem.title;
            },
        },
        {
            field: 'emisor',
            headerName: 'Emisor',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ row }) => {
                let emmiterName = 'Usuario no encontrado';

                if (row.spokeman) {
                    let spokeman = row.spokeman;
                    emmiterName = `${spokeman.last_name} ${spokeman.second_last_name} ${spokeman.name}`;
                }

                return emmiterName;
            },
            renderCell: (params) => {
                let spokeman = params.row.spokeman;

                if (!spokeman) {
                    const FullSpokeman = params.value;
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {FullSpokeman}
                        </div>
                    );
                }

                return (
                    <>
                        <Avatar
                            sx={{ width: 24, height: 24, mr: 1 }}
                            src={spokeman.url_photo_profile}
                        />
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {params.value}
                        </div>
                    </>
                );
            },
        },
        {
            field: 'title',
            headerName: 'Título',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const FullTitle = `${params.row.title}`;
                return (
                    <div
                        style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
                    >
                        {FullTitle}
                    </div>
                );
            },
        },
        {
            field: 'description',
            headerName: 'Descripción',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: function renderCellExpand(params) {
                return (
                    <GridCellExpand
                        value={params.value || ''}
                        width={params.colDef.computedWidth}
                    />
                );
            },
        },
        {
            field: 'created_at',
            headerName: 'Creación',
            type: 'date',
            flex: 0.8,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => value && new Date(value),
            valueFormatter: ({ value }) => {
                return moment(value).format('DD MMMM YYYY');
            },
        },
    ];

    /**
     * Damos formato a los datos de las columnas
     */
    const dataGrid = noticesAll.map((row) => ({ id: row.notice_id, ...row }));

    const sortedDataGrid = dataGrid.slice().sort((a, b) => {
        if (new Date(b.created_at) !== new Date(a.created_at)) {
            return new Date(b.created_at) - new Date(a.created_at);
        }

        if (b.type !== a.type) {
            return b.type - a.type;
        }

        const lastNameComparison = a.spokeman.last_name.localeCompare(b.spokeman.last_name);
        if (lastNameComparison !== 0) {
            return lastNameComparison;
        }

        const secondLastNameComparison = a.spokeman.second_last_name.localeCompare(b.spokeman.second_last_name);
        if (secondLastNameComparison !== 0) {
            return secondLastNameComparison;
        }

        return a.spokeman.name.localeCompare(b.spokeman.name);
    });

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            <DistributionNoticeModal
                openModal={openModal}
                setOpenModal={setOpenModal}
            />

            {serverStatus == 'pending' && <SkeletonPage />}

            {serverStatus == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {serverStatus == 'fulfilled' && (
                <>
                    <Box
                        style={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}
                            >
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>
                    <Card sx={{ mt: 5 }}>
                        <CardContent
                            sx={{
                                pt: 0,
                                pl: 0,
                                pr: 0,
                                pb: '0 !important',
                                ' & .MuiDataGrid-root': {
                                    border: 'none',
                                },
                                ' & .MuiDataGrid-toolbarContainer': {
                                    justifyContent: 'flex-end',
                                },
                            }}
                        >
                            <DataGrid
                                localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                        .localeText
                                }
                                rows={sortedDataGrid}
                                columns={columns}
                                pageSize={10}
                                disableSelectionOnClick
                                autoHeight
                                components={{ Toolbar: AvisosToolBar }}
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                }}
                                onFilterModelChange={handleFilterChange}
                                initialState={{
                                    filter: {
                                        filterModel: {
                                            items: filter.columnField && filter.operatorValue && filter.value ? [
                                                {
                                                    columnField: filter.columnField,
                                                    operatorValue: filter.operatorValue,
                                                    value: filter.value,
                                                }
                                            ] : [],
                                        },
                                    },
                                }}
                                disableDensitySelector
                            />
                        </CardContent>
                        <CardActions
                            sx={{
                                direction: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setOpenModal(true);
                                }}
                                size="small"
                                startIcon={<AddIcon />}
                            >
                                Agregar
                            </Button>
                        </CardActions>
                    </Card>
                </>
            )}
        </Box>
    );
};

export default Avisos;
