
function digitoVerificador1(curp17) {
    //Fuente https://consultas.curp.gob.mx/CurpSP/var diccionario  = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
    let diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ"
    let lngSuma = 0.0
    let lngDigito = 0.0
    for (var i = 0; i < 17; i++) {
        lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i)

    }
    lngDigito = (lngSuma % 10) - 10;
    let lngDigitoTrunc = Math.trunc(lngDigito)
    let digverify = lngDigitoTrunc.toString().charAt(lngDigitoTrunc.toString().length - 1)
    return digverify;
}

const obtenerConsonanteInicial = (nombre) => {
    let nombreSinAcentos = quitarDieresis(nombre);

    if (nombreSinAcentos[0].toUpperCase() === 'Ñ') {
        nombreSinAcentos = 'X' + nombreSinAcentos.slice(1);
    }

    let primeraConsonante = '';
    let foundConsonant = false;

    // Iniciar el bucle en i = 0 para incluir la primera letra
    for (let i = 0; i < nombreSinAcentos.length; i++) {
        // Saltar la primera letra del nombre
        if (i === 0) {
            continue;
        }

        if (!/[AEIOU]/i.test(nombreSinAcentos[i])) {
            if (nombreSinAcentos[i].toUpperCase() === 'Ñ') {
                nombreSinAcentos = nombreSinAcentos.slice(0, i) + 'X' + nombreSinAcentos.slice(i + 1);
            }
            primeraConsonante = nombreSinAcentos[0].toUpperCase();
            foundConsonant = true;
            break;
        }
    }

    return foundConsonant ? primeraConsonante : nombreSinAcentos[0].toUpperCase();
};

const obtenerSegundaConsonante = (nombre) => {
    if (nombre === undefined) {
        return 'X';
    }

    let nombreSinAcentos = quitarDieresis(nombre);

    // Verificar si nombreSinAcentos es undefined
    if (nombreSinAcentos === undefined) {
        return 'X';
    }

    // Verificar si la cadena es vacía
    if (nombreSinAcentos === '') {
        return 'X';
    }

    if (nombreSinAcentos[0].toUpperCase() === 'Ñ') {
        nombreSinAcentos = 'X' + nombreSinAcentos.slice(1);
    }

    let segundaConsonante = '';
    let foundConsonant = false;

    // Iniciar el bucle en i = 0 para incluir la primera letra
    for (let i = 0; i < nombreSinAcentos.length; i++) {
        // Saltar la primera letra del nombre
        if (i === 0) {
            continue;
        }

        if (!/[AEIOU]/i.test(nombreSinAcentos[i])) {
            if (nombreSinAcentos[i].toUpperCase() === 'Ñ') {
                nombreSinAcentos = nombreSinAcentos.slice(0, i) + 'X' + nombreSinAcentos.slice(i + 1);
            }
            segundaConsonante = nombreSinAcentos[i].toUpperCase();
            foundConsonant = true;
            break;
        }
    }

    // Si no se encontró una segunda consonante, asignar 'X'
    if (!foundConsonant) {
        segundaConsonante = 'X';
    }

    // Si no hay segunda letra en el nombre, asignar 'X'
    if (nombreSinAcentos.length === 1) {
        segundaConsonante = 'X';
    }

    return segundaConsonante;
};

const obtenerClaveConsonanteInicial = (nombre) => {
    const primerNombre = procesarNombresExcluidos(nombre);
    const clave = obtenerConsonanteInicial(primerNombre);

    return clave.toUpperCase();
};

const excepciones = {
    BACA: 'BXCA',
    LOCO: 'LXCO',
    BAKA: 'BXKA',
    LOKA: 'LXKA',
    BUEI: 'BXEI',
    LOKO: 'LXKO',
    BUEY: 'BXEY',
    MAME: 'MXME',
    CACA: 'CXCA',
    MAMO: 'MXMO',
    CACO: 'CXCO',
    MEAR: 'MXAR',
    CAGA: 'CXGA',
    MEAS: 'MXAS',
    CAGO: 'CXGO',
    MEON: 'MXON',
    CAKA: 'CXKA',
    MIAR: 'MXAR',
    CAKO: 'CXKO',
    MION: 'MXON',
    COGE: 'CXGE',
    MOCO: 'MXCO',
    COGI: 'CXGI',
    MOKO: 'MXKO',
    COJA: 'CXJA',
    MULA: 'MXLA',
    COJE: 'CXJE',
    MULO: 'MXLO',
    COJI: 'CXJI',
    NACA: 'NXCA',
    COJO: 'CXJO',
    NACO: 'NXCO',
    COLA: 'CXLA',
    PEDA: 'PXDA',
    CULO: 'CXLO',
    PEDO: 'PXDO',
    FALO: 'FXLO',
    PENE: 'PXNE',
    FETO: 'FXTO',
    PIPI: 'PXPI',
    GETA: 'GXTA',
    PITO: 'PXTO',
    GUEI: 'GXEI',
    POPO: 'PXPO',
    GUEY: 'GXEY',
    PUTA: 'PXTA',
    JETA: 'JXTA',
    PUTO: 'PXTO',
    JOTO: 'JXTO',
    QULO: 'QXLO',
    KACA: 'KXCA',
    RATA: 'RXTA',
    KACO: 'KXCO',
    ROBA: 'RXBA',
    KAGA: 'KXGA',
    ROBE: 'RXBE',
    KAGO: 'KXGO',
    ROBO: 'RXBO',
    KAKA: 'KXKA',
    RUIN: 'RXIN',
    KAKO: 'KXKO',
    SENO: 'SXNO',
    KOGE: 'KXGE',
    TETA: 'TXTA',
    KOGI: 'KXGI',
    VACA: 'VXCA',
    KOJA: 'KXJA',
    VAGA: 'VXGA',
    KOJE: 'KXJE',
    VAGO: 'VXGO',
    KOJI: 'KXJI',
    VAKA: 'VXKA',
    KOJO: 'KXJO',
    VUEI: 'VXEI',
    KOLA: 'KXLA',
    VUEY: 'VXEY',
    KULO: 'KXLO',
    WUEI: 'WXEI',
    LILO: 'LXLO',
    WUEY: 'WXEY',
    LOCA: 'LXCA',
};

/**
 * Funcion para convertir letras con dieresis
 */
const quitarDieresis = (cadena) => {
    const dieresisMap = {
        'Á': 'A',
        'É': 'E',
        'Í': 'I',
        'Ó': 'O',
        'Ú': 'U',
        'Ü': 'U',
        'á': 'a',
        'é': 'e',
        'í': 'i',
        'ó': 'o',
        'ú': 'u',
        'ü': 'u',
    };

    return cadena.replace(/[ÁÉÍÓÚÜáéíóúü]/g, (letra) => dieresisMap[letra]);
};

const NotallowedcharactersonCURP = (nombre, apellidoPaterno, apellidoMaterno) => {
    const cleanedNameParts = nombre.split(" ").map(quitarDieresis).filter(part => !isSpecialException(part));
    const cleanedappParts = apellidoPaterno.split(" ").map(quitarDieresis).filter(part => !isSpecialException(part));
    const cleanedapmParts = apellidoMaterno.split(" ").map(quitarDieresis).filter(part => !isSpecialException(part));

    const formattedName = cleanedNameParts.join(" ");

    // NOTO se puede colocar a 0 en la busqeuda del nombre
    const formattedapp = cleanedappParts[0];
    const formattedapm = cleanedapmParts[0];

    return { formattedName, formattedapp, formattedapm };
};

/**
 * LIMPIA LOS CARACTERES NO PERMITIDOS DE NOMBRE COMPLETO
 * 
 * @param {*} nombre 
 * @param {*} apellidoPaterno 
 * @param {*} apellidoMaterno
 * 
 * @returns 
 */
const isSpecialException = (word) => {

    const SpecialExceptions = {
        DA: /^DA\b/,
        DAS: /^DAS\b/,
        DE: /^DE\b/,
        DEL: /^DEL\b/,
        DER: /^DER\b/,
        DI: /^DI\b/,
        DIE: /^DIE\b/,
        DD: /^DD\b/,
        EL: /^EL\b/,
        LA: /^LA\b/,
        LOS: /^LOS\b/,
        LAS: /^LAS\b/,
        LE: /^LE\b/,
        LES: /^LES\b/,
        MAC: /^MAC\b/,
        MC: /^MC\b/,
        VAN: /^VAN\b/,
        VON: /^VON\b/,
        Y: /^Y\b/,
    };

    for (const key in SpecialExceptions) {
        if (SpecialExceptions[key].test(word)) {
            return true;
        }
    }
    return false;
};

const procesarNombresExcluidos = (nombre) => {
    const nombresExcluidos = ["JOSE", "MARIA", "MA.", "MA ", "J.", "J "];

    const nombresSeparados = nombre.split(" ");
    const primerNombre = nombresSeparados[0].toUpperCase();

    let segundoNombre = "";

    // Si existe el segundo nombre, lo asignamos en mayúsculas a la variable segundoNombre
    if (nombresSeparados.length > 1) {
        segundoNombre = nombresSeparados[1].toUpperCase();
    }

    // Si no existe segundoNombre entonces retorna el primer nombre
    if (segundoNombre === "") {
        return primerNombre;
    }

    // Si primerNombre esta en la lista de excluidos muestrame el segundoNombre
    if (nombresExcluidos.includes(primerNombre)) {
        return segundoNombre
    }

    // Si el segundo nombre existe o no está en la lista de excluidos, retornamos el segundo nombre si existe, de lo contrario, el primer nombre
    return primerNombre;
};

/**
 * Funciona inical para generar CURP
 */
const generarCurp = (name, firstName, lastName, burnDate, gender, burnState, Auxiliary17) => {

    if (firstName == '' && lastName == '') {
        throw "ambos apellidos no pueden estar vacios";
    }

    /**
     * Todo se debe de conciderar en mayusculas
     */
    name = name ? name.trim().toUpperCase() : '';
    firstName = firstName ? firstName.trim().toUpperCase() : '';
    lastName = lastName ? lastName.trim().toUpperCase() : '';

    /**
     * Inversion de los apellidos
     */
    if (firstName == '') {
        firstName = lastName
        lastName = 'X'
    } else if (lastName == '') {
        lastName = 'X'
    }

    ///////// REMPLAZAR LETRAS /////////

    let { formattedName, formattedapp, formattedapm } = NotallowedcharactersonCURP(name, firstName, lastName)

    ///////// REMPLAZAR LA LETRA Ñ POR X /////////

    formattedapp = formattedapp ? formattedapp.replace(/[Ñ]/g, 'X') : '';
    formattedapm = formattedapm ? formattedapm.replace(/[Ñ]/g, 'X') : '';
    formattedName = formattedName ? formattedName.replace(/[Ñ]/g, 'X') : '';

    ///////// CONSEGUIMOS LA CLAVE DEL NOMBRE EXCLUYENDO LOS NOMBRES DE JOSE Y MARIA /////////

    const nombreExcluido = procesarNombresExcluidos(formattedName)
    const claveNombre = obtenerClaveConsonanteInicial(formattedName)

    ///////// REMPLAZAR LOS CARACTERES ESPECIALES ( "/", "-", "." ) POR X /////////

    const nombreSinCaracteresEspeciales = nombreExcluido.replace(/[\/\-.]/g, "X");
    const apellidoPaternoSinCaracteresEspeciales = formattedapp.replace(/[\/\-.]/g, "X");
    const apellidoMaternoSinCaracteresEspeciales = formattedapm.replace(/[\/\-.]/g, "X");

    //////// RECUPERA LOS PRIMEROS CUATRO DIGITOS DEL NOMBRE /////////

    const primerletra = `${apellidoPaternoSinCaracteresEspeciales[0]}`
    const segundaletra = `${buscarPrimeraVocal(apellidoPaternoSinCaracteresEspeciales)}`
    const tercerletra = `${apellidoMaternoSinCaracteresEspeciales[0]}`
    const cuartaletra = `${claveNombre[0]}`

    ///////// ASIGNACION DE LA FECHA DE NACIMIENTO /////////

    const anioNacimiento = burnDate.substring(2, 4);
    const mesNacimiento = burnDate.substring(5, 7);
    const diaNacimiento = burnDate.substring(8, 10);

    ///////// VALIDACION PENULTIMO DIGITO SOBRE AÑO /////////
    let anioValidacion = Auxiliary17

    if (Auxiliary17 == null) {

        const anioNacimientoCompleto = burnDate.substring(0, 4);

        const anioNacimientoInt = parseInt(anioNacimientoCompleto, 10);

        anioValidacion = anioNacimientoInt >= 2000 ? 'A' : '0';

    }

    ///////// REGISTRA EL GENERO /////////

    const valorSexo = gender === 'H' ? 'H' : 'M';

    ///////// OBTIENE EL ESTADO DE NACIMIENTO /////////

    const valorEstado = burnState;

    ///////// SE SACA LAS CONSTANTES DE APELLIDO PATERNO, MATERNO Y NOMBRE /////////

    const consonantesPaterno = obtenerSegundaConsonante(apellidoPaternoSinCaracteresEspeciales);
    const primeraConsonanteMaterno = obtenerSegundaConsonante(apellidoMaternoSinCaracteresEspeciales);
    const primeraConsonanteNombre = obtenerSegundaConsonante(nombreSinCaracteresEspeciales);

    ///////// CONSTRUIMOS NOMBRE COMPLETO, CURP INICIAL Y CURP SIN EXCEPCIONES /////////

    const nombreCompleto = `${claveNombre.toUpperCase()} ${primerletra.toUpperCase()} ${tercerletra.toUpperCase()}`;
    const curpInicial = excepciones[nombreCompleto] || `${primerletra}${segundaletra}${tercerletra}${cuartaletra}`.toUpperCase();
    const curpSinExcepcion = excepciones[curpInicial] || curpInicial;

    ///////// DEFINIMOS UNA CURP PROVISIONAL /////////

    const curpProvisional = `${curpSinExcepcion}${anioNacimiento}${mesNacimiento}${diaNacimiento}${valorSexo}${valorEstado}${consonantesPaterno}${primeraConsonanteMaterno}${primeraConsonanteNombre}${anioValidacion}`;

    ///////// SACAMOS EL DIGITO VERIFICADOR /////////

    const digitoV = digitoVerificador1(curpProvisional)

    ///////// CONSTRUIMOS LA CURP COMPLETA /////////

    const curpCompleto = `${curpProvisional}${digitoV}`;

    return curpCompleto
};

const buscarPrimeraVocal = (palabra) => {
    const vocales = ['A', 'E', 'I', 'O', 'U'];

    for (let i = 1; i < palabra.length; i++) {
        if (vocales.includes(palabra[i].toUpperCase())) {
            return palabra[i].toUpperCase();
        }
    }

    return 'X'; // Si no se encontró vocal, retornar "X".
};

export default generarCurp;