import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export default styled(TextField)(({ theme, value, scoreMin }) => ({
    '& label.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.05)',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'rgba(0, 0, 0, 0.05)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.05)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.05)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.05)',
      },
      // Cambio de color del texto basado en la condición
      color: value < scoreMin?.value ? 'red' : 'green',
    },
  }));