import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const notificationsAdapter = createEntityAdapter({
    selectId: (notification) => notification.notification_id,
    sortComparer: (a, b) => a.notification_id - b.notification_id,
});

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: notificationsAdapter.getInitialState(),
    reducers: {
        addOneNotification: notificationsAdapter.addOne,
        addManyNotifications: notificationsAdapter.addMany,
        setNotifications: notificationsAdapter.setAll,
        updateNotification: notificationsAdapter.updateOne,
        removeOneNotification: notificationsAdapter.removeOne,
        upsertOneNotification: notificationsAdapter.upsertOne,
        upsertManyNotiifications: notificationsAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return notificationsAdapter.getInitialState();
        });
    },
});

export const {
    addOneNotification,
    addManyNotifications,
    setNotifications,
    updateNotification,
    removeOneNotification,
    upsertOneNotification,
    upsertManyNotiifications,
} = notificationsSlice.actions;

const globalizedSelectors = notificationsAdapter.getSelectors(
    (state) => state.entities.notifications
);
export const selectNotifications = (state) =>
    globalizedSelectors.selectEntities(state);

export const selectAllNotifications = (state) =>
    globalizedSelectors.selectAll(state);

export default notificationsSlice.reducer;
