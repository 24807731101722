import { createSelector, createSlice } from '@reduxjs/toolkit';
import { selectNoticesHomework } from './selectors';
import { selectNotices } from '../../../store/slices/entities/notices';
import { selectAllUsers } from '../../../store/slices/entities/users';
import { selectAllNotifications } from '../../../store/slices/entities/notifications';
import { selectAllStudents } from '../../../store/slices/entities/students';
import { selectHomeworkNoticeDetails } from '../homeworkDetails/selectors';

let emptyState = [];

export const itemsSlice = createSlice({
    name: 'items',
    initialState: emptyState,
    reducers: {
        setAllHomeworkItems: (state, action) => {
            return action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', () => {
            return emptyState;
        });
    },
});

export const { setAllHomeworkItems } = itemsSlice.actions;

export default itemsSlice.reducer;

export const selectAllNoticesHomeworkItemsEntities = createSelector(
    selectNoticesHomework,
    (homework) => {
        return homework.items;
    }
);

//Traer solo las noticias tipo 1
export const selectAllHomeworkNoticesItems = createSelector(
    selectAllNoticesHomeworkItemsEntities,
    selectNotices,
    selectHomeworkNoticeDetails,
    selectAllUsers,
    selectAllNotifications,
    selectAllStudents,
    (
        noticesDetailsVMEUUIDS,
        noticesEntities,
        viewModels,
        users,
        notifications,
        students
    ) => {
        return noticesDetailsVMEUUIDS.map((i) => {
            let currentViewModel = viewModels[i];

            let emitter = users.find(
                (user) =>
                    user.user_id ===
                    noticesEntities[currentViewModel.ui.notice_id].user_id
            );

            let noticeNotifications = notifications.filter(
                (notification) =>
                    notification.notice_id ===
                    noticesEntities[currentViewModel.ui.notice_id].notice_id
            );

            let student =
                noticeNotifications.length > 0
                    ? students.find(
                          (student) =>
                              student.student_id ===
                              noticeNotifications[0].student_id
                      )
                    : null;

            let sentNotification = noticeNotifications.filter(
                (sent) => sent.status === 1
            );
            let noticedtification = noticeNotifications.filter(
                (sent) => sent.status === 2
            );
            let readNotification = noticeNotifications.filter(
                (sent) => sent.status === 3
            );
            return {
                ...noticesEntities[currentViewModel.ui.notice_id],
                viewModel: currentViewModel,
                emitter: emitter,
                sentNotifications: sentNotification,
                noticedNotifications: noticedtification,
                readNotifications: readNotification,
                student,
            };
        });
    }
);
