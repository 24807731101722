import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
    useTheme,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../../hooks';
import {
    createConcept,
    fetchCreateConcept,
} from '../../../store/concepts/operations';
import { selectEntitiesStudents } from '../../../../store/slices/entities/students';
import { selectCatConcepts } from '../../../store/cat_concepts/selectors';
import { selectGroupsWithStudents } from '../../../store/concepts/selectors';
import * as Yup from 'yup';
import { Transfer } from 'antd';
import { Formik, Form, Field } from 'formik';
import Feedback from '../../../../service/Feedback';
import { selectCatSurcharge } from '../../../store/cat_surcharges/selectors';

const CreateConcept = ({ open, onclose }) => {
    //////////////////////////// LOCAL STATE ////////////////////////////

    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [stepType, setStepType] = useState(null);
    const [automaticSurcharge, setAutomaticSurcharge] = useState(false);
    const [selectedSurcharge, setSelectedSurcharge] = useState(null);
    const [filteredStudentsData, setFilteredStudentsData] = useState([]);

    //////////////////////////// SHARE STATE ////////////////////////////

    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const feedbackApp = useFeedback();

    const studentsData = useSelector((state) =>
        Object.values(selectEntitiesStudents(state)).map((student) => ({
            key: student.student_id,
            name:
                student.name +
                ' ' +
                student.last_name +
                ' ' +
                student.second_last_name,
        }))
    );

    const catalogConcepts = useSelector((state) =>
        Object.values(selectCatConcepts(state))
    );
    const groups = useSelector(selectGroupsWithStudents);

    const surcharge = useSelector((state) =>
        Object.values(selectCatSurcharge(state))
    );
    //////////////////////////// USE EFFECT ////////////////////////////

    useEffect(() => {
        if (open) {
            dispatch(fetchCreateConcept(schoolId)).finally(() =>
                setLoading(false)
            );
        }
    }, [dispatch, schoolId, open]);

    //////////////////////////// FUNCTIONS ////////////////////////////

    const steps = [
        'Información General',
        'Seleccionar Alumnos o Grupos',
        'Asignar Alumnos',
    ];

    const handleStudentSelect = (targetKeys) => {
        setSelectedStudents(targetKeys);
    };
    const handleGroupSelect = (event, value) => {
        const newStudentsInGroups = value.flatMap((group) =>
            group.students.map((student) => student.student_id)
        );

        const previousStudentsInGroups = selectedGroups.flatMap((group) =>
            group.students.map((student) => student.student_id)
        );

        const studentsToRemove = previousStudentsInGroups.filter(
            (studentId) => !newStudentsInGroups.includes(studentId)
        );

        const updatedSelectedStudents = selectedStudents.filter(
            (studentId) => !studentsToRemove.includes(studentId)
        );

        const studentsToAdd = newStudentsInGroups.filter(
            (studentId) => !selectedStudents.includes(studentId)
        );

        setSelectedStudents([...updatedSelectedStudents, ...studentsToAdd]);

        setFilteredStudentsData(
            studentsData.filter((student) =>
                updatedSelectedStudents
                    .concat(studentsToAdd)
                    .includes(student.key)
            )
        );

        setSelectedGroups(value);
    };
    const handleNext = (type = null) => {
        setStepType(type);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setSelectedStudents([]);
        setSelectedGroups([]);
        setAutomaticSurcharge(false);
    };

    const handleReset = () => {
        setActiveStep(0);
        setSelectedStudents([]);
        setSelectedGroups([]);
        setStepType(null);
        setAutomaticSurcharge(false);
        onclose();
    };

    const normalizeString = (str) =>
        str
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();

    //////////////////////////// VALIDATION SCHEMA ////////////////////////////

    const validationSchema = Yup.object({
        description: Yup.string().required('Descripción es requerida'),
        limit_date: Yup.date()
            .min(
                new Date(new Date().setHours(0, 0, 0, 0)),
                'La fecha no puede ser en el pasado'
            )
            .required('Fecha límite es requerida'),
        concept_catalog_id: Yup.number().required(
            'Catálogo de concepto es requerido'
        ),
    });

    //////////////////////////// HANDLERS ////////////////////////////

    const handleSubmit = async (values) => {
        const data = selectedStudents.map((studentId) => {
            const item = {
                description: values.description,
                limit_date: values.limit_date,
                concept_catalog_id: values.concept_catalog_id,
                student: studentId,
            };

            if (automaticSurcharge) {
                item.automatic_surcharge = 1;

                if (selectedSurcharge?.surcharge_catalog_id) {
                    item.surcharge_catalog_id =
                        selectedSurcharge.surcharge_catalog_id;
                }
            }

            return item;
        });

        await dispatch(createConcept(data))
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Concepto registrado correctamente',
                });
                handleReset();
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title: error.feedback.title,
                });
            });
    };

    //////////////////////////// RENDER ////////////////////////////

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onclose();
                }
            }}
            maxWidth="lg"
            fullWidth
            disableEscapeKeyDown
            sx={{
                '& .MuiDialog-paper': {
                    padding: 5,
                    borderRadius: 2,
                },
            }}>
            {loading ? (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <DialogTitle
                        sx={{
                            textAlign: 'center',
                            position: 'relative',
                        }}>
                        <Typography variant="h4" component="div">
                            Registra un Concepto
                        </Typography>
                        <Typography variant="body2">
                            Aquí podrás registrar un nuevo concepto
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={handleReset}
                            sx={{
                                position: 'absolute',
                                right: 6,
                                top: 6,
                                color: theme.palette.grey[500],
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Formik
                            initialValues={{
                                description: '',
                                limit_date: '',
                                concept_catalog_id: null,
                                automatic_surcharge: 0,
                                surcharge_catalog_id: null,
                                selectedGroups: [],
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}>
                            {({
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form>
                                    <Stepper
                                        activeStep={activeStep}
                                        alternativeLabel>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    {activeStep === 0 && (
                                        <Box sx={{ mt: 3 }}>
                                            <Field
                                                name="description"
                                                as={TextField}
                                                fullWidth
                                                label="Descripción"
                                                error={
                                                    touched.description &&
                                                    Boolean(errors.description)
                                                }
                                                helperText={
                                                    touched.description &&
                                                    errors.description
                                                }
                                                variant="outlined"
                                                margin="normal"
                                            />
                                            <Field
                                                name="limit_date"
                                                as={TextField}
                                                fullWidth
                                                type="date"
                                                label="Fecha límite"
                                                error={
                                                    touched.limit_date &&
                                                    Boolean(errors.limit_date)
                                                }
                                                helperText={
                                                    touched.limit_date &&
                                                    errors.limit_date
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                margin="normal"
                                                inputProps={{
                                                    min: new Date()
                                                        .toISOString()
                                                        .split('T')[0],
                                                }}
                                            />
                                            <Autocomplete
                                                noOptionsText="No hay opciones disponibles"
                                                value={
                                                    catalogConcepts.find(
                                                        (concept) =>
                                                            concept.concept_catalog_id ===
                                                            values.concept_catalog_id
                                                    ) || null
                                                }
                                                options={catalogConcepts.filter(
                                                    (concept) =>
                                                        concept.active === true
                                                )}
                                                getOptionLabel={(option) =>
                                                    option.concept
                                                }
                                                onChange={(event, value) =>
                                                    setFieldValue(
                                                        'concept_catalog_id',
                                                        value?.concept_catalog_id
                                                    )
                                                }
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <Box
                                                        component="li"
                                                        {...props}
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                            alignItems:
                                                                'center',
                                                            borderBottom:
                                                                '1px solid #ddd',
                                                            padding: '8px 16px',
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            }}>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}>
                                                                {option.concept}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary">
                                                                ID:{' '}
                                                                {
                                                                    option.concept_catalog_id
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            sx={{
                                                                marginLeft:
                                                                    'auto',
                                                            }}>
                                                            Total: $
                                                            {option.value}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Catálogo de concepto"
                                                        variant="outlined"
                                                        margin="normal"
                                                        error={
                                                            touched.concept_catalog_id &&
                                                            Boolean(
                                                                errors.concept_catalog_id
                                                            )
                                                        }
                                                        helperText={
                                                            touched.concept_catalog_id &&
                                                            errors.concept_catalog_id
                                                        }
                                                    />
                                                )}
                                            />
                                            <Box
                                                sx={{
                                                    mt: 2,
                                                    textAlign: 'right',
                                                }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleNext()}
                                                    disabled={
                                                        !(
                                                            values.description &&
                                                            values.limit_date &&
                                                            values.concept_catalog_id
                                                        )
                                                    }>
                                                    Siguiente
                                                </Button>
                                            </Box>
                                        </Box>
                                    )}
                                    {activeStep === 1 && (
                                        <>
                                            <Box
                                                sx={{
                                                    mt: 3,
                                                    textAlign: 'center',
                                                }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        gap: 4,
                                                        mt: 4,
                                                    }}>
                                                    <CardActionArea
                                                        sx={{ width: 400 }}
                                                        onClick={() =>
                                                            handleNext(
                                                                'students'
                                                            )
                                                        }>
                                                        <Card
                                                            sx={{ width: 400 }}>
                                                            <CardMedia
                                                                component="img"
                                                                height="200"
                                                                image="/images/GroupPrincipal.jpeg"
                                                                alt="Alumnos"
                                                            />
                                                            <CardContent>
                                                                <Typography
                                                                    gutterBottom
                                                                    variant="h5"
                                                                    component="div">
                                                                    Alumnos
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </CardActionArea>
                                                    <CardActionArea
                                                        sx={{ width: 400 }}
                                                        onClick={() =>
                                                            handleNext('groups')
                                                        }>
                                                        <Card
                                                            sx={{ width: 400 }}>
                                                            {' '}
                                                            <CardMedia
                                                                component="img"
                                                                height="200"
                                                                image="/images/GroupSpecial.jpeg"
                                                                alt="Grupos"
                                                            />
                                                            <CardContent>
                                                                <Typography
                                                                    gutterBottom
                                                                    variant="h5"
                                                                    component="div">
                                                                    Grupos
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </CardActionArea>
                                                </Box>
                                            </Box>

                                            <Box sx={{ mt: 2 }}>
                                                <Button
                                                    color="primary"
                                                    onClick={handleBack}
                                                    sx={{ mr: 2 }}>
                                                    Atrás
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                    {activeStep === 2 && (
                                        <Box sx={{ mt: 3 }}>
                                            {stepType === 'students' && (
                                                <>
                                                    <Typography
                                                        variant="h6"
                                                        textAlign="center"
                                                        sx={{
                                                            mt: 2,
                                                            padding: 2,
                                                        }}>
                                                        Selecciona los Alumnos
                                                    </Typography>
                                                    <Transfer
                                                        listStyle={{
                                                            width: '100%',
                                                            height: 400,
                                                        }}
                                                        dataSource={
                                                            studentsData
                                                        }
                                                        showSearch
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) =>
                                                            normalizeString(
                                                                option?.name ??
                                                                    ''
                                                            ).includes(
                                                                normalizeString(
                                                                    input
                                                                )
                                                            )
                                                        }
                                                        targetKeys={
                                                            selectedStudents
                                                        }
                                                        onChange={
                                                            handleStudentSelect
                                                        }
                                                        render={(item) =>
                                                            item.name
                                                        }
                                                        rowKey={(item) =>
                                                            item.key
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    automaticSurcharge
                                                                }
                                                                onChange={(e) =>
                                                                    setAutomaticSurcharge(
                                                                        e.target
                                                                            .checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Recargos Automáticos"
                                                        sx={{ mt: 2 }}
                                                    />
                                                    {automaticSurcharge && (
                                                        <Autocomplete
                                                            noOptionsText="No hay opciones disponibles"
                                                            options={surcharge.filter(
                                                                (surcharge) =>
                                                                    surcharge.active ===
                                                                    true
                                                            )}
                                                            getOptionLabel={(
                                                                option
                                                            ) => option.title}
                                                            onChange={(
                                                                event,
                                                                value
                                                            ) =>
                                                                setSelectedSurcharge(
                                                                    value ||
                                                                        null
                                                                )
                                                            }
                                                            renderOption={(
                                                                props,
                                                                option
                                                            ) => (
                                                                <Box
                                                                    component="li"
                                                                    {...props}
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        justifyContent:
                                                                            'space-between',
                                                                        alignItems:
                                                                            'center',
                                                                        borderBottom:
                                                                            '1px solid #ddd',
                                                                        padding:
                                                                            '8px 16px',
                                                                    }}>
                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'column',
                                                                        }}>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontWeight:
                                                                                    'bold',
                                                                            }}>
                                                                            {
                                                                                option.title
                                                                            }
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="textSecondary">
                                                                            ID:{' '}
                                                                            {
                                                                                option.surcharge_catalog_id
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="textSecondary"
                                                                        sx={{
                                                                            marginLeft:
                                                                                'auto',
                                                                        }}>
                                                                        Total:
                                                                        {option.value_modality ===
                                                                        1
                                                                            ? `$${option.value}`
                                                                            : `${option.value}%`}
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Catálogo de recargos"
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                </>
                                            )}
                                            {stepType === 'groups' && (
                                                <>
                                                    <Typography
                                                        variant="h6"
                                                        textAlign="center"
                                                        sx={{
                                                            mt: 2,
                                                            padding: 2,
                                                        }}>
                                                        Selecciona los Grupos
                                                    </Typography>

                                                    <Autocomplete
                                                        noOptionsText="No hay opciones disponibles"
                                                        multiple
                                                        options={groups}
                                                        renderOption={(
                                                            props,
                                                            option
                                                        ) => (
                                                            <Box
                                                                component="li"
                                                                {...props}
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'space-between',
                                                                    alignItems:
                                                                        'center',
                                                                    borderBottom:
                                                                        '1px solid #ddd',
                                                                    padding:
                                                                        '8px 16px',
                                                                }}>
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'column',
                                                                    }}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        sx={{
                                                                            fontWeight:
                                                                                'bold',
                                                                        }}>
                                                                        {option.grade +
                                                                            ' ' +
                                                                            option.group}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="textSecondary">
                                                                        Turno:{' '}
                                                                        {option.turn ===
                                                                        1
                                                                            ? 'Matutino'
                                                                            : 'Vespertino'}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        )}
                                                        getOptionLabel={(
                                                            option
                                                        ) => {
                                                            const turnLabel =
                                                                option.turn ===
                                                                1
                                                                    ? 'Matutino'
                                                                    : 'Vespertino';
                                                            return `${option.grade} ${option.group}\n${turnLabel}`;
                                                        }}
                                                        onChange={(
                                                            event,
                                                            value
                                                        ) => {
                                                            setFieldValue(
                                                                'selectedGroups',
                                                                value
                                                            );
                                                            handleGroupSelect(
                                                                event,
                                                                value
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Grupos"
                                                                variant="outlined"
                                                                margin="normal"
                                                            />
                                                        )}
                                                    />

                                                    <Typography
                                                        textAlign="center"
                                                        variant="h6"
                                                        sx={{
                                                            mt: 2,
                                                            padding: 2,
                                                        }}>
                                                        Alumnos en Grupos
                                                    </Typography>
                                                    <Transfer
                                                        dataSource={
                                                            filteredStudentsData
                                                        }
                                                        listStyle={{
                                                            width: '100%',
                                                            height: 400,
                                                        }}
                                                        showSearch
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) =>
                                                            normalizeString(
                                                                option?.name ??
                                                                    ''
                                                            ).includes(
                                                                normalizeString(
                                                                    input
                                                                )
                                                            )
                                                        }
                                                        targetKeys={
                                                            selectedStudents
                                                        }
                                                        onChange={
                                                            handleStudentSelect
                                                        }
                                                        render={(item) =>
                                                            item.name
                                                        }
                                                        rowKey={(item) =>
                                                            item.key
                                                        }
                                                    />
                                                    <Box>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        automaticSurcharge
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setAutomaticSurcharge(
                                                                            e
                                                                                .target
                                                                                .checked
                                                                        )
                                                                    }
                                                                />
                                                            }
                                                            label="Recargos Automáticos"
                                                            sx={{ mt: 2 }}
                                                        />
                                                        {automaticSurcharge && (
                                                            <Autocomplete
                                                                noOptionsText="No hay opciones disponibles"
                                                                options={surcharge.filter(
                                                                    (
                                                                        surcharge
                                                                    ) =>
                                                                        surcharge.active ===
                                                                        true
                                                                )}
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option.title
                                                                }
                                                                onChange={(
                                                                    event,
                                                                    value
                                                                ) =>
                                                                    setSelectedSurcharge(
                                                                        value ||
                                                                            null
                                                                    )
                                                                }
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Catálogo de recargos"
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                    />
                                                                )}
                                                                renderOption={(
                                                                    props,
                                                                    option
                                                                ) => (
                                                                    <Box
                                                                        component="li"
                                                                        {...props}
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                            justifyContent:
                                                                                'space-between',
                                                                            alignItems:
                                                                                'center',
                                                                            borderBottom:
                                                                                '1px solid #ddd',
                                                                            padding:
                                                                                '8px 16px',
                                                                        }}>
                                                                        <Box
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                                flexDirection:
                                                                                    'column',
                                                                            }}>
                                                                            <Typography
                                                                                variant="body1"
                                                                                sx={{
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                }}>
                                                                                {
                                                                                    option.title
                                                                                }
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="textSecondary">
                                                                                ID:{' '}
                                                                                {
                                                                                    option.surcharge_catalog_id
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="textSecondary"
                                                                            sx={{
                                                                                marginLeft:
                                                                                    'auto',
                                                                            }}>
                                                                            Total:
                                                                            $
                                                                            {
                                                                                option.value
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                            />
                                                        )}
                                                    </Box>
                                                </>
                                            )}

                                            <Box
                                                sx={{ display: 'flex', mt: 2 }}>
                                                <Button
                                                    fullWidth
                                                    color="primary"
                                                    onClick={handleBack}
                                                    sx={{ mr: 2 }}>
                                                    Atrás
                                                </Button>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={
                                                        isSubmitting ||
                                                        selectedStudents.length ===
                                                            0 ||
                                                        (automaticSurcharge &&
                                                            !selectedSurcharge)
                                                    }>
                                                    {' '}
                                                    {isSubmitting ? (
                                                        <CircularProgress
                                                            size={24}
                                                        />
                                                    ) : (
                                                        'Registrar Concepto'
                                                    )}
                                                </Button>
                                            </Box>
                                        </Box>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};

export default CreateConcept;
