import {
    Autocomplete,
    Avatar,
    Box,
    Divider,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { throttle, debounce } from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import Connection from '../../service/Connection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import Authentication from '../../service/Login';
import { useAuth } from '../../hooks';

/**
 * Componente para buscar usuario
 */
const UsersSearchCura = ({ onChange }) => {
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    /////////////////////// LOCAL STATE //////////////////////

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [requestValue, setRequestValue] = useState('');
    const [noResults, setNoResults] = useState(false);

    //////////////////////// ACTIONS /////////////////////////

    const fetchThrottledAndDebounced = useMemo(
        () =>
            throttle(
                debounce((request, callback) => {
                    setRequestValue(request.search);
                    if (request.search.length >= 3) {
                        let Auth = new Authentication();
                        Connection.getResourcesPaginatedFromServer(
                            'findUsersCura',
                            [request]
                        ).then((response) => {
                            const allUsers = response.data.filter((user) => {
                                const roles = [
                                    'ADMINISTER',
                                    'PROFESSOR',
                                    'ASSESSOR',
                                    'SPOKESMAN',
                                    'DIRECTOR',
                                    'ADMINISTRATIVE',
                                ];
                                return (
                                    !Auth.belongToGroupCura('PARENT', user) ||
                                    roles.some((role) =>
                                        Auth.belongToGroupCura(role, user)
                                    )
                                );
                            });
                            callback(allUsers);
                        });
                    }
                }, 1500),
                1500
            ),
        []
    );

    /**
     * Recupera todos los usuario
     */
    useEffect(() => {
        let active = true;
        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        const request = {
            search: inputValue,
            //filters: { status: 0 },
            per_page: 20,
            fields: {
                only: [
                    'cellphone',
                    'school_id',
                    'status',
                    'email',
                    'has_smartphone',
                    'last_name',
                    'name',
                    'user_id',
                    'group_id',
                    'second_last_name',
                    'Apm',
                    'url_photo_profile',
                ],
            },
        };
        setLoading(true);

        fetchThrottledAndDebounced(request, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    results.sort((a, b) => {
                        const nameA =
                            a.name +
                            ' ' +
                            a.last_name +
                            ' ' +
                            a.second_last_name;
                        const nameB =
                            b.name +
                            ' ' +
                            b.last_name +
                            ' ' +
                            b.second_last_name;
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });

                    newOptions = [...newOptions, ...results];
                }

                // const uniqueUsers = new Set(newOptions.map(user => user.user_id));
                // results.forEach(user => uniqueUsers.add(user.user_id));

                const userIds = newOptions.map((user) => user.user_id);
                const uniqueUserIds = userIds.filter(
                    (user_id, index) => userIds.indexOf(user_id) === index
                );

                newOptions = [...uniqueUserIds].map((userId) =>
                    results.find((user) => user.user_id === userId)
                );

                setOptions(newOptions);
                setLoading(false);
                setNoResults(newOptions.length === 0);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchThrottledAndDebounced]);

    const inputSearchValid = () => {
        return {
            invalid: requestValue.length <= 3,
            message: 'El valor debe ser mayor o igual a tres caracteres',
        };
    };

    /**
     * Funcion lanzada si un usuario a sido seleccionado
     *
     * @param {*} event
     * @param {*} newValue
     */
    const onChangeItem = (event, newValue) => {
        setValue(newValue);

        if (newValue) {
            Connection.getUserById(newValue.user_id)
                .then((i) => {
                    onChange(i.data.data);
                })
                .catch((err) => {
                    console.log(err);
                    setInputValue('');
                    onChange(null);
                });
        } else {
            onChange(null);
        }
    };

    return (
        <Box>
            <Typography variant="h6" component="div" sx={{ mb: 1 }}>
                Buscar usuario
            </Typography>

            <Autocomplete
                id="controllable-states-demo"
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                loading={loading}
                loadingText={
                    inputValue.length < 3
                        ? 'Escribe para iniciar búsqueda'
                        : 'Buscando...'
                }
                noOptionsText={
                    inputValue.length < 3
                        ? 'Escribe para iniciar búsqueda'
                        : noResults
                        ? 'No se encontraron resultados'
                        : ''
                }
                onChange={onChangeItem}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                getOptionLabel={(option) =>
                    `${option.name} ${option.last_name} ${option.second_last_name}`
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        error={inputSearchValid().invalid}
                        helperText={
                            inputSearchValid().invalid
                                ? inputSearchValid().message
                                : false
                        }
                        label="ej Pedro"
                        fullWidth
                    />
                )}
                renderOption={(props, option) => {
                    return (
                        <>
                            <li {...props}>
                                <Stack
                                    direction={'row'}
                                    spacing={2}
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Avatar
                                        alt={option.name}
                                        src={option.url_photo_profile}
                                        sx={{ width: 34, height: 34, mr: 1 }}
                                    />
                                    <Stack direction={'column'} spacing={1}>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            <strong>
                                                {option.name} {option.last_name}{' '}
                                                {option.second_last_name}
                                            </strong>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            <FontAwesomeIcon icon={faPhone} />{' '}
                                            {option.cellphone}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </li>
                            <Divider variant="inset" component="li" />
                        </>
                    );
                }}
            />
        </Box>
    );
};

export default UsersSearchCura;
