import styled from '@emotion/styled';
import {
    TextField,
} from '@mui/material';

/**
 * Componente para editar las calificaciones
 */
const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.05)',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'rgba(0, 0, 0, 0.05)',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.05)',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.05)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.05)',
        },
    },
});

/**
 * Componente destinado a permitir la edicion de una calificacion
 */
const ScoreEditableCell = ({defaultValue, scoreMinimum, onBlur, key}) => {

    let style = {}

    if (defaultValue != 0) {
        style = parseInt(defaultValue) < parseInt(scoreMinimum) ? {
            borderColor: "red",
            borderStyle: "solid",
            borderWidth: "thin"
        } : {
            borderColor: "green",
            borderStyle: "solid",
            borderWidth: "thin"
        }
    }

    return (
        <CssTextField
            id="outlined-basic"
            key={key}
            variant="outlined"
            margin="dense"
            size="small"
            fullWidth
            sx={{...style}}
            defaultValue={defaultValue}
            onBlur={onBlur}
        />
    )
}

export default ScoreEditableCell