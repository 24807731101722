import { createSlice } from "@reduxjs/toolkit";
import * as _ from 'lodash';

const emptyState = {
    groupSelected: null,
    filter: {
        scoreFilters: {
            columnField: '',
            operatorValue: '',
            value: '',
        },
        scoreImportCenter: {
            columnField: '',
            operatorValue: '',
            value: '',
        }
    }
}

/**
 * Slice para el settings UI 
 */
export const scoresSlice = createSlice({
    name: 'scoresUI/ui',
    initialState: emptyState,
    reducers: {
        selectItem: (state, { payload }) => {
            state.groupSelected = payload
        },
        updateScoreFilter: (state, { payload }) => {
            const { filter } = payload;
            state.filter.scoreFilters = { ...state.filter.scoreFilters, ...filter };
        },
        updateScoreImportFilter: (state, { payload }) => {
            const { filter } = payload;
            state.filter.scoreImportCenter = { ...state.filter.scoreImportCenter, ...filter };
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })
    }
});

export const { selectItem, updateScoreFilter, updateScoreImportFilter } = scoresSlice.actions;

export default scoresSlice.reducer;

//////////////////// SELECTORES //////////////////

/**
 * Recuperamos las configuraciones de la escuela 
 */
export const selectScoresUI = (state) => state.scoresUI.ui;


//////////////// TRUNCKS /////////////////

