import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectTotalProfessors,
} from '../../../store/home/fetchSlice';
import { getTotalProfessors } from '../../../store/home/thunks';
import PeopleIcon from '@mui/icons-material/People';
import { SkeletonCard } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeTotalProfessorsStatusServer } from '../../../store/home/componentStatusSelectors';

const TotalProfessorsCard = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeTotalProfessorsStatusServer
    );

    const totalProfessors = useFetchResource(
        () =>
            getTotalProfessors({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectTotalProfessors,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getTotalProfessors({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectTotalProfessors,
            [cycleSelected, schoolId]
        );
    };

    return (
        <Box>
            {fetchingStatus === 'pending' && <SkeletonCard items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    style={{
                        backgroundColor: '#ff9800',
                        borderRadius: '10px',
                        textAlign: 'center',
                        minHeight: '200px',
                        minWidth: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: 'none',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        margin: 'auto',
                    }}
                >
                    <CardContent style={{ padding: '16px', width: '100%' }}>
                        <PeopleIcon
                            style={{
                                fontSize: 40,
                                color: 'rgb(232, 247, 255)',
                                marginBottom: '10px',
                            }}
                        />
                        <Typography
                            variant="h6"
                            align="center"
                            style={{
                                color: 'rgb(232, 247, 255)',
                                marginBottom: '10px',
                                whiteSpace: 'pre-wrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {/* {totalProfessors.title} */}
                            {'Total Profesores'}
                        </Typography>
                        <Typography
                            variant="h4"
                            style={{
                                color: 'rgb(232, 247, 255)',
                            }}
                        >
                            {totalProfessors.value || 0}
                        </Typography>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default TotalProfessorsCard;
