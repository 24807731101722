import { Alert, Avatar, Box, Divider, Grid, List, ListSubheader, Typography } from "@mui/material"
import _ from "lodash"
import { useState } from "react"
import { useEffect } from "react"
import { useAuth } from "../../../../hooks"
import useFeedback from "../../../../hooks/useFeedback"
import { getNameSchoolLevel, getTurnsLabel } from "../../../../helper"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { blue } from "@mui/material/colors"
import TargetSubjectListItem from "./TargetSubjectListItem"
import SourceSubjectListItem from "./SourceSubjectListItem"
import { useDispatch, useSelector } from "react-redux"
import { fetchSpecialGroupsSubjects, selectFetchSpecialGroupsSubjects } from "../../../../store/slices/studentsUI/operationsSlice"
import ResolveConflictsSubjectsSkeleton from './ResolveConflictsSubjectsSkeleton';

/**
 * Componente para resolver los conflictos de las materias
 * 
 * @param groups Grupos  
 * 
 * @returns 
 */
const ResolveConflictsSubjects = ({ currentGroup, newGroup, onChangeSubjects }) => {

    const user = useAuth()
    const feedbackApp = useFeedback()
    const dispatch = useDispatch()

    /////////// SHARES STATE ///////////////////

    const fetchSubjectsStatus = useSelector(selectFetchSpecialGroupsSubjects)

    //////////// LOCAL STATE ///////////

    const [sourceSubjects, setSourceSubjects] = useState([])

    const [targetSubjects, setTargetSubjects] = useState([])

    /////////// ACTIONS ////////////////

    /**
     * Si se cambia alguno de los grupos recuperamos nuevamente sus materias
     */
    useEffect(() => {
        if (currentGroup != null && newGroup != null) {
            dispatch(fetchSpecialGroupsSubjects({
                sourceGroupId: currentGroup.group_id,
                targetGroupId: newGroup.group_id,
                schoolId: user.getUser().school_id
            }))
                .unwrap()
                .then(({ sourceGroupSubjects, targetGroupSubjects, catalogSubjects }) => {

                    let sourcesubjects = sourceGroupSubjects.map(s => ({
                        ...s, catalog: catalogSubjects.find(c => s.catalog_subject_id == c.catalog_subject_id),
                        target_subject_id: null
                    }))

                    let targetSubjects = targetGroupSubjects.map(s => ({
                        ...s,
                        catalog: catalogSubjects.find(c => s.catalog_subject_id == c.catalog_subject_id),
                        source_subject_id: null
                    }))

                    setSourceSubjects(sourcesubjects)
                    setTargetSubjects(targetSubjects)
                }).catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title
                    })
                })
        }
    }, [currentGroup, newGroup])

    /**
     * Notifica al padre si hay algun cambio en las materias
     */
    useEffect(() => {

        onChangeSubjects(sourceSubjects, targetSubjects)

    }, [targetSubjects, sourceSubjects])

    /**
     * El usuario selecciono una materia 
     * 
     * @param {*} targetSubject 
     * @param {*} sourceSubject 
     */
    const onSelectSubject = (sourceSubject, targetSubject) => {

        let newTargetSubjectUpdated = targetSubjects.map(i => {
            return { ...i, source_subject_id: (i.subject_id == targetSubject.subject_id) ? sourceSubject.subject_id : i.source_subject_id }
        })

        let newSourceSubjectUpdated = sourceSubjects.map(i => {
            return { ...i, target_subject_id: (i.subject_id == sourceSubject.subject_id) ? targetSubject.subject_id : i.target_subject_id }
        })

        setTargetSubjects(newTargetSubjectUpdated)
        setSourceSubjects(newSourceSubjectUpdated)
    }

    /**
     * Remover materia fuente de una materia objectivo
     * 
     * @param {*} targetSubject 
     */
    const onRemoveSubject = (targetSubject) => {

        let newTargetSubjectUpdated = targetSubjects.map(i => {
            return { ...i, source_subject_id: (i.subject_id == targetSubject.subject_id) ? null : i.source_subject_id }
        })

        let newSourceSubjectUpdated = sourceSubjects.map(i => {
            return { ...i, target_subject_id: (i.subject_id == targetSubject.source_subject_id) ? null : i.target_subject_id }
        })

        setTargetSubjects(newTargetSubjectUpdated)
        setSourceSubjects(newSourceSubjectUpdated)
    }

    return (
        <>
            {
                fetchSubjectsStatus == "fulfilled" && (
                    <>
                        <Grid container>
                            <Grid item xs={12} md={5}>
                                <Box>
                                    <Box sx={{ textAlign: "center", mb: 2 }}>
                                        <Typography component={"div"} variant={"h6"}>Grupo actual</Typography>

                                        <Typography component={"span"} variant={"subtitle1"} >
                                            {currentGroup?.grade}°{currentGroup?.group} {getTurnsLabel(currentGroup?.turn)} {getNameSchoolLevel(currentGroup?.level)}
                                        </Typography>
                                    </Box>

                                    <Divider variant="middle" />

                                    <List dense={true}
                                        subheader={
                                            <ListSubheader component="div" id="nested-list-subheader">
                                                Materias de origen
                                            </ListSubheader>
                                        }
                                    >
                                        {
                                            sourceSubjects.map(s => (
                                                <SourceSubjectListItem
                                                    subject={s}
                                                    availableSubjects={targetSubjects.filter(i => i.source_subject_id == null)}
                                                    onSelectSubject={onSelectSubject}
                                                />
                                            ))
                                        }
                                    </List>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={2} display={"flex"} direction={"column"} justifyContent={"flex-start"} alignItems={"center"}>
                                <Avatar variant="square" sx={{ bgcolor: blue[500] }}>
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </Avatar>
                                <Divider variant="middle" orientation={"vertical"} sx={{ height: "100px" }} />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Box>
                                    <Box sx={{ textAlign: "center", mb: 2 }}>
                                        <Typography component={"div"} variant={"h6"}>Grupo nuevo</Typography>

                                        <Typography component={"span"} variant={"subtitle1"} >
                                            {newGroup?.grade}°{newGroup?.group} {getTurnsLabel(newGroup?.turn)} {getNameSchoolLevel(newGroup?.level)}
                                        </Typography>
                                    </Box>

                                    <Divider variant="middle" />

                                    <List dense={true}
                                        subheader={
                                            <ListSubheader component="div" id="nested-list-subheader">
                                                Materias destino
                                            </ListSubheader>
                                        }
                                    >
                                        {
                                            targetSubjects.map(s => (
                                                <TargetSubjectListItem
                                                    subject={s}
                                                    currentSubjects={sourceSubjects}
                                                    onRemoveSubject={onRemoveSubject}
                                                />
                                            ))
                                        }
                                    </List>
                                </Box>
                            </Grid>
                        </Grid>

                        <Alert severity="info">
                            Las calificaciones, asistencias, etc. de las materias origen serán transferidas a las materias de
                            destino
                        </Alert>
                    </>

                )
            }
            {
                fetchSubjectsStatus == "pending" && (
                    <ResolveConflictsSubjectsSkeleton />
                )
            }

        </>
    )
}

export default ResolveConflictsSubjects