import { createAsyncThunk } from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import {
    selectComponentHomeBestScoreStudentsFetch,
    selectComponentHomeAssistanceIndexFetch,
    selectComponentHomeAverageImcSchoolFetch,
    selectComponentHomeBestScoreSubjectFetch,
    selectComponentHomeFrequentReportsFetch,
    selectComponentHomeGradeAverageFetch,
    selectComponentHomeTotalStudentsFetch,
    selectComponentHomeTotalProfessorsFetch,
    selectComponentHomeSchoolAverageFetch,
    selectComponentHomeTotalAbscenceFetch,
    selectComponentHomeTotalStudentWithoutGroupFetch,
    selectComponentHomeTotalReportsFetch,
    selectComponentHomeTotalScholarshipFetch,
    selectComponentHomeTotalRegisteredParentsFetch,
    selectComponentHomeTotalIncidentsFetch,
    selectComponentHomeLastAttendanceProfessorFetch,
    selectComponentHomeTotalMaleFemaleStudentsFetch,
    selectComponentHomeStudentsDiversityFetch,
    selectComponentHomeTotalStudentsApprovedFailedFetch,
    selectComponentHomeTotalGroupsFetch,
    selectComponentHomeTotalMaleFemaleStudentsRegisterFetch,
    selectComponentHomeTotalMaleFemaleStudentsWithdrawnFetch,
    selectComponentHomeStudentsApprovedGenderFetch,
    selectComponentHomeStudentsFailedGenderFetch,
    selectComponentHomeTotalMaleFemaleStudentRegisterGradeFetch,
    selectComponentHomeTotalMaleFemaleStudentsWithdrawnGradeFetch,
    selectComponentHomeStudentsApprovedFailedGenderGradeFetch,
    selectComponentHomeTotalStudentsRepeatedFetch,
    selectComponentHomeWorstScoreStudentFetch,
    selectComponentHomeAllIncidentsFetch,
} from './componentStatusSelectors';

export const getBestScoreStudents = createAsyncThunk(
    'fetch/best_score_students',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'best_score_students',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching BestScoreStudents:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeBestScoreStudentsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getWorstScoreStudent = createAsyncThunk(
    'fetch/worst_score_student',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'worst_score_student',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching BestScoreStudents:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeWorstScoreStudentFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getAssistanceIndex = createAsyncThunk(
    'fetch/assistance_index',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'assistance_index',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching AssistanceIndex:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeAssistanceIndexFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getAverageImcSchool = createAsyncThunk(
    'fetch/average_imc_school',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'kpi',
                    idKey: 'average_imc_school',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching AverageImcSchool:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeAverageImcSchoolFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getBestScoreSubject = createAsyncThunk(
    'fetch/best_score_subject',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'best_score_subject',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching BestScoreSubject:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeBestScoreSubjectFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getFrequentReports = createAsyncThunk(
    'fetch/frequent_reports',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'frequent_reports',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching FrequentReports:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeFrequentReportsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getGradeAverage = createAsyncThunk(
    'fetch/grade_average',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'grade_average',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching gradeAverage:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeGradeAverageFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalStudents = createAsyncThunk(
    'fetch/total_students',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'kpi',
                    idKey: 'total_students',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching gradeAverage:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalStudentsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getLastAttendanceProfessor = createAsyncThunk(
    'fetch/last_attendance_professor',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'last_attendance_professor',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching LastAttendanceProfessor:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeLastAttendanceProfessorFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getSchoolAverage = createAsyncThunk(
    'fetch/school_average',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'kpi',
                    idKey: 'school_average',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching SchoolAverage:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeSchoolAverageFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalAbscence = createAsyncThunk(
    'fetch/total_abscence',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'kpi',
                    idKey: 'total_abscence',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching TotalAbscence:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalAbscenceFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getStudentsDiversity = createAsyncThunk(
    'fetch/students_diversity',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'students_diversity',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching StudentsDiversity:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeStudentsDiversityFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalMaleFemaleStudents = createAsyncThunk(
    'fetch/total_male_female_students',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'total_male_female_students',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching TotalMaleFemaleStudents:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalMaleFemaleStudentsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalMaleFemaleStudentsWithdrawn = createAsyncThunk(
    'fetch/total_male_female_students_withdrawn',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'total_male_female_students_withdrawn',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error(
                'Error fetching TotalMaleFemaleStudentsWithdrawn:',
                error
            );
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalMaleFemaleStudentsWithdrawnFetch(
                    getState()
                );

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalGroups = createAsyncThunk(
    'fetch/total_groups',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'total_groups',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching TotalGroups:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalGroupsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalRegisteredParents = createAsyncThunk(
    'fetch/total_registered_parents',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'kpi',
                    idKey: 'total_registered_parents',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching TotalRegisteredParents:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalRegisteredParentsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalProfessors = createAsyncThunk(
    'fetch/total_professors',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'kpi',
                    idKey: 'total_professors',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching TotalProfessors:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalProfessorsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalReports = createAsyncThunk(
    'fetch/total_reports',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'kpi',
                    idKey: 'total_reports',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching TotalReports:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalReportsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalScholarship = createAsyncThunk(
    'fetch/total_scholarship',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'kpi',
                    idKey: 'total_scholarship',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching TotalScholarship:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalScholarshipFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalStudentsApprovedFailed = createAsyncThunk(
    'fetch/total_students_approved_failed',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'total_students_approved_failed',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching TotalStudentsApprovedFailed:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalStudentsApprovedFailedFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalMaleFemaleStudentsRegister = createAsyncThunk(
    'fetch/total_male_female_students_register',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'total_male_female_students_register',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error(
                'Error fetching TotalMaleFemaleStudentsRegister:',
                error
            );
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalMaleFemaleStudentsRegisterFetch(
                    getState()
                );

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalMaleFemaleStudentRegisterGrade = createAsyncThunk(
    'fetch/total_male_female_student_register_grade',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'table',
                    idKey: 'total_male_female_student_register_grade',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error(
                'Error fetching TotalMaleFemaleStudentRegisterGrade:',
                error
            );
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalMaleFemaleStudentRegisterGradeFetch(
                    getState()
                );

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalMaleFemaleStudentsWithdrawnGrade = createAsyncThunk(
    'fetch/total_male_female_students_withdrawn_grade',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'table',
                    idKey: 'total_male_female_students_withdrawn_grade',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error(
                'Error fetching TotalMaleFemaleStudentsWithdrawnGrade:',
                error
            );
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalMaleFemaleStudentsWithdrawnGradeFetch(
                    getState()
                );

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getStudentsApprovedGender = createAsyncThunk(
    'fetch/students_approved_gender',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'students_approved_gender',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching StudentsApprovedGender:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeStudentsApprovedGenderFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getStudentsFailedGender = createAsyncThunk(
    'fetch/students_failed_gender',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'students_failed_gender',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching StudentsFailedGender:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeStudentsFailedGenderFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getStudentsApprovedFailedGenderGrade = createAsyncThunk(
    'fetch/students_approved_failed_gender_grade',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'table',
                    idKey: 'students_approved_failed_gender_grade',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error(
                'Error fetching StudentsApprovedFailedGenderGrade:',
                error
            );
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeStudentsApprovedFailedGenderGradeFetch(
                    getState()
                );

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalStudentsRepeated = createAsyncThunk(
    'fetch/total_students_repeated',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'table',
                    idKey: 'total_students_repeated',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching TotalStudentsRepeated:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalStudentsRepeatedFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalStudentWithoutGroup = createAsyncThunk(
    'fetch/total_students_without_group',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'top',
                    idKey: 'total_students_without_group',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching TotalStudentWithoutGroup:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalStudentWithoutGroupFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalIncidents = createAsyncThunk(
    'fetch/total_incidents',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'kpi',
                    idKey: 'total_incidents',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching TotalIncidents:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeTotalIncidentsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getAllIncidents = createAsyncThunk(
    'fetch/incidents',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'home',
                    type: 'table',
                    idKey: 'incidents',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching TotalIncidents:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeAllIncidentsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export default {
    getBestScoreStudents,
    getAssistanceIndex,
    getAverageImcSchool,
    getBestScoreSubject,
    getFrequentReports,
    getGradeAverage,
    getLastAttendanceProfessor,
    getSchoolAverage,
    getStudentsApprovedFailedGenderGrade,
    getStudentsApprovedGender,
    getStudentsDiversity,
    getStudentsFailedGender,
    getTotalAbscence,
    getTotalGroups,
    getTotalIncidents,
    getTotalMaleFemaleStudentRegisterGrade,
    getTotalMaleFemaleStudents,
    getTotalMaleFemaleStudentsRegister,
    getTotalMaleFemaleStudentsWithdrawn,
    getTotalMaleFemaleStudentsWithdrawnGrade,
    getTotalProfessors,
    getTotalRegisteredParents,
    getTotalReports,
    getTotalScholarship,
    getTotalStudents,
    getTotalStudentsApprovedFailed,
    getTotalStudentsRepeated,
    getTotalStudentWithoutGroup,
    getWorstScoreStudent,
    getAllIncidents,
};
