import React, { useState } from 'react';
import {
    Box,
    Menu,
    MenuItem,
    TextField,
    Typography,
    IconButton,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ScoreCell = ({
    value,
    description,
    type_regularization,
    add_description,
    column,
    onChange,
    disabled,
    subject_id,
    student_id,
}) => {
    ////////// LOCAL STATE //////////
    const [anchorEl, setAnchorEl] = useState(null);

    /*
     * Manejo del click para el anchor del menu
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /*
     * Manejo del cerrado del menu
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /*
     * Manejo del cambio de estado
     * de las calificación
     */
    const handleCalificationChange = (e) => {
        onChange({
            subjectId: subject_id,
            studentId: student_id,
            column: column,
            isType: false,
            isComment: false,
            value: e.target.value,
        })(e);
    };

    /*
     * Manejo del cambio de estado
     * del comentario de calificación
     */
    const handleCommentChange = (e) => {
        const comment = e.target.value.slice(0, 250);
        onChange({
            subjectId: subject_id,
            studentId: student_id,
            column: column,
            isType: false,
            isComment: true,
            value: comment,
        })(e);
    };

    /*
     * Manejo del cambio de estado
     * del checkbox de recuperación
     */
    const handleTypeChange = (e) => {
        onChange({
            subjectId: subject_id,
            studentId: student_id,
            column: column,
            isType: true,
            isComment: false,
            value: e.target.value,
        })(e);
    };

    /*
     * Función que evita que al presionar
     * las teclas "r" y "c" se pierda focus
     * de los inputs
     */
    const handleKeyDown = (e) => {
        e.stopPropagation();
    };

    /*
     * Función que hace el salto de linea
     * en el comentario mostrado en el menu
     */
    const splitTextIntoLines = (text, wordsPerLine) => {
        const words = text.split(' '); // Dividir el texto en palabras
        const lines = [];

        for (let i = 0; i < words.length; i += wordsPerLine) {
            lines.push(words.slice(i, i + wordsPerLine).join(' '));
        }

        return lines;
    };

    const scoreInvalid = () => {
        return {
            invalid: Number(value) > 10 || isNaN(value) || value === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                    gap: '8px',
                }}
            >
                <TextField
                    value={value}
                    onChange={handleCalificationChange}
                    variant="outlined"
                    size="small"
                    error={scoreInvalid().invalid}
                    disabled={disabled}
                    sx={{
                        flex: '0 1 50px',
                        height: '40px',
                    }}
                    InputProps={{
                        style: { height: '40px' },
                    }}
                />
                <TextField
                    id="reg-type"
                    label="Tipo"
                    variant="outlined"
                    value={type_regularization}
                    onChange={handleTypeChange}
                    disabled={disabled}
                    onKeyDown={handleKeyDown}
                    select
                    sx={{
                        flex: '1',
                        height: '40px',
                    }}
                    InputProps={{
                        style: { height: '40px' },
                    }}
                    InputLabelProps={{
                        style: {
                            top: '-6px',
                        },
                    }}
                >
                    <MenuItem value="" />
                    <MenuItem value={1}>Curso</MenuItem>
                    <MenuItem value={2}>Extraordinario</MenuItem>
                    <MenuItem value={3}>Título 1</MenuItem>
                    <MenuItem value={4}>Título 2</MenuItem>
                </TextField>

                <IconButton
                    onClick={handleClick}
                    sx={{ flexShrink: 0, padding: '4px' }}
                >
                    <MoreVertIcon />
                </IconButton>
            </div>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem disableRipple>
                    <Typography sx={{ pl: 1 }} variant="p">
                        {splitTextIntoLines(description, 3).map(
                            (line, index) => (
                                <div key={index}>{line}</div>
                            )
                        )}
                    </Typography>
                </MenuItem>
                <MenuItem disableRipple>
                    <Box display="flex" flexDirection="column">
                        <TextField
                            id="comment-input"
                            label="Comentario"
                            variant="outlined"
                            value={add_description}
                            multiline
                            onChange={handleCommentChange}
                            disabled={disabled}
                            onKeyDown={handleKeyDown}
                            inputProps={{
                                maxLength: description
                                    ? 250 - description.length
                                    : 250,
                            }}
                        />
                        <Typography variant="caption" color="textSecondary">
                            {description
                                ? `${add_description.length}/${
                                      250 - description.length
                                  }`
                                : `${add_description.length}/250`}
                        </Typography>
                    </Box>
                </MenuItem>
            </Menu>
        </>
    );
};

export default ScoreCell;
