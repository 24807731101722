import { DateTime } from "luxon";
import _ from 'lodash';

/**
 * Recuperacion el promedio final usando las regularizaciones
 * 
 * 1.- Si el promedio del alumno es mayor a la calificacion minima de la escuela 
 *  Esta sera tomada como la calificacion final
 * 2.- Si el promedio del alumno es reprobatorio 
 *  Se intentara recuperar la ultima calificacion aprobatoria en las regularizaciones 
 */
export const getFinalScoreUsingRegularization = (evaluations) => {

    let finalScore = 0;

    let approvedRegularizationCell = evaluations.find((cell) => cell.isApproved);

    if (approvedRegularizationCell) {
        finalScore = approvedRegularizationCell.value;

    } else {
        /**
         * Si no hay ninguna calificacion aprobrada en regularizacion
         * totamos la ultima
         */
        let regularizationCellsWithScore = _.filter(
            evaluations,
            "isExists"
        );

        if (regularizationCellsWithScore.length > 0) {
            let lastScore = regularizationCellsWithScore.sort(
                (a, b) => {
                    let aDate = DateTime.fromSQL(
                        a.calificationItem.created_at
                    );
                    let bDate = DateTime.fromSQL(
                        b.calificationItem.created_at
                    );

                    return aDate < bDate
                        ? 1
                        : aDate > bDate
                            ? -1
                            : 0;
                }
            );

            finalScore = lastScore[0].value;
        }
    }

    return finalScore
}
