import {
    createEntityAdapter,
    createSlice,
    createSelector,
} from '@reduxjs/toolkit';
import _ from 'lodash';

const importsAdapter = createEntityAdapter({
    selectId: (importItem) => importItem.import_id,
    sortComparer: (a, b) => b.import_id - a.import_id,
});

export const ImportsSlice = createSlice({
    name: 'imports',
    initialState: importsAdapter.getInitialState(),
    reducers: {
        addOneImport: importsAdapter.addOne,
        updateOneImport: importsAdapter.updateOne,
        upsertOneImport: importsAdapter.upsertOne,
        setAllImports: importsAdapter.setAll,
        upsertManyImports: importsAdapter.upsertMany,
    },
});

export const {
    addOneImport,
    upsertOneImport,
    updateOneImport,
    setAllImports,
    upsertManyImports,
} = ImportsSlice.actions;

const globalizedSelectors = importsAdapter.getSelectors(
    (state) => state.entities.imports
);

export const selectEntitiesImports = (state) =>
    globalizedSelectors.selectEntities(state);
export const selectAllImports = (state) => globalizedSelectors.selectAll(state);
export const selectImportById = (id) => (state) =>
    globalizedSelectors.selectById(state, id);

export const selectImportsAsArray = () => (state) => selectAllImports(state);

export default ImportsSlice.reducer;

//Selector para solo traer importaciones de calificaciones y asistencias
export const selectScoresImports = createSelector(
    selectAllImports,
    (allImports) => {
        let scoreImports = allImports.filter(
            (element) => element.import_type === 'group-scores'
        );
        return scoreImports;
    }
);
