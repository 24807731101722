import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    addOneReportCatalog,
    upsertOneReportCatalog,
} from '../../../store/slices/entities/reportscatalogs';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import { selectReportHome } from './selectors';

const emptyState = {
    create: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    update: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    updateStatus: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
};

export const operationsSlice = createSlice({
    name: 'operations',
    initialState: emptyState,
    reducers: {},

    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        //////////////// ALMACENAR CATALOG //////////////////////

        builder.addCase(addCatalogReport.rejected, (state, action) => {
            state.create.status = 'rejected';
            state.create.feedback = action.payload.feedback;
        });
        builder.addCase(addCatalogReport.fulfilled, (state) => {
            state.create.status = 'fulfilled';
        });
        builder.addCase(addCatalogReport.pending, (state) => {
            state.create.status = 'pending';
        });

        //////////////// ACTUALIZAR CATALOG //////////////////////

        builder.addCase(updateCatalogReport.rejected, (state, action) => {
            state.update.status = 'rejected';
            state.update.feedback = action.payload.feedback;
        });
        builder.addCase(updateCatalogReport.fulfilled, (state) => {
            state.update.status = 'fulfilled';
        });
        builder.addCase(updateCatalogReport.pending, (state) => {
            state.update.status = 'pending';
        });

        //////////////// ACTUALIZAR STATUS CATALOG //////////////////////

        builder.addCase(updateStatusCatalogReport.rejected, (state, action) => {
            state.updateStatus.status = 'rejected';
            state.updateStatus.feedback = action.payload.feedback;
        });
        builder.addCase(updateStatusCatalogReport.fulfilled, (state) => {
            state.updateStatus.status = 'fulfilled';
        });
        builder.addCase(updateStatusCatalogReport.pending, (state) => {
            state.updateStatus.status = 'pending';
        });
    },
});

//export const {} = operationsSlice.actions;

export default operationsSlice.reducer;

export const selectOperations = createSelector(
    selectReportHome,
    (reports) => reports.operations
);

export const selectCreate = createSelector(
    selectOperations,
    (operations) => operations.create
);

export const selectCreateOperation = createSelector(
    selectCreate,
    (create) => create.status
);

export const selectUpdate = createSelector(
    selectOperations,
    (operations) => operations.update
);

export const selectUpdateOperation = createSelector(
    selectUpdate,
    (update) => update.status
);

export const selectUpdateStatus = createSelector(
    selectOperations,
    (operations) => operations.updateStatus
);

export const selectUpdateStatusOperation = createSelector(
    selectCreate,
    (updateStatus) => updateStatus.status
);

export const selectStatusOperation = (state) =>
    state.newReports.home.operations.create.statusOperation;

/**
 * Agregar un nuevo catalogo
 */
export const addCatalogReport = createAsyncThunk(
    'newReports/create/catalog',
    async ({ schoolId, data }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            let SubjectCatalog = await Connection.setReportCatalogBySchool(
                schoolId,
                data
            ).then((i) => i.data.data);
            thunkAPI.dispatch(addOneReportCatalog(SubjectCatalog));

            return {
                SubjectCatalog,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar un catalogo
 */
export const updateCatalogReport = createAsyncThunk(
    'newReports/update/catalog',
    async ({ idCatalog, data }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            let reportCatalog = await Connection.updateReportCatalog(
                idCatalog,
                data
            ).then((i) => i.data.data);
            thunkAPI.dispatch(upsertOneReportCatalog(reportCatalog));

            return {
                reportCatalog,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar el status de catalogo
 */
export const updateStatusCatalogReport = createAsyncThunk(
    'newReports/update/catalog-status',
    async ({ idCatalog, data }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            let reportCatalog = await Connection.updateReportCatalog(
                idCatalog,
                data
            ).then((i) => i.data.data);
            thunkAPI.dispatch(upsertOneReportCatalog(reportCatalog));

            return {
                reportCatalog,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
