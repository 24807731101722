import { combineReducers } from '@reduxjs/toolkit';
import home from './home';
import group from './group';
import student from './student';

/**
 * selector para recuperar el slice de reportes
 */
export const selectReports = (store) => store.newReports;

export default combineReducers({
    home,
    group,
    student,
});
