import { LoadingButton } from "@mui/lab"
import { Alert, Card, CardActions, CardContent, CardHeader } from "@mui/material"
import Permissions from "../../../components/Permissions"
import { useAuth } from "../../../hooks"
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch } from "react-redux"
import { useState } from "react"
import { appClear } from "../../../store/actions"
import { useHistory } from "react-router-dom"
import useFeedback from "../../../hooks/useFeedback"
import Connection from "./../../../service/Connection"
import Feedback from "../../../service/Feedback"

/**
 * Tarjeta que permite cambiar las 
 * permisos de un usuario
 * 
 * @param {*} param0 
 * @returns 
 */
const UserPermissions = ({ sx, onChange }) => {
    const [feedback] = useState(() => new Feedback());
    const Auth = useAuth()
    let user = Auth.getUser()
    const history = useHistory();
    const dispatch = useDispatch()
    const feedbackApp = useFeedback()

    /////////////////// LOCAL STATE ///////////////////

    const [permissions, setPermissions] = useState({ group_id: '' })
    const [state, setState] = useState('idle')

    ///////////////// SHARED STATE ////////////////////

    /**
     * Lanzado cuando los permisso son actualizados
     * 
     * @param {*} data 
     */
    const onChangePermissions = (data) => {
        let newState = {
            "group_id": data.added,
        }

        if (data.removed.length > 0) {
            newState["remove_group_id"] = data.removed
        }

        setPermissions(newState)
    }

    /**
     * Actualizar permisos de usuarios
     */
    const enviarDatos = () => {
        setState('pending')

        Connection.updateUserById(Auth.getUserID(), permissions)
            .then(response => {
                feedbackApp.showFeedback({
                    title: "Permisos actualizado, El sistema se reiniciara"
                })

                setTimeout(() => {
                    dispatch(appClear())
                    Auth.logout()
                    history.push({ pathname: "/" });
                }, 3500)

            }).catch((err) => {
                let message = feedback.getMessage(err)

                feedbackApp.showFeedback({
                    title: message.title
                })
            }).then(() => {
                setState('idle')
            })
    }

    const formInvalid = () => {
        return permissions.group_id.length == 0
    }

    return (
        <Card sx={sx}>
            <CardHeader
                title="Tipo de usuario"
            />
            <CardContent>
                <Permissions
                    initialPermissions={user.group_id}
                    onChange={onChangePermissions}
                    helperText="No puedes quedarte sin permisos"
                />


            </CardContent>
            <CardActions
                sx={{
                    direction: "row",
                    justifyContent: "space-between"
                }}
            >
                <Alert severity="info">
                    Si eliminas el tipo de usuario <strong>Administrador</strong> este panel <strong>desaparecerá</strong>
                </Alert>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={enviarDatos}
                    loading={state == 'pending'}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                >
                    Actualizar
                </LoadingButton>
            </CardActions>
        </Card>
    )
}


export default UserPermissions