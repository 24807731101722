import { Button, Card, CardActions, CardContent } from "@mui/material";
import { DataGrid, esES, GridActionsCellItem } from "@mui/x-data-grid";
import { selectPartialsBySchoolId } from "../../../store/slices/entities/partials";
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getLevelName } from '../../../libs/utils'
import DeletePartialModal from './DeletePartialModal';
import ParcialModal from '../../../components/Modal/ModalParcial';
import { useState } from "react";
import _ from 'lodash';
import { useAuth } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import useFeedback from "../../../hooks/useFeedback";
import * as constants from "./../../../service/const";
import { PartialsToolBar, RegulationsToolBar } from "./PartialsToolBar";
import { DateTime } from "luxon";
import { selectsettingsUI, updatePartialFilter, updateRegulationFilter } from "../../../store/slices/settingsUI";

/**
 * Muestra una tabla con todos los parcialse de la escuela
 * 
 * @returns 
 */
const PartialsCard = () => {

    const dispatch = useDispatch();
    const feedbackApp = useFeedback()
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    ///////////// SHARES STATE /////////////

    const AllPartials = useSelector(selectPartialsBySchoolId(schoolId))
    const filtersUI = useSelector(selectsettingsUI);

    const [regularized, setRegularized] = useState(null)

    let partials = _.filter(AllPartials, ["is_regularized", false])
    let regulations = _.filter(AllPartials, "is_regularized")

    const filterPartials = filtersUI.partials.filter
    const filterRegulations = filtersUI.regulation.filter

    const filterPartialsByNumberAndDate = (partials, partialNumber, startDate) => {
        return partials.filter(partial => {
            return partial.partial === partialNumber && partial.start_date === startDate;
        });
    };

    if (filterPartials && filterPartials.partialNumber && filterPartials.startDate) {
        partials = filterPartialsByNumberAndDate(partials, filterPartials.partialNumber, filterPartials.startDate);
    }

    const ordeningPartials = partials.sort((a, b) => {
        return new Date(a.start_date) - new Date(b.start_date);
    });

    ///////////// LOCAL STATE ////////////////

    const [openModal, setOpenModal] = useState(false);

    const [openDeletePartialModal, setOpenDeletePartialModal] = useState(false);
    const [partialDelete, setPartialDelete] = useState(null);

    const [partialSelected, setPartialSelected] = useState(null);

    const columns = [
        {
            field: 'partial',
            headerName: 'Parcial',
            flex: 0.5,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'nivel',
            headerName: 'Nivel',
            type: 'singleSelect',
            valueOptions: constants.levelsNames.map(item => item.title),
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return getLevelName(params.row.level)
            }
        },
        {
            field: 'start_date',
            type: 'date',
            headerName: 'Fecha inicio',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => value && new Date(value),
            valueFormatter: ({ value }) => {
                return moment(value).format("DD MMMM YYYY")
            }
        },
        {
            field: 'end_date',
            type: 'date',
            headerName: 'Fecha fin',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => value && new Date(value),
            valueFormatter: ({ value }) => {
                return moment(value).format("DD MMMM YYYY")
            }
        },
        {
            field: 'limit_date',
            type: 'date',
            headerName: 'Fechas límite',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => value && new Date(value),
            valueFormatter: ({ value }) => {
                return moment(value).format("DD MMMM YYYY")
            }
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            sortable: false,
            flex: 0.5,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            type: 'actions',
            getActions: (params) => ([
                <GridActionsCellItem
                    icon={<EditIcon color="primary" />}
                    title="Editar"
                    label="Editar"
                    onClick={() => { showUpdatePartialModal(params.id) }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon color='error' />}
                    title="Eliminar"
                    label="Eliminar"
                    onClick={() => {
                        showDeletePartialModal(params.id)
                    }}
                    showInMenu
                />,
            ])
        },
    ];

    /////////////// ACTIONS ////////////////

    /**
     * Muestra el modal para eliminar un parcial
     * 
     * @param {*} parcialId Id del parcial 
     */
    const showDeletePartialModal = (parcialId) => {
        let itemToDelete = _.find(AllPartials, ["partial_id", parcialId])

        setPartialDelete(itemToDelete)
        setOpenDeletePartialModal(true)
    }


    /**
     * Muestra el modal para actualizar un parcial
     * 
     * @param {*} parcialId Id del parcial
     */
    const showUpdatePartialModal = (parcialId) => {
        let itemToUpdate = _.find(AllPartials, ["partial_id", parcialId])

        setPartialSelected(itemToUpdate);
        setOpenModal(true);
    }

    /**
     * Abrir modal para crear nuevo parcial
     */
    const createNewPartial = (regularized) => {
        setRegularized(regularized);
        setPartialSelected(null)
        setOpenModal(true);
    }

    /**
     * Cerrar modal para eliminar un parcial
     */
    const closeDeletePartialModal = () => {
        setOpenDeletePartialModal(false)
        setPartialDelete(null)
    }

    const handlePartialFilterChange = (event) => {
        let newFilterValues = {};

        event.items.forEach(filter => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value
            };
        });
        dispatch(updatePartialFilter({ filter: newFilterValues }));
    };

    const handleRegulationFilterChange = (event) => {
        let newFilterValues = {};

        event.items.forEach(filter => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value
            };
        });
        dispatch(updateRegulationFilter({ filter: newFilterValues }));
    };

    return (
        <>
            <Card sx={{ mt: 3 }}>
                <CardContent
                    sx={{
                        pl: 0,
                        pr: 0,
                        pb: 0,
                        ' & .MuiDataGrid-root': {
                            border: 'none'
                        }
                    }}
                >
                    <DataGrid
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        rows={ordeningPartials}
                        columns={columns}
                        getRowId={(row) => row.partial_id}
                        pageSize={10}
                        disableSelectionOnClick
                        autoHeight
                        components={{ Toolbar: PartialsToolBar }}
                        disableDensitySelector
                        componentsProps={{
                            panel: {
                                placement: "bottom-end"
                            },
                        }}
                        getCellClassName={(params) => {
                            if (params.field == 'partial') {
                                return DateTime.now() >= DateTime.fromSQL(params.row.start_date) && DateTime.now() <= DateTime.fromSQL(params.row.limit_date) ? 'left-border-green' : ''
                            }

                            return ''
                        }}
                        onFilterModelChange={(event) =>
                            handlePartialFilterChange(event)
                        }
                        initialState={{
                            filter: {
                                filterModel: {
                                    items: filterPartials ? [
                                        {
                                            columnField: filterPartials.columnField || '',
                                            operatorValue: filterPartials.operatorValue || '',
                                            value: filterPartials.value || '',
                                        }
                                    ] : [],
                                },
                            },
                        }}
                    />
                </CardContent>
                <CardActions
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end"
                    }}
                >
                    <Button
                        onClick={() => {
                            createNewPartial(false)
                        }}
                        variant="contained"
                        size="small"
                        startIcon={<AddIcon />}
                    >
                        Agregar
                    </Button>
                </CardActions>
            </Card>

            <Card sx={{ mt: 3 }}>
                <CardContent
                    sx={{
                        pl: 0,
                        pr: 0,
                        pb: 0,
                        ' & .MuiDataGrid-root': {
                            border: 'none'
                        }
                    }}
                >
                    <DataGrid
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        rows={regulations}
                        columns={columns}
                        getRowId={(row) => row.partial_id}
                        pageSize={10}
                        disableSelectionOnClick
                        autoHeight
                        components={{ Toolbar: RegulationsToolBar }}
                        disableDensitySelector
                        componentsProps={{
                            panel: {
                                placement: "bottom-end"
                            },
                        }}
                        getCellClassName={(params) => {
                            if (params.field == 'partial') {
                                return DateTime.now() >= DateTime.fromSQL(params.row.start_date) && DateTime.now() <= DateTime.fromSQL(params.row.limit_date) ? 'left-border-green' : ''
                            }

                            return ''
                        }}
                        onFilterModelChange={(event) =>
                            handleRegulationFilterChange(event)
                        }
                        initialState={{
                            filter: {
                                filterModel: {
                                    items: filterRegulations ? [
                                        {
                                            columnField: filterRegulations.columnField || '',
                                            operatorValue: filterRegulations.operatorValue || '',
                                            value: filterRegulations.value || '',
                                        }
                                    ] : [],
                                },
                            },
                        }}
                    />
                </CardContent>
                <CardActions
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end"
                    }}
                >
                    <Button
                        onClick={() => {

                            createNewPartial(true)
                        }}
                        variant="contained"
                        size="small"
                        startIcon={<AddIcon />}
                    >
                        Agregar
                    </Button>
                </CardActions>

            </Card>

            <DeletePartialModal
                open={openDeletePartialModal}
                onClose={closeDeletePartialModal}
                partial={partialDelete}
            />

            <ParcialModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                partialItem={partialSelected}
                regularized={regularized}
            />
        </>
    )
}


export default PartialsCard