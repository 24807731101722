import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import {
    DataGrid,
    GridToolbar,
    GridToolbarContainer,
    esES,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectMostReportGrade,
} from '../../../store/students/fetchSlice';
import { getMostReportGrade } from '../../../store/students/thunks';
import Error from '../../../../components/Feedback/Error';
import { SkeletonStudentsSmallTables } from '../../../components/Skeleton';
import { selectComponentStudentsMostReportGradeStatusServer } from '../../../store/students/componentsStatusSelectors';
import { Tag } from 'antd';

const MostReportGrade = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentStudentsMostReportGradeStatusServer
    );

    const mostReportGrade = useFetchResource(
        () =>
            getMostReportGrade({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectMostReportGrade,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getMostReportGrade({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectMostReportGrade,
            [cycleSelected, schoolId]
        );
    };

    const processData = (data) => {
        return Object.entries(data).map(([key, value], index) => ({
            id: index + 1,
            grado: key,
            alumno: value.data[0],
            reportes: value.data[1],
            grupo: value.data[2],
        }));
    };

    const rows = processData(mostReportGrade?.value || {});

    const columns = [
        {
            field: 'grado',
            headerName: 'Grado',
            flex: 0.3,
            renderCell: (params) => (
                <Box
                    sx={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        lineHeight: '1.2',
                    }}
                >
                    {params.row.grado}
                </Box>
            ),
            headerClassName: 'bold-header',
        },
        {
            field: 'alumno',
            headerName: 'Alumnos',
            flex: 1,
            renderCell: (params) => (
                <Box
                    sx={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        lineHeight: '1.2',
                    }}
                >
                    {params.row.alumno}
                </Box>
            ),
        },
        {
            field: 'reportes',
            headerName: 'Reportes',
            flex: 0.5,
            renderCell: (params) => (
                <Tag
                    color="error"
                    style={{
                        width: '100px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                    }}
                >
                    {params.row.reportes}
                </Tag>
            ),
        },
        {
            field: 'grupo',
            headerName: 'Grupo',
            flex: 1,
            renderCell: (params) => (
                <Box
                    sx={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        lineHeight: '1.2',
                    }}
                >
                    {params.row.grupo}
                </Box>
            ),
        },
    ];

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbar {...props} />
            </GridToolbarContainer>
        );
    };

    return (
        <Box>
            {fetchingStatus === 'pending' && (
                <SkeletonStudentsSmallTables items={9} />
            )}
            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        borderRadius: '10px',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        padding: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: '720px',
                        margin: 'auto',
                    }}
                >
                    <CardContent sx={{ width: '100%' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                marginBottom: '10px',
                            }}
                        >
                            {mostReportGrade?.title ||
                                'Alumno con más reportes por grado'}
                        </Typography>
                        <Box sx={{ height: 370, width: '100%' }}>
                            <DataGrid
                                localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                        .localeText
                                }
                                rows={rows}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[]}
                                disableSelectionOnClick
                                disableDensitySelector
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                }}
                                sx={{
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                    },
                                    '& .MuiDataGrid-cell': {
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                    },
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default MostReportGrade;
