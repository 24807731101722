import {
    createEntityAdapter,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import { selectAllSubjects, selectSubjectsByGroupId } from '../subjects';
import { selectEntitiesSubjectcatalogs } from '../subjectcatalogs';

const modulesAdapter = createEntityAdapter({
    selectId: (module) => module.group_id,
    sortComparer: (a, b) => a.group_id - b.group_id,
});

export const modulesSlice = createSlice({
    name: 'modules',
    initialState: modulesAdapter.getInitialState(),
    reducers: {
        /**
         * Agregar un modulo
         */
        addOneModule: modulesAdapter.addOne,
        /**
         * Agregar varios modulos
         */
        addManyModules: modulesAdapter.addMany,
        /**
         * Remover un modulo
         */
        removeOneModule: modulesAdapter.removeOne,
        upsertManyModules: modulesAdapter.upsertMany,
        upsertOneModule: modulesAdapter.upsertOne,
        updateModule: modulesAdapter.updateOne,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return modulesAdapter.getInitialState();
        });
    },
});

export const {
    addOneModule,
    addManyModules,
    removeOneModule,
    upsertManyModules,
    upsertOneModule,
    updateModule,
} = modulesSlice.actions;

//////////// SELECTORES ////////////////
const globalizedSelectors = modulesAdapter.getSelectors(
    (state) => state.entities.modules
);

/**
 * Selector para recuperar todos los modulos
 *
 * @param {*} state
 * @returns
 */
export const selectAllModulesEntities = (state) =>
    globalizedSelectors.selectEntities(state);

/**
 * Selector para recuperar modulos como array
 *
 * @param {*} schoolId
 * @returns
 */

export const selectAllModules = (state) => globalizedSelectors.selectAll(state);

export const selectModuleById = (id) => (state) =>
    globalizedSelectors.selectById(state, id);

export const selectModulesByGroup = (groupId) =>
    createSelector(selectAllModulesEntities, (modules) => {
        return Object.values(modules).filter(
            (module) => module.group_annexed_id == groupId
        );
    });

export const selectSubjectFromModulesByMainGroup = (groupsId) =>
    createSelector(
        selectModulesByGroup(groupsId),
        selectAllSubjects,
        (modules, allSubjects) => {
            return modules
                .map((module) => {
                    return allSubjects.filter(
                        (subject) => subject.group_id === module.group_id
                    );
                })
                .flat();
        }
    );

export const selectMainAndModuleSubjectsByMainGroup = (groupId) =>
    createSelector(
        selectSubjectsByGroupId(groupId),
        selectSubjectFromModulesByMainGroup(groupId),
        (subjects, subjectsFromModule) => {
            return subjects?.concat(subjectsFromModule);
        }
    );

export const selectMainAndModuleSubjectsDetailsByMainGroup = (groupId) =>
    createSelector(
        selectMainAndModuleSubjectsByMainGroup(groupId),
        selectEntitiesSubjectcatalogs,
        (subjects, subjectCatalogEntities) => {
            return subjects?.map((subject) => {
                return {
                    ...subject,
                    catalog: subjectCatalogEntities[subject.catalog_subject_id],
                };
            });
        }
    );

export default modulesSlice.reducer;
