import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useFetchResource = (fetchAction, selector, dependencies) => {
    const dispatch = useDispatch();
    const data = useSelector(selector);

    useEffect(() => {
        dispatch(fetchAction());
    }, dependencies);

    return data;
};

export default useFetchResource;