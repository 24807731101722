import React, { useState } from 'react';
import TabsComponent from '../../components/tabs';
import { Box, Backdrop, CircularProgress, Skeleton } from '@mui/material';
import DiscountsTable from './Components/DiscountsTable';

const Discounts = () => {
    const [currentTab, setCurrentTab] = useState('descuentos');
    const [loading, setLoading] = useState(false);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <>
            <TabsComponent
                currentTab={currentTab}
                onTabChange={handleTabChange}
                setLoading={setLoading}
            />
            {loading ? (
                <Box
                    sx={{
                        paddingLeft: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                        paddingRight: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                        paddingBottom: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                    }}>
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={500}
                        sx={{ mb: 2 }}
                    />
                </Box>
            ) : (
                <Box
                    sx={{
                        paddingLeft: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                        paddingRight: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                        paddingBottom: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                    }}>
                    <DiscountsTable />
                </Box>
            )}
        </>
    );
};

export default Discounts;
