import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../hooks';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    Stack,
    TextField,
    Typography,
    Chip,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Services from '../../../service/Connection';
import { Transfer } from 'antd';
import { getLevelName } from '../../../libs/utils';
import { selectThemeBySchool } from '../../../store/slices/entities/settings';
import { selectSubjectsCatalogsByLevel } from '../../../store/slices/groupsUI/itemSlice';
import SkeletonPage from './SkeletonPage';
import ModuleSelectorModal from './ModuleSelectorModal';
import * as _ from 'lodash';

export const ModuleCreator = ({
    group,
    modules,
    onAddModule,
    onViewChange,
    onRemoveModule,
    onSubjectSelected,
    onUpdateModule,
    subjects,
    noAvailableSubjects,
}) => {
    /////////// HOOKS ////////////
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    ///////////////// SHARED STATE /////////////////////

    const subjectsByLevel = useSelector(
        selectSubjectsCatalogsByLevel(group.level)
    );

    const { colorTitleBar, fontColor } = useSelector(
        selectThemeBySchool(schoolId)
    );

    /////////// LOCAL STATE ///////////////
    const [moduleView, setModuleView] = useState('resume');
    const [moduleName, setModuleName] = useState('');
    const [modulesByLevelGrade, setModulesByLevelGrade] = useState([]);
    const [loading, setLoading] = useState(false);
    const [subjectModuleTargetKeys, setSubjectModuleTargetKeys] = useState([]);
    const [subjecModuleSelectedKeys, setSubjectModuleSelectedKeys] = useState(
        []
    );
    const [currentModule, setCurrentModule] = useState(null);
    const test = noAvailableSubjects.filter(
        (sub) => !subjectModuleTargetKeys.includes(sub)
    );

    const subjectsAvailable = subjects
        .filter((item) => !test.includes(item.catalog_subject_id))
        .map((i) => {
            return {
                ...i,
                key: i.catalog_subject_id,
            };
        });
    ////////////////// HANDLERS /////////////////////
    /**
     * Cambia vista a agregar modulo nuevo
     */

    useEffect(() => {
        if (moduleView === 'existent') {
            setLoading(true);

            fetchInitialData()
                .then((modules) => {
                    setModulesByLevelGrade(modules);
                })
                .then((res) => {
                    setLoading(false);
                });
        }
    }, [moduleView]);

    const fetchInitialData = async () => {
        let allModulesByLevelGrade =
            await Services.getAllSchoolModulesByLevelGrade(
                schoolId,
                group?.level,
                group?.grade
            ).then(async (i) => i.data.data);

        for (const module of allModulesByLevelGrade) {
            const subjects = await Services.getSubjectsByModule(
                module.group_id
            ).then((i) => i.data.data);

            let subjectsIds = _.map(subjects, 'catalog_subject_id');

            module.subjects = subjectsIds;
        }

        const filteredModules = _.chain(allModulesByLevelGrade)
            .filter((module) => {
                const unavailableModuleSubjects = modules.map(
                    (currentModule) => {
                        return currentModule.subjects;
                    }
                );

                let currentSubjects = [
                    ...noAvailableSubjects,
                    ...unavailableModuleSubjects,
                ];

                return !currentSubjects.some((subject) => {
                    return module.subjects.includes(subject);
                });
            })
            .uniqWith((a, b) =>
                a.subjects.some((subjetId) => b.subjects.includes(subjetId))
            )
            .value();

        return filteredModules;
    };

    const viewNewModule = () => {
        onViewChange('create');
        setModuleView('create');
    };

    /**
     * Cambia vista a agregar modulo existente
     */

    const handleExistentModule = () => {
        onViewChange('existent');
        setModuleView('existent');
    };

    /**
     * Cancelar operación de modulos y volver a vista incial
     */
    const handleCancelModule = () => {
        onViewChange('resume');
        setModuleView('resume');
        setModuleName('');
        setSubjectModuleSelectedKeys([]);
        setSubjectModuleTargetKeys([]);
    };

    /**
     * Editar modulo seleccionado
     */
    const handleEditModule = (i) => {
        let currentModule = modules.find((module, index) => index === i);
        setCurrentModule(i);
        setModuleName(currentModule.name);
        setSubjectModuleTargetKeys(currentModule.subjects);
        onViewChange('create');
        setModuleView('create');
    };

    /**
     * Añade un modulo nuevo a groupItem
     */
    const handleNewModule = () => {
        if (currentModule === null) {
            onAddModule({
                name: moduleName,
                subjects: subjectModuleTargetKeys,
            });
        } else {
            onUpdateModule({
                name: moduleName,
                subjects: subjectModuleTargetKeys,
                index: currentModule,
            });
        }
        onSubjectSelected(subjectModuleTargetKeys);
        setCurrentModule(null);
        onViewChange('resume');
        setModuleView('resume');
        setModuleName('');
        setSubjectModuleSelectedKeys([]);
        setSubjectModuleTargetKeys([]);
    };

    const handleSelectedModule = (module) => {
        setModuleName(module.annexed_name);
        onModuleChange(module.subjects);
        setModuleView('create');
    };

    /**
     * Remover un modulo a agregar
     */

    const handleRemoveModule = (i) => {
        const updatedModules = [...modules];
        updatedModules.splice(i, 1);
        onRemoveModule({
            updatedModules,
            moduleDeleted: modules.find((module, index) => index === i),
        });
    };

    ///////////////////////// TRANFER /////////////////////

    /**
     * Filtro para la busqueda
     */

    const removeAccents = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    const filterOption = (inputValue, option) => {
        const search = removeAccents(
            inputValue.toLowerCase().replace(/\s/g, '')
        );

        const titleNoSpace = removeAccents(
            `${option.folio} ${option.title} ${getLevelName(option.level)}`
                .toLowerCase()
                .replace(/\s/g, '')
        );
        const titleMatch = titleNoSpace.includes(search);

        return titleMatch;
    };

    const onSelectModuleChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSubjectModuleSelectedKeys([
            ...sourceSelectedKeys,
            ...targetSelectedKeys,
        ]);
    };

    const onModuleChange = (nextTargetKeys) => {
        setSubjectModuleTargetKeys(nextTargetKeys);
    };

    const moduleNameValid = () => {
        return {
            invalid: moduleName === '',
            message: 'Campo requerido, Máximo 50 caracteres',
        };
    };

    return (
        <>
            <Card variant="outlined" sx={{ mt: 1, ml: -3 }}>
                {loading && (
                    <Box sx={{ m: 2 }}>
                        <SkeletonPage />
                    </Box>
                )}
                {moduleView === 'resume' && loading === false ? (
                    <Box sx={{ mt: 1, ml: 4 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'end',
                                '& > *': {
                                    ml: 0.5,
                                    mb: 2,
                                },
                            }}>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleExistentModule}>
                                Módulo Existente
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={viewNewModule}>
                                Módulo Nuevo
                            </Button>
                        </Box>
                        {modules.length < 1 ? (
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                <Typography variant="h5">
                                    No hay módulos añadidos
                                </Typography>
                            </CardContent>
                        ) : (
                            <Grid container spacing={{ xs: 1, md: 4 }}>
                                {modules.map((module, index) => {
                                    return (
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            key={index}
                                            sx={{ overflow: 'auto' }}>
                                            <Card
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    maxWidth: 'auto',
                                                    minHeight: 'auto',
                                                    borderRadius: 3,
                                                    m: 1,
                                                    overflow: 'auto',
                                                    boxShadow: 10,
                                                }}>
                                                <CardHeader
                                                    title={module.name}
                                                    sx={{
                                                        backgroundColor:
                                                            colorTitleBar.value,
                                                        color: fontColor.value,
                                                    }}
                                                    avatar={
                                                        <Avatar
                                                            sx={{
                                                                bgcolor:
                                                                    fontColor.value,
                                                                color: colorTitleBar.value,
                                                            }}
                                                            aria-label="recipe">
                                                            <DashboardIcon />
                                                        </Avatar>
                                                    }
                                                />
                                                <CardContent>
                                                    <Stack
                                                        direction="row"
                                                        spacing={1}
                                                        mb={1}
                                                        useFlexGap
                                                        flexWrap="wrap">
                                                        {module.subjects.map(
                                                            (
                                                                subjectId,
                                                                index
                                                            ) => {
                                                                const subject =
                                                                    subjects.find(
                                                                        (
                                                                            subject
                                                                        ) =>
                                                                            subject.catalog_subject_id ===
                                                                            subjectId
                                                                    );
                                                                const subjectName =
                                                                    subject
                                                                        ? subject.title
                                                                        : 'Asignatura no encontrada';
                                                                return (
                                                                    <Chip
                                                                        key={
                                                                            index
                                                                        }
                                                                        sx={{
                                                                            height: 'auto',
                                                                            '& .MuiChip-label':
                                                                                {
                                                                                    display:
                                                                                        'block',
                                                                                    whiteSpace:
                                                                                        'normal',
                                                                                },
                                                                        }}
                                                                        label={
                                                                            <strong>
                                                                                {
                                                                                    subjectName
                                                                                }
                                                                            </strong>
                                                                        }
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    </Stack>
                                                </CardContent>
                                                <CardActions
                                                    disableSpacing
                                                    sx={{
                                                        justifyContent:
                                                            'flex-end',
                                                        alignItems:
                                                            'flex-start',
                                                    }}>
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            borderRadius: 2,
                                                            m: 0.5,
                                                        }}
                                                        size="small"
                                                        onClick={() =>
                                                            handleRemoveModule(
                                                                index
                                                            )
                                                        }>
                                                        Eliminar
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            borderRadius: 2,
                                                            m: 0.5,
                                                        }}
                                                        size="small"
                                                        onClick={() =>
                                                            handleEditModule(
                                                                index
                                                            )
                                                        }>
                                                        Editar
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        )}
                    </Box>
                ) : moduleView === 'create' && loading === false ? (
                    <CardContent>
                        <Stack mt={-3} ml={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={7} mb={2} mt={3}>
                                    <TextField
                                        id="moduleName"
                                        name="moduleName"
                                        label="Nombre de Módulo"
                                        type="text"
                                        size="small"
                                        autoComplete="current-password"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 50,
                                            autoComplete: 'turnoff',
                                        }}
                                        value={moduleName}
                                        onChange={(e) =>
                                            setModuleName(e.target.value)
                                        }
                                        error={moduleNameValid().invalid}
                                        helperText={
                                            moduleNameValid().invalid
                                                ? moduleNameValid().message
                                                : false
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5} mb={2} mt={3}>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        spacing={1}>
                                        <Button
                                            variant="contained"
                                            onClick={handleCancelModule}>
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={handleNewModule}
                                            disabled={
                                                moduleName === '' ||
                                                subjectModuleTargetKeys.length <=
                                                    1
                                            }>
                                            Agregar
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Divider sx={{ mt: 0.5 }}>
                                <Typography
                                    variant="p"
                                    component="div"
                                    style={{
                                        textAlign: 'center',
                                    }}>
                                    Asignar materias a módulo
                                </Typography>
                            </Divider>
                            <Container>
                                <Transfer
                                    style={{
                                        marginTop: '20px',
                                    }}
                                    showSearch
                                    filterOption={filterOption}
                                    listStyle={{
                                        width: 265,
                                        height: 350,
                                    }}
                                    dataSource={subjectsAvailable}
                                    locale={{
                                        notFoundContent: [
                                            'Sin materias',
                                            'Sin materias',
                                        ],
                                        searchPlaceholder: 'Buscar aquí',
                                        itemUnit: 'Materias',
                                        itemsUnit: 'Materias',
                                    }}
                                    titles={['Disponibles', 'Asignadas']}
                                    targetKeys={subjectModuleTargetKeys}
                                    selectedKeys={subjecModuleSelectedKeys}
                                    onChange={onModuleChange}
                                    onSelectChange={onSelectModuleChange}
                                    render={(item) => {
                                        return (
                                            item.folio +
                                            ' - ' +
                                            item.title +
                                            ' - ' +
                                            getLevelName(item.level)
                                        );
                                    }}
                                />
                            </Container>
                        </Stack>
                    </CardContent>
                ) : moduleView === 'existent' && loading === false ? (
                    <CardContent sx={{ ml: 4 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignContent: 'end',
                                justifyContent: 'end',
                            }}>
                            <Button
                                sx={{
                                    alignContent: 'end',
                                    justifyContent: 'end',
                                    m: 1,
                                }}
                                variant="contained"
                                onClick={handleCancelModule}>
                                Cancelar
                            </Button>
                        </Box>
                        <ModuleSelectorModal
                            modulesByLevelGrade={modulesByLevelGrade}
                            subjects={subjectsByLevel}
                            onSelectedModule={handleSelectedModule}
                        />
                    </CardContent>
                ) : null}
            </Card>
        </>
    );
};
