////////////////////////////////////////// FETCH
export const selectComponentStudentsBestAverageLevelFetch = (store) =>
    store.moduleDirector.students.components.best_average_level.fetch;
export const selectComponentStudentsWorstAverageLevelFetch = (store) =>
    store.moduleDirector.students.components.worst_average_level.fetch;
export const selectComponentStudentsBestAverageGradeFetch = (store) =>
    store.moduleDirector.students.components.best_average_grade.fetch;
export const selectComponentStudentsWorstAverageGradeFetch = (store) =>
    store.moduleDirector.students.components.worst_average_grade.fetch;
export const selectComponentStudentsMoreInassistLevelFetch = (store) =>
    store.moduleDirector.students.components.more_inassist_level.fetch;
export const selectComponentStudentsMoreInassistGradeFetch = (store) =>
    store.moduleDirector.students.components.more_inassists_grade.fetch;
export const selectComponentStudentsMostReportLevelFetch = (store) =>
    store.moduleDirector.students.components.most_report_level.fetch;
export const selectComponentStudentsMostReportGradeFetch = (store) =>
    store.moduleDirector.students.components.most_report_grade.fetch;
export const selectComponentStudentsAllStudentsFetch = (store) =>
    store.moduleDirector.students.components.students.fetch;
export const selectComponentStudentSchoolPerformanceSummaryFetch = (store) =>
    store.moduleDirector.students.components.students_sumari.fetch;
///////////////////////////////////////// STATUS SERVER
export const selectComponentStudentsBestAverageLevelStatusServer = (store) =>
    store.moduleDirector.students.components.best_average_level.fetch
        .statusServer;
export const selectComponentStudentsWorstAverageLevelStatusServer = (store) =>
    store.moduleDirector.students.components.worst_average_level.fetch
        .statusServer;
export const selectComponentStudentsBestAverageGradeStatusServer = (store) =>
    store.moduleDirector.students.components.best_average_grade.fetch
        .statusServer;
export const selectComponentStudentsWorstAverageGradeStatusServer = (store) =>
    store.moduleDirector.students.components.worst_average_grade.fetch
        .statusServer;
export const selectComponentStudentsMoreInassistLevelStatusServer = (store) =>
    store.moduleDirector.students.components.more_inassist_level.fetch
        .statusServer;
export const selectComponentStudentsMoreInassistsGradeStatusServer = (store) =>
    store.moduleDirector.students.components.more_inassists_grade.fetch
        .statusServer;
export const selectComponentStudentsMostReportLevelStatusServer = (store) =>
    store.moduleDirector.students.components.most_report_level.fetch
        .statusServer;
export const selectComponentStudentsMostReportGradeStatusServer = (store) =>
    store.moduleDirector.students.components.most_report_grade.fetch
        .statusServer;
export const selectComponentStudentsAllStudentsStatusServer = (store) =>
    store.moduleDirector.students.components.students.fetch.statusServer;
export const selectComponentStudentSchoolPerformanceSummaryStatusServer = (
    store
) =>
    store.moduleDirector.students.components.students_sumari.fetch.statusServer;
