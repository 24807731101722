import React, { useState } from 'react';
import {
    Backdrop,
    Box,
    CardContent,
    CircularProgress,
    Skeleton,
    Tab,
} from '@mui/material';
import ConceptTable from './Components/ConceptTable';
import TabsComponent from '../../components/tabs';

const Concepts = () => {
    const [currentTab, setCurrentTab] = useState('conceptos');
    const [loading, setLoading] = useState(false);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };
    return (
        <>
            <TabsComponent
                currentTab={currentTab}
                onTabChange={handleTabChange}
                setLoading={setLoading}
            />

            {loading ? (
                <Box
                    sx={{
                        paddingLeft: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                        paddingRight: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                        paddingBottom: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                    }}>
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={500}
                        sx={{ mb: 2 }}
                    />
                </Box>
            ) : (
                <Box
                    sx={{
                        paddingLeft: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                        paddingRight: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                        paddingBottom: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                    }}>
                    <ConceptTable />
                </Box>
            )}
        </>
    );
};

export default Concepts;
