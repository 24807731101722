import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

/**
 * Adaptador para los parentescos
 */
const calificationsAdapter = createEntityAdapter({
    selectId: (relation) => relation.calification_id,
    sortComparer: (a, b) => a.calification_id - b.calification_id
})

/**
 * Slice para la entidades de parentescos
 */
export const relationshipsSlice = createSlice({
    name: 'relationships',
    initialState: calificationsAdapter.getInitialState(),
    reducers: {
        addOneCalification: calificationsAdapter.addOne,
        addManyCalifications: calificationsAdapter.addMany,
        upsertManyCalifications: calificationsAdapter.upsertMany,
        upsertOneCalification: calificationsAdapter.upsertOne,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return calificationsAdapter.getInitialState()
        })
    }
})

/**
 * Acciones generadas por la librerias
 */
export const {
    addOneCalification,
    addManyCalifications,
    upsertManyCalifications,
    upsertOneCalification
} = relationshipsSlice.actions

/**
 * Exportaciones por defecto del reducer generado por la libreria
 */
export default relationshipsSlice.reducer

//////////////////// SELECTORES //////////////////

const globalizedSelectors = calificationsAdapter.getSelectors((state) => state.entities.califications)

/**
 * Selecciona todas las calificaciones
 */
export const selectAllCalifications = (store) => globalizedSelectors.selectAll(store)

/**
 * Selecciona una calificacion por ID
 */
export const selectCalificationById = (id) => (store) => globalizedSelectors.selectById(store, id)


/**
 * Selecciona una asistencia por ID
 */
export const selectCalificationsBy = (params) => (store) => _.filter(selectAllCalifications(store), params)


//////////////////// TRUNKS /////////////////////
