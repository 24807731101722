import React, { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Select,
    MenuItem,
    FormControl,
    TextField,
    InputAdornment,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Card,
    CardActions,
    Tooltip,
    CardContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCatConcepts } from '../../../store/cat_concepts/fetchSlice';
import { useAuth, useFeedback } from '../../../../hooks';
import { useTheme } from '@emotion/react';
import { selectCatConcepts } from '../../../store/cat_concepts/selectors';
import {
    AddCircleOutline,
    AddCircleOutlineOutlined,
    AddIcCallOutlined,
} from '@mui/icons-material';
import { BookmarkAddOutlined, BookmarkAddRounded } from '@mui/icons-material';
import { DataGrid, esES } from '@mui/x-data-grid';
import CreateCatalog from './CreateCatalog';
import { DeleteCatConcepts } from '../../../store/cat_concepts/operations';
import { selectCatDiscounts } from '../../../store/cat_discounts/selectors';
import { fetchCatDiscounts } from '../../../store/cat_discounts/fetchSlice';
import { DeleteCatDiscounts } from '../../../store/cat_discounts/operations';
import Feedback from '../../../../service/Feedback';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PercentIcon from '@mui/icons-material/Percent';
import useThemeColors from '../../../../theme/themes';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import SearchIcon from '@mui/icons-material/Search'; // Asegúrate de importar SearchIcon
import ExportButton from '../../../components/exportButton';
import CustomPagination from '../../../components/CustomPagination';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import DeleteSweepTwoToneIcon from '@mui/icons-material/DeleteSweepTwoTone';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';

const CatalogModal = ({ open, onclose }) => {
    ///////////////// LOCAL STATE /////////////

    const [openModal, setOpenModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    ///////////// SHERD STATE /////////////
    const feedbackApp = useFeedback();
    const colors = useThemeColors();

    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();

    const catDiscounts = useSelector(selectCatDiscounts);
    ///////////// USE EFFECT /////////////

    useEffect(() => {
        dispatch(fetchCatDiscounts(schoolId));
    }, [dispatch, schoolId]);
    ///////////// HANDLERS /////////////

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const handleDelete = async (discount_catalog_id) => {
        await dispatch(DeleteCatDiscounts(discount_catalog_id))
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Descuento Eliminado con exito',
                });
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title: error.feedback.title,
                });
            });
    };

    ///////////// FUNCTIONS /////////////

    ///////////// COLUMNS /////////////
    const columns = [
        {
            field: 'discount_catalog_id',
            headerName: 'Folio',
            width: 100,
            flex: 1,
            renderCell: (params) => (
                <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                    #{params.value}
                </Typography>
            ),
        },
        {
            field: 'title',
            headerName: 'Título',
            flex: 2,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'value',
            headerName: 'Importe',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params) => {
                const isMonetary = params.row.value_modality === 1;
                const formattedValue = new Intl.NumberFormat('es-MX', {
                    style: isMonetary ? 'decimal' : 'decimal',
                    currency: isMonetary ? 'MXN' : undefined,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }).format(params.row.value);

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            gap: 0.5,
                            width: '100%',
                        }}>
                        {isMonetary ? (
                            <>
                                <AttachMoneyOutlinedIcon
                                    sx={{
                                        color: colors.orange[500],
                                        fontSize: '1.2rem',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'right',
                                        width: 'auto',
                                        fontSize: '1rem',
                                    }}>
                                    {formattedValue}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <PercentOutlinedIcon
                                    sx={{
                                        color: colors.teal[500],
                                        fontSize: '1.2rem',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'right',
                                        fontSize: '1rem',
                                        width: 'auto',
                                    }}>
                                    {formattedValue}
                                </Typography>
                            </>
                        )}
                    </Box>
                );
            },
        },
        {
            field: 'value_modality',
            headerName: 'Modalidad',
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.row.value_modality === 1 ? (
                    <Tooltip title="Monetario">
                        <Box
                            sx={{
                                background: colors.orange[100],
                                borderRadius: '100%',
                                height: 30,
                                width: 30,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <AttachMoneyOutlinedIcon
                                size="small"
                                sx={{
                                    color: colors.orange[500],
                                }}
                            />
                        </Box>
                    </Tooltip>
                ) : (
                    <Tooltip title="Porcentual">
                        <Box
                            sx={{
                                background: colors.teal[100],
                                borderRadius: '100%',
                                height: 30,
                                width: 30,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <PercentOutlinedIcon
                                size="small"
                                sx={{
                                    color: colors.teal[500],
                                }}
                            />
                        </Box>
                    </Tooltip>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Acciones',
            flex: 0.5,
            renderCell: (params) => (
                <Tooltip title={'Eliminar Catálogo'} arrow>
                    <IconButton
                        onClick={() =>
                            handleDelete(params.row.discount_catalog_id)
                        }
                        sx={{ color: colors.red[500] }}>
                        <DeleteSweepTwoToneIcon />
                    </IconButton>
                </Tooltip>
            ),
        },
    ];

    const filteredConcepts = catDiscounts.filter((surcharge) =>
        Object.values(surcharge)
            .join(' ')
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
    );

    const rows = filteredConcepts
        .map((discount) => ({
            id: discount.discount_catalog_id,
            ...discount,
        }))
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    return (
        <>
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onclose();
                    }
                }}
                maxWidth="lg"
                fullWidth
                disableEscapeKeyDown
                sx={{
                    '& .MuiDialog-paper': {
                        padding: 5,
                        borderRadius: 2,
                    },
                }}>
                <DialogTitle
                    sx={{
                        textAlign: 'center',
                        position: 'relative',
                    }}>
                    <Typography variant="h4" component="div">
                        Catálogo de los Descuentos
                    </Typography>
                    <Typography variant="body2">
                        Aquí podras observar el Catálogo de los Descuentos
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onclose}
                        sx={{
                            position: 'absolute',
                            right: 6,
                            top: 6,
                            color: theme.palette.grey[500],
                        }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Card
                        sx={{
                            width: '119%',
                            borderRadius: 2,
                            boxShadow: 0,
                            backgroundColor: theme.palette.background.default,
                            color: theme.palette.neutral.main,
                            maxWidth: '100%',
                            mb: 2,
                        }}>
                        {' '}
                        {/* <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="flex-end"
                        alignItems="center"
                        paddingTop={3}
                        paddingBottom={3}>
                        <CardActions></CardActions>
                    </Box> */}
                        <CardContent>
                            <Box
                                display="flex"
                                flexWrap="wrap"
                                justifyContent="space-between"
                                alignItems="center"
                                mb={2}>
                                <Tooltip title="Crea un nuevo catalogo de descuentos">
                                    <Button
                                        onClick={handleOpen}
                                        variant="contained"
                                        startIcon={<AddCircleOutlineOutlined />}
                                        sx={{
                                            flexBasis: { xs: '90%', sm: '30%' },
                                            minWidth: { xs: '90%', sm: '30%' },
                                            mb: { xs: 2, sm: 0 },
                                        }}>
                                        Añadir Catálogo
                                    </Button>
                                </Tooltip>
                                <TextField
                                    variant="outlined"
                                    placeholder="Buscar un catálogo de descuento"
                                    size="small"
                                    value={searchTerm}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                    sx={{
                                        minWidth: { xs: '90%', sm: '30%' },
                                        backgroundColor:
                                            theme.palette.background.paper,
                                        mr: 2,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>

                            <Box
                                display="grid"
                                gridTemplateColumns={{ xs: '1fr', md: 'auto' }}
                                gap={2}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    autoHeight
                                    pageSize={10}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    disableColumnReorder
                                    localeText={
                                        esES.components.MuiDataGrid.defaultProps
                                            .localeText
                                    }
                                    components={{
                                        Toolbar: () => (
                                            <ExportButton fileName="Catalogo de descuentos" />
                                        ),
                                        Pagination: CustomPagination,
                                    }}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
            <CreateCatalog open={openModal} onclose={handleClose} />
        </>
    );
};

export default CatalogModal;
