import React, { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Divider,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    LinearProgress,
} from '@mui/material';
import { getTurnLevel } from '../../../libs/utils';
import { useSelector } from 'react-redux';
import { Transfer } from 'antd';
import * as constants from '../../../service/const';
import { selectStatusOperation } from '../../../store/slices/studentsUI/serverSlice';
import _ from 'lodash';

/**
 * Componente para seleccionar grupo
 *
 * @returns
 */
const GroupsSelector = ({
    level,
    groups,
    targetKeys,
    selectedKeys,
    selectedPrimaryGroups,
    onChangeGettingSpecialGroupsNames,
    onChangeGettingNameGroup,
    onChangeLevel,
    onChange,
}) => {
    /////////// LOCAL STATE ////////////////////

    let specialgroups = _.filter(groups, { group_type: 2 });
    let primarygroups = _.filter(groups, { group_type: 1 });

    //////////// SHARED STATE /////////////

    const statusOperation = useSelector(selectStatusOperation);

    const specialGroupNames = specialgroups.map(
        (group) => `${group.grade}° ${group.group} ${getTurnLevel(group.turn)}`
    );

    //////////// FUNCTIONS //////////////////

    /**
     * Funcion para intercambiar elementos de la lista
     *
     * @param {*} nextTargetKeys
     * @param {*} direction
     * @param {*} moveKeys
     */
    const onChangeTargetKeys = (nextTargetKeys, direction, moveKeys) => {
        onChange({
            targetkeys: nextTargetKeys,
        });
    };

    /**
     * Funcion para marcar grupos como seleccionados en la lista
     *
     * @param {*} sourceSelectedKeys
     * @param {*} targetSelectedKeys
     */
    const onSelectChange = (sourceselectedSpecials, targetselectedSpecials) => {
        onChange({
            selectedkeys: [
                ...sourceselectedSpecials,
                ...targetselectedSpecials,
            ],
        });
    };

    /**
     * Cambia el tipo de nivel
     *
     * @param {*} event
     */
    const handleChange = (event) => {
        onChange({
            selectedkeys: [],
            targetkeys: [],
            primaryGroup: '',
        });
        onChangeLevel(event.target.value);
    };

    /**
     * Cambia el tipo de nivel
     *
     * @param {*} event
     */
    const onSelectPrimaryGroup = (event) => {
        const groupId = event.target.value;
        const selectedGroup = primarygroups.find(
            (group) => group.group_id === groupId
        );
        const groupName = `${selectedGroup.grade}° ${
            selectedGroup.group
        } ${getTurnLevel(selectedGroup.turn)}`;
        //        setGetNameGroup(groupName);
        onChangeGettingNameGroup(groupName);
        onChange({
            primaryGroup: groupId,
        });
    };

    /**
     * Obtener los nombres de los grupos especiales seleccionados
     *
     * @param {*} targetKeys
     * @returns
     */
    const getSelectedSpecialGroupNames = (targetKeys) => {
        return targetKeys.map((key) => {
            const group = specialgroups.find((group) => group.group_id === key);
            return `${group.grade}° ${group.group} ${getTurnLevel(group.turn)}`;
        });
    };

    useEffect(() => {
        const selectedNames = getSelectedSpecialGroupNames(targetKeys);
        onChangeGettingSpecialGroupsNames(selectedNames);
    }, [targetKeys]);

    /**
     * Filtro para la busqueda
     */

    const removeAccents = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    const filterOption = (inputValue, option) => {
        const search = removeAccents(
            inputValue.toLowerCase().replace(/\s/g, '')
        );

        const titleNoSpace = removeAccents(
            `${option.grade} ${option.group} ${getTurnLevel(option.turn)}`
                .toLowerCase()
                .replace(/\s/g, '')
        );
        const titleMatch = titleNoSpace.includes(search);

        return titleMatch;
    };

    return (
        <>
            <Divider sx={{ mt: 2, mb: 2 }}>
                <Typography
                    variant="h6"
                    component="div"
                    style={{ textAlign: 'center' }}>
                    Grupo Principal
                </Typography>
                {statusOperation == 'pending' && (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                )}
            </Divider>
            <Box>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="demo-simple-select-label">
                                Nivel escolar
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={level}
                                label="Nivel escolar"
                                onChange={handleChange}>
                                {constants.levelsNames.map((i) => {
                                    return (
                                        <MenuItem key={i.key} value={i.key}>
                                            {i.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                                Grupo principal
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedPrimaryGroups}
                                label="Grupo principal"
                                onChange={onSelectPrimaryGroup}>
                                {primarygroups.map((i) => {
                                    return (
                                        <MenuItem
                                            key={i.group_id}
                                            value={i.group_id}>
                                            {`${i.grade}° ${
                                                i.group
                                            } ${getTurnLevel(i.turn)}`}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <FormControl variant="standard" sx={{ width: '100%', mt: 2 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Divider
                            orientation="horizontal"
                            flexItem
                            variant="h6"
                            component="div"
                            style={{ textAlign: 'center' }}>
                            Grupo Especial
                        </Divider>

                        <Transfer
                            filterOption={filterOption}
                            style={{ marginTop: '20px' }}
                            value={specialgroups}
                            listStyle={{
                                width: 300,
                                height: 300,
                            }}
                            locale={{
                                notFoundContent: ['Sin grupos', 'Sin grupos'],
                                itemUnit: 'Grupos',
                                itemsUnit: 'Grupos',
                            }}
                            dataSource={specialgroups.map((i) => {
                                return { ...i, key: i.group_id };
                            })}
                            titles={['Disponibles', 'Asignados']}
                            targetKeys={targetKeys}
                            selectedKeys={selectedKeys}
                            onChange={onChangeTargetKeys}
                            onSelectChange={onSelectChange}
                            render={(item) =>
                                `${item.grade}° ${item.group} ${getTurnLevel(
                                    item.turn
                                )}`
                            }
                            showSearch
                        />
                    </Box>
                </FormControl>
            </Box>
        </>
    );
};

export default GroupsSelector;
