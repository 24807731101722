import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const paymentAdapter = createEntityAdapter({
    selectId: (payment) => payment.payment_id,
    sortComparer: (a, b) => a.payment_id - b.payment_id,
});

export const PaymentSlice = createSlice({
    name: 'payment',
    initialState: paymentAdapter.getInitialState(),
    reducers: {
        setAllPayments: paymentAdapter.setAll,
        addManyPayments: paymentAdapter.addMany,
        addOnePayment: paymentAdapter.addOne,
        removeOnePayment: paymentAdapter.removeOne,
        upsertOnePayment: paymentAdapter.upsertOne,
        upsertManyPayments: paymentAdapter.upsertMany,
        updateManyPayments: paymentAdapter.updateMany,
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return paymentAdapter.getInitialState();
        });
    },
});

export const {
    setAllPayments,
    addManyPayments,
    addOnePayment,
    removeOnePayment,
    upsertOnePayment,
    upsertManyPayments,
    updateManyPayments,
} = PaymentSlice.actions;

const globalizedSelectors = paymentAdapter.getSelectors(
    (state) => state.entities.payment
);

export default PaymentSlice.reducer;
