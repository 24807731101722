import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Card, CardContent, Tooltip } from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarContainer,
    GridToolbarExport,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectSubjects,
} from '../../../store/subjects/fetchSlice';
import { getAllSubjects } from '../../../store/subjects/thunks';
import { selectComponentHomeAllSubjectsStatusServer } from '../../../store/subjects/componentsStatusSelectors';
import { SkeletonSubjectsTable } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { Tag } from 'antd';
import _ from 'lodash';

const AllSubjects = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeAllSubjectsStatusServer
    );

    const allSubjects = useFetchResource(
        () =>
            getAllSubjects({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectSubjects,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getAllSubjects({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectSubjects,
            [cycleSelected, schoolId]
        );
    };

    const itemsPerPage = 10;
    const [pageSize, setPageSize] = useState(itemsPerPage);

    const data = allSubjects.value
        ? _.chain(
              allSubjects.value.Grado.map((_, index) => ({
                  id: index + 1,
                  grado: allSubjects.value.Grado[index].value,
                  grupo: allSubjects.value.Grupo[index].value,
                  nivel: allSubjects.value.Nivel[index].value,
                  turno: allSubjects.value.Turno[index].value,
                  materia: allSubjects.value.Materia[index].value,
                  profesor: allSubjects.value.Profesor[index].value,
                  tipo: allSubjects.value.Tipo[index].value,
                  modulo: allSubjects.value.Modulo[index].value,
                  asistencias: allSubjects.value.Asistencias[index].value,
                  faltas: allSubjects.value.Faltas[index].value,
                  promedio: allSubjects.value.Promedio[index].value,
              }))
          )
              .sortBy([
                  (item) => item.nivel,
                  (item) => item.turno,
                  (item) => item.grado,
                  (item) => item.grupo,
                  (item) => item.materia,
                  (item) => item.tipo,
                  (item) => item.profesor,
              ])
              .value()
        : [];

    const columns = [
        { field: 'nivel', headerName: 'Nivel', flex: 1 },
        { field: 'turno', headerName: 'Turno', flex: 1 },
        { field: 'grado', headerName: 'Grado', flex: 1 },
        { field: 'grupo', headerName: 'Grupo', flex: 1 },
        { field: 'materia', headerName: 'Materia', flex: 1 },
        { field: 'profesor', headerName: 'Profesor', flex: 1 },
        { field: 'tipo', headerName: 'Tipo', flex: 1 },
        { field: 'modulo', headerName: 'Modulo', flex: 1 },
        {
            field: 'asistencias',
            headerName: 'Asistencia',
            flex: 0.7,
            renderCell: (params) => (
                <Tag
                    color="success"
                    style={{
                        width: '100px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {params.value}
                </Tag>
            ),
        },
        {
            field: 'faltas',
            headerName: 'Faltas',
            flex: 0.7,
            renderCell: (params) => (
                <Tag
                    color="error"
                    style={{
                        width: '100px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {params.value}
                </Tag>
            ),
        },
        {
            field: 'promedio',
            headerName: 'Promedio',
            flex: 0.7,
            renderCell: (params) => (
                <Tag
                    color="blue"
                    style={{
                        width: '100px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {params.value}
                </Tag>
            ),
        },
    ];

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbar {...props} />
            </GridToolbarContainer>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonSubjectsTable items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                    }}
                >
                    <CardContent>
                        <Tooltip
                            title={
                                allSubjects?.description ||
                                'Información general de las materias en la escuela'
                            }
                            followCursor
                        >
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                gutterBottom
                            >
                                {allSubjects?.title ||
                                    'Información general de las materias'}
                            </Typography>
                        </Tooltip>
                        <Box sx={{ height: 700, width: '100%' }}>
                            <DataGrid
                                localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                        .localeText
                                }
                                rows={data}
                                columns={columns}
                                pageSize={pageSize}
                                rowsPerPageOptions={[itemsPerPage]}
                                disableDensitySelector
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                }}
                                sx={{
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                    },
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default AllSubjects;
