import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    statusFilter: 'all',
};

const filtersSlice = createSlice({
    name: 'filters/discounts',
    initialState,
    reducers: {
        setStatusFilter(state, action) {
            state.statusFilter = action.payload;
        },
    },
});

export const { setStatusFilter } = filtersSlice.actions;

export default filtersSlice.reducer;
