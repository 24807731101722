/**
 * Selector para recuperar home de avisos
 */
export const selectNoticesReport = (store) => store.notices.report;

/**
 * Recuperamos la ui de home
 */
export const selectedReportNoticesUI = (state) =>
    state.notices.report.ui;
