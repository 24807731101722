import React, { useEffect, useState } from "react";

import { Backdrop, CircularProgress } from "@mui/material";

/**
 * Componente encargado de mostra una animacion
 * en caso se que aun no se hayan cargado todo los
 * datos en redux
 * 
 * @param {*} param0
 * 
 * @returns 
 */
const AnimatedGate = ({ children, bootstrapped }) => {

    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (bootstrapped) {
            setTimeout(() => {
                setLoaded(true)
            }, 1000)
        }
    }, [bootstrapped])


    return (
        <>
            {!loaded && (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            {loaded && (children)}
        </>
    )
}

export default AnimatedGate;
