import { DialogTitle, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { useAuth } from "../../hooks"
import { selectSettingBySchoolId } from "../../store/slices/entities/settings"

/**
 * Retorna el titulo de la venta
 * 
 * @returns 
 */
const TitleComponent = ({typeView, distributionMode}) => {

    const Auth = useAuth()
    const schoolId = Auth.getUser().school_id

    ///////////////////// SHARED STATE //////////////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    return (
        <DialogTitle
            style={{backgroundColor: titleBarBackground.value, color: fontColor.value}}
        >
            <div style={{display: 'flex'}}>
                <Typography variant="h6" component="div" style={{color: fontColor.value}}>
                    Enviar aviso <br/>
                    <span style={{fontSize: '15px', color:'#fff'}}>
                        {
                            typeView == 1 ? 
                                (<span>Redactar contenido</span>)
                            :
                                (
                                    <span>
                                        {
                                            distributionMode == null && (<span>Seleccionar modo de distribución</span>)
                                        }
                                        {
                                            distributionMode == "groups" && (<span>Modo de distribución por grupos</span>)
                                        }
                                        {
                                            distributionMode == "students" && (<span>Modo de distribución por alumnos</span>)
                                        }

                                    </span>
                                )
                        }
                    </span>
                </Typography>
            </div>
        </DialogTitle>
    )
}


export default TitleComponent