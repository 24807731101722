import React, { useState } from 'react';
import {
    Backdrop,
    Box,
    CircularProgress,
    Grid,
    IconButton,
    Skeleton,
} from '@mui/material';
import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material';
import FlexBetween from '../../components/FlexBetween';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { setMode } from '../../../store/slices/theme';
import IncomeCard from './Components/IncomeCards';
import IncomeTable from './Components/IncomeTable';
import TabsComponent from '../../components/tabs';

const Income = () => {
    const [currentTab, setCurrentTab] = useState('pagos');
    const [loading, setLoading] = useState(false);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const dispatch = useDispatch();
    const theme = useTheme();

    return (
        <>
            <TabsComponent
                currentTab={currentTab}
                onTabChange={handleTabChange}
                setLoading={setLoading}
            />

            {loading ? (
                <Box
                    sx={{
                        paddingLeft: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                        paddingRight: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                        paddingBottom: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                    }}>
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={500}
                        sx={{ mb: 2 }}
                    />
                </Box>
            ) : (
                <Box
                    sx={{
                        paddingLeft: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                        paddingRight: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                        paddingBottom: {
                            xs: 1,
                            sm: 2,
                            md: 5,
                        },
                    }}>
                    {/* <IncomeCard /> */}
                    <IncomeTable />
                </Box>
            )}
        </>
    );
};

export default Income;
