import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import useFetchResource from '../../../components/HelperHook';
import { useAuth } from '../../../../hooks';
import {
    getCycleSelected,
    selectStudentsFailedGenderGradeSubject,
} from '../../../store/subjects/fetchSlice';
import { getStudentsFailedGenderGradeSubject } from '../../../store/subjects/thunks';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SkeletonSubjectsGraffic } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeStudentsFailedGenderGradeSubjectStatusServer } from '../../../store/subjects/componentsStatusSelectors';

const StudentsFailedGenderGradeSubject = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeStudentsFailedGenderGradeSubjectStatusServer
    );

    const studentsFailedGenderGradeSubject = useFetchResource(
        () =>
            getStudentsFailedGenderGradeSubject({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectStudentsFailedGenderGradeSubject,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getStudentsFailedGenderGradeSubject({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectStudentsFailedGenderGradeSubject,
            [cycleSelected, schoolId]
        );
    };

    const [page, setPage] = useState(0);
    const pageSize = 7;

    const processData = (data) => {
        const subjects = Object.keys(data);

        subjects.sort((a, b) => {
            const totalA =
                data[a].HOMBRES.reduce((acc, curr) => acc + curr.data, 0) +
                data[a].MUJERES.reduce((acc, curr) => acc + curr.data, 0);
            const totalB =
                data[b].HOMBRES.reduce((acc, curr) => acc + curr.data, 0) +
                data[b].MUJERES.reduce((acc, curr) => acc + curr.data, 0);

            return totalB - totalA;
        });

        const pages = [];
        for (let i = 0; i < subjects.length; i += pageSize) {
            pages.push(subjects.slice(i, i + pageSize));
        }

        return pages.map((pageSubjects) => {
            return pageSubjects.map((subject) => {
                const subjectData = data[subject];
                const hombresData = subjectData.HOMBRES.reduce(
                    (acc, curr) => acc + curr.data,
                    0
                );
                const mujeresData = subjectData.MUJERES.reduce(
                    (acc, curr) => acc + curr.data,
                    0
                );

                return {
                    subject,
                    Hombres: hombresData,
                    Mujeres: mujeresData,
                };
            });
        });
    };

    const paginatedData = processData(
        studentsFailedGenderGradeSubject.value || {}
    );

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonSubjectsGraffic items={9} />
            )}
            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {fetchingStatus === 'fulfilled' && (
                <Box
                    sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: '10px',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        {studentsFailedGenderGradeSubject.title}
                    </Typography>
                    <Box sx={{ height: '400px', minWidth: '100%' }}>
                        {paginatedData.length > 0 && (
                            <ResponsiveBar
                                data={paginatedData[page]}
                                keys={['Hombres', 'Mujeres']}
                                indexBy="subject"
                                margin={{
                                    top: 20,
                                    right: 50,
                                    bottom: 110,
                                    left: 60,
                                }}
                                padding={0.3}
                                colors={({ id }) =>
                                    id === 'Hombres' ? '#007bff' : '#ff69b4'
                                }
                                borderRadius={5}
                                groupMode="grouped"
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 45,
                                    legendPosition: 'middle',
                                    legendOffset: 60,
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legendPosition: 'middle',
                                    legendOffset: -50,
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor="white"
                                theme={{
                                    axis: {
                                        ticks: {
                                            text: { fontSize: 12 },
                                        },
                                    },
                                    labels: {
                                        text: {
                                            fontSize: 16,
                                            fill: 'white',
                                        },
                                    },
                                    tooltip: {
                                        container: {
                                            background: 'white',
                                            color: 'inherit',
                                            fontSize: 12,
                                            borderRadius: '2px',
                                            boxShadow:
                                                '0 1px 2px rgba(0, 0, 0, 0.25)',
                                        },
                                    },
                                }}
                                layers={[
                                    'grid',
                                    'axes',
                                    'bars',
                                    'markers',
                                    'legends',
                                    (props) =>
                                        props.bars.map((bar) => {
                                            const isSmallValue =
                                                bar.data.value <= 10;

                                            return (
                                                <g
                                                    transform={`translate(${bar.x}, ${bar.y})`}
                                                    key={bar.key}
                                                >
                                                    <rect
                                                        width={bar.width}
                                                        height={bar.height}
                                                        fill={bar.color}
                                                    />
                                                    {bar.data.value > 10 && (
                                                        <text
                                                            x={bar.width / 2}
                                                            y={bar.height / 2}
                                                            textAnchor="middle"
                                                            dominantBaseline="central"
                                                            style={{
                                                                fill: 'white',
                                                                fontSize: 16,
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            {bar.data.value}
                                                        </text>
                                                    )}
                                                    {isSmallValue && (
                                                        <text
                                                            x={bar.width / 2}
                                                            y={-5}
                                                            textAnchor="middle"
                                                            style={{
                                                                fill: 'black',
                                                                fontSize: 16,
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            {bar.data.value}
                                                        </text>
                                                    )}
                                                </g>
                                            );
                                        }),
                                ]}
                            />
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography sx={{ margin: '0 10px' }}>
                            Página {page + 1} de {paginatedData.length}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '20px',
                        }}
                    >
                        <Button
                            startIcon={<ArrowBackIosIcon />}
                            variant="contained"
                            color="primary"
                            disabled={page === 0}
                            onClick={() => setPage(page - 1)}
                            size="small"
                            sx={{ mr: 1 }}
                        >
                            Anterior
                        </Button>
                        <Button
                            endIcon={<ArrowForwardIosIcon />}
                            variant="contained"
                            color="primary"
                            disabled={page === paginatedData.length - 1}
                            onClick={() => setPage(page + 1)}
                            size="small"
                        >
                            Siguiente
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default StudentsFailedGenderGradeSubject;
