import { combineReducers } from '@reduxjs/toolkit';
import home from './home';
import groups from './groups';
import subjects from './subjects';
import professors from './professors';
import students from './students';
import entities from './entities';

export default combineReducers({
    entities,
    home,
    groups,
    subjects,
    professors,
    students,
});
