import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import esLocale from '@fullcalendar/core/locales/es';
import { useSelector } from "react-redux";
import { getActivitiesCalendar } from '../../../store/slices/calendar'
import moment from 'moment'

const ActivityCalendar = (props) => {

    moment.locale('es')

    const activities = useSelector(getActivitiesCalendar)
    const calendarRef = React.createRef()

    const onviewDidMount = () => {
        props.mounted((new Date).toISOString())
    }

    //////////////////// FUNCIONES Y COMPONENTES /////////////

    /**
     * Maneja cuendo se presiona sobre un dia del calendario
     * 
     * @param {*} date 
     */
    const onDateClick = (date) => {
       // props.clickDay(date)
    }

    /**
     * Maneja cuendo se presiona sobre un evento
     * 
     * @param {*} date 
     */
    const onEventClick = (date) => {
        props.eventClick(date)
    }
    

    const getDates =  () => {
        let dates = []

        for (const activity of activities) {
            dates.push({
                id: activity.activity_id,
                key: activity.activity_id,
                title: activity.description,
                date: moment(activity.start_date).format('YYYY-MM-DD'),
                end: (activity.end_date) ? moment(activity.end_date).format('YYYY-MM-DD') : null,
                backgroundColor: activity.color,
                borderColor: activity.color,
                payload: activity
            })
        }
        return dates
    }

    return (
      <FullCalendar
        ref={calendarRef}
        plugins={[ dayGridPlugin, interactionPlugin ]}
        initialView="dayGridMonth"
        events={getDates()}
        dateClick={onDateClick}
        eventClick={onEventClick}
        locale={esLocale}
        viewDidMount={onviewDidMount}
        headerToolbar={{
            start: 'title',
            center: '',
            end: 'ownToday ownPrev,ownNext'
        }}
        customButtons={{
            ownToday: {
                text: 'Hoy',
                click: function() {
                    let calendarApi = calendarRef.current.getApi()
                    calendarApi.today()
      
                    props.today(calendarApi.getDate().toISOString())
                }
            },
            ownNext: {
                text: 'Siguiente',
                click: function() {
                    let calendarApi = calendarRef.current.getApi()
                    calendarApi.next()
      
                    props.nextMonth(calendarApi.getDate().toISOString())
                }
            },
            ownPrev: {
                text: 'Anterior',
                click: function() {
                    let calendarApi = calendarRef.current.getApi()
                    calendarApi.prev()

                    props.prevMonth(calendarApi.getDate().toISOString())
                }
            }         
        }}
      />
    )
}


export default ActivityCalendar