//////////////////////////////////////// FETCH

export const selectComponentProfessorsLagAssistsFetch = (store) =>
    store.moduleDirector.professors.components.lag_assists.fetch;

export const selectComponentProfessorsLagCalificationsFetch = (store) =>
    store.moduleDirector.professors.components.professors_without_group.fetch;

export const selectComponentProfessorsWithoutGroupFetch = (store) =>
    store.moduleDirector.professors.components.professor_most_reports.fetch;

export const selectComponentProfessorsMostReportsFetch = (store) =>
    store.moduleDirector.professors.components.professor_most_reports.fetch;

export const selectComponentProfessorsTotalReportsFetch = (store) =>
    store.moduleDirector.professors.components.all_professor_reports.fetch;

export const selectComponentAllProfessorsFetch = (store) =>
    store.moduleDirector.professors.components.professors.fetch;

export const selectComponentProfessorsLastInsidentFetch = (store) =>
    store.moduleDirector.professors.components.last_incident.fetch;

/////////////////////////////////////// STATUS SERVER

export const selectComponentProfessorsLagAssistsStatusServer = (store) =>
    store.moduleDirector.professors.components.lag_assists.fetch.statusServer;

export const selectComponentProfessorsLagCalificationsStatusServer = (store) =>
    store.moduleDirector.professors.components.lag_califications.fetch
        .statusServer;

export const selectComponentProfessorsWithoutGroupStatusServer = (store) =>
    store.moduleDirector.professors.components.professors_without_group.fetch
        .statusServer;

export const selectComponentProfessorsMostReportsStatusServer = (store) =>
    store.moduleDirector.professors.components.professor_most_reports.fetch
        .statusServer;

export const selectComponentProfessorsTotalReportsStatusServer = (store) =>
    store.moduleDirector.professors.components.all_professor_reports.fetch
        .statusServer;

export const selectComponentAllProfessorsStatusServer = (store) =>
    store.moduleDirector.professors.components.professors.fetch.statusServer;

export const selectComponentProfessorsLastInsidentStatusServer = (store) =>
    store.moduleDirector.professors.components.last_incident.fetch.statusServer;
