///////////////////////////////////////////// FETCH //////////////////////////////////////////////////

export const selectComponentHomeAbsenceBySubjectsFetch = (store) =>
    store.moduleDirector.subjects.components.absence_by_subjects.fetch;

export const selectComponentHomeAssistsBySubjectsFetch = (store) =>
    store.moduleDirector.subjects.components.assists_by_subjects.fetch;

export const selectComponentHomeBestScoreSubjectFetch = (store) =>
    store.moduleDirector.subjects.components.best_score_on_subject.fetch;

export const selectComponentHomeWorstScoreSubjectFetch = (store) =>
    store.moduleDirector.subjects.components.worst_score_subject.fetch;

export const selectComponentHomeStudentsApprovedGenderGradeSubjectFetch = (
    store
) =>
    store.moduleDirector.subjects.components
        .students_approved_gender_grade_subject.fetch;

export const selectComponentHomeStudentsFailedGenderGradeSubjectFetch = (
    store
) =>
    store.moduleDirector.subjects.components
        .students_failed_gender_grade_subject.fetch;

export const selectComponentHomeAllSubjectsFetch = (store) =>
    store.moduleDirector.subjects.components.subjects.fetch;

///////////////////////////////////////STATUS SERVER///////////////////////////////////////////

export const selectComponentHomeAbsenceBySubjectsStatusServer = (store) =>
    store.moduleDirector.subjects.components.absence_by_subjects.fetch
        .statusServer;

export const selectComponentHomeAssistsBySubjectsStatusServer = (store) =>
    store.moduleDirector.subjects.components.assists_by_subjects.fetch
        .statusServer;

export const selectComponentHomeBestScoreSubjectStatusServer = (store) =>
    store.moduleDirector.subjects.components.best_score_on_subject.fetch
        .statusServer;

export const selectComponentHomeWorstScoreSubjectStatusServer = (store) =>
    store.moduleDirector.subjects.components.worst_score_subject.fetch
        .statusServer;

export const selectComponentHomeStudentsApprovedGenderGradeSubjectStatusServer =
    (store) =>
        store.moduleDirector.subjects.components
            .students_approved_gender_grade_subject.fetch.statusServer;

export const selectComponentHomeStudentsFailedGenderGradeSubjectStatusServer = (
    store
) =>
    store.moduleDirector.subjects.components
        .students_failed_gender_grade_subject.fetch.statusServer;

export const selectComponentHomeAllSubjectsStatusServer = (store) =>
    store.moduleDirector.subjects.components.subjects.fetch.statusServer;
