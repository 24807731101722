/**
 * Componente encargado de renderisar el nombre de una materia
 */
const SubjectCell = ({value}) => {
    return (
        <div
            style={{
                whiteSpace: 'pre-line',
                textAlign: 'center',
            }}
        >
            {value == null ? '' : value}
        </div>
    )
}

export default SubjectCell