import { Grid, Skeleton } from "@mui/material"
import SkeletonInput from "antd/lib/skeleton/Input"

export default () => {

    return (
        <>
            <Skeleton variant="rectangular" height={50} sx={{ mb: 4 }} animation="wave" />

            <Skeleton variant="rectangular" height={600} sx={{ mt: 2 }} animation="wave" />
        </>
    )
}

export const SkeletonUpdateStudentStepperModal = () => {
    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                    <Skeleton variant="rectangular" height={60} animation="wave" width="500%" />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Skeleton variant="rectangular" height={60} animation="wave" width="500%" />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Skeleton variant="rectangular" height={60} animation="wave" width="500%" />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Skeleton variant="rectangular" height={60} animation="wave" width="500%" />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Skeleton variant="rectangular" height={60} animation="wave" width="500%" />
                </Grid>
            </Grid>
        </>
    )
}

export const SkeletonDeleteStudentModal = () => {
    return (
        <Grid>
            <Grid style={{ margin: '16px 0' }}>
                <Skeleton variant="circular" width={80} height={80} animation="wave" sx={{ ml: 30, mb: 1 }} />
                <Skeleton variant="rectangular" height={30} animation="wave" width="100%" />
                <Skeleton variant="rectangular" height={30} animation="wave" width="100%" />
            </Grid>

            <Skeleton variant="rectangular" height={30} animation="wave" width="100%" />
            <Skeleton variant="rectangular" height={30} animation="wave" width="100%" />

            <Grid style={{ margin: '16px 0' }}>
                <Skeleton variant="rectangular" height={30} animation="wave" width="100%" />
                <Skeleton variant="rectangular" height={30} animation="wave" width="100%" />
            </Grid>

            <Grid style={{ margin: '16px 0' }}>
                <Skeleton variant="rectangular" height={30} animation="wave" width="100%" />
                <Skeleton variant="rectangular" height={30} animation="wave" width="100%" />
            </Grid>

            <Grid style={{ margin: '16px 0' }}>
                <Skeleton variant="rectangular" height={30} animation="wave" width="100%" />
                <Skeleton variant="rectangular" height={30} animation="wave" width="100%" />
            </Grid>
        </Grid>
    );
};
