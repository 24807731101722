import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    Skeleton,
    Box,
    Tooltip,
    IconButton,
    Stack,
} from '@mui/material';
import {
    getLevelName,
    getSpecialName,
    getTurnLevel,
} from '../../../libs/utils';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useSelector } from 'react-redux';
import {
    loadGroupsUI,
    selectGroupsStatusServerItemSelected,
    selectStudentItemSelected,
} from '../../../store/slices/studentsUI/ItemsSlice';
import { useDispatch } from 'react-redux';
import { DataGrid, esES } from '@mui/x-data-grid';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import * as Style from '../../../styles/index';
import { Error } from '../../../components/Feedback';

/**
 * Modal para Mostrar los grupos de un estudiante
 *
 * @param {*} param0
 *
 * @returns
 */
const StudentGroupModal = ({ open, setOpen, onDelete }) => {
    const classes = Style.tableModalStyle();

    const dispatch = useDispatch();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    //////////// SHARED STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));
    //const statusOperation = useSelector(selectStatusOperation)

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    const studentSelected = useSelector(selectStudentItemSelected(null));
    const statusServer = useSelector(selectGroupsStatusServerItemSelected);

    /**
     * Iniciamos el flujo de recuperacion de los grupos
     * del alumno seleccionado
     */
    useEffect(() => {
        if (open) {
            dispatch(loadGroupsUI());
        }
    }, [open]);

    /////////////////////// ACTIONS ////////////////////

    /**
     * Mostrar modal para eliminar alumno de grupo
     *
     * @param {*} studentId
     */
    const onOpenDeleteStudentFromGroupModal = (groupId) => {
        setOpen(false);
        onDelete(studentSelected.student_id, groupId);
    };

    /**
     *
     */
    const closeModal = () => {
        setOpen(false);
    };

    /**
     * Funcion para recargar los datos del usuario
     */
    const reload = () => {
        dispatch(loadGroupsUI())
            .unwrap()
            .then((response) => { });
    };

    /**
     * Definicion de las columnas
     */
    const columns = [
        {
            field: 'grade',
            headerName: 'Grado',
            flex: 0.5,
            width: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return `${params.row.grade}°`;
            },
        },
        {
            field: 'group',
            headerName: 'Grupo',
            flex: 0.5,
            width: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'turn',
            headerName: 'Turno',
            flex: 0.5,
            width: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return getTurnLevel(params.row.turn);
            },
        },
        {
            field: 'level',
            headerName: 'Nivel',
            flex: 0.5,
            width: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return getLevelName(params.row.level);
            },
        },
        {
            field: 'special',
            headerName: 'Tipo',
            flex: 0.5,
            width: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return getSpecialName(params.row.group_type);
            },
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            flex: 0.5,
            width: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <>
                    <Tooltip title="Sacar de grupo">
                        <IconButton
                            style={{ textTransform: 'none', marginLeft: 10 }}
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                onOpenDeleteStudentFromGroupModal(params.id);
                            }}
                        >
                            <PersonRemoveIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
    ];

    const studentsGroups = studentSelected.groupsList.map((i) => {
        return {
            id: i.group_id,
            ...i,
        };
    }).sort((a, b) => {
        if (a.group_type !== b.group_type) {
            return a.group_type - b.group_type;
        }
        if (a.level !== b.level) {
            return a.level - b.level;
        }
        if (a.turn !== b.turn) {
            return a.turn - b.turn;
        }
        if (a.grade !== b.grade) {
            return a.grade - b.grade;
        }
        return a.group.localeCompare(b.group);
    });

    return (
        <>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="sm"
                classes={{ paper: classes.dialogWrapper }}
                disableEscapeKeyDown={statusServer == 'pending'}
                onClose={() => {
                    closeModal();
                }}
            >
                <DialogTitle
                    className={classes.dialogTitle}
                    style={{
                        backgroundColor: titleBarBackground.value,
                        color: fontColor.value,
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography
                            variant="h6"
                            component="div"
                            style={{ flexGrow: 1 }}
                        >
                            Grupos
                            <br />
                            <span style={{ fontSize: '15px' }}>
                                Alumno:{' '}
                                {`${studentSelected.name} ${studentSelected.last_name}`}
                            </span>
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {statusServer == 'pending' && (
                        <Stack spacing={2} sx={{ padding: 2 }}>
                            <Skeleton variant="rectangular" height={30} />
                            <Skeleton variant="rectangular" height={30} />
                            <Skeleton variant="rectangular" height={30} />
                            <Skeleton variant="rectangular" height={30} />
                        </Stack>
                    )}

                    {statusServer == 'rejected' && (
                        <Error
                            onRetry={reload}
                            message={'Estamos teniendo problemas'}
                        />
                    )}

                    {statusServer == 'fulfilled' && (
                        <Box
                            sx={{
                                '& .super-app-theme--header': {
                                    backgroundColor: titleBarBackground.value,
                                    color: fontColor.value,
                                },
                                '& .MuiDataGrid-root': {
                                    border: 0,
                                },
                            }}
                        >
                            <Box sx={{ textAlign: 'center' }}>
                                <DataGrid
                                    localeText={
                                        esES.components.MuiDataGrid.defaultProps
                                            .localeText
                                    }
                                    rows={studentsGroups}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    autoHeight
                                    hideFooterPagination
                                />
                            </Box>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={closeModal}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StudentGroupModal;
