import { makeStyles } from "@material-ui/core";


export default makeStyles(theme =>({
    dialogWrapper: {
        //padding: theme.spacing(0),
        //position: 'absolute',
        //top: theme.spacing(5)
    },
    dialogTitle:{
        paddingRight: '0px',
        paddingBottom: 0
    },
    dialogContent: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0
    }
}));
