import React, {useState} from "react";

const AuthContext = React.createContext({
    some: "Holy",
    isProfessor: null,
    isDirector: null,
    isParent: null,
    belongToGroup: null,
    setUser: null,
    getHomeRoute: null,
    getUser: null,
    login: null
})


export default AuthContext