import React from "react";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";

const TerminosCondiciones = () => {
  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={1}>
        <Grid item xl={2} sm={2} md={2} xs={2}></Grid>
        <Grid
          item
          xl={10}
          sm={10}
          md={10}
          xs={10}
          style={{ textAlign: "justify" }}
        >
          <h1 id="titulo">TÉRMINOS Y CONDICIONES</h1>
          <Typography
            variant="p"
            component="div"
            style={{ width: "90%", color: "#000" }}
          >
            <strong>Términos y condiciones de uso de la aplicación Cura:</strong><br /> <br />
            Bienvenido a la aplicación Cura. Antes de utilizar la aplicación, 
            lea atentamente los términos y condiciones que rigen su uso. 
            Al utilizar la aplicación, usted acepta cumplir con estos términos y condiciones.
            <br /> <br />
            <strong>Información personal</strong><br /> <br />
            La aplicación Cura utilizará su nombre, dirección, correo electrónico, 
            fotografía y ubicación para proporcionar servicios de localización. 
            Nos comprometemos a proteger su privacidad y a utilizar sus datos 
            personales sólo para los fines previstos en la aplicación.
            <br /> <br />
            <strong>Responsabilidad del usuario</strong><br /> <br />
            Es responsabilidad del usuario proporcionar información precisa y 
            actualizada en la aplicación. El usuario es responsable de cualquier 
            información que publique en la aplicación y de cualquier acción que 
            realice en la aplicación.
            <br /><br />
            <strong>Uso de la aplicación</strong><br /> <br />
            El uso de la aplicación está sujeto a las leyes y regulaciones aplicables. 
            El usuario se compromete a utilizar la aplicación sólo para fines 
            legales y autorizados. El usuario no puede utilizar la aplicación para 
            enviar contenido ofensivo o ilegal.
            <br /> <br />
            <strong>Propiedad intelectual</strong><br /> <br />
            La aplicación Cura y todo su contenido, incluidos, entre otros, 
            el diseño, los gráficos, el software y los textos, son propiedad de la 
            empresa. El usuario no puede reproducir, distribuir, modificar, c
            rear software derivadas o utilizar cualquier contenido de la aplicación 
            sin nuestro consentimiento previo por escrito.
            <br /><br />
            <strong>Exclusión de responsabilidad</strong><br /> <br />
            La aplicación Cura se proporciona "tal cual", sin garantía de ningún tipo, 
            expresa o implícita. No nos hacemos responsables de ningún daño, incluidos, 
            entre otros, los daños directos, indirectos, especiales, incidentales o 
            consecuentes, que surjan del uso de la aplicación.
            <br /> <br />
            <strong>Modificaciones a los términos y condiciones</strong><br /> <br />
            Nos reservamos el derecho de modificar estos términos y condiciones en 
            cualquier momento. Si se realizan cambios, se notificará al usuario por 
            medio de la aplicación. El uso continuado de la aplicación después de la 
            modificación de los términos y condiciones constituye su aceptación de los 
            términos y condiciones modificados.
            <br /><br />
            <strong>Terminación del uso de la aplicación</strong><br /> <br />
            Nos reservamos el derecho de suspender o cancelar el acceso del usuario a la 
            aplicación en cualquier momento y por cualquier motivo sin previo aviso.
            <br />
            Al utilizar la aplicación Cura, el usuario acepta cumplir con estos términos y 
            condiciones. Si el usuario no está de acuerdo con estos términos y condiciones, 
            no debe utilizar la aplicación.
            <br /><br />
            Actualización: Marzo 2023
          </Typography>
        </Grid>
        <Grid item xl={2} sm={2} md={2} xs={2}></Grid>
      </Grid>
    </Box>
  );
};

export default TerminosCondiciones;
