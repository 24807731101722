import React from 'react';
import { InputLabel, ListSubheader } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { getLevelName } from '../../libs/utils';
import { getCycleSelected } from '../../moduleDirector/store/home/fetchSlice';
import { getCycles } from '../../moduleDirector/store/entities';
import { setSchoolCycleSelected } from '../../moduleDirector/store/home/uiSlice';

/**
 * Componente encargado de seleccionar el cliclo
 * del cual se quiere ver informacion
 *
 * @param {*} props
 *
 * @returns
 */
const CyclesSelectOnDirector = ({ disabled, onChange }) => {
    const dispatch = useDispatch();

    /////////////// SHARES STATE  //////////////

    /**
     * Ciclos disponibles
     */
    const cycles = useSelector(getCycles);
    /**
     * Ciclo actual seleccionado
     */
    const schoolCycle = useSelector(getCycleSelected);
    /////////////// ACTIONS ///////////////////

    /**
     * Cambiar el ciclo
     *
     * @param {*} event
     */
    const onChangeSchoolCycle = (event) => {
        let cycleSelected = cycles.find(
            (item) => item.cycle_id == event.target.value
        );
        if (cycleSelected == undefined) {
            cycleSelected = {
                cycle_id: -1,
            };
        }

        dispatch(setSchoolCycleSelected(cycleSelected));
        onChange(cycleSelected);
    };

    let menuItemUI = [];

    const cyclesByCycleName = cycles.reduce((group, product) => {
        const { cycle } = product;
        group[cycle] = group[cycle] ?? [];
        group[cycle].push(product);
        return group;
    }, {});

    for (const cycle in cyclesByCycleName) {
        menuItemUI.push(
            <ListSubheader key={cycle}>Ciclo: {cycle}</ListSubheader>
        );

        for (const cyclesLevel of cyclesByCycleName[cycle]) {
            const levelName = getLevelName(cyclesLevel.level);
            menuItemUI.push(
                <MenuItem
                    key={cyclesLevel.cycle_id}
                    value={cyclesLevel.cycle_id}
                >
                    Nivel: {levelName} - ({cyclesLevel.segment_cut})
                </MenuItem>
            );
        }
    }

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-simple-select-label">Ciclo Escolar</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={schoolCycle.cycle_id}
                label="Ciclo Escolar"
                autoWidth
                onChange={onChangeSchoolCycle}
                disabled={disabled}
            >
                <MenuItem value={-1}>Ciclo actual</MenuItem>
                {menuItemUI}
            </Select>
        </FormControl>
    );
};

export default CyclesSelectOnDirector;
