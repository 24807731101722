import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Typography, Avatar } from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectBestScoreStudents,
} from '../../../store/home/fetchSlice';
import { getBestScoreStudents } from '../../../store/home/thunks';
import { selectComponentHomeBestScoreStudentsStatusServer } from '../../../store/home/componentStatusSelectors';
import { SkeletonCard } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import DEFAULT_AVATAR_URL from './../../../../assets/images/Unknown_person.jpeg';

const BestScoreStudentCard = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeBestScoreStudentsStatusServer
    );

    const bestScoreStudents = useFetchResource(
        () =>
            getBestScoreStudents({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectBestScoreStudents,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getBestScoreStudents({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectBestScoreStudents,
            [cycleSelected, schoolId]
        );
    };

    const avatarSrc = bestScoreStudents.value?.avatar || DEFAULT_AVATAR_URL;

    return (
        <Box>
            {fetchingStatus === 'pending' && <SkeletonCard items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    style={{
                        backgroundColor: 'rgb(19, 222, 185)',
                        borderRadius: '10px',
                        textAlign: 'center',
                        minHeight: '200px',
                        minWidth: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: 'none',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        margin: 'auto',
                    }}
                >
                    <Avatar
                        src={avatarSrc}
                        alt="Avatar Image"
                        sx={{
                            width: 60,
                            height: 60,
                            marginBottom: '10px',
                            borderRadius: '50%',
                            border: '3px solid #ffffff',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        }}
                    />
                    <CardContent
                        style={{
                            padding: '0',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            width: '100%',
                            height: 'auto',
                        }}
                    >
                        <Typography
                            variant="h6"
                            style={{
                                color: 'rgb(230, 255, 250)',
                                marginBottom: '8px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {/* {bestScoreStudents?.title || 'Alumno con mejor promedio'} */}
                            {'Mejor promedio'}
                        </Typography>
                        <Typography
                            variant="body1"
                            style={{
                                color: 'rgb(230, 255, 250)',
                                marginBottom: '4px',
                            }}
                        >
                            {bestScoreStudents.value?.data[0] ||
                                'Nombre del estudiante'}
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{
                                color: 'rgb(230, 255, 250)',
                                marginBottom: '4px',
                            }}
                        >
                            {bestScoreStudents.value?.data[1] || 'Grupo'}
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{
                                color: 'rgb(230, 255, 250)',
                            }}
                        >
                            {bestScoreStudents.value?.data[2] || 'Promedio'}
                        </Typography>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default BestScoreStudentCard;
