import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as _ from 'lodash';
import { selectStudentIdSelected } from "./uiSlice";
import Connection from "../../../service/Connection"
import Feedback from "../../../service/Feedback";
import { PROFESSORS_STUDENT_REPORT_EXPIRE_TIME } from "../../../service/const";
import { addOneFile, setManyFiles, upsertFile } from "./entitiesSlice";

const emptyState = {
}

/**
 * Slice para el settings UI
 */
export const studentItemsSlice = createSlice({
    name: 'professorModule/studentsItems',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, { payload }) => {
            state[payload].fetch.didInvalidate = true
        },
        addStudentsItem: (state, action) => {
            if (!state[action.payload.student_id]) {
                state[action.payload.student_id] = action.payload
            }
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })

        ////////////////// RECUPERAR LOS REPORTES /////////////////////

        builder.addCase(fetchStudentReport.rejected, (state, { meta }) => {
            const studentId = meta.arg
            state[studentId].fetch.status = 'rejected'
        })

        builder.addCase(fetchStudentReport.fulfilled, (state, { meta }) => {
            const studentId = meta.arg

            state[studentId].fetch.expireIn = (new Date()).setMinutes(((new Date()).getMinutes() + PROFESSORS_STUDENT_REPORT_EXPIRE_TIME))
            state[studentId].fetch.fetchingAt = Date.now()
            state[studentId].fetch.didInvalidate = false
            state[studentId].fetch.status = 'fulfilled'
        })

        builder.addCase(fetchStudentReport.pending, (state, { meta }) => {
            const studentId = meta.arg
            state[studentId].fetch.status = 'pending'
        })

        //////////////////// GUARDAR LOS REPORTES ////////////////////

        builder.addCase(storeReports.rejected, (state, { meta }) => {
            const { studentId } = meta.arg

            state[studentId].fetch.operation = 'rejected'
        })
        builder.addCase(storeReports.fulfilled, (state, { meta }) => {
            const { studentId } = meta.arg

            state[studentId].fetch.operation = 'fulfilled'
        })
        builder.addCase(storeReports.pending, (state, { meta }) => {
            const { studentId } = meta.arg

            state[studentId].fetch.operation = 'pending'
        })
    }
});

export const { addStudentsItem, invalidate } = studentItemsSlice.actions;

export default studentItemsSlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 * 
 * @param {*} state 
 * @returns 
 */
export const selectProfessorsStudentsItems = (state) => state.professorModule.studentsItems;


export const selectStudentItemSelected = (state) => selectProfessorsStudentsItems(state)[selectStudentIdSelected(state)]


/**
* Selector para recuperar el estado de operation del elemento seleccionado
* 
* @param {*} store 
* @returns 
*/
export const selectStudentItemOperationStatusSelected = (state) => selectStudentItemSelected(state)?.fetch.operation


/**
* Selector para recuperar el estado de fetching del elemento seleccionado
* 
* @param {*} store 
* @returns 
*/
export const selectStudentItemFetchStatusSelected = (state) => selectStudentItemSelected(state)?.fetch.status



//////////////// TRUNCKS /////////////////

/**
 * Crea un elemento de student
 * 
 * @param {*} student 
 * 
 * @returns 
 */
export const getEmptyStudentItem = (student) => {
    return (
        {
            student_id: student.student_id,
            fetch: {
                state: 'idel',
                operation: 'idel',
                didInvalidate: false,
                expireIn: null,
                fetchingAt: null
            }
        }
    )
}

/**
 * Crea o actualiza todas las calificaciones
 */
export const fetchStudentReport = createAsyncThunk(
    'professorsModule/studentsItems/fetchStudentReport',
    async (studentId, thunkAPI) => {

        let FeedbackService = new Feedback()

        try {
            const files = await Connection.getInactiveFilesByStudent(studentId).then(res => res.data.data)

            thunkAPI.dispatch(setManyFiles(files))

            /*
            thunkAPI.dispatch(addStudentsReport({
                [studentId]: {
                    student_id: studentId,
                    files: files.map(item => item.file_id)
                }
            }));
            */

            return {
                files
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            const studentItem = selectStudentItemSelected(getState())

            let { didInvalidate, expireIn } = studentItem.fetch
            const valid = expireIn > Date.now()

            if (!didInvalidate && valid) {
                return false
            }

        }
    }
)

/**
 * Crear expediantes y reportes
 */
export const storeReports = createAsyncThunk(
    'professorsModule/studentsItems/storeReports',
    async (data, thunkAPI) => {
        let { studentId, reports, group_id } = data

        let FeedbackService = new Feedback()

        try {
            const reportsRequest = reports.map(async(item) => {
                return await Connection.storeReports( item.user_id, studentId, {
                    catalog_report_id: item.catalog_report_id,
                    description: item.description,
                    group_id: group_id
                })
            })
            let reportsResponse = await Promise.all(reportsRequest)
            return {
                reportsResponse
            }

        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)

