import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Feedback from '../../../service/Feedback';
import Connection from '../../../service/Connection';
import { PROFESSORS_HOME_EXPIRE_TIME } from '../../../service/const';
import { upsertManyNotices } from '../../../store/slices/entities/notices';
import { upsertManyNotiifications } from '../../../store/slices/entities/notifications';
import { upsertManyStudents } from '../../../store/slices/entities/students';
import { addItem } from '../assistanceDetails';
import { setAllAssistanceItems } from './itemsSlice';
import { v4 as uuidv4 } from 'uuid';
import { upsertManyUsers } from '../../../store/slices/entities/users';

let emptyState = {
    expireIn: null,
    ferchingAt: null,
    groups: null,
    subjects: null,
    status: 'idle',
    operation: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

export const fetchSlice = createSlice({
    name: 'fetch',
    initialState: {},
    reducers: {
        invalidateAssistance: (state, action) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        builder.addCase(fetchAssistsNoticesData.fulfilled, (state, action) => {
            state.expireIn = new Date().setMinutes(
                new Date().getMinutes() + PROFESSORS_HOME_EXPIRE_TIME
            );
            state.ferchingAt = Date.now();
            state.didInvalidate = false;
            state.status = 'fulfilled';
        });

        builder.addCase(fetchAssistsNoticesData.pending, (state, action) => {
            state.status = 'pending';
        });

        builder.addCase(fetchAssistsNoticesData.rejected, (state, action) => {
            state.status = 'rejected';
        });
    },
});

export const { invalidateAssistance } = fetchSlice.actions;

export default fetchSlice.reducer;

export const selectAssistanceFetch = (state) => state.notices.assistance.fetch;

export const selectFetchingStatus = (state) =>
    selectAssistanceFetch(state).status;

export const fetchAssistsNoticesData = createAsyncThunk(
    'notices/fetch/assists',
    async (data, thunkAPI) => {
        const { schoolId, type } = data;

        let FeedbackService = new Feedback();

        try {
            let notices = await Connection.getNoticesByType(
                schoolId,
                type
            ).then((i) => i.data.data);

            let notifications = [];
            let users = [];
            for (let notice of notices) {
                let noticeNotifications =
                    await Connection.getNotificationsByNotice(
                        notice.notice_id
                    ).then((i) => i.data.data);
                notifications = notifications.concat(noticeNotifications);

                let noticeUsers = await Connection.getUserById(
                    notice.user_id
                ).then((i) => i.data.data);
                users = users.concat(noticeUsers);
            }

            let viewModels = notices.map((notice) => {
                return {
                    id: uuidv4(),
                    ui: {
                        notice_id: notice.notice_id,
                    },
                    operations: {
                        sent: {
                            expireIn: null,
                            fetchingAt: null,
                            status: 'idle',
                            didInvalidate: true,
                        },
                        noticed: {
                            expireIn: null,
                            fetchingAt: null,
                            status: 'idle',
                            didInvalidate: true,
                        },
                        read: {
                            expireIn: null,
                            fetchingAt: null,
                            status: 'idle',
                            didInvalidate: true,
                        },
                    },
                };
            });

            let groupsIds = viewModels.map(({ id }) => {
                return id;
            });

            let students = [];
            for (let notification of notifications) {
                if (
                    notification.student_id !== 0 &&
                    notification.student_id !== null &&
                    notifications.length > 0
                ) {
                    let notificationStudent = await Connection.getStudentById(
                        notification.student_id
                    ).then((i) => i.data.data);
                    students = students.concat(notificationStudent);
                }
            }

            thunkAPI.dispatch(upsertManyNotices(notices));
            thunkAPI.dispatch(upsertManyNotiifications(notifications));
            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyUsers(users));
            thunkAPI.dispatch(addItem(viewModels));
            thunkAPI.dispatch(setAllAssistanceItems(groupsIds));
        } catch (err) {
            console.log(err, 'errr');
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectAssistanceFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
