import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
//import PaginationAllDates from "./PaginationDates";

const Survey = ({ questions, title }) => {
    // const [answers, setAnswers] = useState([]);
    // const [currentDate, setCurrentDate] = useState("");
    const [dates, setDates] = useState([])
    const [date, setDate] = useState("")
    const [newQuestions, setNewQuestions] = useState([])

    const handleChange = (e) => {
        setDate(e.target.value)
    }

    const getDates = () => {
        let fechas = []
        questions.forEach((question) => {
            //aqui se obtienen todas las fecha de las respuestas
            if (question.type === 1) {
                question.respuesta.forEach((respuesta) => fechas.push(respuesta.created_at.split(" ")[0]))
            } else {
                question.catalogo.forEach((respuesta) => respuesta.respuesta.forEach((res) => fechas.push(res.created_at.split(" ")[0])))
            }
        })
        //se remueven las fechas repeditas
        let removeDuplicates = fechas.filter((date, i) => fechas.indexOf(date) === i)

        //ordenar las fechas de mayor a menor
        return removeDuplicates.sort(function (a, b) {
            a = a.split('/').reverse().join('');
            b = b.split('/').reverse().join('');
            return a > b ? 1 : a < b ? -1 : 0;
        })
    }

    const modifyQuestionArray = () => {
        //funcion para filtrar respuestas en base al input de fecha
        let filteredAnswer = questions.map((question) => {

            //para texto en el if y para opcion multiple en el else if
            if (question.type === 1) {
                let filter = question.respuesta.filter((element) => element.created_at.split(" ")[0] === date)
                return {
                    ...question, answer: filter
                }
            } else if (question.type === 2 || question.type === 3) {
                let filteredCatalogo = question.catalogo.map((item) => {
                    let filteredRespuestas = item.respuesta.filter(
                        (respuesta) => respuesta.created_at.split(" ")[0] === date
                    );
                    return {
                        ...item,
                        respuesta: filteredRespuestas
                    };
                });

                return {
                    ...question,
                    catalogo: filteredCatalogo
                };
            }
        })
        setNewQuestions(filteredAnswer)
    }

    useEffect(() => {
        modifyQuestionArray()
    }, [date])

    useEffect(() => {
        let filteredDates = getDates()
        setDates(filteredDates)

        //funcion para obtener la ultima fecha del array ordenado
        let lastDate = filteredDates[filteredDates.length - 1]
        setDate(lastDate)

        // let ans = questions
        //     .map((I) => (I.type === 1 ? [I.respuesta] : I.catalogo.map((c) => c.respuesta)))
        //     .flat()
        //     .filter((i) => i !== undefined)
        //     .flat();
        //setAnswers(ans);
    }, []);


    return (
        <Card sx={{ minHeight: 376 }} overflow="unset">
            <CardHeader title={title} titleTypographyProps={{ sx: { fontSize: 24 } }} sx={{ paddingBottom: 1 }} />
            <CardContent>
                <FormControl sx={{ marginTop: 1 }} fullWidth>
                    <InputLabel id="select-label">Seleccione una Fecha</InputLabel>
                    <Select
                        labelId="select-label"
                        id="demo-simple-select"
                        value={date}
                        label="FeSeleccione una fechacha"
                        onChange={handleChange}
                    >
                        {dates && dates.map((date, i) => (
                            <MenuItem key={i} value={date}>{date}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {newQuestions.length > 0 && date !== "" ? (
                    <Stack direction="column">
                        {newQuestions.map((questionmap) => {
                            return (
                                <Accordion key={questionmap.question_id}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography sx={{ fontSize: '1.2rem', fontFamily: 'Helvetica' }}>
                                            {questionmap?.question}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {questionmap.type === 1 ? (
                                            <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', ml: 2, mb: 2, mt: 1 }}>
                                                {questionmap.respuesta === null ? (
                                                    "No existe Respuesta"
                                                ) : (
                                                    <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', display: 'flex', alignItems: 'center', padding: 1 }}>
                                                        {questionmap.answer[0]?.answer}
                                                    </Typography>
                                                )}
                                            </Typography>
                                        ) : (
                                            questionmap.catalogo.length ? (
                                                questionmap.catalogo.map((catalogomap) => {
                                                    return (
                                                        <Typography sx={{ fontSize: '1rem', fontWeight: catalogomap.respuesta ? 'bold' : '', display: 'flex', alignItems: 'center', padding: 1 }} key={catalogomap.title}>
                                                            {catalogomap.respuesta.length > 0 ? (
                                                                <CheckCircleOutlineOutlinedIcon fontSize="medium" sx={{ marginRight: 1 }} style={{ color: 'green' }} />
                                                            ) : (
                                                                <RadioButtonUncheckedIcon fontSize="medium" sx={{ marginRight: 1 }} style={{ color: 'red' }} />
                                                            )}
                                                            {catalogomap.title}
                                                        </Typography>
                                                    )
                                                })
                                            ) : (
                                                <Typography>
                                                    <RadioButtonUncheckedIcon fontSize="medium" /> Catálogo aún no definido
                                                </Typography>
                                            )
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </Stack>
                ) : (
                    <Typography>
                        No hay preguntas disponibles
                    </Typography>
                )}

            </CardContent>
        </Card>
    );
}

export default Survey;
