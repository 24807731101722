import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Grid, Switch, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useParams } from "react-router";
//import TutorModal from '../../components/Modal/ModalAgregarTutor';

import service from '../../service/Connection';
import { param } from "jquery";
import { DataGrid } from "@mui/x-data-grid";
import { margin } from "@mui/system";

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const AlumnosGroup = () => {

  let { id } = useParams();
  const [cargar, setCargar] = useState(false);
  const [alumnos, setAlumnos] = useState([]);
  const [alumnoInfo, setAlumnoInfo] = useState([]);
  const [idAlumnoT, setIdAlumnoT] = useState([]);

  const [openTutor, setOpenTutor] = useState(false);
  const [openAlumno, setOpenAlumno] = useState(false);




  useEffect(() => {

    const fetchData = async () => {

      try {
        let alumnosData = await service.StudentsByGroup(id);

        setAlumnos(alumnosData.data.data);
        setCargar(true);
      } catch (err) {
        console.log(err)
      }


    };

    fetchData();
  }, []);


  const columns = [
    {
      field: 'avatar',
      headerName: '',
      width: 100,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (

        <Avatar alt={params.value.name} ></Avatar>
      ),
    },
    {
      field: 'idUsuario',
      headerName: 'ID',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'matricula',
      headerName: 'Matrícula',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'nombre',
      headerName: 'Nombre',
      width: 250,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (

        <Button style={{ textTransform: 'none' }} onClick={() => { setOpenAlumno(true); setAlumnoInfo(params.value) }}>
          {params.value.name + " " + params.value.last_name + " "}
        </Button>
      ),
    },
    {
      field: 'tutor',
      headerName: 'Tutor',
      width: 250,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (

        <Button style={{ textTransform: 'none' }} onClick={() => { setOpenTutor(true); setIdAlumnoT(params.value.student_id) }}>
          Tutor
        </Button>
      ),
    },
    {
      field: 'acciones',
      headerName: 'Acciones',
      width: 300,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <>
          <Button style={{ textTransform: 'none', marginLeft: 10 }} variant="contained">Dar de baja</Button>
          <Button style={{ textTransform: 'none', marginLeft: 10 }} variant="contained">Sacar del grupo</Button>
        </>
      ),

    },
  ];

  const dataGrid = alumnos?.map(row => {
    return {
      id: row.student_id,
      avatar: row,
      idUsuario: row.student_id,
      matricula: row.folio,
      nombre: row,
      tutor: row,
      acciones: row.status,
    }
  });


  return (
    <>

      {/*<AlumnoModal 
          title="Actualizar Alumno"
          openModal={openAlumno}
          setOpenModal={setOpenAlumno}
          other={[]}
          >
        </AlumnoModal>

        <TutorModal 
          title="Agregar Tutor"
          openModal={openTutor}
          setOpenModal={setOpenTutor}
          other={[]}
          >
        </TutorModal>

        */}


      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xl={12} md={12} sm={12}>
            <Typography variant="h6" component="div" style={{ width: '100%', padding: 10 }} >
              Información del grupo
            </Typography>
          </Grid>
          {cargar ? (
            <>
              <Grid item xl={12} sm={12} md={12} xs={12} style={{ textAlign: "center", marginTop: "10px" }}>
                <DataGrid
                  rows={dataGrid}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  disableSelectionOnClick
                  autoHeight

                />
              </Grid>
            </>
          ) : (
            <></>
          )}

        </Grid>


      </Box>



    </>
  );
};

export default AlumnosGroup;
