import { Avatar } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useAuth } from "../../hooks";
import { selectOneSettingBySchoolId } from "../../store/slices/entities/settings";

/**
 * Componente DrawerHeader estilizado
 */
const CustomeDrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    marginBottom: 15,
    marginTop: 15
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
}));

/**
 * Header del drawer layout
 * 
 * @returns 
 */
const DrawerHeader = () => {

    const Auth = useAuth();
    const user = Auth.getUser()
    const schoolId = Auth.getUser().school_id

    /////////////////// SHARES STATE /////////////////

    const logo = useSelector(selectOneSettingBySchoolId(schoolId, 'theme-logo-school'));

    return (
        <CustomeDrawerHeader>
            <Avatar sx={{ width: 46, height: 46 }} src={logo?.value} alt="I">
                I
            </Avatar>
        </CustomeDrawerHeader>
    )
}

export default DrawerHeader