import React, { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Select,
    MenuItem,
    FormControl,
    TextField,
    InputAdornment,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Card,
    CardActions,
    Tooltip,
    CardContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCatConcepts } from '../../../store/cat_concepts/fetchSlice';
import { useAuth, useFeedback } from '../../../../hooks';
import { useTheme } from '@emotion/react';
import { selectCatConcepts } from '../../../store/cat_concepts/selectors';
import {
    AddCircleOutline,
    AddCircleOutlineOutlined,
    AddIcCallOutlined,
} from '@mui/icons-material';
import { BookmarkAddOutlined, BookmarkAddRounded } from '@mui/icons-material';
import { DataGrid, esES } from '@mui/x-data-grid';
import CreateCatalog from './CreateCatalog';
import { DeleteCatConcepts } from '../../../store/cat_concepts/operations';
import Feedback from '../../../../service/Feedback';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import useThemeColors from '../../../../theme/themes';
import ExportButton from '../../../components/exportButton';
import CustomPagination from '../../../components/CustomPagination';
import DeleteSweepTwoToneIcon from '@mui/icons-material/DeleteSweepTwoTone';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';

const CatalogModal = ({ open, onclose }) => {
    ///////////////// LOCAL STATE /////////////

    const [openModal, setOpenModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);

    ///////////// SHERD STATE /////////////

    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const feedbackApp = useFeedback();

    const catConcepts = useSelector(selectCatConcepts);
    const colors = useThemeColors();

    ///////////// HANDLERS /////////////

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const handleDelete = async (id) => {
        await dispatch(DeleteCatConcepts({ catalogconcepts_id: id }))
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Catalogo inactivado con exito',
                });
            })
            .catch((error) => {
                feedbackApp.showFeedback({ title: error.feedback.title });
            });
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value); // Actualiza el texto de búsqueda
    };
    ///////////// FUNCTIONS /////////////

    // Filtra los conceptos basados en el texto de búsqueda
    const filteredConcepts = catConcepts
        .filter((concept) => concept.active)
        .filter((concept) =>
            Object.values(concept)
                .join(' ')
                .toLowerCase()
                .includes(searchText.toLowerCase())
        );

    ///////////// RENDER /////////////

    // Define las columnas del DataGrid
    const columns = [
        {
            field: 'concept_catalog_id',
            headerName: 'Folio',
            flex: 0.5,
            width: 100,
            renderCell: (params) => (
                <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                    #{params.value}
                </Typography>
            ),
        },
        { field: 'concept', headerName: 'Concepto', width: 200, flex: 1 },
        {
            field: 'value',
            headerName: 'Importe',
            flex: 1,
            align: 'right',
            headerAlign: 'right',

            renderCell: (params) => (
                <>
                    <AttachMoneyOutlinedIcon
                        sx={{
                            color: colors.orange[500],
                            fontSize: '1.2rem',
                        }}
                    />
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            textAlign: 'right',
                            width: 'auto',
                            fontSize: '1rem',
                        }}>
                        {params.value}
                    </Typography>
                </>
            ),
        },
        {
            field: 'actions',
            headerName: 'Acciones',
            flex: 0.5,
            renderCell: (params) => (
                <Tooltip title={'Eliminar Catálogo'} arrow>
                    <IconButton
                        onClick={() =>
                            handleDelete(params.row.concept_catalog_id)
                        }
                        sx={{ color: colors.red[500] }}>
                        <DeleteSweepTwoToneIcon />
                    </IconButton>
                </Tooltip>
            ),
        },
    ];

    const rows = filteredConcepts
        .map((catalog) => ({
            id: catalog.concept_catalog_id,
            ...catalog,
        }))
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    return (
        <>
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onclose();
                    }
                }}
                maxWidth="lg"
                fullWidth
                disableEscapeKeyDown
                sx={{
                    '& .MuiDialog-paper': {
                        padding: 5,
                        borderRadius: 2,
                    },
                }}>
                <DialogTitle
                    sx={{
                        textAlign: 'center',
                        position: 'relative',
                    }}>
                    <Typography variant="h4" component="div">
                        Catalogo de Conceptos
                    </Typography>
                    <Typography variant="body2">
                        Aquí podras observar el catalogo de los conceptos
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onclose}
                        sx={{
                            position: 'absolute',
                            right: 6,
                            top: 6,
                            color: theme.palette.grey[500],
                        }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Card
                        sx={{
                            width: '119%',
                            borderRadius: 2,
                            boxShadow: 0,
                            backgroundColor: theme.palette.background.default,
                            color: theme.palette.neutral.main,
                            maxWidth: '100%',
                            mb: 2,
                        }}>
                        <CardContent>
                            <Box
                                display="flex"
                                flexWrap="wrap"
                                justifyContent="space-between"
                                alignItems="center"
                                mb={2}>
                                <Tooltip title="Crea un nuevo catalogo de conceptos">
                                    <Button
                                        onClick={handleOpen}
                                        variant="contained"
                                        startIcon={<AddCircleOutlineOutlined />}
                                        sx={{
                                            flexBasis: { xs: '90%', sm: '30%' },
                                            minWidth: { xs: '90%', sm: '30%' },
                                            mb: { xs: 2, sm: 0 },
                                        }}>
                                        Añadir Catálogo
                                    </Button>
                                </Tooltip>
                                <TextField
                                    variant="outlined"
                                    placeholder="Buscar un conceptos"
                                    size="small"
                                    value={searchText}
                                    onChange={handleSearchChange}
                                    sx={{
                                        minWidth: { xs: '90%', sm: '30%' },
                                        backgroundColor:
                                            theme.palette.background.paper,
                                        mr: 2,
                                    }}
                                />
                            </Box>

                            <Box
                                display="grid"
                                gridTemplateColumns={{ xs: '1fr', md: 'auto' }}
                                gap={2}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    autoHeight
                                    pageSize={10}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    disableColumnReorder
                                    localeText={
                                        esES.components.MuiDataGrid.defaultProps
                                            .localeText
                                    }
                                    components={{
                                        Toolbar: () => (
                                            <ExportButton fileName="Catalogo de Conceptos" />
                                        ),
                                        Pagination: CustomPagination,
                                    }}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
            <CreateCatalog open={openModal} onclose={handleClose} />
        </>
    );
};

export default CatalogModal;
