import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';

import Connection from '../../../service/Connection';
import * as constants from '../../../service/const';
import Feedback from '../../../service/Feedback';
import Authentication from '../../../service/Login';
import FeatureFlags from '../../../service/FeatureFlags';

import { upsertManyGroup } from '../../../store/slices/entities/groups';
import { upsertManyReports } from '../../../store/slices/entities/reports';
import { addItem } from '../group';
import { upsertManyUsers } from '../../../store/slices/entities/users';
import { upsertManyReportsCatalogs } from '../../../store/slices/entities/reportscatalogs';
import { upsertManyStudents } from '../../../store/slices/entities/students';
import { upsertManyFiles } from '../../../store/slices/entities/files';

import { setAllItems } from './itemsSlice';

import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { selectReportHome } from './selectors';

const emptyState = {
    expireIn: null,
    ferchingAt: null,
    status: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

export const fetchSlice = createSlice({
    name: 'fetch',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state) => {
            state.didInvalidate = true;
        },
    },

    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        /**
         * El flujo de recuperacion de datos termina correctamente
         */
        builder.addCase(loadUI.fulfilled, (state) => {
            state.expireIn = new Date().setMinutes(
                new Date().getMinutes() + constants.REPORTESUI_EXPIRE_TIME
            );
            state.ferchingAt = Date.now();
            state.didInvalidate = false;

            state.status = 'fulfilled';
        });

        /**
         * El flujo de recuperacion de datos esta por comenzar
         */
        builder.addCase(loadUI.pending, (state) => {
            state.status = 'pending';
        });

        /**
         * El flujo de recuperacion de datos fue rechazado
         */
        builder.addCase(loadUI.rejected, (state, action) => {
            state.status = 'rejected';
            state.feedback = action.payload.feedback;
        });
    },
});

export const { invalidate } = fetchSlice.actions;

export default fetchSlice.reducer;

/**
 * Selector para recuperar fetch
 */
export const selectFetch = createSelector(
    selectReportHome,
    (reports) => reports.fetch
);

/**
 * Selector para recuperar el estatus de fetch
 */
export const selectFetchStatus = createSelector(
    selectFetch,
    (fetch) => fetch.status
);

/**
 * Cargar informacion de la UI
 */
export const loadUI = createAsyncThunk(
    'newReports/home/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            const catalogs = await Connection.getReportCatalogBySchool(
                schoolId
            ).then((i) => i.data.data);

            if (FeatureFlags.isFeatureFlagActive('NEW_REPORTS_STRCUTURE')) {
                const groups = await Connection.getResourcesPaginatedFromServer(
                    'groupsBySchool',
                    [schoolId]
                ).then((i) => i.data);

                let filteredGroups = groups.filter(
                    (group) =>
                        group.assesor_id !== 0 &&
                        group.total_students_reported > 0 &&
                        group.students > 0 &&
                        group.subjects > 0
                );

                let viewModels = filteredGroups.map((group) => {
                    return {
                        id: uuidv4(),
                        ui: {
                            group_id: group.group_id,
                            studentSelected: null,
                        },
                        fetch: {
                            expireIn: null,
                            ferchingAt: null,
                            status: 'pending',
                            didInvalidate: true,
                        },
                        items: [],
                    };
                });

                let groupsIds = viewModels.map(({ id }) => {
                    return id;
                });

                thunkAPI.dispatch(upsertManyGroup(filteredGroups));
                thunkAPI.dispatch(addItem(viewModels));
                thunkAPI.dispatch(setAllItems(groupsIds));
            } else {
                let reports = await Connection.getResourcesPaginatedFromServer(
                    'getReportBySchool',
                    [schoolId]
                ).then((i) => i.data);
                const assesors = await Connection.getAssesorsBySchool(
                    schoolId
                ).then((i) => i.data.data);

                const professorsList = await Connection.professorsBySchool(
                    schoolId
                ).then((i) => i.data.data);

                let students = [];
                let files = [];
                let reporters = [];

                for (let report of reports) {
                    let file = files.find((i) => i.file_id === report.file_id);

                    if (!file) {
                        file = await Connection.getFilesByReport(
                            report.report_id
                        ).then((i) => i.data.data);

                        files.push(file);
                    }

                    let student = students.find(
                        (i) => i.student_id === file.student_id
                    );

                    if (!student) {
                        let student = await Connection.getStudentByFile(
                            file.file_id
                        ).then((i) => i.data.data);

                        students.push(student);
                    }

                    let reporter = reporters.find(
                        (i) => i.professor_id === report.profesor_id
                    );

                    if (!reporter) {
                        let reporter = professorsList.find(
                            (i) => i.professor_id === report.profesor_id
                        );

                        reporters.push(reporter);
                    }
                }

                let fullUsers = reporters.concat(assesors);

                let users = _.uniqBy(
                    fullUsers.map((i) => {
                        let userId = Auth.getUserID(i);

                        return {
                            ...i,
                            user_id: userId,
                        };
                    }),
                    'user_id'
                );

                thunkAPI.dispatch(upsertManyUsers(users));
                thunkAPI.dispatch(upsertManyStudents(students));
                thunkAPI.dispatch(upsertManyFiles(files));
                thunkAPI.dispatch(upsertManyReports(reports));
            }

            thunkAPI.dispatch(upsertManyReportsCatalogs(catalogs));

            return {
                catalogs,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
