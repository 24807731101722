import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { selectUserById } from '../../../store/slices/entities/users';
import { selectRelationshipsById } from '../../../store/slices/entities/relationships';
import { LoadingButton } from '@mui/lab';
import {
    deleteRelationship,
    selectActivateRemoveOneRelationship,
} from '../../../store/slices/studentsUI/operationsSlice';
import useFeedback from '../../../hooks/useFeedback';

const DeleteParentModal = ({
    openModal,
    setOpenModal,
    userId = null,
    relationshipId = null,
}) => {
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    //////////// SHARES STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));

    //const student = useSelector(selectStudentById(studentId))
    const user = useSelector(selectUserById(userId));
    const relationship = useSelector(selectRelationshipsById(relationshipId));
    const statusOperation = useSelector(selectActivateRemoveOneRelationship);

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    const [userData, setUserData] = useState({
        name: '',
        last_name: '',
        second_last_name: '',
        password: '',
        password_confirmation: '',
        enrollment: '',
        email: '',
        cellphone: '',
        street: '',
        colony: '',
        outside_number: '',
        inside_number: '',
        zipcode: '',
        country_code: '12',
        group_id: '',
    });

    //const [loading, setLoading] = useState(false);

    const resetUserData = () => {
        setUserData({
            name: '',
            last_name: '',
            second_last_name: '',
            password: '',
            password_confirmation: '',
            enrollment: '',
            email: '',
            cellphone: '',
            street: '',
            colony: '',
            outside_number: '',
            inside_number: '',
            zipcode: '',
            country_code: '12',
            group_id: '',
        });
    };

    useEffect(() => {
        if (!openModal) {
            resetUserData();
        } else {
            setUserData(user);
        }
    }, [openModal]);

    const removeRelationship = () => {
        dispatch(deleteRelationship(relationship.relationship_id))
            .unwrap()
            .then((res) => {
                feedbackApp.showFeedback({
                    title: 'Parentesco eliminado',
                });
                handleClose();
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }

        setOpenModal(false);
    };

    return (
        <Dialog
            open={openModal}
            maxWidth={false}
            PaperProps={{
                sx: {
                    width: '650px', // Modificar el ancho en píxeles
                    maxHeight: '600px', // Modificar el alto en píxeles
                },
            }}
            onClose={handleClose}
        >
            <DialogTitle
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ flexGrow: 1 }}
                    >
                        Eliminar parentesco
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
                Desea eliminar el parentesco de{' '}
                {<strong>{userData.name}</strong>}{' '}
                {<strong>{userData.last_name}</strong>}{' '}
                {
                    <strong>
                        {userData.second_last_name === null
                            ? ''
                            : userData.second_last_name}
                    </strong>
                }{' '}
                ?
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={statusOperation === 'pending'}
                    onClick={() => {
                        setOpenModal(false);
                    }}
                >
                    Cerrar
                </Button>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={removeRelationship}
                    loading={statusOperation === 'pending'}
                    loadingPosition="start"
                    startIcon={<DeleteIcon />}
                    variant="contained"
                >
                    Eliminar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteParentModal;
