import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

/**
 * Adaptador para grupos
 */
const usersAdapter = createEntityAdapter({
    selectId: (group) => group.user_id,
    sortComparer: (a, b) => a.user_id - b.user_id 
})

/**
 * Slice para la entidades de alumnos
 */
const  usersSlice = createSlice({
    name: "noticesModalUI/entities/users",
    initialState: usersAdapter.getInitialState(),
    reducers: {
        addOneUser: usersAdapter.addOne,
        addManyUsers: usersAdapter.addMany,
        upsertManyUsers: usersAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
                    
            return usersAdapter.getInitialState()
        })
        /**
         * Limpiar los datos al cerar el modal
         */
        builder.addCase('noticesModalUI/reset', (state, action) => usersAdapter.getInitialState())
    }
})

export const { addOneUser, addManyUsers, upsertManyUsers } = usersSlice.actions

//////////////////////// SELECTORES ///////////////////////

const globalizedSelectors = usersAdapter.getSelectors((state) => state.noticesModalUI.entities.users)

/**
 * Selecciona todos los grupos
 */
export const selectAllUsers = (store) => globalizedSelectors.selectAll(store)

/**
 * Selecciona todos los grupos
 */
 export const selectUsers = (store) => globalizedSelectors.selectEntities(store)


export default usersSlice.reducer