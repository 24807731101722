import { Button, ListItem, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material"
import _ from "lodash"
import { useState } from "react"

/**
 * Componente que muestra una materia fuente 
 * 
 * @param {*} param0 
 * @returns 
 */
const SourceSubjectListItem = ({ subject, availableSubjects, onSelectSubject }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    ///////////// ACTIONS //////////////

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Cerrar el menu 
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Marcar materia como seleccionada 
     * 
     * @param {*} newSubject 
     */
    const onSelect = (newSubject) => {
        handleClose()
        onSelectSubject(subject, newSubject)
    }

    return (
        <ListItem
            secondaryAction={
                <>
                    <Tooltip title="Seleccionar materia de destino">
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            disabled={subject.target_subject_id}
                            size={"small"}
                        >
                            Seleccionar
                        </Button>
                    </Tooltip>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >

                        <MenuItem disabled={true}>Seleccionar materia</MenuItem>
                        {
                            availableSubjects.map(i => (
                                <MenuItem onClick={() => { onSelect(i) }}>{i.catalog.title}</MenuItem>
                            ))
                        }
                    </Menu>
                </>
            }
        >
            <ListItemText
                primary={`${subject.catalog.folio} ${subject.catalog.title}`}
            />
        </ListItem>
    )
}


export default SourceSubjectListItem