import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Services from '../../../service/Connection';
import { USERSUI_EXPIRE_TIME } from '../../../service/const';
import Feedback from '../../../service/Feedback';
import * as _ from 'lodash';
import Authentication from '../../../service/Login';
import {
    addManyUsers,
    addOneUser,
    setAllUsers,
    upsertOneUser,
} from '../entities/users';
import { getResourcesPaginatedFromServer } from '../../../libs/utils';

const emptyState = {
    data: {},
    server: {
        expireIn: null,
        ferchingAt: null,
        statusServer: 'idle',
        statusOperation: 'idle',
        didInvalidate: true,
        feedback: {
            title: null,
            message: null,
            payload: null,
        },
    },
    ui: {
        'maestros': { columnField: '', operatorValue: '', value: '' },
        'administradores': { columnField: '', operatorValue: '', value: '' },
        'padres': { columnField: '', operatorValue: '', value: '' },
        'orientadores': { columnField: '', operatorValue: '', value: '' },
        'directores': { columnField: '', operatorValue: '', value: '' }
    },
};

/**
 * Slice para el settings UI
 */
export const usersUISlice = createSlice({
    name: 'usersUI',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.server.didInvalidate = true;
        },
        updateFilter: (state, { payload }) => {
            const { value, filter } = payload;
            state.ui = { ...state.ui, [value]: filter };
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        const pendingServerStatus = (state, action) => {
            state.server.statusServer = 'pending';
        };

        /**
         * Termina la carga de informacion
         */
        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.server.expireIn = new Date().setMinutes(
                new Date().getMinutes() + USERSUI_EXPIRE_TIME
            );
            state.server.ferchingAt = Date.now();
            state.server.didInvalidate = false;

            state.server.statusServer = 'fulfilled';
        });
        builder.addCase(loadUI.pending, pendingServerStatus);
        builder.addCase(loadUI.rejected, (state, action) => {
            state.server.statusServer = 'rejected';
            state.server.feedback = action.payload.feedback;
        });

        /////////////////////// ACTUALIZAR USUARIO /////////////////////////

        builder.addCase(updateUser.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled';
        });
        builder.addCase(updateUser.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });

        /////////////////////// ACTUALIZAR USUARIO /////////////////////////

        builder.addCase(createUser.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(createUser.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled';
        });
        builder.addCase(createUser.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });

        /////////////////////// ACTUALIZAR CONTRASEÑA DE USUARIO /////////////////////////

        builder.addCase(updatePasswordUser.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(updatePasswordUser.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled';
        });
        builder.addCase(updatePasswordUser.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });
    },
});

export const { invalidate, updateFilter } = usersUISlice.actions;

export default usersUISlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 *
 * @param {*} state
 * @returns
 */
export const selectUsersUI = (state) => state.usersUI.ui;

export const selectUsersData = (state) => state.usersUI.data;

export const selectUsersServer = (state) => state.usersUI.server;

export const selectStatusServer = (state) => state.usersUI.server.statusServer;

export const selectStatusOperation = (state) =>
    state.usersUI.server.statusOperation;

//////////////// TRUNCKS /////////////////

/**
 * Cargar informacion de la UI
 */
export const loadUI = createAsyncThunk(
    'usersUI/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        let Auth = new Authentication();

        try {
            let professors = await Services.professorsBySchool(schoolId).then(
                (res) => res.data.data
            );
            let parents = await Services.getResourcesPaginatedFromServer(
                'getParentsBySchool',
                [schoolId]
            ).then((res) => res.data);
            let orientador = await Services.getOrientadoresBySchool(
                schoolId
            ).then((res) => res.data.data);
            let administrators = await Services.getAdministratorssBySchool(
                schoolId
            ).then((res) => res.data.data);
            let directors = await Services.getDirectorsBySchool(schoolId).then(
                (res) => res.data.data
            );
            let spokeman = await Services.getVocerosBySchool(schoolId).then(
                (res) => res.data.data
            );

            let allUsers = _.concat(
                professors,
                parents,
                orientador,
                administrators,
                directors,
                spokeman
            );

            let users = allUsers.map((i) => ({
                ...i,
                user_id: Auth.getUserID(i),
            }));

            thunkAPI.dispatch(setAllUsers(users));

            return users;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = getState().usersUI.server;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

/**
 * Crear usuario
 */
export const createUser = createAsyncThunk(
    'usersUI/user/create',
    async ({ type, schoolId, userData }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            let user = null;

            switch (type) {
                case 'ADMINISTER':
                    user = await Services.setUserAdministratorsBySchool(
                        schoolId,
                        userData
                    ).then((res) => res.data.data);
                    break;
                case 'PROFESSOR':
                    user = await Services.setUserProfessorBySchool(
                        schoolId,
                        userData
                    ).then((res) => res.data.data);
                    break;
                case 'ASSESSOR':
                    user = await Services.setUserOrientadorBySchool(
                        schoolId,
                        userData
                    ).then((res) => res.data.data);
                    break;
                case 'PARENT':
                    user = await Services.storeParentSchool(
                        schoolId,
                        userData
                    ).then((res) => res.data.data);
                    break;
                case 'SPOKESMAN':
                    user = await Services.setUserVoceroBySchool(
                        schoolId,
                        userData
                    ).then((res) => res.data.data);
                    break;
                case 'DIRECTOR':
                    user = await Services.setUserDirectorBySchool(
                        schoolId,
                        userData
                    ).then((res) => res.data.data);
                    break;
                case 'ADMINISTRATIVE': //Administrativo
                    throw 'No implementado';
                    //user = await Services.setUserAdministratorsBySchool(schoolId, userData).then(res => res.data.data)
                    break;
                case 'TUTOR': //Tutor
                    throw 'No implementado';
                    //user = await Services.setUserAdministratorsBySchool(schoolId, userData).then(res => res.data.data)
                    break;

                default:
                    throw 'No implementado';
                    break;
            }

            Auth.setUser(user);

            user['user_id'] = Auth.getUserID();

            thunkAPI.dispatch(addOneUser(user));

            return user;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar usuario
 */
export const updateUser = createAsyncThunk(
    'usersUI/user/update',
    async ({ userId, permissions, userData }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            let userPermissionUpdate = await Services.updateUserById(
                userId,
                permissions
            ).then((res) => res.data.data);

            Auth.setUser(userPermissionUpdate);
            let endpoint = Auth.getEndPoint();

            let user = await Services.updateUserData(
                endpoint,
                userId,
                userData
            ).then((res) => res.data.data);

            user['user_id'] = userId;

            thunkAPI.dispatch(upsertOneUser(user));

            return {
                user,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar contraseña
 */
export const updatePasswordUser = createAsyncThunk(
    'usersUI/user/update-password',
    async ({ userId, password, userData }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            let user = await Services.updateUserById(userId, password).then(
                (res) => res.data.data
            );

            Auth.setUser(user);
            user['user_id'] = userId;
            thunkAPI.dispatch(upsertOneUser(user));

            return {
                user,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Inhabilitar usuario
 */
export const disableUser = createAsyncThunk(
    'usersUI/user/disable',
    async ({ userId, user, data }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            Auth.setUser(user);
            let endpoint = Auth.getEndPoint();

            let userRespone = await Services.updateUserData(
                endpoint,
                userId,
                data
            ).then((res) => res.data.data);

            userRespone['user_id'] = userId;

            thunkAPI.dispatch(upsertOneUser(userRespone));
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Activar usuario
 */
export const activateUser = createAsyncThunk(
    'usersUI/user/activate',
    async ({ userId, user, data }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            Auth.setUser(user);
            let endpoint = Auth.getEndPoint();

            let userRespone = await Services.updateUserData(
                endpoint,
                userId,
                data
            ).then((res) => res.data.data);

            userRespone['user_id'] = userId;

            thunkAPI.dispatch(upsertOneUser(userRespone));
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar usuario
 */
export const updateUsername = createAsyncThunk(
    'usersUI/user/update',
    async ({ userId, cellphone }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            let user = await Services.updateUserById(userId, cellphone).then(
                (res) => res.data.data
            );

            user['user_id'] = userId;

            thunkAPI.dispatch(upsertOneUser(user));

            return {
                user,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
