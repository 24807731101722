import React, { useState, useEffect } from 'react'

import { Box, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Tooltip } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Visibility } from '@mui/icons-material';

const InputChange = (props) => {
    const { status } = props
    const [inputUpdated, setInputUpdated] = useState(false)
    const [inputValue, setInputValue] = useState(props.value)
    const [state, setState] = useState(false)

    /**
     * Actualiza el valor de la configuracion 
     * y activa el boton de actualizar
     * 
     * @param {*} $event 
     */
    const onUpdateSetting = ($event) => {
        const value = $event.target.value

        setInputUpdated(true)
        setInputValue(value)
    }

    /**
     * Indica que la actualizacion del input
     * ha terminado
     */
    const saveSetting = () => {
        setState('pending')
        props.onChangeSetting(inputValue, props.settingKey)
    }

    /////////////////// VALDIACION /////////////////////

    const valid = () => {
        return {
            invalid: inputValue == null || inputValue == '',
            message: "Campo requerido o incorrecto"
        }
    }

    /**
     * Escuchamos las propiedades en busca de una
     * actualizacio
     */
    useEffect(() => {
        if ((status == 'rejected' || status == 'fulfilled') && state == 'pending') {
            setState('idle')
            setInputUpdated(false)
        }
    }, [props]);

    return (
        <FormControl variant={props.inputProps.variant} fullWidth={true}>
            <InputLabel id={props.inputProps.id} shrink={inputValue}>{props.inputProps.label}</InputLabel>
            <OutlinedInput
                id={props.inputProps.id}
                labelId={props.inputProps.id}
                value={inputValue}
                onChange={onUpdateSetting}
                name={props.inputProps.name}
                type="text"
                fullWidth={true}
                endAdornment={
                    inputUpdated ? <InputAdornment position="end">
                        <LoadingButton
                            color="primary"
                            onClick={saveSetting}
                            loading={state == 'pending'}
                            loadingPosition="center"
                            variant="text"
                            disabled={valid().invalid}
                        >
                            <SaveIcon />
                        </LoadingButton>
                    </InputAdornment> : <></>
                }
                label={props.inputProps.label}
            />
            <FormHelperText> {valid().invalid ? valid().message : props.tooltip}</FormHelperText>
        </FormControl>
    )
}


export default InputChange