import React, { useEffect, useState } from 'react';
import {
    DialogContent,
    Divider,
    Grid,
    Stack,
    TextField,
    Container,
    Typography,
} from '@mui/material';
import { Transfer } from 'antd';
import { getLevelName } from '../../../libs/utils';

/**
 * Modal para crear un grupo
 *
 * @param {*} param0
 *
 * @returns
 */
const ModuleEdit = ({
    module,
    unavailableSubjects,
    subjectsByLevel,
    onChange,
}) => {
    /////////////////////////// LOCAL STATE ///////////////////////////
    const [moduleName, setModuleName] = useState(module.annexed_name);
    const [subjectModuleTargetKeys, setSubjectModuleTargetKeys] = useState(
        module.subjects
    );
    const [subjecModuleSelectedKeys, setSubjectModuleSelectedKeys] = useState(
        []
    );

    /////////////////////////// SHARED STATE  ///////////////////////////

    const filteredSubjectsByLevel = subjectsByLevel.filter((subject) => {
        return !unavailableSubjects.some(
            (unavailableSubject) =>
                unavailableSubject.catalog_subject_id ===
                subject.catalog_subject_id
        );
    });

    /////////////////////////// HANDLERS ///////////////////////////

    useEffect(() => {
        onChange({ name: module.annexed_name, subjects: module.subjects });
    }, []);

    /**
     * Filtro para la busqueda
     */

    const removeAccents = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    const filterOption = (inputValue, option) => {
        const search = removeAccents(
            inputValue.toLowerCase().replace(/\s/g, '')
        );

        const titleNoSpace = removeAccents(
            `${option.folio} ${option.title} ${getLevelName(option.level)}`
                .toLowerCase()
                .replace(/\s/g, '')
        );
        const titleMatch = titleNoSpace.includes(search);

        return titleMatch;
    };

    const onModuleChange = (nextTargetKeys) => {
        setSubjectModuleTargetKeys(nextTargetKeys);
        onChange({ name: moduleName, subjects: nextTargetKeys });
    };

    const handleNameInput = (e) => {
        setModuleName(e.target.value);
        onChange({ name: e.target.value, subjects: subjectModuleTargetKeys });
    };

    const onSelectModuleChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSubjectModuleSelectedKeys([
            ...sourceSelectedKeys,
            ...targetSelectedKeys,
        ]);
    };

    const moudleNameValid = () => {
        return {
            invalid: moduleName === '',
            message: 'Campo requerido, Máximo 50 caracteres',
        };
    };

    /////////////////////////// COMPONENTS ///////////////////////////

    return (
        <>
            <DialogContent>
                <Stack
                    direction="column"
                    divider={
                        <Divider
                            orientation="vertical"
                            flexItem
                            variant="middle"
                        />
                    }
                    spacing={4.7}
                    sx={{ height: 550 }}>
                    <Grid container direction="column" alignItems="center">
                        <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
                            Editar Módulo
                        </Typography>
                        <TextField
                            id="moduleName"
                            name="moduleName"
                            label="Nombre de Módulo"
                            type="text"
                            autoComplete="current-password"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                                maxLength: 50,
                                autoComplete: 'turnoff',
                            }}
                            value={moduleName}
                            onChange={(e) => handleNameInput(e)}
                            error={moudleNameValid().invalid}
                            helperText={
                                moudleNameValid().invalid
                                    ? moudleNameValid().message
                                    : false
                            }
                        />
                        <Divider sx={{ mt: 2 }}>
                            <Typography
                                variant="h6"
                                component="div"
                                style={{
                                    textAlign: 'center',
                                }}>
                                Asignar materias a módulo
                            </Typography>
                        </Divider>
                        <Container>
                            <Transfer
                                style={{
                                    marginTop: '20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                showSearch
                                listStyle={{
                                    width: 265,
                                    height: 350,
                                }}
                                filterOption={filterOption}
                                dataSource={filteredSubjectsByLevel.map((i) => {
                                    return {
                                        ...i,
                                        key: i.catalog_subject_id,
                                    };
                                })}
                                targetKeys={subjectModuleTargetKeys}
                                selectedKeys={subjecModuleSelectedKeys}
                                onSelectChange={onSelectModuleChange}
                                onChange={onModuleChange}
                                pagination
                                locale={{
                                    notFoundContent: [
                                        'Sin Materias',
                                        'Sin Materias',
                                    ],
                                    searchPlaceholder: 'Buscar aquí',
                                    itemUnit: 'Materias',
                                    itemsUnit: 'Materias',
                                }}
                                titles={['Disponibles', 'Asignadas']}
                                render={(item) => {
                                    return (
                                        item.folio +
                                        ' - ' +
                                        item.title +
                                        ' - ' +
                                        getLevelName(item.level)
                                    );
                                }}
                            />
                        </Container>
                    </Grid>
                </Stack>
            </DialogContent>
        </>
    );
};
export default ModuleEdit;
