import { Box, Button, Container, Stack, Typography } from "@mui/material"
import CampaignIcon from '@mui/icons-material/Campaign';

/**
 * Selector para los modos de distribucion disponibles
 * 
 * @param onChangeMode Evento lanzado cada ves que se selecciona un modo
 * @param selected Modo actualmente selecionado
 * 
 * @returns 
 */
const SelectMode = ({onChangeMode, selected}) => {

    const selectMode = (mode) => {
        onChangeMode(mode)
    }

    return (
        <Container>
            <Box sx={{textAlign: "center", mt: 2, mb: 2}}>
                <Typography variant="h6" component={"div"}>
                    El modo de distribución permite enviar su aviso a 
                    diferentes destinatarios previamente segmentados.
                </Typography>

                <CampaignIcon sx={{ fontSize: 80 }} color="primary"/>
            </Box>

            <Stack
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{mb: 3}}
            >
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => {selectMode("groups")}}
                    disabled={selected == "groups"}
                >
                    Grupos
                </Button>
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => {selectMode("students")}}
                    disabled={selected == "students"}
                >
                    Alumnos
                </Button>
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => {selectMode("users")}}
                    disabled={selected == "users"}
                >
                    Usuarios
                </Button>
            </Stack>

        </Container>
    )
}


export default SelectMode