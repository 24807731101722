import { CardHeader, Stack } from "@mui/material"
import { GridToolbar } from "@mui/x-data-grid"

const ProfesoresToolBar = (props) => {
    return (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <CardHeader />
            <GridToolbar {...props} />
        </Stack>
    )
}
const AdminToolBar = (props) => {
    return (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <CardHeader />
            <GridToolbar {...props} />
        </Stack>
    )
}
const PadresToolBar = (props) => {
    return (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <CardHeader />
            <GridToolbar {...props} />
        </Stack>
    )
}
const OrientadorToolBar = (props) => {
    return (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <CardHeader />
            <GridToolbar {...props} />
        </Stack>
    )
}
const DirectorToolBar = (props) => {
    return (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <CardHeader />
            <GridToolbar {...props} />
        </Stack>
    )
}

export { ProfesoresToolBar, AdminToolBar, PadresToolBar, OrientadorToolBar, DirectorToolBar }