import { Skeleton } from '@mui/material';

export default () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                height={50}
                sx={{ mb: 4 }}
                animation="wave"
            />

            <Skeleton
                variant="rectangular"
                height={600}
                sx={{ mt: 2 }}
                animation="wave"
            />
        </>
    );
};

export const SkeletonCard = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                style={{
                    borderRadius: '10px',
                    textAlign: 'center',
                    minHeight: '200px',
                    minWidth: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 'none',
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletonLongCard = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '16px',
                    width: '100%',
                    position: 'relative',
                    height: '450px',
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletongGrafics = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    mt: 2,
                    width: '100%',
                    borderRadius: '10px',
                    height: '600px',
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletonSmallGrafics = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    mt: 2,
                    width: '280px',
                    height: '410px',
                    borderRadius: '10px',
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletonGroupsTables = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    maxWidth: '100%',
                    height: '520px',
                    margin: '20px auto',
                    padding: '16px',
                    overflow: 'visible',
                    position: 'relative',
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletonGroupsCards = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '10px',
                    maxWidth: '600px',
                    height: '280px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    boxShadow: 'none',
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletonGroupsSmallCards = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    maxWidth: '100%',
                    height: '260px',
                    margin: '20px auto',
                    padding: '16px',
                    overflow: 'visible',
                    position: 'relative',
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletonSubjectsCards = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '10px',
                    minHeight: '240px',
                    minWidth: '500px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '0',
                    boxShadow: 'none',
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletonSubjectsTable = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    maxWidth: '100%',
                    height: '700px',
                    margin: '20px auto',
                    padding: '16px',
                    overflow: 'visible',
                    position: 'relative',
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletonSubjectsGraffic = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    maxWidth: '100%',
                    height: '570px',
                    margin: '20px auto',
                    padding: '16px',
                    overflow: 'visible',
                    position: 'relative',
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletonProfessorsSmallTables = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    maxWidth: '100%',
                    height: 720,
                    margin: '20px auto',
                    padding: '16px',
                    overflow: 'visible',
                    position: 'relative',
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletonProfessorsCard = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '10px',
                    width: '700px',
                    height: '180px',
                    mb: 4,
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletonProfessorsTable = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '8px',
                    width: '100%',
                    height: '600px',
                    mb: 4,
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletonStudentsCards = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '10px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    padding: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '800px',
                    height: '300px',
                    margin: 'auto',
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletonStudentsSmallTables = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '10px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    padding: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '720px',
                    height: '720px',
                    margin: 'auto',
                }}
                animation="wave"
            />
        </>
    );
};
export const SkeletonStudentsTables = () => {
    return (
        <>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '10px',
                    width: '97%',
                    height: '700px',
                    overflow: 'hidden',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    borderColor: '#e0e0e0',
                    mb: 4,
                }}
                animation="wave"
            />
        </>
    );
};
