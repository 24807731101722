import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as _ from 'lodash';

const emptyState = {
    classroomModelSelected: null,
};

/**
 * Slice para el settings UI
 */
export const professorHomeUi = createSlice({
    name: 'newProfessor/ui',
    initialState: emptyState,
    reducers: {
        classroomModelSelected: (state, action) => {
            state.classroomModelSelected = action.payload;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });
    },
});

export const { classroomModelSelected } = professorHomeUi.actions;

export default professorHomeUi.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 *
 * @param {*} state
 * @returns
 */
export const selectProfessorsModuleUI = (state) =>
    state.professorClassroom.meGroups.home.ui;

export const selectClassroomSelected = (state) =>
    selectProfessorsModuleUI(state).classroomModelSelected;

//////////////// TRUNCKS /////////////////
