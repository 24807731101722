import React from 'react';
import {
    Typography, Dialog, DialogActions, DialogContent, DialogTitle,
    Button, TableContainer, Table, TableHead, TableBody, TableCell, TableRow
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from "react-redux";
import * as Style from '../../../styles/index';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useAuth } from '../../../hooks';
import { selectSubjectCatalogDataByID } from '../../../store/slices/materias';

const SubjectProfessorsModal = ({ openModal, setOpenModal, subjectCatalogId = null }) => {
    const classes = Style.tableModalStyle();

    const dispatch = useDispatch();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    //////////// SELECTORES /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))
    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    let subjectCatalog = useSelector(selectSubjectCatalogDataByID(subjectCatalogId))

    const compareProfessors = (profA, profB) => {
        const lastNameComparison = profA.last_name.localeCompare(profB.last_name);
        if (lastNameComparison !== 0) {
            return lastNameComparison;
        }
        const secondLastNameComparison = profA.second_last_name.localeCompare(profB.second_last_name);
        if (secondLastNameComparison !== 0) {
            return secondLastNameComparison;
        }
        return profA.name.localeCompare(profB.name);
    };

    const orderedProfessors = subjectCatalog?.professors.slice().sort(compareProfessors);

    return (
        <>
            <Dialog
                open={openModal}
                fullWidth={true}
                maxWidth="sm"
                classes={{ paper: classes.dialogWrapper }}
                onClose={() => {
                    setOpenModal(false)
                }}
            >
                <DialogTitle
                    className={classes.dialogTitle}
                    style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Lista de profesores <br />
                            <span style={{ fontSize: '15px' }}>
                                Materia: {subjectCatalog?.folio} {subjectCatalog?.title}
                            </span>
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {orderedProfessors?.length > 0 && (
                        <TableContainer>
                            <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow style={{ backgroundColor: titleBarBackground.value }}>
                                        <TableCell align='center' style={{ color: fontColor.value }}>FOLIO</TableCell>
                                        <TableCell align='center' style={{ color: fontColor.value }}>NOMBRE</TableCell>
                                        <TableCell align='center' style={{ color: fontColor.value }}>CELULAR</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orderedProfessors.map((row, i) => (
                                        <TableRow key={row.group_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align='center'>
                                                {row.enrollment}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align='center'>
                                                {`${row.last_name} ${row.second_last_name} ${row.name}`}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align='center'>
                                                {row.cellphone}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

                    {!orderedProfessors?.length && (
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <Typography variant="h5" component="div" style={{ flexGrow: 1, textAlign: 'center' }}>
                                Sin profesores
                            </Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => { setOpenModal(false) }}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SubjectProfessorsModal;
