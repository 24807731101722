import { Skeleton } from "@mui/material"

export default () => {

    return (
        <>
            <Skeleton variant="rectangular" height={400} sx={{ mb: 4 }} animation="wave" />
            <Skeleton variant="rectangular" height={200} sx={{ mb: 4 }} animation="wave" />
            <Skeleton variant="rectangular" height={400} sx={{ mb: 4 }} animation="wave" />
        </>
    )
}
