import React, { useEffect } from 'react';
import { Box, Card, CardContent, Chip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { fetchTotalDiscountLoss } from '../../../store/home/kpis';
import { fetchConceptsWithDiscount, selectChartsData } from '../../../store/home/charts';
import { ResponsiveBar } from '@nivo/bar';
import useThemeColors from '../../../../theme/themes';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
const DiscountConceptsCard = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const schoolId = useSelector((state) => state.auth.user.school_id);

    const colors= useThemeColors();
    useEffect(() => {
        if (schoolId) {
            dispatch(fetchTotalDiscountLoss(schoolId));
            dispatch(fetchConceptsWithDiscount(schoolId));
        }
    }, [dispatch, schoolId]);

    const conceptsWithDiscount = useSelector(selectChartsData).value || [];
    const rows = Array.isArray(conceptsWithDiscount) ? conceptsWithDiscount : [];

    const graphicData = rows.map((item) => ({
        catalog: item.catalog,
        total_subtotal: parseFloat(item.total_subtotal) || 0,
    })).slice(0, 5);
    const maxValue = Math.max(...graphicData.map((item) => item.total_subtotal), 0);

    const getChipProps = (conceptTitle) => {
        switch (conceptTitle) {
         
            default:
                return { color: 'default', label: conceptTitle };
        }
    };

    return (
        <Card
            sx={{
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: theme.palette.background.default,
                color: theme.palette.neutral.main,
                maxWidth: '100%',
                height: '650px',  
                mb: 2,
                padding: {
                    xs: 2,
                    md: 3,
                },
            }}
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Box
                    sx={{
                        backgroundColor: colors.sunbeam[900],
                        borderRadius: '100%',
                        width: { xs: '1.5rem', md: '2rem' },
                        height: { xs: '1.5rem', md: '2rem' },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mr: { xs: 1, md: 1.5 },
                    }}
                >
                    < AssignmentOutlinedIcon
                        sx={{
                            width: { xs: '1rem', md: '1.5rem' },
                            color: '#ffffff',
                        }}
                    />
                </Box>
                <Typography variant="h7" sx={{ textAlign: 'center', color: '#000' }}>
                Conceptos con Descuentos
                </Typography>
            </Box>
            <div className="chart" style={{ height: '300px', width: '100%' }}>
                <ResponsiveBar
                    data={graphicData}
                    keys={['total_subtotal']}
                    indexBy="catalog"
                    margin={{ top: 20, right: 20, bottom: 80, left: 60 }}
                    padding={0.3}
                    colors={(d) => {
                        const colorArray = [
                          colors.green[500], 
                          colors.sunbeam[500], 
                          colors.velvetNight[500], 
                          colors.calmWaters[500], 
                          colors.red[500]
                        ];
                        return colorArray[d.index % colorArray.length];  
                    }}
                   borderRadius={4}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '$',
                        legendPosition: 'middle',
                        legendOffset: -40,
                    }}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -30,
                        legend: 'Concepto',
                        legendPosition: 'middle',
                        legendOffset: 60,
                    }}
                    enableGridX={false}
                    enableGridY={true}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor="white"
                    tooltip={({ indexValue, value, color }) => (
                        <div
                            style={{
                                padding: '12px',
                                color: '#000',
                                background: '#fff',
                                border: `1px solid ${color}`,
                                borderRadius: '4px',
                            }}
                        >
                            <strong>{indexValue}</strong>: {value}
                        </div>
                    )}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    minValue={0}
                    maxValue={maxValue < 10 ? 10 : 'auto'}
                />
            </div>
            <CardContent>
                <Box>
                    {rows.map((row) => (
                        <Box
                            key={row.catalog}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={.02}
                            sx={{
                                borderBottom: `1px solid ${theme.palette.divider}`,
                                padding: {
                                    xs: '4px 0',
                                    md: '6px 0',
                                },
                            }}
                        >
                            <Box display="flex" alignItems="center">
                                <Box ml={2}>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        fontSize={{ xs: '0.875rem', md: '1rem' }}
                                    >
                                        {row.catalog}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {row.label}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Chip
                                    label={getChipProps(row.concepts_count).label}
                                    color={getChipProps(row.concepts_count).color}
                                    sx={{ fontWeight: 'bold' }}
                                />
                            </Box>
                        </Box>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
};

export default DiscountConceptsCard;
