import { CardActions, CardContent, CardHeader } from '@material-ui/core';
import { Card, Grid, MenuItem, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { levelsNames, turnsNames } from '../../service/const';
import Connection from '../../service/Connection';
import { useAuth } from '../../hooks';
import { SkeletonFilter } from './SkeletonPage';
import SearchIcon from '@mui/icons-material/Search';

import { LoadingButton } from '@mui/lab';

const SearchInputs = ({ onSearch, dataGridLoading, setDataGridLoading }) => {
    const Auth = useAuth();

    const [cycles, setCycles] = useState([]);
    const [loading, setLoading] = useState('idle');

    const validations = Yup.object().shape({
        cycle: Yup.string().required('Campo requerido'),
        turn: Yup.string().required('Campo requerido'),
        grade: Yup.string().required('Campo requerido'),
        group: Yup.string().required('Campo requerido'),
    });

    const formik = useFormik({
        initialValues: {
            cycle: '',
            turn: '',
            level: '',
            grade: '',
            group: '',
        },
        validationSchema: validations,
        onSubmit: (values) => {
            setDataGridLoading('pending');
            onSearch(values).finally(() => {
                setDataGridLoading('fulfilled');
            });
        },
        enableReinitialize: true,
        validateOnMount: true,
    });

    useEffect(() => {
        const fetchCycles = async () => {
            setLoading('pending');
            const schoolCycles = await Connection.getCyclesBySchool(
                Auth.user.school_id
            ).then((i) => i.data.data);
            setCycles(schoolCycles);
            setLoading('fulfilled');
        };
        fetchCycles();
    }, []);

    return (
        <>
            {loading === 'pending' && <SkeletonFilter />}

            {loading === 'fulfilled' && (
                <Card sx={{ mt: 2 }}>
                    <CardHeader title="Filtros de búsqueda" />
                    <CardContent>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xl={4}
                                    sm={4}
                                    md={4}
                                    xs={12}
                                    style={{ padding: 10 }}
                                >
                                    <TextField
                                        id="cycle"
                                        name="cycle"
                                        {...formik.getFieldProps('cycle')}
                                        label="Ciclo"
                                        variant="outlined"
                                        fullWidth
                                        select
                                        error={
                                            formik.touched.cycle &&
                                            Boolean(formik.errors.cycle)
                                        }
                                        helperText={
                                            formik.touched.cycle &&
                                            formik.errors.cycle
                                                ? formik.errors.cycle
                                                : false
                                        }
                                        InputLabelProps={{
                                            shrink: formik.values.cycle !== '',
                                        }}
                                    >
                                        {cycles.map((cycle) => (
                                            <MenuItem
                                                key={cycle.cycle_id}
                                                value={cycle.cycle_id}
                                            >
                                                {cycle.cycle}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid
                                    item
                                    xl={4}
                                    sm={4}
                                    md={4}
                                    xs={12}
                                    style={{ padding: 10 }}
                                >
                                    <TextField
                                        id="level"
                                        name="level"
                                        {...formik.getFieldProps('level')}
                                        label="Nivel"
                                        variant="outlined"
                                        fullWidth
                                        select
                                        error={
                                            formik.touched.level &&
                                            Boolean(formik.errors.level)
                                        }
                                        helperText={
                                            formik.touched.level &&
                                            formik.errors.level
                                                ? formik.errors.level
                                                : false
                                        }
                                        InputLabelProps={{
                                            shrink: formik.values.level !== '',
                                        }}
                                    >
                                        {levelsNames.map((level) => (
                                            <MenuItem
                                                key={level.key}
                                                value={level.key}
                                            >
                                                {level.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid
                                    item
                                    xl={4}
                                    sm={4}
                                    md={4}
                                    xs={12}
                                    style={{ padding: 10 }}
                                >
                                    <TextField
                                        id="turn"
                                        name="turn"
                                        {...formik.getFieldProps('turn')}
                                        label="Turno"
                                        variant="outlined"
                                        fullWidth
                                        select
                                        error={
                                            formik.touched.turn &&
                                            Boolean(formik.errors.turn)
                                        }
                                        helperText={
                                            formik.touched.turn &&
                                            formik.errors.turn
                                                ? formik.errors.turn
                                                : false
                                        }
                                        InputLabelProps={{
                                            shrink: formik.values.turn !== '',
                                        }}
                                    >
                                        {turnsNames.map((level) => (
                                            <MenuItem
                                                key={level.key}
                                                value={level.key}
                                            >
                                                {level.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid
                                    item
                                    xl={4}
                                    sm={4}
                                    md={4}
                                    xs={12}
                                    style={{ padding: 10 }}
                                >
                                    <TextField
                                        id="grade"
                                        name="grade"
                                        {...formik.getFieldProps('grade')}
                                        label="Grado"
                                        variant="outlined"
                                        inputProps={{ maxLength: 2 }}
                                        fullWidth
                                        onChange={(event) => {
                                            formik.handleChange(event);
                                        }}
                                        error={
                                            formik.touched.grade &&
                                            Boolean(formik.errors.grade)
                                        }
                                        helperText={
                                            formik.touched.grade &&
                                            formik.errors.grade
                                                ? formik.errors.grade
                                                : false
                                        }
                                        InputLabelProps={{
                                            shrink: formik.values.grade !== '',
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xl={4}
                                    sm={4}
                                    md={4}
                                    xs={12}
                                    style={{ padding: 10 }}
                                >
                                    <TextField
                                        id="group"
                                        name="group"
                                        {...formik.getFieldProps('group')}
                                        label="Grupo"
                                        variant="outlined"
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        onChange={(event) => {
                                            formik.handleChange(event);
                                        }}
                                        error={
                                            formik.touched.group &&
                                            Boolean(formik.errors.group)
                                        }
                                        helperText={
                                            formik.touched.group &&
                                            formik.errors.group
                                                ? formik.errors.group
                                                : false
                                        }
                                        InputLabelProps={{
                                            shrink: formik.values.group !== '',
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xl={4}
                                    sm={4}
                                    md={4}
                                    xs={12}
                                    style={{ padding: 10 }}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CardActions>
                                        <LoadingButton
                                            size="normal"
                                            color="primary"
                                            type="submit"
                                            loadingPosition="end"
                                            variant="contained"
                                            endIcon={<SearchIcon />}
                                            disabled={!formik.isValid}
                                            loading={
                                                dataGridLoading === 'pending'
                                            }
                                        >
                                            Buscar
                                        </LoadingButton>
                                    </CardActions>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export default SearchInputs;
