import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    Typography,
    Paper,
    Card,
    CardContent,
} from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectStudentsApprovedGender,
    selectStudentsFailedGender,
} from '../../../store/home/fetchSlice';
import {
    getStudentsApprovedGender,
    getStudentsFailedGender,
} from '../../../store/home/thunks';
import { selectComponentHomeStudentsApprovedGenderStatusServer } from '../../../store/home/componentStatusSelectors';
import {
    SkeletongGrafics,
    SkeletonLongCard,
} from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';

const TotalApprovedAndFailedGender = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeStudentsApprovedGenderStatusServer
    );
    const studentsApprovedGender = useFetchResource(
        () =>
            getStudentsApprovedGender({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectStudentsApprovedGender,
        [cycleSelected, schoolId]
    );
    const studentsFailedGender = useFetchResource(
        () =>
            getStudentsFailedGender({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectStudentsFailedGender,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getStudentsApprovedGender({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectStudentsApprovedGender,
            [cycleSelected, schoolId]
        );
        dispatch(
            getStudentsFailedGender({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectStudentsFailedGender,
            [cycleSelected, schoolId]
        );
    };
    const [view, setView] = useState('approved');

    const getData = () => {
        const data =
            view === 'approved' ? studentsApprovedGender : studentsFailedGender;
        return (
            data?.value?.data?.map((value, index) => ({
                gender: data.value.labels[index],
                count: value,
            })) || []
        );
    };

    const data = getData();

    const getColor = (bar) => {
        if (bar.id === 'count') {
            return bar.data.gender === 'Hombres' ? '#1E90FF' : '#f47edd';
        }
        return '#cccccc';
    };

    return (
        <Box>
            {fetchingStatus === 'pending' && <SkeletonLongCard items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        position: 'relative',
                        overflow: 'visible',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        margin: 'auto',
                    }}
                >
                    <CardContent
                        sx={{
                            width: '100%',
                            padding: '16px',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            {view === 'approved'
                                ? 'Alumnos aprobados por género'
                                : 'Alumnos reprobados por género'}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '10px',
                                marginBottom: '20px',
                            }}
                        >
                            <Button
                                variant={
                                    view === 'approved'
                                        ? 'contained'
                                        : 'outlined'
                                }
                                size="small"
                                onClick={() => setView('approved')}
                            >
                                Aprobados
                            </Button>
                            <Button
                                variant={
                                    view === 'failed' ? 'contained' : 'outlined'
                                }
                                size="small"
                                onClick={() => setView('failed')}
                            >
                                Reprobados
                            </Button>
                        </Box>
                        {data.length > 0 ? (
                            <Box height={280}>
                                <ResponsiveBar
                                    data={data}
                                    keys={['count']}
                                    indexBy="gender"
                                    sx={{ with: '100%', height: '100%' }}
                                    margin={{
                                        top: 10,
                                        right: 20,
                                        bottom: 50,
                                        left: 30,
                                    }}
                                    padding={0.3}
                                    colors={getColor}
                                    borderRadius={5}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legendPosition: 'middle',
                                        legendOffset: 32,
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legendPosition: 'middle',
                                        legendOffset: -40,
                                    }}
                                    labelSkipWidth={0}
                                    labelSkipHeight={0}
                                    labelTextColor="white"
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    theme={{
                                        axis: {
                                            ticks: {
                                                text: { fontSize: 12 },
                                            },
                                        },
                                        labels: {
                                            text: {
                                                fontSize: 16,
                                                fill: 'white',
                                            },
                                        },
                                        tooltip: {
                                            container: {
                                                background: 'white',
                                                color: 'inherit',
                                                fontSize: 12,
                                                borderRadius: '2px',
                                                boxShadow:
                                                    '0 1px 2px rgba(0, 0, 0, 0.25)',
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        ) : (
                            <Paper elevation={3} sx={{ margin: 2, padding: 2 }}>
                                <Typography variant="h5" gutterBottom>
                                    Sin datos disponibles
                                </Typography>
                            </Paper>
                        )}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default TotalApprovedAndFailedGender;
