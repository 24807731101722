import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as CONSTANTS from "../../service/const";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";

const TitlePage = (props) => {
    const {color, index, location} = props

    const menuItems = CONSTANTS.MENU_ITEMS

    const item = menuItems.find( item => item.path === location)

    return (
        <Box sx={{display: "flex", flexDirection:"row", alignItems: "center" }}>
           {item && (
                <>
                    <Box sx={{mr: 1, color: color}} >{item.icon}</Box>
                    <Typography variant="h5" component="div" color={color}>
                        {item.text}
                    </Typography >
                </>
           )}
           {!item && (
               <>
                    <Box sx={{mr: 1, color: color}} >
                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                    </Box>
                    <Typography variant="h5" component="div" color={color}>
                        Otros
                    </Typography>
               </>
           )}
        </Box>
    )
}

export default TitlePage