import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Typography,
    Box,
    Alert,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectStatusOperation,
    updateGroup,
} from '../../../store/slices/groupsUI/serverSlice';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import useFeedback from '../../../hooks/useFeedback';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useAuth } from '../../../hooks';

/**
 * Modal para actualizar el tipo de grupo
 *
 * @param {*} param
 *
 * @returns
 */
const ChangesTypeGroupModal = ({ open, setOpen, groupId = null }) => {
    //////////// HOOKS ////////////////////

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    //////////// LOCAL STATE ////////////////////

    const [groupItem, setGroupItem] = useState({
        group_id: null,
        grade: '',
        group: '',
        turn: -1,
        level: -1,
        special: -1,
        annexed: false,
        annexed_name: '',
        combined: null,
        share_students: false,
        group_annexed_id: null,
        group_type: null,
    });

    //////////// SHARED STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(selectStatusOperation);

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    /////////////////// ACTIONS /////////////////

    /**
     * Actualizar grupo
     */
    const updateGroups = async () => {
        const annexed = groupItem.annexed;
        const groupType = groupItem.group_type;
        const annexed_name = groupItem.annexed_name;
        const combined = groupItem.combined;

        const adjustedGroupItem = {
            ...groupItem,
            annexed: !annexed,
            group_type: groupType === 2 ? 3 : 2,
            annexed_name: annexed ? '' : annexed_name,
            combined,
        };

        dispatch(
            updateGroup({
                groupId: groupItem.group_id,
                data: adjustedGroupItem,
            })
        )
            .unwrap()
            .then((data) => {
                feedbackApp.showFeedback({
                    title: 'Grupo actualizado',
                });

                handleClose();
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    //////////////////// Handlers ///////////////////////////

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }
        setOpen(false);
    };

    /**
     * Efecto escuchando a show para limpiar los valores
     */
    useEffect(() => {
        if (!open) {
            setGroupItem({
                group_id: null,
                grade: '',
                group: '',
                turn: -1,
                level: -1,
                special: -1,
                annexed: false,
                annexed_name: '',
                combined: null,
                share_students: false,
                group_annexed_id: null,
                group_type: -1,
            });
        } else {
            setGroupItem({ ...groupItem, ...groupId });
        }
    }, [open]);

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     *
     * @param {*} event
     */
    const onInputChange = (event) => {
        const { name, value } = event.target;
        setGroupItem({ ...groupItem, [name]: value });
    };

    //////////////////////// VALIDACIONES ////////////////////////

    const annexednameValid = () => {
        return {
            invalid:
                groupItem.annexed_name === '' ||
                groupItem.annexed_name === null,
            message: 'Máximo 20 caracteres',
        };
    };

    const isValidType = () => {
        if (groupItem.annexed === false) {
            return annexednameValid().invalid;
        }

        return false;
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                disableEscapeKeyDown={statusOperation === 'pending'}>
                {groupItem.group_type === 3 ? (
                    <>
                        <DialogTitle
                            style={{
                                backgroundColor: titleBarBackground.value,
                                color: fontColor.value,
                            }}>
                            <div style={{ display: 'flex' }}>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    style={{ flexGrow: 1 }}>
                                    Convertir grupo
                                </Typography>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <Box mt={2} textAlign="center">
                                <DialogContent>
                                    <Typography variant="h6">
                                        ¿Estás seguro de convertir el grupo de{' '}
                                        <strong>extraescolar</strong> a Nuevo
                                        grupo especial?
                                    </Typography>
                                </DialogContent>
                            </Box>
                            {groupItem.subjects > 1 ? (
                                <>
                                    <TextField
                                        id="annexed_name"
                                        name="annexed_name"
                                        label="Nombre del grupo especial"
                                        type="text"
                                        fullWidth
                                        required
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 20,
                                            autocomplete: 'turnoff',
                                        }}
                                        onChange={onInputChange}
                                        value={groupItem.annexed_name}
                                        disabled={true}
                                        error={annexednameValid().invalid}
                                        helperText={
                                            annexednameValid().invalid
                                                ? annexednameValid().message
                                                : false
                                        }
                                    />
                                    <Alert sx={{ mt: 6 }} severity="error">
                                        Solo los grupos{' '}
                                        <strong>extraescolares</strong> con una
                                        materia pueden ser convertidos a{' '}
                                        <strong>
                                            Nuevos grupos especiales
                                        </strong>
                                    </Alert>
                                </>
                            ) : (
                                <TextField
                                    id="annexed_name"
                                    name="annexed_name"
                                    label="Nombre del grupo especial"
                                    type="text"
                                    fullWidth
                                    required
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 20,
                                        autocomplete: 'turnoff',
                                    }}
                                    onChange={onInputChange}
                                    value={groupItem.annexed_name}
                                    error={annexednameValid().invalid}
                                    helperText={
                                        annexednameValid().invalid
                                            ? annexednameValid().message
                                            : false
                                    }
                                />
                            )}
                        </DialogContent>
                    </>
                ) : (
                    <>
                        <DialogTitle
                            style={{
                                backgroundColor: titleBarBackground.value,
                                color: fontColor.value,
                            }}>
                            <div style={{ display: 'flex' }}>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    style={{ flexGrow: 1 }}>
                                    Convertir grupo
                                </Typography>
                            </div>
                        </DialogTitle>
                        <Box mt={2} textAlign="center">
                            <DialogContent>
                                <Typography variant="h6">
                                    ¿Estás seguro de convertir el grupo de{' '}
                                    <strong>nuevo especial</strong> a
                                    extraescolar?
                                </Typography>
                            </DialogContent>
                        </Box>
                    </>
                )}
                <DialogActions>
                    {groupItem.group_type === 3 ? (
                        <>
                            {groupItem.subjects > 1 ? (
                                <LoadingButton
                                    size="small"
                                    color="primary"
                                    onClick={updateGroups}
                                    loading={statusOperation === 'pending'}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    disabled={true}>
                                    Convertir
                                </LoadingButton>
                            ) : (
                                <LoadingButton
                                    size="small"
                                    color="primary"
                                    onClick={updateGroups}
                                    loading={statusOperation === 'pending'}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    disabled={isValidType()}>
                                    Convertir
                                </LoadingButton>
                            )}
                        </>
                    ) : (
                        <LoadingButton
                            size="small"
                            color="primary"
                            onClick={updateGroups}
                            loading={statusOperation === 'pending'}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            disabled={isValidType()}>
                            Convertir
                        </LoadingButton>
                    )}

                    <Button
                        disabled={statusOperation === 'pending'}
                        onClick={handleClose}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default ChangesTypeGroupModal;
