import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import {
    selectAllGroups,
    selectAllGroupsEntities,
    selectGroupBySubject,
    selectGroupsById,
    selectStudentsByGroup,
    upsertManyGroup,
} from '../../../../store/slices/entities/groups/index';
import {
    selectAllSettings,
    selectOneSettingBySchoolId,
    selectSettingBySchoolId,
} from '../../../../store/slices/entities/settings';
import {
    selectEntitiesSchools,
    selectSchoolById,
} from '../../../../store/slices/entities/schools';
import {
    selectAllCalifications,
    upsertManyCalifications,
} from '../../../../store/slices/entities/califications';
import {
    addManyAssists,
    selectAllAssists,
    upsertManyAssists,
} from '../../../../store/slices/entities/assists';
import { convertArrayToObject } from '../../../../libs/utils';
import {
    addManyGroupsStudents,
    removeManyGroupsStudents,
    selectAllGroupsStudents,
} from '../../../../store/slices/entities/groups_students';
import {
    addManyStudents,
    selectEntitiesStudents,
    upsertManyStudents,
} from '../../../../store/slices/entities/students';
import { addAditionalDataGroupItem } from '../home/ItemsSlice';
import {
    addOneSubject,
    selectAllSubjects,
    selectSubjectByid,
    selectSubjects,
    selectSubjectsByGroupId,
    upsertManySubjects,
    upsertOneSubject,
} from '../../../../store/slices/entities/subjects';

import * as _ from 'lodash';
import {
    selectEntitiesSubjectcatalogs,
    selectSubjectCatalogBySubject,
    upsertManySubjectcatalog,
} from '../../../../store/slices/entities/subjectcatalogs';
import Connection from '../../../../service/Connection';
import Feedback from '../../../../service/Feedback';
import { PROFESSORS_SUBJECT_EXPIRE_TIME } from '../../../../service/const';
import {
    selectAllPartials,
    selectPartialsByGroup,
    upsertManyPartials,
} from '../../../../store/slices/entities/partials';
import { selectAllCatalogSubjects } from '../../../../store/slices/background';
import {
    selectModulesByGroup,
    upsertManyModules,
} from '../../../../store/slices/entities/modules';
import {
    selectUserById,
    upsertOneUser,
} from '../../../../store/slices/entities/users';

const emptyState = {};

export const classroomSlice = createSlice({
    name: 'classroom',
    initialState: emptyState,
    reducers: {
        invalidate: (state, { payload }) => {
            state[payload].fetch.didInvalidate = true;
        },
        setAllClassroomItems: (state, action) => {
            return action.payload;
        },
        performedAssistsList: (state, action) => {
            state[action.payload].ui.listPerformed = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        /////////////////// Recuperar información de classroom Subject ///////////////////////

        builder.addCase(
            fetchClassroomSubjectData.fulfilled,
            (state, { meta }) => {
                const { uuid } = meta.arg;

                state[uuid].fetch.expireIn = new Date().setMinutes(
                    new Date().getMinutes() + PROFESSORS_SUBJECT_EXPIRE_TIME
                );
                state[uuid].fetch.status = 'fulfilled';
                state[uuid].fetch.fetchingAt = Date.now();
                state[uuid].fetch.didInvalidate = false;
            }
        );

        builder.addCase(
            fetchClassroomSubjectData.pending,
            (state, { meta }) => {
                const { uuid } = meta.arg;
                state[uuid].fetch.status = 'pending';
            }
        );

        builder.addCase(fetchClassroomSubjectData.rejected, (state, action) => {
            if (action.payload) {
                const { uuid } = action.payload;

                state[uuid].fetch.status = 'rejected';
            }
        });

        builder.addCase(
            fetchClassroomGroupData.fulfilled,
            (state, { meta }) => {
                const { uuid } = meta.arg;

                state[uuid].fetch.expireIn = new Date().setMinutes(
                    new Date().getMinutes() + PROFESSORS_SUBJECT_EXPIRE_TIME
                );
                state[uuid].fetch.status = 'fulfilled';
                state[uuid].fetch.fetchingAt = Date.now();
                state[uuid].fetch.didInvalidate = false;
            }
        );

        builder.addCase(fetchClassroomGroupData.pending, (state, { meta }) => {
            const { uuid } = meta.arg;
            state[uuid].fetch.status = 'pending';
        });

        builder.addCase(fetchClassroomGroupData.rejected, (state, action) => {
            if (action.payload) {
                const { uuid } = action.payload;

                state[uuid].fetch.status = 'rejected';
            }
        });

        const fulfilledAssistList = (state, { meta }) => {
            const { uuid } = meta.arg;

            state[uuid].operations.saveAssists = 'fulfilled';
        };

        const rejectedAssistList = (state, { meta }) => {
            const { uuid } = meta.arg;

            state[uuid].operations.saveAssists = 'rejected';
        };

        const pendingAssistList = (state, { meta }) => {
            const { uuid } = meta.arg;

            state[uuid].operations.saveAssists = 'pending';
        };
        ////////////////// STORE ASSIST LIST ///////////////////

        builder.addCase(newStoreAssitsList.rejected, rejectedAssistList);
        builder.addCase(newStoreAssitsList.fulfilled, fulfilledAssistList);
        builder.addCase(newStoreAssitsList.pending, pendingAssistList);

        builder.addCase(storeAssistListGroup.rejected, rejectedAssistList);
        builder.addCase(storeAssistListGroup.fulfilled, fulfilledAssistList);
        builder.addCase(storeAssistListGroup.pending, pendingAssistList);

        const fulfilledScoresSave = (state, { meta }) => {
            const { uuid } = meta.arg;

            state[uuid].operations.saveScores = 'fulfilled';
        };

        const rejectedAScoresSave = (state, { meta }) => {
            const { uuid } = meta.arg;

            state[uuid].operations.saveScores = 'rejected';
        };

        const pendingAScoresSave = (state, { meta }) => {
            const { uuid } = meta.arg;

            state[uuid].operations.saveScores = 'pending';
        };

        builder.addCase(newStoreCalificationList.rejected, rejectedAScoresSave);
        builder.addCase(
            newStoreCalificationList.fulfilled,
            fulfilledScoresSave
        );
        builder.addCase(newStoreCalificationList.pending, pendingAScoresSave);
    },
});

export const { setAllClassroomItems, invalidate, performedAssistsList } =
    classroomSlice.actions;

/////////////// SELECTORS /////////////////

export const selectClassroomsEntities = (store) =>
    store.professorClassroom.meGroups.classroom;

export const selectClassroomsEntitiesAsArray = (store) =>
    Object.values(store.professorClassroom.meGroups.classroom);

export const selectItemById = (uuid) => (state) =>
    selectClassroomsEntities(state)[uuid];

export const selectClassroomViewmodelById = (viewModelId) =>
    createSelector(
        selectClassroomsEntities,
        (viewmodels) => viewmodels[viewModelId]
    );

export const selectClassroomViewmodelUIById = (viewModelId) =>
    createSelector(
        selectClassroomViewmodelById(viewModelId),
        (viewmodel) => viewmodel.ui
    );

export const selectClassroomViewmodelFetchById = (viewModelId) =>
    createSelector(
        selectClassroomViewmodelById(viewModelId),
        (viewmodel) => viewmodel.fetch
    );

export const selectClassroomViewmodelFetchStatusById = (viewModelId) =>
    createSelector(
        selectClassroomViewmodelFetchById(viewModelId),
        (viewmodel) => {
            return viewmodel.status;
        }
    );

export const selectClassroomViewmodelOperationById = (viewModelId) =>
    createSelector(selectClassroomViewmodelById(viewModelId), (viewmodel) => {
        return viewmodel.operations;
    });

export const selectClassroomViewmodelAssistOperationtatusById = (viewModelId) =>
    createSelector(
        selectClassroomViewmodelOperationById(viewModelId),
        (viewmodel) => {
            return viewmodel.saveAssists;
        }
    );

export const selectClassroomViewmodelScoreOperationtatusById = (viewModelId) =>
    createSelector(
        selectClassroomViewmodelOperationById(viewModelId),
        (viewmodel) => {
            return viewmodel.saveScores;
        }
    );

export const selectSubjectsByClassroomGroup = (classroomId, schoolId) =>
    createSelector(
        selectClassroomViewmodelUIById(classroomId, schoolId),
        selectAllSubjects,
        selectAllGroups,
        (classroomUI, allSubjects, allGroups) => {
            return _.chain(allGroups)
                .filter(['group_annexed_id', classroomUI.group_id])
                .map('group_id')
                .concat(classroomUI.group_id)
                .map((groupsId) =>
                    _.filter(allSubjects, ['group_id', groupsId])
                )
                .flatten()
                .value();
        }
    );

export const selectClassroomCommonDetails = (viewModelId, schoolId) =>
    createSelector(
        selectClassroomViewmodelUIById(viewModelId),
        selectAllGroupsEntities,
        selectSettingBySchoolId(schoolId),
        selectEntitiesSchools,
        (classUI, groupsEntities, allSchoolsettings, schoolEntities) => {
            const group = groupsEntities[classUI.group_id];
            const school = schoolEntities[group.school_id];
            const schoolSettings = allSchoolsettings.filter(
                (i) => i.school_id === group.school_id
            );

            return {
                viewmodel: {
                    ...classUI,
                    uuid: viewModelId,
                },
                group,
                schoolSettings,
                school,
            };
        }
    );

export const selectClassroomGroupDetails = (viewModelId, schoolId) =>
    createSelector(
        selectClassroomCommonDetails(viewModelId, schoolId),
        selectEntitiesSubjectcatalogs,
        selectSubjectsByClassroomGroup(viewModelId),
        (commonData, subjectcatalogEntities, subjects) => {
            let catalogSubjects = subjects.map(
                (subject) => subjectcatalogEntities[subject.catalog_subject_id]
            );

            return {
                ...commonData,
                subjects,
                catalogSubjects,
            };
        }
    );

export const selectClassroomSubjectDetails = (viewModelId, schoolId) =>
    createSelector(
        selectClassroomViewmodelUIById(viewModelId),
        selectClassroomCommonDetails(viewModelId, schoolId),
        selectSubjects,
        selectEntitiesSubjectcatalogs,
        (classUI, commonData, subjectsEntities, subjectcatalogEntities) => {
            const subject = subjectsEntities[classUI.subject_id];
            const subjectCatalog =
                subjectcatalogEntities[subject?.catalog_subject_id];

            return {
                ...commonData,
                subject,
                subjectCatalog,
            };
        }
    );

export const fetchClassroomSubjectData = createAsyncThunk(
    'newProfessors/classroom-subjetc/fetch',
    async (data, thunkAPI) => {
        const { groupId, subjectId, curretnDate, schoolId } = data;

        let FeedbackService = new Feedback();

        try {
            let students = await Connection.getStudentsByGroup(groupId).then(
                (res) => res.data.data
            );

            let partials = await Connection.getPartials(schoolId).then(
                (res) => res.data.data
            );

            let allAssists = [];
            let allCalifications = [];
            let allAssistsIds = [];
            let allCalificationsIds = [];

            for (let student of students) {
                const assists = await Connection.getStudentAssists(
                    student.student_id,
                    subjectId
                ).then((res) => res.data.data);
                const califications =
                    await Connection.getCalificationbyStudentUsingSubjetID(
                        student.student_id,
                        subjectId
                    ).then((res) => res.data.data);

                const assistIds = assists.map((assist) => assist.assist_id);
                const calificationIds = califications.map(
                    (calification) => calification.calification_id
                );

                allAssists = allAssists.concat(assists);
                allCalifications = allCalifications.concat(califications);

                allAssistsIds = allAssists.concat(assistIds);
                allCalificationsIds = allCalifications.concat(calificationIds);
            }

            let groupsStudents = students.map((s) => ({
                group_id: groupId,
                student_id: s.student_id,
            }));

            let assistancePerformed = false;

            let findPerformedList = allAssists.find(
                ({ date }) => date.substring(0, 10) === curretnDate
            );

            assistancePerformed = findPerformedList ? true : false;

            thunkAPI.dispatch(upsertManyCalifications(allCalifications));
            thunkAPI.dispatch(upsertManyPartials(partials));
            thunkAPI.dispatch(upsertManyAssists(allAssists));
            thunkAPI.dispatch(
                addManyStudents(convertArrayToObject(students, 'student_id'))
            );
            thunkAPI.dispatch(addManyGroupsStudents(groupsStudents));

            thunkAPI.dispatch(
                addAditionalDataGroupItem({
                    id: subjectId,
                    data: {
                        listPerformed: assistancePerformed,
                    },
                })
            );

            return {
                students,
                allAssists,
                allCalifications,
                groupsStudents,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: ({ uuid }, { getState, extra }) => {
            const store = getState();

            const classroomItemSelected = selectItemById(uuid)(store);

            let { didInvalidate, expireIn } = classroomItemSelected.fetch;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const fetchClassroomGroupData = createAsyncThunk(
    'newProfessors/classroom-group/fetch',
    async (data, thunkAPI) => {
        const { groupId, schoolId, userId, currentStudents } = data;

        let modulesWithDetails = [];

        let partials = await Connection.getPartials(schoolId).then(
            (res) => res.data.data
        );

        let user = await Connection.getUserById(userId).then(
            (res) => res.data.data
        );

        let subjectCatalogs = await Connection.getSubjectCatalogBySchool(
            schoolId
        ).then((i) => i.data.data);
        let subjectsMainGroup = await Connection.getSubjectByGroup(
            groupId
        ).then((i) => i.data.data);
        let students = await Connection.getStudentsByGroup(groupId).then(
            (i) => i.data.data
        );
        let scoresInMainGroup = await Connection.getCalificationsByGroupId(
            groupId
        ).then((i) => i.data.data);
        let assistsMainGroup = await Connection.getAssistsByGroupId(
            groupId
        ).then((i) => i.data.data);
        let group = await Connection.getGroupById(groupId).then(
            (i) => i.data.data
        );
        let modules = await Connection.getModulesByGroup(groupId).then(
            (i) => i.data.data
        );
        for (let module of modules) {
            let moduleSubjects = await Connection.getSubjectsByModule(
                module.group_id
            ).then((i) => i.data.data);
            let moduleCalifications =
                await Connection.getCalificationsByGroupId(
                    module.group_id
                ).then((i) => i.data.data);
            let moduleAssists = await Connection.getAssistsByGroupId(
                module.group_id
            ).then((i) => i.data.data);

            modulesWithDetails.push({
                ...module,
                moduleSubjects,
                moduleCalifications,
                moduleAssists,
            });
        }

        let allScores = scoresInMainGroup.concat(
            modulesWithDetails.map((i) => i.moduleCalifications).flat()
        );
        let allAssists = assistsMainGroup.concat(
            modulesWithDetails.map((i) => i.moduleAssists).flat()
        );

        let subjectsByProfessor = subjectsMainGroup
            .concat(modulesWithDetails.map((i) => i.moduleSubjects).flat())
            .filter((s) => s.professor_id === userId);

        let assists = subjectsByProfessor
            .map((subject) =>
                allAssists.filter(
                    (assist) => subject.subject_id === assist.subject_id
                )
            )
            .flat();
        let scores = subjectsByProfessor
            .map((subject) =>
                allScores.filter(
                    (score) => subject.subject_id === score.subject_id
                )
            )
            .flat();

        let groups = _.chain(modulesWithDetails)
            // .map('module')
            .flatten()
            .concat(group)
            .value();

        let groupsStudentsRelationship = _.chain(groups)
            .map((group) => {
                return students.map((i) => ({
                    group_id: group.group_id,
                    student_id: i.student_id,
                }));
            })
            .flatten()
            .value();

        let subjectsIds = _.map(subjectsByProfessor, 'subject_id');

        const removeEvents = [];

        /**
         * Eliminamos la relacion de los alumnos que ya no pertenecen al grupo
         */

        // let studentstoRemove = _.differenceWith(
        //     currentStudents,
        //     students,
        //     (a, b) => a.student_id === b.student_id
        // );

        // removeEvents.push(
        //     removeManyGroupsStudents({
        //         ids: _.map(studentstoRemove, 'groupsStudentId'),
        //     })
        // );

        thunkAPI.dispatch(upsertManyGroup(groups));
        thunkAPI.dispatch(upsertManyStudents(students));
        thunkAPI.dispatch(upsertManySubjectcatalog(subjectCatalogs));
        thunkAPI.dispatch(upsertManySubjects(subjectsByProfessor));
        thunkAPI.dispatch(addManyGroupsStudents(groupsStudentsRelationship));
        thunkAPI.dispatch(upsertManyAssists(assists));
        thunkAPI.dispatch(upsertManyCalifications(scores));
        thunkAPI.dispatch(upsertManyModules(modules));
        thunkAPI.dispatch(upsertManyPartials(partials));
        thunkAPI.dispatch(upsertOneUser(user));
    },
    {
        condition: ({ uuid }, { getState, extra }) => {
            const store = getState();

            const classroomItemSelected = selectItemById(uuid)(store);

            let { didInvalidate, expireIn } = classroomItemSelected.fetch;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

/**
 * Almacena todas las listas de asistencia de classroom de materia
 */
export const newStoreAssitsList = createAsyncThunk(
    'newProfessor/items/assistsSubject/store',
    async (data, thunkAPI) => {
        const { postData, uuid } = data;

        let FeedbackService = new Feedback();

        try {
            const assists = await Connection.storeMultiAssistsList(
                postData
            ).then((res) => res.data.data);

            const subject = await Connection.getSubjectById(
                assists[0].subject_id
            ).then((i) => i.data.data);

            thunkAPI.dispatch(addManyAssists(assists));
            thunkAPI.dispatch(performedAssistsList(uuid));
            thunkAPI.dispatch(upsertOneSubject(subject));
            return {
                assists,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Almacena todas las listas de asistencia de classroom de materia
 */
export const storeAssistListGroup = createAsyncThunk(
    'newProfessor/items/assistsGroup/store',
    async (data, thunkAPI) => {
        const { assists, uuid } = data;

        let FeedbackService = new Feedback();

        let allSubjects = [];

        try {
            const assistsGroup = await Connection.storeMultiAssistsList(
                assists
            ).then((res) => res.data.data);

            for (let i = 0; i < assistsGroup.length; i++) {
                const subject = await Connection.getSubjectById(
                    assistsGroup[i].subject_id
                ).then((i) => i.data.data);

                allSubjects.push(subject);
            }
            thunkAPI.dispatch(upsertManySubjects(allSubjects));
            thunkAPI.dispatch(performedAssistsList(uuid));
            thunkAPI.dispatch(addManyAssists(assistsGroup));
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Crea o actualiza todas las calificaciones
 */
export const newStoreCalificationList = createAsyncThunk(
    'newProfessor/items/scores/store',
    async (data, thunkAPI) => {
        const { subjectId, create, update } = data;

        let FeedbackService = new Feedback();

        try {
            const scoresToCreate = create.concat(update);

            const newScores = await Connection.saveMutiCalifications(
                scoresToCreate
            ).then((i) => i.data.data);

            thunkAPI.dispatch(upsertManyCalifications(newScores));

            const subject = await Connection.getSubjectById(subjectId).then(
                (response) => response.data.data
            );

            thunkAPI.dispatch(upsertOneSubject(subject));
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

export const selectStudentsByClassroom = (classroomId) =>
    createSelector(
        selectClassroomViewmodelUIById(classroomId),
        selectEntitiesStudents,
        selectAllGroupsStudents,
        (classUI, studentsEntities, allGroupsStudents) => {
            let groupsStudents = allGroupsStudents.filter(
                (i) => i.group_id === classUI.group_id
            );

            let students = groupsStudents.map(
                (i) => studentsEntities[i.student_id]
            );

            return students;
        }
    );

export const selectAssistsByClassroomSubject = (classroomId) =>
    createSelector(
        selectStudentsByClassroom(classroomId),
        selectAllAssists,
        (students, allAssists) => {
            let allMixedAssists = students.map((e) => {
                return allAssists.filter((c) => e.student_id === c.student_id);
            });

            return allMixedAssists.flat();
        }
    );

export const selectAtendenceForSubject = (classroomId, date) =>
    createSelector(
        selectClassroomViewmodelUIById(classroomId),
        selectSubjects,
        selectAssistsByClassroomSubject(classroomId),
        selectStudentsByClassroom(classroomId),
        (classUI, subjectsEntities, assists, students) => {
            const subject = subjectsEntities[classUI.subject_id];

            let assistsforDate = assists
                .filter((i) => i.date.substring(0, 10) === date)
                .filter((i) => i.subject_id === subject.subject_id);

            let assistancePerformed = subject?.last_assist_list
                ? subject.last_assist_list.substring(0, 10) === date
                : false;

            return {
                students,
                subject,
                assistsToday: assistsforDate,
                assistancePerformed,
            };
        }
    );

export const selectAssistByGroup = (groupId, userId) =>
    createSelector(
        selectGroupsById(groupId),
        selectSubjectsByGroupId(groupId),
        selectStudentsByGroup(groupId),
        selectPartialsByGroup(groupId),
        selectAllAssists,
        selectUserById(userId),
        selectModulesByGroup(groupId),
        selectAllSubjects,
        (
            group,
            subjects,
            students,
            partials,
            allAssists,
            user,
            modules,
            allSubjects
        ) => {
            let mergeAssistswhitStudent = students.map((e) => {
                return allAssists.filter((c) => e.student_id == c.student_id);
            });

            let assist = _.flattenDeep(mergeAssistswhitStudent);

            let subjectsModule = [];

            for (let item of modules) {
                subjectsModule.push(
                    allSubjects.filter((i) => i.group_id == item.group_id)
                );
            }

            return {
                students,
                partials,
                subjects,
                group,
                assist,
                user,
                modules,
                subjectsModule,
            };
        }
    );

export const selectJustifyFaults = (subjectId, groupId) =>
    createSelector(
        selectAllGroupsEntities,
        selectSubjects,
        selectAllAssists,
        selectAllPartials,
        selectAllSettings,
        selectEntitiesStudents,
        selectAllGroupsStudents,
        (
            groupsEntities,
            subjectsEntities,
            allAssists,
            allPartials,
            allSchoolsettings,
            studentsEntities,
            allgroupsStudents
        ) => {
            const subject = subjectsEntities[subjectId];

            const group = groupsEntities[groupId];
            const schoolSettings = allSchoolsettings.filter(
                (i) => i.school_id === group.school_id
            );
            const partials = allPartials.filter(
                (i) =>
                    i.level === group.level &&
                    i.school_id === group.school_id &&
                    i.is_regularized === false
            );
            const regularizations = allPartials.filter(
                (i) =>
                    i.level === group.level &&
                    i.school_id === group.school_id &&
                    i.is_regularized === true
            );

            /**
             * Recupermos los grupos del alumno
             */
            let groupsStudents = allgroupsStudents.filter(
                (i) => i.group_id === groupId
            );

            let students = groupsStudents.map(
                (i) => studentsEntities[i.student_id]
            );

            /**
             * Recuperar todas las assistencias de los alumnos
             */
            let allMixedScores = students.map((e) => {
                return allAssists.filter((c) => e.student_id === c.student_id);
            });

            let assists = _.flatten(allMixedScores).filter(
                (i) => i.subject_id === subject.subject_id
            );

            return {
                students,
                assists,
                partials,
                regularizations,
                subject,
                group,
                schoolSettings,
            };
        }
    );

export const selectClassroomScoresPage = (subjectId, schoolId) =>
    createSelector(
        selectGroupBySubject(subjectId),
        selectSubjectByid(subjectId),
        selectSubjectCatalogBySubject(subjectId),
        selectSchoolById(schoolId),
        selectOneSettingBySchoolId(schoolId, 'calification-minimum'),
        (group, subject, subjectCatalog, school, calificationMinimum) => {
            return {
                subject,
                subjectCatalog,
                group,
                calificationMinimum,
                school,
            };
        }
    );

export const selectClassroomJustifyPage = (subjectId, schoolId) =>
    createSelector(
        selectGroupBySubject(subjectId),
        selectSubjectByid(subjectId),
        selectSubjectCatalogBySubject(subjectId),
        selectSchoolById(schoolId),
        (group, subject, subjectCatalog, school) => {
            return {
                subject,
                subjectCatalog,
                group,
                school,
            };
        }
    );

export const selectClassroomScoresDetails = (subjectId, groupId) =>
    createSelector(
        selectGroupsById(groupId),
        selectSubjectByid(subjectId),
        selectAllCalifications,
        selectAllPartials,
        selectAllSettings,
        selectStudentsByGroup(groupId),
        (
            group,
            subject,
            allScores,
            allPartials,
            allSchoolsettings,
            students
        ) => {
            const schoolSettings = allSchoolsettings.filter(
                (i) => i.school_id === group.school_id
            );
            const partials = allPartials.filter(
                (i) =>
                    i.level === group.level && i.school_id === group.school_id
            );

            /**
             * Recuperar todas las calificaciones de los alumnos
             */
            let allMixedScores = students.map((e) => {
                return allScores.filter((c) => e.student_id === c.student_id);
            });

            let califications = _.flatten(allMixedScores).filter(
                (i) => i.subject_id === subject.subject_id
            );

            return {
                students,
                califications,
                partials,
                subject,
                group,
                schoolSettings,
            };
        }
    );

export const selectAssistsByClassroom = (classroomId) =>
    createSelector(
        selectStudentsByClassroom(classroomId),
        selectAllAssists,
        (students, allAssists) => {
            let allMixedAssists = students.map((e) => {
                return allAssists.filter((c) => e.student_id === c.student_id);
            });

            return allMixedAssists.flat();
        }
    );

export const selectAtendenceForGroup = (classroomId, date) =>
    createSelector(
        selectClassroomViewmodelUIById(classroomId),
        selectAllGroupsEntities,
        selectEntitiesSubjectcatalogs,
        selectAssistsByClassroom(classroomId),
        selectStudentsByClassroom(classroomId),
        selectSubjectsByClassroomGroup(classroomId),
        (
            classUI,
            groupsEntities,
            subjectCatalogs,
            assists,
            students,
            subjects
        ) => {
            const group = groupsEntities[classUI.group_id];

            /**
             * asistencias filtradas por fecha
             */
            let assistsforDate = assists.filter(
                (i) => i.date.substring(0, 10) === date
            );

            /**
             * todas las asistencias de las materias del grupo
             */
            let assistsInGroupInDate = subjects
                .map((subject) =>
                    assistsforDate.filter(
                        (assits) => assits.subject_id === subject.subject_id
                    )
                )
                .flat();

            /**
             * Asistenicas por alumno
             */
            let assistsAll = _.uniqBy(assistsInGroupInDate, 'student_id');

            let assistancePerformed = subjects[0]?.last_assist_list
                ? subjects[0].last_assist_list.substring(0, 10) === date
                : false;

            let subjectsWithCatalog = subjects.map((subject) => {
                return {
                    ...subject,
                    subjectCatalog: subjectCatalogs[subject.catalog_subject_id],
                };
            });

            return {
                students,
                group,
                assistsToday: assistsAll,
                assistancePerformed: assistancePerformed,
                subjects: subjectsWithCatalog,
            };
        }
    );
export default classroomSlice.reducer;
