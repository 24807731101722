import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { remove } from 'lodash';

const discountAdapter = createEntityAdapter({
    selectId: (discount) => discount.discount_id,
    sortComparer: (a, b) => a.discount_id - b.discount_id,
});

export const DiscountSlice = createSlice({
    name: 'discount',
    initialState: discountAdapter.getInitialState(),
    reducers: {
        setAllDiscounts: discountAdapter.setAll,
        addManyDiscounts: discountAdapter.addMany,
        addOneDiscount: discountAdapter.addOne,
        removeOneDiscount: discountAdapter.removeOne,
        removeManyDiscounts: discountAdapter.removeMany,
        upsertOneDiscount: discountAdapter.upsertOne,
        upsertManyDiscounts: discountAdapter.upsertMany,
        updateManyDiscounts: discountAdapter.updateMany,
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return discountAdapter.getInitialState();
        });
    },
});

export const {
    setAllDiscounts,
    addManyDiscounts,
    addOneDiscount,
    removeOneDiscount,
    removeManyDiscounts,
    upsertOneDiscount,
    upsertManyDiscounts,
    updateManyDiscounts,
} = DiscountSlice.actions;

const globalizedSelectors = discountAdapter.getSelectors(
    (state) => state.entities.discount
);

export default DiscountSlice.reducer;
