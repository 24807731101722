import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Card, CardActionArea, Stack, Typography } from '@mui/material';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { getLevelName, getTurnLevel } from '../../../../libs/utils';
import { classroomModelSelected } from '../../../store/meGroups/home/uiSlice';

export const ClassroomCard = ({ item, color }) => {
    ////////// HOOKS ///////////////

    const dispatch = useDispatch();
    const history = useHistory();

    ////////// LOCAL STATE //////////

    const [hovered, setHovered] = useState(false);

    ////////// HANDLERS //////////

    /*
     * Manejo del hover de la carta activo
     */
    const handlerHover = () => {
        setHovered(true);
    };

    /*
     * Manejo del hover de la carta inactivo
     */
    const handlerUnhover = () => {
        setHovered(false);
    };

    /*
     * Función que reedirecciona
     * a las vista de asistencias
     */
    const handlerAssistensList = () => {
        dispatch(classroomModelSelected(item.viewmodel.uuid));
        if (item.viewmodel.classroomType === 1) {
            history.push({
                pathname: `/inicio-maestros/classroom-subject/${item.viewmodel.uuid}`,
            });
        } else if (item.viewmodel.classroomType === 2) {
            history.push({
                pathname: `/inicio-maestros/classroom-group/${item.viewmodel.uuid}`,
            });
        }
    };
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: color,
                color: 'white',
                width: 300,
                height: 200, // Usar 'auto' para que la altura se ajuste al contenido
                '&:hover': {
                    boxShadow: hovered
                        ? '0px 4px 20px rgba(0, 0, 0, 0.7)'
                        : 'none',
                },
                borderRadius: 5,
            }}
        >
            <CardActionArea
                onMouseEnter={handlerHover}
                onMouseLeave={handlerUnhover}
                onClick={handlerAssistensList}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        padding: 2,
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        variant="h6"
                        style={{ color: 'white', maxWidth: '100%' }}
                    >
                        {item.group.grade}°{item.group.group}{' '}
                        {getTurnLevel(item.group.turn)}{' '}
                        {getLevelName(item.group.level)}
                    </Typography>
                    <CoPresentIcon style={{ fontSize: '85px' }} />
                    {item.viewmodel.classroomType === 1 && (
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'white',
                                maxWidth: '100%',
                                fontWeight: 'bold',
                                mt: 0.5,
                            }}
                        >
                            {item.subjectCatalog.title}
                        </Typography>
                    )}
                </Stack>
            </CardActionArea>
        </Card>
    );
};
