import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Feedback from "../../../service/Feedback";
import Authentication from "../../../service/Login";
import Services from "../../../service/Connection";
import { selectUserById, upsertOneUser as upsertOneUserEnt } from "../entities/users"

let initlaState = {
    token: null,
    user: null,
    data: {},
    server: {
        expireIn: null,
        ferchingAt: null,
        statusServer: "idle",
        statusOperation: "idle",
        didInvalidate: true,
        feedback: {
            title: null,
            message: null,
            payload: null,
        },
    },
    ui: {

    }
}

export const userSlice = createSlice({
    name: 'auth',
    initialState: initlaState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        /**
         * Agrega y actualiza valores actuales, 
         * @param {*} state 
         * @param {*} action 
         */
        upsertOneUser: (state, action) => {
            state.user = { ...state.user, ...action.payload }
        }
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return initlaState
        })

        /////////////////////// USER UPDATE /////////////////////////

        builder.addCase(updateUser.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(updateUser.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })

        //////////////////// APERMISOS ////////////////

        builder.addCase(updateUserPermissions.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(updateUserPermissions.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(updateUserPermissions.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })

        //////////////////// CONTRASEÑA ////////////////

        builder.addCase(updateUserPassword.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(updateUserPassword.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(updateUserPassword.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })

        //////////////////// NOMBRE DE USUARIO ////////////////

        builder.addCase(updateUserUsername.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(updateUserUsername.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(updateUserUsername.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })

    }
});

export const { setToken, setUser, upsertOneUser } = userSlice.actions;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 * 
 * @param {*} state 
 * @returns 
 */
export const selectUsersUI = (state) => state.auth.ui;

export const selectUsersData = (state) => state.auth.data;

export const selectUsersServer = (state) => state.auth.server;

export const selectStatusServer = (state) => state.auth.server.statusServer

export const selectStatusOperation = (state) => state.auth.server.statusOperation


export default userSlice.reducer;




/////////////////// TRUNCKS /////////////////////

/**
 * Actualizar informacion del usuario
 */
export const updateUser = createAsyncThunk(
    'authUI/user/update-data',
    async ({ userId, userData, endpoint }, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let user = await Services.updateUserData(endpoint, userId, userData).then(res => res.data.data)

            user["user_id"] = userId

            thunkAPI.dispatch(upsertOneUser(user))

            if (selectUserById(userId)(thunkAPI.getState())) {
                thunkAPI.dispatch(upsertOneUserEnt(user))
            }

            return {
                user
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)

/**
 * Actualizar los permisos del usuario
 */
export const updateUserPermissions = createAsyncThunk(
    'authUI/user/update-permissions',
    async ({ userId, permissions }, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let user = await Services.updateUserById(userId, permissions).then(res => res.data.data)

            user["user_id"] = userId

            thunkAPI.dispatch(upsertOneUser(user))

            if (selectUserById(userId)(thunkAPI.getState())) {
                thunkAPI.dispatch(upsertOneUserEnt(user))
            }

            return {
                user
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)


/**
 * Actualizar los permisos del usuario
 */
export const updateUserPassword = createAsyncThunk(
    'authUI/user/update-password',
    async ({ userId, credentialState }, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let user = await Services.updateUser(userId, credentialState).then(res => res.data.data)

            user["user_id"] = userId

            thunkAPI.dispatch(upsertOneUser(user))

            if (selectUserById(userId)(thunkAPI.getState())) {
                thunkAPI.dispatch(upsertOneUserEnt(user))
            }

            return {
                user
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)


/**
 * Actualizar nombre de usuario
 */
export const updateUserUsername = createAsyncThunk(
    'authUI/user/update-username',
    async ({ userId, usernameData }, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let user = await Services.updateUser(userId, usernameData).then(res => res.data.data)

            user["user_id"] = userId

            thunkAPI.dispatch(upsertOneUser(user))

            if (selectUserById(userId)(thunkAPI.getState())) {
                thunkAPI.dispatch(upsertOneUserEnt(user))
            }

            return {
                user
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)