import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Feedback from '../../../service/Feedback';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import { selectPaymentConcepts } from './selectors';
import Connection from '../../../service/Connection';
import { upsertOneCatalogDiscount } from '../../../store/slices/entities/payments/cat_discounts';
import { upsertManyCataloConcepts } from '../../../store/slices/entities/payments/cat_concepts';
import { upsertManyStudents } from '../../../store/slices/entities/students';
import {
    removeManyConcepts,
    upsertManyConcepts,
} from '../../../store/slices/entities/payments/concepts';
import { upsertManyGroup } from '../../../store/slices/entities/groups';
import { upsertManyCatalogSurcharges } from '../../../store/slices/entities/payments/cat_surcharges';

const emptyState = {
    fetch: {
        expireIn: null,
        fetchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    create: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    delete: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    update: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
};

export const operationsSlice = createSlice({
    name: 'operations/concepts',
    initialState: emptyState,
    reducers: {},

    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        // Handle fetchCreateConcept actions
        builder.addCase(fetchCreateConcept.rejected, (state, action) => {
            state.fetch.status = 'rejected';
            state.fetch.feedback = action.payload.feedback;
        });
        builder.addCase(fetchCreateConcept.fulfilled, (state) => {
            state.fetch.status = 'fulfilled';
        });
        builder.addCase(fetchCreateConcept.pending, (state) => {
            state.fetch.status = 'pending';
        });

        // Handle CreateConcept actions
        builder.addCase(createConcept.rejected, (state, action) => {
            state.create.status = 'rejected';
        });
        builder.addCase(createConcept.fulfilled, (state) => {
            state.create.status = 'fulfilled';
        });
        builder.addCase(createConcept.pending, (state) => {
            state.create.status = 'pending';
        });

        //handle DeleteConcept actions
        builder.addCase(DeleteConcept.rejected, (state, action) => {
            state.delete.status = 'rejected';
        });
        builder.addCase(DeleteConcept.fulfilled, (state) => {
            state.delete.status = 'fulfilled';
        });
        builder.addCase(DeleteConcept.pending, (state) => {
            state.delete.status = 'pending';
        });

        // Handle UpdateConcept actions
        builder.addCase(UpdateConcept.rejected, (state, action) => {
            state.update.status = 'rejected';
        });
        builder.addCase(UpdateConcept.fulfilled, (state) => {
            state.update.status = 'fulfilled';
        });
        builder.addCase(UpdateConcept.pending, (state) => {
            state.update.status = 'pending';
        });
    },
});

//export const {} = operationsSlice.actions;

export default operationsSlice.reducer;

export const selectOperations = createSelector(
    selectPaymentConcepts,
    (reports) => reports.operations
);

export const selectCreate = createSelector(
    selectOperations,
    (operations) => operations.create
);

export const selectCreateOperation = createSelector(
    selectCreate,
    (create) => create.status
);

export const selectStatusOperation = (state) =>
    state.createConcepts.operations.create.statusOperation;

/**
 * fetcha para la creación de un concepto
 */

export const fetchCreateConcept = createAsyncThunk(
    'Concepts/fetchCreateConcept',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const [responseGroups, responseCatSurcharge] = await Promise.all([
                Connection.groupsBySchool(schoolId),
                Connection.getSchoolsBySurchargesCatalog(schoolId),
            ]);

            const groups = responseGroups.data.data;
            const catSurcharge = responseCatSurcharge.data.data;

            thunkAPI.dispatch(upsertManyGroup(groups));
            thunkAPI.dispatch(upsertManyCatalogSurcharges(catSurcharge));

            return { groups, catSurcharge };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Agregar un concepto
 */

export const createConcept = createAsyncThunk(
    'Concepts/createConcept',
    async (data, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const response = await Connection.addConcept({ data });
            const concept = response.data.data;

            thunkAPI.dispatch(upsertManyConcepts(concept));

            return { concept };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Eliminar  conceptos
 */

export const DeleteConcept = createAsyncThunk(
    'Concepts/DeleteConcept',
    async (data, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.deleteConcept({ data });

            const deletedConcepts = response.data.data;

            thunkAPI.dispatch(upsertManyConcepts(deletedConcepts));

            return { deletedConcepts };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar conceptos
 */

export const UpdateConcept = createAsyncThunk(
    'Concepts/UpdateConcept',
    async (data, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.updateConcept({ data });
            const concept = response.data.data;

            thunkAPI.dispatch(upsertManyConcepts(concept));

            return { concept };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
