import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Card,
    CardContent,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectTotalMaleFemaleStudents,
} from '../../../store/home/fetchSlice';
import { getTotalMaleFemaleStudents } from '../../../store/home/thunks';
import { ResponsivePie } from '@nivo/pie';
import {
    SkeletongGrafics,
    SkeletonLongCard,
} from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeTotalMaleFemaleStudentsStatusServer } from '../../../store/home/componentStatusSelectors';

const TotalMaleFemaleStudents = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeTotalMaleFemaleStudentsStatusServer
    );

    const totalStudentsMaleFemale = useFetchResource(
        () =>
            getTotalMaleFemaleStudents({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectTotalMaleFemaleStudents,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getTotalMaleFemaleStudents({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectTotalMaleFemaleStudents,
            [cycleSelected, schoolId]
        );
    };

    const data = totalStudentsMaleFemale?.value?.data
        ?.map((value, index) => ({
            id: totalStudentsMaleFemale.value.labels[index],
            value: value,
        }))
        .filter((item) => item.value !== null && item.value !== '');

    const colorScheme = {
        Hombres: '#1E90FF',
        Mujeres: '#f47edd',
    };

    return (
        <Box>
            {fetchingStatus === 'pending' && <SkeletonLongCard items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        position: 'relative',
                        overflow: 'visible',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        margin: 'auto',
                    }}
                >
                    <CardContent
                        sx={{
                            width: '100%',
                            padding: '16px',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            {totalStudentsMaleFemale?.title}
                        </Typography>
                        {data && data.length > 0 ? (
                            <Box height={330}>
                                <ResponsivePie
                                    data={data}
                                    margin={{
                                        top: 20,
                                        right: 5,
                                        bottom: 20,
                                        left: 5,
                                    }}
                                    innerRadius={0.5}
                                    padAngle={0.7}
                                    cornerRadius={10}
                                    colors={({ id }) =>
                                        colorScheme[id] || '#ccc'
                                    }
                                    borderWidth={0}
                                    borderColor={{
                                        from: 'color',
                                        modifiers: [['darker', 0.2]],
                                    }}
                                    enableRadialLabels={false}
                                    enableSlicesLabels={true}
                                    enableArcLinkLabels={false}
                                    sliceLabel={({ id, value }) =>
                                        `${id}: ${value}`
                                    }
                                    theme={{
                                        labels: {
                                            text: {
                                                fontSize: 16,
                                                fill: 'white',
                                            },
                                        },
                                        tooltip: {
                                            container: {
                                                background: 'white',
                                                color: 'inherit',
                                                fontSize: 12,
                                                borderRadius: '2px',
                                                boxShadow:
                                                    '0 1px 2px rgba(0, 0, 0, 0.25)',
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        ) : (
                            <Paper elevation={3} sx={{ margin: 2, padding: 2 }}>
                                <Typography variant="h5" gutterBottom>
                                    Sin datos disponibles
                                </Typography>
                            </Paper>
                        )}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default TotalMaleFemaleStudents;
