import EficazAPI from "./EficazAPI"
import ClientHttp from "./HttpClient"
import Token from "./Token"

let token = new Token()

let IClientHttp = new ClientHttp()
IClientHttp.setToken(token)

let IEficazAPI = new EficazAPI(IClientHttp)

export default IEficazAPI