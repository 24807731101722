import { createAsyncThunk } from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import {
    selectComponentStudentsAllStudentsFetch,
    selectComponentStudentsBestAverageGradeFetch,
    selectComponentStudentsBestAverageLevelFetch,
    selectComponentStudentSchoolPerformanceSummaryFetch,
    selectComponentStudentsMoreInassistGradeFetch,
    selectComponentStudentsMoreInassistLevelFetch,
    selectComponentStudentsMostReportGradeFetch,
    selectComponentStudentsMostReportLevelFetch,
    selectComponentStudentsWorstAverageGradeFetch,
    selectComponentStudentsWorstAverageLevelFetch,
} from './componentsStatusSelectors';

export const getBestAverageLevel = createAsyncThunk(
    'fetch/best_average_level',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'students',
                    type: 'top',
                    idKey: 'best_average_level',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching LagAssists:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentStudentsBestAverageLevelFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getWorstAverageLevel = createAsyncThunk(
    'fetch/worst_average_level',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'students',
                    type: 'top',
                    idKey: 'worst_average_level',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching LagAssists:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentStudentsWorstAverageLevelFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getBestAverageGrade = createAsyncThunk(
    'fetch/best_average_grade',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'students',
                    type: 'top',
                    idKey: 'best_average_grade',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching LagAssists:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentStudentsBestAverageGradeFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getWorstAverageGrade = createAsyncThunk(
    'fetch/worst_average_grade',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'students',
                    type: 'top',
                    idKey: 'worst_average_grade',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching LagAssists:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentStudentsWorstAverageGradeFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getMoreInassistLevel = createAsyncThunk(
    'fetch/more_inassist_level',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'students',
                    type: 'top',
                    idKey: 'more_inassist_level',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching LagAssists:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentStudentsMoreInassistLevelFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getMoreInassistsGrade = createAsyncThunk(
    'fetch/more_inassists_grade',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'students',
                    type: 'top',
                    idKey: 'more_inassists_grade',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching LagAssists:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentStudentsMoreInassistGradeFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getMostReportLevel = createAsyncThunk(
    'fetch/most_report_level',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'students',
                    type: 'top',
                    idKey: 'most_report_level',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching LagAssists:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentStudentsMostReportLevelFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getMostReportGrade = createAsyncThunk(
    'fetch/most_report_grade',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'students',
                    type: 'top',
                    idKey: 'most_report_grade',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching LagAssists:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentStudentsMostReportGradeFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getAllStudentsOnDirectors = createAsyncThunk(
    'fetch/students',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'students',
                    type: 'table',
                    idKey: 'students',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching LagAssists:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentStudentsAllStudentsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const getSchoolPerformanceSummary = createAsyncThunk(
    'fetch/students',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'students',
                    type: 'top-section',
                    idKey: 'students_sumari',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching LagAssists:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentStudentSchoolPerformanceSummaryFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
