import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const schoolsAdapter = createEntityAdapter({
    selectId: (school) => school.school_id,
    sortComparer: (a, b) => a.school_id - b.school_id
})

/**
 * Slice para los parciales
 */
export const SchoolsSlice = createSlice({
    name: 'schools',
    initialState: schoolsAdapter.getInitialState(),
    reducers: {
        /**
         * Si la materia no se encuentra en las entidades es agregados
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addOneSchool: schoolsAdapter.addOne,
        /**
         * Agrega muchos elementos, si esque estos aun no se encuentran agregados
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addManySchools: schoolsAdapter.addMany,
        /**
         * Remplazar todos los parciales
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setAllSchools: schoolsAdapter.setAll,
        /**
         * Elimina un parcial de la coleccion
         * @param {*} state 
         * @param {*} action 
         */
        deleteOneSchool: schoolsAdapter.removeOne,
        /**
         * Si el elemento existe realziara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         * 
         * @param {*} state 
         * @param {*} action 
         */
        upsertOneSchool: schoolsAdapter.upsertOne

    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return schoolsAdapter.getInitialState()
        })
    }
});

/**
 * Acciones generadas por la librerias
 */
export const {
    setAllSchools, addManySchools, deleteOneSchool,
    upsertOneSchool,
    addOneSchool
} = SchoolsSlice.actions;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = schoolsAdapter.getSelectors((state) => state.entities.schools)

/**
 * Selector para recuperar todas las entidades 
 * 
 * @param any Estado completo de la app
 * 
 * @returns any
 */
export const selectEntitiesSchools = (state) => globalizedSelectors.selectEntities(state)

/**
 * Selector para recuperar todas las escuelas 
 * 
 * @param any Estado completo de la app
 *  
 * @returns any[]
 */
export const selectAllSchools = (state) => globalizedSelectors.selectAll(state)

/**
 * Selector para seleccionar una escuela por ID
 * 
 * @param number Identificador de la escuela
 * 
 * @returns any
 */
export const selectSchoolById = (id) => (state) => globalizedSelectors.selectById(state, id)

export default SchoolsSlice.reducer;
