import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectStudentsApprovedFailedGenderGrade,
} from '../../../store/home/fetchSlice';
import { getStudentsApprovedFailedGenderGrade } from '../../../store/home/thunks';
import { SkeletongGrafics } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeStudentsApprovedFailedGenderGradeStatusServer } from '../../../store/home/componentStatusSelectors';

const StudentApproventFailedGenderGradeGrafic = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeStudentsApprovedFailedGenderGradeStatusServer
    );

    const data = useFetchResource(
        () =>
            getStudentsApprovedFailedGenderGrade({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectStudentsApprovedFailedGenderGrade,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getStudentsApprovedFailedGenderGrade({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectStudentsApprovedFailedGenderGrade,
            [cycleSelected, schoolId]
        );
    };

    const [view, setView] = useState('Aprobados');

    const processStudentData = (data) => {
        if (!data || !data.value) {
            return [];
        }

        const structuredData = [];

        const addToStructuredData = (items, gender) => {
            if (!items) return;
            items.forEach((item) => {
                const label = item?.label || 'EMPTY';
                let entry = structuredData.find((d) => d.label === label);

                if (!entry) {
                    entry = {
                        label,
                        Hombres: 0,
                        Mujeres: 0,
                    };
                    structuredData.push(entry);
                }

                entry[gender] = item.data;
            });
        };

        if (view === 'Aprobados') {
            if (data.value.APROBADOS) {
                addToStructuredData(
                    data.value.APROBADOS.hombres || [],
                    'Hombres'
                );
                addToStructuredData(
                    data.value.APROBADOS.mujeres || [],
                    'Mujeres'
                );
            }
        } else {
            if (data.value.REPROBADOS) {
                addToStructuredData(
                    data.value.REPROBADOS.hombres || [],
                    'Hombres'
                );
                addToStructuredData(
                    data.value.REPROBADOS.mujeres || [],
                    'Mujeres'
                );
            }
        }

        return structuredData;
    };

    const getColor = (bar) => {
        if (bar.id === 'Hombres') return '#1E90FF';
        if (bar.id === 'Mujeres') return '#f47edd';
        return '#cccccc';
    };

    const structuredData = data ? processStudentData(data) : [];

    return (
        <Box>
            {fetchingStatus === 'pending' && <SkeletongGrafics items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        position: 'relative',
                        overflow: 'visible',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        margin: 'auto',
                    }}
                >
                    <CardContent
                        sx={{
                            width: '100%',
                            padding: '16px',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Alumnos {view.toLowerCase()} por género y grado
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '10px',
                                marginBottom: '20px',
                            }}
                        >
                            <Button
                                variant={
                                    view === 'Aprobados'
                                        ? 'contained'
                                        : 'outlined'
                                }
                                size="small"
                                onClick={() => setView('Aprobados')}
                            >
                                Aprobados
                            </Button>
                            <Button
                                variant={
                                    view === 'Reprobados'
                                        ? 'contained'
                                        : 'outlined'
                                }
                                size="small"
                                onClick={() => setView('Reprobados')}
                            >
                                Reprobados
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                height: '232px',
                                minWidth: '200px',
                            }}
                        >
                            <ResponsiveBar
                                data={structuredData}
                                keys={['Hombres', 'Mujeres']}
                                indexBy="label"
                                margin={{
                                    top: 10,
                                    right: 20,
                                    bottom: 50,
                                    left: 30,
                                }}
                                padding={0.2}
                                colors={getColor}
                                borderRadius={5}
                                groupMode="stacked"
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 45,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: 60,
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Cantidad de Alumnos',
                                    legendPosition: 'middle',
                                    legendOffset: -50,
                                }}
                                labelSkipWidth={0}
                                labelSkipHeight={0}
                                labelTextColor="white"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                theme={{
                                    axis: {
                                        ticks: {
                                            text: { fontSize: 12 },
                                        },
                                    },
                                    labels: {
                                        text: {
                                            fontSize: 16,
                                            fill: 'white',
                                        },
                                    },
                                    tooltip: {
                                        container: {
                                            background: 'white',
                                            color: 'inherit',
                                            fontSize: 12,
                                            borderRadius: '2px',
                                            boxShadow:
                                                '0 1px 2px rgba(0, 0, 0, 0.25)',
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default StudentApproventFailedGenderGradeGrafic;
