import * as React from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
//import NumberFormat from 'react-number-format';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="0000-0000"
      definitions={{
        'yearone': /[1-9]{4}/,
        'yeartwo': /[1-9]{4}/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


export default function CycleSchool(props) {
  const {id, name, label, variant, size, error, helperText} = props


  const [value, setValues] = React.useState(props.value);

  const handleChange = (event) => {
    setValues(event.target.value);
    props.onChange(event)
  };

  return (
      <TextField
        id={id}
        name={name} 
        label={label}
        variant={variant}
        size={size}
        error={error}
        helperText={helperText}

        value={value}
        onChange={handleChange}
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
      />
  );
}