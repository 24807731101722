import { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useFeedback from '../../../hooks/useFeedback';

import { Box, Button, Snackbar, Tooltip, Typography } from '@mui/material';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SyncIcon from '@mui/icons-material/Sync';

import { selectSubjectById } from '../../../store/slices/professorModule/entitiesSlice';
import {
    fetchSubjectAditionalData,
    selectItemFetchingStatusSelected,
    selectItemSelected,
    invalidate,
} from '../../../store/slices/professorModule/ItemsSlice';

import { Error } from '../../../components/Feedback';
import ExportablesModal from '../../../components/Chart/ExportableModal/ExportablesModal';
import { ScoresCard, SkeletonBlock } from './components';
import { SubjectPreviewCard } from '../../components';
import FeatureFlags from '../../../service/FeatureFlags';
import moment from 'moment';

/**
 * Vista de calificaciones
 *
 * @returns
 */
const ScoreEdit = () => {
    ////////// HOOKS //////////
    const { id } = useParams();
    const dispatch = useDispatch();
    const Auth = useAuth();
    const feedbackApp = useFeedback();

    moment.locale('es');
    const schoolId = Auth.getUser().school_id;

    ////////// LOCAL STATE //////////
    const [openExportModal, setOpenExportModal] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState(null);

    const [reportData, setReportData] = useState({
        report: null,
        title: null,
        query: { subject_id: null, group_id: null },
    });

    ////////////////////// SHARES STATUS //////////////////////

    const groupItem = useSelector(selectItemSelected);

    const serverStatus = useSelector(selectItemFetchingStatusSelected);

    const subject = useSelector(selectSubjectById(id));

    ////////////////////////// ACTIONS //////////////////////////

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(id));
        reload();
    };

    /**
     * Recargar los datos
     */
    const reload = () => {
        dispatch(
            fetchSubjectAditionalData({
                groupId: subject.group_id,
                subjectId: subject.subject_id,
                curretnDate: moment().format('YYYY-MM-DD'),
            })
        );
    };

    /**
     * Evento lanzado cuanto el proceso
     * de guardar o crear calificaciones ha terminado
     */
    const handleSaveScores = (type, data) => {
        if (type == 'done') {
            feedbackApp.showFeedback({
                title: 'Calificaciones registradas',
            });
        } else {
            feedbackApp.showFeedback({
                title: data.feedback.title,
            });
        }
    };

    ///////////////////////// EFECTOS SECUNDARIOS ////////////////////////

    useEffect(() => {
        if (id) {
            reload();
        }
    }, [id]);

    /*
     * Función para cerrar el modal
     * de exportaciones
     */
    const handleCloseExportableModal = () => {
        setOpenExportModal(false);
    };

    /*
     * Función para abrir el modal
     * de exportaciones
     */
    const handleExport = (exportOptions) => {
        const { id, title, report } = exportOptions;
        const pre = {
            id,
            title,
            report,
            query: {
                subject_id: id,
                group_id: groupItem.group_id,
            },
        };

        setSelectedTitle(title);
        setReportData(pre);
        setOpenExportModal(true);
    };

    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {serverStatus == 'pending' && <SkeletonBlock />}

            {serverStatus == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {serverStatus == 'fulfilled' && (
                <>
                    <Box
                        sx={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="h4" component="div">
                            Calificaciones
                        </Typography>

                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}
                            >
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>

                    <SubjectPreviewCard subjectId={subject.subject_id} />
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() =>
                            handleExport({
                                id: subject.subject_id,
                                title: 'Calificaciones',
                                report: 'subject-califications',
                            })
                        }
                        startIcon={<FileDownloadIcon />}
                    >
                        Exportar calificaciones
                    </Button>

                    <ScoresCard
                        schoolId={schoolId}
                        groupId={subject?.group_id}
                        subjectId={subject?.subject_id}
                        onSave={handleSaveScores}
                    />
                </>
            )}

            <ExportablesModal
                show={openExportModal}
                data={reportData}
                selectedTitle={selectedTitle}
                onClose={handleCloseExportableModal}
            />
        </Box>
    );
};

export default ScoreEdit;
