import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Card, CardActions, CardContent } from '@mui/material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

import { selectOneSettingBySchoolId } from '../../../../store/slices/entities/settings';
import {
    selectGroupById,
    selectPartialsByLevel,
    selectScoresBySubject,
    selectStudentsByGroup,
} from '../../../../store/slices/professorModule/entitiesSlice';
import {
    selectItemOperationStatusSelected,
    storeCalificationList,
} from '../../../../store/slices/professorModule/ItemsSlice';

import ScoreTextFiend from '../../../../components/common/ScoreTextField';
import CalificationMenu from './CalificationMenu';

import FeatureFlags from '../../../../service/FeatureFlags';
import moment from 'moment';
import { DateTime } from 'luxon';
import { round } from '../../../../helper';

/**
 * Componente que muestra un un formulario para crear
 * o actualziar calificaciones de un grupo
 *
 * @param {*} param0
 *
 * @returns
 */
const ScoresCard = ({ schoolId, groupId, subjectId, onSave }) => {
    moment.locale('es');
    const dispatch = useDispatch();

    let currentDate = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');

    /////////////////// SHARES STATUS //////////////////
    const group = useSelector(selectGroupById(groupId));

    const allCalifications = useSelector((store) =>
        selectScoresBySubject(store, subjectId)
    );

    const studentsList = useSelector(selectStudentsByGroup(groupId));
    const partials = useSelector((store) =>
        selectPartialsByLevel(store, group.level)
    );

    const scoreMin = useSelector(
        selectOneSettingBySchoolId(schoolId, 'calification-minimum')
    );

    const recoveryDays = useSelector(
        selectOneSettingBySchoolId(schoolId, 'recovery-days')
    );

    const operationState = useSelector(selectItemOperationStatusSelected);

    /////////////////////// LOCAL STATE ////////////////////

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [hasRegularization, setHasRegularization] = useState(false);
    const [invalidScore, setInvalidScore] = useState(false);

    // const formValid = rows.some((i) => {
    //     return Object.values(i.partialsItems).some((p) => !p.isValid);
    // });

    ////////////////////// ACTIONS //////////////////////////

    /*
     * UseEffect que da formato a la data
     * de calificaciones, conectando materias, estudiantes y calificaciones
     */
    useEffect(() => {
        let studentItems = studentsList.map((student) => {
            let partialsAdditionalProp = {};
            let partialsItems = {};

            let regularizationsAdditionalProp = {};
            let regularizationsItems = {};

            let totalOverage = 0;

            let total = 0;
            let calificationsNum = 0;
            let regTotal = 0;
            let regCalificationsNum = 0;

            let lastApprovedValue = 0;

            for (let partial of partials) {
                if (partial.is_regularized === false) {
                    let caliBySubject = allCalifications.find(
                        (d) =>
                            d.student_id == student.student_id &&
                            d.partial_id == partial.partial_id
                    );

                    let cal = 0;

                    if (caliBySubject?.calification) {
                        total += caliBySubject.calification;
                        cal = caliBySubject.calification;
                        ++calificationsNum;
                    }

                    let partialName = `p-${partial.partial_id}`;

                    let limitDate = moment(partial.limit_date);
                    limitDate.add(recoveryDays.value, 'days');
                    let limitDateFormatted = limitDate.format(
                        'YYYY-MM-DD hh:mm:ss'
                    );

                    partialsAdditionalProp[partialName] = {
                        value: caliBySubject?.calification
                            ? caliBySubject.calification
                            : 0,
                        description: caliBySubject?.description || '',
                        in_recovery: caliBySubject?.in_recovery || false,
                        add_description: '',
                    };

                    partialsItems[partialName] = {
                        partial: partial,
                        score: caliBySubject,
                        value: cal,
                        isExists: caliBySubject !== undefined,
                        partialActive:
                            currentDate >= partial.start_date &&
                            currentDate <= limitDateFormatted,
                        isValid: true,
                    };

                    let average = total > 0 ? total / calificationsNum : 0;

                    totalOverage = round(average, 1);

                    partialsAdditionalProp['total'] = totalOverage;
                } else {
                    let caliBySubject = allCalifications.find(
                        (d) =>
                            d.student_id == student.student_id &&
                            d.partial_id == partial.partial_id
                    );

                    let calreg = 0;

                    if (caliBySubject?.calification) {
                        regTotal += caliBySubject.calification;
                        calreg = caliBySubject.calification;
                        ++regCalificationsNum;

                        if (caliBySubject.calification >= scoreMin?.value) {
                            lastApprovedValue = calreg;
                        }
                    }

                    let regularizationName = `r-${partial.partial_id}`;

                    regularizationsAdditionalProp[regularizationName] = {
                        value: caliBySubject?.calification
                            ? caliBySubject.calification
                            : 0,
                        description: caliBySubject?.description || '',
                        in_recovery: false,
                        add_description: '',
                    };

                    regularizationsItems[regularizationName] = {
                        regularization: partial,
                        score: caliBySubject,
                        value: {
                            value: calreg,
                            description: caliBySubject?.description || '',
                            in_recovery: false,
                        },
                        isExists: caliBySubject !== undefined,
                        description:
                            caliBySubject !== undefined
                                ? caliBySubject.description
                                : '',
                        partialActive:
                            currentDate >= partial.start_date &&
                            currentDate <= partial.limit_date,
                        isValid: true,
                    };

                    let totalreg;

                    if (lastApprovedValue <= scoreMin?.value) {
                        totalreg = totalOverage;
                    } else {
                        totalreg = Math.max(totalOverage, lastApprovedValue);
                    }

                    regularizationsAdditionalProp['totalreg'] = totalreg;

                    const hasRegularization = Object.keys(
                        regularizationsItems
                    ).some((key) => {
                        const regularizationItem = regularizationsItems[key];
                        return (
                            Object.keys(regularizationsItems).length > 0 &&
                            regularizationItem.partialActive === true
                        );
                    });

                    setHasRegularization(hasRegularization);
                }
            }

            return {
                ...student,
                ...partialsAdditionalProp,
                ...regularizationsAdditionalProp,
                regularizationsItems,
                partialsItems,
            };
        });
        setRows(studentItems);
    }, [subjectId, allCalifications]);

    /**
     * Columnas del datagrid
     */
    useEffect(() => {
        let columnsItems = [
            {
                column: 'student',
                title: 'ALUMNOS',
                sticky: true,
                className: '',
            },
        ];
        const partialColumns = partials
            .filter((partial) => !partial.is_regularized)
            .map((partial) => ({
                column: `p-${partial.partial_id}`,
                title: `P${partial.partial}`,
                sticky: false,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            }));

        columnsItems = [...columnsItems, ...partialColumns];
        columnsItems.push({
            column: `total`,
            title: `PROMEDIO`,
            sticky: false,
            className: 'parcial-column',
        });

        const regularizedColumns = partials
            .filter((partial) => partial.is_regularized)
            .map((partial) => ({
                column: `r-${partial.partial_id}`,
                title: `R${partial.partial}`,
                sticky: false,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            }));

        columnsItems = [...columnsItems, ...regularizedColumns];

        columnsItems.push({
            column: `totalreg`,
            title: `FINAL`,
            sticky: false,
            className: 'parcial-column',
        });

        //////////////////// FORMATO DE LAS COLUMNAS /////////////////////

        let columns = columnsItems.map((i) => {
            return {
                field: i.column,
                headerName: i.title,
                flex: 0.4,
                maxWidth: 'auto',
                editable: false,
                headerAlign: 'center',
                align: 'center',
                headerClassName: i.partialActive ? 'partial-active--cell' : '',
                cellClassName: (params) => {
                    let row =
                        params.row.partialsItems[params.field]?.partialActive;
                    if (row) {
                        return 'partial-active--cell';
                    }

                    return '';
                },
                valueGetter: (params) => {
                    if (params.field === 'student') {
                        return `${params.row.name} ${params.row.last_name} ${params.row.second_last_name}`;
                    }

                    return params.value;
                },
                renderCell: (params) => {
                    if (params.field === 'total') {
                        return params.value;
                    }

                    if (params.field === 'totalreg') {
                        return params.value;
                    }

                    if (params.field === 'student') {
                        return params.value;
                    }

                    let partial = params.row[params.field];

                    let isInput = false;
                    let isRegularizationActive = false;

                    if (!hasRegularization) {
                        isInput =
                            params.row.partialsItems[params.field]
                                ?.partialActive === true;
                    } else {
                        isInput =
                            params.row.regularizationsItems[params.field]
                                ?.partialActive === true &&
                            params.row.total <= scoreMin?.value &&
                            params.row.totalreg <= scoreMin?.value;

                        isRegularizationActive =
                            params.row.regularizationsItems[params.field]
                                ?.partialActive === true;
                    }

                    const shouldEnableInputs =
                        !params.row.regularizationsItems[params.field]
                            ?.isExists ||
                        (params.row.total === scoreMin?.value &&
                            params.row.totalreg === scoreMin?.value);

                    return (
                        <CalificationMenu
                            value={partial.value}
                            description={partial.description}
                            in_recovery={partial.in_recovery}
                            studentId={params.row.student_id}
                            add_description={partial.add_description}
                            column={params.field}
                            onChange={isInput ? handleInputChange : undefined}
                            scoreMin={scoreMin}
                            disabled={
                                !isInput ||
                                (hasRegularization && !shouldEnableInputs)
                            }
                        />
                    );
                },
            };
        });

        const studentColumnIndex = columns.findIndex(
            (column) => column.field === 'student'
        );
        if (studentColumnIndex !== -1) {
            columns[studentColumnIndex].maxWidth = 300;
        }

        setColumns(columns);
    }, [subjectId, rows, partials]);

    /**
     * Actualizacion de los inputs del menu de calificaciones
     *
     * @param integer studentId Id del alumno que representa el id del row
     * @param string partialColumnName Nombre de la columna
     */
    const handleInputChange =
        ({ studentId, column, isCheckbox, isComment }) =>
        (e) => {
            if (e.key === 'r' || e.key === 'c') {
                e.preventDefault();
                return;
            }
            const value = e.target.value;
            const checked = e.target.checked;

            let regex = /^(100|(\d{1,2})(\.\d{1,2})?)?$/;

            let nextRows = rows.map((row) => {
                const isPartialItem = column.startsWith('p-');
                const isRegularizationItem = column.startsWith('r-');

                const updatedRow = { ...row };

                if (studentId === row.student_id) {
                    if (isCheckbox) {
                        updatedRow[column] = {
                            ...updatedRow[column],
                            in_recovery: checked,
                            changed: true,
                        };
                    } else if (!isComment) {
                        updatedRow[column] = {
                            ...updatedRow[column],
                            value: value,
                            changed: true,
                        };
                        if (Number(value) > 10 || isNaN(value)) {
                            setInvalidScore(true);
                        } else {
                            setInvalidScore(false);
                        }
                    } else {
                        updatedRow[column] = {
                            ...updatedRow[column],
                            add_description: value,
                            changed: true,
                        };
                    }
                }

                if (isPartialItem || isRegularizationItem) {
                    updatedRow[column] = {
                        ...updatedRow[column],
                        isValid: regex.test(updatedRow[column].value),
                    };
                }

                return updatedRow;
            });

            setRows(nextRows);
        };

    /**
     * Registra las calificaciones
     */
    const handleSaveCalificationList = () => {
        let calificationNotProceced = [];
        for (let student of rows) {
            for (const property in student) {
                if (/p-[0-9]+/.test(property)) {
                    const {
                        value,
                        description,
                        in_recovery,
                        add_description,
                        ...partialWithoutValue
                    } = student[property];
                    calificationNotProceced.push({
                        value: value,
                        description: `${description} ${add_description}`,
                        in_recovery: in_recovery,
                        student: student,
                        isExists: student.partialsItems[property].isExists,
                        score: student.partialsItems[property].score,
                        partial: student.partialsItems[property].partial,
                        changed: student.partialsItems[property].changed,
                        ...partialWithoutValue,
                    });
                } else if (/r-[0-9]+/.test(property)) {
                    const {
                        value,
                        description,
                        in_recovery,
                        add_description,
                        ...regularizationWithoutValue
                    } = student[property];
                    calificationNotProceced.push({
                        value: value,
                        description: `${description} ${add_description}`,
                        in_recovery: in_recovery,
                        student: student,
                        isExists:
                            student.regularizationsItems[property].isExists,
                        score: student.regularizationsItems[property].score,
                        regularization:
                            student.regularizationsItems[property]
                                .regularization,
                        ...regularizationWithoutValue,
                    });
                }
            }
        }

        let califications = calificationNotProceced.filter((item) => {
            return (
                item.value != 0 &&
                item.value != '' &&
                item.value != null &&
                item.value <= 10 &&
                item.changed === true
            );
        });

        const updateRequest = califications
            .filter((item) => item.isExists)
            .map((item) => ({
                calification: item.value,
                calification_id: item.score.calification_id,
                description: item.description,
                in_recovery: item.in_recovery,
            }));

        const createRequest = califications
            .filter((item) => !item.isExists)
            .map((item) => ({
                is_final: 0,
                calification: item.value,
                partial_id: item.partial
                    ? item.partial.partial_id
                    : item.regularization.partial_id,
                student_id: item.student.student_id,
                subject_id: subjectId,
                description: item.description,
                in_recovery: item.in_recovery,
            }));

        dispatch(
            storeCalificationList({
                subjectId,
                update: updateRequest,
                create: createRequest,
            })
        )
            .unwrap()
            .then((data) => {
                onSave('done', data);
            })
            .catch((err) => {
                onSave('err', err);
            });
    };

    return (
        <Card
            sx={{
                pt: 0,
                pl: 0,
                pr: 0,
                pb: 0,
                ' & .MuiDataGrid-root': {
                    border: 'none',
                },
            }}
        >
            <CardContent>
                <Box
                    sx={{
                        '& .partial-active--cell': {
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            color: '#1a3e72',
                            fontWeight: '600',
                            overflowX: 'auto',
                            maxHeight: '500px',
                        },
                    }}
                >
                    <DataGrid
                        getRowId={(row) => row.student_id}
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={rows}
                        columns={columns}
                        components={{
                            Toolbar: () => (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                    }}
                                >
                                    <GridToolbar />
                                </div>
                            ),
                        }}
                        componentsProps={{
                            panel: {
                                placement: 'bottom-end',
                            },
                        }}
                        rowsPerPageOptions={[]}
                        disableSelectionOnClick
                        autoHeight
                        disableDensitySelector
                    />
                </Box>
            </CardContent>
            <CardActions
                sx={{
                    direction: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >
                <LoadingButton
                    size="small"
                    endIcon={<SendIcon />}
                    loading={operationState == 'pending'}
                    loadingPosition="end"
                    variant="contained"
                    onClick={handleSaveCalificationList}
                    disabled={invalidScore}
                >
                    Guardar
                </LoadingButton>
            </CardActions>
        </Card>
    );
};

export default ScoresCard;
