import { createSelector, createSlice } from '@reduxjs/toolkit';
import { selectNoticesHome } from './selectors';
import { selectNotices } from '../../../store/slices/entities/notices';
import { selectAllUsers } from '../../../store/slices/entities/users';
import { selectAllNotifications } from '../../../store/slices/entities/notifications';
import { selectNoticeDetails } from '../noticeDetails/selectors';

let emptyState = [];

export const itemsSlice = createSlice({
    name: 'items',
    initialState: emptyState,
    reducers: {
        setAllItems: (state, action) => {
            return action.payload;
        },
        addOneItem: (state, action) => {
            state.push(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', () => {
            return emptyState;
        });
    },
});

export const { setAllItems, addOneItem } = itemsSlice.actions;

export default itemsSlice.reducer;

export const selectAllNoticesItemsEntities = createSelector(
    selectNoticesHome,
    (home) => {
        return home.items;
    }
);

//Traer solo las noticias tipo 1
export const selectAllNoticesItems = createSelector(
    selectAllNoticesItemsEntities,
    selectNotices,
    selectNoticeDetails,
    selectAllUsers,
    selectAllNotifications,
    (
        noticesDetailsVMEUUIDS,
        noticesEntities,
        viewModels,
        users,
        notifications
    ) => {
        return noticesDetailsVMEUUIDS
            .map((i) => {
                let currentViewModel = viewModels[i];

                let emitter = users.find(
                    (user) =>
                        user.user_id ===
                        noticesEntities[currentViewModel.ui.notice_id].user_id
                );

                let noticeNotifications = notifications.filter(
                    (notification) =>
                        notification.notice_id ===
                        noticesEntities[currentViewModel.ui.notice_id].notice_id
                );
                let sentNotification = noticeNotifications.filter(
                    (sent) => sent.status === 1
                );
                let noticedtification = noticeNotifications.filter(
                    (sent) => sent.status === 2
                );
                let readNotification = noticeNotifications.filter(
                    (sent) => sent.status === 3
                );
                return {
                    ...noticesEntities[currentViewModel.ui.notice_id],
                    viewModel: currentViewModel,
                    emitter: emitter,
                    sentNotifications: sentNotification,
                    noticedNotifications: noticedtification,
                    readNotifications: readNotification,
                };
            })
            .sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
    }
);
