import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const surchargeAdapter = createEntityAdapter({
    selectId: (surcharge) => surcharge.surcharge_id,
    sortComparer: (a, b) => a.surcharge_id - b.surcharge_id,
});

export const SurchargeSlice = createSlice({
    name: 'surcharge',
    initialState: surchargeAdapter.getInitialState(),
    reducers: {
        setAllSurcharges: surchargeAdapter.setAll,
        addManySurcharges: surchargeAdapter.addMany,
        addOneSurcharge: surchargeAdapter.addOne,
        removeOneSurcharge: surchargeAdapter.removeOne,
        upsertOneSurcharge: surchargeAdapter.upsertOne,
        upsertManySurcharges: surchargeAdapter.upsertMany,
        updateManySurcharges: surchargeAdapter.updateMany,
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return surchargeAdapter.getInitialState();
        });
    },
});

export const {
    setAllSurcharges,
    addManySurcharges,
    addOneSurcharge,
    removeOneSurcharge,
    upsertOneSurcharge,
    upsertManySurcharges,
    updateManySurcharges,
} = SurchargeSlice.actions;

const globalizedSelectors = surchargeAdapter.getSelectors(
    (state) => state.entities.surcharge
);

export default SurchargeSlice.reducer;
