import React, { useEffect } from 'react';
import { useAuth } from '../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { selectClassroomIdAndType } from '../../store/meGroups/home/ItemsSlice';
import {
    newFetchInitialData,
    selectFetchingStatus,
} from '../../store/meGroups/home/fetchSlice';
import { invalidate } from '../../store/meGroups/home/fetchSlice';
import { SkeletonBlock, GroupCard } from './components';
import { Empty, Error } from '../../../components/Feedback';
import { ClassroomSubjectContainer } from './components/ClassroomSubjectContainer';
import { ClassroomGroupContainer } from './components/ClassroomGroupContainer';
import { ClassroomLevelContainer } from './components/ClassroomLevelContainer';
import * as _ from 'lodash';

const HomeProfessors = () => {
    ////////// HOOKS //////////

    const Auth = useAuth();
    const dispatch = useDispatch();

    // //////////////// SHARES STATE /////////////////

    const groupsItemsIds = useSelector(selectClassroomIdAndType);
    const fetchingStatus = useSelector(selectFetchingStatus);

    let colors = [
        '#FFBF43',
        '#FF8660',
        '#FF4A8B',
        '#00C483',
        '#63A1DF',
        '#FF6060',
    ];

    let classroomsRows = _.chain(groupsItemsIds)
        .chunk(6)
        .map((groupsIds) =>
            groupsIds.map((item, index) => ({
                ...item,
                background: colors[index],
            }))
        )
        .flatten()
        .chunk(3)
        .flatten()
        .value();

    ///////////// ACTIONS /////////////////

    /**
     * Iniciar el proceso de recuperacion de datos
     */
    const reload = () => {
        dispatch(
            newFetchInitialData({
                professorId: Auth.getUserID(),
                schoolId: Auth.user.school_id,
            })
        );
    };

    /**
     * UseEffect que ejecuta el proceso
     * de recuperación de datos
     */
    useEffect(() => {
        reload();
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate());
        reload();
    };
    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {fetchingStatus == 'pending' && <SkeletonBlock items={9} />}

            {fetchingStatus == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus == 'fulfilled' && (
                <>
                    <Box
                        style={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="h4" component="div">
                            Mis Classrooms
                        </Typography>

                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}
                            >
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>

                    <Box sx={{ mt: 5 }}>
                        {classroomsRows.length == 0 ? (
                            <Empty label="Sin grupos" />
                        ) : (
                            <Grid
                                container
                                spacing={{ xs: 12, md: 12, sm: 12, lg: 4 }}
                            >
                                {classroomsRows.map((item, index) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={3}
                                            key={index}
                                        >
                                            {item.classroomType === 1 ? (
                                                <ClassroomSubjectContainer
                                                    item={item}
                                                />
                                            ) : item.classroomType == 2 ? (
                                                <ClassroomGroupContainer
                                                    item={item}
                                                />
                                            ) : (
                                                <ClassroomLevelContainer
                                                    item={item}
                                                />
                                            )}
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default HomeProfessors;
