import { createSlice } from '@reduxjs/toolkit';
import { selectGroupsById } from '../groups';

const emptyState = {
    byId: {},
};

/**
 * Slice para los parciales
 */
export const ReportsSlice = createSlice({
    name: 'reports',
    initialState: emptyState,
    reducers: {
        /**
         * Si la materia no se encuentra en las entidades es agregados
         *
         * @param {*} state
         * @param {*} action
         */
        addOneReports: (state, action) => {
            if (!state.byId[action.payload.report_id]) {
                state.byId[action.payload.report_id] = action.payload;
            }
        },
        /**
         * Agrega muchos elementos, si esque estos aun no se encuentran agregados
         *
         * @param {*} state
         * @param {*} action
         */
        addManyReports: (state, action) => {
            state.byId = { ...state.byId, ...action.payload };
        },
        /**
         * Remplazar todos los parciales
         *
         * @param {*} state
         * @param {*} action
         */
        setReports: (state, action) => {
            state.byId = action.payload;
        },
        updateReport: (state, action) => {
            const {
                partial_id,
                partial,
                start_date,
                end_date,
                limit_date,
                level,
            } = action.payload;
            // find this todo in the list
            const parcial = state.list.find((t) => t.partial_id === partial_id);
            // update that todo
            parcial.partial = partial;
            parcial.start_date = start_date;
            parcial.end_date = end_date;
            parcial.limit_date = limit_date;
            parcial.level = level;
            // note: .find() might return undefined, so you may want to handle that
        },
        /**
         * Elimina un parcial de la coleccion
         * @param {*} state
         * @param {*} action
         */
        removeOneReport: (state, action) => {
            delete state.byId[action.payload.report_id];
        },
        /**
         * Si el elemento existe realziara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         *
         * @param {*} state
         * @param {*} action
         */
        upsertOneReport: (state, action) => {
            let { report_id } = action.payload;

            state.byId[report_id] = {
                ...state.byId[report_id],
                ...action.payload,
            };
        },
        /**
         * Si el elemento existe realizara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         *
         * @param {*} state
         * @param {*} action
         */
        upsertManyReports: (state, action) => {
            for (const catalog of action.payload) {
                let { report_id } = catalog;

                state.byId[report_id] = {
                    ...state.byId[report_id],
                    ...catalog,
                };
            }
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });
    },
});

/**
 * Acciones generadas por la librerias
 */
export const {
    setReports,
    addManyReports,
    removeOneReport,
    updateReport,
    addOneReport,
    upsertOneReport,
    upsertManyReports,
} = ReportsSlice.actions;

//////////////////// SELECTORES //////////////////

/**
 * Selector para recuperar todos los parciales de la coleccion
 *
 * @param {*} state
 * @returns
 */
export const selectSubjects = (state) => state.entities.subjects.byId;

export const selectAllReports = (state) => state.entities.reports.byId;

/**
 * Selector para recuperar parciales de una escuela especifica
 *
 * @param {*} schoolId
 * @returns
 */
export const selectSubjectDataById = (subjectId) => {
    return (state) => {
        let subject = state.entities.subjects.byId[subjectId];

        if (!subject) {
            return null;
        }

        const group = selectGroupsById(subject.group_id)(state);
        const catalog =
            state.entities.subjectcatalogs.byId[subject.catalog_report_id];

        return {
            ...subject,
            group,
            catalog,
        };
    };
};

export default ReportsSlice.reducer;
