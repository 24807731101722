import React, { useEffect } from 'react'
import { Box, Avatar, Stack } from '@mui/material'
import { useState } from 'react'
import { useDispatch } from "react-redux";
import { useAuth } from '../../../hooks';
import { LoadingButton } from '@mui/lab';
import Feedback from '../../../service/Feedback';
import useFeedback from '../../../hooks/useFeedback';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { blue } from '@mui/material/colors';
import { setUser, updateUser } from '../../../store/slices/auth'
import Connection from "./../../../service/Connection"

/**
 * Componente para cambiar la imagen de usuario
 * 
 * @returns 
 */
const UserImage = () => {
    const [feedback] = useState(() => new Feedback());
    const feedbackApp = useFeedback()
    const dispatch = useDispatch();

    const Auth = useAuth()
    let user = Auth.getUser()

    let refInputFile = React.createRef();

    //////////////// LOCAL STATE ///////////////

    const [state, setState] = useState('idle')

    //////////////// ACTIONS /////////////////

    /**
     * Actualziacion la imagen del usuario
     * 
     * @param {*} event 
     */
    function changePhotoEvent($e) {
        setState('pending')
        let imgRemplazar = $e.target.files[0]

        Auth.setUser(user)

        const url = Auth.getEndPoint()
        const userID = Auth.getUserID()

        let formData = new FormData()
        formData.append('url_photo_profile', imgRemplazar)
        formData.append('_method', 'PATCH')

        Connection.uploadUserImage(url, userID, formData)
            .then(response => {
                $e.target.value = ''

                dispatch(setUser(response.data.data))

                feedbackApp.showFeedback({
                    title: "Actualizado correctamente"
                })
            })
            .catch(err => {
                $e.target.value = ''
                let message = feedback.getMessage(err)

                feedbackApp.showFeedback({
                    title: message.title
                })
            }).then(() => {
                setState('idle')
            })

    }

    function onChangeImage() {
        refInputFile.current.click()
    }

    /**
     * Obtenemos el icono del usuario
     * 
     * @returns 
     */
    function getIconUserUI() {

        const iconSX = { width: 190, height: 190, bgcolor: blue[500], marginLeft: "auto", marginRight: "auto" }
        let iconUser = (
            <Avatar
                alt="Imagen de usuario"
                sx={iconSX}
            />
        )

        if (user.url_photo_profile != null) {
            iconUser = (<Avatar
                alt="Imagen de usuario"
                src={user.url_photo_profile}
                sx={iconSX}
            />)
        }

        return iconUser
    }


    return (
        <Stack
            component="div"
            noValidate
            autoComplete="off"
            sx={{ padding: 1 }}
        >

            <Box sx={{ display: "none" }}>
                <input ref={refInputFile} type="file" accept="image/*" onChange={changePhotoEvent} />
            </Box>
            {getIconUserUI()}

            <LoadingButton
                size="small"
                color="primary"
                sx={{ marginTop: 2 }}
                onClick={onChangeImage}
                loading={state == 'pending'}
                loadingPosition="start"
                startIcon={<FileUploadIcon fontSize="inherit" />}
                variant="contained"
            >
                Cambiar
            </LoadingButton>
        </Stack>
    )
}

export default UserImage