import { createSlice } from '@reduxjs/toolkit';
import { selectReportHome } from './selectors';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';

const emptyState = {
    groupSelected: null,
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState: emptyState,
    reducers: {
        groupSelected: (state, { payload }) => {
            state.groupSelected = payload;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });
    },
});

export const { groupSelected } = uiSlice.actions;

export default uiSlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 *
 * @param {*} state
 * @returns
 */
export const selectedGroupUI = (state) => state.reportsUI.ui;

/**
 * Selector para recuperar ui
 */
export const selectUI = createSelector(
    selectReportHome,
    (reports) => reports.ui
);

/**
 * selector para recueprar el viewmodel de home
 */
export const selectSelectedGroup = createSelector(
    selectUI,
    (ui) => ui.groupSelected
);
