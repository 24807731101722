import React, { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Autocomplete,
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Skeleton,
    TextField,
} from '@mui/material';
import { CardActions } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { VALIDATIONS } from '../../src/constants';
import Services from '../service/Connection';

const SchoolLocationInfoCard = ({ schoolsData, onUpdate, loading }) => {
    const [locationInfoLoading, setLocationInfoLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [stateValue, setStateValue] = useState(null);
    const [municipalities, setMunicipalities] = useState([]);
    const [municipalityValue, setMunicipalityValue] = useState(null);
    const [cities, setCities] = useState([]);
    const [cityValue, setCityValue] = useState(null);

    const validationSchema = Yup.object().shape({
        street: Yup.string()
            .required('Campo requerido')
            .matches(VALIDATIONS.ALPHA_NUMERIC_SPACES, 'Campo incorrecto'),
        colony: Yup.string()
            .required('Campo requerido')
            .matches(VALIDATIONS.ALPHA_NUMERIC_SPACES, 'Campo incorrecto'),
        outside_number: Yup.string()
            .matches(VALIDATIONS.ALPHA_NUMERIC_SPACES, 'Campo incorrecto')
            .required('Campo requerido'),
        inside_number: Yup.string().matches(
            VALIDATIONS.ALPHA_NUMERIC_SPACES,
            'Campo incorrecto'
        ),
        zipcode: Yup.string()
            .required('Campo requerido')
            .matches(VALIDATIONS.INTEGER, 'Campo incorrecto'),
        sector: Yup.string().required('Campo requerido'),
        zone: Yup.string().required('Campo requerido'),
        region: Yup.string().required('Campo requerido'),
    });

    const formik = useFormik({
        initialValues: {
            city_id: schoolsData.city_id,
            street: schoolsData.street || '',
            colony: schoolsData.colony || '',
            outside_number: schoolsData.outside_number || '',
            inside_number: schoolsData.inside_number || '',
            zipcode: schoolsData.zipcode || '',
            sector: schoolsData.sector || '',
            zone: schoolsData.zone || '',
            region: schoolsData.region || '',
        },
        validationSchema,
        onSubmit: (values) => {
            setLocationInfoLoading(true);
            onUpdate(values).finally(() => {
                setLocationInfoLoading(false);
            });
        },
        enableReinitialize: true,
    });

    const isFormChanged = () => {
        return (
            JSON.stringify(formik.values) !==
            JSON.stringify(formik.initialValues)
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            const statesOptions = await Services.getStates().then(
                (res) => res.data.data
            );
            setStates(statesOptions);

            if (schoolsData.city_id) {
                const city = await Services.getCityById(
                    schoolsData.city_id
                ).then((res) => res.data.data);
                const municipality = await Services.getMunicipalityByCityId(
                    city.city_id
                ).then((res) => res.data.data);
                const state = await Services.getStateByMunicipalityID(
                    municipality.municipality_id
                ).then((res) => res.data.data);
                const municipalitiesOptions =
                    await Services.getMunicipalitysByStateId(
                        state.state_id
                    ).then((res) => res.data.data);
                const citiesOptions = await Services.getCityByMunicipalityId(
                    municipality.municipality_id
                ).then((res) => res.data.data);

                setStateValue(state);
                setMunicipalityValue(municipality);
                setCities(citiesOptions);
                setMunicipalities(municipalitiesOptions);
                setCityValue(city);
            }
        };

        fetchData();
    }, [schoolsData.city_id]);

    const handleStateChange = async (newValue) => {
        setStateValue(newValue);
        setMunicipalityValue(null);
        setCityValue(null);
        setMunicipalities([]);
        setCities([]);

        if (newValue !== null) {
            const municipalitiesOptions =
                await Services.getMunicipalitysByStateId(
                    newValue.state_id
                ).then((res) => res.data.data);
            setMunicipalities(municipalitiesOptions);
        }
    };

    const handleMunicipalityChange = async (newValue) => {
        setMunicipalityValue(newValue);
        setCityValue(null);
        setCities([]);

        if (newValue !== null) {
            const citiesOptions = await Services.getCityByMunicipalityId(
                newValue.municipality_id
            ).then((res) => res.data.data);
            setCities(citiesOptions);
        }
    };

    const handleCityChange = (newValue) => {
        setCityValue(newValue);
        formik.setFieldValue('city_id', newValue ? newValue.city_id : '');
    };
    return (
        <Card sx={{ mt: 2 }}>
            <CardHeader title="Información de ubicación" />
            <CardContent>
                {loading ? (
                    <Grid item xs={12} sm={6} lg={3}>
                        <Skeleton
                            variant="rectangular"
                            height={376}
                            animation="wave"
                        />
                    </Grid>
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            spacing={2}
                            style={{ textAlign: 'justify' }}>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}>
                                <TextField
                                    id="pais"
                                    name="pais"
                                    label="País"
                                    type="text"
                                    variant="outlined"
                                    value="México"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <>
                                <Grid
                                    item
                                    xl={4}
                                    sm={4}
                                    md={4}
                                    xs={12}
                                    style={{ padding: 10 }}>
                                    <Autocomplete
                                        value={stateValue}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        getOptionLabel={(option) =>
                                            option.title
                                        }
                                        onChange={(event, newValue) =>
                                            handleStateChange(newValue)
                                        }
                                        options={states}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                sx={{
                                                    '& > img': {
                                                        mr: 2,
                                                        flexShrink: 0,
                                                    },
                                                }}
                                                {...props}>
                                                {option.title}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Estado"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xl={4}
                                    sm={4}
                                    md={4}
                                    xs={12}
                                    style={{ padding: 10 }}>
                                    <Autocomplete
                                        value={municipalityValue}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        getOptionLabel={(option) =>
                                            option.title
                                        }
                                        onChange={(event, newValue) =>
                                            handleMunicipalityChange(newValue)
                                        }
                                        options={municipalities}
                                        disabled={municipalities.length === 0}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                sx={{
                                                    '& > img': {
                                                        mr: 2,
                                                        flexShrink: 0,
                                                    },
                                                }}
                                                {...props}>
                                                {option.title}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Municipio"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xl={4}
                                    sm={4}
                                    md={4}
                                    xs={12}
                                    style={{ padding: 10 }}>
                                    <Autocomplete
                                        value={cityValue}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        getOptionLabel={(option) =>
                                            option.title
                                        }
                                        onChange={(event, newValue) =>
                                            handleCityChange(newValue)
                                        }
                                        options={cities}
                                        disabled={cities.length === 0}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                sx={{
                                                    '& > img': {
                                                        mr: 2,
                                                        flexShrink: 0,
                                                    },
                                                }}
                                                key={option.city_id}
                                                {...props}>
                                                {option.title}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Elige una ciudad"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                            </>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}>
                                <TextField
                                    id="street"
                                    name="street"
                                    label="Calle"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    {...formik.getFieldProps('street')}
                                    error={
                                        formik.touched.street &&
                                        Boolean(formik.errors.street)
                                    }
                                    helperText={
                                        formik.touched.street &&
                                        formik.errors.street
                                            ? formik.errors.street
                                            : `Caracteres disponibles: ${formik.values.street.length}/50`
                                    }
                                    onChange={(event) => {
                                        formik.handleChange(event);
                                    }}
                                    value={formik.values.street}
                                />
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}>
                                <TextField
                                    id="colony"
                                    name="colony"
                                    label="Col / Fracc"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    {...formik.getFieldProps('colony')}
                                    error={
                                        formik.touched.colony &&
                                        Boolean(formik.errors.colony)
                                    }
                                    helperText={
                                        formik.touched.colony &&
                                        formik.errors.colony
                                            ? formik.errors.colony
                                            : `Caracteres disponibles: ${formik.values.colony.length}/50`
                                    }
                                    onChange={(event) => {
                                        formik.handleChange(event);
                                    }}
                                    value={formik.values.colony}
                                />
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}>
                                <TextField
                                    id="outside_number"
                                    name="outside_number"
                                    label="No. ext."
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ maxLength: 20 }}
                                    {...formik.getFieldProps('outside_number')}
                                    error={
                                        formik.touched.outside_number &&
                                        Boolean(formik.errors.outside_number)
                                    }
                                    helperText={
                                        formik.touched.outside_number &&
                                        formik.errors.outside_number
                                            ? formik.errors.outside_number
                                            : `Caracteres disponibles: ${formik.values.outside_number.length}/20`
                                    }
                                    onChange={(event) => {
                                        formik.handleChange(event);
                                    }}
                                    value={formik.values.outside_number}
                                />
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}>
                                <TextField
                                    id="inside_number"
                                    name="inside_number"
                                    label="No. int."
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ maxLength: 20 }}
                                    {...formik.getFieldProps('inside_number')}
                                    error={
                                        formik.touched.inside_number &&
                                        Boolean(formik.errors.inside_number)
                                    }
                                    helperText={
                                        formik.touched.inside_number &&
                                        formik.errors.inside_number
                                            ? formik.errors.inside_number
                                            : `Caracteres disponibles: ${formik.values.inside_number.length}/20`
                                    }
                                    onChange={(event) => {
                                        formik.handleChange(event);
                                    }}
                                    value={formik.values.inside_number}
                                />
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}>
                                <TextField
                                    id="zipcode"
                                    name="zipcode"
                                    label="Código postal"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ maxLength: 5 }}
                                    {...formik.getFieldProps('zipcode')}
                                    error={
                                        formik.touched.zipcode &&
                                        Boolean(formik.errors.zipcode)
                                    }
                                    helperText={
                                        formik.touched.zipcode &&
                                        formik.errors.zipcode
                                            ? formik.errors.zipcode
                                            : `Caracteres disponibles: ${formik.values.zipcode.length}/5`
                                    }
                                    onChange={(event) => {
                                        formik.handleChange(event);
                                    }}
                                    value={formik.values.zipcode}
                                />
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}>
                                <TextField
                                    id="sector"
                                    name="sector"
                                    label="Sector"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ maxLength: 2 }}
                                    {...formik.getFieldProps('sector')}
                                    error={
                                        formik.touched.sector &&
                                        Boolean(formik.errors.sector)
                                    }
                                    helperText={
                                        formik.touched.sector &&
                                        formik.errors.sector
                                            ? formik.errors.sector
                                            : `Caracteres disponibles: ${formik.values.sector.length}/2`
                                    }
                                    onChange={(event) => {
                                        formik.handleChange(event);
                                    }}
                                    value={formik.values.sector}
                                />
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}>
                                <TextField
                                    id="zone"
                                    name="zone"
                                    label="Zona"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ maxLength: 3 }}
                                    {...formik.getFieldProps('zone')}
                                    error={
                                        formik.touched.zone &&
                                        Boolean(formik.errors.zone)
                                    }
                                    helperText={
                                        formik.touched.zone &&
                                        formik.errors.zone
                                            ? formik.errors.zone
                                            : `Caracteres disponibles: ${formik.values.zone.length}/3`
                                    }
                                    onChange={(event) => {
                                        formik.handleChange(event);
                                    }}
                                    value={formik.values.zone}
                                />
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}>
                                <TextField
                                    id="region"
                                    name="region"
                                    label="Región"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ maxLength: 5 }}
                                    {...formik.getFieldProps('region')}
                                    error={
                                        formik.touched.region &&
                                        Boolean(formik.errors.region)
                                    }
                                    helperText={
                                        formik.touched.region &&
                                        formik.errors.region
                                            ? formik.errors.region
                                            : `Caracteres disponibles: ${formik.values.region.length}/5`
                                    }
                                    onChange={(event) => {
                                        formik.handleChange(event);
                                    }}
                                    value={formik.values.region}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xl={4}
                            sm={4}
                            md={4}
                            xs={12}
                            style={{ padding: 10 }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            }}>
                            <CardActions>
                                <LoadingButton
                                    size="small"
                                    color="primary"
                                    type="submit"
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    disabled={
                                        !isFormChanged() || !formik.isValid
                                    }
                                    loading={locationInfoLoading}>
                                    Guardar
                                </LoadingButton>
                            </CardActions>
                        </Grid>
                    </form>
                )}
            </CardContent>
        </Card>
    );
};

export default SchoolLocationInfoCard;
