import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const conceptCatalogAdapter = createEntityAdapter({
    selectId: (concept) => concept.concept_catalog_id,
    sortComparer: (a, b) => a.concept_id - b.concept_catalog_id,
});

export const ConceptCatalogSlice = createSlice({
    name: 'concept_catalog',
    initialState: conceptCatalogAdapter.getInitialState(),
    reducers: {
        setAllCatalogConcepts: conceptCatalogAdapter.setAll,
        addManyCataloConcepts: conceptCatalogAdapter.addMany,
        addOneCataloConcept: conceptCatalogAdapter.addOne,
        removeOneCataloConcept: conceptCatalogAdapter.removeOne,
        upsertOneCataloConcept: conceptCatalogAdapter.upsertOne,
        upsertManyCataloConcepts: conceptCatalogAdapter.upsertMany,
        updateManyCataloConcepts: conceptCatalogAdapter.updateMany,
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return conceptCatalogAdapter.getInitialState();
        });
    },
});

export const {
    setAllCatalogConcepts,
    addManyCataloConcepts,
    addOneCataloConcept,
    removeOneCataloConcept,
    upsertOneCataloConcept,
    upsertManyCataloConcepts,
    updateManyCataloConcepts,
} = ConceptCatalogSlice.actions;

const globalizedSelectors = conceptCatalogAdapter.getSelectors(
    (state) => state.entities.concept_catalog
);

export default ConceptCatalogSlice.reducer;
