import { combineReducers } from '@reduxjs/toolkit';

import ui from './uiSlice';
import items from './itemsSlice';
import fetch from './fetchSlice';
import operations from './operationsSlice';

export default combineReducers({
    items,
    ui,
    fetch,
    operations,
});
