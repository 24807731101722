import { useHistory } from 'react-router-dom';
import { useAuth } from '../hooks';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { appClear } from '../store/actions';
import FeatureFlags from '../service/FeatureFlags';

function PrivateRoute({ children, ...rest }) {
    let auth = useAuth();

    const history = useHistory();

    const dispatch = useDispatch();
    const currentState = useSelector((state) => state);
    const [lastState, setLastState] = useState(null);
    /*
     * useEffect para el logout por inactividad
     */

    useEffect(() => {
        if (FeatureFlags.isFeatureFlagActive('NEW_IDLE_STORE_LOGOUT')) {
            // Función para comparar el estado actual con el estado anterior
            const hasStateChanged = () => {
                return (
                    JSON.stringify(currentState) !== JSON.stringify(lastState)
                );
            };

            // Función para realizar el logout si el estado no ha cambiado
            const logoutIfInactive = () => {
                if (!hasStateChanged()) {
                    dispatch(appClear());
                    auth.logout();
                    history.push({ pathname: '/' });
                }
            };

            // Configurar el temporizador para realizar la verificación periódica
            const intervalId = setInterval(
                logoutIfInactive,
                Number(process.env.REACT_APP_LOGOUT_TIME_MS_IDLE_STORE)
            );
            // Limpiar el temporizador al desmontar el componente
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [dispatch, currentState, lastState]);

    // Actualizar el estado anterior cada vez que el estado actual cambia
    useEffect(() => {
        if (FeatureFlags.isFeatureFlagActive('NEW_IDLE_STORE_LOGOUT')) {
            setLastState(currentState);
        }
    }, [currentState]);

    const render =
        () =>
        ({ location }) => {
            if (!auth.getUser()) {
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                );
            } else {
                return children;
            }
        };

    return <Route {...rest} render={render()} />;
}

export default PrivateRoute;
