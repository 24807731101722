import { createSelector, createSlice } from '@reduxjs/toolkit';
import { selectedReprobationNoticesUI } from './selectors';

const emptyState = {
    noticeSelected: null,
    filters: {},
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {},
    reducers: {
        noticeReprobationSelected: (state, { payload }) => {
            state.noticeSelected = payload;
        },
        updateReprobationFilters: (state, { payload }) => {
            const { filter } = payload;
            state.filters = { ...filter };
        },
    },

    extraReducers: (builder) => {
        builder.addCase('app/clear', () => {
            return emptyState;
        });
    },
});

export const { noticeReprobationSelected, updateReprobationFilters } =
    uiSlice.actions;

export default uiSlice.reducer;

/////////// SELECTORS ///////////

export const selectSelectedReprobationNoticeUI = createSelector(
    selectedReprobationNoticesUI,
    (ui) => {
        return ui.noticeSelected;
    }
);

export const selectReprobationNoticesFilter = createSelector(
    selectedReprobationNoticesUI,
    (ui) => {
        return ui.filters;
    }
);
