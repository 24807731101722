import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';

const CardItem = ({ backgroundColor, iconColor, icon, text, textColor, title }) => {
    return (
        <Card sx={{ display: 'flex' }} style={{ height: 80, width: '100%', backgroundColor: backgroundColor }}>
            <Box sx={{ width: 70, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Avatar sx={{ bgcolor: iconColor }}>
                    <FontAwesomeIcon icon={icon} />
                </Avatar>
            </Box>

            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box>
                    <Typography variant="h6" component="h2" style={{ color: textColor }}>
                        {text}
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle1" gutterBottom component="span" style={{ color: textColor }}>
                        {title}
                    </Typography>
                </Box>
            </CardContent>

        </Card>
    )
}

export default CardItem;
