import React from 'react';
import { useAuth } from '../../../../hooks';
import { useSelector } from 'react-redux';
import { selectClassroomSubjectDetails } from '../../../store/meGroups/classrooms';
import { ClassroomCard } from './ClassroomCard';

export const ClassroomSubjectContainer = ({ item }) => {
    const Auth = useAuth();
    const classroomSubject = useSelector(
        selectClassroomSubjectDetails(item.uuid, Auth.user.school_id)
    );
    return (
        <div>
            <ClassroomCard item={classroomSubject} color={item.background} />
        </div>
    );
};
