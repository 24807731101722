import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import { selectPaymentSurcharges } from './selectors';
import {
    setAllSurcharges,
    upsertManySurcharges,
} from '../../../store/slices/entities/payments/surcharges';
import { upsertManyCatalogSurcharges } from '../../../store/slices/entities/payments/cat_surcharges';
import { upsertManyCataloConcepts } from '../../../store/slices/entities/payments/cat_concepts';
import { upsertManyConcepts } from '../../../store/slices/entities/payments/concepts';
import { upsertManyStudents } from '../../../store/slices/entities/students';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../service/const';
import { upsertManyAgreements } from '../../../store/slices/entities/payments/agreements';

// Estado inicial del slice
const emptyState = {
    expireIn: null,
    fetchingAt: null,
    status: 'idle',
    operation: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

// Thunk para realizar la petición asincrónica de descuentos de pago
export const fetchSurcharges = createAsyncThunk(
    'Surcharges/fetch',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const [
                responseConcepts,
                responseStudents,

                responseCatalogSurcharges,
                responseSurcharges,

                responseCatalogConcepts,
            ] = await Promise.all([
                Connection.getConceptsBySchool(schoolId),
                Connection.StudentsBySchool(schoolId),

                Connection.getSchoolsBySurchargesCatalog(schoolId),
                Connection.getSurchargesBySchool(schoolId),

                Connection.getSchoolsByConceptsCatalog(schoolId),
            ]);

            const concepts = responseConcepts.data.data;
            const students = responseStudents.data.data;

            const catSurcharges = responseCatalogSurcharges.data.data;
            const surcharges = responseSurcharges.data.data;

            const catConcepts = responseCatalogConcepts.data.data;

            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyCataloConcepts(catConcepts));
            thunkAPI.dispatch(upsertManyConcepts(concepts));

            thunkAPI.dispatch(setAllSurcharges(surcharges));
            thunkAPI.dispatch(upsertManyCatalogSurcharges(catSurcharges));

            return {
                concepts,
                catConcepts,
                students,
                surcharges,
                catSurcharges,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        // Condición para ejecutar el thunk solo si es necesario
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectFetchSurcharges(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const fetchCreateSurcharge = createAsyncThunk(
    'Surcharges/create/fetch',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const [
                responseConcepts,
                responseCatalogSurcharges,
                responseCatalogConcepts,
                responseAgreements,
            ] = await Promise.all([
                Connection.getConceptsBySchool(schoolId),
                Connection.getSchoolsBySurchargesCatalog(schoolId),
                Connection.getSchoolsByConceptsCatalog(schoolId),
                Connection.getAgreementBySchool(schoolId),
            ]);

            const concepts = responseConcepts.data.data;
            const catSurcharges = responseCatalogSurcharges.data.data;
            const catConcepts = responseCatalogConcepts.data.data;
            const agreements = responseAgreements.data.data;

            thunkAPI.dispatch(upsertManyCataloConcepts(catConcepts));
            thunkAPI.dispatch(upsertManyConcepts(concepts));

            thunkAPI.dispatch(upsertManyCatalogSurcharges(catSurcharges));
            thunkAPI.dispatch(upsertManyAgreements(agreements));

            return {
                concepts,
                catConcepts,
                catSurcharges,
                agreements,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

// Slice para los conceptos de pago
const SurchargesSlice = createSlice({
    name: 'Surcharges',
    initialState: emptyState,
    reducers: {
        // Acción para invalidar los datos actuales
        invalidate: (state) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        builder
            // Manejo del estado pendiente de la petición
            .addCase(fetchSurcharges.pending, (state) => {
                state.status = 'pending';
            })
            // Manejo del estado cumplido de la petición
            .addCase(fetchSurcharges.fulfilled, (state, action) => {
                state.expireIn = new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                );
                state.fetchingAt = Date.now();
                state.didInvalidate = false;

                state.status = 'fulfilled';
            })
            // Manejo del estado rechazado de la petición
            .addCase(fetchSurcharges.rejected, (state, action) => {
                state.status = 'rejected';
                state.feedback = action.payload.feedback;
            });
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });
    },
});

export const { invalidate: invalidateSurcharges } = SurchargesSlice.actions;

export default SurchargesSlice.reducer;

//////////////////////////////////// SELECTORES ////////////////////////////////////
/**
 * Selector para recuperar el estado de la petición de conceptos de pago
 */
export const selectFetchSurcharges = createSelector(
    selectPaymentSurcharges,
    (state) => state.fetch
);
