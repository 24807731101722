import React from 'react';
import { useAuth } from '../../../../hooks';
import { useSelector } from 'react-redux';
import { selectClassroomGroupDetails } from '../../../store/meGroups/classrooms';
import { ClassroomCard } from './ClassroomCard';

export const ClassroomGroupContainer = ({ item }) => {
    const Auth = useAuth();
    const classroomGroups = useSelector(
        selectClassroomGroupDetails(item.uuid, Auth.user.school_id)
    );

    return (
        <div>
            <ClassroomCard item={classroomGroups} color={item.background} />
        </div>
    );
};
