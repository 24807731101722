import { CardHeader, Stack } from '@mui/material';
import {
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';

const GroupsToolBar = (props) => {
    return (
        <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <CardHeader />
            <GridToolbar {...props} />
        </Stack>
    );
};

const GroupsToolBarOnSubjects = (props) => {
    const { columns } = props;
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const config = useSelector(selectSettingBySchoolId(schoolId));

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    return (
        <GridToolbarContainer
            style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: titleBarBackground.value,
                color: fontColor.value,
            }}
        >
            <GridToolbarColumnsButton
                style={{
                    color: fontColor.value,
                }}
            />
            <GridToolbarFilterButton
                style={{
                    color: fontColor.value,
                }}
            />
            <GridToolbarExport
                style={{
                    color: fontColor.value,
                }}
                csvOptions={{
                    fields: columns.map((column) => column.field),
                }}
            />
        </GridToolbarContainer>
    );
};

const GroupsToolBarOnStudent = (props) => {
    const { columns } = props;
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const config = useSelector(selectSettingBySchoolId(schoolId));

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    return (
        <GridToolbarContainer
            style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: titleBarBackground.value,
                color: fontColor.value,
            }}
        >
            <GridToolbarColumnsButton
                style={{
                    color: fontColor.value,
                }}
            />
            <GridToolbarFilterButton
                style={{
                    color: fontColor.value,
                }}
            />
            <GridToolbarExport
                style={{
                    color: fontColor.value,
                }}
                csvOptions={{
                    fields: columns.map((column) => column.field),
                }}
            />
        </GridToolbarContainer>
    );
};

export { GroupsToolBar, GroupsToolBarOnSubjects, GroupsToolBarOnStudent };
