import React, { useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Button, DialogContentText, Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectStudentById } from '../../store/slices/entities/students';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../hooks';
import { selectSettingBySchoolId } from '../../store/slices/entities/settings';
import { removeStudentFromGroup, selectRemoveStudentFromGroupOperationStatus } from '../../store/slices/groupsUI/operationsSlice';
import useFeedback from '../../hooks/useFeedback';

/**
 * Eliminar alumno de un grupo
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
const DeleteStudentFromGroupModal = ({ open, setOpen, studentId, groupId, onStudentDeleted }) => {
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    //////////// SHARED STATE /////////////

    const student = useSelector(selectStudentById(studentId))
    const config = useSelector(selectSettingBySchoolId(schoolId))
    const statusOperation = useSelector(selectRemoveStudentFromGroupOperationStatus)


    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    //////////////////// ACTIONS /////////////////

    /**
     * Maneja el cierre del modal
     */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false)    
    }

    /**
     * Funcion para dar de baja al alumno
     */
    const enviarDatos = () => {
        dispatch(removeStudentFromGroup({
            studentId,
            groupId
        }))
            .unwrap()
            .then((data) => {
                //onStudentDeleted(data)
               
                feedbackApp.showFeedback({
                    title: "El alumno fue eliminado del grupo"
                })

                setOpen(false)

            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"xs"}
            fullWidth={true}
        >
            <DialogTitle
                id="alert-dialog-title"
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <Typography variant="h6" component="div" style={{ color: fontColor.value }}>
                    Eliminar alumno de grupo
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ textAlign: "center", mt: 2 }}>
                    El alumno
                    <Typography variant="h5">
                        {student?.name} {student?.last_name} {student?.second_last_name}
                    </Typography>

                    será eliminado del grupo
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="error"
                    onClick={enviarDatos}
                    loading={statusOperation == 'pending'}
                    loadingPosition="start"
                    startIcon={<PersonRemoveIcon />}
                    variant="contained"
                >
                    Eliminar
                </LoadingButton>

                <Button
                    onClick={handleClose}
                    autoFocus
                    variant="contained"
                    size="small"
                    disabled={statusOperation == 'pending'}
                >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteStudentFromGroupModal
