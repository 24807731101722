import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton,
    Box,
    TextField,
    Autocomplete,
    Button,
    Avatar,
    InputAdornment,
    CircularProgress,
    DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { selectConcepts } from '../../../store/concepts/selectors';
import { selectStudents } from '../../../../store/slices/entities/students';
import { CreateAgreements } from '../../../store/agreements/operations';
import { useAuth, useFeedback } from '../../../../hooks';
import { selectCatConcepts } from '../../../store/cat_concepts/selectors';
import { FetchCreate } from '../../../store/agreements/fetchSlice';
import student from '../../../../assets/images/default-image-niña.svg';
import student2 from '../../../../assets/images/default-image-niño.svg';

const AgreementsModal = ({ open, onclose }) => {
    ///////////// LOCAL STATE /////////////
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [selectedConcept, setSelectedConcept] = useState(null);
    const [selectedPaymentType, setSelectedPaymentType] = useState({});
    const [nextPaymentDate, setNextPaymentDate] = useState('');
    const [loading, setLoading] = useState(true);

    const options = [
        { label: 'Semanal', value: 1 },
        { label: 'Mensual', value: 2 },
        { label: 'Diario', value: 3 },
    ];

    ///////////// SHARED STATE /////////////

    const dispatch = useDispatch();
    const theme = useTheme();
    const feedbackApp = useFeedback();

    const auth = useAuth();
    const schoolId = auth.getUser().school_id;

    const students = Object.values(useSelector(selectStudents));
    const concepts = Object.values(useSelector(selectConcepts));
    const catConcepts = useSelector(selectCatConcepts);

    const filteredConcepts = concepts.filter(
        (concept) => concept.status === 2 && !concept.in_agreement
    );

    const studentIdsWithConcepts = new Set(
        filteredConcepts.map((concept) => concept.student_id)
    );

    const filteredStudents = students.filter((student) =>
        studentIdsWithConcepts.has(student.student_id)
    );

    const studentConcepts = selectedStudent
        ? filteredConcepts.filter(
              (concept) => concept.student_id === selectedStudent.student_id
          )
        : [];

    const filteredStudentConcepts = studentConcepts.filter(
        (concept) => concept.in_agreement === false
    );

    ///////////// USE EFFECT /////////////

    ///////////// FORM HANDLING /////////////
    const formik = useFormik({
        initialValues: {
            student: selectedStudent,
            concept: selectedConcept,
            numInstallments: 1,
            nextPaymentDate: '',
            type: null,
        },
        validationSchema: Yup.object({
            student: Yup.object().required('Selecciona un estudiante'),
            concept: Yup.object().required('Selecciona un concepto'),
            numInstallments: Yup.number()
                .required('Número de plazos requerido')
                .positive()
                .integer(),

            nextPaymentDate: Yup.date()
                .required('Fecha del próximo pago requerida')
                .nullable(),
            type: Yup.object().required('Tipo de plazo requerido'),
        }),
        onSubmit: async (values) => {
            const { numInstallments, concept, student } = values;
            const data = {
                term: numInstallments,
                term_type: selectedPaymentType.value,
            };

            await dispatch(
                CreateAgreements({
                    schoolId: schoolId,
                    concept_id: concept.concept_id,
                    student_id: student.student_id,
                    data,
                })
            )
                .unwrap()
                .then(() => {
                    feedbackApp.showFeedback({
                        title: 'Convenio agregado correctamente',
                    });

                    formik.resetForm();
                    onclose();
                })
                .catch((error) => {
                    feedbackApp.showFeedback({ title: error.feedback.title });
                    formik.resetForm();
                    onclose();
                });
        },
    });

    ///////////// HANDLERS /////////////
    const handleStudentChange = (event, value) => {
        setSelectedStudent(value);
        formik.setFieldValue('student', value);
        setSelectedConcept(null);
        formik.setFieldValue('concept', null);
        setNextPaymentDate('');
    };

    const handleConceptChange = (event, value) => {
        setSelectedConcept(value);
        formik.setFieldValue('concept', value);
        if (value) {
            setNextPaymentDate(calculateNextPaymentDate(value.limit_date));
            formik.setFieldValue(
                'nextPaymentDate',
                calculateNextPaymentDate(value.limit_date)
            );
        } else {
            setNextPaymentDate('');
            formik.setFieldValue('nextPaymentDate', '');
        }
    };

    const handleNumInstallmentsChange = (event) => {
        const value = event.target.value;
        formik.setFieldValue('numInstallments', value);
    };
    const calculateNextPaymentDate = (conceptLimitDate) => {
        const today = new Date();
        let nextPaymentDate = new Date(today);

        // Convert concept limit date to Date object
        const conceptDate = new Date(conceptLimitDate);

        // Determine the base date to work with
        if (conceptDate > today) {
            nextPaymentDate = new Date(conceptDate); // Use concept date if it's in the future
        }

        // Add days based on payment type
        switch (selectedPaymentType?.value) {
            case 1: // Semanal
                nextPaymentDate.setDate(nextPaymentDate.getDate() + 7);
                break;
            case 2: // Mensual
                nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);
                break;
            case 3: // Diario
                nextPaymentDate.setDate(nextPaymentDate.getDate() + 1);
                break;
            default:
                break;
        }

        const skipWeekend = (date) => {
            let newDate = new Date(date);
            while (newDate.getDay() === 0 || newDate.getDay() === 6) {
                newDate.setDate(newDate.getDate() + 1);
            }
            return newDate;
        };

        nextPaymentDate = skipWeekend(nextPaymentDate);

        return nextPaymentDate.toISOString().split('T')[0];
    };
    ///////////// USE EFFECT /////////////

    useEffect(() => {
        if (open) {
            dispatch(FetchCreate(schoolId)).finally(() => setLoading(false));
        }
    }, [open, dispatch, schoolId]);

    useEffect(() => {
        if (selectedConcept) {
            const calculatedDate = calculateNextPaymentDate(
                selectedConcept.limit_date
            );
            setNextPaymentDate(calculatedDate);
            formik.setFieldValue('nextPaymentDate', calculatedDate);
        }
    }, [selectedConcept, selectedPaymentType]);

    ///////////// RENDER /////////////
    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onclose();
                }
            }}
            maxWidth="lg"
            fullWidth
            disableEscapeKeyDown
            sx={{
                '& .MuiDialog-paper': {
                    padding: 5,
                    borderRadius: 2,
                },
            }}>
            {' '}
            {loading ? (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <DialogTitle
                        sx={{
                            textAlign: 'center',
                            position: 'relative',
                        }}>
                        <Typography variant="h4" component="div">
                            Agregar Convenio
                        </Typography>
                        <Typography variant="body2">
                            Aquí podrás agregar un nuevo convenio
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={() => {
                                formik.resetForm();
                                onclose();
                            }}
                            sx={{
                                position: 'absolute',
                                right: 6,
                                top: 6,
                                color: theme.palette.grey[500],
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ paddingTop: 5, mt: 2 }}>
                            <Autocomplete
                                disableClearable
                                options={filteredStudents}
                                getOptionLabel={(option) =>
                                    `${option.name} ${option.last_name} ${option.second_last_name}`
                                }
                                value={formik.values.student}
                                onChange={handleStudentChange}
                                sx={{ width: '100%' }}
                                renderOption={(props, option) => {
                                    const defaultAvatar =
                                        option.gender === 'M'
                                            ? student
                                            : student2;
                                    const avatarSrc =
                                        option.url_photo_profile ||
                                        defaultAvatar;

                                    return (
                                        <Box
                                            component="li"
                                            {...props}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                mb: 1,
                                                p: 1,
                                                borderRadius: 1,
                                                '&:hover': {
                                                    backgroundColor:
                                                        theme.palette.action
                                                            .hover,
                                                },
                                            }}>
                                            <Avatar
                                                sx={{
                                                    width: 56,
                                                    height: 56,
                                                    mr: 2,
                                                }}
                                                src={avatarSrc}
                                                alt={option.name}
                                            />
                                            <Box>
                                                <Typography variant="body1">
                                                    {option.name}{' '}
                                                    {option.last_name}{' '}
                                                    {option.second_last_name}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary">
                                                    ID: {option.student_id}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary">
                                                    Teléfono: {option.cellphone}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Seleccionar estudiante"
                                        variant="outlined"
                                        helperText={
                                            formik.touched.student &&
                                            formik.errors.student
                                        }
                                        error={
                                            formik.touched.student &&
                                            Boolean(formik.errors.student)
                                        }
                                    />
                                )}
                            />
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mt: 2,
                            }}>
                            <Autocomplete
                                disabled={!selectedStudent}
                                options={filteredStudentConcepts}
                                getOptionLabel={(option) =>
                                    option.description || ''
                                }
                                value={formik.values.concept}
                                onChange={handleConceptChange}
                                sx={{ width: '70%' }}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            borderBottom: '1px solid #ddd',
                                            padding: '8px 16px',
                                        }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}>
                                            <Typography
                                                variant="body1"
                                                sx={{ fontWeight: 'bold' }}>
                                                {option.description}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary">
                                                ID: {option.concept_id}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{ marginLeft: 'auto' }}>
                                            Total: ${option.net_total}
                                        </Typography>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Seleccionar concepto"
                                        variant="outlined"
                                        helperText={
                                            formik.touched.concept &&
                                            formik.errors.concept
                                        }
                                        error={
                                            formik.touched.concept &&
                                            Boolean(formik.errors.concept)
                                        }
                                    />
                                )}
                            />

                            <TextField
                                label="Total"
                                value={selectedConcept?.net_total || ''}
                                disabled
                                sx={{ width: '28%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>

                        <Box
                            sx={{
                                mt: 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                            <Autocomplete
                                noOptionsText="No hay opciones disponibles"
                                disableClearable
                                options={options}
                                getOptionLabel={(option) => option.label}
                                value={formik.values.type}
                                onChange={(event, value) => {
                                    setSelectedPaymentType(value);
                                    formik.setFieldValue('type', value);
                                    setNextPaymentDate(
                                        calculateNextPaymentDate(
                                            selectedConcept?.limit_date
                                        )
                                    );
                                }}
                                sx={{ width: '48%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Tipo de plazo"
                                        variant="outlined"
                                        helperText={
                                            formik.touched.type &&
                                            formik.errors.type
                                        }
                                        error={
                                            formik.touched.type &&
                                            Boolean(formik.errors.type)
                                        }
                                    />
                                )}
                            />

                            <TextField
                                label="Número de plazos"
                                type="number"
                                value={formik.values.numInstallments}
                                onChange={handleNumInstallmentsChange}
                                sx={{ width: '48%' }}
                                helperText={
                                    formik.touched.numInstallments &&
                                    formik.errors.numInstallments
                                }
                                error={
                                    formik.touched.numInstallments &&
                                    Boolean(formik.errors.numInstallments)
                                }
                            />
                        </Box>
                        <Box
                            sx={{
                                mt: 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                            <TextField
                                disabled={true}
                                label="Fecha del próximo pago"
                                type="date"
                                value={formik.values.nextPaymentDate}
                                onChange={(event) =>
                                    formik.setFieldValue(
                                        'nextPaymentDate',
                                        event.target.value
                                    )
                                }
                                sx={{ width: '60%', mt: 2 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={
                                    formik.touched.nextPaymentDate &&
                                    formik.errors.nextPaymentDate
                                }
                                error={
                                    formik.touched.nextPaymentDate &&
                                    Boolean(formik.errors.nextPaymentDate)
                                }
                            />
                            <TextField
                                label="Importe del siguiente plazo"
                                variant="outlined"
                                disabled={true}
                                value={
                                    selectedConcept?.net_total &&
                                    formik.values.numInstallments
                                        ? (
                                              selectedConcept.net_total /
                                              formik.values.numInstallments
                                          ).toFixed(2)
                                        : '0'
                                }
                                fullWidth
                                sx={{ width: '35%', mt: 2 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>

                        <DialogActions sx={{ mt: 2 }}>
                            <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={formik.handleSubmit}
                                disabled={
                                    formik.isSubmitting ||
                                    !formik.isValid ||
                                    !formik.dirty
                                }
                                sx={{ ml: 'auto' }}>
                                {formik.isSubmitting ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    'Registrar Convenio'
                                )}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};

export default AgreementsModal;
