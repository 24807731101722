import { CardHeader, Stack } from "@mui/material"
import { GridToolbar } from "@mui/x-data-grid"

const DetallesSupervisorToolBar = (props) => {
    return (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <CardHeader/>
            <GridToolbar {...props} />
        </Stack>
    )
}
export default DetallesSupervisorToolBar