import React, { useState } from 'react';
import Authentication from '../service/Login';
import AuthContext from './AuthContext';
import { useSelector } from 'react-redux';
import Connection from '../service/Connection';
import Token from '../service/Token';
import Exportable from '../service/Exportable';

const AuthProvider = (props) => {
    const { children } = props;

    /**
     * Se recupera el token de la store y
     * es agregado a la instancia global de la API
     */
    let tokenStore = useSelector((store) => store.auth.token);
    Connection.getClient().setToken(new Token(tokenStore));
    Exportable.setConnection(Connection);
    /**
     * Se recupera el usuario de la store
     */
    let userStore = useSelector((store) => store.auth.user);

    let user = userStore ? userStore : null;

    const auth = new Authentication();

    auth.setUser(user);

    const login = (telefono, password) => {
        return auth.login(telefono, password);
    };

    return (
        <AuthContext.Provider value={auth}> {children} </AuthContext.Provider>
    );
};

export default AuthProvider;
