import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Typography, Paper, Grid } from '@mui/material';
import { EmojiEvents } from '@mui/icons-material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectBestAssistanceGroup,
} from '../../../store/groups/fetchSlice';
import { getBestAssistanceGroup } from '../../../store/groups/thunks';
import { selectComponentGroupsBestAssistanceGroupStatusServer } from '../../../store/groups/componentStatusSelectors';
import { SkeletonGroupsCards } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';

const BestAssistanceGroupCard = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentGroupsBestAssistanceGroupStatusServer
    );

    const bestAssistanceGroup = useFetchResource(
        () =>
            getBestAssistanceGroup({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectBestAssistanceGroup,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getBestAssistanceGroup({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectBestAssistanceGroup,
            [cycleSelected, schoolId]
        );
    };

    const value = bestAssistanceGroup?.value;

    return (
        <Box>
            {fetchingStatus === 'pending' && <SkeletonGroupsCards items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#03a9f4',
                        borderRadius: '10px',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        padding: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: '900px',
                        height: '400px',
                        margin: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <EmojiEvents
                            style={{
                                fontSize: 35,
                                color: 'rgb(232, 247, 255)',
                                marginRight: '10px',
                                marginBottom: '10px',
                            }}
                        />
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 700,
                                color: '#fff',
                                marginBottom: '10px',
                                textAlign: 'center',
                            }}
                        >
                            {bestAssistanceGroup?.title ||
                                'Mejor Promedio de Nivel'}
                        </Typography>
                    </Box>
                    <Paper
                        elevation={3}
                        sx={{
                            margin: '16px 0',
                            padding: '16px',
                            width: '100%',
                            backgroundColor: '#f1f1f1',
                            borderRadius: '10px',
                        }}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: '#808080',
                                        textAlign: 'center',
                                    }}
                                >
                                    {value?.labels[0] || 'Grado'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: '#808080',
                                        textAlign: 'center',
                                    }}
                                >
                                    {value?.labels[1] || 'Grupo'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#03a9f4',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                    }}
                                >
                                    {value.data[0] || 'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#03a9f4',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                    }}
                                >
                                    {value.data[1] || 'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: '#808080',
                                        textAlign: 'center',
                                    }}
                                >
                                    {value?.labels[2] || 'Turno'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: '#808080',
                                        textAlign: 'center',
                                    }}
                                >
                                    {value?.labels[5] || 'Nivel'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#03a9f4',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                    }}
                                >
                                    {value.data[2] || 'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#03a9f4',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                    }}
                                >
                                    {value.data[5] || 'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: '#808080',
                                        textAlign: 'center',
                                    }}
                                >
                                    {value?.labels[3] || 'Asistencias'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: '#808080',
                                        textAlign: 'center',
                                    }}
                                >
                                    {value?.labels[4] || 'Promedio'}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#03a9f4',
                                        borderRadius: '10px',
                                        width: '100px',
                                        padding: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        color: '#fff',
                                        fontWeight: 700,
                                        fontSize: '2rem',
                                        textAlign: 'center',
                                    }}
                                >
                                    {value.data[3] || 'N/A'}
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#03a9f4',
                                        borderRadius: '10px',
                                        width: '100px',
                                        padding: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        color: '#fff',
                                        fontWeight: 700,
                                        fontSize: '2rem',
                                        textAlign: 'center',
                                    }}
                                >
                                    {value.data[4] || 'N/A'}
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Card>
            )}
        </Box>
    );
};

export default BestAssistanceGroupCard;
