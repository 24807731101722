import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { selectAllGroupsStudents } from "../groups_students";

const studentsAdapter = createEntityAdapter({
    selectId: (student) => student.student_id,
    sortComparer: (a, b) => a.student_id - b.student_id
})

/**
 * Slice para los parciales
 */
export const StudentsSlice = createSlice({
    name: 'students',
    initialState: studentsAdapter.getInitialState(),
    reducers: {
        /**
         *  Si el alumno no se encuentra en las entidades es agregados
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addOneStudent: studentsAdapter.addOne,
        /**
         * Agrega muchos elementos, si esque estos aun no se encuentran agregados
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addManyStudents: studentsAdapter.addMany,
        /**
         * Remplazar todos los parciales
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setManyStudents: studentsAdapter.setMany,
        /**
         * Elimina una entidad la lista
         * 
         * @param {*} state 
         * @param {*} action 
         */
        removeOneStudent: studentsAdapter.removeOne,
        /**
         * Si el elemento existe realziara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         * 
         * @param {*} state 
         * @param {*} action 
         */
        upsertOneStudent: studentsAdapter.upsertOne,
        updateStudent: studentsAdapter.updateOne,
        /**
         * Si el elemento existe realizara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         * 
         * @param {*} state 
         * @param {*} action 
         */
        upsertManyStudents: studentsAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return studentsAdapter.getInitialState()
        })
    }
});

/**
 * Acciones generadas por la librerias
 */
export const {
    setManyStudents, addManyStudents, removeOneStudent,
    updateStudent,
    addOneStudent, upsertOneStudent, upsertManyStudents
} = StudentsSlice.actions;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = studentsAdapter.getSelectors((state) => state.entities.students)

/**
 * Selector para recuperar todas las entidades 
 * 
 * @param any Estado completo de la app
 * 
 * @returns any
 */
export const selectEntitiesStudents = (state) => globalizedSelectors.selectEntities(state)

/**
 * Selector para recuperar todas las configuraciones 
 * 
 * @param any Estado completo de la app
 *  
 * @returns any[]
 */
export const selectAllStudents = (state) => globalizedSelectors.selectAll(state)

/**
 * Selector para seleccionar una configuracion por ID
 * 
 * @param number Identificador de la configuracion
 * 
 * @returns any
 */
export const selectStudentById = (id) => (state) => globalizedSelectors.selectById(state, id)


/**
 * Selector para recuperar todos los parciales de la coleccion 
 * 
 * @param {*} state 
 * @returns 
 */
export const selectStudents = (state) => selectEntitiesStudents(state);



/**
 * Selector para recuperar alumnos de un grupo
 * 
 * @param {*} schoolId 
 * @returns 
 */
export const selectStudentsByGroupId = (groupId) => {
    return (state) => {

        let groupStudents = selectAllGroupsStudents(state).filter(i => i.group_id == groupId);

        let students = groupStudents.map(i => selectStudentById(i.student_id)(state))

        return students
    }
}


/**
 * Selector para recuperar grupos como array
 * 
 * @param {*} schoolId 
 * @returns 
 */

export const selectStudentsAsArray = () => (state) => selectAllStudents(state)


export default StudentsSlice.reducer;
