import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";


const UserListItem = ({user}) => {

    const labelId = `checkbox-list-secondary-label-${user.user_id}`;

    return (
        <ListItem
            key={`item-${user.user_id}`}
        >
            <ListItemAvatar>
                <Avatar
                    alt={`Avatar n°${user.user_id}`}
                    src={user.url_photo_profile}
                />
            </ListItemAvatar>
            <ListItemText id={labelId} primary={`${user.name} ${user.last_name} ${user.second_last_name}`} />
        </ListItem>
    )
}

export default UserListItem