import React, {useState} from 'react'
import { Box, Grid, Card, Typography, Stack, Menu, Button} from '@mui/material'
import { useDispatch } from "react-redux";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import * as constants from "../../../service/const";
import Feedback from "../../../service/Feedback"
import moment from 'moment'
import 'moment/locale/es';

import { deleteActivity } from '../../../store/slices/calendar'

function getLevelName(level) {
    let levelName = constants.ACTIVITY_LEVELS.find(item => item.key == level)

    return levelName.title
}

const ActivityItemCard = (props) => {

     ///////////////////// DATOS PARA EL FEEDBACK ///////////////////////////////////////////

     const showFeedback = (message) =>  {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [openLoading, setOpenLoading] = useState(false);
    ////////////////////////////////////////////////////////
    const dispatch = useDispatch()
    const [feedback] = useState(()=> new Feedback());

    moment.locale('es')

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
    const onShowMenuDelete = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  

    //////////////////////////////////////////////////

    const startDate = () => {
        let formated = moment(props.start_date).format("DD MMMM YYYY")

        if (props.is_range) {
            formated = `Del ${moment(props.start_date).format("DD MMMM YYYY")}`
        }
        
        return (
            <Typography variant="body2" gutterBottom component="span">
                {formated}
            </Typography>
        )
    }

    const endDate = () => {
        
        if (props.is_range) {
            return (
                <Typography variant="body2" gutterBottom component="span">
                            al {moment(props.end_date).format("DD MMMM YYYY")}
                </Typography>
            )
        } 
    }


    const handleDeleteActivity = () => {
        dispatch(deleteActivity(props.activity_id)).then(response => {
            props.done()
        }).catch(err => {
            let message = feedback.getMessage(err)
            showFeedback(message.message)
        })
    }

    return (
        <Card style={{position: 'relative'}}>
            <Box
                style={{position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 'tooltip'
                }}
            >
                <IconButton aria-label="delete" size="small"  onClick={onShowMenuDelete}>
                    <DeleteIcon fontSize="small" color="error"/>
                </IconButton>

                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <Button variant="contained" onClick={handleDeleteActivity} color="error">
                        Eliminar
                    </Button>
                </Menu>

            </Box>


            <Grid container>
                <Grid item xs={0.2} sx={{backgroundColor: props.color}}>
                    <Box sx={{backgroundColor: props.color}}> </Box>
                </Grid>
                <Grid item xs={11.8}>
                    <Box sx={{padding:1}}>
                        <Typography variant="body1" gutterBottom component="div">
                            {props.description}
                        </Typography>
                        {startDate()} {endDate()}

                        <Typography variant="caption" gutterBottom component="div">
                            Ciclo Escolar: {props.school_cycle}
                        </Typography>

                        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                            <Typography variant="caption" component="div">
                                Nivel: {getLevelName(props.level)}
                            </Typography>
                            <Typography variant="caption" component="div">
                                Actualizado: {props.updated_at}
                            </Typography>
                        </Stack>
                        

                    </Box>
                </Grid>
            </Grid>
        </Card>
    )
}


export default ActivityItemCard