import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../../../hooks';

import { Box, Button, Tooltip, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { Error } from '../../../../components/Feedback';
import SkeletonPage from './../../../components/SkeletonPage';
import ClassroomGroupPreviewCard from '../../../components/ClassroomGroupPreviewCard';
import GroupAssistanseTable from './components/GroupAssistanseTable';
import { selectStudentsByGroup } from '../../../../store/slices/entities/groups';
import {
    fetchClassroomGroupData,
    invalidate,
    selectClassroomGroupDetails,
    selectClassroomViewmodelFetchStatusById,
} from '../../../store/meGroups/classrooms';
import { DateTime } from 'luxon';

const ClassroomGroupAssistance = () => {
    ////////// HOOKS //////////
    let { id } = useParams();
    const Auth = useAuth();
    const dispatch = useDispatch();

    ////////// SHARED STATE //////////

    const classroomDetails = useSelector(
        selectClassroomGroupDetails(id, Auth.user.school_id)
    );

    const statusServer = useSelector(
        selectClassroomViewmodelFetchStatusById(id)
    );

    const studentsInMainGroup = useSelector(
        selectStudentsByGroup(classroomDetails.group.group_id)
    );

    ////////// HANDLERS //////////

    const reload = () => {
        dispatch(
            fetchClassroomGroupData({
                groupId: classroomDetails.group.group_id,
                schoolId: classroomDetails.school.school_id,
                userId: Auth.user.user_id,
                currentStudents: studentsInMainGroup,
                uuid: classroomDetails.viewmodel.uuid,
            })
        );
    };

    /**
     * UseEffect que ejecuta el proceso
     * de recuperación de datos
     */
    useEffect(() => {
        reload();
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(id));
        reload();
    };

    const getCurrentDate = () => {
        const fecha = DateTime.local();
        const formattedDate = fecha
            .setLocale('es')
            .toFormat("dd 'de' LLLL yyyy");
        return formattedDate;
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {statusServer === 'pending' && <SkeletonPage />}

            {statusServer === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {statusServer === 'fulfilled' && (
                <>
                    <Box
                        sx={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="h4" component="div">
                            Control de asistencia del dia: {getCurrentDate()}{' '}
                        </Typography>

                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}
                            >
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>
                    <ClassroomGroupPreviewCard classroom={classroomDetails} />
                    <GroupAssistanseTable classroom={id} />
                </>
            )}
        </Box>
    );
};

export default ClassroomGroupAssistance;
