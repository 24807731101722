import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import { selectAssistanceNoticeDetails } from './selectors';
import { selectSelectedAssistanceNoticeUI } from '../assistance/uiSlice';
import Feedback from '../../../service/Feedback';
import Connection from '../../../service/Connection';
import { selectAllUsers, upsertManyUsers } from '../../../store/slices/entities/users';
import { selectAllAssistanceNoticesItems } from '../assistance/itemsSlice';
import { PROFESSORS_SUBJECT_EXPIRE_TIME } from '../../../service/const';

let emptyState = [];

export const assistanceDetails = createSlice({
    name: 'asisstanceDetails',
    initialState: emptyState,
    reducers: {
        invalidateAssistanceSent: (state, { payload }) => {
            state[payload].operations.sent.didInvalidate = true;
        },
        invalidateAssistanceNoticed: (state, { payload }) => {
            state[payload].operations.notcied.didInvalidate = true;
        },
        invalidateAssistanceRead: (state, { payload }) => {
            state[payload].operations.read.didInvalidate = true;
        },
        addItem: (state, action) => {
            return {
                ...state,
                ...action.payload.reduce((accumulator, item) => {
                    accumulator[item.id] = item;
                    return accumulator;
                }, {}),
            };
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        builder.addCase(loadSentAssistanceUsersData.fulfilled, (state, { payload }) => {
            const { noticeUUID } = payload;

            state[noticeUUID].operations.sent.expireIn = new Date().setMinutes(
                new Date().getMinutes() + PROFESSORS_SUBJECT_EXPIRE_TIME
            );
            state[noticeUUID].operations.sent.status = 'fulfilled';
            state[noticeUUID].operations.sent.fetchingAt = Date.now();
            state[noticeUUID].operations.sent.didInvalidate = false;
        });

        builder.addCase(loadSentAssistanceUsersData.pending, (state, { meta }) => {
            const { noticeUUID } = meta;
            state[noticeUUID].operations.sent.status = 'pending';
        });

        builder.addCase(loadSentAssistanceUsersData.rejected, (state, { payload }) => {
            const { noticeUUID } = payload;
            state[noticeUUID].operations.sent.status = 'rejected';
        });

        //Recuperar información de notificaciones de asistencia recibidas

        builder.addCase(
            loadNoticedAssistanceUsersData.fulfilled,
            (state, { payload }) => {
                const { noticeUUID } = payload;

                state[noticeUUID].operations.noticed.expireIn =
                    new Date().setMinutes(
                        new Date().getMinutes() + PROFESSORS_SUBJECT_EXPIRE_TIME
                    );
                state[noticeUUID].operations.noticed.status = 'fulfilled';
                state[noticeUUID].operations.noticed.fetchingAt = Date.now();
                state[noticeUUID].operations.noticed.didInvalidate = false;
            }
        );

        builder.addCase(loadNoticedAssistanceUsersData.pending, (state, { meta }) => {
            const { noticeUUID } = meta;
            state[noticeUUID].operations.noticed.status = 'pending';
        });

        builder.addCase(loadNoticedAssistanceUsersData.rejected, (state, { payload }) => {
            const { noticeUUID } = payload;
            state[noticeUUID].operations.noticed.status = 'rejected';
        });

        //Recuperar información de notificaciones de asistencia leídas

        builder.addCase(loadReadAssistanceUsersData.fulfilled, (state, { payload }) => {
            const { noticeUUID } = payload;

            state[noticeUUID].operations.read.expireIn = new Date().setMinutes(
                new Date().getMinutes() + PROFESSORS_SUBJECT_EXPIRE_TIME
            );
            state[noticeUUID].operations.read.status = 'fulfilled';
            state[noticeUUID].operations.read.fetchingAt = Date.now();
            state[noticeUUID].operations.read.didInvalidate = false;
        });

        builder.addCase(loadReadAssistanceUsersData.pending, (state, { meta }) => {
            const { noticeUUID } = meta;
            state[noticeUUID].operations.read.status = 'pending';
        });

        builder.addCase(loadReadAssistanceUsersData.rejected, (state, { payload }) => {
            const { noticeUUID } = payload;
            state[noticeUUID].operations.read.status = 'rejected';
        });
    },
});

export const { addItem, invalidateAssistanceSent,invalidateAssistanceNoticed,invalidateAssistanceRead } = assistanceDetails.actions;

export default assistanceDetails.reducer;

export const loadSentAssistanceUsersData = createAsyncThunk(
    'notices/load/sentAssistanceNotices',
    async (data, thunkAPI) => {
        const { usersIds } = data;

        let FeedbackService = new Feedback();
        const state = thunkAPI.getState();
        const noticeUUID = state.notices.assistance.ui.noticeSelected;

        try {
            let users = await Connection.getUsers(usersIds).then(
                (i) => i.data.data
            );
            thunkAPI.dispatch(upsertManyUsers(users));

            return {
                users,
                noticeUUID,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                noticeUUID,
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            const state = getState();
            const noticeUUID = state.notices.assistance.ui.noticeSelected;

            let { didInvalidate, expireIn } =
                state.notices.assistanceDetails[noticeUUID].operations.sent;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
        getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
            return {
                noticeUUID: getState().notices.assistance.ui.noticeSelected,
            };
        },
    }
);

export const loadNoticedAssistanceUsersData = createAsyncThunk(
    'notices/load/noticedAssistanceNotices',
    async (data, thunkAPI) => {
        const { usersIds } = data;

        let FeedbackService = new Feedback();
        const state = thunkAPI.getState();
        const noticeUUID = state.notices.assistance.ui.noticeSelected;

        try {
            let users = await Connection.getUsers(usersIds).then(
                (i) => i.data.data
            );
            thunkAPI.dispatch(upsertManyUsers(users));

            return {
                users,
                noticeUUID,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                noticeUUID,
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            const state = getState();
            const noticeUUID = state.notices.assistance.ui.noticeSelected;

            let { didInvalidate, expireIn } =
                state.notices.assistanceDetails[noticeUUID].operations.noticed;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
        getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
            return {
                noticeUUID: getState().notices.assistance.ui.noticeSelected,
            };
        },
    }
);

export const loadReadAssistanceUsersData = createAsyncThunk(
    'notices/load/readAssistanceNotices',
    async (data, thunkAPI) => {
        const { usersIds } = data;

        let FeedbackService = new Feedback();
        const state = thunkAPI.getState();
        const noticeUUID = state.notices.assistance.ui.noticeSelected;

        try {
            let users = await Connection.getUsers(usersIds).then(
                (i) => i.data.data
            );
            thunkAPI.dispatch(upsertManyUsers(users));

            return {
                users,
                noticeUUID,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                noticeUUID,
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            const state = getState();
            const noticeUUID = state.notices.assistance.ui.noticeSelected;

            let { didInvalidate, expireIn } =
                state.notices.assistanceDetails[noticeUUID].operations.read;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
        getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
            return {
                noticeUUID: getState().notices.assistance.ui.noticeSelected,
            };
        },
    }
);

export const selectSelectedNoticeAssistanceOperations = createSelector(
    selectAssistanceNoticeDetails,
    selectSelectedAssistanceNoticeUI,
    (assistanceNoticesDetails, selectedAssistanceNoticeUUID) => {
        return assistanceNoticesDetails[selectedAssistanceNoticeUUID]
            .operations;
    }
);

export const selectSelectedAssistanceNoticeTypeOperation = (type) =>
    createSelector(selectSelectedNoticeAssistanceOperations, (operations) => {
        if (type === 'sent') {
            return operations.sent;
        } else if (type === 'noticed') {
            return operations.noticed;
        } else {
            return operations.read;
        }
    });

export const selecAssistanceStatusOperation = (type) =>
    createSelector(
        selectSelectedAssistanceNoticeTypeOperation(type),
        (operation) => {
            return operation.status;
        }
    );

export const selectSelectedAssistanceViewModelUsersIds = (vmUUID, type) =>
    createSelector(
        selectAllAssistanceNoticesItems,
        (noticesAssistanceDetails) => {
            let selectedNotices = noticesAssistanceDetails.find(
                (notice) => notice.viewModel.id === vmUUID
            );

            let sentUsersIds = selectedNotices.sentNotifications.map(
                (notification) => notification.user_id
            );

            let noticesUsersIds = selectedNotices.noticedNotifications.map(
                (notification) => notification.user_id
            );

            let readUsersIds = selectedNotices.readNotifications.map(
                (notification) => notification.user_id
            );

            if (type === 'sent') {
                return {
                    usersIds: sentUsersIds,
                };
            } else if (type === 'noticed') {
                return {
                    usersIds: noticesUsersIds,
                };
            } else {
                return {
                    usersIds: readUsersIds,
                };
            }
        }
    );

    export const selectUsersByAssistsNotifIds = (usersIds) =>
        createSelector(selectAllUsers, (allUsers) => {
            return allUsers.filter((user) =>
                usersIds.some((id) => id === user.user_id)
            );
        });
    