import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeedback } from '../../../../../hooks';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    createTheme,
    Stack,
    Switch,
    ThemeProvider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DescriptionIcon from '@mui/icons-material/Description';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SendIcon from '@mui/icons-material/Send';
import ExportablesModal from '../../../../../components/Chart/ExportableModal/ExportablesModal';
import { ReportCreateModal } from './ReportSubjectModal';
import {
    newStoreAssitsList,
    selectAtendenceForSubject,
    selectClassroomViewmodelAssistOperationtatusById,
} from '../../../../store/meGroups/classrooms';
import { selectGroupsById } from '../../../../../store/slices/entities/groups';
import { DateTime } from 'luxon';

const SubjectAssistanceTable = ({ classroom }) => {
    const currentDate = DateTime.local().toFormat('yyyy-MM-dd');

    const theme = createTheme({
        palette: {
            neutral: {
                main: '#006837',
                contrastText: 'white',
            },
        },
    });

    /////////////////// HOOKS //////////

    const dispatch = useDispatch();
    const feedbackApp = useFeedback();
    const history = useHistory();

    ///////////// SHARED STATE /////////////////

    const assists = useSelector(
        selectAtendenceForSubject(classroom, currentDate)
    );
    const saveAssistsStatus = useSelector(
        selectClassroomViewmodelAssistOperationtatusById(classroom)
    );
    const { students, subject, assistancePerformed, assistsToday } = assists;

    const group = useSelector(selectGroupsById(subject.group_id));

    ///////////////////////// LOCAL STATE /////////////////////////
    const [checkedStudent, setCheckedStudent] = useState([]);
    const [selectAllAttendance, setSelectAllAttendance] = useState(true);
    const [attendanceList, setAttendanceList] = useState([]);

    const [openExportModal, setOpenExportModal] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [reportData, setReportData] = useState({
        report: null,
        title: null,
        query: { subject_id: null, group_id: null },
    });

    const [openModal, setOpenModal] = useState(false);

    const studentColumns = [
        {
            field: 'url_photo_profile',
            headerName: 'Alumno',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            renderCell: (props) => (
                <Avatar
                    alt="Estudiante"
                    src={props.value}
                    sx={{ display: { xs: 'none', sm: 'flex' } }}
                />
            ),
        },
        {
            field: 'Nombre',
            headerName: 'Nombre',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            valueGetter: (params) => {
                return `${params.row.last_name} ${params.row.second_last_name} ${params.row.name}`;
            },
        },
        {
            field: 'assist',
            headerName: 'Asistencia',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            renderCell: (props) => (
                <Switch
                    edge="end"
                    onChange={($event) => {
                        handlerChangeAssitsList(props.id, $event);
                    }}
                    checked={props.value}
                    color="neutral"
                    disabled={assistancePerformed}
                />
            ),
        },
    ];

    //////////////////////// HANDLERS ////////////////////

    useEffect(() => {
        let studentsAssitsList = students
            .map((i) => {
                let todayAssist = assistsToday.find(
                    (assist) => assist.student_id == i.student_id
                );
                return {
                    ...i,
                    assist: todayAssist ? todayAssist.assistance : true,
                };
            })
            .sort((a, b) => {
                const lastNameA = a.last_name;
                const lastNameB = b.last_name;
                return lastNameA.localeCompare(lastNameB);
            });
        setAttendanceList(studentsAssitsList);
    }, []);

    const handleSelectionChange = (selectionModel) => {
        const selectedStudents = selectionModel.map((studentId) => {
            return students.find((student) => student.student_id === studentId);
        });
        setCheckedStudent(selectedStudents);
    };

    /*
     * Registra las asistencias en webservices
     */
    const handlerSaveAssitsList = () => {
        let assitsRequest = attendanceList.map((item) => ({
            assistance: item.assist === true ? 1 : 0,
            date: DateTime.local().toFormat('yyyy-MM-dd'),
            student_id: item.student_id,
            subject_id: subject.subject_id,
        }));

        dispatch(
            newStoreAssitsList({
                postData: assitsRequest,
                groupId: subject.group_id,
                subjectId: subject.subject_id,
                uuid: classroom,
            })
        )
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Guardado correctamente',
                });
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const handlerChangeAssitsList = (studentID, event) => {
        const student = attendanceList.map((item) => ({
            ...item,
            assist:
                item.student_id === studentID
                    ? event.target.checked
                    : item.assist,
        }));
        setAttendanceList(student);
    };

    /*
     * Función que activa/desactiva
     * todas las asistencias
     */
    const handlerSelectAllAttendance = () => {
        setSelectAllAttendance(!selectAllAttendance);
        const updatedStudents = attendanceList.map((student) => ({
            ...student,
            assist: !selectAllAttendance,
        }));

        setAttendanceList(updatedStudents);
    };

    /*
     * Función que se encarga
     * de cerrar el modal
     */
    const handlerCloseExportableModal = () => {
        setOpenExportModal(false);
    };

    /*
     * Función que abre el modal
     * de exportaciones
     */
    const handlerExport = (exportOptions) => {
        const { id, title, report } = exportOptions;
        const pre = {
            id,
            title,
            report,
            query: {
                subject_id: subject.subject_id,
                group_id: subject.group_id,
            },
        };

        setSelectedTitle(title);
        setReportData(pre);
        setOpenExportModal(true);
    };

    /*
     * Función que abre el modal
     * de reportes
     */
    const openReportModal = () => {
        if (
            group.assessor_id === null ||
            group.assessor_id === 0 ||
            group.assessor_id === undefined
        ) {
            feedbackApp.showFeedback({
                title: 'El grupo no cuenta con un orientador',
            });
        } else {
            setOpenModal(true);
        }
    };

    /*
     * Función que te redirecciona
     * a la vista de registrar calificaciones
     */
    const handlerCalificationList = () => {
        history.push({
            pathname: `/inicio-maestros/classroom-subject/${classroom}/scores/${subject.subject_id}`,
        });
    };

    /*
     * Función que te redirecciona
     * a la vista de justificación de faltas
     */
    const handlerJustifyAssistantPage = () => {
        history.push({
            pathname: `/inicio-maestros/classroom-subject/${classroom}/justify/${subject.subject_id}`,
        });
    };

    return (
        <>
            <Stack direction="row" spacing={2}>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={checkedStudent.length === 0}
                    onClick={openReportModal}
                    startIcon={<DescriptionIcon />}
                >
                    {`Reportar Alumno(s)`}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={assistancePerformed}
                    onClick={handlerSelectAllAttendance}
                >
                    {selectAllAttendance
                        ? 'Desactivar todas las asistencias'
                        : 'Activar todas las asistencias'}
                </Button>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handlerJustifyAssistantPage}
                    startIcon={<AssignmentTurnedInIcon />}
                >
                    Justificacion de Faltas
                </Button>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handlerCalificationList}
                    startIcon={<CalendarTodayIcon />}
                >
                    Registrar calificación
                </Button>

                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() =>
                        handlerExport({
                            id: assists.subject.subject_id,
                            title: 'Asistencias',
                            report: 'subject-assists',
                        })
                    }
                    startIcon={<FileDownloadIcon />}
                >
                    Exportar asistencias
                </Button>
            </Stack>
            <Card sx={{ mt: 3 }}>
                <CardContent
                    sx={{
                        pt: 0,
                        pl: 0,
                        pr: 0,
                        pb: 0,
                        ' & .MuiDataGrid-root': {
                            border: 'none',
                        },
                    }}
                >
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            getRowId={(row) => row.student_id}
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            rows={attendanceList}
                            checkboxSelection
                            disableRowSelectionOnClick
                            columns={studentColumns}
                            components={{
                                Toolbar: () => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <GridToolbar />
                                    </div>
                                ),
                            }}
                            componentsProps={{
                                panel: {
                                    placement: 'bottom-end',
                                },
                            }}
                            pageSize={100}
                            rowsPerPageOptions={[100]}
                            disableSelectionOnClick
                            disableColumnMenu
                            autoHeight
                            disableDensitySelector
                            onSelectionModelChange={handleSelectionChange}
                        />
                    </ThemeProvider>
                </CardContent>
                <CardActions
                    sx={{
                        direction: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <LoadingButton
                        size="small"
                        endIcon={<SendIcon />}
                        loading={saveAssistsStatus === 'pending'}
                        loadingPosition="end"
                        variant="contained"
                        onClick={handlerSaveAssitsList}
                        disabled={assistancePerformed}
                    >
                        Guardar
                    </LoadingButton>
                </CardActions>
            </Card>

            {openExportModal && (
                <ExportablesModal
                    show={openExportModal}
                    data={reportData}
                    selectedTitle={selectedTitle}
                    onClose={handlerCloseExportableModal}
                />
            )}
            {openModal && (
                <ReportCreateModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    students={checkedStudent}
                    group={group}
                />
            )}
        </>
    );
};

export default SubjectAssistanceTable;
