import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const partialitiesAdapter = createEntityAdapter({
    selectId: (partialities) => partialities.concept,
    sortComparer: (a, b) => a.concept - b.concept,
});

export const PartialitiesSlice = createSlice({
    name: 'Partialities',
    initialState: partialitiesAdapter.getInitialState(),
    reducers: {
        setAllPartialities: partialitiesAdapter.setAll,
        addManyPartialities: partialitiesAdapter.addMany,
        addOnePartialities: partialitiesAdapter.addOne,
        removeOnePartialities: partialitiesAdapter.removeOne,
        upsertOnePartialities: partialitiesAdapter.upsertOne,
        upsertManyPartialities: partialitiesAdapter.upsertMany,
        updateManyPartialities: partialitiesAdapter.updateMany,
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', () => {
            return partialitiesAdapter.getInitialState();
        });
    },
});

export const {
    setAllPartialities,
    addManyPartialities,
    addOnePartialities,
    removeOnePartialities,
    upsertOnePartialities,
    upsertManyPartialities,
    updateManyPartialities,
} = PartialitiesSlice.actions;

const globalizedSelectors = partialitiesAdapter.getSelectors(
    (state) => state.entities.Partialities
);

export default PartialitiesSlice.reducer;
