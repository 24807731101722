import { createSelector, createSlice } from '@reduxjs/toolkit';
import { selectedAssistanceNoticesUI } from './selectors';

const emptyState = {
    noticeSelected: null,
    filters: {},
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {},
    reducers: {
        noticeAssistanceSelected: (state, { payload }) => {
            state.noticeSelected = payload;
        },
        updateAssistanceFilters: (state, { payload }) => {
            const { filter } = payload;
            state.filters = { ...filter };
        },
    },

    extraReducers: (builder) => {
        builder.addCase('app/clear', () => {
            return emptyState;
        });
    },
});

export const { noticeAssistanceSelected, updateAssistanceFilters } =
    uiSlice.actions;

export default uiSlice.reducer;

///////////// SELECTORS ////////////////

export const selectSelectedAssistanceNoticeUI = createSelector(
    selectedAssistanceNoticesUI,
    (ui) => {
        return ui.noticeSelected;
    }
);

export const selectAssistanceNoticesFilter = createSelector(
    selectedAssistanceNoticesUI,
    (ui) => {
        return ui.filters;
    }
);
