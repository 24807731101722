import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectThemeBySchool } from '../../../store/slices/entities/settings';
import { selectPartialsBySchoolLevelSorted } from '../../../store/slices/entities/partials';
import { useAuth } from '../../../hooks';
import useFeedback from '../../../hooks/useFeedback';
import Exportable from '../../../service/Exportable';
import Services from '../../../service/Connection';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PostAddIcon from '@mui/icons-material/PostAdd';
import UploadIcon from '@mui/icons-material/Upload';
import Slide from '@mui/material/Slide';
import Feedback from '../../../service/Feedback';
import { addOneImport } from '../../../store/slices/entities/imports';
import { saveFile } from '../../../helper';
import DownloadIcon from '@mui/icons-material/Download';

/**
 * Modal que permite la importacion de recursos a un grupo
 */
export const AddImportsGroupModal = ({ open, onClose, group }) => {
    ////////////////// HOOKS ////////////////

    const Auth = useAuth();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();
    let FeedbackService = new Feedback();

    const schoolId = Auth.getUser().school_id;
    const userID = Auth.getUserID();

    ////////////////// SHARED STATE ////////////////

    const {colorTitleBar, fontColor} = useSelector(selectThemeBySchool(schoolId));
    const sortedPartials = useSelector(
        selectPartialsBySchoolLevelSorted(schoolId, group.level)
    );

    ////////////////// LOCAL STATE ////////////////

    const [selectedPartial, setSelectedPartial] = useState('');
    const [importState, setImportState] = useState({
        file: null,
        import: null,
        import_type: 'group-scores',
        location: 'local',
    });

    const [downloadingFile, setDownloadingFile] = useState(false);
    const [loading, setLoading] = useState(false);

    /**
     * file extensions allowed
     */
    const importFiles = ['.csv', '.xlsx', '.xls'];


    ////////// HANDLERS //////////////

    /**
     * manejador que permite la seleccion del archivo 
     */
    const onInputChange = (event) => {
        const name = event.target.name;
        let newValue = event.target.value;

        if (name === 'import') {
            const selectedFile = event.target.files[0];

            if (!selectedFile) {
                return
            }

            let allowFile = importFiles.find((ext) =>
                selectedFile.name.endsWith(ext)
            );

            if (allowFile) {
                newValue = selectedFile;
            } else {
                feedbackApp.showFeedback({
                    title:
                        'Por favor seleccione un archivo con extensión ' +
                        importFiles.join(','),
                });
                event.target.value = null;
                newValue = null;
            }
        }

        setImportState({ ...importState, [name]: newValue });
    };

    /**
     * manejador para el cierre del modal
     */
    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        
        setImportState({
            file: null,
            import: null,
            import_type: 'group-scores',
            location: 'local',
        });
        setSelectedPartial('');
        onClose();
    };

    /**
     * manejador para la descargar del reporte
     */
    const onDownloadFileExample = (format) => {
        setDownloadingFile(true);
        Exportable.export(
            'group-scores-inPartial',
            format,
            {
                group_id: group.group_id,
                partial_id: selectedPartial,
            },
            null
        )
            .then((result) => {
                saveFile(result.data, 'calificaciones-por-grupo.xlsx').then(() => {
                    feedbackApp.showFeedback({
                        title: 'Archivo exportado correctamente',
                    });
                }).catch((err) => {
                    feedbackApp.showFeedback({
                        title: 'Estamos teniendo problemas al almacenar el archivo',
                    });
                })
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title: FeedbackService.getMessage(error).title,
                });
            }).then(() => {
                setDownloadingFile(false);
            });
    };

    /**
     * manejador para importar el archivo 
     */
    const onSaveImport = () => {
        setLoading(true);

        let formData = new FormData();
        formData.append('file', importState.import);
        formData.append('import_type', importState.import_type);
        formData.append('location', importState.location);

        Services.saveImportFile(userID, formData)
            .then((response) => {
                dispatch(addOneImport(response.data.data));
                feedbackApp.showFeedback({
                    title: 'El proceso de importación comenzará en breve',
                });

                handleClose();
            })
            .catch((err) => {
                feedbackApp.showFeedback({
                    title: FeedbackService.getMessage(err).message,
                });
            })
            .then(() => {
                setLoading(false);
            });
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Slide}
            disableEscapeKeyDown={loading || downloadingFile}
            TransitionProps={{
                direction: 'left',
            }}
            PaperProps={{
                style: {
                    color: fontColor.value,
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    height: '100%',
                    width: '350px',
                    overflowY: 'auto',
                },
            }}
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    textAlign: 'center',
                    backgroundColor: colorTitleBar.value,
                    color: fontColor.value,
                }}
            >
                Importar calificaciones
            </DialogTitle>
            <DialogContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '16px',
                    }}
                >
                    <PostAddIcon
                        sx={{ fontSize: 72, color: colorTitleBar.value }}
                    />
                    <Typography
                        variant="subtitile1"
                        sx={{
                            mt: 2,
                            textAlign: 'center',
                            color: 'gray',
                        }}
                    >
                        Seleccionar parcial o regularización
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Grid container spacing={1}>
                            <Grid
                                container
                                item
                                xl={12}
                                sm={12}
                                md={12}
                                xs={12}
                            >
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    sx={{ paddingTop: 2 }}
                                >
                                    <TextField
                                        label="Seleccionar"
                                        variant="outlined"
                                        fullWidth
                                        select
                                        value={selectedPartial}
                                        onChange={(e) =>
                                            setSelectedPartial(e.target.value)
                                        }
                                        id="partial"
                                    >
                                        {sortedPartials.map((partial) => (
                                            <MenuItem
                                                key={partial.partial_id}
                                                value={partial.partial_id}
                                            >
                                                {`${
                                                    partial.is_regularized
                                                        ? 'Regularización'
                                                        : 'Parcial'
                                                } ${partial.partial}`}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>

                            {selectedPartial && (
                                <Box sx={{ mt: 2, pl: 2, textAlign: "center" }}>
                                    <Typography variant="div" color="initial" sx={{textAlign: "center"}}>
                                        Descargar archivo de calificaciones para su importación
                                    </Typography>
                                    <Stack
                                        sx={{ mt: 1 }}
                                        spacing={1}
                                        direction={'row'}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <LoadingButton
                                            loading={downloadingFile}
                                            color={'success'}
                                            loadingPosition="start"
                                            startIcon={<DownloadIcon size="lg"/>}
                                            variant="contained"
                                            onClick={() =>
                                                onDownloadFileExample('xlsx')
                                            }
                                        >
                                            Descargar
                                        </LoadingButton>
                                    </Stack>
                                </Box>
                            )}

                            <Grid
                                container
                                item
                                xl={12}
                                sm={12}
                                md={12}
                                xs={12}
                                style={{ marginTop: '10px' }}
                            >
                                <TextField
                                    id="import"
                                    name="import"
                                    size="small"
                                    variant="outlined"
                                    inputProps={{ maxLength: 50 }}
                                    value={importState.file}
                                    onChange={onInputChange}
                                    fullWidth
                                    type="file"
                                    accept=".csv"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={() => {
                        onSaveImport();
                    }}
                    loading={loading === true}
                    loadingPosition="start"
                    startIcon={<UploadIcon size="lg" />}
                    variant="contained"
                    disabled={!(importState.import !== null)}
                >
                    {'Importar'}
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        handleClose();
                    }}
                    disabled={downloadingFile === true}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
