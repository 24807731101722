import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Box,
    InputLabel,
    Select,
    TextField,
    Typography,
    FormControl,
    MenuItem,
    Button,
    FormHelperText,
    Divider,
    Container,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    createGroup,
    selectStatusOperation,
    updateGroup,
} from '../../../store/slices/groupsUI/serverSlice';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useAuth } from '../../../hooks';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { Transfer } from 'antd';
import { getLevelName } from '../../../libs/utils';
import { selectSubjectcatalogsByLevel } from '../../../store/slices/entities/subjectcatalogs';
import { levelsNames, specialNames, turnsNames } from '../../../service/const';
import useFeedback from '../../../hooks/useFeedback';
import { selectGroupByItemSelected } from '../../../store/slices/groupsUI/itemSlice';
import { selectGroupItem } from '../../../store/slices/groupsUI/uiSlice';
import FeatureFlags from '../../../service/FeatureFlags';

/**
 * Modal para actualizar y crear un grupo
 *
 * @param {*} param0
 *
 * @returns
 */
export default ({ open, setOpen }) => {
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    //////////// LOCAL STAT E////////////////////
    const [groupItem, setGroupItem] = useState({
        group_id: null,
        grade: '',
        group: '',
        turn: -1,
        level: -1,
        special: -1,
    });

    const groupSelected = useSelector(selectGroupByItemSelected);

    const [subjectTargetKeys, setSubjectTargetKeys] = useState([]);
    const [subjectSelectedKeys, setSubjectSelectedKeys] = useState([]);

    const attachedgroups = ['Grupo A', 'Grupo B', 'Grupo C'];
    const [attached, setAttached] = useState('');
    const featureFlagEnabledAttached = FeatureFlags.isFeatureFlagActive(
        'NEW_ATTACHED_GROUPS'
    );

    //////////// SHARED STATE  /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(selectStatusOperation);
    const subjects = useSelector(selectSubjectcatalogsByLevel(groupItem.level));

    /////////////////// ACTIONS /////////////////

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    /**
     * Actualizar el grupo
     */
    const actualizarGrupo = async () => {
        if (groupItem.group_id == null) {
            dispatch(
                createGroup({
                    schoolId: schoolId,
                    data: groupItem,
                    subjects: subjectTargetKeys,
                })
            )
                .unwrap()
                .then(({ message }) => {
                    let initialMessage = 'Grupo creado';

                    if (message) {
                        initialMessage = message;
                    }

                    feedbackApp.showFeedback({
                        title: initialMessage,
                    });

                    handleClose();
                })
                .catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title,
                    });
                });
        } else {
            dispatch(
                updateGroup({
                    groupId: groupItem.group_id,
                    data: groupItem,
                })
            )
                .unwrap()
                .then((response) => {
                    feedbackApp.showFeedback({
                        title: 'Grupo actualizado',
                    });
                    handleClose();
                })
                .catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title,
                    });
                });
        }
    };

    /**
     * Cerrar el modal y restablecer los valores
     */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }

        setOpen(false);
        dispatch(selectGroupItem(null));
    };

    const handleAttachedChange = (event) => {
        const selectedValue = event.target.value;
        setAttached(selectedValue);
    };
    /**
     * Efecto escuchando a show para limpiar los valores
     */
    useEffect(() => {
        if (!open) {
            setGroupItem({
                group_id: null,
                grade: '',
                group: '',
                turn: -1,
                level: -1,
                special: -1,
            });
            setSubjectSelectedKeys([]);
            setSubjectTargetKeys([]);
        } else {
            setGroupItem({ ...groupItem, ...groupSelected });
        }
    }, [open]);

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     *
     * @param {*} event
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        if (name == 'level') {
            setSubjectTargetKeys([]);
            setSubjectSelectedKeys([]);
        }

        setGroupItem({ ...groupItem, [name]: value });
    };

    ///////////////////////// TRANFER /////////////////////

    /**
     * Filtro para la busqueda
     */

    const removeAccents = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    const filterOption = (inputValue, option) => {
        const search = removeAccents(
            inputValue.toLowerCase().replace(/\s/g, '')
        );

        const titleNoSpace = removeAccents(
            `${option.folio} ${option.title} ${getLevelName(option.level)}`
                .toLowerCase()
                .replace(/\s/g, '')
        );
        const titleMatch = titleNoSpace.includes(search);

        return titleMatch;
    };

    /**
     * Una funciona que es llamada cuando la tranferencias entre columnas se ha completado
     *
     * @param {*} nextTargetKeys
     * @param {*} direction
     * @param {*} moveKeys
     */
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setSubjectTargetKeys(nextTargetKeys);
    };

    /**
     *  Funcion que es ejecutada cuendo los elementos selecionados han cambiado
     *
     * @param {*} sourceSelectedKeys
     * @param {*} targetSelectedKeys
     */
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSubjectSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    //////////////////////// VALIDACIONES ////////////////////////

    const levelValid = () => {
        return {
            invalid: groupItem.level == -1,
            message: 'Campo requerido',
        };
    };

    const gradeValid = () => {
        return {
            invalid: groupItem.grade == '',
            message: 'Campo requerido, Máximo 10 caracteres',
        };
    };

    const groupValid = () => {
        return {
            invalid: groupItem.group == '',
            message: 'Campo requerido, Máximo 10 caracteres',
        };
    };

    const attachedValid = () => {
        return {
            invalid: groupItem.attached == '',
            message: 'Campo requerido',
        };
    };

    const turnValid = () => {
        return {
            invalid: groupItem.turn == -1,
            message: 'Campo requerido',
        };
    };

    const specialValid = () => {
        return {
            invalid: groupItem.group_type == -1,
            message: 'Campo requerido',
        };
    };

    const formInvalid = () => {
        return (
            levelValid().invalid ||
            gradeValid().invalid ||
            groupValid().invalid ||
            turnValid().invalid ||
            specialValid().invalid
        );
    };

    return (
        <>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="md"
                disableEscapeKeyDown={statusOperation == 'pending'}
                onClose={handleClose}>
                <DialogTitle
                    style={{
                        backgroundColor: titleBarBackground.value,
                        color: fontColor.value,
                    }}>
                    <div style={{ display: 'flex' }}>
                        <Typography
                            variant="h6"
                            component="div"
                            style={{ flexGrow: 1 }}>
                            {groupItem.group_id != null && `Actualizar grupo`}
                            {groupItem.group_id == null && `Agregar grupo`}
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ flexGrow: 1, padding: 2 }}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xl={6}
                                md={6}
                                xs={12}
                                sm={12}
                                style={{ textAlign: 'center' }}>
                                <TextField
                                    id="grade"
                                    name="grade"
                                    label="Grado"
                                    type="text"
                                    size="small"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    inputProps={{ maxLength: 2 }}
                                    fullWidth
                                    value={groupItem.grade}
                                    onChange={onInputChange}
                                    error={gradeValid().invalid}
                                    helperText={
                                        gradeValid().invalid
                                            ? gradeValid().message
                                            : false
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xl={6}
                                md={6}
                                xs={12}
                                sm={12}
                                style={{ textAlign: 'center' }}>
                                <TextField
                                    id="group"
                                    name="group"
                                    label="Grupo"
                                    type="text"
                                    size="small"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    inputProps={{ maxLength: 10 }}
                                    fullWidth
                                    value={groupItem.group}
                                    onChange={onInputChange}
                                    error={groupValid().invalid}
                                    helperText={
                                        groupValid().invalid
                                            ? groupValid().message
                                            : false
                                    }
                                />
                            </Grid>
                            <Grid item xl={4} md={4} xs={12} sm={12}>
                                <FormControl
                                    variant="standard"
                                    sx={{ width: '100%' }}
                                    error={levelValid().invalid}>
                                    <InputLabel id="lbl-nivel">
                                        Nivel
                                    </InputLabel>
                                    <Select
                                        labelId="lbl-nivel"
                                        id="level"
                                        value={groupItem.level}
                                        onChange={onInputChange}
                                        label="level"
                                        name="level"
                                        fullWidth
                                        disabled={groupItem.group_id != null}>
                                        <MenuItem value={-1} selected disabled>
                                            Selecciona una opción
                                        </MenuItem>
                                        {levelsNames.map((i) => {
                                            return (
                                                <MenuItem
                                                    key={i.key}
                                                    value={i.key}>
                                                    {i.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {levelValid().invalid && (
                                        <FormHelperText>
                                            {levelValid().message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xl={4} md={4} xs={12} sm={12}>
                                <FormControl
                                    variant="standard"
                                    sx={{ width: '100%' }}
                                    error={specialValid().invalid}>
                                    <InputLabel id="lbl-tipo">Tipo</InputLabel>
                                    <Select
                                        labelId="lbl-tipo"
                                        id="special"
                                        value={groupItem.group_type}
                                        onChange={onInputChange}
                                        label="special"
                                        name="special"
                                        fullWidth
                                        disabled={groupItem.group_id != null}>
                                        <MenuItem value={-1} selected disabled>
                                            Selecciona una opción
                                        </MenuItem>
                                        {specialNames.map((i) => {
                                            return (
                                                <MenuItem
                                                    key={i.key}
                                                    value={i.key}>
                                                    {i.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {specialValid().invalid && (
                                        <FormHelperText>
                                            {specialValid().message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {featureFlagEnabledAttached && (
                                <Grid item xl={4} md={4} xs={12} sm={12}>
                                    <FormControl
                                        variant="standard"
                                        sx={{ width: '100%' }}
                                        error={attachedValid().invalid}>
                                        <InputLabel id="lbl-attached">
                                            Grupo Anexado
                                        </InputLabel>
                                        <Select
                                            labelId="lbl-attached"
                                            id="attached"
                                            value={attached}
                                            onChange={handleAttachedChange}
                                            label="attached"
                                            name="attached"
                                            fullWidth>
                                            <MenuItem
                                                value={-1}
                                                selected
                                                disabled>
                                                Selecciona una opción
                                            </MenuItem>
                                            {attachedgroups.map((attached) => {
                                                return (
                                                    <MenuItem
                                                        key={attached}
                                                        value={attached}>
                                                        {attached}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {attachedValid().invalid && (
                                            <FormHelperText>
                                                {attachedValid().message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item xl={4} md={4} xs={12} sm={12}>
                                <FormControl
                                    variant="standard"
                                    sx={{ width: '100%' }}
                                    error={turnValid().invalid}>
                                    <InputLabel id="lbl-turno">
                                        Turno
                                    </InputLabel>
                                    <Select
                                        labelId="lbl-turno"
                                        id="turn"
                                        name="turn"
                                        value={groupItem.turn}
                                        onChange={onInputChange}
                                        label="turn"
                                        fullWidth>
                                        <MenuItem value={-1} selected disabled>
                                            Selecciona una opción
                                        </MenuItem>
                                        {turnsNames.map((i) => {
                                            return (
                                                <MenuItem
                                                    key={i.key}
                                                    value={i.key}>
                                                    {i.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {turnValid().invalid && (
                                        <FormHelperText>
                                            {turnValid().message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                        {groupItem.group_id == null && (
                            <>
                                <Divider sx={{ mt: 2 }}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        style={{ textAlign: 'center' }}>
                                        Asignar materias al grupo
                                    </Typography>
                                </Divider>
                                <Container>
                                    <Transfer
                                        filterOption={filterOption}
                                        style={{ marginTop: '20px' }}
                                        listStyle={{
                                            width: 400,
                                            height: 400,
                                        }}
                                        dataSource={subjects.map((i) => {
                                            return {
                                                ...i,
                                                key: i.catalog_subject_id,
                                            };
                                        })}
                                        locale={{
                                            notFoundContent: [
                                                'Sin materias',
                                                'Sin materias',
                                            ],
                                            itemUnit: 'Materias',
                                            itemsUnit: 'Materias',
                                        }}
                                        titles={['Disponibles', 'Asignadas']}
                                        targetKeys={subjectTargetKeys}
                                        selectedKeys={subjectSelectedKeys}
                                        onChange={onChange}
                                        onSelectChange={onSelectChange}
                                        render={(item) =>
                                            item.folio +
                                            ' - ' +
                                            item.title +
                                            ' - ' +
                                            getLevelName(item.level)
                                        }
                                        disabled={groupItem.level == -1}
                                    />
                                </Container>
                            </>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        size="small"
                        color="primary"
                        onClick={() => {
                            actualizarGrupo();
                        }}
                        loading={statusOperation == 'pending'}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        disabled={formInvalid()}>
                        {groupItem.group_id != null && `Actualizar`}
                        {groupItem.group_id == null && `Agregar`}
                    </LoadingButton>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleClose}
                        disabled={statusOperation == 'pending'}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
