import { Box, Divider, Typography } from "@mui/material"

/**
 * Componente que muestra los detalles de la aplicacion
 * 
 * @returns 
 */
const AppDetails = () => {

    return (
        <>
            <Divider />
            <Box
                sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Typography variant="body1" comonent="div">
                    {`v ${process.env.REACT_APP_VERSION_APP}`}
                </Typography>
                <Typography variant="body2" comonent="div">
                    {`${process.env.REACT_APP_NAME}`}
                </Typography>
            </Box>
        </>
    )
}

export default AppDetails