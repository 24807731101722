import React, { useState, useEffect } from 'react';
import {
    Box,
    Stack,
    CardContent,
    Typography,
    Card,
    CardActions,
    TextField,
    Snackbar,
    Container,
    CardHeader,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { useLocation, useParams } from 'react-router';

import './common.css';

import Services from '../../service/EficazAPI';
import Feedback from '../../service/Feedback';
import { useHistory } from 'react-router-dom';
import ClientHttp from '../../service/HttpClient';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword = () => {
    ///////////////////// DATOS PARA EL FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message);
        setOpenFeedback(true);
    };

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    const [loading, setLoading] = React.useState(false);

    ///////////////////////// CODIGO DEL COMPONENTE ////////////////////////////////

    let query = useQuery();
    const history = useHistory();

    const [dataRequest, setDataRequest] = useState({
        token: '',
        email: '',
        password: '',
        password_confirmation: '',
    });

    useEffect(() => {
        let token = query.get('token');
        let email = query.get('email');

        if (!token || !email) {
            history.push({ pathname: '/login' });
        }

        setDataRequest({ ...dataRequest, ...{ token: token, email: email } });
    }, []);

    const [FeedbackAPI] = useState(() => new Feedback());

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     *
     * @param {*} event
     */
    function inInputChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        let newValue = {};
        newValue[name] = value;

        setDataRequest({ ...dataRequest, ...newValue });
    }

    const validUsername = () => {
        return {
            invalid: dataRequest.email == '',
            message: 'Valor incorrecto',
        };
    };

    const passwordinValidation = () => {
        const password = dataRequest.password;
        const passwordConfirmation = dataRequest.password_confirmation;

        if (password.length < 8 || password !== passwordConfirmation) {
            return {
                invalid: true,
                message:
                    password.length <= 8
                        ? 'La contraseña debe tener al menos 8 caracteres'
                        : 'Las contraseñas no coinciden',
            };
        }

        return {
            invalid: false,
            message: '',
        };
    };

    const formValidation = () => {
        return validUsername().invalid || passwordinValidation().invalid;
    };

    const onResetPassword = () => {
        setLoading(true);

        let client = new ClientHttp();
        client.useDomain();
        let EficazApi = new Services(client);

        EficazApi.resetAccount(dataRequest)
            .then((response) => {
                showFeedback('Contraseña restablecida');
                setTimeout(() => {
                    history.push({ pathname: '/login' });
                }, 2000);
            })
            .catch((err) => {
                let message = FeedbackAPI.getMessage(err);
                showFeedback(message.message);
            })
            .then(() => {
                setLoading(false);
            });
    };

    return (
        <Box className="background">
            <div>
                <Snackbar
                    open={openFeedback}
                    autoHideDuration={3000}
                    onClose={closeFeedback}
                    message={feedbackMessage}
                />
            </div>

            <Container>
                <Card sx={{ maxWidth: 500, margin: '0 auto' }}>
                    <CardHeader
                        title="Restablecer contraseña"
                        titleTypographyProps={{
                            sx: {
                                fontSize: 20,
                            },
                        }}
                    />
                    <CardContent>
                        <Typography variant="body1" component="div">
                            Ingresar nueva contraseña
                        </Typography>

                        <Stack
                            component="div"
                            sx={{ marginTop: 1, marginBottom: 2 }}
                            spacing={2}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <TextField
                                id="new-password"
                                name="password"
                                type="password"
                                size="small"
                                onChange={inInputChange}
                                value={dataRequest.password}
                                error={passwordinValidation().invalid}
                                helperText={
                                    passwordinValidation().invalid
                                        ? passwordinValidation().message
                                        : false
                                }
                                label="Nueva contraseña(*)"
                                variant="outlined"
                            />

                            <TextField
                                id="new-password"
                                name="password_confirmation"
                                type="password"
                                size="small"
                                onChange={inInputChange}
                                value={dataRequest.password_confirmation}
                                error={passwordinValidation().invalid}
                                helperText={
                                    passwordinValidation().invalid
                                        ? passwordinValidation().message
                                        : false
                                }
                                label="Vuelva a escribir contraseña(*)"
                                variant="outlined"
                            />
                        </Stack>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'end' }}>
                        <LoadingButton
                            onClick={onResetPassword}
                            endIcon={<SendIcon />}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            disabled={
                                formValidation() ||
                                passwordinValidation().invalid
                            }
                            size="small"
                        >
                            Recuperar
                        </LoadingButton>
                    </CardActions>
                </Card>
            </Container>
        </Box>
    );
};

export default ResetPassword;
