import { saveAs } from 'file-saver';
import { getLevelName, getSpecialName, getTurnLevel } from '../libs/utils';
import round from './round';
import { curpValida } from './regexs';
import CurpGenerator from './CurpGenerator';
import * as constants from './../service/const';

/**
 * Recuperar el nombre del nivel de una escuela
 */
const getNameSchoolLevel = getLevelName;

/**
 * Recuperar el label para el valor de especial
 */
const getSpecialsLabel = getSpecialName;

/**
 * Recuperar el label para el valor de turno
 */
const getTurnsLabel = getTurnLevel;

/**
 * Generador de CURP
 */
const curpGenerator = CurpGenerator;

/**
 * Exportar el archivo
 */
const saveFile = (blob, filename) => {
    return new Promise((resolve, rejected) => {
        try {
            saveAs(blob, filename);
            resolve();
        } catch (err) {
            rejected(err);
        }
    });
};

/**
 * Permite truncar algun valor entero
 */
const truncate = (number, posiciones = 0) => {
    let numberFormated = number;

    var s = number.toString();
    var l = s.length;

    if (s.indexOf('.') > 0) {
        var decimalLength = s.indexOf('.') + 1;
        numberFormated = s.substr(0, decimalLength + posiciones);
    }

    return Number(numberFormated);
};

/**
 * Retorna la modalidad de lista de asistencia
 *
 * @param {*} turn
 * @returns
 */
const getAttendanceList = (AttendanceList) => {
    if (AttendanceList === null) {
        return 'Sin valor';
    }
    let AttendanceListName = constants.assistanceModality.find(
        (item) => item.value == AttendanceList
    );

    if (!AttendanceListName) {
        return 'Sin valor';
    }

    return AttendanceListName.label;
};

export {
    round,
    getNameSchoolLevel,
    getSpecialsLabel,
    getTurnsLabel,
    curpValida,
    curpGenerator,
    saveFile,
    truncate,
    getAttendanceList,
};
