import { combineReducers } from "@reduxjs/toolkit";

import items from "./ItemsSlice";
import ui from "./uiSlice";
import server from "./serverSlice";

export default combineReducers({
    items,
    server,
    ui
})