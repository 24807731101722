import Services from './EficazAPI';
import ClientHttp from './HttpClient';
import Token from './Token';

class Exportable {
    connecion = null;

    constructor(connecionService) {
        this.connecion = connecionService;
    }

    /**
     * Convertir un objecto blob a un objecto de javascript
     */
    blobToJsObject(blob) {
        return new Promise((resovel, rejected) => {
            let reader = new FileReader();

            reader.onload = function () {
                resovel(JSON.parse(this.result.toString()));
            };

            reader.readAsText(blob);
        });
    }

    /**
     * Funcion encargada de exportar los archivos
     *
     * @param {*} reportName
     * @param {*} typeFormat
     */
    export(reportName, typeFormat, query, snaphot) {
        return new Promise((resolve, rejected) => {
            return this.connecion
                .getExportable(reportName, typeFormat, query, snaphot)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    this.blobToJsObject(error.response.data).then((msg) => {
                        rejected({
                            response: { data: msg },
                        });
                    });
                });
        });
    }

    setConnection = (connection) => {
        this.connecion = connection;
    };
}

let token = new Token();

let IClientHttp = new ClientHttp();
IClientHttp.setToken(token);

let APIService = new Services(IClientHttp);

let APIExportable = new Exportable(APIService);

export default APIExportable;
