import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    DialogContentText,
    Snackbar,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { selectSubjectDataById } from '../../../store/slices/entities/subjects';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import {
    deleteGroupSubject,
    selectRemoveGroupSubjectOperationStatus,
} from '../../../store/slices/groupsUI/operationsSlice';

/**
 * Modal para eliminar materia
 */
export default ({ open, setOpen, subjectId, onDeleted, groupId }) => {
    const dispatch = useDispatch();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    //////////// SELECTORES /////////////

    const subject = useSelector(selectSubjectDataById(subjectId));
    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(
        selectRemoveGroupSubjectOperationStatus
    );

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    //////////////////// FUNCIONES /////////////////
    const handleClose = () => {
        setOpen(false);
    };

    /**
     * Funcion para dar de baja al alumno
     */
    const enviarDatos = () => {
        dispatch(
            deleteGroupSubject({
                subject,
                schoolId,
                groupId,
            })
        )
            .unwrap()
            .then((data) => {
                onDeleted(data);
                setOpen(false);
            })
            .catch(({ feedback }) => {
                showFeedback(feedback?.title);
            });
    };

    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message);
        setOpenFeedback(true);
    };

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'xs'}
            fullWidth={true}
        >
            <DialogTitle
                id="alert-dialog-title"
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <Typography
                    variant="h6"
                    component="div"
                    style={{ color: fontColor.value }}
                >
                    Eliminar materia
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    sx={{ textAlign: 'center', mt: 2 }}
                >
                    La materia
                    <Typography variant="h6">
                        {subject && (
                            <span>
                                {subject?.catalog.folio}°{' '}
                                {subject?.catalog.title}
                            </span>
                        )}
                    </Typography>
                    será eliminada del grupo
                    <Typography variant="h6">
                        {subject && (
                            <span>
                                {subject?.group.grade}° {subject?.group?.group}{' '}
                                {getTurnLevel(subject?.group?.turn)}{' '}
                                {getLevelName(subject?.group?.level)}
                            </span>
                        )}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="error"
                    onClick={enviarDatos}
                    loading={statusOperation == 'pending'}
                    loadingPosition="start"
                    startIcon={<DeleteOutlineIcon />}
                    variant="contained"
                >
                    Eliminar
                </LoadingButton>

                <Button
                    onClick={handleClose}
                    autoFocus
                    variant="contained"
                    size="small"
                    disabled={statusOperation == 'pending'}
                >
                    Cancelar
                </Button>
            </DialogActions>
            <Snackbar
                open={openFeedback}
                autoHideDuration={2500}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Dialog>
    );
};
