import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Grid,
    InputAdornment,
    IconButton,
    Button,
    Snackbar,
} from '@mui/material';
import { Box } from '@mui/system';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../hooks';
import { selectSettingBySchoolId } from '../../store/slices/entities/settings';
import {
    selectStatusOperation,
    updatePasswordUser,
} from '../../store/slices/usersUI';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import useFeedback from '../../hooks/useFeedback';

/**
 * Modal para cambiar la contraseña
 *
 * @param {*} param0
 *
 * @returns
 */
const ModalUsuarioPassword = ({
    openModalPassword,
    setOpenModalPassword,
    user = null,
}) => {
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    //////////// SELECTORES /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(selectStatusOperation);

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    const [password, setPassword] = useState({
        password: '',
        password_confirmation: '',
    });

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [showPasswordConf, setShowPasswordConf] = useState(false);
    const handleClickShowPasswordConf = () =>
        setShowPasswordConf(!showPasswordConf);
    const handleMouseDownPasswordConf = () =>
        setShowPasswordConf(!showPasswordConf);

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     *
     * @param {*} event
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setPassword({ ...password, [name]: value });
    };

    /**
     * Actualziar contraseña
     */
    const enviarDatos = async () => {
        dispatch(
            updatePasswordUser({
                userId: user.user_id,
                password,
            })
        )
            .unwrap()
            .then((response) => {
                feedbackApp.showFeedback({
                    title: 'Contraseña actualizada',
                });
                setOpenModalPassword(false);
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    ////////////////////////// VALIDACIONES //////////////////////////////////

    const passwordValid = () => {
        return {
            invalid: password.password == '' || password.password.length < 8,
            message:
                'Campo requerido, La contraseña debe tener una longitud de 8 caracteres',
        };
    };

    const passwordConfirmationValid = () => {
        return {
            invalid:
                password.password_confirmation == '' ||
                password.password != password.password_confirmation ||
                password.password_confirmation.length < 8,
            message:
                'Las Contraseñas no coinciden, La contraseña debe tener una longitud de 8 caracteres',
        };
    };

    /**
     * Validacion global para el formulario
     *
     * @returns
     */
    const formInvalid = () => {
        return passwordValid().invalid || passwordConfirmationValid().invalid;
    };

    useEffect(() => {
        if (!openModalPassword) {
            setPassword({
                password: '',
                password_confirmation: '',
            });
        }
    }, [openModalPassword]);

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpenModalPassword(false);
    };

    ////////////////////// VIEW //////////////////////////

    return (
        <Dialog
            open={openModalPassword}
            fullWidth={true}
            maxWidth="sm"
            disableEscapeKeyDown={statusOperation == 'pending'}
            onClose={handleClose}
        >
            <DialogTitle
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ flexGrow: 1, color: fontColor.value }}
                    >
                        Cambiar contraseña
                    </Typography>
                </div>
            </DialogTitle>

            <DialogContent dividers>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Grid container spacing={2}>
                        <Grid container item xl={12}>
                            <TextField
                                id="password"
                                name="password"
                                label="Contraseña"
                                size="small"
                                variant="outlined"
                                value={password.password}
                                onChange={onInputChange}
                                fullWidth
                                type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
                                InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                            >
                                                {showPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={passwordValid().invalid}
                                helperText={
                                    passwordValid().invalid
                                        ? passwordValid().message
                                        : false
                                }
                            />
                        </Grid>
                        <Grid container item xl={12}>
                            <TextField
                                id="password_confirmation"
                                name="password_confirmation"
                                label="Repetir contraseña"
                                size="small"
                                variant="outlined"
                                value={password.password_confirmation}
                                onChange={onInputChange}
                                fullWidth
                                type={showPasswordConf ? 'text' : 'password'} // <-- This is where the magic happens
                                InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPasswordConf
                                                }
                                                onMouseDown={
                                                    handleMouseDownPasswordConf
                                                }
                                            >
                                                {showPasswordConf ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={passwordConfirmationValid().invalid}
                                helperText={
                                    passwordConfirmationValid().invalid
                                        ? passwordConfirmationValid().message
                                        : false
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>

            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={enviarDatos}
                    loading={statusOperation == 'pending'}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                >
                    Cambiar
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleClose}
                    disabled={statusOperation == 'pending'}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalUsuarioPassword;
