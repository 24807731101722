import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Tooltip,
    Paper,
} from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectTotalStudentWithoutGroup,
} from '../../../store/home/fetchSlice';
import { getTotalStudentWithoutGroup } from '../../../store/home/thunks';
import { SkeletongGrafics } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeTotalStudentWithoutGroupStatusServer } from '../../../store/home/componentStatusSelectors';

const TotalStudentWithOutGroup = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeTotalStudentWithoutGroupStatusServer
    );

    const totalStudentWithOutGroup = useFetchResource(
        () =>
            getTotalStudentWithoutGroup({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectTotalStudentWithoutGroup,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getTotalStudentWithoutGroup({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectTotalStudentWithoutGroup,
            [cycleSelected, schoolId]
        );
    };

    const processStudentData = (data) => {
        if (
            !data ||
            !data.value ||
            !Array.isArray(data.value.data) ||
            !Array.isArray(data.value.labels)
        ) {
            return [];
        }

        return data.value.data.map((value, index) => ({
            id: data.value.labels[index],
            label: data.value.labels[index],
            value: value,
        }));
    };

    const processedData = processStudentData(totalStudentWithOutGroup);

    const colorScheme = {
        Hombres: '#1E90FF',
        Mujeres: '#f47edd',
    };

    return (
        <Box>
            {fetchingStatus === 'pending' && <SkeletongGrafics items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        position: 'relative',
                        overflow: 'visible',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        margin: 'auto',
                    }}
                >
                    <CardContent sx={{ padding: '16px' }}>
                        <Typography variant="h6" gutterBottom>
                            {totalStudentWithOutGroup?.title}
                        </Typography>
                        {processedData.length > 0 ? (
                            <Box height={300}>
                                <ResponsivePie
                                    data={processedData}
                                    margin={{
                                        top: 10,
                                        right: 10,
                                        bottom: 80,
                                        left: 40,
                                    }}
                                    innerRadius={0.45}
                                    padAngle={1}
                                    cornerRadius={5}
                                    colors={({ id }) =>
                                        colorScheme[id] || '#ccc'
                                    }
                                    borderWidth={1}
                                    enableRadialLabels={true}
                                    radialLabel={(d) => `${d.id}: ${d.value}`}
                                    enableSlicesLabels={true}
                                    enableArcLinkLabels={false}
                                    sliceLabel={({ id, value }) =>
                                        `${id}: ${value}`
                                    }
                                    theme={{
                                        labels: {
                                            text: {
                                                fill: 'white',
                                                fontSize: 16,
                                            },
                                        },
                                        tooltip: {
                                            container: {
                                                background: '#333',
                                                color: 'white',
                                                fontSize: 14,
                                                borderRadius: '4px',
                                                boxShadow:
                                                    '0 2px 4px rgba(0, 0, 0, 0.5)',
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        ) : (
                            <Paper
                                elevation={3}
                                sx={{
                                    marginTop: 2,
                                    padding: 2,
                                    textAlign: 'center',
                                }}
                            >
                                <Typography variant="h5" gutterBottom>
                                    Sin datos disponibles
                                </Typography>
                            </Paper>
                        )}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default TotalStudentWithOutGroup;
