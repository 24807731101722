import { Avatar, Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from "@mui/material"
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAddressCard, faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";
import { appClear } from "../../store/actions";

const UserMenu = ({ fontColor }) => {
    const Auth = useAuth();
    const history = useHistory();
    const dispatch = useDispatch()

    //////////////////// MENU /////////////////
    const [menuAnchorEl, setMenuAnchorEl] = useState(null)
    const menuOpen = Boolean(menuAnchorEl);

    const handleMenu = (event) => {
        setMenuAnchorEl(event.currentTarget);

    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    const onchangePerfil = () => {
        handleClose()
        history.push({ pathname: "/perfil" });
    }

    const Logout = () => {
        dispatch(appClear())
        Auth.logout()
        history.push({ pathname: "/" });
    }

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                style={{ color: fontColor }}
                startIcon={<Avatar alt="User Icon" src={Auth.user?.url_photo_profile} sx={{ width: 24, height: 24 }} />}
            >
                {Auth.user?.name} {Auth.user?.last_name}
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleClose}
            >
                <MenuList>
                    <MenuItem onClick={onchangePerfil}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faAddressCard} size="lg" />
                        </ListItemIcon>
                        <ListItemText>Perfil</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={Logout}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
                        </ListItemIcon>
                        <ListItemText>Salir</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
        </Box>
    )
}

export default UserMenu