import { createSlice } from '@reduxjs/toolkit';
import * as constants from '../../../service/const';
import _ from 'lodash';
import { addAsyncCase, initialStateGroups } from '../commonState';
import {
    getAssistsAbsences,
    getBestAssistanceGroup,
    getBestScoreGroup,
    getImc,
    getMaleFemale,
    getMostAbscensesGroup,
    getMostReportsGroup,
    getReports,
    getScoresExtracurricular,
    getScoresMain,
    getScoresSpecial,
    getTotalStudentsDiversity,
    getWorstScoreGroup,
    getAllScholarshipStudents,
} from './thunks';

export const fetchSlice = createSlice({
    name: 'components',
    initialState: initialStateGroups,
    reducers: {
        invalidate: (state) => {
            if (state) {
                Object.keys(state).forEach((kpiName) => {
                    if (state[kpiName] && state[kpiName].fetch) {
                        state[kpiName].fetch.didInvalidate = true;
                    }
                });
            }
        },
    },

    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return initialStateGroups;
        });

        addAsyncCase(builder, getBestAssistanceGroup, 'best_assistance_group');
        addAsyncCase(builder, getMostAbscensesGroup, 'most_abscenses_group');
        addAsyncCase(builder, getMostReportsGroup, 'most_reports_group');
        addAsyncCase(builder, getImc, 'imc');
        addAsyncCase(builder, getScoresMain, 'scores_main');
        addAsyncCase(builder, getScoresSpecial, 'scores_special');
        addAsyncCase(
            builder,
            getScoresExtracurricular,
            'scores_extracurricular'
        );
        addAsyncCase(builder, getAssistsAbsences, 'assists_absences');
        addAsyncCase(builder, getReports, 'reports');
        addAsyncCase(
            builder,
            getTotalStudentsDiversity,
            'total_students_diversity'
        );
        addAsyncCase(builder, getMaleFemale, 'male_female');
        addAsyncCase(builder, getBestScoreGroup, 'best_score_group');
        addAsyncCase(builder, getWorstScoreGroup, 'worst_score_group');
        addAsyncCase(
            builder,
            getAllScholarshipStudents,
            'all_scholarship_students'
        );
    },
});

export const { invalidate } = fetchSlice.actions;

export const getLoading = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getDataStatus = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getFetchStatus = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getCycleSelected = (state) =>
    state.moduleDirector.groups.ui.cycleSelected;
export const getCurrentTab = (state) =>
    state.moduleDirector.groups.ui.currentTab;

export default fetchSlice.reducer;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const selectBestAssistanceGroup = (state) => {
    return state.moduleDirector.groups.components.best_assistance_group.data;
};

export const selectMostAbscensesGroup = (state) => {
    return state.moduleDirector.groups.components.most_abscenses_group.data;
};

export const selectMostReportsGroup = (state) => {
    return state.moduleDirector.groups.components.most_reports_group.data;
};

export const selectImc = (state) => {
    return state.moduleDirector.groups.components.imc.data;
};

export const selectScoresMain = (state) => {
    return state.moduleDirector.groups.components.scores_main.data;
};

export const selectScoresSpecial = (state) => {
    return state.moduleDirector.groups.components.scores_special.data;
};

export const selectScoresExtracurricular = (state) => {
    return state.moduleDirector.groups.components.scores_extracurricular.data;
};

export const selectAssistsAbsences = (state) => {
    return state.moduleDirector.groups.components.assists_absences.data;
};

export const selectReports = (state) => {
    return state.moduleDirector.groups.components.reports.data;
};

export const selectAllScholarshipStudents = (state) => {
    return state.moduleDirector.groups.components.all_scholarship_students.data;
};

export const selectTotalStudentsDiversity = (state) => {
    return state.moduleDirector.groups.components.total_students_diversity.data;
};

export const selectMaleFemale = (state) => {
    return state.moduleDirector.groups.components.male_female.data;
};

export const selectBestScoreGroup = (store) => {
    return store.moduleDirector.groups.components.best_score_group.data;
};

export const selectWorstScoreGroup = (state) => {
    return state.moduleDirector.groups.components.worst_score_group.data;
};

export const selectDirectorGroupsStatusServer = (store) =>
    store.moduleDirector.groups.components.status.statusServer;

export const selectDirectorGroups = (store) => store.moduleDirector.groups;
