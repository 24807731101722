import { combineReducers } from '@reduxjs/toolkit';

import fetch from './fetchSlice';
import operations from './operations';

export default combineReducers({
    fetch,
    //selectors
    //ui
    //items
    operations,
});
