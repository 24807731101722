import React, { useEffect, useState } from 'react';
import { SkeletonDataGrid } from './SkeletonPage';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import { selectSettingByKey } from '../../store/slices/entities/settings';
import { useSelector } from 'react-redux';
import ScoreCell from './ScoreCell';
import { LoadingButton } from '@mui/lab';
import * as _ from 'lodash';

const RegularizationsDataGrid = ({ data, loading, onSave }) => {
    const scoreMinimum = useSelector(
        selectSettingByKey('calification-minimum')
    );

    const { regularizations, students } = data;

    const [rows, setRows] = useState({});
    const [columns, setColumns] = useState([]);
    const [invalidScore, setInvalidScore] = useState(false);
    const [saveLoading, setSaveLoading] = useState('idle');

    useEffect(() => {
        if (loading === 'fulfilled') {
            const studentRows = {};
            students.forEach((student) => {
                const signatures = student.subjects.map((subject) => {
                    let regularizationsAdditionalProp = {};
                    let regularizationsItems = {};
                    let regCalificationsNum = 0;

                    let signature = {
                        payload: {
                            ...subject,
                        },
                        name: {
                            value: subject.subject,
                        },
                        final_average: {
                            value: subject.final_average,
                        },
                    };

                    regularizations.forEach((reg, index) => {
                        let regularizationName = `r-${index + 1}`;

                        let reguCalif = subject.regularizations.find(
                            (element) => element.partial_id === reg.partial_id
                        );

                        let calreg = 0;

                        if (reguCalif?.final_average) {
                            calreg = reguCalif.final_average;
                            ++regCalificationsNum;
                        }

                        regularizationsAdditionalProp[regularizationName] = {
                            isExists: reguCalif !== undefined,
                            value: reguCalif?.final_average || 0,
                            description: reguCalif?.comment || '',
                            add_description: '',
                            isAvailable: reg.is_available,
                            student: student.commond_properties,
                            type_regularization:
                                reguCalif?.type_regularization || '',
                            partial_id: reg.partial_id,
                            calification_id: reguCalif?.calification_id,
                        };

                        regularizationsItems[regularizationName] = {
                            regularization: reg,
                            score: reguCalif?.calification || 0,
                            value: {
                                value: calreg,
                                description: reguCalif?.comment || '',
                                type_regularization:
                                    reguCalif?.type_regularization || '',
                            },
                            isExists: reguCalif !== undefined,
                            type_regularization:
                                reguCalif?.type_regularization || '',
                            description: reguCalif?.comment || '',
                            isAvailable: reg.is_available,
                        };
                    });

                    signature = {
                        ...signature,
                        ...regularizationsAdditionalProp,
                        regularizationsItems,
                    };
                    return signature;
                });
                studentRows[student.commond_properties.student_id] = signatures;
            });

            setRows(studentRows); // Mantiene cada estudiante con su propio conjunto de rows
        }
    }, [data, loading]);

    useEffect(() => {
        let columnsItems = [
            {
                column: 'name',
                title: 'MATERIAS',
                sticky: true,
                className: '',
            },
            {
                column: 'final_average',
                title: 'PROMEDIO',
                sticky: true,
                className: '',
            },
        ];

        const regularizationsColumns = regularizations.map(
            (regularization, index) => ({
                column: `r-${index + 1}`,
                title: `R-${index + 1}`,
                sticky: false,
                className: 'parcial-column',
                partialActive: regularization.is_available,
            })
        );

        columnsItems = [...columnsItems, ...regularizationsColumns];

        let columns = columnsItems.map((i) => {
            return {
                field: i.column,
                headerName: i.title,
                flex: i.column === 'final_average' ? 0.2 : 0.4, // Menor flex para la columna 'final_average'
                minWidth:
                    i.column === 'final_average'
                        ? 40
                        : i.column === 'name'
                        ? 155
                        : 80,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                headerClassName: i.partialActive ? 'partial-active--cell' : '',
                cellClassName: (params) => {
                    let row =
                        params.row.regularizationsItems[params.field]
                            ?.partialActive;
                    if (row) {
                        return 'partial-active--cell';
                    }

                    return '';
                },
                valueGetter: (params) => {
                    if (params.field === 'name') {
                        return `${params.value.value || ''}`;
                    }

                    if (params.field === 'final_average') {
                        return `${params.value.value || ''}`;
                    }

                    return params.value.value;
                },
                renderCell: (params) => {
                    let row = params.row;
                    let columnName = params.field;
                    let currentCell = row[columnName];

                    if (columnName === 'name') {
                        return (
                            <div
                                style={{
                                    whiteSpace: 'pre-line',
                                    textAlign: 'center',
                                }}
                            >
                                {params.value == null ? '' : params.value}
                            </div>
                        );
                    }

                    if (columnName === 'final_average') {
                        return (
                            <div
                                style={{
                                    whiteSpace: 'pre-line',
                                    textAlign: 'center',
                                }}
                            >
                                {params.value == null ? '' : params.value}
                            </div>
                        );
                    }

                    return (
                        <ScoreCell
                            value={currentCell.value}
                            description={currentCell.description}
                            add_description={currentCell.add_description}
                            type_regularization={
                                currentCell.type_regularization
                            }
                            column={params.field}
                            onChange={handleInputChange}
                            scoreMin={scoreMinimum}
                            disabled={!currentCell.isAvailable}
                            subject_id={params.row.payload.subject_id}
                            student_id={currentCell.student.student_id}
                            invalidScore={invalidScore}
                        />
                    );
                },
            };
        });
        setColumns(columns);
    }, [data, loading, rows]);

    useEffect(() => {
        let isValid = true;

        Object.keys(rows).forEach((studentId) => {
            const studentRows = rows[studentId];

            studentRows.forEach((subject) => {
                for (const property in subject) {
                    if (/r-[0-9]/.test(property)) {
                        const currentItem = subject[property];

                        const isScoreInvalid =
                            Number(currentItem.value) > 10 ||
                            isNaN(currentItem.value) ||
                            currentItem.value === '';

                        const isRegularizationTypeMissing =
                            currentItem.changed &&
                            !currentItem.type_regularization;

                        if (isScoreInvalid || isRegularizationTypeMissing) {
                            isValid = false;
                        }
                    }
                }
            });
        });

        setInvalidScore(!isValid);
    }, [rows]);

    const handleInputChange =
        ({ subjectId, studentId, column, isType, isComment }) =>
        (e) => {
            if (e.key === 'r' || e.key === 'c') {
                e.preventDefault();
                return;
            }
            const value = e.target.value;

            let regex = /^(100|(\d{1,2})(\.\d{1,2})?)?$/;

            const studentRows = [...(rows[studentId] || [])];

            let nextRows = studentRows.map((row) => {
                const updatedRow = { ...row };

                if (
                    subjectId === row.payload.subject_id &&
                    studentId === row[column].student.student_id
                ) {
                    if (isType) {
                        updatedRow[column] = {
                            ...updatedRow[column],
                            type_regularization: value,
                            changed: true,
                        };
                    } else if (!isComment) {
                        updatedRow[column] = {
                            ...updatedRow[column],
                            value: value,
                            changed: true,
                        };
                    } else {
                        updatedRow[column] = {
                            ...updatedRow[column],
                            add_description: value,
                            changed: true,
                        };
                    }
                }

                updatedRow[column] = {
                    ...updatedRow[column],
                    isValid: regex.test(updatedRow[column].value),
                };

                return updatedRow;
            });

            setRows({
                ...rows,
                [studentId]: nextRows, // Actualizamos las filas solo para el `studentId` correspondiente
            });
        };

    const handleSaveCalificationList = () => {
        setSaveLoading('pending');
        let calificationNotProceced = [];
        Object.keys(rows).forEach((studentId) => {
            const studentRows = rows[studentId];
            studentRows.forEach((subject) => {
                for (const property in subject) {
                    if (/r-[0-9]/.test(property)) {
                        calificationNotProceced.push({
                            ...subject[property],
                            subject: subject.payload,
                            changed: subject[property].changed
                                ? subject[property].changed
                                : false,
                        });
                    }
                }
            });
        });

        let califications = calificationNotProceced.filter((item) => {
            return (
                Number(item.value) !== 0 &&
                item.value !== '' &&
                item.value !== null &&
                item.changed
            );
        });

        const createRequest = califications
            .filter((item) => !item.isExists)
            .map((item) => ({
                partial_id: item.partial_id,
                student_id: item.student.student_id,
                subject_id: item.subject.subject_id,
                calification: item.value,
                type_regularization: item.type_regularization,
                description: item.add_description,
            }));

        const updateRequest = califications
            .filter((item) => item.isExists)
            .map((item) => ({
                partial_id: item.partial_id,
                student_id: item.student.student_id,
                subject_id: item.subject.subject_id,
                calification: item.value,
                type_regularization: item.type_regularization,
                description: item.add_description,
                calification_id: item.calification_id,
            }));

        // Combina las solicitudes de creación y actualización
        const allCalifications = _.concat(updateRequest, createRequest);

        // Llama a la función onSave con todas las calificaciones
        onSave(allCalifications);
        setSaveLoading('fulfilled');
    };

    return (
        <>
            {loading === 'pending' && <SkeletonDataGrid />}

            {loading === 'fulfilled' && students.length > 0 && (
                <Card sx={{ mt: 1 }}>
                    <CardHeader title={'Regularizaciones'} />
                    <CardContent>
                        {students.map((student) => (
                            <div key={student.commond_properties.student_id}>
                                <Accordion
                                    sx={{
                                        boxShadow:
                                            '0px 4px 8px rgba(0, 0, 0, 0.3)',
                                        marginBottom: '8px',
                                        borderRadius: '8px',
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography variant="h6">
                                            {`${student.commond_properties.name} ${student.commond_properties.app} ${student.commond_properties.apm} `}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="h6">{`Promedio en grupo: ${student.commond_properties.average_in_group}`}</Typography>
                                        <Box
                                            sx={{
                                                '& .partial-active--cell': {
                                                    backgroundColor:
                                                        'rgba(0, 0, 0, 0.05)',
                                                    color: '#1a3e72',
                                                    fontWeight: '600',
                                                },
                                            }}
                                        >
                                            <DataGrid
                                                getRowId={(row) =>
                                                    row.payload.subject_id
                                                }
                                                localeText={
                                                    esES.components.MuiDataGrid
                                                        .defaultProps.localeText
                                                }
                                                rows={
                                                    rows[
                                                        student
                                                            .commond_properties
                                                            .student_id
                                                    ] || []
                                                }
                                                columns={columns}
                                                rowHeight={70}
                                                components={{
                                                    Toolbar: () => (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'flex-end',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <GridToolbar />
                                                        </div>
                                                    ),
                                                }}
                                                componentsProps={{
                                                    panel: {
                                                        placement: 'bottom-end',
                                                    },
                                                }}
                                                rowsPerPageOptions={[]}
                                                disableSelectionOnClick
                                                autoHeight
                                                disableDensitySelector
                                            />
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ))}
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'flex-end' }}>
                        {invalidScore && (
                            <Box>
                                <Typography
                                    sx={{ pr: 2 }}
                                    variant="text"
                                    color={'red'}
                                >
                                    {`Algunos detalles faltan por capturarse (Tipo regularizacion, calificacion no válida)`}{' '}
                                </Typography>
                            </Box>
                        )}
                        <LoadingButton
                            size="small"
                            endIcon={<SendIcon />}
                            loading={saveLoading === 'pending'}
                            loadingPosition="end"
                            variant="contained"
                            onClick={handleSaveCalificationList}
                            disabled={invalidScore}
                        >
                            Guardar
                        </LoadingButton>
                    </CardActions>
                </Card>
            )}
        </>
    );
};

export default RegularizationsDataGrid;
