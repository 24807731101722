import React, { useEffect, useState } from 'react';
import {
    Button,
    CardContent,
    Tooltip,
    Card,
    LinearProgress,
    Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/system';
import { DataGrid, esES, GridActionsCellItem } from '@mui/x-data-grid';
import { useAuth } from '../../hooks';
import SkeletonPage from './components/SkeletonPage';
import { Error } from '../../components/Feedback';
import SyncIcon from '@mui/icons-material/Sync';
import { getLevelName, getSpecialName, getTurnLevel } from '../../libs/utils';
import {
    invalidate,
    loadUI,
    selectStatusServer,
} from '../../views/Scores/store/serverSlice';
import { selectAllGroupsItems } from '../../views/Scores/store/ItemsSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectItem,
    selectScoresUI,
    updateScoreFilter,
} from '../../views/Scores/store/uiSlice';
import { SCHOOL_LEVELS, SPECIAL_TYPES, TURNS_TYPES } from '../../constants';
import { ScoresToolBar } from './components/ScoresToolBar';
import useFeedback from '../../hooks/useFeedback';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { PieModalExport } from './components/PieModalExport';
import FeatureFlags from '../../service/FeatureFlags';
import { AddImportsGroupModal } from './components/AddImportsGroupModal';
import { ImportsCard } from './components/ImportsCard';
import { green, yellow, red } from '@mui/material/colors';
import { selectPartialsBySchoolId } from '../../store/slices/entities/partials';

/**
 * Pagina que funciona como home para la interacion con
 * calificaciones de todos los grupos principales
 */
const ScoresPage = () => {
    ////////////// HOOKS ///////////////

    const history = useHistory();
    const Auth = useAuth();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();
    const schoolId = Auth.getUser().school_id;

    /////////////// SHARED STATE //////////////////

    const statusServer = useSelector(selectStatusServer);
    const groups = useSelector(selectAllGroupsItems);
    const filters = useSelector(selectScoresUI);
    const filter = filters.filter;
    const partials = useSelector(selectPartialsBySchoolId(schoolId));

    const featureFlagGroups = FeatureFlags.isFeatureFlagActive(
        'NEW_ANNEXED_GROUPS_ON_SCORES_VIEW'
    )
        ? groups.filter((group) => group.group_type === 1)
        : groups;

    /////////////// LOCAL STATE /////////////////

    const [openExportModal, setOpenExportModal] = useState(false);
    const [openImportModal, setOpenImportModal] = useState(false);
    const [groupSelected, setGroupSelected] = useState({});
    const [partialActives, setPartialActives] = useState('');

    ////////////// ACTIONS //////////////////////

    /*
     * Manejador para el cerrar del modal de exportaciones
     */
    const handleCloseExportableModal = () => {
        setOpenExportModal(false);
    };

    /**
     * Manejador para el cierre del modal de importaciones
     */
    const handleCloseImportableModal = () => {
        setOpenImportModal(false);
        setGroupSelected({});
    };

    /**
     * Inicia el proceso de recuperacion de datos
     */
    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id));
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate());
        reload();
    };

    /**
     * Funcion para inicar la sincronizacion de datos
     */
    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const getCurrentDate = () => new Date();
    const getPartials = (partials) => {
        const currentDate = getCurrentDate();

        const activePartials = partials.filter(
            (partial) =>
                new Date(partial.start_date) <= currentDate &&
                new Date(partial.end_date) >= currentDate
        );

        const pastPartials = partials
            .filter((partial) => new Date(partial.end_date) < currentDate)
            .sort((a, b) => new Date(b.end_date) - new Date(a.end_date));

        const partialsToShow = [...activePartials, ...pastPartials].slice(0, 3);

        return partialsToShow.map((partial) => partial.partial).join(', ');
    };

    useEffect(() => {
        if (partials.length > 0) {
            const names = getPartials(partials);
            setPartialActives(names);
        }
    }, [partials]);

    /**
     * Accion para seleccionar un grupo
     */
    const onSelectGroup = (group) => {
        dispatch(selectItem(group.group_id));
        history.push({ pathname: `/calificaciones/${group.group_id}` });
    };

    const handleScoreFilterChange = (event) => {
        let newFilterValues = {};
        event.items.forEach((filter) => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value,
            };
        });

        dispatch(updateScoreFilter({ filter: newFilterValues }));
    };

    const LinearProgressBar = ({ value }) => {
        let color;
        let textColor;
        let textShadow;

        if (value === 0.0) {
            color = '#FF6060';
            textColor = '#FFEBEE';
            textShadow = '0 0 3px #840005';
        } else if (value > 0.0 && value < 33) {
            color = '#FF6060';
            textColor = '#FFEBEE';
            textShadow = '0 0 3px #840005';
        } else if (value >= 33 && value < 100) {
            color = '#FFBF43';
            textColor = '#FFF9C4';
            textShadow = '0 0 3px #840005';
        } else {
            color = '#00C483';
            textColor = '#A7FFEB';
            textShadow = '0 0 3px #840005';
        }

        return (
            <div style={{ width: '100%', position: 'relative' }}>
                <LinearProgress
                    variant="determinate"
                    value={value}
                    style={{
                        width: '100%',
                        backgroundColor:
                            value === 0.0 ? '#FF6060' : 'transparent',
                        borderRadius: '10px',
                        height: '25px',
                    }}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: color,
                            borderRadius: '10px',
                        },
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: textColor,
                        fontWeight: 'bold',
                        fontSize: '1.2em',
                        textShadow: textShadow,
                    }}
                >
                    {`${value}%`}
                </div>
            </div>
        );
    };

    /**
     * Columnas del DataGrid
     */
    const columns = [
        {
            field: 'grade',
            headerName: 'Grado',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ value }) => {
                return `${value || ''}`;
            },
        },
        {
            field: 'group',
            headerName: 'Grupo',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'special',
            headerName: 'Tipo',
            type: 'singleSelect',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueOptions: SPECIAL_TYPES.map((i) => i.title),
            valueGetter: (params) => {
                return getSpecialName(params.row.group_type);
            },
        },
        {
            field: 'turn',
            headerName: 'Turno',
            type: 'singleSelect',
            flex: 0.5,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueOptions: TURNS_TYPES.map((i) => i.title),
            valueGetter: (params) => {
                return getTurnLevel(params.row.turn);
            },
        },
        {
            field: 'level',
            headerName: 'Nivel',
            type: 'singleSelect',
            flex: 0.6,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueOptions: SCHOOL_LEVELS.map((i) => i.title),
            valueGetter: (params) => {
                return getLevelName(params.row.level);
            },
        },
        {
            field: 'progress',
            renderHeader: () => (
                <Tooltip title="Porcentaje de alumnos calificados">
                    <Typography>Parcial: {partialActives}</Typography>
                </Tooltip>
            ),
            type: 'singleSelect',
            flex: 0.6,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <LinearProgressBar value={params.row.average_scored_students} />
            ),
        },
        {
            field: 'students',
            headerName: 'Alumnos',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'total_presence',
            headerName: 'Asistencias',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'total_absence',
            headerName: 'Faltas',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'average_rating',
            headerName: 'Promedio',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            sortable: false,
            filterable: false,
            flex: 0.5,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            type: 'actions',
            disableExport: true,
            getActions: (params) => {
                const actions = [
                    <GridActionsCellItem
                        icon={<FontAwesomeIcon icon={faEye} size="lg" />}
                        color="error"
                        title="Detalles"
                        label="Detalles"
                        showInMenu
                        onClick={() => onSelectGroup(params.row)}
                    />,
                    <GridActionsCellItem
                        icon={<DownloadIcon size="lg" />}
                        color="error"
                        title="Exportar calificaciones a PIE"
                        label="Exportar calificaciones a PIE"
                        showInMenu
                        onClick={() => {
                            setGroupSelected(params.row);
                            setOpenExportModal(true);
                        }}
                    />,
                ];

                if (
                    FeatureFlags.isFeatureFlagActive('NEW_IMPORT_TO_PIE_SCORES')
                ) {
                    actions.push(
                        <GridActionsCellItem
                            icon={<UploadIcon size="lg" />}
                            color="error"
                            title="Importar"
                            label="Importar"
                            showInMenu
                            onClick={() => {
                                setGroupSelected(params.row);
                                setOpenImportModal(true);
                            }}
                        />
                    );
                }

                return actions;
            },
        },
    ];

    const dataGrid = featureFlagGroups.map((row) => ({
        id: row.group_id,
        ...row,
    }));

    const sortByCriteria = (a, b, criteria) => {
        for (const criterion of criteria) {
            const aValue = a[criterion];
            const bValue = b[criterion];

            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
        }
        return 0;
    };

    const sortedDataGrid = dataGrid.sort((a, b) => {
        const criteria = ['level', 'turn', 'grade', 'group'];
        return sortByCriteria(a, b, criteria);
    });

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {statusServer == 'pending' && <SkeletonPage />}

            {statusServer == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {statusServer == 'fulfilled' && (
                <>
                    <Box
                        style={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}
                            >
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>

                    <Card sx={{ mt: 5 }}>
                        <CardContent
                            sx={{
                                pt: 0,
                                pl: 0,
                                pr: 0,
                                pb: 0,
                                ' & .MuiDataGrid-root': {
                                    border: 'none',
                                },
                                ' & .MuiDataGrid-toolbarContainer': {
                                    justifyContent: 'flex-end',
                                },
                            }}
                        >
                            <DataGrid
                                localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                        .localeText
                                }
                                rows={sortedDataGrid}
                                columns={columns}
                                pageSize={20}
                                rowsPerPageOptions={[5]}
                                disableSelectionOnClick
                                autoHeight
                                components={{ Toolbar: ScoresToolBar }}
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                }}
                                onFilterModelChange={handleScoreFilterChange}
                                initialState={{
                                    filter: {
                                        filterModel: {
                                            items:
                                                filter.scoreFilters
                                                    .columnField &&
                                                filter.scoreFilters
                                                    .operatorValue &&
                                                filter.scoreFilters.value
                                                    ? [
                                                          {
                                                              columnField:
                                                                  filter
                                                                      .scoreFilters
                                                                      .columnField,
                                                              operatorValue:
                                                                  filter
                                                                      .scoreFilters
                                                                      .operatorValue,
                                                              value: filter
                                                                  .scoreFilters
                                                                  .value,
                                                          },
                                                      ]
                                                    : [],
                                        },
                                    },
                                }}
                                disableDensitySelector
                            />
                        </CardContent>
                    </Card>
                    {FeatureFlags.isFeatureFlagActive(
                        'NEW_IMPORT_TO_PIE_SCORES'
                    ) && <ImportsCard />}
                </>
            )}
            <PieModalExport
                open={openExportModal}
                onClose={handleCloseExportableModal}
                group={groupSelected}
            />
            <AddImportsGroupModal
                open={openImportModal}
                onClose={handleCloseImportableModal}
                group={groupSelected}
            />
        </Box>
    );
};

export default ScoresPage;
