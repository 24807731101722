import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Card, CardContent } from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarContainer,
    GridToolbarExport,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectAbsenceBySubjects,
} from '../../../store/subjects/fetchSlice';
import { getAbsenceBySubjects } from '../../../store/subjects/thunks';
import { SkeletonSubjectsTable } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeAbsenceBySubjectsStatusServer } from '../../../store/subjects/componentsStatusSelectors';
import _ from 'lodash';
import { Tag } from 'antd';

const AbsenceBySubjects = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeAbsenceBySubjectsStatusServer
    );

    const absenceBySubjects = useFetchResource(
        () =>
            getAbsenceBySubjects({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectAbsenceBySubjects,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getAbsenceBySubjects({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectAbsenceBySubjects,
            [cycleSelected, schoolId]
        );
    };

    const headers = absenceBySubjects.value
        ? Object.keys(absenceBySubjects.value)
        : [];
    const rows = absenceBySubjects.value
        ? _.chain(
              absenceBySubjects.value[headers[0]].map((_, index) => {
                  const row = {};
                  headers.forEach((header) => {
                      row[header] =
                          absenceBySubjects.value[header][index].value;
                  });
                  row.id = index + 1;
                  return row;
              })
          )
              .sortBy([
                  (item) => item.Turno,
                  (item) => item.Grado,
                  (item) => item.Grupo,
                  (item) => item.Materia,
                  (item) => item.Profesor,
              ])
              .value()
        : [];

    const columns = headers.map((header) => ({
        field: header,
        headerName: header,
        flex: header === 'Profesor' ? 2 : 1,
        renderCell: (params) => {
            if (header === 'Faltas') {
                return (
                    <Tag
                        color="error"
                        style={{
                            width: '100px',
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {params.value}
                    </Tag>
                );
            }
            return (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {params.value}
                </div>
            );
        },
    }));

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbar {...props} />
            </GridToolbarContainer>
        );
    };
    const [pageSize, setPageSize] = useState(10);

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonSubjectsTable items={9} />
            )}
            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        width: '100%',
                        margin: '20px auto',
                        padding: '16px',
                    }}
                >
                    <CardContent>
                        <Typography
                            variant="h6"
                            color="textPrimary"
                            gutterBottom
                        >
                            {absenceBySubjects.title || 'Promedio por materia'}
                        </Typography>
                        <Box
                            sx={{
                                height: 700,
                                width: '100%',
                                overflowX: 'auto',
                            }}
                        >
                            <DataGrid
                                localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                        .localeText
                                }
                                size="small"
                                rows={rows}
                                columns={columns}
                                pageSize={pageSize}
                                rowsPerPageOptions={[]}
                                disableDensitySelector
                                components={{ Toolbar: CustomToolbar }}
                                componentsProps={{
                                    panel: { placement: 'bottom-end' },
                                }}
                                sx={{
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                        width: '100%',
                                    },
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default AbsenceBySubjects;
