import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { addAsyncCase, initialStateSubjects } from '../commonState';
import {
    getAbsenceBySubjects,
    getAllSubjects,
    getAssistsBySubjects,
    getBestScoreSubject,
    getStudentsApprovedGenderGradeSubject,
    getStudentsFailedGenderGradeSubject,
    getWorstScoreSubject,
} from './thunks';

export const fetchSlice = createSlice({
    name: 'components',
    initialState: initialStateSubjects,
    reducers: {
        invalidate: (state) => {
            if (state) {
                Object.keys(state).forEach((kpiName) => {
                    if (state[kpiName] && state[kpiName].fetch) {
                        state[kpiName].fetch.didInvalidate = true;
                    }
                });
            }
        },
    },

    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return initialStateSubjects;
        });

        addAsyncCase(builder, getAbsenceBySubjects, 'absence_by_subjects');
        addAsyncCase(builder, getAssistsBySubjects, 'assists_by_subjects');
        addAsyncCase(builder, getBestScoreSubject, 'best_score_on_subject');
        addAsyncCase(builder, getWorstScoreSubject, 'worst_score_subject');
        addAsyncCase(
            builder,
            getStudentsApprovedGenderGradeSubject,
            'students_approved_gender_grade_subject'
        );
        addAsyncCase(
            builder,
            getStudentsFailedGenderGradeSubject,
            'students_failed_gender_grade_subject'
        );
        addAsyncCase(builder, getAllSubjects, 'subjects');
    },
});

export const { invalidate } = fetchSlice.actions;

export const getLoading = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getDataStatus = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getFetchStatus = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getCycleSelected = (state) =>
    state.moduleDirector.subjects.ui.cycleSelected;
export const getCurrentTab = (state) =>
    state.moduleDirector.subjects.ui.currentTab;

export default fetchSlice.reducer;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const selectAbsenceBySubjects = (state) => {
    return state.moduleDirector.subjects.components.absence_by_subjects.data;
};

export const selectAssistsBySubjects = (state) => {
    return state.moduleDirector.subjects.components.assists_by_subjects.data;
};

export const selectBestScoreSubject = (state) => {
    return state.moduleDirector.subjects.components.best_score_on_subject.data;
};

export const selectWorstScoreSubject = (state) => {
    return state.moduleDirector.subjects.components.worst_score_subject.data;
};

export const selectStudentsApprovedGenderGradeSubject = (state) => {
    return state.moduleDirector.subjects.components
        .students_approved_gender_grade_subject.data;
};

export const selectStudentsFailedGenderGradeSubject = (state) => {
    return state.moduleDirector.subjects.components
        .students_failed_gender_grade_subject.data;
};

export const selectSubjects = (state) => {
    return state.moduleDirector.subjects.components.subjects.data;
};

export const selectDirectorSubjects = (state) => {
    return state.moduleDirector.subjects;
};

export const selectDirectorSubjectStatusServer = (store) => {
    return store.moduleDirector.subjects.components.status.statusServer;
};
