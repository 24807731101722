import { useState } from 'react';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useDispatch, useSelector } from 'react-redux';
import Services from './../../../service/Connection';
import { LoadingButton } from '@mui/lab';
import {
    faLayerGroup,
    faBoxArchive,
    faBell,
    faCheckToSlot,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select,
    TextField,
    Typography,
    ListItemIcon,
    Stack,
    AlertTitle,
    Alert,
    ListItemText,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CachedIcon from '@mui/icons-material/Cached';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { addOneImport } from '../../../store/slices/entities/imports';
import useFeedback from '../../../hooks/useFeedback';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Feedback from '../../../service/Feedback';

export const ImportModal = ({ openModal, setOpenModal }) => {
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const userID = Auth.getUserID();
    const feedbackApp = useFeedback();

    const config = useSelector(selectSettingBySchoolId(schoolId));
    const dispatch = useDispatch();

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    const [importState, setImportState] = useState({
        file: null,
        import: null,
        import_type: '',
        location: 'local',
    });

    const [loading, setLoading] = useState(false);
    const [downloadingFile, setDownloadingFile] = useState(false);

    const smsValid =
        (schoolId === 21 && process.env.REACT_APP_NAME === 'Producción') ||
        (schoolId === 22 && process.env.REACT_APP_NAME === 'Desarrollo');

    /**
     * import type catalog
     */
    const importsType = {
        students: {
            fileName: 'alumnos',
            descripction:
                'Este tipo de importación creará alumnos y los asignará a su respectivo grupo, si el grupo no existe aún en CURA este será creado también',
            resourceFileName: 'students',
        },
        assists: {
            fileName: 'asistencias',
            descripction:
                'Este tipo de importación permite la incorporación de nuevas asistencias de los alumnos a cura',
            resourceFileName: 'assits',
        },
        califications: {
            fileName: 'calificaciones',
            descripction:
                'Esta importación registrará las calificaciones por parcial que no fueron capturadas sobre una materia asociada a un alumno. ',
            resourceFileName: 'califications',
        },
        'massive-sms': {
            fileName: 'sms',
            descripction: 'Esta importación enviará un sms masivo. ',
            resourceFileName: 'massive-sms',
        },
    };

    /**
     * file extensions allowed
     */
    const importFiles = ['.csv', '.xlsx', '.xls'];

    const onInputChange = (event) => {
        const name = event.target.name;
        let newValue = event.target.value;

        if (name === 'import') {
            const selectedFile = event.target.files[0];

            if (selectedFile) {
                let allowFile = importFiles.find((ext) =>
                    selectedFile.name.endsWith(ext)
                );

                if (allowFile) {
                    newValue = selectedFile;
                } else {
                    feedbackApp.showFeedback({
                        title:
                            'Por favor seleccione un archivo con extensión ' +
                            importFiles.join(','),
                    });
                    event.target.value = null;
                    newValue = null;
                }
            }
        }

        setImportState({ ...importState, [name]: newValue });
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpenModal(false);
        setImportState({
            file: null,
            import: null,
            import_type: '',
            location: 'local',
        });
    };

    const importTypeValid = () => {
        return {
            invalid: importState.import_type === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    /**
     * Crear la importacion
     */
    const saveImport = () => {
        setLoading(true);
        let formData = new FormData();
        formData.append('file', importState.import);
        formData.append('import_type', importState.import_type);
        formData.append('location', importState.location);

        Services.saveImportFile(userID, formData)
            .then((response) => {
                dispatch(addOneImport(response.data.data));

                feedbackApp.showFeedback({
                    title: 'Importación creada',
                });

                handleClose();
            })
            .catch((err) => {
                let FeedbackService = new Feedback();

                feedbackApp.showFeedback({
                    title: FeedbackService.getMessage(err).message,
                });
            })
            .then(() => {
                setLoading(false);
            });
    };

    /**
     * Crear la importacion
     */
    const onDownloadFileExample = (importModel, format) => {
        setDownloadingFile(true);

        Services.getExampleFile(importModel.resourceFileName, format)
            .then((response) => {
                let formats = {
                    csv: '.csv',
                    xlsx: '.xlsx',
                    xls: '.xls',
                };

                const ext = formats[format];

                exportToFile(response.data, importModel.fileName + ext);
            })
            .catch((err) => {
                feedbackApp.showFeedback({
                    title: 'El archivo no pudo ser descargado',
                });
            })
            .then(() => {
                setDownloadingFile(false);
            });
    };

    /**
     * Exportar el archivo
     *
     * @param {*} blob
     * @param {*} filename
     */
    function exportToFile(blob, filename) {
        try {
            saveAs(blob, filename);
        } catch (err) {
            throw err;
        }
    }

    return (
        <Dialog
            open={openModal}
            fullWidth={true}
            disableEscapeKeyDown={loading === true}
            onClose={handleClose}
            maxWidth={'md'}
        >
            <DialogTitle
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ flexGrow: 1, color: fontColor.value }}
                    >
                        Agregar importación
                    </Typography>
                </div>
            </DialogTitle>

            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <List dense={true}>
                            <ListItem>
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faBoxArchive}
                                        size="2x"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Información."
                                    secondary={
                                        'Toda la información se verá reflejada en tu dashboard cuando la importación haya terminado'
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faLayerGroup}
                                        size="2x"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Tipo de importación."
                                    secondary={
                                        'Determina como es que tu documento será interpretado por CURA'
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faCheckToSlot}
                                        size="2x"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Validación de datos."
                                    secondary={
                                        'Es importante mencionar que se verificarán todos los datos ingresados, que sean coherentes con la información previamente almacenada de la escuela.'
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faBell} size="2x" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Te mantendremos al tanto."
                                    secondary={
                                        'Te informaremos mediante correo y notificación la finalización de tu importación'
                                    }
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Stack spacing={2}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Grid container spacing={1}>
                                    <Grid
                                        container
                                        item
                                        xl={12}
                                        sm={12}
                                        md={12}
                                        xs={12}
                                    >
                                        <FormControl
                                            variant="outlined"
                                            sx={{ width: '100%', mt: 2 }}
                                            error={importTypeValid().invalid}
                                        >
                                            <InputLabel id="import-type">
                                                Tipo de importación
                                            </InputLabel>
                                            <Select
                                                labelId="import-type"
                                                id="import_type"
                                                name="import_type"
                                                value={importState.import_type}
                                                onChange={onInputChange}
                                                label="Tipo de importación"
                                                fullWidth
                                            >
                                                <MenuItem value={'students'}>
                                                    Alumnos
                                                </MenuItem>
                                                <MenuItem value={'assists'}>
                                                    Asistencias
                                                </MenuItem>
                                                <MenuItem
                                                    value={'califications'}
                                                >
                                                    Calificaciones
                                                </MenuItem>
                                                {smsValid && (
                                                    <MenuItem
                                                        value={'massive-sms'}
                                                    >
                                                        SMS Masivo
                                                    </MenuItem>
                                                )}
                                            </Select>
                                            {importTypeValid().invalid && (
                                                <FormHelperText>
                                                    {importTypeValid().message}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    {importState.import_type && (
                                        <Grid
                                            item
                                            xl={12}
                                            sm={12}
                                            md={12}
                                            xs={12}
                                            style={{ marginTop: '5px' }}
                                        >
                                            <Alert severity="info">
                                                <AlertTitle>
                                                    Acción a realizar
                                                </AlertTitle>
                                                {
                                                    importsType[
                                                        importState.import_type
                                                    ].descripction
                                                }
                                            </Alert>
                                            <Box sx={{ mt: 2 }}>
                                                <Typography
                                                    variant="subtitle1"
                                                    component="div"
                                                >
                                                    Layout de importación
                                                    descargable
                                                </Typography>
                                                <Stack
                                                    sx={{ mt: 1 }}
                                                    spacing={1}
                                                    direction={'row'}
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <LoadingButton
                                                        loading={
                                                            downloadingFile
                                                        }
                                                        color={'success'}
                                                        loadingPosition="start"
                                                        startIcon={
                                                            <CloudDownloadIcon />
                                                        }
                                                        variant="contained"
                                                        onClick={() =>
                                                            onDownloadFileExample(
                                                                importsType[
                                                                    importState
                                                                        .import_type
                                                                ],
                                                                'xlsx'
                                                            )
                                                        }
                                                    >
                                                        XLSX
                                                    </LoadingButton>
                                                    <LoadingButton
                                                        loading={
                                                            downloadingFile
                                                        }
                                                        color={'success'}
                                                        loadingPosition="start"
                                                        startIcon={
                                                            <CloudDownloadIcon />
                                                        }
                                                        variant="contained"
                                                        onClick={() =>
                                                            onDownloadFileExample(
                                                                importsType[
                                                                    importState
                                                                        .import_type
                                                                ],
                                                                'xls'
                                                            )
                                                        }
                                                    >
                                                        XLS
                                                    </LoadingButton>
                                                </Stack>
                                            </Box>
                                        </Grid>
                                    )}

                                    <Grid
                                        container
                                        item
                                        xl={12}
                                        sm={12}
                                        md={12}
                                        xs={12}
                                        style={{ marginTop: '5px' }}
                                    >
                                        <TextField
                                            id="import"
                                            name="import"
                                            size="small"
                                            variant="outlined"
                                            inputProps={{ maxLength: 50 }}
                                            value={importState.file}
                                            onChange={onInputChange}
                                            fullWidth
                                            type="file"
                                            accept=".csv"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Alert
                                icon={<AccessTimeIcon fontSize="inherit" />}
                                severity="warning"
                            >
                                El tiempo de importación puede variar
                                dependiendo de la cantidad de información
                            </Alert>
                            <Alert
                                icon={<CachedIcon fontSize="inherit" />}
                                severity="warning"
                            >
                                No olvides sincronizar tu pantalla
                            </Alert>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={() => {
                        saveImport();
                    }}
                    loading={loading === true}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={
                        !(
                            importState.import_type !== '' &&
                            importState.import !== null
                        )
                    }
                >
                    {'Importar'}
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        handleClose();
                    }}
                    disabled={loading === true}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
