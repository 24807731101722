import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Stack,
    FormControl,
    MenuItem,
    Typography,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import LoadingButton from '@mui/lab/LoadingButton';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Exportable from './../../../service/Exportable';
import { useAuth } from '../../../hooks';
import { selectPartialsBySchoolLevelSorted } from '../../../store/slices/entities/partials';
import useFeedback from '../../../hooks/useFeedback';
import Feedback from '../../../service/Feedback';
import { saveFile } from '../../../helper';
import DownloadIcon from '@mui/icons-material/Download';
import { selectThemeBySchool } from '../../../store/slices/entities/settings';

/**
 * Modal para exportar calificaciones a sistema de PIE
 */
export const PieModalExport = ({ open, onClose, group }) => {
    ////////////////// HOOKS ////////////////

    const Auth = useAuth();
    const feedbackApp = useFeedback();

    ////////////////// SHARED STATE ////////////////

    const schoolId = Auth.getUser().school_id;
    const sortedPartials = useSelector(
        selectPartialsBySchoolLevelSorted(schoolId, group.level)
    );

    const {colorTitleBar, fontColor} = useSelector(selectThemeBySchool(schoolId));

    ////////////////// LOCAL STATE ////////////////

    const [selectedPartial, setSelectedPartial] = useState('');
    const [loading, setLoading] = useState(false);
    let FeedbackService = new Feedback();

    ////////////////// ACTIONS ////////////////
    
    /**
     * manejador para el cierre del modal
     */
    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        
        setSelectedPartial('');
        onClose();
    };

    /**
     * Accion para exportar 
     */
    const onExportPie = () => {
        setLoading(true);
        Exportable.export(
            'pie_group_scores',
            'xlsx',
            { group_id: group.group_id, partial_id: selectedPartial },
            null
        )
            .then((result) => {
                saveFile(result.data, 'calificaciones-por-grupo-para-pie.xlsx').then(() => {
                    feedbackApp.showFeedback({
                        title: 'Archivo exportado correctamente',
                    });
                }).catch((err) => {
                    feedbackApp.showFeedback({
                        title: 'Estamos teniendo problemas al almacenar el archivo',
                    });
                })
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title: FeedbackService.getMessage(error).title,
                });
            }).then(() => {
                handleClose();
                setLoading(false);
            });
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Slide}
            disableEscapeKeyDown={loading}
            TransitionProps={{
                direction: 'left',
            }}
            PaperProps={{
                style: {
                    color: fontColor.value,
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    height: '100%',
                    width: '300px',
                    overflowY: 'auto',
                },
            }}
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    textAlign: 'center',
                    backgroundColor: colorTitleBar.value,
                    color: fontColor.value,
                }}
            >
                Exportar calificaciones a PIE
            </DialogTitle>
            <DialogContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '16px',
                    }}
                >
                    <PostAddIcon
                        sx={{ fontSize: 72, color: colorTitleBar.value }}
                    />
                    <Typography
                        variant="subtitile1"
                        sx={{
                            mt: 2,
                            textAlign: 'center',
                            color: 'gray',
                        }}
                    >
                        Seleccionar parcial o regularización
                    </Typography>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        sx={{ paddingTop: 2 }}
                    >
                        <TextField
                            label="Seleccionar"
                            variant="outlined"
                            fullWidth
                            select
                            value={selectedPartial}
                            onChange={(e) => setSelectedPartial(e.target.value)}
                            id="partial"
                        >
                            {sortedPartials.map((partial) => (
                                <MenuItem
                                    key={partial.partial_id}
                                    value={partial.partial_id}
                                >
                                    {`${
                                        partial.is_regularized
                                            ? 'Regularización'
                                            : 'Parcial'
                                    } ${partial.partial}`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Stack>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        loading={loading}
                        disabled={selectedPartial === ''}
                        loadingPosition="start"
                        onClick={onExportPie}
                        startIcon={<DownloadIcon size="lg"/> }
                    >
                        Descargar
                    </LoadingButton>
                </Stack>
                <Button onClick={handleClose} autoFocus variant="contained" disabled={loading}>
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
