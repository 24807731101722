import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    Card,
    CardContent,
    CircularProgress,
    Grid,
} from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { Tag } from 'antd';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectAllStudentsOnDirectors,
} from '../../../store/students/fetchSlice';
import { getAllStudentsOnDirectors } from '../../../store/students/thunks';
import DEFAULT_AVATAR_URL from './../../../../assets/images/Unknown_person.jpeg';
import { selectComponentStudentsAllStudentsStatusServer } from '../../../store/students/componentsStatusSelectors';
import { SkeletonStudentsTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';

const AllStudents = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentStudentsAllStudentsStatusServer
    );

    const allStudents = useFetchResource(
        () =>
            getAllStudentsOnDirectors({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectAllStudentsOnDirectors,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getAllStudentsOnDirectors({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectAllStudentsOnDirectors,
            [cycleSelected, schoolId]
        );
    };

    const headers = allStudents.value ? Object.keys(allStudents.value) : [];
    const data = allStudents.value ? allStudents.value : {};

    const renderTag = (header, value) => {
        switch (header) {
            case 'Promedio':
            case 'Promedio de materias calificadas':
            case 'IMC':
                return (
                    <Tag
                        color="blue"
                        style={{
                            width: '100px',
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        }}
                    >
                        {value || '0'}
                    </Tag>
                );
            case 'Asistencias':
                return (
                    <Tag
                        color="success"
                        style={{
                            width: '100px',
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        }}
                    >
                        {value || '0'}
                    </Tag>
                );
            case 'Faltas':
            case 'Reportes':
                return (
                    <Tag
                        color="error"
                        style={{
                            width: '100px',
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        }}
                    >
                        {value || '0'}
                    </Tag>
                );
            case 'Faltas justificadas':
                return (
                    <Tag
                        color="warning"
                        style={{
                            width: '100px',
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        }}
                    >
                        {value}
                    </Tag>
                );
            default:
                return value;
        }
    };

    const rows =
        data[headers[0]]?.map((_, index) => {
            let row = { id: index };
            headers.forEach((header) => {
                if (header === 'Foto') {
                    row[header] =
                        data[header][index]?.value || DEFAULT_AVATAR_URL;
                } else {
                    row[header] = data[header][index]?.value || '';
                }
            });
            return row;
        }) || [];

    const columns = headers.map((header) => {
        let column = {
            field: header,
            headerName: header,
            disableExport: header === 'Foto',
            flex: 1,
            minWidth:
                header === 'CURP'
                    ? 200
                    : header === 'Foto'
                    ? 100
                    : header === 'Información de los padres'
                    ? 500
                    : 150,
            renderCell: (params) => {
                if (header === 'Foto') {
                    return (
                        <img
                            src={params.value}
                            alt="Foto del estudiante"
                            style={{
                                width: 50,
                                height: 50,
                                borderRadius: '50%',
                                objectFit: 'cover',
                            }}
                        />
                    );
                }
                return (
                    <div
                        style={{
                            whiteSpace:
                                header === 'Nombre' ||
                                header === 'Información de los padres'
                                    ? 'normal'
                                    : 'nowrap',
                            overflow: 'visible',
                            textOverflow: 'clip',
                            wordBreak: 'break-word',
                            maxHeight:
                                header === 'Información de los padres'
                                    ? '150px'
                                    : 'none',
                            overflowY:
                                header === 'Información de los padres'
                                    ? 'auto'
                                    : 'hidden',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        title={
                            header === 'Información de los padres'
                                ? params.value
                                : undefined
                        }
                    >
                        {renderTag(header, params.value)}
                    </div>
                );
            },
            headerClassName: 'bold-header',
            cellClassName: 'data-grid-cell',
        };

        if (
            [
                'Es indígena',
                'Es extranjero',
                'Es foráneo',
                'Es becado',
                'Padres',
                'En repetición de año',
            ].includes(header)
        ) {
            column.minWidth = 120;
        }

        return column;
    });

    const sortModel = [
        {
            field: 'Nombre',
            sort: 'asc',
        },
    ];

    const CustomToolbar = () => (
        <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
            <GridToolbar />
        </GridToolbarContainer>
    );

    return (
        <Box>
            {fetchingStatus === 'pending' && (
                <SkeletonStudentsTables items={9} />
            )}
            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '8px',
                        width: '97%',
                        overflow: 'hidden',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        borderColor: '#e0e0e0',
                        mb: 4,
                    }}
                >
                    <CardContent sx={{ width: '100%', padding: '24px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                    gutterBottom
                                >
                                    {allStudents.title ||
                                        'Información general de los estudiantes'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {allStudents.loading ? (
                                    <Box display="flex" justifyContent="center">
                                        <CircularProgress />
                                    </Box>
                                ) : allStudents.error ? (
                                    <Typography color="error">
                                        Error: {allStudents.error.message}
                                    </Typography>
                                ) : (
                                    <Box sx={{ height: 700, width: '100%' }}>
                                        <DataGrid
                                            localeText={
                                                esES.components.MuiDataGrid
                                                    .defaultProps.localeText
                                            }
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            rowsPerPageOptions={[]}
                                            disableDensitySelector
                                            disableSelectionOnClick
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                            sortModel={sortModel}
                                            sx={{
                                                border: 'none',
                                                '& .MuiDataGrid-cell': {
                                                    whiteSpace: 'normal',
                                                    overflow: 'visible',
                                                    textOverflow: 'clip',
                                                    wordBreak: 'break-word',
                                                },
                                                '& .bold-header': {
                                                    fontWeight: 'bold',
                                                    borderBottom:
                                                        '1px solid #e0e0e0',
                                                },
                                            }}
                                        />
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default AllStudents;
