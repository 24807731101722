import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { useAuth, useFeedback } from '../../../../hooks';
import { fetchCreateConcept } from '../../../store/concepts/operations';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { CreateCatConcepts } from '../../../store/cat_concepts/operations';
import { createCatDiscount } from '../../../store/cat_discounts/operations';
import Feedback from '../../../../service/Feedback';

const CreateCatalog = ({ open, onclose }) => {
    ///////////// SHERD STATE /////////////

    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const feedbackApp = useFeedback();

    ///////////// LOCAL STATE /////////////

    ///////////// FORM CONFIGURATION /////////////

    const formik = useFormik({
        initialValues: {
            title: '',
            value: '',
            value_modality: '',
        },
        validationSchema: Yup.object({
            title: Yup.string().required('El título es obligatorio'),
            value: Yup.number()
                .required('El valor es obligatorio')
                .positive('El valor debe ser positivo')
                .when('value_modality', {
                    is: 2,
                    then: (schema) =>
                        schema.max(99, 'El valor no puede ser mayor que 99%'),
                    otherwise: (schema) => schema,
                }),
            value_modality: Yup.number()
                .required('La modalidad es obligatoria')
                .oneOf([1, 2], 'Modalidad inválida'),
        }),
        onSubmit: async (values, { resetForm }) => {
            await dispatch(createCatDiscount({ schoolId, data: values }))
                .unwrap()
                .then((response) => {
                    feedbackApp.showFeedback({
                        title: 'Catalogo de descuentos creado',
                    });
                    resetForm();
                    onclose();
                })
                .catch((error) => {
                    feedbackApp.showFeedback({
                        title: error.feedback.title,
                    });
                });
        },
    });

    ///////////// RENDER /////////////

    return (
        <>
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onclose();
                    }
                }}
                maxWidth="lg"
                fullWidth
                disableEscapeKeyDown
                sx={{
                    '& .MuiDialog-paper': {
                        padding: 5,
                        width: '60%',
                        borderRadius: 2,
                    },
                }}>
                <DialogTitle
                    sx={{
                        textAlign: 'center',
                        position: 'relative',
                    }}>
                    <Typography variant="h4" component="div">
                        Registrar un nuevo catalogo de descuento
                    </Typography>
                    <Typography variant="body2">
                        Aquí podrás registrar un nuevo catalogo de descuento
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onclose}
                        sx={{
                            position: 'absolute',
                            right: 6,
                            top: 6,
                            color: theme.palette.grey[500],
                        }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <TextField
                            fullWidth
                            label="Título del catalogo de Descuento"
                            variant="outlined"
                            id="title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.title &&
                                Boolean(formik.errors.title)
                            }
                            helperText={
                                formik.touched.title && formik.errors.title
                            }
                            margin="dense"
                        />

                        <FormControl
                            fullWidth
                            margin="dense"
                            error={
                                formik.touched.value_modality &&
                                Boolean(formik.errors.value_modality)
                            }>
                            <InputLabel id="value_modality-label">
                                Modalidad de Valor
                            </InputLabel>
                            <Select
                                labelId="value_modality-label"
                                id="value_modality"
                                name="value_modality"
                                value={formik.values.value_modality}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                label="Modalidad de Valor">
                                <MenuItem value={1}>Monetario</MenuItem>
                                <MenuItem value={2}>Porcentaje</MenuItem>
                            </Select>
                            {formik.touched.value_modality &&
                                formik.errors.value_modality && (
                                    <Typography
                                        variant="body2"
                                        color="error"
                                        sx={{ mt: 1 }}>
                                        {formik.errors.value_modality}
                                    </Typography>
                                )}
                        </FormControl>
                        <TextField
                            fullWidth
                            id="value"
                            name="value"
                            placeholder="0.00"
                            type="number"
                            label="Valor"
                            variant="outlined"
                            value={formik.values.value}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.value &&
                                Boolean(formik.errors.value)
                            }
                            helperText={
                                formik.touched.value && formik.errors.value
                            }
                            margin="dense"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {formik.values.value_modality === 2
                                            ? '%'
                                            : '$'}
                                    </InputAdornment>
                                ),
                            }}
                            disabled={!formik.values.value_modality}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={
                                    formik.isSubmitting ||
                                    !(formik.isValid && formik.dirty)
                                }
                                type="submit">
                                {formik.isSubmitting ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    'Registrar Catalogo de Descuento'
                                )}{' '}
                            </Button>
                        </Box>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default CreateCatalog;
