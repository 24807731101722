import React, { useState } from 'react';
import {
    Box,
    Checkbox,
    Menu,
    MenuItem,
    TextField,
    Typography,
    IconButton,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DnsIcon from '@mui/icons-material/Dns';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import PersonIcon from '@mui/icons-material/Person';

const CalificationMenu = ({
    value,
    studentId,
    description,
    add_description,
    in_recovery,
    column,
    updated_by,
    created_by,
    onChange,
    scoreMin,
    disabled,
}) => {
    ////////// LOCAL STATE //////////
    const [anchorEl, setAnchorEl] = useState(null);

    const isRegularizationCell = /r-[0-9]+/.test(column);

    /*
     * Manejo del click para el anchor del menu
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /*
     * Manejo del cerrado del menu
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /*
     * Manejo del cambio de estado
     * de las calificación
     */
    const handleCalificationChange = (e) => {
        onChange({
            studentId: studentId,
            column: column,
            isCheckbox: false,
            isComment: false,
            value: e.target.value,
        })(e);
    };

    /*
     * Manejo del cambio de estado
     * del comentario de calificación
     */
    const handleCommentChange = (e) => {
        const comment = e.target.value.slice(0, 250);
        onChange({
            studentId: studentId,
            column: column,
            isCheckbox: false,
            isComment: true,
            value: comment,
        })(e);
    };

    /*
     * Manejo del cambio de estado
     * del checkbox de recuperación
     */
    const handleCheckboxChange = (e) => {
        onChange({
            studentId: studentId,
            column: column,
            isCheckbox: true,
            isComment: false,
            value: e.target.checked,
        })(e);
    };

    /*
     * Función que evita que al presionar
     * las teclas "r" y "c" se pierda focus
     * de los inputs
     */
    const handleKeyDown = (e) => {
        e.stopPropagation();
    };

    /*
     * Función que hace el salto de linea
     * en el comentario mostrado en el menu
     */
    const splitTextIntoLines = (text, wordsPerLine) => {
        const words = text.split(' '); // Dividir el texto en palabras
        const lines = [];

        for (let i = 0; i < words.length; i += wordsPerLine) {
            lines.push(words.slice(i, i + wordsPerLine).join(' '));
        }

        return lines;
    };

    return (
        <>
            {/* <div onClick={handleClick}>
                <Typography
                    sx={{
                        color: value < scoreMin?.value ? 'red' : 'green',
                    }}>
                    {value !== undefined ? value : '0'}
                </Typography>
            </div>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}> 
                <MenuItem disableRipple>
                    <TextField
                        id="calification-input"
                        label="Calificación"
                        type="text"
                        variant="outlined"
                        value={value}
                        onChange={handleCalificationChange}
                        disabled={disabled}
                    />
                </MenuItem>
                <MenuItem disableRipple>
                    <Typography sx={{ pl: 1 }} variant="p">
                        {splitTextIntoLines(description, 3).map(
                            (line, index) => (
                                <div key={index}>{line}</div>
                            )
                        )}
                    </Typography>
                </MenuItem>
                <MenuItem disableRipple>
                    <Box display="flex" flexDirection="column">
                        <TextField
                            id="comment-input"
                            label="Comentario"
                            variant="outlined"
                            value={add_description}
                            multiline
                            onChange={handleCommentChange}
                            disabled={disabled}
                            onKeyDown={handleKeyDown}
                            inputProps={{
                                maxLength: description
                                    ? 250 - description.length
                                    : 250,
                            }}
                        />
                        <Typography variant="caption" color="textSecondary">
                            {description
                                ? `${add_description.length}/${
                                      250 - description.length
                                  }`
                                : `${add_description.length}/250`}
                        </Typography>
                    </Box>
                </MenuItem>
                {!isRegularizationCell && (
                    <MenuItem disableRipple>
                        <Checkbox
                            id="recovery-checkbox"
                            checked={in_recovery}
                            onChange={handleCheckboxChange}
                            disabled={disabled}
                        />
                        <Typography>Recuperación</Typography>
                    </MenuItem>
                )}
            </Menu> */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                    gap: '8px',
                }}
            >
                <TextField
                    value={value}
                    onChange={handleCalificationChange}
                    variant="outlined"
                    size="small"
                    disabled={disabled}
                    sx={{
                        flex: '1 1 auto',
                        height: '40px',
                    }}
                    InputProps={{
                        style: { height: '40px' },
                    }}
                />
                {updated_by === 2 && (
                    <DnsIcon
                        sx={{
                            color: '#0a90d3',
                        }}
                    />
                )}
                {updated_by !== created_by && (
                    <LaptopChromebookIcon
                        sx={{
                            color: '#ffbf43',
                        }}
                    />
                )}
                {updated_by === created_by && (
                    <PersonIcon
                        sx={{
                            color: '#grey',
                        }}
                    />
                )}
                <IconButton
                    onClick={handleClick}
                    sx={{
                        padding: '4px',
                        height: '40px',
                    }}
                >
                    <MoreVertIcon />
                </IconButton>
            </div>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {/* <MenuItem disableRipple>
                    <TextField
                        id="calification-input"
                        label="Calificación"
                        type="text"
                        variant="outlined"
                        value={value}
                        onChange={handleCalificationChange}
                        disabled={disabled}
                    />
                </MenuItem> */}
                <MenuItem disableRipple>
                    <Typography sx={{ pl: 1 }} variant="p">
                        {splitTextIntoLines(description, 3).map(
                            (line, index) => (
                                <div key={index}>{line}</div>
                            )
                        )}
                    </Typography>
                </MenuItem>
                <MenuItem disableRipple>
                    <Box display="flex" flexDirection="column">
                        <TextField
                            id="comment-input"
                            label="Comentario"
                            variant="outlined"
                            value={add_description}
                            multiline
                            onChange={handleCommentChange}
                            disabled={disabled}
                            onKeyDown={handleKeyDown}
                            inputProps={{
                                maxLength: description
                                    ? 250 - description.length
                                    : 250,
                            }}
                        />
                        <Typography variant="caption" color="textSecondary">
                            {description
                                ? `${add_description.length}/${
                                      250 - description.length
                                  }`
                                : `${add_description.length}/250`}
                        </Typography>
                    </Box>
                </MenuItem>
                {!isRegularizationCell &&
                    value > 0 &&
                    value < scoreMin.value && (
                        <MenuItem disableRipple>
                            <Checkbox
                                id="recovery-checkbox"
                                checked={in_recovery}
                                onChange={handleCheckboxChange}
                                disabled={disabled}
                            />
                            <Typography>Recuperación</Typography>
                        </MenuItem>
                    )}
            </Menu>
        </>
    );
};

export default CalificationMenu;
