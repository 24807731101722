import { useSelector } from "react-redux";

import { getActivitiesList } from '../../../store/slices/calendar'
import ActivityItemCard from './../ActivityItemCard';


const ActivityList = (props) => {

    const activities = useSelector(getActivitiesList)

    /**
     * Obtenemos lasactividades con diseño
     * 
     * @returns 
     */
    function getActivitiesUI() {
        let activityUI = []
        for (const activity of activities) {
            activityUI.push( <ActivityItemCard key={activity.activity_id} {...activity}></ActivityItemCard>)
        }

        return activityUI
    }

    return (
        getActivitiesUI()
    )
}


export default ActivityList