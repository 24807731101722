import React, { useState } from 'react';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import { useAuth } from '../../../hooks';
import { useSelector } from 'react-redux';
import { selectThemeBySchool } from '../../../store/slices/entities/settings';
import DashboardIcon from '@mui/icons-material/Dashboard';

/**
 * Selector de modulos
 */
const ModuleSelectorModal = ({
    modulesByLevelGrade,
    subjects,
    onSelectedModule,
}) => {
    //////////// HOOKS ////////////////////

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    //////////// LOCAL STATE ////////////////////

    const [selectedModule, setSelectedModule] = useState(null);

    /////////////////// THEME /////////////////

    const { colorTitleBar, fontColor } = useSelector(
        selectThemeBySchool(schoolId)
    );

    /////////////////// HANDLERS /////////////////

    const handleModuleSelect = (module) => {
        setSelectedModule(module);
        onSelectedModule(module);
    };

    //////////////////////// COMPONENTS ////////////////////////////

    return (
        <>

            {
                modulesByLevelGrade.length > 0 && (
                    <Grid container spacing={2}>
                        {modulesByLevelGrade.map((module, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Card
                                    sx={{
                                        maxWidth: 400,
                                        borderRadius: 3,
                                        overflow: 'auto',
                                        boxShadow: 3,
                                    }}
                                >
                                    <CardHeader
                                        title={module.annexed_name}
                                        sx={{
                                            backgroundColor: colorTitleBar.value,
                                            color: fontColor.value,
                                        }}
                                        avatar={
                                            <Avatar
                                                sx={{
                                                    bgcolor: fontColor.value,
                                                    color: colorTitleBar.value,
                                                }}
                                                aria-label="recipe"
                                            >
                                                <DashboardIcon />
                                            </Avatar>
                                        }
                                    />
                                    <CardContent>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            mb={1}
                                            useFlexGap
                                            flexWrap="wrap"
                                        >
                                            {module.subjects.map((subjectId, i) => {
                                                const subject = subjects.find(
                                                    (subject) =>
                                                        subject.catalog_subject_id ===
                                                        subjectId
                                                );
                                                const subjectName = subject
                                                    ? subject.title
                                                    : 'N/A';
                                                return (
                                                    <Chip
                                                        key={i}
                                                        sx={{
                                                            height: 'auto',
                                                            '& .MuiChip-label': {
                                                                display: 'block',
                                                                whiteSpace: 'normal',
                                                            },
                                                        }}
                                                        label={
                                                            <strong>
                                                                {subjectName}
                                                            </strong>
                                                        }
                                                    />
                                                );
                                            })}
                                        </Stack>
                                    </CardContent>
                                    <CardActions
                                        disableSpacing
                                        sx={{
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <Button
                                            onClick={() => handleModuleSelect(module)}
                                            onSelectedModule={selectedModule}
                                            variant="outlined"
                                            color="primary"
                                        >
                                            Seleccionar
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )
            }

            {
                modulesByLevelGrade.length === 0 && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{ height: '100%' }}
                    >
                        <Typography variant="h5" gutterBottom component="div">No hay módulos disponibles</Typography>
                    </Stack>
                )
            }

        </>
    );
};
export default ModuleSelectorModal;
