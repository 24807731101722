import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';

import {
    setAllDiscounts,
    upsertManyDiscounts,
} from '../../../store/slices/entities/payments/discounts';
import { upsertManyStudents } from '../../../store/slices/entities/students';
import { upsertManyCatalogDiscounts } from '../../../store/slices/entities/payments/cat_discounts';
import { upsertManyConcepts } from '../../../store/slices/entities/payments/concepts';
import { upsertManyCataloConcepts } from '../../../store/slices/entities/payments/cat_concepts';
import { selectPaymentDiscounts } from './selectors';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../service/const';
import { upsertManyAgreements } from '../../../store/slices/entities/payments/agreements';

// Estado inicial del slice
const emptyState = {
    expireIn: null,
    fetchingAt: null,
    status: 'idle',
    operation: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

// Thunk para realizar la petición asincrónica de descuentos de pago
export const fetchCreateDiscount = createAsyncThunk(
    'Discount/create/fetch',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const [
                responseConcepts,
                responseCatalogDiscounts,
                responseCatalogConcepts,
                responseAgreements,
            ] = await Promise.all([
                Connection.getConceptsBySchool(schoolId),
                Connection.getSchoolsByDiscountsCatalog(schoolId),
                Connection.getSchoolsByConceptsCatalog(schoolId),
                Connection.getAgreementBySchool(schoolId),
            ]);

            const concepts = responseConcepts.data.data;
            const catDiscounts = responseCatalogDiscounts.data.data;
            const catConcepts = responseCatalogConcepts.data.data;
            const agreements = responseAgreements.data.data;

            thunkAPI.dispatch(upsertManyCataloConcepts(catConcepts));
            thunkAPI.dispatch(upsertManyConcepts(concepts));
            thunkAPI.dispatch(upsertManyCatalogDiscounts(catDiscounts));
            thunkAPI.dispatch(upsertManyAgreements(agreements));

            return {
                concepts,
                catConcepts,
                catDiscounts,
                agreements,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

// Thunk para realizar la petición asincrónica de descuentos de pago
export const fetchDiscounts = createAsyncThunk(
    'Discounts/fetch',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const [
                responseConcepts,
                responseCatConcepts,
                responseStudents,
                responseCatalogDiscounts,
                responseDiscounts,
            ] = await Promise.all([
                Connection.getConceptsBySchool(schoolId),
                Connection.getSchoolsByConceptsCatalog(schoolId),
                Connection.StudentsBySchool(schoolId),
                Connection.getSchoolsByDiscountsCatalog(schoolId),
                Connection.getDiscountsBySchool(schoolId),
            ]);

            const students = responseStudents.data.data;
            const catalogDiscounts = responseCatalogDiscounts.data.data;
            const concepts = responseConcepts.data.data;
            const discounts = responseDiscounts.data.data;
            const CatConcepts = responseCatConcepts.data.data;

            thunkAPI.dispatch(upsertManyDiscounts(discounts));
            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyCatalogDiscounts(catalogDiscounts));
            thunkAPI.dispatch(upsertManyConcepts(concepts));
            thunkAPI.dispatch(upsertManyCataloConcepts(CatConcepts));

            return {
                discounts,
                concepts,
                students,
                catalogDiscounts,
                CatConcepts,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        // Condición para ejecutar el thunk solo si es necesario
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectDiscountsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

// Thunk para obtener los conceptos asignados a un alumno específico
export const fetchConceptsStudents = createAsyncThunk(
    'Discounts/fetch/ConceptsStudents',
    async (studentId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const responseConceptsStudent =
                await Connection.getAssignedConceptsToStudent(studentId);

            const concepts = responseConceptsStudent.data.data;

            thunkAPI.dispatch(upsertManyConcepts(concepts));

            return { concepts };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

// Slice para los conceptos de pago
const DiscountsSlice = createSlice({
    name: 'Discounts',
    initialState: emptyState,
    reducers: {
        invalidate: (state) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDiscounts.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchDiscounts.fulfilled, (state, action) => {
                state.expireIn = new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                );
                state.fetchingAt = Date.now();
                state.didInvalidate = false;

                state.status = 'fulfilled';
            })
            .addCase(fetchDiscounts.rejected, (state, action) => {
                state.status = 'rejected';
                state.feedback = action.payload.feedback;
            })
            .addCase(fetchConceptsStudents.pending, (state) => {
                state.operation = 'pending';
            })
            .addCase(fetchConceptsStudents.fulfilled, (state, action) => {
                state.operation = 'fulfilled';
                state.concepts = action.payload.concepts;
            })
            .addCase(fetchConceptsStudents.rejected, (state, action) => {
                state.operation = 'rejected';
            });
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });
    },
});

export const { invalidate: invalidateDiscounts } = DiscountsSlice.actions;

export default DiscountsSlice.reducer;

//////////////////////////////////// SELECTORES ////////////////////////////////////

export const selectDiscountsFetch = createSelector(
    selectPaymentDiscounts,
    (state) => state.fetch
);
