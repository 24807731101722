import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Card,
    CardContent,
    Tooltip,
    Typography,
    Button,
} from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectMaleFemale,
} from '../../../store/groups/fetchSlice';
import { getMaleFemale } from '../../../store/groups/thunks';
import { selectComponentGroupsMaleFemaleStatusServer } from '../../../store/groups/componentStatusSelectors';
import { SkeletongGrafics } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const PrincipalGroupsMaleFemale = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentGroupsMaleFemaleStatusServer
    );
    const MaleFemale = useFetchResource(
        () =>
            getMaleFemale({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectMaleFemale,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getMaleFemale({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectMaleFemale,
            [cycleSelected, schoolId]
        );
    };

    const data = MaleFemale?.value
        ? Object.keys(MaleFemale?.value).map((key) => ({
              group: key,
              Hombres: MaleFemale?.value[key].data[0] || 0,
              Mujeres: MaleFemale?.value[key].data[1] || 0,
          }))
        : [];

    const [page, setPage] = useState(0);
    const rowsPerPage = 5;

    const handleChangePage = (direction) => {
        setPage((prevPage) =>
            Math.max(
                0,
                Math.min(
                    Math.ceil(data.length / rowsPerPage) - 1,
                    prevPage + direction
                )
            )
        );
    };

    const paginatedData = data.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && <SkeletongGrafics items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        position: 'relative',
                        overflow: 'visible',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <CardContent
                        sx={{
                            width: '100%',
                            padding: '24px',
                        }}
                    >
                        <Tooltip
                            title={
                                MaleFemale?.description ||
                                'Número total de alumnas y alumnos por grupo'
                            }
                            followCursor
                        >
                            <Typography variant="h6">
                                Total de alumnos por género y grado
                            </Typography>
                            <Box height={400}>
                                <ResponsiveBar
                                    data={paginatedData}
                                    keys={['Hombres', 'Mujeres']}
                                    indexBy="group"
                                    groupMode="grouped"
                                    margin={{
                                        top: 50,
                                        right: 130,
                                        bottom: 50,
                                        left: 60,
                                    }}
                                    padding={0.3}
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={({ id }) =>
                                        id === 'Hombres' ? '#1E90FF' : '#f47edd'
                                    }
                                    borderColor={{
                                        from: 'color',
                                        modifiers: [['darker', 1.6]],
                                    }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legendPosition: 'middle',
                                        legendOffset: 32,
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legendPosition: 'middle',
                                        legendOffset: -40,
                                    }}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor="white"
                                    legends={[
                                        {
                                            dataFrom: 'keys',
                                            anchor: 'bottom-right',
                                            direction: 'column',
                                            justify: false,
                                            translateX: 120,
                                            translateY: 0,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 20,
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 0.85,
                                            symbolSize: 20,
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemOpacity: 1,
                                                    },
                                                },
                                            ],
                                        },
                                    ]}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    theme={{
                                        axis: {
                                            ticks: {
                                                text: { fontSize: 12 },
                                            },
                                        },
                                        labels: {
                                            text: {
                                                fontSize: 16,
                                                fill: 'white',
                                            },
                                        },
                                        tooltip: {
                                            container: {
                                                background: 'white',
                                                color: 'inherit',
                                                fontSize: 12,
                                                borderRadius: '2px',
                                                boxShadow:
                                                    '0 1px 2px rgba(0, 0, 0, 0.25)',
                                            },
                                        },
                                    }}
                                    layers={[
                                        'grid',
                                        'axes',
                                        'bars',
                                        'markers',
                                        'legends',
                                        (props) =>
                                            props.bars.map((bar) => {
                                                const isSmallValue =
                                                    bar.data.value <= 10;

                                                return (
                                                    <g
                                                        transform={`translate(${bar.x}, ${bar.y})`}
                                                        key={bar.key}
                                                    >
                                                        <rect
                                                            width={bar.width}
                                                            height={bar.height}
                                                            fill={bar.color}
                                                        />
                                                        {bar.data.value >
                                                            10 && (
                                                            <text
                                                                x={
                                                                    bar.width /
                                                                    2
                                                                }
                                                                y={
                                                                    bar.height /
                                                                    2
                                                                }
                                                                textAnchor="middle"
                                                                dominantBaseline="central"
                                                                style={{
                                                                    fill: 'white',
                                                                    fontSize: 16,
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                {bar.data.value}
                                                            </text>
                                                        )}
                                                        {isSmallValue && (
                                                            <text
                                                                x={
                                                                    bar.width /
                                                                    2
                                                                }
                                                                y={-5}
                                                                textAnchor="middle"
                                                                style={{
                                                                    fill: 'black',
                                                                    fontSize: 16,
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                {bar.data.value}
                                                            </text>
                                                        )}
                                                    </g>
                                                );
                                            }),
                                    ]}
                                />
                            </Box>
                        </Tooltip>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Typography variant="subtitle1">
                                Página {page + 1} de{' '}
                                {Math.ceil(data.length / rowsPerPage)}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '20px',
                            }}
                        >
                            <Button
                                startIcon={<ArrowBackIosIcon />}
                                variant="contained"
                                color="primary"
                                onClick={() => handleChangePage(-1)}
                                size="small"
                                disabled={page === 0}
                                sx={{ mr: 1 }}
                            >
                                Anterior
                            </Button>
                            <Button
                                endIcon={<ArrowForwardIosIcon />}
                                variant="contained"
                                color="primary"
                                onClick={() => handleChangePage(1)}
                                size="small"
                                disabled={
                                    page >=
                                    Math.ceil(data.length / rowsPerPage) - 1
                                }
                            >
                                Siguiente
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default PrincipalGroupsMaleFemale;
