import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';
import Services from '../../../service/Connection';
import { STUDENTSUI_MODALS_EXPIRE_TIME } from '../../../service/const';
import Feedback from '../../../service/Feedback';
import * as _ from 'lodash';
import Authentication from '../../../service/Login';
import { selectStudentById, upsertManyStudents, upsertOneStudent } from '../../../store/slices/entities/students';
import {
    selectAllGroupsEntities,
    selectGroupsById,
} from '../../../store/slices/entities/groups';
import {
    addManyGroupsStudents,
} from '../../../store/slices/entities/groups_students';
import {
    upsertManySubjects,
} from '../../../store/slices/entities/subjects';
import {
    upsertManySubjectcatalog,
} from '../../../store/slices/entities/subjectcatalogs';
import {
    upsertManyPartials,
} from '../../../store/slices/entities/partials';
import {
    selectCalificationsBy,
    upsertManyCalifications,
} from '../../../store/slices/entities/califications';
import {
    selectAssistsBy,
    upsertManyAssists,
} from '../../../store/slices/entities/assists';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import { selectSubjectsByStudent, selectPartialsByStudent, selectSubjectsCatalogByStudent, selectGroupsByStudent } from '../../../store/slices/entities/selectors';

const emptyState = {};

/**
 * Slice para el settings UI
 */
export const studentsUIItemsSlice = createSlice({
    name: 'scoresUI/items',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI de alumnos
         */
        invalidateStudentItem: (state, { payload }) => {
            const { groupId, studentId } = payload;

            state[groupId].items[
                studentId
            ].servers.details.didInvalidate = true;
        },

        /**
         * Invalidar datos de la UI de grupos
         */
        invalidateGroupItem: (state, { payload }) => {
            state[payload].servers.details.didInvalidate = true;
        },
        setAllItems: (state, action) => {
            return action.payload;
        },
        itemUpdated: (state, { payload }) => {
            const { id, changes } = payload;
            state[id] = { ...state[id], ...changes };
        },
        selectStudentItem: (state, { payload }) => {
            const { itemGroupId, itemStudentId } = payload;

            state[itemGroupId].ui.studentSelected = itemStudentId;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        //////////////// GROUPS INFORMATION ///////////////////////////////

        builder.addCase(loadGroupsDetailsUI.fulfilled, (state, { payload }) => {
            const { groupId } = payload;

            const newState = {
                expireIn: new Date().setMinutes(
                    new Date().getMinutes() + STUDENTSUI_MODALS_EXPIRE_TIME
                ),
                ferchingAt: Date.now(),
                didInvalidate: false,
                statusServer: 'fulfilled',
            };
            state[groupId].servers.details = {
                ...state[groupId].servers.details,
                ...newState,
            };
        });

        builder.addCase(loadGroupsDetailsUI.pending, (state, { meta }) => {
            const { groupId } = meta;

            state[groupId].servers.details.statusServer = 'pending';
        });

        builder.addCase(loadGroupsDetailsUI.rejected, (state, action) => {
            if (action.payload) {
                const { groupId, feedback } = action.payload;

                state[groupId].servers.details.statusServer = 'rejected';
                state[groupId].servers.details.feedback = feedback;
            }
        });

        //////////////// STUDENTS INFORMATION ///////////////////////////////

        builder.addCase(loadStudentsDetailsUI.fulfilled, (state, { meta }) => {
            const { arg } = meta;
            const { groupId, studentId } = arg;

            const newState = {
                expireIn: new Date().setMinutes(
                    new Date().getMinutes() + STUDENTSUI_MODALS_EXPIRE_TIME
                ),
                ferchingAt: Date.now(),
                didInvalidate: false,
                statusServer: 'fulfilled',
            };
            state[groupId].items[studentId].servers.details = {
                ...state[groupId].items[studentId].servers.details,
                ...newState,
            };
        });

        builder.addCase(loadStudentsDetailsUI.pending, (state, { meta }) => {
            const { arg } = meta;

            state[arg.groupId].items[
                arg.studentId
            ].servers.details.statusServer = 'pending';
        });

        builder.addCase(loadStudentsDetailsUI.rejected, (state, action) => {
            if (action.payload) {
                const { groupId, studentId } = action.meta.arg;
                const { feedback } = action.payload;

                state[groupId].items[studentId].servers.details.statusServer =
                    'rejected';
                state[groupId].items[studentId].servers.details.feedback =
                    feedback;
            }
        });
    },
});

export const {
    addManyItems,
    upsertManyItems,
    itemUpdated,
    setAllItems,
    removeOneItem,
    selectStudentItem,
    invalidateStudentItem,
    invalidateGroupItem,
} = studentsUIItemsSlice.actions;

export default studentsUIItemsSlice.reducer;

//////////////////// SELECTORES //////////////////

/**
 * Selecciona todos los ItemsGroups
 */
export const selectAllGroupsItems = (store) => {
    let items = Object.values(selectAllGroupsItemsEntities(store));
    let groups = selectAllGroupsEntities(store);

    return items.map((i) => ({
        ...groups[i.ui.group_id],
        item: i,
    }));
};

export const selectAllGroupsItemsEntities = (store) => store.scoresUI.items;

/**
 * Recuperar un GroupItem por ID
 */
export const selectGroupItemById = (id) => (store) => {
    return selectAllGroupsItemsEntities(store)[id];
};

////////////////////// GROUPS PAGE /////////////////////////

/**
 * Selecciona el grupo actual con informacion adicional
 */
export const selectGroupItemSelected = (itemId) => (store) => {
    const groupSelected = store.scoresUI.ui.groupSelected;

    let group = selectGroupsById(groupSelected)(store);

    let item = selectGroupItemById(groupSelected)(store);

    return {
        ...group,
        item,
    };
};

/**
 * Selector para recuperar el status server del detalle del grupo
 */
export const selectGroupsStatusServerItemSelected = (store) => {
    const groupSelected = store.scoresUI.ui.groupSelected;

    let item = selectGroupItemById(groupSelected)(store);

    if (groupSelected && item) {
        return item.servers.details.statusServer;
    }
    return 'idle';
};

/**
 * Selecciona todos los grupos
 */
export const selectAllStudentsFromGroupItemSelected = (store) => {
    const groupSelected = store.scoresUI.ui.groupSelected;
    let groupItem = store.scoresUI.items[groupSelected];

    return Object.values(groupItem.items).map((i) => {
        return {
            ...selectStudentById(i.ui.student_id)(store),
            item: i,
        };
    });
};

//////////////////////////// STUDENTS PAGES //////////////////////////////////

/**
 * Selector para recuperar los datos comunes para las vistas 
 * de calificaciones y asistencias
 */
export const selectCommonStudentDataView = (studentId) =>
createSelector(
    selectGroupsByStudent(studentId),
    selectSubjectsByStudent(studentId),
    selectPartialsByStudent(studentId),
    selectSubjectsCatalogByStudent(studentId),
    selectStudentById(studentId),
    (
        group,
        subjects,
        partialsInStudentsGroup,
        subjectCatalog,
        student
    ) => {
        const partials = partialsInStudentsGroup.filter(
            (item) => item.is_regularized === false
        );
        const regularizations = partialsInStudentsGroup.filter(
            (item) => item.is_regularized === true
        );

        return {
            group,
            student,
            partials,
            regularizations,
            subjects,
            subjectCatalog
        };
    }
);

/**
 * Selector para recuperar toda la informacion necesaria para el componente de 
 * calificaciones de un alumno
 */
export const selectStudentCalificationsView = (studentId) =>
createSelector(
    selectCommonStudentDataView(studentId),
    selectCalificationsBy({ student_id: studentId }),
    (
        allData,
        califications
    ) => {
        return {
            ...allData,
            califications,
        };
    }
);

/**
 * Selector para recuperar toda la informacion necesaria para el componente de 
 * calificaciones de un alumno
 */
export const selectStudentAssistsView = (studentId) =>
createSelector(
    selectCommonStudentDataView(studentId),
    selectAssistsBy({ student_id: studentId }),
    (
        allData,
        assists
    ) => {
       
        return {
            ...allData,
            assists,
        };
    }
);

/**
 * Selector para recuperar el estado del proceso de recuperacion de datos
 * de la pagina de alumnos
 */
export const selectStudentFetchStatus = (groupId, studentId) => createSelector(
    selectAllGroupsItemsEntities,
     (items) => {
      
        return items[groupId].items[studentId].servers.details.statusServer;
    }
);
/**
 * Selector para recuperar el estado del proceos de operacion de la 
 * pagina de alumnos
 */
export const selectStudentStatusOperation = (groupId, studentId) => createSelector(
    selectAllGroupsItemsEntities,
    (items) => {
        return items[groupId].items[studentId].servers.details.statusOperation;
    }
);

///////////////////////// TRUNKS ////////////////////////////

function getEmptyItem(studentId) {
    return {
        items: {},
        servers: {
            details: {
                expireIn: null,
                ferchingAt: null,
                statusServer: 'idle',
                statusOperation: 'idle',
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
        },
        ui: {
            student_id: studentId,
        },
    };
}

/**
 * Cargar informacion todos los alumnos de
 * el grupo seleccionado
 */
export const loadGroupsDetailsUI = createAsyncThunk(
    'scoresUI/item/fetch/groups',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        let Auth = new Authentication();

        const state = thunkAPI.getState();
        const groupId = state.scoresUI.ui.groupSelected;

        try {
            let students = await Services.getStudentsByGroup(groupId).then(
                (i) => i.data.data
            );

            let groupsStudents = students.map((i) => {
                return {
                    student_id: i.student_id,
                    group_id: groupId,
                };
            });

            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(addManyGroupsStudents(groupsStudents));

            const items = students.reduce((preveState, curr) => {
                preveState[curr.student_id] = getEmptyItem(curr.student_id);
                return preveState;
            }, {});

            thunkAPI.dispatch(
                itemUpdated({
                    id: groupId,
                    changes: { items },
                })
            );

            return {
                groupId,
                students,
                groupsStudents,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                groupId,
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            const state = getState();

            const groupId = state.scoresUI.ui.groupSelected;

            let { didInvalidate, expireIn } =
                state.scoresUI.items[groupId].servers.details;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
        getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
            return {
                groupId: getState().scoresUI.ui.groupSelected,
            };
        },
    }
);

/**
 * Cargar informacion de los grupos del alumno
 */
export const loadStudentsDetailsUI = createAsyncThunk(
    'scoresUI/item/fetch/students',
    async ({ groupId, studentId, schoolId, level }, thunkAPI) => {
        let FeedbackService = new Feedback();

        let Auth = new Authentication();

        try {
            let student = await Services.getStudentById(studentId).then((i) => i.data.data);

            let partials = await Services.getPartialsByLevel(
                schoolId,
                level
            ).then((i) => i.data.data);

            let subjets = await Services.getSubjectsByStudent(studentId).then(
                (i) => i.data.data
            );

            // TODO METERLE EL NIVEL
            let catalogSubject = await Services.getSubjectCatalogBySchool(
                schoolId
            ).then((i) => i.data.data);

            let allAssitens = await Services.getAssistensByStudent(
                studentId
            ).then((i) => i.data.data);

            let califications = await Services.getCalificationbyStudent(
                studentId
            ).then((i) => i.data.data);

            let groups = await Services.getGroupByStudent(studentId).then(
                (i) => i.data.data
            );

            let specialGroups = await Services.getSpecialGroupsByStudent(
                studentId
            ).then((i) => i.data.data);

            const allGroups = _.compact([groups, ...specialGroups]);

            const groupsIds = _.map(allGroups, 'group_id');
            const groupsStudents = groupsIds.map((i) => {
                return {
                    student_id: Number(studentId),
                    group_id: i,
                };
            });

            thunkAPI.dispatch(addManyGroupsStudents(groupsStudents));
            thunkAPI.dispatch(upsertManyAssists(allAssitens));
            thunkAPI.dispatch(upsertManyCalifications(califications));
            thunkAPI.dispatch(upsertManyPartials(partials));
            thunkAPI.dispatch(upsertManySubjects(subjets));
            thunkAPI.dispatch(upsertManySubjectcatalog(catalogSubject));
            thunkAPI.dispatch(upsertOneStudent(student))

            return {
                studentId,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                studentId,
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: ({ groupId, studentId }, { getState, extra }) => {
            const state = getState();

            const studentItemSelected =
                state.scoresUI.items[groupId].items[studentId];

            let { didInvalidate, expireIn } =
                studentItemSelected.servers.details;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
