import { createAsyncThunk } from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import {
    selectComponentAllProfessorsFetch,
    selectComponentProfessorsLagAssistsFetch,
    selectComponentProfessorsLagCalificationsFetch,
    selectComponentProfessorsLastInsidentFetch,
    selectComponentProfessorsMostReportsFetch,
    selectComponentProfessorsTotalReportsFetch,
    selectComponentProfessorsWithoutGroupFetch,
} from './componentsStatusSelectors';

export const getLagAssists = createAsyncThunk(
    'fetch/lag_assists',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'professors',
                    type: 'top',
                    idKey: 'lag_assists',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching LagAssists:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentProfessorsLagAssistsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getLagCalifications = createAsyncThunk(
    'fetch/lag_califications',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'professors',
                    type: 'top',
                    idKey: 'lag_califications',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching LagCalifications:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentProfessorsLagCalificationsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getProfessorsWithoutGroup = createAsyncThunk(
    'fetch/professors_without_group',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'professors',
                    type: 'table',
                    idKey: 'professors_without_group',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching ProfessorsWithoutGroup:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentProfessorsWithoutGroupFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getProfessorMostReports = createAsyncThunk(
    'fetch/professor_most_reports',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'professors',
                    type: 'top',
                    idKey: 'professor_most_reports',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching ProfessorMostReports:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentProfessorsMostReportsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalReports = createAsyncThunk(
    'fetch/all_professor_reports',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'professors',
                    type: 'table',
                    idKey: 'all_professor_reports',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching TotalReports:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentProfessorsTotalReportsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getAllProfessors = createAsyncThunk(
    'fetch/professors',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'professors',
                    type: 'table',
                    idKey: 'professors',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching Professors:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectComponentAllProfessorsFetch(
                getState()
            );

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getLastIncident = createAsyncThunk(
    'fetch/last_incident',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'professors',
                    type: 'table',
                    idKey: 'last_incident',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching LastIncident:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentProfessorsLastInsidentFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
