import { useAuth } from '../../hooks';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import googleApp from '../../public/assets/img/playstore.png';
import appleApp from '../../public/assets/img/appstore.png';
import { appClear } from '../../store/actions';
import { useDispatch } from 'react-redux';

/**
 * Componente que es mostrado cuando se esta trabajando en un modulo
 *
 * @returns
 */
const Working = () => {
    const Auth = useAuth();
    const history = useHistory();
    const dispatch = useDispatch();

    const Logout = () => {
        dispatch(appClear());
        Auth.logout();
        history.push({ pathname: '/' });
    };

    return (
        <Box className="working">
            <Box className="box">
                <Stack
                    sx={{
                        backgroundColor: '#201c1ca3',
                        p: 2,
                    }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}>
                    <Box>
                        <Typography
                            style={{
                                margin: 0,
                                fontSize: 70,
                                fontWeight: 700,
                                color: 'white',
                                position: 'sticky',
                            }}>
                            Trabajando
                        </Typography>
                    </Box>

                    <Stack spacing={2} direction="row">
                        <Button
                            variant="contained"
                            startIcon={<FontAwesomeIcon icon={faHome} />}>
                            <Link
                                to="/"
                                className="btn-selected"
                                style={{ color: 'white' }}>
                                <span>Inicio</span>
                            </Link>
                        </Button>
                        <Button
                            variant="contained"
                            onClick={Logout}
                            startIcon={<FontAwesomeIcon icon={faUser} />}>
                            Salir
                        </Button>
                    </Stack>
                </Stack>
            </Box>

            <div id="footer">
                <div className="container">
                    <h3>Descarga la aplicación</h3>
                    <div className="tienda">
                        <div className="playstore">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.eficaz.appmovil&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                                target="_blank"
                                rel="noreferrer">
                                <img
                                    alt="Disponible en Google Play"
                                    style={{ width: '200px' }}
                                    src={googleApp}
                                />
                            </a>
                        </div>
                        <div className="appstore">
                            <a
                                href="https://apps.apple.com/mx/app/eficaz/id1498693599"
                                target="_blank"
                                rel="noreferrer">
                                <img
                                    alt="Disponible en App Store"
                                    style={{ width: '250px' }}
                                    src={appleApp}
                                />
                            </a>
                        </div>
                    </div>
                    <div className="copyright">
                        &copy; Copyright{' '}
                        <strong>
                            <span>Cura</span>
                        </strong>
                        . All Rights Reserved
                    </div>
                    <div className="copyright">
                        <strong>
                            <span>
                                <Link
                                    to="/aviso-privacidad"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Aviso de privacidad
                                </Link>
                            </span>
                        </strong>
                    </div>
                </div>
            </div>
        </Box>
    );
};

export default Working;
