import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Card, CardContent, Box, Tooltip } from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectLastIncident,
} from '../../../store/professors/fetchSlice';
import { getLastIncident } from '../../../store/professors/thunks';
import { selectComponentProfessorsLastInsidentStatusServer } from '../../../store/professors/componentsStatusSelectors';
import Error from '../../../../components/Feedback/Error';
import { SkeletonProfessorsTable } from '../../../components/Skeleton';
import _ from 'lodash';

const LastIncidents = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentProfessorsLastInsidentStatusServer
    );

    const lastIncident = useFetchResource(
        () =>
            getLastIncident({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectLastIncident,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getLastIncident({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectLastIncident,
            [cycleSelected, schoolId]
        );
    };

    const [pageSize, setPageSize] = useState(10);

    const columns = [
        { field: 'profesor', headerName: 'Profesor', flex: 1 },
        { field: 'catalogo', headerName: 'Catálogo', flex: 1 },
        { field: 'descripcion', headerName: 'Descripción', flex: 2 },
        { field: 'fecha', headerName: 'Fecha', flex: 1 },
        { field: 'folio', headerName: 'Folio de atención', flex: 1 },
    ];

    const rows =
        lastIncident?.value?.Profesor?.map((_, index) => ({
            id: index + 1,
            profesor: lastIncident?.value?.Profesor[index]?.value || '',
            catalogo: lastIncident?.value?.Catálogo[index]?.value || '',
            descripcion: lastIncident?.value?.Descripción[index]?.value || '',
            fecha: lastIncident?.value?.Fecha[index]?.value || '',
            folio: lastIncident?.value['Folio de atención'][index]?.value || '',
        })) || [];

    const sortedRows = _.sortBy(rows, [(item) => item.fecha]);

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbar {...props} />
            </GridToolbarContainer>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonProfessorsTable items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        height: 'auto',
                        overflow: 'hidden',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#fff',
                        borderColor: '#e0e0e0',
                        mb: 4,
                    }}
                >
                    <CardContent
                        sx={{
                            width: '100%',
                            padding: '24px',
                        }}
                    >
                        <Tooltip
                            title={
                                lastIncident?.description ||
                                'Último incidente reportado por profesor en la escuela'
                            }
                            followCursor
                        >
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                gutterBottom
                            >
                                {lastIncident?.title ||
                                    'Último incidente reportado'}
                            </Typography>
                        </Tooltip>
                        <Box sx={{ width: '100%' }}>
                            <DataGrid
                                columns={columns}
                                rows={sortedRows}
                                pageSize={pageSize}
                                rowsPerPageOptions={[]}
                                disableDensitySelector
                                disableSelectionOnClick
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                }}
                                getRowHeight={() => 'auto'}
                                sx={{
                                    '& .MuiDataGrid-cell': {
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                        overflow: 'visible',
                                        lineHeight: '1.5',
                                    },
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                    },
                                }}
                                autoHeight
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default LastIncidents;
