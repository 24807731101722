import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    MenuItem,
    TextField,
    Typography,
    CardActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { DatePicker, LocalizationProvider, esES } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { VALIDATIONS } from '../../src/constants';

const SchoolGeneralInfoCard = ({ schoolsData, onUpdate }) => {
    const [generalInfoLoading, setGeneralInfoLoading] = useState(false);
    const [hasCCTChanged, setHasCCTChanged] = useState(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Campo requerido')
            .matches(VALIDATIONS.ALPHA_NUMERIC_SPACES, 'Campo incorrecto'),
        cellphone: Yup.string()
            .required('Campo requerido')
            .matches(/^[0-9]{10}$/, 'Campo incorrecto'),
        level: Yup.number().required('Campo requerido'),
        control: Yup.number().required('Campo requerido'),
        clave: Yup.string()
            .required('Campo requerido')
            .max(12, 'Demasiado largo, máximo 12 caracteres'),
        sustenance: Yup.number().required('Campo requerido'),
        school_type_id: Yup.number().required('Campo requerido'),
        geographical_scope: Yup.number().required('Campo requerido'),
        foundation_date: Yup.string()
            .required('Campo requerido o incorrecto')
            .test(
                'is-valid-date',
                'La fecha de fundación no puede ser en el futuro y debe ser posterior a 1900',
                (value) => {
                    if (!value) return false;
                    const selectedDate = DateTime.fromISO(value);
                    const minDate = DateTime.fromObject({
                        year: 1900,
                        month: 1,
                        day: 1,
                    });
                    const currentDate = DateTime.now();
                    return (
                        selectedDate >= minDate && selectedDate <= currentDate
                    );
                }
            ),
        maximum_student_capacity: Yup.string()
            .trim()
            .required('Campo requerido')
            .matches(/^\d{1,4}$/, 'Debe tener máximo 4 dígitos'),
        reserved_students_capacity: Yup.string()
            .trim()
            .required('Campo requerido')
            .matches(/^\d{1,4}$/, 'Debe tener máximo 4 dígitos')
            .test(
                'max-capacity',
                'Debe ser inferior a la capacidad máxima',
                function (value) {
                    const maxCapacity = this.parent.maximum_student_capacity;
                    return Number(value) <= Number(maxCapacity);
                }
            ),
        oci: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, 'Campo incorrecto'),
    });

    const formik = useFormik({
        initialValues: {
            name: schoolsData.name || '',
            cellphone: schoolsData.cellphone || '',
            clave: schoolsData.clave || '',
            level: schoolsData.level || '',
            control: schoolsData.control || '',
            sustenance: schoolsData.sustenance || '',
            school_type_id: schoolsData.school_type_id || '',
            geographical_scope: schoolsData.geographical_scope || '',
            foundation_date: schoolsData.foundation_date || '',
            maximum_student_capacity: schoolsData.maximum_student_capacity || 0,
            reserved_students_capacity:
                schoolsData.reserved_students_capacity || 0,
            oci: schoolsData.oci || '',
        },
        validationSchema,
        onSubmit: (values) => {
            setGeneralInfoLoading(true);
            onUpdate(values).finally(() => {
                setGeneralInfoLoading(false);
            });
        },
        enableReinitialize: true,
    });

    const handleCellphoneChange = (e) => {
        const { value } = e.target;
        if (/^[0-9]{0,10}$/.test(value)) {
            formik.setFieldValue('cellphone', value);
        }
    };

    const rules = {
        control: {
            P: 'Privada',
        },
        level: {
            ...(() => {
                const preescolar = ['CC', 'JS'];
                const bachillerato = [
                    'CT',
                    'TA',
                    'TC',
                    'PT',
                    'BH',
                    'BP',
                    'CB',
                    'MS',
                    'U',
                ];
                const result = {};
                preescolar.forEach((code) => (result[code] = 'Preescolar'));
                bachillerato.forEach((code) => (result[code] = 'Bachillerato'));
                return result;
            })(),
            PB: 'Primaria',
            PR: 'Primaria',
            ES: 'Secundaria',
            SU: 'Universidad',
        },
        schoolType: {
            ST: 'Técnica',
            TV: 'Telesecundaria',
            ML: 'Centro de Atención Múltiple',
        },
        sustenance: {
            E: 'Estatal',
            D: 'Federal',
        },
    };

    const valueMap = {
        control: {
            Pública: 1,
            Privada: 2,
        },
        level: {
            'Educación Básica': 1,
            'Educación Básica y Media Superior': 2,
            Preescolar: 3,
            Primaria: 4,
            Secundaria: 5,
            Bachillerato: 6,
            Universidad: 7,
        },
        schoolType: {
            Técnica: 2,
            Telesecundaria: 3,
            'Centro de Atención Múltiple': 4,
            'No aplica': 5,
        },
        sustenance: {
            Estatal: 1,
            Federal: 3,
        },
    };

    const mapLevelOrSchoolType = (code) => {
        if (rules.level[code]) {
            return { level: valueMap.level[rules.level[code]] };
        }
        if (rules.schoolType[code]) {
            return {
                school_type_id: valueMap.schoolType[rules.schoolType[code]],
            };
        }
        return {};
    };

    useEffect(() => {
        const clave = formik.values.clave;
        if (clave.length >= 3 && hasCCTChanged) {
            const typeOrHoldingsChar = clave.charAt(2);
            const levelOrSchoolType = clave.substring(3, 5);

            let updates = {};

            updates.control = rules.control[typeOrHoldingsChar]
                ? valueMap.control[rules.control[typeOrHoldingsChar]]
                : valueMap.control['Pública'];

            if (rules.sustenance[typeOrHoldingsChar]) {
                updates.sustenance =
                    valueMap.sustenance[rules.sustenance[typeOrHoldingsChar]];
            }

            updates = {
                ...updates,
                ...mapLevelOrSchoolType(levelOrSchoolType),
            };

            if (['ST', 'TV'].includes(levelOrSchoolType)) {
                updates.level = valueMap.level['Secundaria'];
            }

            if (updates.control === valueMap.control['Privada']) {
                updates.sustenance = valueMap.sustenance['Estatal'];
            }

            if (
                updates.level === valueMap.level['Primaria'] ||
                updates.level === valueMap.level['Preescolar']
            ) {
                updates.school_type_id = valueMap.schoolType['No aplica'];
            }

            if (Object.keys(updates).length > 0) {
                formik.setValues((prevValues) => ({
                    ...prevValues,
                    ...updates,
                }));
                setHasCCTChanged(false);
            }
        }
    }, [formik.values.clave, hasCCTChanged]);

    const isFormChanged = () => {
        return (
            JSON.stringify(formik.values) !==
            JSON.stringify(formik.initialValues)
        );
    };

    return (
        <Card sx={{ mt: 2 }}>
            <CardHeader title="Información general" />
            <CardContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item container>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}
                            >
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Nombre de la escuela"
                                    type="text"
                                    variant="outlined"
                                    inputProps={{ maxLength: 100 }}
                                    {...formik.getFieldProps('name')}
                                    error={
                                        formik.touched.name &&
                                        Boolean(formik.errors.name)
                                    }
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                            ? formik.errors.name
                                            : `Caracteres disponibles: ${formik.values.name.length}/100`
                                    }
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}
                            >
                                <TextField
                                    id="cellphone"
                                    name="cellphone"
                                    label="Teléfono"
                                    type="tel"
                                    inputProps={{ maxLength: 10 }}
                                    variant="outlined"
                                    value={formik.values.cellphone}
                                    onChange={handleCellphoneChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.cellphone &&
                                        Boolean(formik.errors.cellphone)
                                    }
                                    helperText={
                                        formik.touched.cellphone &&
                                        formik.errors.cellphone
                                            ? formik.errors.cellphone
                                            : `Caracteres disponibles: ${formik.values.cellphone.length}/10`
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}
                            >
                                <TextField
                                    id="clave"
                                    name="clave"
                                    label="Registro SEP"
                                    type="text"
                                    variant="outlined"
                                    {...formik.getFieldProps('clave')}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'clave',
                                            e.target.value.toUpperCase()
                                        );
                                        setHasCCTChanged(true);
                                    }}
                                    inputProps={{ maxLength: 12 }}
                                    error={
                                        formik.touched.clave &&
                                        Boolean(formik.errors.clave)
                                    }
                                    helperText={
                                        formik.touched.clave &&
                                        formik.errors.clave
                                            ? formik.errors.clave
                                            : `Caracteres disponibles: ${formik.values.clave.length}/12`
                                    }
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}
                            >
                                <TextField
                                    labelId="lbl-nivel"
                                    id="level"
                                    name="level"
                                    {...formik.getFieldProps('level')}
                                    label="Nivel educativo"
                                    variant="outlined"
                                    fullWidth
                                    select
                                    error={
                                        formik.touched.level &&
                                        Boolean(formik.errors.level)
                                    }
                                    helperText={
                                        formik.touched.level &&
                                        formik.errors.level
                                            ? formik.errors.level
                                            : false
                                    }
                                    InputLabelProps={{
                                        shrink: formik.values.level !== '',
                                    }}
                                >
                                    <MenuItem value={1}>
                                        Educación Básica
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        Educación Básica y Media Superior
                                    </MenuItem>
                                    <MenuItem value={3}>Preescolar</MenuItem>
                                    <MenuItem value={4}>Primaria</MenuItem>
                                    <MenuItem value={5}>Secundaria</MenuItem>
                                    <MenuItem value={6}>Bachillerato</MenuItem>
                                    <MenuItem value={7}>Universidad</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}
                            >
                                <TextField
                                    labelId="lbl-sector"
                                    id="control"
                                    name="control"
                                    {...formik.getFieldProps('control')}
                                    label="Tipo"
                                    variant="outlined"
                                    fullWidth
                                    select
                                    error={
                                        formik.touched.control &&
                                        Boolean(formik.errors.control)
                                    }
                                    helperText={
                                        formik.touched.control &&
                                        formik.errors.control
                                            ? formik.errors.control
                                            : false
                                    }
                                    InputLabelProps={{
                                        shrink: formik.values.control !== '',
                                    }}
                                >
                                    <MenuItem value={1}>Pública</MenuItem>
                                    <MenuItem value={2}>Privada</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}
                            >
                                <TextField
                                    labelId="lbl-tipo"
                                    id="sustenance"
                                    name="sustenance"
                                    {...formik.getFieldProps('sustenance')}
                                    label="Sostenimiento "
                                    variant="outlined"
                                    fullWidth
                                    select
                                    error={
                                        formik.touched.sustenance &&
                                        Boolean(formik.errors.sustenance)
                                    }
                                    helperText={
                                        formik.touched.sustenance &&
                                        formik.errors.sustenance
                                            ? formik.errors.sustenance
                                            : false
                                    }
                                    InputLabelProps={{
                                        shrink: formik.values.sustenance !== '',
                                    }}
                                >
                                    <MenuItem value={1}>Estatal</MenuItem>
                                    <MenuItem value={2}>Municipal</MenuItem>
                                    <MenuItem value={3}>Federal</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}
                            >
                                <TextField
                                    labelId="lbl-tipo"
                                    id="school_type_id"
                                    name="school_type_id"
                                    {...formik.getFieldProps('school_type_id')}
                                    label="Tipo de escuela"
                                    variant="outlined"
                                    fullWidth
                                    select
                                    error={
                                        formik.touched.school_type_id &&
                                        Boolean(formik.errors.school_type_id)
                                    }
                                    helperText={
                                        formik.touched.school_type_id &&
                                        formik.errors.school_type_id
                                            ? formik.errors.school_type_id
                                            : false
                                    }
                                    InputLabelProps={{
                                        shrink:
                                            formik.values.school_type_id !== '',
                                    }}
                                >
                                    <MenuItem value={1}>
                                        General o Estatal
                                    </MenuItem>
                                    <MenuItem value={2}>Técnica</MenuItem>
                                    <MenuItem value={3}>
                                        Telesecundaria
                                    </MenuItem>
                                    <MenuItem value={4}>
                                        Centro de atención múltiple
                                    </MenuItem>
                                    <MenuItem value={5}>No aplica</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}
                            >
                                <TextField
                                    labelId="lbl-tipo"
                                    id="geographical_scope"
                                    name="geographical_scope"
                                    {...formik.getFieldProps(
                                        'geographical_scope'
                                    )}
                                    label="Ámbito"
                                    variant="outlined"
                                    fullWidth
                                    select
                                    error={
                                        formik.touched.geographical_scope &&
                                        Boolean(
                                            formik.errors.geographical_scope
                                        )
                                    }
                                    helperText={
                                        formik.touched.geographical_scope &&
                                        formik.errors.geographical_scope
                                            ? formik.errors.geographical_scope
                                            : false
                                    }
                                    InputLabelProps={{
                                        shrink:
                                            formik.values.geographical_scope !==
                                            '',
                                    }}
                                >
                                    <MenuItem value={1}>Urbano</MenuItem>
                                    <MenuItem value={2}>Rural</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterLuxon}
                                    localeText={
                                        esES.components.MuiLocalizationProvider
                                            .defaultProps.localeText
                                    }
                                    adapterLocale="es-MX"
                                >
                                    <DatePicker
                                        label="Fecha de fundación"
                                        slotProps={{
                                            textField: { fullWidth: true },
                                        }}
                                        value={
                                            formik.values.foundation_date
                                                ? DateTime.fromISO(
                                                      formik.values
                                                          .foundation_date
                                                  )
                                                : null
                                        }
                                        maxDate={DateTime.now()}
                                        onChange={(date) => {
                                            const formattedDate = date
                                                ? date.toISODate()
                                                : null;
                                            formik.setFieldValue(
                                                'foundation_date',
                                                formattedDate
                                            );
                                        }}
                                    />
                                    {formik.errors.foundation_date && (
                                        <Grid item>
                                            <Typography
                                                variant="caption"
                                                color="error"
                                            >
                                                {formik.errors.foundation_date}
                                            </Typography>
                                        </Grid>
                                    )}
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}
                            >
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="maximum_student_capacity"
                                    name="maximum_student_capacity"
                                    label="Capacidad máxima"
                                    type="number"
                                    variant="outlined"
                                    {...formik.getFieldProps(
                                        'maximum_student_capacity'
                                    )}
                                    error={
                                        formik.touched
                                            .maximum_student_capacity &&
                                        Boolean(
                                            formik.errors
                                                .maximum_student_capacity
                                        )
                                    }
                                    inputProps={{ maxLength: 4 }}
                                    helperText={
                                        formik.touched
                                            .maximum_student_capacity &&
                                        formik.errors.maximum_student_capacity
                                            ? formik.errors
                                                  .maximum_student_capacity
                                            : `Caracteres disponibles: ${
                                                  String(
                                                      formik.values
                                                          .maximum_student_capacity
                                                  ).length
                                              }/4`
                                    }
                                    onChange={(event) => {
                                        formik.handleChange(event);
                                    }}
                                    onInput={(e) => {
                                        e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                        )
                                            .toString()
                                            .slice(0, 4);
                                    }}
                                    min={0}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}
                            >
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="reserved_students_capacity"
                                    name="reserved_students_capacity"
                                    label="Capacidad reservada"
                                    type="number"
                                    variant="outlined"
                                    inputProps={{ maxLength: 5 }}
                                    {...formik.getFieldProps(
                                        'reserved_students_capacity'
                                    )}
                                    error={
                                        formik.touched
                                            .reserved_students_capacity &&
                                        Boolean(
                                            formik.errors
                                                .reserved_students_capacity
                                        )
                                    }
                                    helperText={
                                        formik.touched
                                            .reserved_students_capacity &&
                                        formik.errors.reserved_students_capacity
                                            ? formik.errors
                                                  .reserved_students_capacity
                                            : `Caracteres disponibles: ${
                                                  String(
                                                      formik.values
                                                          .reserved_students_capacity
                                                  ).length
                                              }/4`
                                    }
                                    onChange={(event) => {
                                        formik.handleChange(event);
                                    }}
                                    onInput={(e) => {
                                        e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                        )
                                            .toString()
                                            .slice(0, 4);
                                    }}
                                    min={0}
                                />
                            </Grid>
                            <Grid
                                item
                                xl={4}
                                sm={4}
                                md={4}
                                xs={12}
                                style={{ padding: 10 }}
                            >
                                <TextField
                                    sx={{ width: '100%' }}
                                    id="oci"
                                    name="oci"
                                    label="Sede OCI"
                                    type="text"
                                    variant="outlined"
                                    {...formik.getFieldProps('oci')}
                                    error={
                                        formik.touched.oci &&
                                        Boolean(formik.errors.oci)
                                    }
                                    inputProps={{ maxLength: 100 }}
                                    helperText={
                                        formik.touched.oci && formik.errors.oci
                                            ? formik.errors.oci
                                            : `Caracteres disponibles: ${formik.values.oci.length}/100`
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xl={4}
                        sm={4}
                        md={4}
                        xs={12}
                        style={{ padding: 10 }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <CardActions>
                            <LoadingButton
                                size="small"
                                color="primary"
                                type="submit"
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                disabled={!isFormChanged() || !formik.isValid}
                                loading={generalInfoLoading}
                            >
                                Guardar
                            </LoadingButton>
                        </CardActions>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    );
};

export default SchoolGeneralInfoCard;
