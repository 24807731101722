import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';

const notesAdapter = createEntityAdapter({
    selectId: (note) => note.note_id,
    sortComparer: (a, b) => a.note_id - b.note_id,
});

export const notesSlice = createSlice({
    name: 'notes',
    initialState: notesAdapter.getInitialState(),
    reducers: {
        addOneNote: notesAdapter.addOne,
        addManyNotes: notesAdapter.addMany,
        upsertManyNotes: notesAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return notesAdapter.getInitialState();
        });
    },
});

export const { addOneNote, addManyNotes, upsertManyNotes } = notesSlice.actions;

export default notesSlice.reducer;

////////////////////// SELECTORES /////////////////////

const globalizedSelectors = notesAdapter.getSelectors(
    (state) => state.entities.notes
);

/**
 * Selector para recuperar todos las notas de la coleccion
 *
 * @param {*} state
 * @returns
 */
export const selectEntitiesNotes = (state) =>
    globalizedSelectors.selectEntities(state);

/**
 * Selector para recuperar todos las notas
 * @param {*} state
 * @returns
 */
export const selectAllNotes = (state) => globalizedSelectors.selectAll(state);

export const selectNotesByFileId = (fileId) =>
    createSelector(selectAllNotes, (allNotes) => {
        return allNotes.filter((note) => note.file_id === fileId);
    });
