import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import { Stack } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../hooks";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import TitlePage from "./TitlePage";
import * as CONSTANTS from "../../service/const";
import UserMenu from "./UserMenu";
import { selectAllSettings, selectOneSettingBySchoolId, selectSettingBySchoolId } from "../../store/slices/entities/settings";
import DrawerItemsList from "./DrawerItemsList";
import DrawerHeader from "./DrawerHeader";
import AppDetails from "./AppDetails";
import FooterDashboard from "./FooterDashboard";

/**
 * Componente que contiene todo el dashboard
 * 
 * @param {*} param0 
 * @returns 
 */
const Layout = ({ children }) => {
    const Auth = useAuth();
    const location = useLocation();
    const theme = useTheme();

    const schoolId = Auth.getUser().school_id

    ////////////////// LOCAL STATE  //////////////////

    /////////////////// SHARES STATE /////////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let menuBarSelected = config.find(
        (res) => res.key === 'theme-color-menu-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    //////////////////// ACTIONS //////////////////////

    const [open, setOpen] = useState(false);

    const drawerWidth = 190;

    /**
     * Genera un objecto para un thema
     * cuando el drawer esta abierto
     * 
     * @param {*} theme 
     * @returns 
     */
    const openedMixin = (theme) => ({
        width: drawerWidth,
        backgroundColor: menuBarSelected?.value,
        color: '#fffff',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,

        }),
        overflowX: "hidden",
    });

    /**
     * Genera un objecto para un tema 
     * cuando el drawer esta cerrado
     * 
     * @param {*} theme 
     * @returns 
     */
    const closedMixin = (theme) => ({
        width: `calc(${theme.spacing(7)} + 1px)`,
        backgroundColor: menuBarSelected?.value,
        color: '#fff',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        [theme.breakpoints.up("sm")]: {
            width: `calc(${theme.spacing(7)} + 2px)`,
        },
    });

    /**
     * Componente Drawer estilizado 
     * 
     */
    const Drawer = styled(MuiDrawer, {
        shouldForwardProp: (prop) => prop !== "open",
    })(({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
        }),
    }));

    /**
     * Componente AppBar estilizado
     */
    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== "open",
    })(({ theme, open }) => ({
        backgroundColor: `${titleBarBackground?.value} !important`,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            //marginLeft: drawerWidth,
            width: `calc(100%)`,
            // width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div
            style={{
                display: "flex",
            }}
        >
            <CssBaseline />

            <Drawer variant="permanent" open={open}>
                <DrawerHeader />
                <Divider />
                <DrawerItemsList />
                {
                    open && (
                        <AppDetails />
                    )
                }
            </Drawer>
            <Box
                component={"main"}
                sx={{
                    background: "#f9f9f9",
                    width: "100%",
                    flexGrow: 1,
                }}
            >
                <AppBar position="sticky" open={open} sx={{ backgroundColor: titleBarBackground?.value }}>

                    <Toolbar variant="dense">
                        <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }} alignItems="center">
                            <Stack spacing={3} direction="row" alignItems="center" justifyContent="space-between">
                                <Box>
                                    <IconButton
                                        style={{ color: fontColor?.value }}
                                        aria-label="menu"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        sx={{
                                            ...(open && { display: "none" }),
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>

                                    <IconButton onClick={handleDrawerClose} style={{ color: fontColor?.value }}
                                        sx={{
                                            ...(!open && { display: "none" }),
                                        }}
                                    >
                                        {theme.direction === "rtl" ? (
                                            <ArrowBackSharpIcon />
                                        ) : (
                                            <ArrowBackSharpIcon />
                                        )}
                                    </IconButton>
                                </Box>
                                <Box>
                                    <img className="avatar-icon" style={{ width: "70px" }} src={`${process.env.PUBLIC_URL}/images/logo-brand.png`} alt="Avatar" />
                                </Box>
                                <TitlePage color={fontColor?.value} location={`/${location.pathname.split("/")[1]}`} />
                            </Stack>

                            <UserMenu fontColor={fontColor?.value} ></UserMenu>
                        </Stack>
                    </Toolbar>
                </AppBar>
                {children}

                <FooterDashboard />
            </Box>
        </div>
    );
};

export default Layout;
