import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import Connection from "../../../../service/Connection";
import Feedback from "../../../../service/Feedback";
import { selectAllStudents, upsertManyStudents } from "../entities/studentsSlice";
import { addManyRelationships, selectAllRelationships } from "../entities/relationshipsSlice";
import { selectAllUsers, upsertManyUsers } from "../entities/usersSlice";
import Authentication from "../../../../service/Login";

const emptyState = {
    steps: {
        students: {
            all: false,
            selected: [],
            operationStatus: "idle",
            didInvalidate: true
        },
        parents: {
            all: false,
            selected: [],
            operationStatus: "idle",
            didInvalidate: true
        }
    },
    currentStep: 0,
    completedSteps: {}
}

/**
 * Reductor para los modos de ditribuccion
 */
export const distributinModesSlice = createSlice({
    name: "noticesModalUI/distributinModes/students",
    initialState: emptyState,
    reducers: {
         /**
         * Ir hacia adelante en el strapper
         * 
         * @param store 
         * @param payload Paso que ha sido completado
         */
          nextStep: (store, {payload}) => {
            const currentStep = store.currentStep
            const nextStep = store.currentStep + 1

            store.currentStep = nextStep
            store.completedSteps = {...store.completedSteps, [currentStep]: payload}
        },
        /**
         * Ir hacia atras en el strapper
         * 
         * @param store 
         * @param payload Paso que ha sido completado
         */
         previusStep: (store, {payload}) => {
            const stepsCompleted = Object.keys(store.completedSteps)
        
            const nextStep = stepsCompleted.length - 1
    
            let currentCompleted = {...store.completedSteps}
    
            delete currentCompleted[parseInt(stepsCompleted[nextStep])]
    
            store.currentStep = parseInt(stepsCompleted[nextStep])
            store.completedSteps = currentCompleted
        },
        /**
         *  Ir hacia al final de los pasoso
         * 
         * @param store 
         * @param payload Paso que ha sido completado
         */
        finalStep: (state, {payload}) => {
            const currentStep = state.currentStep
            const nextStep = 2

            state.currentStep = nextStep
            state.completedSteps = {...state.completedSteps, [currentStep]: payload}

            if (currentStep == 0) {
                state.steps.parents = {
                    ...state.steps.parents,
                    didInvalidate: true,
                    all: false,
                    selected: [],
                }
            }
        },
        /**
         * Cambiaron a los alumnos
         * 
         * @param {*} store 
         * @param {*} param1 
         */
        changeStudentsSelected: (store, {payload}) => {
            store.steps.students.selected = payload
        },
        /**
         * Todos los alumnos fueron seleccionados
         * 
         * @param {*} store 
         * @param {*} param1 
         */
        changeSelectAllStudents: (store, {payload}) => {
            store.steps.students.all = payload
        },
         /**
         * Cambiaron a los padres
         * 
         * @param {*} store 
         * @param {*} param1 
         */
        changeParentsSelected: (store, {payload}) => {
            store.steps.parents.selected = payload
        },
        /**
         * Todos los padres fueron seleccionados
         * 
         * @param {*} store 
         * @param {*} param1 
         */
        changeSelectAllParents: (store, {payload}) => {
            store.steps.parents.all = payload
        },
        /**
         * Invalidar los datos que esta disponibles
         * 
         * @param {*} store 
         * 
         * @param {*} param1 
         */
        invalidateStepData: (store, {payload}) => {
            store.steps[payload].didInvalidate = true
            store.steps[payload].all = false
            store.steps[payload].selected = []
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
                    
            return emptyState
        })

        /** 
         * Reinicia el proceso de seleccion de
         * los modos de ditribucion
         * 
         * @param {*} state 
         * @param {*} action 
         * 
         * @returns 
         */
         builder.addCase('noticesModalUI/distributinModes/reset', (state, action) => {
            state.steps.students = {
                ...state.steps.students,
                didInvalidate: true,
                all: false,
                selected: [],
            }

            state.steps.parents = {
                ...state.steps.parents,
                didInvalidate: true,
                all: false,
                selected: [],
            }
    
            state.currentStep = 0
            state.completedSteps = {}
        })

        /**
         * Carga de datos del paso de grupos
         */
        builder.addCase(loadStudentsStepData.fulfilled, (state, action) => {
            state.steps.students.didInvalidate = false
            state.steps.students.operationStatus = 'fulfilled'
        })
        builder.addCase(loadStudentsStepData.pending, (state, action) => {
            state.steps.students.operationStatus = 'pending'
        })
        builder.addCase(loadStudentsStepData.rejected, (state, action) => {
            state.steps.students.operationStatus = 'rejected'
        })

        /**
         * Carga de datos del paso de padres
         */
        builder.addCase(loadParentsStepData.fulfilled, (state, action) => {
            state.steps.parents.didInvalidate = false
            state.steps.parents.operationStatus = 'fulfilled'
        })
        builder.addCase(loadParentsStepData.pending, (state, action) => {
            state.steps.parents.operationStatus = 'pending'
        })
        builder.addCase(loadParentsStepData.rejected, (state, action) => {
            state.steps.parents.operationStatus = 'rejected'
        })
        
    }
})

export const  {
     // STRAPER
     nextStep, previusStep, finalStep,
     /// Alumnos
     changeStudentsSelected, changeSelectAllStudents,
     // Padres
     changeParentsSelected, changeSelectAllParents,
     // compartidos
     invalidateStepData
} = distributinModesSlice.actions



//////////////////// SELECTORES //////////////////

/**
 * Consulta para recuperar el paso actual
 * 
 *      0.- Alumnos
 *      1.- Padres
 *      2.- Finalizar
 * 
 * @param {*} state 
 * 
 * @returns 
 */
 export const selectCurrentStep = (state) => state.noticesModalUI.distributinModes.students.currentStep;

 /**
 * Consulta para recuperar los pasos que fueron completados
 * 
 * @param {*} state 
 * @returns 
 */
export const selectCompletedSteps = (state) => state.noticesModalUI.distributinModes.students.completedSteps;


//////////////////////////// ALUMNOS //////////////////////////////////

/**
 * Consulta para recuperar los alumnos que estan selecinados en el step
 * 
 * @param {*} state 
 * @returns 
 */
 export const selectStudentSelected = (state) => state.noticesModalUI.distributinModes.students.steps.students.selected;

/**
 * Consulta para saber si todos los elementos de grupos estan seleccionados
 * 
 * @param {*} state 
 * @returns 
 */
 export const selectStudentsIsAllSelected = (state) => state.noticesModalUI.distributinModes.students.steps.students.all;


/**
 * Consulta para recuperar los alumnos que estan selecinados
 * 
 * @param {*} state 
 * @returns 
 */
//export const selectStudentSelected = (state) => state.noticesModalUI.distributinModes.groups.steps.students.selected;

/**
 * Consulta para saber si todos los elementos de alumnos estan seleccionados
 * 
 * @param {*} state 
 * @returns 
 */
 //export const selectStudentIsAllSelected = (state) => state.noticesModalUI.distributinModes.groups.steps.students.all;

/**
 * Consulta para recuperar el estado de operacion del paso grupos
 * 
 * @param {*} state 
 * @returns 
 */
export const selectOperationStatusStudentsStep = (state) => state.noticesModalUI.distributinModes.students.steps.students.operationStatus;

////////////////////////// PADRES //////////////////////////////


/**
 * Consulta para recuperar los elementos 
 * del paso de padres
 * 
 * @param {*} state 
 * @returns 
 */
 export const selectParentItemsbyStudentSelected = createSelector([
    selectAllStudents, selectAllUsers, selectAllRelationships, selectStudentSelected
], (students, users, relationships, studentsSelected) => {

    let studentsWithGroupList = []

    for (const studentId of studentsSelected) {
        let studentItem = students.find(i => i.student_id == studentId)
        let relationshipsItems = relationships.filter(i => i.student_id == studentId)

        let parentsItem = relationshipsItems.map(gs => users.find(p => p.user_id == gs.user_id))

        const formated = parentsItem.map(s => ({ student: studentItem, parent: s}))

        studentsWithGroupList = studentsWithGroupList.concat(formated)
    }

    return _.flatten(studentsWithGroupList)
})

/**
 * Consulta para recuperar informacion de los padres de los alumnos selecionados
 * 
 * @param {*} state 
 * @returns 
 */
 export const selectParentsDataByStudentsSelected = createSelector([
    selectAllStudents, selectAllUsers, selectAllRelationships, selectStudentSelected
], (students, users, relationships, studentsSelected) => {

    let studentsSelectedSource = _.uniq(_.flatten(studentsSelected.map(i => i.students)))
    
    let parentsWithStudentsList = []

    for (const studentId of studentsSelectedSource) {
        let studentItem = students.find(i => i.student_id == studentId)
        let relationshipsItems = relationships.filter(i => i.student_id == studentId)

        let parentsItem = relationshipsItems.map(gs => users.find(p => p.user_id == gs.user_id))

        const formated = parentsItem.map(s => ({ student: studentItem, parent: s}))

        parentsWithStudentsList = parentsWithStudentsList.concat(formated)
    }

    return _.flatten(parentsWithStudentsList)
})


/**
 * Consulta para recuperar los padres que estan selecinados
 * 
 * @param {*} state 
 * @returns 
 */
 export const selectParentsSelected = (state) => state.noticesModalUI.distributinModes.students.steps.parents.selected;


/**
 * Consulta para recuperar el estado de operacion del paso grupos
 * 
 * @param {*} state 
 * @returns 
 */
 export const selectOperationStatusParentsStep = (state) => state.noticesModalUI.distributinModes.students.steps.parents.operationStatus;


 /**
 * Consulta para saber si todos los elementos de alumnos estan seleccionados
 * 
 * @param {*} state 
 * @returns 
 */
export const selectParentsIsAllSelected = (state) => state.noticesModalUI.distributinModes.students.steps.parents.all;



///////////////////////////////// ENVIAR /////////////////////////////

/**
 * Consulta para recuperar informacion de los padres de los alumnos selecionados
 * 
 * @param {*} state 
 * @returns 
 */
 export const selectPreviewDataTree = createSelector([
    selectStudentSelected, selectParentsSelected,
    selectAllUsers, selectAllStudents
], (studentsSelected, parentsSelected, parents, students) => {

    let tree = studentsSelected.map(studentId => {
        let student = students.find(i => i.student_id == studentId)

        let parentsItems = _.flatten(parentsSelected.filter(parentItem => parentItem.student_id == studentId).map(s => s.parents))
        .map(parentId => parents.find(i => i.user_id == parentId))

        return {
            ...student,
            parents: parentsItems
        }
    })

    return tree
})


export default distributinModesSlice.reducer


/////////////////////// TRUNKS /////////////////////////////////

/**
 * Cargamos todos los alumnos
 */
 export const loadStudentsStepData = createAsyncThunk(
    'noticesModalUI/distributinModes/students/step/students',
    async(schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let students = await Connection.getStudentsHasRelationships(schoolId).then(i => i.data.data)

            thunkAPI.dispatch(upsertManyStudents(students))
            
            return students
        }catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    },{
        condition: (arg, { getState, extra } ) => {
            let {didInvalidate} = getState().noticesModalUI.distributinModes.students.steps.students
            
            if (!didInvalidate) {
                return false
            }

        }
    }
)

/**
 * Cargamos todos los padres de los alumnos
 */
 export const loadParentsStepData = createAsyncThunk(
    'noticesModalUI/distributinModes/students/step/parents',
    async({studentIds, schoolId}, thunkAPI) => {
        let FeedbackService = new Feedback()
        let Auth = new Authentication()

        try {
            // RECUPERAR DATOS DEL SERVIDOR

            let allRelationships = await Connection.getRelationshipsBySchool(schoolId).then(i => i.data.data)
            let allParents = await Connection.getParentsBySchool(schoolId).then(i => i.data.data)

            // OBTENEMOS PARES Y RELACIONES DE LOS ALUMNOS SELECCIONADOS

            let relationships = allRelationships.filter(i => studentIds.find(r => r == i.student_id))
            let parents = allParents.filter(i => relationships.find(r => r.parent_id == i.parent_id))

            // DAMOS FORMATO A LLAVES DE USUARIO

            let customRelations = relationships.map(i => ({...i, user_id: i.parent_id}))
            let users = parents.map(i => ({...i, user_id: Auth.getUserID(i)}))

            thunkAPI.dispatch(addManyRelationships(customRelations))
            thunkAPI.dispatch(upsertManyUsers(users))

            return {
                users,
                customRelations
            }
            
        }catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    },{
        condition: (arg, { getState, extra } ) => {
            let {didInvalidate} = getState().noticesModalUI.distributinModes.students.steps.parents
            
            if (!didInvalidate) {
                return false
            }

        }
    }
)