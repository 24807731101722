import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../../hooks';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import { Error } from '../../../../components/Feedback';
import { selectOneSettingBySchoolId } from '../../../../store/slices/entities/settings';
import {
    invalidateReportNoticed,
    invalidateReportRead,
    invalidateReportSent,
    loadNoticedReportUsersData,
    loadReadReportUsersData,
    loadSentReportUsersData,
    selecReportStatusOperation,
    selectSelectedReportViewModelUsersIds,
    selectUsersByReportsNotifIds,
} from '../../../store/reportDetails';

const UsersReportModal = ({ open, setOpen, viewModel, type }) => {
    //////////////// HOOKS ///////////
    const Auth = useAuth();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    ////////////// SHARED STATE ////////////////
    const schoolId = Auth.getUser().school_id;

    const { usersIds } = useSelector(
        selectSelectedReportViewModelUsersIds(viewModel?.id, type)
    );

    const usersData = useSelector(selectUsersByReportsNotifIds(usersIds));

    const statusOperation = useSelector(selecReportStatusOperation(type));

    const titleBar = useSelector(
        selectOneSettingBySchoolId(schoolId, 'theme-color-title-bar')
    );
    const font = useSelector(
        selectOneSettingBySchoolId(schoolId, 'theme-color-font')
    );

    ////////////// LOCAL STATE /////////////////
    let columns = [
        {
            field: 'name',
            headerName: 'Nombre',
            flex: 2,
            width: 300,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            //headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return `${params.row.last_name || ''} ${
                    params.row.second_last_name || ''
                } ${params.row.name || ''}`;
            },
            renderCell: (params) => (
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Avatar
                        sx={{ width: 24, height: 24 }}
                        src={params.row.url_photo_profile}
                    />
                    <span>{params.formattedValue}</span>
                </Stack>
            ),
        },
        {
            field: 'cellphone',
            headerName: 'Número de Teléfono',
            flex: 2,
            width: 300,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => {
                return value;
            },
        },
    ];

    ////////////////// HANDLERS /////////////////////
    useEffect(() => {
        if (open) {
            if (type === 'sent') {
                dispatch(loadSentReportUsersData({ usersIds }));
            } else if (type === 'noticed') {
                dispatch(loadNoticedReportUsersData({ usersIds }));
            } else {
                dispatch(loadReadReportUsersData({ usersIds }));
            }
        }
    }, [open]);

    const closeModal = () => {
        setOpen(false);
    };

    const reloadSent = () => {
        dispatch(loadNoticedReportUsersData({ usersIds }))
            .unwrap()
            .then(() => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const reloadNoticed = () => {
        dispatch(loadSentReportUsersData({ usersIds }))
            .unwrap()
            .then(() => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const reloadRead = () => {
        dispatch(loadReadReportUsersData({ usersIds }))
            .unwrap()
            .then(() => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const invalidateUI = () => {
        if (type === 'sent') {
            dispatch(invalidateReportSent(viewModel?.id));
            reloadSent();
        } else if (type === 'noticed') {
            dispatch(invalidateReportNoticed(viewModel?.id));
            reloadNoticed();
        } else {
            dispatch(invalidateReportRead(viewModel?.id));
            reloadRead();
        }
    };

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="lg"
            disableEscapeKeyDown={statusOperation === 'pending'}
            onClose={() => {
                closeModal();
            }}
        >
            <DialogTitle
                style={{
                    backgroundColor: titleBar.value,
                    color: font.value,
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ flexGrow: 1 }}
                    >
                        Notificaciones Enviadas
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent>
                {statusOperation === 'pending' && (
                    <Stack spacing={2} sx={{ padding: 2 }}>
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton variant="rectangular" height={30} />
                    </Stack>
                )}

                {statusOperation === 'rejected' && (
                    <Error
                        onRetry={invalidateUI}
                        message={'Estamos teniendo problemas'}
                    />
                )}

                {statusOperation === 'fulfilled' && (
                    <Box
                        sx={{
                            '& .super-app-theme--header': {
                                backgroundColor: titleBar.value,
                                color: font.value,
                            },
                            '& .MuiDataGrid-root': {
                                border: 0,
                            },
                        }}
                    >
                        <Box sx={{ textAlign: 'center' }}>
                            <DataGrid
                                localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                        .localeText
                                }
                                getRowId={(row) => row.user_id}
                                rows={usersData}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                disableSelectionOnClick
                                autoHeight
                                components={{
                                    Toolbar: () => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <GridToolbar />
                                        </div>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={statusOperation === 'pending'}
                    onClick={() => {
                        closeModal();
                    }}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UsersReportModal;
