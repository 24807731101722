import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Box,
    Button,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Step,
    StepContent,
    StepIcon,
    StepLabel,
    Stepper,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, selectStatusOperation } from '../../store/slices/usersUI';
import { selectSettingBySchoolId } from '../../store/slices/entities/settings';
import { useAuth } from '../../hooks';
import Services from '../../service/Connection';

import useFeedback from '../../hooks/useFeedback';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { DatePicker, LocalizationProvider, esES } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { VALIDATIONS } from '../../constants/index';
import { withStyles } from '@material-ui/core';
import generarCurp from '../../helper/CurpGenerator';

export const ModalCreateUser = ({
    title = 'Agregar Usuario',
    openModal,
    setOpenModal,
    tipoUsuario,
    user = null,
}) => {
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpenModal(false);
        setActiveStep(0);
        resetCityInputs();
    };

    //////////// SELECTORES /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(selectStatusOperation);

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    const optionGender = ['Hombre', 'Mujer'];

    const educationLevel = [
        {
            id: 0,
            text: 'No definido',
        },
        {
            id: 1,
            text: 'Preescolar',
        },
        {
            id: 2,
            text: 'Primaria',
        },
        {
            id: 3,
            text: 'Secundaria',
        },
        {
            id: 4,
            text: 'Bachillerato',
        },
        {
            id: 5,
            text: 'Licenciatura/Ingeniería',
        },
        {
            id: 6,
            text: 'Maestría',
        },
        {
            id: 7,
            text: 'Doctorado',
        },
    ];

    const [states, setStates] = useState([]);
    const [stateValue, setStateValue] = useState(null);
    const [municipalities, setMunicipialities] = useState([]);
    const [municipalityValue, setMunicipialityValue] = useState(null);
    const [cities, setCities] = useState([]);
    const [cityValue, setCityValue] = useState(null);

    const [birthStates, setBirthStates] = useState([]);
    const [birthStateValue, setBirthStateValue] = useState(null);
    const [birthMunicipalities, setBirthMunicipialities] = useState([]);
    const [birthMunicipalityValue, setBirthMunicipialityValue] = useState(null);
    const [birthCities, setBirthCities] = useState([]);
    const [birthCityValue, setBirthCityValue] = useState(null);

    const [birthStateSelected, setBirthStateSelected] = useState({ code: '' });

    const [dateError, setDateError] = useState(false);

    const [userData, setUserData] = useState({
        name: '',
        last_name: '',
        second_last_name: '',
        password: '',
        password_confirmation: '',
        enrollment: null,
        email: '',
        cellphone: '',
        street: '',
        colony: '',
        outside_number: '',
        inside_number: '',
        zipcode: '',
        country_code: '12',
        school_id: null,
        level_education: '',
        curp: '',
        rfc: '',
        date_birth: '',
        gender: '',
        city_id: '',
        birth_city_id: '',
        state_id: '',
        municipality_id: '',
        is_immigrant: false,
        is_stranger: false,
        is_indigenous: false,
    });

    /**
     * Funcion para reiniciar los valores del modal
     */
    const resetUserData = () => {
        setUserData({
            name: '',
            last_name: '',
            second_last_name: '',
            password: '',
            password_confirmation: '',
            enrollment: null,
            email: '',
            cellphone: '',
            street: '',
            colony: '',
            outside_number: '',
            inside_number: '',
            zipcode: '',
            country_code: '12',
            level_education: '',
            curp: '',
            rfc: '',
            date_birth: '',
            gender: '',
            city_id: '',
            state_id: '',
            municipality_id: '',
            is_immigrant: false,
            is_stranger: false,
            is_indigenous: false,
        });
    };

    const [activeStep, setActiveStep] = useState(0);

    const steps = [
        {
            label: 'Datos generales',
            description: 'Información personal del usuario',
        },
        {
            label: 'Domicilio',
            description: 'Información detallada de la residencia',
        },
        {
            label: 'Credenciales',
            description: 'Credenciales de ingreso',
        },
    ];

    const GreyBlueSwitch = withStyles((theme) => ({
        switchBase: {
            color: theme.palette.grey[500], // Color inicial del Switch (gris)
            '&$checked': {
                color: theme.palette.primary.main, // Color al activar el Switch (azul)
            },
            '&$checked + $track': {
                backgroundColor: theme.palette.primary.main, // Color del fondo al activar el Switch
            },
        },
        checked: {},
        track: {},
    }))(Switch);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    /**
     * Guardar datos del usuario
     */
    const enviarDatos = () => {
        dispatch(
            createUser({
                type: tipoUsuario,
                schoolId: schoolId,
                userData,
            })
        )
            .unwrap()
            .then((response) => {
                feedbackApp.showFeedback({
                    title: 'Usuario creado',
                });
                resetUserData();
                resetCityInputs();
                setActiveStep(0);
                setOpenModal(false);
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    useEffect(() => {
        const formatData = async () => {
            if (!openModal) {
                resetUserData();
            } else {
                setUserData({ ...userData, ...user });
            }
        };

        formatData();
    }, [openModal]);

    useEffect(() => {
        const getAllStates = async () => {
            let states = await Services.getStates();
            setStates(states.data.data);
        };
        getAllStates();
    }, [openModal]);

    const getMunicipalities = async (value) => {
        setStateValue(value);
        setMunicipialityValue(null);
        setCityValue(null);
        setMunicipialities([]);
        setCities([]);
        if (value !== null) {
            let municipality = await Services.getMunicipalitysByStateId(
                value.state_id
            );
            setMunicipialities(municipality.data.data);
        }
    };

    const getCities = async (value) => {
        setMunicipialityValue(value);
        setCityValue(null);
        setCities([]);
        if (value !== null) {
            let city = await Services.getCityByMunicipalityId(
                value.municipality_id
            );
            setCities(city.data.data);
        }
    };

    const saveCity = (value) => {
        if (value !== null) {
            setCityValue(value);
            userData.city_id = value.city_id;
        }
    };

    useEffect(() => {
        const getBirthStates = async () => {
            let addressstate = await Services.getStates();
            setBirthStates(addressstate.data.data);
        };
        getBirthStates();
    }, [openModal]);

    const getBirthMuncipalities = async (value) => {
        setBirthMunicipialityValue(null);
        setBirthCityValue(null);
        setBirthStateSelected(value);
        setBirthStateValue(value);
        setBirthMunicipialities([]);
        setBirthCities([]);
        if (value !== null) {
            let addressmunicipality = await Services.getMunicipalitysByStateId(
                value.state_id
            );
            setBirthMunicipialities(addressmunicipality.data.data);
        }
    };

    const getBirthCities = async (value) => {
        setBirthMunicipialityValue(value);
        setBirthCityValue(null);
        setBirthCities([]);
        if (value !== null) {
            let addresscity = await Services.getCityByMunicipalityId(
                value.municipality_id
            );
            setBirthCities(addresscity.data.data);
        }
    };

    const saveBirthCity = (value) => {
        if (value !== null) {
            setBirthCityValue(value);
            userData.birth_city_id = value.city_id;
        }
    };

    const resetCityInputs = () => {
        setStates([]);
        setBirthStates([]);
        setMunicipialities([]);
        setBirthMunicipialities([]);
        setCities([]);
        setBirthCities([]);
        setStateValue(null);
        setBirthStateValue(null);
        setMunicipialityValue(null);
        setBirthMunicipialityValue(null);
        setCityValue(null);
        setBirthCityValue(null);
    };

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     *
     * @param {*} event
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        let altervalues = handleInputs(name, value);
        setUserData(altervalues);

        //setSchoolData({ ...schoolsData, [name]: value });
    };

    const handleInputs = (name, value) => {
        let updatedUserData = { ...userData, [name]: value };

        switch (name) {
            case 'cellphone':
            case 'zipcode':
                const numericValue = value.replace(/\D/g, '');
                updatedUserData[name] = numericValue;
                break;
            default:
                break;
        }

        return updatedUserData;
    };

    const curpInputEvent = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        setUserData({ ...userData, [name]: value, rfc: value.slice(0, 10) });
    };

    ////////////////////////// VALIDACIONES //////////////////////////////////

    const nameValid = () => {
        return {
            invalid:
                userData.name === '' ||
                !VALIDATIONS.ALPHA_NUMERIC_SPACES.test(userData.name),
            message: 'Campo requerido, Máximo 50 caracteres',
        };
    };

    const lastNameValid = () => {
        return {
            invalid:
                userData.last_name === '' ||
                !VALIDATIONS.ALPHA_NUMERIC_SPACES.test(userData.last_name),
            message: 'Campo requerido o incorrecto',
        };
    };

    const secondLastNameValid = () => {
        return {
            invalid:
                userData.second_last_name !== '' &&
                !VALIDATIONS.ALPHA_NUMERIC_SPACES.test(
                    userData.second_last_name
                ),
            message: 'Campo requerido o incorrecto',
        };
    };

    const curpValid = () => {
        const isAnySwitchActive =
            userData.is_indigenous ||
            userData.is_immigrant ||
            userData.is_stranger;

        if (isAnySwitchActive) {
            const requiredFieldsFilled =
                userData.name &&
                userData.last_name &&
                userData.second_last_name &&
                userData.date_birth &&
                userData.gender &&
                birthStateSelected &&
                birthStateSelected.code;

            if (requiredFieldsFilled && userData.curp !== '') {
                const curpGenerada = generarCurp(
                    userData.name || '',
                    userData.last_name || '',
                    userData.second_last_name || '',
                    userData.date_birth || '',
                    userData.gender || '',
                    birthStateSelected.code || ''
                );

                const isCurpValid = curpGenerada === userData.curp;

                return {
                    invalid: !isCurpValid,
                    message: 'La CURP proporcionada no es correcta',
                };
            } else {
                return {
                    invalid: false,
                    message: 'Campo requerido o incorrecto',
                };
            }
        } else {
            if (
                userData.name === '' ||
                userData.last_name === '' ||
                userData.second_last_name === '' ||
                userData.date_birth === null ||
                userData.gender === '' ||
                !birthStateSelected ||
                !birthStateSelected.code
            ) {
                return {
                    invalid: true,
                    message: 'La CURP proporcionada no es correcta',
                };
            }

            const curpGenerada = generarCurp(
                userData.name,
                userData.last_name,
                userData.second_last_name,
                userData.date_birth,
                userData.gender,
                birthStateSelected.code,
                userData.curp.substring(16, 17)
            );

            const isCurpValid = curpGenerada === userData.curp;

            return {
                invalid: !isCurpValid,
                message: isCurpValid
                    ? ''
                    : 'La CURP proporcionada no es correcta',
            };
        }
    };

    const enrollmentValid = () => {
        if (tipoUsuario === 'PARENT') {
            return { invalid: false, message: '' };
        }

        const enrollment =
            userData.enrollment === null ? '' : userData.enrollment.trim();

        return {
            invalid: enrollment === '' || !VALIDATIONS.FOLIO.test(enrollment),
            message: 'Campo requerido o incorrecto',
        };
    };

    const cellphoneValid = () => {
        const isPhone = /^[0-9]{10}$/.test(userData.cellphone);

        return {
            invalid: !isPhone,
            message: 'Campo requerido o incorrecto',
        };
    };

    /**
     * Validacion para el correo
     *
     * @returns
     */
    const emailValid = () => {
        var mailformat =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+$/;
        const isEmail = mailformat.test(userData.email);

        return {
            invalid:
                !isEmail || userData.email === '' || userData.email === null,
            message: 'Campo requerido o incorrecto',
        };
    };

    const rfcValid = () => {
        // var rfcFormat =
        //   /^([A-ZÑ&]{3,4}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1-2]\d|3[0-1])[A-Z\d]{0,3})?$/;
        // const isRFC = rfcFormat.test(userData.rfc);
        const isAnySwitchActive =
            userData.is_indigenous ||
            userData.is_immigrant ||
            userData.is_stranger;

        if (isAnySwitchActive) {
            return {
                invalid: false, // Si hay algún switch activo, el RFC no es obligatorio
                message: 'Campo opcional',
            };
        }

        return {
            invalid:
                tipoUsuario !== 'PARENT' &&
                (userData.rfc === '' ||
                    userData.rfc === null ||
                    userData.rfc.length < 10 ||
                    userData.rfc.length > 13),
            message: 'Campo requerido o incorrecto',
        };
    };

    const streetValid = () => {
        return {
            invalid:
                userData.street === '' ||
                !VALIDATIONS.ALPHA_NUMERIC_SPACES.test(userData.street),
            message: 'Campo requerido o incorrecto',
        };
    };
    const colonyValid = () => {
        return {
            invalid:
                userData.colony === '' ||
                !VALIDATIONS.ALPHA_NUMERIC_SPACES.test(userData.colony),
            message: 'Campo requerido o incorrecto',
        };
    };
    const outsideNumberValid = () => {
        return {
            invalid: !VALIDATIONS.ALPHA_NUMERIC_SPACES.test(
                userData.outside_number
            ),
            message: 'Campo requerido o incorrecto',
        };
    };
    const insideNumberValid = () => {
        return {
            invalid: false, //userData.inside_number == '',
            message: 'Campo requerido o incorrecto',
        };
    };
    const zipcodeValid = () => {
        return {
            invalid:
                userData.zipcode === '' ||
                !VALIDATIONS.INTEGER.test(userData.zipcode),
            message: 'Campo requerido o incorrecto',
        };
    };
    const countryCodeValid = () => {
        return {
            invalid: userData.country_code === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const genderValid = () => {
        return {
            invalid: userData.gender === '' || userData.gender === null,
            message: 'Campo requerido o incorrecto',
        };
    };

    const cityValid = () => {
        return {
            invalid: !cityValue || cityValue === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const statesValid = () => {
        return {
            invalid: !stateValue || stateValue === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const municipalityValid = () => {
        return {
            invalid: !municipalityValue || municipalityValue === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const birthCityValid = () => {
        return {
            invalid: !birthCityValue || birthCityValue === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const birthStateValid = () => {
        return {
            invalid: !birthStateValue || birthStateValue === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const birthMunicipalityValid = () => {
        return {
            invalid: !birthMunicipalityValue || birthMunicipalityValue === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const educationLevelValid = () => {
        return {
            invalid:
                userData.level_education === '' ||
                userData.level_education === null,
            message: 'Campo requerido o incorrecto',
        };
    };

    const birthDateValid = () => {
        return {
            invalid:
                userData.date_birth === '' ||
                userData.date_birth === null ||
                dateError,
            message: 'Campo requerido o incorrecto',
        };
    };

    const passwordValid = () => {
        return {
            invalid:
                !userData.hasOwnProperty('user_id') &&
                (userData.password === '' || userData.password.length < 8),
            message:
                'Campo requerido, La contraseña debe tener una longitud de 8 caracteres',
        };
    };

    const passwordConfirmationValid = () => {
        let isInvalid =
            userData.password_confirmation === '' ||
            userData.password !== userData.password_confirmation ||
            userData.password_confirmation.length < 8;

        return {
            invalid: isInvalid,
            message:
                'Campo requerido, La contraseña debe tener una longitud de 8 caracteres',
        };
    };

    /**
     * Validacion global para el formulario
     *
     * @returns
     */
    const formInvalid = () => {
        return (
            nameValid().invalid ||
            lastNameValid().invalid ||
            secondLastNameValid().invalid ||
            enrollmentValid().invalid ||
            emailValid().invalid ||
            streetValid().invalid ||
            colonyValid().invalid ||
            outsideNumberValid().invalid ||
            insideNumberValid().invalid ||
            zipcodeValid().invalid ||
            countryCodeValid().invalid ||
            passwordValid().invalid ||
            cellphoneValid().invalid
        );
    };

    const StepOneisInvalid = () => {
        return (
            nameValid().invalid ||
            lastNameValid().invalid ||
            secondLastNameValid().invalid ||
            enrollmentValid().invalid ||
            curpValid().invalid ||
            rfcValid().invalid ||
            emailValid().invalid ||
            educationLevelValid().invalid ||
            genderValid().invalid ||
            birthDateValid().invalid ||
            birthStateValid().invalid ||
            birthMunicipalityValid().invalid ||
            birthCityValid().invalid
        );
    };

    const StepTwoisInvalid = () => {
        return (
            streetValid().invalid ||
            colonyValid().invalid ||
            outsideNumberValid().invalid ||
            insideNumberValid().invalid ||
            cityValid().invalid ||
            zipcodeValid().invalid
        );
    };

    const StepThreeisInvalid = () => {
        return (
            passwordValid().invalid ||
            passwordConfirmationValid().invalid ||
            cellphoneValid().invalid
        );
    };

    const isStepInvalid = (step) => {
        switch (step) {
            case 0:
                return StepOneisInvalid();
            case 1:
                return StepTwoisInvalid();
            case 2:
                return StepThreeisInvalid();
            default:
                return false;
        }
    };

    const renderForm = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="name"
                                name="name"
                                label="Nombre"
                                type="text"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 50,
                                    autocomplete: 'turnoff',
                                }}
                                InputLabelProps={{ shrink: userData.name }}
                                fullWidth
                                value={userData.name}
                                onChange={onInputChange}
                                error={nameValid().invalid}
                                helperText={
                                    nameValid().invalid
                                        ? nameValid().message
                                        : `Caracteres disponibles: ${
                                              userData?.name
                                                  ? userData.name.length
                                                  : 0
                                          }/50`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="last_name"
                                name="last_name"
                                label="Apellido paterno"
                                type="text"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 50,
                                    autocomplete: 'turnoff',
                                }}
                                InputLabelProps={{ shrink: userData.last_name }}
                                fullWidth
                                value={userData.last_name}
                                onChange={onInputChange}
                                error={lastNameValid().invalid}
                                helperText={
                                    lastNameValid().invalid
                                        ? lastNameValid().message
                                        : `Caracteres disponibles: ${
                                              userData?.last_name
                                                  ? userData.last_name.length
                                                  : 0
                                          }/50`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="second_last_name"
                                name="second_last_name"
                                label="Apellido materno"
                                type="text"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 50,
                                    autocomplete: 'turnoff',
                                }}
                                InputLabelProps={{
                                    shrink: userData.second_last_name,
                                }}
                                fullWidth
                                value={userData.second_last_name}
                                onChange={onInputChange}
                                error={secondLastNameValid().invalid}
                                helperText={
                                    secondLastNameValid().invalid
                                        ? secondLastNameValid().message
                                        : `Caracteres disponibles: ${
                                              userData?.second_last_name
                                                  ? userData.second_last_name
                                                        .length
                                                  : 0
                                          }/50`
                                }
                            />
                        </Grid>
                        {tipoUsuario !== 'PARENT' && (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="enrollment"
                                    name="enrollment"
                                    label="Matrícula"
                                    type="text"
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 20,
                                        autocomplete: 'turnoff',
                                    }}
                                    InputLabelProps={{
                                        shrink: userData.enrollment,
                                    }}
                                    fullWidth
                                    value={
                                        userData.enrollment === null
                                            ? ''
                                            : userData.enrollment
                                    }
                                    onChange={onInputChange}
                                    error={enrollmentValid().invalid}
                                    helperText={
                                        enrollmentValid().invalid
                                            ? enrollmentValid().message
                                            : `Caracteres disponibles: ${
                                                  userData?.enrollment
                                                      ? userData.enrollment
                                                            .length
                                                      : 0
                                              }/20`
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="email"
                                name="email"
                                label="Correo electrónico"
                                type="email"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    maxLength: 50,
                                    autocomplete: 'turnoff',
                                }}
                                InputLabelProps={{ shrink: userData.email }}
                                value={userData.email}
                                onChange={onInputChange}
                                error={emailValid().invalid}
                                helperText={
                                    emailValid().invalid
                                        ? emailValid().message
                                        : `Caracteres disponibles: ${
                                              userData?.email
                                                  ? userData.email.length
                                                  : 0
                                          }/50`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                value={birthStateValue}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                getOptionLabel={(option) => option.title}
                                onChange={(event, newValue) => {
                                    getBirthMuncipalities(newValue);
                                }}
                                id="controllable-states-demo"
                                options={birthStates}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{
                                            '& > img': { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                    >
                                        {option.title}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Estado de nacimiento"
                                        variant="outlined"
                                        error={birthStateValid().invalid}
                                        helperText={
                                            birthStateValid().invalid
                                                ? birthStateValid().message
                                                : false
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                value={birthMunicipalityValue}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                getOptionLabel={(option) => option.title}
                                onChange={(event, newValue) => {
                                    getBirthCities(newValue);
                                }}
                                id="controllable-states-demo"
                                options={birthMunicipalities}
                                disabled={
                                    birthMunicipalities.length === 0
                                        ? true
                                        : false
                                }
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{
                                            '& > img': { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                    >
                                        {option.title}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Municipio de nacimiento"
                                        variant="outlined"
                                        error={birthMunicipalityValid().invalid}
                                        helperText={
                                            birthMunicipalityValid().invalid
                                                ? birthMunicipalityValid()
                                                      .message
                                                : false
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                value={birthCityValue}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                getOptionLabel={(option) => option.title}
                                onChange={(event, newValue) => {
                                    saveBirthCity(newValue);
                                }}
                                id="controllable-states-demo"
                                options={birthCities}
                                disabled={
                                    birthCities.length === 0 ? true : false
                                }
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{
                                            '& > img': { mr: 2, flexShrink: 0 },
                                        }}
                                        key={option.city_id}
                                        {...props}
                                    >
                                        {option.title}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Ciudad de nacimiento"
                                        variant="outlined"
                                        error={birthCityValid().invalid}
                                        helperText={
                                            birthCityValid().invalid
                                                ? birthCityValid().message
                                                : false
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                required
                                error={educationLevelValid().invalid}
                            >
                                <InputLabel id="level_education">
                                    Máximo grado de estudios
                                </InputLabel>
                                <Select
                                    labelId="level_education"
                                    label="Máximo grado de estudios"
                                    id="level_education"
                                    name="level_education"
                                    value={
                                        userData.level_education === undefined
                                            ? ''
                                            : userData.level_education
                                    }
                                    onChange={onInputChange}
                                    InputLabelProps={{
                                        shrink: userData.level_education,
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value="" />
                                    {educationLevel.map((level) => (
                                        <MenuItem
                                            key={level.id}
                                            value={level.id}
                                        >
                                            {level.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                required
                                error={genderValid().invalid}
                            >
                                <InputLabel id="gender">
                                    Seleccionar género
                                </InputLabel>
                                <Select
                                    labelId="gender"
                                    label="Seleccionar género"
                                    id="gender"
                                    name="gender"
                                    value={userData.gender}
                                    onChange={onInputChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: userData.gender,
                                    }}
                                >
                                    <MenuItem value="" />
                                    {optionGender.map((option) => (
                                        <MenuItem
                                            key={option}
                                            value={option.charAt(0)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider
                                dateAdapter={AdapterLuxon}
                                localeText={
                                    esES.components.MuiLocalizationProvider
                                        .defaultProps.localeText
                                }
                                adapterLocale="es-MX"
                            >
                                <DatePicker
                                    label="Fecha de nacimiento"
                                    value={
                                        userData.date_birth
                                            ? DateTime.fromISO(
                                                  userData.date_birth
                                              )
                                            : null
                                    }
                                    onChange={(date) => {
                                        const formattedDate = date
                                            ? date.toISODate()
                                            : null;
                                        setUserData({
                                            ...userData,
                                            date_birth: formattedDate,
                                        });
                                    }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                        },
                                    }}
                                    onError={(error) => {
                                        if (error === null) {
                                            setDateError(false);
                                        } else {
                                            setDateError(true);
                                        }
                                    }}
                                    onClose={() => {}}
                                    disableOpenPicker
                                    shouldDisableDate={(day) => {
                                        const currentDate = DateTime.now();
                                        let minDate = currentDate.minus({
                                            years: 100,
                                        });
                                        minDate = DateTime.fromISO(
                                            minDate.year + '-08-30T13:07:04.054'
                                        );
                                        let maxDate = currentDate.minus({
                                            years: 12,
                                        });
                                        maxDate = DateTime.fromISO(
                                            maxDate.year + '-08-30T13:07:04.054'
                                        );
                                        return day < minDate || day > maxDate;
                                    }}
                                    minDate={DateTime.fromISO(
                                        DateTime.now().minus({ years: 100 })
                                            .year + '-08-30T11:59:59.054'
                                    )}
                                    maxDate={DateTime.fromISO(
                                        DateTime.now().minus({ years: 12 })
                                            .year + '-08-30T11:59:59.054'
                                    )}
                                />
                            </LocalizationProvider>
                            {birthDateValid().invalid && (
                                <Grid item>
                                    <Typography variant="caption" color="error">
                                        {birthDateValid().message}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                            <FormControlLabel
                                control={
                                    <GreyBlueSwitch
                                        checked={userData.is_indigenous}
                                        onChange={(event) => {
                                            const isChecked =
                                                event.target.checked;
                                            setUserData((prevItem) => ({
                                                ...prevItem,
                                                is_indigenous: isChecked,
                                            }));
                                        }}
                                    />
                                }
                                label="Indígena"
                            />
                            <FormControlLabel
                                control={
                                    <GreyBlueSwitch
                                        defaultChecked={userData.is_immigrant}
                                        onChange={(event) => {
                                            const isChecked =
                                                event.target.checked;
                                            setUserData((prevItem) => ({
                                                ...prevItem,
                                                is_immigrant: isChecked,
                                            }));
                                        }}
                                    />
                                }
                                label="Inmigrante"
                            />
                            <FormControlLabel
                                control={
                                    <GreyBlueSwitch
                                        defaultChecked={userData.is_stranger}
                                        onChange={(event) => {
                                            const isChecked =
                                                event.target.checked;
                                            setUserData((prevItem) => ({
                                                ...prevItem,
                                                is_stranger: isChecked,
                                            }));
                                        }}
                                    />
                                }
                                label="Extranjero"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="curp"
                                name="curp"
                                label="CURP"
                                type="text"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    maxLength: 18,
                                    autocomplete: 'turnoff',
                                }}
                                InputLabelProps={{ shrink: userData.curp }}
                                value={userData.curp.toUpperCase()}
                                onChange={curpInputEvent}
                                error={curpValid().invalid}
                                helperText={
                                    curpValid().invalid
                                        ? curpValid().message
                                        : false
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="rfc"
                                name="rfc"
                                label="RFC"
                                type="text"
                                variant="outlined"
                                inputProps={{ autocomplete: 'turnoff' }}
                                fullWidth
                                InputLabelProps={{ shrink: userData.rfc }}
                                value={userData.rfc.toUpperCase()}
                                onChange={onInputChange}
                                error={rfcValid().invalid}
                                helperText={
                                    rfcValid().invalid
                                        ? rfcValid().message
                                        : false
                                }
                            />
                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="street"
                                name="street"
                                label="Calle"
                                type="text"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 50,
                                    autocomplete: 'turnoff',
                                }}
                                InputLabelProps={{ shrink: userData.street }}
                                fullWidth
                                value={userData.street}
                                onChange={onInputChange}
                                error={streetValid().invalid}
                                helperText={
                                    streetValid().invalid
                                        ? streetValid().message
                                        : `Caracteres disponibles: ${
                                              userData?.street
                                                  ? userData.street.length
                                                  : 0
                                          }/50`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="colony"
                                name="colony"
                                label="Colonia"
                                type="text"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 50,
                                    autocomplete: 'turnoff',
                                }}
                                InputLabelProps={{ shrink: userData.colony }}
                                fullWidth
                                value={userData.colony}
                                onChange={onInputChange}
                                error={colonyValid().invalid}
                                helperText={
                                    colonyValid().invalid
                                        ? colonyValid().message
                                        : `Caracteres disponibles: ${
                                              userData?.colony
                                                  ? userData.colony.length
                                                  : 0
                                          }/50`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outside_number"
                                name="outside_number"
                                label="Número exterior"
                                type="text"
                                autoComplete="current-password"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 20,
                                    autocomplete: 'turnoff',
                                }}
                                InputLabelProps={{
                                    shrink: userData.outside_number,
                                }}
                                fullWidth
                                value={userData.outside_number}
                                onChange={onInputChange}
                                error={outsideNumberValid().invalid}
                                helperText={
                                    outsideNumberValid().invalid
                                        ? outsideNumberValid().message
                                        : `Caracteres disponibles: ${
                                              userData?.outside_number
                                                  ? userData.outside_number
                                                        .length
                                                  : 0
                                          }/20`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="inside_number"
                                name="inside_number"
                                label="Número interior"
                                type="text"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    maxLength: 20,
                                    autocomplete: 'turnoff',
                                }}
                                InputLabelProps={{
                                    shrink: userData.inside_number,
                                }}
                                value={userData.inside_number}
                                onChange={onInputChange}
                                error={insideNumberValid().invalid}
                                helperText={
                                    insideNumberValid().invalid
                                        ? insideNumberValid().message
                                        : `Caracteres disponibles: ${
                                              userData?.inside_number
                                                  ? userData.inside_number
                                                        .length
                                                  : 0
                                          }/20`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="zipcode"
                                name="zipcode"
                                label="Código postal"
                                type="text"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 5,
                                    autocomplete: 'turnoff',
                                }}
                                InputLabelProps={{ shrink: userData.zipcode }}
                                fullWidth
                                value={userData.zipcode}
                                onChange={onInputChange}
                                error={zipcodeValid().invalid}
                                helperText={
                                    zipcodeValid().invalid
                                        ? zipcodeValid().message
                                        : `Caracteres disponibles: ${
                                              userData?.zipcode
                                                  ? userData.zipcode.length
                                                  : 0
                                          }/5`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                value={stateValue}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                getOptionLabel={(option) => option.title}
                                onChange={(event, newValue) => {
                                    getMunicipalities(newValue);
                                }}
                                id="controllable-states-demo"
                                options={states}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{
                                            '& > img': { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                    >
                                        {option.title}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Elige un estado"
                                        variant="outlined"
                                        error={statesValid().invalid}
                                        helperText={
                                            statesValid().invalid
                                                ? statesValid().message
                                                : false
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                value={municipalityValue}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                getOptionLabel={(option) => option.title}
                                onChange={(event, newValue) => {
                                    getCities(newValue);
                                }}
                                id="controllable-states-demo"
                                options={municipalities}
                                disabled={
                                    municipalities.length === 0 ? true : false
                                }
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{
                                            '& > img': { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                    >
                                        {option.title}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Elige un municipio"
                                        variant="outlined"
                                        error={municipalityValid().invalid}
                                        helperText={
                                            municipalityValid().invalid
                                                ? municipalityValid().message
                                                : false
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                value={cityValue}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                getOptionLabel={(option) => option.title}
                                onChange={(event, newValue) => {
                                    saveCity(newValue);
                                }}
                                id="controllable-states-demo"
                                options={cities}
                                disabled={cities.length === 0 ? true : false}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{
                                            '& > img': { mr: 2, flexShrink: 0 },
                                        }}
                                        key={option.city_id}
                                        {...props}
                                    >
                                        {option.title}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Elige una ciudad"
                                        variant="outlined"
                                        error={cityValid().invalid}
                                        helperText={
                                            cityValid().invalid
                                                ? cityValid().message
                                                : false
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                );
            case 2:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="cellphone"
                                name="cellphone"
                                label="Teléfono"
                                type="tel"
                                autoComplete="current-password"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    maxLength: 10,
                                    autocomplete: 'turnoff',
                                }}
                                value={userData.cellphone}
                                onChange={onInputChange}
                                error={cellphoneValid().invalid}
                                helperText={
                                    cellphoneValid().invalid
                                        ? cellphoneValid().message
                                        : `Caracteres disponibles: ${
                                              userData?.cellphone
                                                  ? userData.cellphone.length
                                                  : 0
                                          }/10`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="password"
                                name="password"
                                label="Contraseña"
                                type="password"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 50,
                                    autocomplete: 'turnoff',
                                }}
                                fullWidth
                                value={userData.password}
                                onChange={onInputChange}
                                error={passwordValid().invalid}
                                helperText={
                                    passwordValid().invalid
                                        ? passwordValid().message
                                        : false
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="password_confirmation"
                                name="password_confirmation"
                                label="Repetir contraseña"
                                type="password"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 50,
                                    autocomplete: 'turnoff',
                                }}
                                fullWidth
                                value={userData.password_confirmation}
                                onChange={onInputChange}
                                error={passwordConfirmationValid().invalid}
                                helperText={
                                    passwordConfirmationValid().invalid
                                        ? passwordConfirmationValid().message
                                        : false
                                }
                            />
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    };

    return (
        <Dialog
            open={openModal}
            fullWidth
            maxWidth={'lg'}
            disableEscapeKeyDown={statusOperation === 'pending'}
            onClose={handleClose}
        >
            <DialogTitle
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ flexGrow: 1, color: fontColor.value }}
                    >
                        {`Agregar ${title}`}
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent>
                <Stack
                    direction="row"
                    divider={
                        <Divider
                            orientation="vertical"
                            flexItem
                            variant="middle"
                        />
                    }
                    spacing={3}
                    sx={{ height: 550 }}
                >
                    <Stack
                        direction="column"
                        spacing={1}
                        sx={{ minWidth: 225, maxWidth: 225, paddingTop: 4 }}
                    >
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => {
                                let stepValid = true;
                                let stepText = '';
                                if (index === 0) {
                                    stepValid = !StepOneisInvalid();
                                    stepText = StepOneisInvalid()
                                        ? 'Faltan campos por llenar'
                                        : 'Todos los campos están completos';
                                } else if (index === 1) {
                                    stepValid = !StepTwoisInvalid();
                                    stepText = StepTwoisInvalid()
                                        ? 'Faltan campos por llenar'
                                        : 'Todos los campos están completos';
                                } else if (index === 2) {
                                    stepValid = !StepThreeisInvalid();
                                    stepText = StepThreeisInvalid()
                                        ? 'Faltan campos por llenar'
                                        : 'Todos los campos están completos';
                                }
                                return (
                                    <Step
                                        key={index}
                                        completed={index < activeStep}
                                        error={
                                            index === activeStep && !stepValid
                                        }
                                    >
                                        <StepLabel
                                            StepIconProps={{
                                                completed: index < activeStep,
                                                error:
                                                    index === activeStep &&
                                                    !stepValid,
                                            }}
                                            StepIconComponent={(props) => {
                                                if (props.error) {
                                                    return (
                                                        <ErrorIcon
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        />
                                                    );
                                                } else if (props.completed) {
                                                    return (
                                                        <CheckIcon
                                                            style={{
                                                                color: 'green',
                                                            }}
                                                        />
                                                    );
                                                } else {
                                                    return (
                                                        <StepIcon {...props} />
                                                    );
                                                }
                                            }}
                                        >
                                            <Typography
                                                noWrap
                                                variant="h7"
                                                color="primary"
                                            >
                                                {label.label}
                                            </Typography>
                                            {index === activeStep && (
                                                <Typography
                                                    noWrap
                                                    variant="body2"
                                                    style={{
                                                        color: stepValid
                                                            ? 'green'
                                                            : 'red',
                                                    }}
                                                >
                                                    {stepText}
                                                </Typography>
                                            )}
                                        </StepLabel>
                                        <StepContent>
                                            <Typography>
                                                {label.description}
                                            </Typography>
                                        </StepContent>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Stack>
                    <Box
                        sx={{
                            overflowY: 'auto',
                            maxHeight: '500px',
                        }}
                    >
                        <CardContent>
                            <Box>
                                <DialogActions>{renderForm()}</DialogActions>
                            </Box>
                        </CardContent>
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                {activeStep === 0 ? (
                    <Button onClick={handleClose}>Cerrar</Button>
                ) : (
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                        Atrás
                    </Button>
                )}
                {activeStep < steps.length - 1 ? (
                    <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ ml: 2 }}
                        disabled={isStepInvalid(activeStep)}
                    >
                        Siguiente
                    </Button>
                ) : (
                    <LoadingButton
                        color="primary"
                        onClick={enviarDatos}
                        loading={statusOperation === 'pending'}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        disabled={formInvalid()}
                    >
                        {`Agregar`}
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};
