import { combineReducers } from '@reduxjs/toolkit';

import home from './home';
import income from './income';
import concepts from './concepts';
import discounts from './discounts';
import surcharges from './surcharges';
import cat_discounts from './cat_discounts';
import cat_surcharges from './cat_surcharges';
import cat_concepts from './cat_concepts';
import agreements from './agreements';
import partialities from './partialities';

/**
 * selector para recuperar el slice de reportes
 */
// export const selectReports = (store) => store.;

export default combineReducers({
    home,
    income,
    agreements,
    concepts,
    discounts,
    surcharges,
    cat_concepts,
    cat_discounts,
    cat_surcharges,
    partialities,
});
