import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mode: 'light',
    userId: null,
};

export const themeSlice = createSlice({
    name: 'Theme',
    initialState,
    reducers: {
        setMode: (state) => {
            state.mode = state.mode === 'dark' ? 'light' : 'dark';
        },
    },
});

export const { setMode } = themeSlice.actions;

export default themeSlice.reducer;
