import React, { useState } from 'react';
import { Box, Typography, Grid, Button, Snackbar, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../../hooks';
import Feedback from '../../../service/Feedback';
import { Redirect, useHistory } from 'react-router-dom';
import { addManySettings } from '../../../store/slices/entities/settings';
import { addOneSchool } from '../../../store/slices/entities/schools';
import { setToken, setUser } from '../../../store/slices/auth';
import { LoadingButton } from '@mui/lab';
import { NavLink } from 'react-router-dom';
import Downloads from '../../Components/Downloads';

// Assets
import directivo from '../../assets/directivo.png';
import facebook from '../../assets/facebook.svg';
import instagram from '../../assets/instagram.svg';
import logoFooter from '../../assets/logo_footer.svg';
import palomita from '../../assets/palomita.svg';
import whatsapp from '../../assets/whatsapp.svg';
import whatsAppFloat from '../../assets/whatsAppFloat.svg';
import youtube from '../../assets/youtube.svg';
import funcionalidadesbottom from '../../assets/funcionalidades_bottom.png';
import NavBar from '../../Components/NavBar';

// Styles
// import './styles.scss';

const Login = () => {
    ///////////////////// DATOS PARA EL FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message);
        setOpenFeedback(true);
    };

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    const [feedback] = useState(() => new Feedback());

    ///////////////////////// ESTADO DEL COMPONENTE ////////////////////////////////

    const [password, setPassword] = useState('');
    const [telefono, setTelefono] = useState('');
    const [loading, setLoading] = useState(false);

    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isPhoneFocused, setIsPhoneFocused] = useState(false);
    const [isPhoneEmpty, setIsPhoneEmpty] = useState(true);

    const [isPassValid, setIsPassValid] = useState(false);
    const [isPassFocused, setIsPassFocused] = useState(false);
    const [isPassEmpty, setIsPassEmpty] = useState(true);

    const history = useHistory();
    const dispatch = useDispatch();
    const Auth = useAuth();

    if (Auth.getUser()) {
        const route = Auth.getHomeRoute();
        return <Redirect to={route} />;
    }

    /**
     * Funcion para logear al usuario
     */
    const enviarDatos = () => {
        if (telefono !== '' && password !== '') {
            setLoading(true);

            Auth.login(telefono, password)
                .then((response) => {
                    dispatch(addManySettings(response.setting));

                    if (response.school != null) {
                        dispatch(addOneSchool(response.school));
                    }

                    dispatch(setToken(response.token));
                    dispatch(setUser(response.userData));

                    const route = Auth.getHomeRoute();

                    history.push({ pathname: route });
                })
                .catch((err) => {
                    console.log(err);
                    let message = feedback.getMessage(err);
                    showFeedback(message.message);
                })
                .then(() => {
                    setLoading(false);
                });
        } else {
            showFeedback('Datos incompletos');
        }
    };

    const onGoRecoverAccount = () => {
        history.push({ pathname: '/NewRecoverPassword' });
    };

    const handleInputPhoneChange = (event) => {
        const inputValue = event.target.value;
        const isNumeric = /^\d+$/.test(inputValue);
        setIsPhoneValid(isNumeric && inputValue.length === 10);
        setIsPhoneEmpty(inputValue === '');
        setTelefono(event.target.value);
    };

    const handlePhoneFocus = () => {
        setIsPhoneFocused(true);
    };

    const handlePhoneBlur = () => {
        setIsPhoneFocused(false);
    };

    const handleInputPassChange = (event) => {
        const inputValue = event.target.value;
        setIsPassValid(inputValue.length >= 3);
        setIsPassEmpty(inputValue === '');
        setPassword(event.target.value);
    };

    const handlePassFocus = () => {
        setIsPassFocused(true);
    };

    const handlePassBlur = () => {
        setIsPassFocused(false);
    };

    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            enviarDatos();
        }
    };

    const inputPhoneStyles = {
        width: '100%',
        border: '0',
        fontFamily: 'inherit',
        padding: '0',
        height: '48px',
        fontSize: '16px',
        fontWeight: '500',
        variant: 'outlined',
        borderRadius: '0',
        color: 'rgb(46, 51, 62)',
        transition: 'all .15s ease',
        outline: 'none',
        borderBottom: `2px solid ${
            isPhoneFocused || isPhoneEmpty ? '#fff' : '#ff6969'
        }`,
        paddingLeft: '0.5em',
        margin: '0 auto 10px auto',
    };

    const borderPhoneStyles = {
        position: 'absolute',
        left: '0',
        bottom: '0',
        height: '18px',
        fill: 'none',
        stroke: isPhoneValid ? '#fff' : '#ff6969',
        strokeWidth: '2',
        transition: 'all .2s ease',
    };

    const containerPhoneStyles = {
        position: 'relative',
        width: '100%',
        maxWidth: '280px',
        height: '53px',
        margin: '0 auto 15px auto',
    };

    const inputPassStyles = {
        width: '100%',
        border: '0',
        fontFamily: 'inherit',
        padding: '0',
        height: '48px',
        fontSize: '16px',
        fontWeight: '500',
        variant: 'outlined',
        borderRadius: '0',
        color: 'rgb(46, 51, 62)',
        transition: 'all .15s ease',
        outline: 'none',
        paddingBottom: '2px',
        borderBottom: `2px solid ${
            isPassFocused || isPassEmpty ? '#fff' : '#ff6969'
        }`,
        paddingLeft: '0.5em',
    };

    const borderPassStyles = {
        position: 'absolute',
        left: '0',
        bottom: '0',
        height: '18px',
        fill: 'none',
        stroke: isPassValid ? '#fff' : '#ff6969',
        strokeWidth: '2',
        transition: 'all .2s ease',
    };

    const containerPassStyles = {
        position: 'relative',
        width: '100%',
        maxWidth: '280px',
        height: '53px',
        margin: '0 auto 15px auto',
    };

    return (
        <Box>
            <NavBar />
            <div className="body">
                <div className="mweb">
                    <section className="sidebar">
                        <div className="custom-container">
                            <div className="grid dos">
                                <div className="info">
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={4}
                                    >
                                        <img
                                            src={palomita}
                                            alt="Palomita"
                                            style={{ width: '50px' }}
                                        />
                                        <h1>Administrador personal</h1>
                                    </Stack>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        <p>¡Potencia el éxito educativo!</p>
                                        <div className="">
                                            <p>Iniciar sesión</p>
                                        </div>
                                        <div style={containerPhoneStyles}>
                                            <input
                                                type="text"
                                                id="inp"
                                                placeholder="Teléfono"
                                                pattern="[0-9]*"
                                                minLength="10"
                                                maxLength="10"
                                                required
                                                onChange={
                                                    handleInputPhoneChange
                                                }
                                                onFocus={handlePhoneFocus}
                                                onBlur={handlePhoneBlur}
                                                onKeyDown={handleKeyDown}
                                                style={inputPhoneStyles}
                                            />
                                            <svg
                                                width="280px"
                                                height="18px"
                                                viewBox="0 0 280 18"
                                                className="border"
                                                style={borderPhoneStyles}
                                            >
                                                <path d="M0,12 L223.166144,12 C217.241379,12 217.899687,12 225.141066,12 C236.003135,12 241.9279,12 249.827586,12 C257.727273,12 264.639498,12 274.514107,12 C281.097179,12 281.755486,12 276.489028,12"></path>
                                            </svg>
                                        </div>
                                        <div style={containerPassStyles}>
                                            <input
                                                type="password"
                                                id="password"
                                                placeholder="Contraseña"
                                                minLength="3"
                                                required
                                                onChange={handleInputPassChange}
                                                onFocus={handlePassFocus}
                                                onBlur={handlePassBlur}
                                                onKeyDown={handleKeyDown}
                                                style={inputPassStyles}
                                            />
                                            <svg
                                                width="280px"
                                                height="18px"
                                                viewBox="0 0 280 18"
                                                className="border"
                                                style={borderPassStyles}
                                            >
                                                <path d="M0,12 L223.166144,12 C217.241379,12 217.899687,12 225.141066,12 C236.003135,12 241.9279,12 249.827586,12 C257.727273,12 264.639498,12 274.514107,12 C281.097179,12 281.755486,12 276.489028,12"></path>
                                            </svg>
                                        </div>
                                        <div className="buttons">
                                            <Stack
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                <LoadingButton
                                                    className="btn_mw red"
                                                    style={{
                                                        marginBottom: '5px',
                                                    }}
                                                    loading={loading}
                                                    endIcon={<SendIcon />}
                                                    loadingPosition="end"
                                                    variant="contained"
                                                    onClick={enviarDatos}
                                                >
                                                    Ingresar
                                                </LoadingButton>
                                                <Button
                                                    size="small"
                                                    onClick={onGoRecoverAccount}
                                                >
                                                    Recuperar contraseña
                                                </Button>
                                            </Stack>
                                        </div>
                                        <p className="FirstText">
                                            Toma <b>decisiones informadas</b>{' '}
                                            sobre la{' '}
                                            <b>educación y desarrollo</b> de tus{' '}
                                            <b>alumnos y maestros,</b>{' '}
                                            identificando áreas de{' '}
                                            <b>mejora y fortaleza.</b>
                                        </p>
                                        <p className="SecondText">
                                            Automatizamos el registro de
                                            calificaciones y asistencias,
                                            brindando a los <b>Maestros</b> más
                                            tiempo para enfocarse en lo que
                                            realmente importa: <b>educar.</b>
                                        </p>
                                    </div>
                                </div>
                                <div className="maestra">
                                    <img
                                        src={funcionalidadesbottom}
                                        alt="maestra"
                                    />
                                    <div className="botonix">
                                        <div></div>
                                        <div className="delay"></div>
                                        <div className="moredelay"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="descargas">
                        <Downloads />
                    </section>
                    <footer>
                        <img className="palomita" src={palomita} />
                        <div className="container">
                            <div className="grid cinco">
                                <div>
                                    <img src={logoFooter} />
                                    <p>
                                        Transformando la manera en que Maestros,
                                        Padres de familia y Directores gestionan
                                        la educación.
                                    </p>
                                </div>
                                <div>
                                    <img
                                        src={directivo}
                                        style={{ width: '230px' }}
                                    />
                                </div>
                                <div className="FBeneficios">
                                    <h4>Beneficios</h4>
                                    <ul>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}
                                            >
                                                <a>Maestros</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}
                                            >
                                                <a>Padres</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}
                                            >
                                                <a>Orientadores</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}
                                            >
                                                <a>Directivos</a>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="FFuncionalidades">
                                    <h4>Funcionalidades</h4>
                                    <ul>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}
                                            >
                                                <a>
                                                    Calificaciones y asistencias
                                                </a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}
                                            >
                                                <a>Promedio automático</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}
                                            >
                                                <a>
                                                    Notificaciones para padres
                                                </a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}
                                            >
                                                <a>
                                                    Reportes de comportamiento
                                                </a>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul className="redes">
                                        <li>
                                            <a
                                                href="https://www.facebook.com/curaeducacion"
                                                target="_blank"
                                            >
                                                <img src={facebook} />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/curaeducacion/"
                                                target="_blank"
                                            >
                                                <img src={instagram} />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.youtube.com/@cura-educacionefectiva-oficial"
                                                target="_blank"
                                            >
                                                <img src={youtube} />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="https://wa.me/5216181448945" target="_blank">
                                                <img src={whatsapp} />
                                            </a>
                                        </li> */}
                                    </ul>
                                    <a
                                        href="https://wa.me/5216181448945"
                                        className="float-wa"
                                        target="_blank"
                                    >
                                        <img
                                            src={whatsAppFloat}
                                            className="wa-icon"
                                        />
                                    </a>
                                    <a
                                        href="https://wa.me/5216181448945"
                                        target="_blank"
                                    >
                                        <h2>
                                            <img src={whatsapp} /> 618 144 8945
                                        </h2>
                                    </a>
                                    <h2>Email: curati2023@gmail.com</h2>
                                    <h2>Dirección</h2>
                                    <p className="dir">
                                        Blvd. Domingo Arrieta 1700, Gral Domingo
                                        Arrieta, CP 34180, Durango, Dgo., Mexico
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="bottom">
                            <div className="container">
                                <span>
                                    Cura App ® Todos los Derechos Reservados •
                                    Durango, Dgo. México • 2023
                                </span>
                                <Box>
                                    <Typography
                                        variant="caption"
                                        component="div"
                                    >
                                        v{' '}
                                        {`${process.env.REACT_APP_VERSION_APP} ${process.env.REACT_APP_NAME}`}
                                    </Typography>
                                </Box>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
            <Snackbar
                open={openFeedback}
                autoHideDuration={3000}
                onClose={closeFeedback}
                message={feedbackMessage}
                action={
                    <Button
                        color="inherit"
                        size="small"
                        onClick={closeFeedback}
                    >
                        X
                    </Button>
                }
            />
        </Box>
    );
};

export default Login;
