import { Grid, Skeleton } from "@mui/material"

/**
 * Component que muestra la carga de datos
 * del componente para resolver conlfitos
 * 
 * @returns 
 */
const ResolveConflictsSubjectsSkeleton = () => {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
                <Skeleton variant="rectangular" height={200} animation="wave" />
            </Grid>
            <Grid item xs={12} md={2}>
                <Skeleton variant="rectangular" height={200} animation="wave" />
            </Grid>
            <Grid item xs={12} md={5}>
                <Skeleton variant="rectangular" height={200} animation="wave" />
            </Grid>
        </Grid>
    )
}

export default ResolveConflictsSubjectsSkeleton
