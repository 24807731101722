import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectTotalAbscence,
} from '../../../store/home/fetchSlice';
import { getTotalAbscence } from '../../../store/home/thunks';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { SkeletonCard } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeTotalAbscenceStatusServer } from '../../../store/home/componentStatusSelectors';

const TotalAbscenceCard = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeTotalAbscenceStatusServer
    );

    const totalAbscence = useFetchResource(
        () =>
            getTotalAbscence({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectTotalAbscence,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getTotalAbscence({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectTotalAbscence,
            [cycleSelected, schoolId]
        );
    };

    return (
        <Box>
            {fetchingStatus === 'pending' && <SkeletonCard items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    style={{
                        backgroundColor: '#e91e63',
                        borderRadius: '10px',
                        textAlign: 'center',
                        minHeight: '200px',
                        minWidth: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: 'none',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        margin: 'auto',
                    }}
                >
                    <CardContent style={{ padding: '16px', width: '100%' }}>
                        <EventBusyIcon
                            style={{
                                fontSize: 40,
                                color: 'rgb(253, 237, 232)',
                                marginBottom: '10px',
                            }}
                        />
                        <Typography
                            variant="h6"
                            align="center"
                            style={{
                                color: 'rgb(253, 237, 232)',
                                marginBottom: '10px',
                                whiteSpace: 'pre-wrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {totalAbscence.title}
                        </Typography>
                        <Typography
                            variant="h4"
                            style={{
                                color: 'rgb(253, 237, 232)',
                            }}
                        >
                            {totalAbscence.value || 0}
                        </Typography>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default TotalAbscenceCard;
