import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../../hooks';
import { useTheme } from '@emotion/react';
import useThemeColors from '../../../../theme/themes';
import { selectTableData } from '../../../store/surcharges/selectors';
import {
    DataGrid,
    esES,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Divider,
    IconButton,
    Pagination,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { setStatusFilter } from '../../../store/surcharges/ui';
import CreateSurcharges from './CreateSurcharges';
import CatalogModal from './CatalogModal';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';

import student from '../../../../assets/images/default-image-niña.svg';
import student2 from '../../../../assets/images/default-image-niño.svg';
import {
    AddCircleOutline,
    BookmarkAddRounded,
    CheckOutlined,
    CloseOutlined,
    Edit,
} from '@mui/icons-material';
import { DeleteSurcharges } from '../../../store/surcharges/operations';
import Feedback from '../../../../service/Feedback';
import ModalUpdateSurcharges from './ModalUpdateSurcharges';
import ExportButton from '../../../components/exportButton';
import CustomPagination from '../../../components/CustomPagination';
import DeleteSweepTwoToneIcon from '@mui/icons-material/DeleteSweepTwoTone';
import EditRoadTwoToneIcon from '@mui/icons-material/EditRoadTwoTone';
import { date } from 'yup';

const SurchargesTable = () => {
    ///////////// LOCAL STATE /////////////

    const [open, setOpen] = useState(false);
    const [openCatalog, setOpenCatalog] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');

    const [tabValue, setTabValue] = useState(() => {
        const savedTab = localStorage.getItem('conceptsTab');
        return savedTab ? parseInt(savedTab, 10) : 0;
    });

    ///////////// SHARED STATE /////////////

    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = useThemeColors();
    const datatable = useSelector(selectTableData);
    const statusFilter = useSelector(
        (state) => state.paymentsModule.surcharges.ui.statusFilter
    );
    const feedbackApp = useFeedback();

    ///////////// USE EFFECT /////////////

    ///////////// HANDLERS /////////////
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenCatalog = () => setOpenCatalog(true);
    const handleCloseCatalog = () => setOpenCatalog(false);

    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const handleTabChange = (event, newValue) => {
        dispatch(setStatusFilter(newValue));
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleSelectionChange = (selection) => {
        const selectedData = datatable.filter((row) =>
            selection.includes(row.surcharge_id)
        );
        setSelectedRows(selectedData);
        setSelectedIds(selection);
    };

    const handleDeleteSelected = () => {
        const data = selectedIds.map((surcharge_id) => ({
            surcharge_id: surcharge_id,
        }));

        dispatch(DeleteSurcharges({ data }))
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Recargo cancelado con éxito',
                });
                setSelectedRows([]);
                setSelectedIds([]);
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title:
                        error.feedback?.title ||
                        'Error al cancelar el concepto',
                });
            });
    };

    ///////////// COLUMN CONFIG /////////////

    const getStatusColor = (status) => {
        switch (status) {
            case 1:
                return {
                    color: colors.green[200],
                    description: 'Activo',
                    icon: (
                        <CheckOutlined
                            size="small"
                            sx={{
                                color: colors.green[600],
                            }}
                        />
                    ),
                };
            case 2:
                return {
                    color: colors.red[200],
                    description: 'Cancelado',
                    icon: (
                        <CloseOutlined
                            size="small"
                            sx={{
                                color: colors.red[600],
                            }}
                        />
                    ),
                };
            default:
                return { color: colors.grey[300], description: 'Desconocido' };
        }
    };

    const columns = [
        {
            field: 'surcharge_id',
            headerName: 'Folio',
            flex: 0.5,
            minWidth: 100,
            renderCell: (params) => (
                <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                    #{params.value}
                </Typography>
            ),
        },
        {
            field: 'user',
            headerName: 'Alumno',
            flex: 3,
            minWidth: 400,
            renderCell: (params) => {
                const user = params.row.user;
                const defaultAvatar = user.gender === 'M' ? student : student2;
                const avatarSrc = user.url_photo_profile || defaultAvatar;

                return (
                    <Box display="flex" alignItems="center">
                        <Avatar src={avatarSrc} sx={{ marginRight: 2 }} />
                        <Box>
                            <Typography variant="body1" noWrap>
                                {`${user.name} ${user.last_name} ${user.second_last_name}`}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                noWrap>
                                ID: {user.student_id}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                noWrap>
                                Teléfono: {user.cellphone}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Estado',
            flex: 1,
            headerAlign: 'center',
            minWidth: 200,
            align: 'center',
            renderCell: (params) => {
                const { color, description, icon } = getStatusColor(
                    params.value
                );
                return (
                    <Box>
                        <Tooltip title={description}>
                            <Box
                                sx={{
                                    borderRadius: '100%',
                                    height: 30,
                                    width: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: color,
                                    marginRight: 1,
                                }}>
                                {icon}
                            </Box>
                        </Tooltip>
                    </Box>
                );
            },
        },

        {
            field: 'concept',
            headerName: 'Concepto',
            flex: 2,
            minWidth: 200,
            headerAlign: 'center',
            renderCell: (params) => {
                const title = params.value;
                const description = params.row.descriptionConcept;

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                color: colors.deepOcean[500],
                            }}
                            noWrap>
                            {title}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: '0.875rem',
                                color: colors.grey[600],
                            }}
                            noWrap>
                            {description}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'surcharges',
            headerName: 'Recargo',
            flex: 2,
            minWidth: 200,
            headerAlign: 'center',
            renderCell: (params) => {
                const title = params.value;
                const description = params.row.descriptionsurcharges;

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                color: colors.sunbeam[800],
                            }}
                            noWrap>
                            {title}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: '0.875rem',
                                color: colors.grey[800],
                            }}
                            noWrap>
                            {description}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'amount',
            headerName: 'Importe',
            flex: 1,
            minWidth: 200,
            headerAlign: 'center',
            type: 'number',
            renderCell: (params) => (
                <Chip
                    label={`$${params.value}`}
                    sx={{
                        width: '60%',
                        backgroundColor: colors.red[200],
                        color: colors.red[800],
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        textAlign: 'center',
                        margin: '0 auto',
                    }}
                />
            ),
        },
    ];
    const filteredRows = datatable
        .filter((row) => {
            if (statusFilter === 'all') return true;
            if (statusFilter === 'paid' && row.status === 1) return true;
            if (statusFilter === 'pending' && row.status === 2) return true;
            return false;
        })
        .filter((row) => {
            const searchTermLower = searchTerm.toLowerCase();

            const userName =
                `${row.user.name} ${row.user.last_name} ${row.user.second_last_name}`.toLowerCase();
            const userCellphone = row.user.cellphone
                ? row.user.cellphone.toLowerCase()
                : '';
            const concept =
                row.catConcept && row.catConcept.title
                    ? row.catConcept.title.toLowerCase()
                    : '';
            const descriptionConcept =
                row.concept && row.concept.description
                    ? row.concept.description.toLowerCase()
                    : '';
            const surcharges =
                row.catsurcharges && row.catsurcharges.title
                    ? row.catsurcharges.title.toLowerCase()
                    : '';
            const descriptionsurcharges = row.description
                ? row.description.toLowerCase()
                : '';
            const amount = row.amount.toString().toLowerCase();

            return (
                userName.includes(searchTermLower) ||
                userCellphone.includes(searchTermLower) ||
                concept.includes(searchTermLower) ||
                descriptionConcept.includes(searchTermLower) ||
                surcharges.includes(searchTermLower) ||
                descriptionsurcharges.includes(searchTermLower) ||
                amount.includes(searchTermLower)
            );
        })
        .map((row) => ({
            id: row.surcharge_id,
            user: row.user,
            status: row.status,
            concept: row.catConcept.concept,
            descriptionConcept: row.concept.description,
            surcharges: row.catsurcharges.title,
            descriptionsurcharges: row.description,
            amount: row.amount,
            surcharge_id: row.surcharge_id,
            amount: row.concept.surcharge_total,
            date: new Date(row.created_at),
        }))
        .sort((a, b) => b.date - a.date);

    const hasCancelledConcepts = selectedRows.some((row) => row.status === 2);

    return (
        <>
            <Card
                sx={{
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.neutral.main,
                    maxWidth: '100%',
                }}>
                <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="flex-end"
                    alignItems="center"
                    paddingBottom={3}>
                    <CardActions>
                        <Tooltip title="Catálogo de Recargos">
                            <Button
                                onClick={handleOpenCatalog}
                                variant="contained"
                                color="primary">
                                <BookmarkAddRounded />
                            </Button>
                        </Tooltip>
                    </CardActions>
                </Box>
                <CardContent>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutline />}
                            onClick={handleOpen}
                            sx={{
                                flexBasis: { xs: '90%', sm: '30%' },
                                minWidth: { xs: '90%', sm: '30%' },
                                mb: { xs: 2, sm: 0 },
                            }}>
                            Registrar un nuevo Recargo
                        </Button>
                        <TextField
                            variant="outlined"
                            placeholder="Buscar un Recargo"
                            size="small"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            sx={{
                                minWidth: { xs: '90%', sm: '30%' },
                                backgroundColor: theme.palette.background.paper,
                                mr: -2,
                            }}
                        />
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end">
                        <Tooltip title="Editar los seleccionados">
                            <IconButton
                                color="inherit"
                                disabled={
                                    selectedIds.length === 0 ||
                                    hasCancelledConcepts
                                }
                                onClick={handleOpenEdit}
                                sx={{
                                    backgroundColor: 'transparent',
                                    color:
                                        selectedRows.length > 0
                                            ? colors.yellow[500]
                                            : theme.palette.grey[500],
                                }}>
                                <EditRoadTwoToneIcon />
                            </IconButton>
                        </Tooltip>
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ mx: 1 }}
                        />
                        <Tooltip title="Cancelar los seleccionados">
                            <IconButton
                                color="inherit"
                                disabled={
                                    selectedIds.length === 0 ||
                                    hasCancelledConcepts
                                }
                                onClick={handleDeleteSelected}
                                sx={{
                                    backgroundColor: 'transparent',
                                    color:
                                        selectedIds.length > 0 &&
                                        !hasCancelledConcepts
                                            ? colors.red[500]
                                            : theme.palette.grey[500],
                                }}>
                                <DeleteSweepTwoToneIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box
                        display="grid"
                        gridTemplateColumns={{ xs: '1fr', md: 'auto' }}
                        gap={2}>
                        <Tabs
                            value={statusFilter}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            sx={{ marginBottom: 2 }}>
                            <Tab label="Todos" value="all" />
                            <Tab label="Activos" value="paid" />
                            <Tab label="Cancelados" value="pending" />
                        </Tabs>
                        <DataGrid
                            rowHeight={100}
                            rows={filteredRows}
                            columns={columns}
                            autoHeight
                            pageSize={15}
                            rowsPerPageOptions={[10, 25, 50]}
                            checkboxSelection
                            onSelectionModelChange={(newSelection) => {
                                setSelectedIds(newSelection);
                                handleSelectionChange(newSelection);
                            }}
                            selectionModel={selectedIds}
                            disableColumnReorder
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            components={{
                                Pagination: CustomPagination,
                                Toolbar: () => (
                                    <ExportButton fileName="Tabla de recargos" />
                                ),
                            }}
                        />
                    </Box>
                </CardContent>
            </Card>

            <CreateSurcharges open={open} onclose={handleClose} />
            <CatalogModal open={openCatalog} onclose={handleCloseCatalog} />

            <ModalUpdateSurcharges
                open={openEdit}
                onclose={handleCloseEdit}
                selectedRows={selectedRows}
            />
        </>
    );
};

export default SurchargesTable;
