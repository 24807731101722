import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import Connection from "../../../../service/Connection";
import Feedback from "../../../../service/Feedback";
import { selectAllStudents, upsertManyStudents } from "../entities/studentsSlice";
import { selectAllGroups, upsertManyGroups } from "../entities/groupsSlice";
import { addManyGroupsStudents, selectAllGroupsStudents } from "../entities/groupsStudentsSlice";
import { addManyRelationships, selectAllRelationships } from "../entities/relationshipsSlice";
import { selectAllUsers, upsertManyUsers } from "../entities/usersSlice";
import Authentication from "../../../../service/Login";

const emptyState = {
    steps: {
        groups: {
            all: false,
            selected: [],
            operationStatus: "idle",
            didInvalidate: true
        },
        students: {
            all: false,
            selected: [],
            operationStatus: "idle",
            didInvalidate: true
        },
        parents: {
            all: false,
            selected: [],
            operationStatus: "idle",
            didInvalidate: true
        }
    },
    currentStep: 0,
    completedSteps: {}
}

/**
 * Reductor para los modos de ditribuccion
 */
export const groupsModeSlice = createSlice({
    name: "noticesModalUI/distributinModes/groups",
    initialState: emptyState,
    reducers: {
        /**
         * Ir hacia adelante en el strapper
         * 
         * @param store 
         * @param payload Paso que ha sido completado
         */
        nextStep: (store, { payload }) => {
            const currentStep = store.currentStep
            const nextStep = store.currentStep + 1

            store.currentStep = nextStep
            store.completedSteps = { ...store.completedSteps, [currentStep]: payload }
        },
        /**
         * Ir hacia atras en el strapper
         * 
         * @param store 
         * @param payload Paso que ha sido completado
         */
        previusStep: (store, { payload }) => {
            const stepsCompleted = Object.keys(store.completedSteps)

            const nextStep = stepsCompleted.length - 1

            let currentCompleted = { ...store.completedSteps }

            delete currentCompleted[parseInt(stepsCompleted[nextStep])]

            store.currentStep = parseInt(stepsCompleted[nextStep])
            store.completedSteps = currentCompleted
        },
        /**
         *  Ir hacia al final de los pasoso
         * 
         * @param store 
         * @param payload Paso que ha sido completado
         */
        finalStep: (state, { payload }) => {
            const currentStep = state.currentStep
            const nextStep = 3

            state.currentStep = nextStep
            state.completedSteps = { ...state.completedSteps, [currentStep]: payload }

            if (currentStep == 0) {
                state.steps.students = {
                    ...state.steps.students,
                    didInvalidate: true,
                    all: false,
                    selected: [],
                }

                state.steps.parents = {
                    ...state.steps.parents,
                    didInvalidate: true,
                    all: false,
                    selected: [],
                }
            } else if (currentStep == 1) {
                state.steps.parents = {
                    ...state.steps.parents,
                    didInvalidate: true,
                    all: false,
                    selected: [],
                }
            }
        },
        /**
         * Cambiaron o grupos
         * 
         * @param {*} store 
         * @param {*} param1 
         */
        changeGroupsSelected: (store, { payload }) => {
            store.steps.groups.selected = payload
        },
        /**
         * Todos los grupos fueron seleccionados
         * 
         * @param {*} store 
         * @param {*} param1 
         */
        changeSelectAllGroups: (store, { payload }) => {
            store.steps.groups.all = payload
        },
        /**
         * Cambiaron a los alumnos
         * 
         * @param {*} store 
         * @param {*} param1 
         */
        changeStudentsSelected: (store, { payload }) => {
            store.steps.students.selected = payload
        },
        /**
         * Todos los alumnos fueron seleccionados
         * 
         * @param {*} store 
         * @param {*} param1 
         */
        changeSelectAllStudents: (store, { payload }) => {
            store.steps.students.all = payload
        },
        /**
        * Cambiaron a los padres
        * 
        * @param {*} store 
        * @param {*} param1 
        */
        changeParentsSelected: (store, { payload }) => {
            store.steps.parents.selected = payload
        },
        /**
         * Todos los padres fueron seleccionados
         * 
         * @param {*} store 
         * @param {*} param1 
         */
        changeSelectAllParents: (store, { payload }) => {
            store.steps.parents.all = payload
        },
        /**
         * Invalidar los datos que esta disponibles
         * 
         * @param {*} store 
         * 
         * @param {*} param1 
         */
        invalidateStepData: (store, { payload }) => {
            store.steps[payload].didInvalidate = true
            store.steps[payload].all = false
            store.steps[payload].selected = []
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {

            return emptyState
        })
        /** 
         * Reinicia el proceso de seleccion de
         * los modos de ditribucion
         * 
         * @param {*} state 
         * @param {*} action 
         * 
         * @returns 
         */
        builder.addCase('noticesModalUI/distributinModes/reset', (state, action) => {
            state.steps.groups = {
                ...state.steps.groups,
                didInvalidate: true,
                all: false,
                selected: [],
            }

            state.steps.students = {
                ...state.steps.students,
                didInvalidate: true,
                all: false,
                selected: [],
            }

            state.steps.parents = {
                ...state.steps.parents,
                didInvalidate: true,
                all: false,
                selected: [],
            }

            state.currentStep = 0
            state.completedSteps = {}
        })

        /**
         * Carga de datos del paso de grupos
         */
        builder.addCase(loadGroupsStepData.fulfilled, (state, action) => {
            //state.server.ferchingAt = Date.now()
            state.steps.groups.didInvalidate = false

            state.steps.groups.operationStatus = 'fulfilled'
        })
        builder.addCase(loadGroupsStepData.pending, (state, action) => {
            state.steps.groups.operationStatus = 'pending'
        })
        builder.addCase(loadGroupsStepData.rejected, (state, action) => {
            state.steps.groups.operationStatus = 'rejected'
        })

        /**
         * Carga de datos del paso de alumnos 
         */
        builder.addCase(loadStudentsStepData.fulfilled, (state, action) => {
            //state.server.ferchingAt = Date.now()
            state.steps.students.didInvalidate = false

            state.steps.students.operationStatus = 'fulfilled'
        })
        builder.addCase(loadStudentsStepData.pending, (state, action) => {
            state.steps.students.operationStatus = 'pending'
        })
        builder.addCase(loadStudentsStepData.rejected, (state, action) => {
            state.steps.students.operationStatus = 'rejected'
        })

        /**
         * Carga de datos del paso de alumnos 
         */
        builder.addCase(loadParentsStepData.fulfilled, (state, action) => {
            //state.server.ferchingAt = Date.now()
            state.steps.parents.didInvalidate = false

            state.steps.parents.operationStatus = 'fulfilled'
        })
        builder.addCase(loadParentsStepData.pending, (state, action) => {
            state.steps.parents.operationStatus = 'pending'
        })
        builder.addCase(loadParentsStepData.rejected, (state, action) => {
            state.steps.parents.operationStatus = 'rejected'
        })

    }
})

export const {
    // STRAPER
    nextStep, previusStep, finalStep,
    /// GROUPS
    changeGroupsSelected, changeSelectAllGroups,
    /// Alumnos
    changeStudentsSelected, changeSelectAllStudents,
    // Padres
    changeParentsSelected, changeSelectAllParents,
    // compartidos
    invalidateStepData
} = groupsModeSlice.actions


//////////////////// SELECTORES //////////////////

/**
 * Consulta para recuperar el paso actual
 * 
 *      0.- Grupos
 *      1.- Alumnos
 *      2.- Padres
 *      2.- Finalizar
 * 
 * @param {*} state 
 * @returns 
 */
export const selectCurrentStep = (state) => state.noticesModalUI.distributinModes.groups.currentStep;

/**
* Consulta para recuperar los pasos que fueron completados
* 
* @param {*} state 
* @returns 
*/
export const selectCompletedSteps = (state) => state.noticesModalUI.distributinModes.groups.completedSteps;


//////////////////////////// GRUPOS //////////////////////////////////

/**
* Consulta para recuperar el estado de operacion del paso grupos
* 
* @param {*} state 
* @returns 
*/
export const selectOperationStatusGroupsStep = (state) => state.noticesModalUI.distributinModes.groups.steps.groups.operationStatus;

/**
 * Consulta para recuperar el grupos que estan selecinados
 * 
 * @param {*} state 
 * @returns 
 */
export const selectGroupsSelected = (state) => state.noticesModalUI.distributinModes.groups.steps.groups.selected;


/**
 * Consulta para saber si todos los elementos de grupos estan seleccionados
 * 
 * @param {*} state 
 * @returns 
 */
export const selectGroupsIsAllSelected = (state) => state.noticesModalUI.distributinModes.groups.steps.groups.all;


//////////////////////////// ALUMNOS //////////////////////////////////

/**
 * Consulta para recuperar los alumnos de los grupos que fueron seleccionados
 * 
 * @param {*} state 
 * @returns 
 */
export const selectStudentsByGroupsSelected = createSelector([
    selectAllStudents, selectAllGroups, selectAllGroupsStudents, selectGroupsSelected
], (students, groups, groupsStudents, grouspSelected) => {

    let studentsWithGroupList = []

    for (const groupId of grouspSelected) {
        let groupsItem = groups.find(i => i.group_id == groupId)
        let grouspStudents = groupsStudents.filter(i => i.group_id == groupId)

        let studentsItem = grouspStudents.map(gs => students.find(s => s.student_id == gs.student_id))

        const formated = studentsItem.map(s => ({ group: groupsItem, student: s }))

        studentsWithGroupList = studentsWithGroupList.concat(formated)
    }

    return _.flatten(studentsWithGroupList)
})

/**
 * Consulta para recuperar los alumnos que estan seleccionados
 * 
 * @param {*} state 
 * @returns 
 */
export const selectStudentSelected = (state) => state.noticesModalUI.distributinModes.groups.steps.students.selected;

/**
 * Consulta para saber si todos los elementos de alumnos estan seleccionados
 * 
 * @param {*} state 
 * @returns 
 */
export const selectStudentIsAllSelected = (state) => state.noticesModalUI.distributinModes.groups.steps.students.all;

/**
 * Consulta para recuperar el estado de operacion del paso grupos
 * 
 * @param {*} state 
 * @returns 
 */
export const selectOperationStatusStudentsStep = (state) => state.noticesModalUI.distributinModes.groups.steps.students.operationStatus;

////////////////////////// PADRES //////////////////////////////


/**
 * Consulta para recuperar informacion de los padres de los alumnos selecionados
 * 
 * @param {*} state 
 * @returns 
 */
export const selectParentsDataByStudentsSelected = createSelector([
    selectAllStudents, selectAllUsers, selectAllRelationships, selectStudentSelected
], (students, users, relationships, studentsSelected) => {

    let studentsSelectedSource = _.uniq(_.flatten(studentsSelected.map(i => i.students)))

    let parentsWithStudentsList = []

    for (const studentId of studentsSelectedSource) {
        let studentItem = students.find(i => i.student_id == studentId)
        let relationshipsItems = relationships.filter(i => i.student_id == studentId)

        let parentsItem = relationshipsItems.map(gs => users.find(p => p.user_id == gs.user_id))

        const formated = parentsItem.map(s => ({ student: studentItem, parent: s }))

        parentsWithStudentsList = parentsWithStudentsList.concat(formated)
    }

    return _.flatten(parentsWithStudentsList)
})


/**
 * Consulta para recuperar los padres que estan selecinados
 * 
 * @param {*} state 
 * @returns 
 */
export const selectParentsSelected = (state) => state.noticesModalUI.distributinModes.groups.steps.parents.selected;


/**
 * Consulta para recuperar el estado de operacion del paso grupos
 * 
 * @param {*} state 
 * @returns 
 */
export const selectOperationStatusParentsStep = (state) => state.noticesModalUI.distributinModes.groups.steps.parents.operationStatus;


/**
* Consulta para saber si todos los elementos de alumnos estan seleccionados
* 
* @param {*} state 
* @returns 
*/
export const selectParentsIsAllSelected = (state) => state.noticesModalUI.distributinModes.groups.steps.parents.all;



export default groupsModeSlice.reducer


///////////////////////////////// ENVIAR /////////////////////////////

/**
 * Consulta para recuperar informacion de los padres de los alumnos selecionados
 * 
 * @param {*} state 
 * @returns 
 */
export const selectDataTree = createSelector([
    selectGroupsSelected, selectStudentSelected, selectParentsSelected,
    selectAllGroups, selectAllUsers, selectAllStudents
], (groupsSelected, studentsSelected, parentsSelected, groups, parents, students) => {

    let tree = groupsSelected.map(groupId => {
        let group = groups.find(g => g.group_id == groupId)

        let studentsItems = _.flatten(studentsSelected.filter(studentItem => studentItem.group_id == groupId).map(s => s.students))
            .map(studentId => {
                let student = students.find(i => i.student_id == studentId)

                let parentsItems = _.flatten(parentsSelected.filter(parentItem => parentItem.student_id == studentId).map(s => s.parents))
                    .map(parentId => parents.find(i => i.user_id == parentId))

                return {
                    ...student,
                    parents: parentsItems
                }
            })

        return {
            ...group,
            students: studentsItems
        }
    })

    return tree
})

//////////////////////////// TRUNKS ////////////////////////////

/**
 * Cargamos todos los grupos 
 */
export const loadGroupsStepData = createAsyncThunk(
    'noticesModalUI/distributinModes/groups/step/groups',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let groups = await Connection.getGroupsWithStudents(schoolId).then(i => i.data.data)
            groups =  groups.filter((group) => group.group_type !== 4)
            thunkAPI.dispatch(upsertManyGroups(groups))

            return groups
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        let { didInvalidate } = getState().noticesModalUI.distributinModes.groups.steps.groups

        if (!didInvalidate) {
            return false
        }

    }
}
)

/**
 * Cargamos todos los grupos 
 */
export const loadStudentsStepData = createAsyncThunk(
    'noticesModalUI/distributinModes/groups/step/students',
    async (groups, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let studentGroups = []
            let allStudents = []

            for (const groupId of groups) {
                let students = await Connection.getStudentsHasRelationshipsByGroup(groupId).then(i => i.data.data)

                allStudents.push(students)

                const studentGroup = students.map(s => ({ group_id: groupId, student_id: s.student_id }))

                studentGroups = studentGroups.concat(studentGroup)
            }

            let studentFlatten = _.uniqBy(_.flatten(allStudents), 'student_id')

            thunkAPI.dispatch(upsertManyStudents(studentFlatten))
            thunkAPI.dispatch(addManyGroupsStudents(studentGroups))

            return {
                studentGroups,
                studentFlatten
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        let { didInvalidate } = getState().noticesModalUI.distributinModes.groups.steps.students

        if (!didInvalidate) {
            return false
        }

    }
}
)

/**
 * Cargamos todos los padres
 */
export const loadParentsStepData = createAsyncThunk(
    'noticesModalUI/distributinModes/groups/step/parents',
    async ({ studentIds, schoolId }, thunkAPI) => {
        let FeedbackService = new Feedback()
        let Auth = new Authentication()

        try {
            // RECUPERAR DATOS DEL SERVIDOR

            let allRelationships = await Connection.getResourcesPaginatedFromServer('getRelationshipsBySchool', [schoolId]).then(i => i.data)
            let allParents = await Connection.getResourcesPaginatedFromServer('getParentsBySchool', [schoolId]).then(i => i.data)

            // OBTENEMOS PARES Y RELACIONES DE LOS ALUMNOS SELECCIONADOS

            let relationships = allRelationships.filter(i => studentIds.find(r => r == i.student_id))
            let parents = allParents.filter(i => relationships.find(r => r.parent_id == i.parent_id))

            // DAMOS FORMATO A LLAVES DE USUARIO

            let customRelations = relationships.map(i => ({ ...i, user_id: i.parent_id }))
            let users = parents.map(i => ({ ...i, user_id: Auth.getUserID(i) }))

            thunkAPI.dispatch(addManyRelationships(customRelations))
            thunkAPI.dispatch(upsertManyUsers(users))

            return {
                users,
                customRelations
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        let { didInvalidate } = getState().noticesModalUI.distributinModes.groups.steps.parents

        if (!didInvalidate) {
            return false
        }

    }
}
)