import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import { selectPaymentConcepts } from './selectors';
import {
    setAllConcepts,
    upsertManyConcepts,
} from '../../../store/slices/entities/payments/concepts';
import { upsertManyCataloConcepts } from '../../../store/slices/entities/payments/cat_concepts';
import { upsertManyStudents } from '../../../store/slices/entities/students';
import { upsertManyDiscounts } from '../../../store/slices/entities/payments/discounts';
import { upsertManySurcharges } from '../../../store/slices/entities/payments/surcharges';
import { upsertManyPayments } from '../../../store/slices/entities/payments/income';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../service/const';

// Estado inicial del slice
const emptyState = {
    expireIn: null,
    fetchingAt: null,
    status: 'idle',
    operation: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

// Slice para los descuentos de pago
const ConceptsSlice = createSlice({
    name: 'Concepts',
    initialState: emptyState,
    reducers: {
        // Acción para invalidar los datos actuales
        invalidate: (state, action) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        builder
            // Manejo del estado pendiente de la petición
            .addCase(fetchConcepts.pending, (state) => {
                state.status = 'pending';
            });

        builder
            .addCase(fetchConcepts.fulfilled, (state, action) => {
                state.expireIn = new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                );
                state.fetchingAt = Date.now();
                state.didInvalidate = false;

                state.status = 'fulfilled';
            })
            // Manejo del estado rechazado de la petición
            .addCase(fetchConcepts.rejected, (state, action) => {
                state.status = 'rejected';
                state.feedback = action.payload.feedback;
            });

        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });
    },
});

export const { invalidate: invalidateConcepts } = ConceptsSlice.actions;

export default ConceptsSlice.reducer;

/////////////////////////////////////////////// TRUNKS ///////////////////////////////////////////////

export const fetchConcepts = createAsyncThunk(
    'Concepts/fetch',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            /* Se obtienen los conceptos asociados a la escuela */

            const [responseConcepts, responseCatConcepts, responseStudents] =
                await Promise.all([
                    Connection.getConceptsBySchool(schoolId),
                    Connection.getSchoolsByConceptsCatalog(schoolId),
                    Connection.StudentsBySchool(schoolId),
                ]);

            const concepts = responseConcepts.data.data;
            const concept_catalog = responseCatConcepts.data.data;
            const students = responseStudents.data.data;

            thunkAPI.dispatch(upsertManyConcepts(concepts));
            thunkAPI.dispatch(upsertManyCataloConcepts(concept_catalog));
            thunkAPI.dispatch(upsertManyStudents(students));

            return {
                concept_catalog,
                concepts,
                students,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        // Condición para ejecutar el thunk solo si es necesario
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectConceptsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
//////////////////////////////////// SELECTORES ////////////////////////////////////
/**
 * Selector para recuperar el estado de la petición de conceptos de pago
 */
export const selectConceptsFetch = createSelector(
    selectPaymentConcepts,
    (state) => state.fetch
);
