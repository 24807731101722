import { createSelector } from '@reduxjs/toolkit';
import { selectCatDiscounts } from '../cat_discounts/selectors';
import { selectConcepts } from '../concepts/selectors';
import {
    selectStudentById,
    selectStudents,
} from '../../../store/slices/entities/students';
import { selectCatConcepts } from '../cat_concepts/selectors';

/**
 * Selector para recuperar home de reportes
 */
export const selectPaymentDiscounts = (state) => state.paymentsModule.discounts;

export const selectDiscounts = (state) =>
    Object.values(state.entities.discounts.entities);

/**
 * Selector para recuperar los datos de la tabla
 */

export const selectTableData = createSelector(
    [
        selectDiscounts,
        selectCatDiscounts,
        selectConcepts,
        selectCatConcepts,
        selectStudents,
    ],
    (discounts, cat_Discounts, concepts, catConcepts, student) => {
        return discounts.map((discounts) => {
            const catDiscounts = cat_Discounts.find(
                (catDiscounts) =>
                    discounts.discount_catalog_id ===
                    catDiscounts.discount_catalog_id
            );

            const conceptsArray = Object.values(concepts);
            const studentsArray = Object.values(student);

            const concept = conceptsArray.find(
                (concepts) => concepts.concept_id === discounts.concept_id
            );

            const user = studentsArray.find(
                (student) => concept.student_id === student?.student_id
            );

            const catConcept = catConcepts.find(
                (catConcept) =>
                    catConcept.concept_catalog_id === concept.concept_catalog_id
            );

            return {
                ...discounts,
                catDiscounts,
                concept,
                catConcept,
                user,
            };
        });
    }
);

export const studentsWithConcepts = (studentId) =>
    createSelector(
        [selectStudentById(studentId), selectConcepts],
        (student, concepts) => {
            if (!student) return null;
            const conceptsArray = Object.values(concepts);

            // Filtramos los conceptos del estudiante
            const studentConcepts = conceptsArray.filter(
                (concept) => concept.student_id === student.student_id
            );

            return {
                concepts: studentConcepts.map((concept) => ({
                    ...concept,
                })),
            };
        }
    );
