import React, { useEffect } from 'react'
import { Box, Stack, Container, TextField, Button, Typography, Snackbar, Backdrop, CircularProgress, Card, CardContent, CardHeader, CardActions } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { selectStatusOperation, updateUserUsername } from '../../../store/slices/auth'
import { useAuth } from '../../../hooks';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import useFeedback from '../../../hooks/useFeedback';

/**
 * Componente para cambiar el nombre de usuario
 * 
 * @returns 
 */
const ChangeUsername = () => {
    const Auth = useAuth()
    const dispatch = useDispatch();
    const feedbackApp = useFeedback()

    ////////////// LOCAL STATE ////////////////////

    const [usernameData, updateUsernameData] = useState({
        cellphone: ''
    })

    const [state, setState] = useState('idle')

    ////////////// SHARES STATE /////////////////////

    const statusOperation = useSelector(selectStatusOperation)

    /**
     * Efecto para escuchar el estado de la operacion
     */
    useEffect(() => {
        if ((statusOperation == 'rejected' || statusOperation == 'fulfilled') && state == 'pending') {
            setState('idle')
        }
    }, [statusOperation])

    /**
     * Actualziacion del nombre de usuario
     * 
     * @param {*} event 
     */
    const onSubmitUsername = (event) => {
        setState('pending')
        dispatch(updateUserUsername({
            userId: Auth.getUserID(),
            usernameData
        }))
            .unwrap()
            .then(response => {
                feedbackApp.showFeedback({
                    title: "Actualizado correctamente"
                })

                updateUsernameData({
                    cellphone: ''
                })
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     * 
     * @param {*} event 
     */
    function onInputUsernameChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        let oldState = {
            cellphone: usernameData.cellphone,
            close_all_sessions: usernameData.close_all_sessions,
        }

        oldState[name] = value

        updateUsernameData(oldState);
    }

    ///////////////////// VALIDACIONES PARA NOMBRE DE USUARIO ////////////////

    const usernameValidation = () => {
        const phone = usernameData.cellphone

        return {
            invalid: !(/^[0-9]{10}$/.test(phone)),
            message: "Formato incorrecto"
        }
    }

    return (
        <Box>
            <Card>
                <CardHeader
                    title="Cambiar usuario"
                    titleTypographyProps={{
                        sx: {
                            fontSize: 20
                        }
                    }}
                />
                <CardContent>
                    <TextField
                        id="cellphone"
                        name="cellphone"
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        onChange={onInputUsernameChange}
                        value={usernameData.cellphone}
                        error={usernameValidation().invalid}
                        helperText={
                            usernameValidation().invalid ? usernameValidation().message :
                              `Caracteres disponibles: ${(usernameData?.cellphone) ? usernameData.cellphone.length : 0}/10`
                          }
                        label="Teléfono"
                        variant="outlined"
                    />
                </CardContent>
                <CardActions
                    sx={{
                        flexDirection: "row",
                        justifyContent: "flex-end"
                    }}
                >
                    <LoadingButton
                        size="small"
                        color="primary"
                        onClick={onSubmitUsername}
                        loading={state == 'pending'}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        disabled={usernameValidation().invalid}
                    >
                        Cambiar
                    </LoadingButton>

                </CardActions>
            </Card>
        </Box>
    )
}

export default ChangeUsername