import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography,
    Box,
    Grid,
    Card,
    CardContent,
    Avatar,
} from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectProfessorsWithoutGroup,
} from '../../../store/professors/fetchSlice';
import { getProfessorsWithoutGroup } from '../../../store/professors/thunks';
import { selectComponentProfessorsWithoutGroupStatusServer } from '../../../store/professors/componentsStatusSelectors';
import Error from '../../../../components/Feedback/Error';
import { SkeletonProfessorsSmallTables } from '../../../components/Skeleton';

const ProfessorsWithoutGroup = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentProfessorsWithoutGroupStatusServer
    );

    const professorsWithoutGroup = useFetchResource(
        () =>
            getProfessorsWithoutGroup({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectProfessorsWithoutGroup,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getProfessorsWithoutGroup({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectProfessorsWithoutGroup,
            [cycleSelected, schoolId]
        );
    };

    const rows = Array.isArray(professorsWithoutGroup.value?.Profesor)
        ? professorsWithoutGroup?.value.Profesor.map((profesor, index) => ({
              id: index + 1,
              profesor: profesor.value,
              fotografia:
                  professorsWithoutGroup?.value.Fotografía[index]?.value || '',
          }))
        : [];

    const columns = [
        {
            field: 'fotografia',
            headerName: 'Fotografía',
            disableExport: true,
            renderCell: (params) => (
                <Avatar
                    alt={params.row.profesor}
                    src={params.value}
                    sx={{ width: 40, height: 40 }}
                />
            ),
            sortable: false,
            width: 80,
        },
        {
            field: 'profesor',
            headerName: 'Profesor',
            flex: 1,
            renderCell: (params) => (
                <Typography
                    variant="h7"
                    sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
                >
                    {params.value}
                </Typography>
            ),
        },
    ];

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbar {...props} />
            </GridToolbarContainer>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonProfessorsSmallTables items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                    }}
                >
                    <CardContent sx={{ width: '100%', padding: '24px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                    gutterBottom
                                >
                                    {professorsWithoutGroup.title ||
                                        'Profesores sin grupo'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ height: 710, width: '100%' }}>
                                    <DataGrid
                                        localeText={
                                            esES.components.MuiDataGrid
                                                .defaultProps.localeText
                                        }
                                        rows={rows}
                                        columns={columns}
                                        pageSize={10}
                                        rowsPerPageOptions={[]}
                                        disableDensitySelector
                                        disableSelectionOnClick
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                        componentsProps={{
                                            panel: {
                                                placement: 'bottom-end',
                                            },
                                        }}
                                        sx={{
                                            '&.MuiDataGrid-root': {
                                                border: 'none',
                                            },
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default ProfessorsWithoutGroup;
