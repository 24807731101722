import React, { useState } from 'react';
import { Box, Grid, Divider, Typography } from '@mui/material';
import BestAverageLevel from './BestAverageLevel';
import WorstAverageLevel from './WorstAverageLevel';
import MostInassistLevel from './MostInassistLevel';
import MostReportLevel from './MostReportLevel';

const LevelIndicators = ({ levelName }) => {
    const [dataAvailable, setDataAvailable] = useState({});

    const handleDataAvailable = (level) => {
        setDataAvailable((prevState) => ({ ...prevState, [level]: true }));
    };

    const shouldShowDivider = Object.values(dataAvailable).some(Boolean);
    return (
        <Box>
            {shouldShowDivider ? (
                <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="h5">
                        <span>{levelName.toUpperCase()}</span>
                    </Typography>
                </Divider>
            ) : null}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={6}>
                    <BestAverageLevel
                        level={levelName}
                        onDataAvailable={() => handleDataAvailable(levelName)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={6}>
                    <WorstAverageLevel
                        level={levelName}
                        onDataAvailable={() => handleDataAvailable(levelName)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={6}>
                    <MostInassistLevel
                        level={levelName}
                        onDataAvailable={() => handleDataAvailable(levelName)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={6}>
                    <MostReportLevel
                        level={levelName}
                        onDataAvailable={() => handleDataAvailable(levelName)}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default LevelIndicators;
