import { createSelector } from '@reduxjs/toolkit';

import {
    selectAllUsers,
    selectParents,
} from '../../../store/slices/entities/users';
import {
    selectStudentById,
    selectStudents,
} from '../../../store/slices/entities/students';
import { flatMap, groupBy } from 'lodash';
import { selectPartialities } from '../partialities/selectors';
import { selectConcepts } from '../concepts/selectors';
import { selectAgreements } from '../agreements/selectors';

/**
 * Selector para recuperar home de reportes
 */
export const selectPaymentIncome = (state) => state.paymentsModule.income;

/**
+ * Selector para recuperar los pagos
+ */
export const selectIncome = (state) => state.entities.income.entities;

/**
 *  selector para recuperar los usuarios
 */
export const selectCatalogConcepts = (state) =>
    state.entities.cat_concepts.entities;

export const selectTableData = createSelector(
    [
        selectStudents,
        selectIncome,
        selectCatalogConcepts,
        selectConcepts,
        selectAgreements,
    ],
    (users, payments, catalogConcepts, concepts, agreements) => {
        const paymentsArray = Object.values(payments);

        const agreementsObject = agreements.reduce((acc, agreement) => {
            acc[agreement.concept_id] = agreement;
            return acc;
        }, {});

        return paymentsArray.map((payment) => {
            const user = users[payment.user_id];
            const concept = concepts[payment.concept_id];

            const catalogConcept = catalogConcepts[concept.concept_catalog_id];

            const agreement = agreementsObject[concept.concept_id] || null;

            return {
                ...payment,
                user,
                catalogConcept,
                concept,
                agreement,
            };
        });
    }
);
export const selectStudentsWithPendingConcepts = createSelector(
    [selectStudents, selectConcepts],
    (students, concepts) => {
        const pendingConcepts = Object.values(concepts).filter(
            (concept) => concept.status === 2
        );

        const studentIdsWithPendingConcepts = new Set(
            pendingConcepts.map((concept) => concept.student_id)
        );

        return Object.values(students).filter((student) =>
            studentIdsWithPendingConcepts.has(student.student_id)
        );
    }
);

export const selectConceptsByStudent = createSelector(
    [selectConcepts, selectCatalogConcepts, (state, studentId) => studentId],
    (concepts, catConcept, studentId) => {
        return Object.values(concepts)
            .filter((concept) => concept.student_id === studentId)
            .filter((concept) => concept.status === 2)
            .map((concept) => {
                return {
                    ...concept,
                    catalogConcept: catConcept[concept.concept_catalog_id],
                };
            });
    }
);

export const selectPartialitiesByConcept = createSelector(
    [selectPartialities, (state, conceptId) => conceptId],
    (partialities, conceptId) => {
        return Object.values(partialities).filter(
            (partiality) => partiality.concept === conceptId
        );
    }
);
