import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Connection from '../../../../service/Connection';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../../service/const';

const initialState = {
    Last_Student_With_Discount: {
        fetch: {
            expireIn: null,
            fetchingAt: null,
            status: 'idle',
            operation: 'idle',
            didInvalidate: true,
            feedback: {
                title: null,
                message: null,
                payload: null,
            },
        },
        data: {},
    },
};

const cardsSlice = createSlice({
    name: 'Cards',
    initialState,
    reducers: {
        invalidate: (state) => {
            state.didInvalidate = true;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(
            fetchCardLastStudentWithDiscount.fulfilled,
            (state, action) => {
                state.Last_Student_With_Discount.fetch = {
                    ...state.Last_Student_With_Discount.fetch,
                    expireIn: new Date().setMinutes(
                        new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                    ),
                    fetchingAt: Date.now(),
                    status: 'fulfilled',
                    didInvalidate: false,
                };


                state.Last_Student_With_Discount.data =
                    action.payload.data.components;
            }
        );
        builder.addCase(fetchCardLastStudentWithDiscount.pending, (state) => {
            state.Last_Student_With_Discount.fetch.status = 'pending';
        });
        builder.addCase(fetchCardLastStudentWithDiscount.rejected, (state, action) => {
            state.Last_Student_With_Discount.fetch.status = 'rejected';

        });
    },
});

export const { invalidate: invalidateCards } = cardsSlice.actions;

export default cardsSlice.reducer;

/////////  THUNKS  ////////////

export const fetchCardLastStudentWithDiscount = createAsyncThunk(
    'fetch/cardLastStudentWithDiscount',
    async (schoolId) => {
        const response = await Connection.getPaymentsInformation({
            school_id: schoolId,
            data: {
                page: 'home',
                type: 'card',
                idKey: 'last_student_with_discount',
            },
        });
        return response.data;
    },
    {
        condition: (schoolId, { getState }) => {
            const { didInvalidate, expireIn } = selectCardFetchState(getState());
            const now = Date.now();

            // Si el estado no está invalidado y el tiempo de expiración no ha pasado, no hacer la petición
            if (!didInvalidate && expireIn && expireIn > now) {
                return false;
            }

            return true; // Permite la petición si el estado está invalidado o ha expirado
        },
    }
);
///////////// SELECTORS  ////////////

export const selectCardsData = (state) =>
    state.paymentsModule.home.cards.data;

export const selectCardsStatus = (state) =>
    state.paymentsModule.home.cards.fetch.status;

export const selectCardFetchState = (state) =>
    state.paymentsModule.home.cards.Last_Student_With_Discount.fetch;
