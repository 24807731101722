import { createSelector, createSlice } from '@reduxjs/toolkit';
import { selectedNoticesUI } from './selectors';

const emptyState = {
    noticeSelected: null,
    filters: {},
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState: emptyState,
    reducers: {
        noticeSelected: (state, { payload }) => {
            state.noticeSelected = payload;
        },
        updateHomeFilters: (state, { payload }) => {
            const { filter } = payload;
            state.filters = { ...filter };
        },
    },

    extraReducers: (builder) => {
        builder.addCase('app/clear', () => {
            return emptyState;
        });
    },
});

export const { noticeSelected, updateHomeFilters } = uiSlice.actions;

export default uiSlice.reducer;

///////////// SELECTORS ////////////////

export const selectSelectedNoticeUI = createSelector(
    selectedNoticesUI,
    (ui) => {
        return ui.noticeSelected;
    }
);

export const selectHomeNoticesFilter = createSelector(
    selectedNoticesUI,
    (ui) => {
        return ui.filters;
    }
);
