import enviroment from './Service';
import axios from 'axios';

/**
 * Clase que funciona como envoltorio para el cliente HTTP
 *
 */
export default class ClientHttp {
    client = null;
    uiClient = null;
    token = null;

    /**
     * En la contruccion de esta clase el cliente tomara
     * datos de la enviroments que se encuentren por defecto
     * al correo el bundle
     */
    constructor() {
        this.client = axios.create({
            baseURL: enviroment.RUTA_API + enviroment.VERSION,
            proxy: false,
        });

        this.uiClient = axios.create({
            baseURL:
                enviroment.RUTA_API + enviroment.RUTA_UI + enviroment.VERSION,
            proxy: false,
        });
        this.clientV2 = axios.create({
            baseURL: enviroment.RUTA_API + enviroment.VERSION_2,
            proxy: false,
        });

        this.clientHistorical = axios.create({
            baseURL:
                enviroment.RUTA_API +
                enviroment.VERSION +
                enviroment.RUTA_HISTORIC,
        });

        this.interceptorsRequest();
    }

    /**
     * Retorna el objeto http nativo
     *
     * @returns
     */
    getClient() {
        return this.client;
    }

    get(url, config) {
        return this.client.get(url, config);
    }

    delete(url, config) {
        return this.client.delete(url, config);
    }

    post(url, data, config) {
        return this.client.post(url, data, config);
    }

    put(url, data, config) {
        return this.client.put(url, data, config);
    }

    patch(url, data, config) {
        return this.client.patch(url, data, config);
    }

    // Métodos para uiClient (UI API)
    getUI(url, config) {
        return this.uiClient.get(url, config);
    }

    deleteUI(url, config) {
        return this.uiClient.delete(url, config);
    }

    postUI(url, data, config) {
        return this.uiClient.post(url, data, config);
    }

    putUI(url, data, config) {
        return this.uiClient.put(url, data, config);
    }

    patchUI(url, data, config) {
        return this.uiClient.patch(url, data, config);
    }

    // Métodos para client V2
    getV2(url, config) {
        return this.clientV2.get(url, config);
    }

    deleteV2(url, config) {
        return this.clientV2.delete(url, config);
    }

    postV2(url, data, config) {
        return this.clientV2.post(url, data, config);
    }

    putV2(url, data, config) {
        return this.clientV2.put(url, data, config);
    }

    patchV2(url, data, config) {
        return this.clientV2.patch(url, data, config);
    }

    //Métodos para peticiones historical
    getHistorical(url, config) {
        return this.clientHistorical.get(url, config);
    }

    postHistorical(url, config) {
        return this.clientHistorical.post(url, config);
    }

    /**
     * Remplaza el token actual por el enviado por parametro
     *
     * @param token Token a usar por el cliente
     */
    setToken(token) {
        this.token = token;

        this.client.defaults.headers.common['Authorization'] =
            'Bearer ' + this.token.getToken()?.access_token;

        this.uiClient.defaults.headers.common['Authorization'] =
            'Bearer ' + this.token.getToken()?.access_token;

        this.clientV2.defaults.headers.common['Authorization'] =
            'Bearer ' + this.token.getToken()?.access_token;

        this.clientHistorical.defaults.headers.common['Authorization'] =
            'Bearer ' + this.token.getToken()?.access_token;
    }

    /**
     * Hacer que las peticiones se relicen sobre el dominio
     */
    useDomain() {
        this.client = axios.create({
            baseURL: enviroment.RUTA_API,
            proxy: false,
        });

        this.interceptorsRequest();
    }

    /**
     * Hacer que las peticiones se relicen sobre el dominio
     */
    makeAPI(version = null) {
        version = version ? version : enviroment.VERSION;

        this.client = axios.create({
            baseURL: enviroment.RUTA_API + version,
            proxy: false,
        });

        this.interceptorsRequest();
    }

    interceptorsRequest = () => {
        this.client.interceptors.request.use((config) => {
            //console.log(config)
            return config;
        });

        this.uiClient.interceptors.request.use((config) => {
            return config;
        });

        this.clientV2.interceptors.request.use((config) => {
            return config;
        });

        this.clientHistorical.interceptors.request.use((config) => {
            return config;
        });
    };
}
