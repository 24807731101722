import { Box, Button, Container, Stack, Typography } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import LinkIcon from '@mui/icons-material/Link';
import { selectNoticeData } from '../../store/slices/noticesModalUI/uiSlice';
import { useSelector } from 'react-redux';

/**
 * Componente que previsualiza la noticia
 *
 * @returns
 */
const PreviewNotice = ({}) => {
    /**
     * Elementos de los pasos que ha sido seleccionados
     */
    let notice = useSelector(selectNoticeData);

    return (
        <Box sx={{ maxWidth: 300, textAlign: 'center' }}>
            <Typography variant="h5">{notice.title}</Typography>
            <Typography variant="h6">{notice.description}</Typography>
            {notice.url && (
                <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="primary"
                    href={notice.url}
                    target="_blank"
                    size="small"
                    startIcon={<LinkIcon />}
                >
                    Visitar
                </Button>
            )}
        </Box>
    );
};

export default PreviewNotice;
