import { createSelector } from '@reduxjs/toolkit';
import { selectGroupsStudentsByStudentId } from './groups_students';
import { selectAllPartials } from './partials';
import { selectAllSubjects, selectSubjectsByGroupId } from './subjects';
import { selectAllSubjectcatalogs } from './subjectcatalogs';
import { selectAllGroupsEntities } from './groups';
import { selectAllFiles } from './files';
import { selectModulesByGroup } from './modules';
import _ from 'lodash';

/**
 * Selector para recuperar los parciales que esta cursando un alumno
 */
export const selectPartialsByStudent = (studentId) =>
    createSelector(
        selectAllPartials,
        selectGroupsByStudent(studentId),
        (partials, groups) => {
            return partials.filter(
                (partial) => partial.level == groups[0].level
            );
        }
    );

/**
 * Selector para recuperar todas las materias de un alumno
 */
export const selectSubjectsByStudent = (studentId) =>
    createSelector(
        selectAllSubjects,
        selectGroupsStudentsByStudentId(studentId),
        (allSubjects, groupsStudents) => {
            return allSubjects.filter((subject) =>
                groupsStudents.some(
                    (group) => group.group_id == subject.group_id
                )
            );
        }
    );

/**
 * Selector para recupera el catalogo de materias por alumno
 *
 * este selector va atravez de la tabla de StudentGroup
 */
export const selectSubjectsCatalogByStudent = (studentId) =>
    createSelector(
        selectAllSubjectcatalogs,
        selectGroupsByStudent(studentId),
        (subjectCatalogs, groups) => {
            return subjectCatalogs.filter(
                (subjectCatalog) => subjectCatalog.level == groups[0].level
            );
        }
    );

/**
 * Selector para recuperar todos los GroupsStudents por alumno
 */
export const selectGroupsByStudent = (studentId) =>
    createSelector(
        selectAllGroupsEntities,
        selectGroupsStudentsByStudentId(studentId),
        (groupsEntities, groupsStudents) => {
            return Object.values(groupsEntities).filter((group) =>
                groupsStudents.some(
                    (groupStudent) => group.group_id == groupStudent.group_id
                )
            );
        }
    );

/**
 * Selector para recuperar expedientes por id del grupo
 */
export const selectFilesByGroup = (groupId) =>
    createSelector(selectAllFiles, (files) => {
        return files.filter((file) => file.group_id === Number(groupId));
    });

export const selectMainAndModuleSubjectsByMainGroup = (groupId) =>
    createSelector(
        selectModuleSubjectByMainGroup(groupId),
        selectSubjectsByGroupId(groupId),
        (moduleSubjects, mainSubjects) => {
            return [...moduleSubjects, ...mainSubjects];
        }
    );

export const selectModuleSubjectByMainGroup = (groupId) =>
    createSelector(
        selectModulesByGroup(groupId),
        selectAllSubjects,
        (modules, allSubjects) => {
            return _.chain(modules)
                .map((module) => {
                    return _.filter(allSubjects, ['group_id', module.group_id]);
                })
                .flatten()
                .value();
        }
    );

export const selectModulesWithSubjectsByModule = (moduleId) =>
    createSelector(
        selectModulesByGroup(moduleId),
        selectAllSubjects,
        selectAllSubjectcatalogs,
        (modules, allSubjects, catalogs) => {
            return _.chain(modules)
                .map((module) => {
                    return {
                        ...module,
                        subjectsItems: _.chain(allSubjects)
                            .filter(['group_id', module.group_id])
                            .map((subject) => {
                                return {
                                    subject,
                                    catalog: _.find(catalogs, [
                                        'catalog_subject_id',
                                        subject.catalog_subject_id,
                                    ]),
                                };
                            })
                            .value(),
                    };
                })
                .flatten()
                .value();
        }
    );
