/**
 * Selector para recuperar home de avisos
 */
export const selectNoticesHomework = (store) => store.notices.homework;

/**
 * Recuperamos la ui de home
 */
export const selectedHomeworkNoticesUI = (state) =>
    state.notices.homework.ui;
