import { Typography } from '@mui/material';
import DnsIcon from '@mui/icons-material/Dns';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import PersonIcon from '@mui/icons-material/Person';

const ScoreNotEditableCell = ({ value, minScore, row }) => {
    let color;

    const currentDate = new Date();

    if (value !== 0) {
        color = value < minScore ? 'red' : 'green';
    }

    // if (
    //     row.partialItem &&
    //     row.partialItem.start_date &&
    //     row.partialItem.limit_date
    // ) {
    //     const startDate = new Date(row.partialItem.start_date);
    //     const endDate = new Date(row.partialItem.limit_date);

    //     if (currentDate < startDate && value === 0) {
    //         color = 'black';
    //     } else {
    //         color = 'white';
    //     }
    // }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                gap: '8px',
            }}
        >
            {row.calificationItem?.updated_by === 2 && (
                <DnsIcon
                    sx={{
                        color: '#0a90d3',
                    }}
                />
            )}
            {row.calificationItem?.updated_by !==
                row.calificationItem?.created_by && (
                <LaptopChromebookIcon
                    sx={{
                        color: '#ffbf43',
                    }}
                />
            )}
            {row.calificationItem?.updated_by ===
                row.calificationItem?.created_by && (
                <PersonIcon
                    sx={{
                        color: '#grey',
                    }}
                />
            )}
            <Typography sx={{ color }}>{value == null ? '' : value}</Typography>
        </div>
    );
};

export default ScoreNotEditableCell;
