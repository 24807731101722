import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Feedback from '../../../service/Feedback';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import { selectPaymentAgreements } from './selectors';
import Connection from '../../../service/Connection';
import {
    addManyAgreements,
    upsertManyAgreements,
} from '../../../store/slices/entities/payments/agreements';
import { flatMap } from 'lodash';
import { upsertManyConcepts } from '../../../store/slices/entities/payments/concepts';

const emptyState = {
    create: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    update: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    delete: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
};

export const operationsSlice = createSlice({
    name: 'operations/Agremments',
    initialState: emptyState,
    reducers: {},

    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        //////////////// Registro de un convenio ///////////T///////////

        builder.addCase(CreateAgreements.rejected, (state, action) => {
            state.create.status = 'rejected';
            state.create.feedback = action.payload.feedback;
        });
        builder.addCase(CreateAgreements.fulfilled, (state) => {
            state.create.status = 'fulfilled';
        });
        builder.addCase(CreateAgreements.pending, (state) => {
            state.create.status = 'pending';
        });

        //////////////// Eliminacion de un convenio  ///////////T///////////

        builder.addCase(DeleteAgreements.rejected, (state, action) => {
            state.delete.status = 'rejected';
            state.delete.feedback = action.payload.feedback;
        });
        builder.addCase(DeleteAgreements.fulfilled, (state) => {
            state.delete.status = 'fulfilled';
        });
        builder.addCase(DeleteAgreements.pending, (state) => {
            state.delete.status = 'pending';
        });

        //////////////// actualizacion de un convenio  ///////////T///////////

        builder.addCase(Updategreements.rejected, (state, action) => {
            state.update.status = 'rejected';
            state.update.feedback = action.payload.feedback;
        });
        builder.addCase(Updategreements.fulfilled, (state) => {
            state.update.status = 'fulfilled';
        });
        builder.addCase(Updategreements.pending, (state) => {
            state.update.status = 'pending';
        });
    },
});

//export const {} = operationsSlice.actions;

export default operationsSlice.reducer;

export const selectOperations = createSelector(
    selectPaymentAgreements,
    (reports) => reports.operations
);

export const selectCreate = createSelector(
    selectOperations,
    (operations) => operations.create
);

export const selectCreateOperation = createSelector(
    selectCreate,
    (create) => create.status
);

export const selectStatusOperation = (state) => state.statusOperation;

/**
 * Agregar un nuevo Pago
 */

export const CreateAgreements = createAsyncThunk(
    'Agreements/create',
    async ({ concept_id, student_id, data, schoolId }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.addAgreement(
                concept_id,
                student_id,
                data
            );

            const Agreements = response.data;

            const responseConcepts =
                await Connection.getConceptsBySchool(schoolId);

            const Concepts = responseConcepts.data.data;

            thunkAPI.dispatch(upsertManyConcepts(Concepts));
            thunkAPI.dispatch(addManyAgreements(Agreements));

            return { Agreements };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Eliminar un catalogo de conceptos
 */

export const DeleteAgreements = createAsyncThunk(
    'Agreements/delete',
    async ({ agreement_id }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.deleteAgreement(agreement_id);
            const Agreements = response.data;
            thunkAPI.dispatch(upsertManyAgreements(Agreements));

            return { Agreements };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
/**
 * Actualizar un catalogo de conceptos
 */

export const Updategreements = createAsyncThunk(
    'Agreements/update',
    async ({ schoolId, data }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
