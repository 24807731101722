import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import './styles.scss';
import MonthlyIncome from './Components/MonthlyIncome';
import DiscountConceptsCard from './Components/DiscountConceptsCard';
import ToPayCard from './Components/ToPayCard';
import GainsCard from './Components/GainsCard';
import TopTransactionsCard from './Components/TopTransactions';
import WelcomeCard from './Components/WelcomeCard';
import DebtorTable from './Components/DebtorTable';
import { TopStudentsDiscount } from './Components/TopStudentsDiscount';
import TabsComponent from '../../components/tabs';

const DashboardPayments = () => {
    const [currentTab, setCurrentTab] = useState('inicio');
    const [loading, setLoading] = useState(false);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <>
            <TabsComponent
                currentTab={currentTab}
                onTabChange={handleTabChange}
                setLoading={setLoading}
            />
            <Box
                sx={{
                    px: {
                        xs: 2, 
                        sm: 2, 
                        md: 7,
                    },
                    paddingBottom: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },
                }}>
                {!loading && (
                    <Grid container spacing={4}>
                        {/* Welcome Card */}
                        <Grid item xs={12} md={7} sx={{ display: 'flex' ,alignItems: 'stretch', mt:7}}>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <WelcomeCard />
                            </Box>
                        </Grid>

                        {/* Revenue and Transactions */}
                        <Grid item xs={12} sm={6} md={2.5} sx={{ mb: 4, mt:2 }}>
                            <GainsCard />
                        </Grid>

                        <Grid item xs={12} sm={6} md={2.5} sx={{ mb: 4, mt:2 }}>
                            <ToPayCard />
                        </Grid>

                        {/* Transactions, New Registrations, and Discount Concepts */}
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', alignItems: 'stretch', mb: 4, mt:2 }}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <TopTransactionsCard sx={{ height: '100%' }} />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', alignItems: 'stretch', mb: 4, mt:2 }}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <MonthlyIncome sx={{ height: '100%' }} />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', alignItems: 'stretch', mb: 4 , mt:2}}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <DiscountConceptsCard sx={{ height: '100%' }} />
                                </Box>
                            </Grid>
                        </Grid>

                        {/* Debtor Table and Top Students Discount */}
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={8} sx={{ mb: 4, mt:2 }}>
                                <DebtorTable />
                            </Grid>

                            <Grid item xs={12} md={4} sx={{ mb: 4, mt:2 }}>
                                <TopStudentsDiscount />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </>
    );
};

export default DashboardPayments;
