import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    Button,
    Stack,
    DialogActions,
    Snackbar,
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../hooks';
import TextsmsIcon from '@mui/icons-material/Textsms';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { selectStatusOperation } from '../../store/slices/noticesModule/selectors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {
    selectTypeView,
    selectDistributionMode,
    setDistributionMode,
    setTypeView,
    changeFormData,
    selectNoticeData,
} from '../../store/slices/noticesModalUI/uiSlice';
import TitleComponent from './TitleComponent';
import NoticeEditor from './NoticeEditor';
import SelectMode from './SelectMode';
import {
    noticesModalDistributinModesReset,
    noticesModalUIReset,
} from '../../store/actions';
import GroupsDistributionMode from './GroupsDistributionMode';
import StudentsDistributionMode from './StudentsDistributionMode';
import UsersDistributionMode from './UsersDistributionMode';

/**
 * Modal para poder enviar notificaciones
 *
 * @param {*} param0
 *
 * @returns
 */
const DistributionNoticeModal = ({ openModal, setOpenModal }) => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;

    ///////////////////// SHARED STATE //////////////////////

    //const statusOperation = useSelector(selectStatusOperation)
    const operationState = useSelector(selectStatusOperation);

    const typeView = useSelector(selectTypeView);
    const distributionMode = useSelector(selectDistributionMode);

    /**
     * Elementos de los pasos que ha sido seleccionados
     */
    let noticeTrue = useSelector(selectNoticeData);

    ///////////////////// LOCAL STATE ///////////////////////

    const [notice, setNotice] = useState(noticeTrue);

    /////////////////// FUNCTIONS ///////////////////////////

    /**
     * Efecto lanzado cada ves que se abre el modal
     */
    useEffect(() => {
        if (openModal) {
            setNotice(noticeTrue);
        }
    }, [openModal]);

    /**
     * Cambiar el tipo de vista
     */
    const onChangeTypeView = () => {
        dispatch(changeFormData(notice));
        dispatch(setTypeView(typeView == 1 ? 2 : 1));
    };

    /**
     * Cerrar el modal
     */
    const onCloseModal = () => {
        dispatch(noticesModalUIReset());
        dispatch(noticesModalDistributinModesReset());
        setOpenModal(false);
    };

    ///////////////////   VALIDACIONES    ///////////////////////

    /**
     * Identifica si el editor esta completo
     *
     * @returns
     */
    const isinvalidForm = () => {
        const isTitleInvalidate = notice.title == '' || notice.title == null;
        const isDescripctionInvalidate =
            notice.description == '' || notice.description == null;
        const isUrlInvalidate =
            notice.url !== '' &&
            notice.url !== null &&
            !/^(http|https):\/\/[^ "]+$/.test(notice.url);

        return isTitleInvalidate || isDescripctionInvalidate || isUrlInvalidate;
    };

    /**
     * Cerrar el modo de distribucion actual
     */
    const onCloseDistributionMode = () => {
        changeDistributionMode(null);
    };

    /**
     * Cambiar el tipo de modo de distribucion
     *
     * @param {*} mode
     */
    const changeDistributionMode = (mode) => {
        dispatch(setDistributionMode(mode));
    };

    /**
     * Cambio el contenido de la noticia
     */
    const onChangeNotice = (value) => {
        setNotice(value);
    };

    /**
     * Funciona ejecutad si que se creo una noticia
     */
    const onSaveNotice = (title) => {
        showFeedback(title);
        setOpenModal(false);
        onCloseModal();
    };

    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message);
        setOpenFeedback(true);
    };

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        dispatch(noticesModalUIReset());
        dispatch(noticesModalDistributinModesReset());
        setOpenModal(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    return (
        <>
            <Dialog
                open={openModal}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
            >
                <TitleComponent
                    typeView={typeView}
                    distributionMode={distributionMode}
                />
                <DialogContent dividers>
                    <Box sx={{ margin: 0 }}>
                        {typeView == 1 && (
                            <NoticeEditor
                                value={notice}
                                onChange={onChangeNotice}
                            />
                        )}
                        {typeView == 2 && (
                            <Box>
                                {distributionMode == null && (
                                    <SelectMode
                                        onChangeMode={changeDistributionMode}
                                        selected={distributionMode}
                                    />
                                )}

                                {distributionMode == 'groups' && (
                                    <GroupsDistributionMode
                                        onSave={onSaveNotice}
                                    />
                                )}
                                {distributionMode == 'students' && (
                                    <StudentsDistributionMode
                                        onSave={onSaveNotice}
                                    />
                                )}
                                {distributionMode == 'users' && (
                                    <UsersDistributionMode
                                        onSave={onSaveNotice}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{ direction: 'row', justifyContent: 'space-between' }}
                >
                    <Box>
                        {distributionMode != null && typeView == 2 && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => onCloseDistributionMode()}
                                size="small"
                                startIcon={<ArrowBackIcon />}
                            >
                                Modos disponibles
                            </Button>
                        )}
                    </Box>
                    <Stack spacing={1} direction={'row'}>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            disabled={
                                operationState == 'pending' || typeView == 1
                            }
                            startIcon={<TextsmsIcon />}
                            onClick={onChangeTypeView}
                        >
                            Aviso
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            disabled={
                                operationState == 'pending' ||
                                isinvalidForm() ||
                                typeView == 2
                            }
                            startIcon={<GroupAddIcon />}
                            onClick={onChangeTypeView}
                        >
                            Modo de distribución
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={onCloseModal}
                            disabled={operationState == 'pending'}
                        >
                            Cerrar
                        </Button>
                    </Stack>
                </DialogActions>
                <Snackbar
                    open={openFeedback}
                    autoHideDuration={3000}
                    onClose={closeFeedback}
                    message={feedbackMessage}
                />
            </Dialog>
        </>
    );
};

export default DistributionNoticeModal;
