import { useSelector } from 'react-redux';
import { selectSettingByKey } from '../../store/slices/entities/settings';
import { Box, Card, Stack, Typography } from '@mui/material';
import { CardContent } from '@material-ui/core';
import { getLevelName, getTurnLevel } from '../../libs/utils';

const ClassroomSubjectPreviewCard = ({ classroom }) => {
    const scoreMin = useSelector(selectSettingByKey('calification-minimum'));
    return (
        <Card sx={{ marginTop: 2, marginBottom: 2 }}>
            <CardContent>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Stack direction={'row'} spacing={2}>
                        <Box>
                            <Typography variant="h5" component="div">
                                {classroom.school.name}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack>
                        <Typography variant="h6" component="div">
                            {classroom.group.grade}°{classroom.group.group}{' '}
                            {getTurnLevel(classroom.group.turn)}{' '}
                        </Typography>
                        <Typography variant="h6" component="div">
                            {getLevelName(classroom.group.level)}{' '}
                            {classroom.subjectCatalog.title}
                        </Typography>
                    </Stack>
                    <Stack direction={'column'} sx={{ textAlign: 'center' }}>
                        <Typography variant="caption" component="span">
                            Alumnos{' '}
                        </Typography>

                        <Typography
                            variant="h4"
                            component="span"
                            sx={{ fontWeight: 'bold' }}
                        >
                            {classroom.group.students}
                        </Typography>
                    </Stack>
                    <Stack direction={'column'} sx={{ textAlign: 'center' }}>
                        <Typography variant="caption" component="span">
                            Faltas{' '}
                        </Typography>

                        <Typography
                            variant="h4"
                            component="span"
                            sx={{ color: 'red', fontWeight: 'bold' }}
                        >
                            {classroom.subject.total_absence}
                        </Typography>
                    </Stack>
                    <Stack direction={'column'} sx={{ textAlign: 'center' }}>
                        <Typography variant="caption" component="span">
                            Promedio{' '}
                        </Typography>

                        <Typography
                            variant="h4"
                            component="span"
                            sx={{
                                color:
                                    classroom.subject.average_rating >=
                                    scoreMin?.value
                                        ? 'green'
                                        : 'red',
                                fontWeight: 'bold',
                            }}
                        >
                            {classroom.subject.average_rating}
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default ClassroomSubjectPreviewCard;
