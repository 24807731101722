import React, { Fragment } from 'react'
import { Card, CardContent, Box, Typography, CardActionArea } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar } from '@mui/material'

const ColorBox = (props) => {
    const { sx, mainColor, onClick, valueVariant} = props

    const contentBox = () => {
        return (
           <Fragment>
                <Box sx={{color: "white"}}>
                    <Typography variant="body1" component="span">
                        {props.label}
                    </Typography>
                </Box>

                <Box sx={{color: "white", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Typography variant={(valueVariant) ? valueVariant : "h5"} sx={{color: "white"}}>
                        {props.value}
                    </Typography>
                </Box>
           </Fragment>
        )
    }

    if (onClick){
        return (
            <Card sx={{background: mainColor, padding: "5px", textAlign: "center", height: 90, width: 130, ...sx}} onClick={props.onClick}>
                <CardActionArea>
                    {contentBox()}
                </CardActionArea>
            </Card>
            
        )
    }

    return (
        <Card sx={{background: mainColor, padding: "5px", textAlign: "center", height: 90, width: 130, ...sx}}>
            {contentBox()}
        </Card>
    )
}


export default ColorBox