import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const conceptAdapter = createEntityAdapter({
    selectId: (concept) => concept.concept_id,
    sortComparer: (a, b) => a.concept_id - b.concept_id,
});

export const ConceptSlice = createSlice({
    name: 'concept',
    initialState: conceptAdapter.getInitialState(),
    reducers: {
        setAllConcepts: conceptAdapter.setAll,
        addManyConcepts: conceptAdapter.addMany,
        addOneConcept: conceptAdapter.addOne,
        removeOneConcept: conceptAdapter.removeOne,
        removeManyConcepts: conceptAdapter.removeMany,
        upsertOneConcept: conceptAdapter.upsertOne,
        upsertManyConcepts: conceptAdapter.upsertMany,
        updateManyConcepts: conceptAdapter.updateMany,
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return conceptAdapter.getInitialState();
        });
    },
});

export const {
    setAllConcepts,
    addManyConcepts,
    addOneConcept,
    removeOneConcept,
    removeManyConcepts,
    upsertOneConcept,
    upsertManyConcepts,
    updateManyConcepts,
} = ConceptSlice.actions;

const globalizedSelectors = conceptAdapter.getSelectors(
    (state) => state.entities.concept
);

export default ConceptSlice.reducer;
