import React from 'react';
import { Tooltip, IconButton, Box } from '@mui/material';
import { GridToolbarExport } from '@mui/x-data-grid';
import DownloadIcon from '@mui/icons-material/Download';

const ExportButton = ({ fileName = 'data_export', position = 'left' }) => {
    return (
        <Box
            display="flex"
            justifyContent={position === 'right' ? 'flex-start' : 'flex-end'}>
            <IconButton color="primary">
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ fileName }}
                    icon={<DownloadIcon />}
                />
            </IconButton>
        </Box>
    );
};

export default ExportButton;
