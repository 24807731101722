import React, { useEffect, useState } from 'react'
import { TextField, Typography, Box, FormControlLabel, Snackbar, RadioGroup, Radio, FormLabel, Grid, MenuItem} from '@mui/material'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Feedback from "../../../service/Feedback"
import { useSelector, useDispatch } from "react-redux";
import { ColorPicker } from 'material-ui-color';
import ActivityLevels from '../ActivityLevels'
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

import { getLoading, createActivity, updateActivity, deleteActivity} from '../../../store/slices/calendar'
import 'moment/locale/es';

import CalendarDatePiker from '../DatePiker'
import CycleSchool from '../../Uilts/CycleSchool';
import { useAuth } from '../../../hooks';

const ActivityModal = (props) => {

    ///////////////////// DATOS PARA EL FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) =>  {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");


    ///////////////////////// ESTADO DEL COMPONENTE ////////////////////////////////

    const serverState = useSelector(getLoading)

    const dispatch = useDispatch()
    const Auth = useAuth()
    let user = Auth.getUser()

    const [activity, setActivity] = useState({
        activity_id: null,
        color: "#741CD9",
        description: "",
        end_date: null,
        is_range: 0,
        level: -1,
        school_cycle: "",
        shape: 1,
        start_date: null
    });

    /**
     * Efecto escuchando a show para limpiar los valores
     */
    useEffect(() => {
        if (!props.show) {
            setActivity({
                activity_id: null,
                color: "#741CD9",
                description: "",
                end_date: null,
                is_range: 0,
                level: -1,
                school_cycle: "",
                shape: 1,
                start_date: null
            })
        } else {
            setActivity({...activity, ...props.activity})
        }
    }, [props.show]);

    const [feedback] = useState(()=> new Feedback());

    const handleClose = () => {
        props.close()
    };

    //////////////////////////////// FUNCIONES Y COMPONENTES //////////////////////////

    /**
     * Funciona lanzada cuendo se pretende guardar la actividad
     * 
     */
    const onDone = () => {
        if (activity.activity_id == null) {
            dispatch(createActivity({school_id: user.school_id, data: activity}))
            .unwrap()
            .then(response => {
                props.done()
            }).catch(err => {
                let message = feedback.getMessage(err)
                showFeedback(message.message)
            })
        } else {
            dispatch(updateActivity({activity_id: activity.activity_id, data: activity}))
            .unwrap()
            .then(response => {
                props.done()
            }).catch(err => {
                let message = feedback.getMessage(err)
                showFeedback(message.message)
            })
        }

    }

    const onChangeActivityRange = (event) => {

        let isRange = event.target.value

        if(isRange == 0) {
            setActivity({...activity, ...{end_date: null, is_range: isRange}});
        } else {
            setActivity({...activity, is_range: isRange});
        }
    }


    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     * 
     * @param {*} event 
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setActivity({...activity, [name]: value});
    }

    const onChangeColor = (colorObject) => {

        setActivity({...activity, color: `#${colorObject.hex}`});
    }
    
    const onChangeLevel = (event) => {
        setActivity({...activity, level: event.target.value});
    }

    const onCalendarChange = (dates) => {
        let formateedDates = {
            start_date: null,
            end_date: null 
        }

        if (dates) {
            let startDate = null
            let endDate = null
            
            if(activity.is_range == 1) {
                startDate = dates[0]
                endDate = dates[1]
                console.log(dates)
            } else {
                startDate = dates
            } 

            formateedDates.start_date = startDate ? startDate.format('YYYY-MM-DD HH:MM:SS') : null
            formateedDates.end_date = endDate ? endDate.format('YYYY-MM-DD HH:MM:SS') : null 
        }
        
        setActivity({...activity, ...formateedDates});
    }

    const handleDeleteActivity = () => {
        dispatch(deleteActivity(activity.activity_id)).then(response => {
            props.done()
        }).catch(err => {
            let message = feedback.getMessage(err)
            showFeedback(message.message)
        })
    }

    const doneButtonUI = () => {
        if (activity.activity_id) {
            return "Actualizar"
        } else {
            return "Crear"
        }
    }

    const deleteButtonUI = () => {
        if (activity.activity_id) {
            return (
                <Button variant="contained" onClick={handleDeleteActivity} color="error">
                    Eliminar
                </Button>
            )
        } else {
            return ""
        }
    }
    ///////////////////   VALIDACIONES    ///////////////////////

    const descriptionValid = () => {
        return {
            invalid: activity.description == "",
            message: "Campo requerido o incorrecto"
        }
    }

    const startDateValid = () => {
        return {
            invalid: activity.start_date == null,
            message: "Campo requerido o incorrecto"
        }
    }

    const endDateValid = () => {
        return {
            invalid: activity.end_date == null,
            message: "Campo requerido o incorrecto"
        }
    }

    
    const schoolCicleValid = () => {
        
        const [yearOne, YearTwo] = activity.school_cycle.split("-")

        return {
            invalid: yearOne > YearTwo || (yearOne == "0000" && YearTwo == "0000" || activity.school_cycle.length == 0),
            message: "Campo requerido o incorrecto"
        }
    }

    const schoolLevel = () => {
        return {
            invalid: activity.level == -1,
            message: "Campo requerido o incorrecto"
        }
    }

    const errorCalendarUI = () => {
        let UI = (
            <Typography
                variant='caption'
                component="div"
                sx={{color: 'red'}}
            >
             Campo requerido o incorrecto
            </Typography>
        )

        if (activity.is_range == 1) {
            let invalid = endDateValid().invalid || startDateValid().invalid
            if (invalid) {
                return UI
            }
        } else {
            let invalid = startDateValid().invalid
            if (invalid) {
                return UI
            }
        }
    }

    const activityForminValid = () => {
        let valid = true

        if(activity.is_range == 1) {
            valid = (descriptionValid().invalid || startDateValid().invalid || endDateValid().invalid || 
                    schoolCicleValid().invalid)
        } else {
            valid = (descriptionValid().invalid || startDateValid().invalid || 
            schoolCicleValid().invalid)
        } 
        return  valid
    }

    return (
        <Dialog
            open={props.show}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Crear actividad
                <Typography variant="caption" component="div">
                    Para mostrar o todos los involicrados de la escuela
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id="description"
                            name="description" 
                            label="Descripcion(*)"
                            variant="outlined"
                            size="small"
                            multiline
                            fullWidth
                            rows={4}
                            maxRows={4}
                            value={activity.description}
                            onChange={onInputChange}
                            error={descriptionValid().invalid}
                            helperText={descriptionValid().invalid ? descriptionValid().message: false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box component="div">
                        <CycleSchool
                            id="school_cycle"
                            name="school_cycle" 
                            label="Ciclo Escolar(*)"
                            variant="outlined"
                            size="small"
                            value={activity.school_cycle}
                            onChange={onInputChange}
                            error={schoolCicleValid().invalid}
                            helperText={schoolCicleValid().invalid ? schoolCicleValid().message: false}
                        />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box component="div">
                            <ActivityLevels
                                items={<MenuItem key={-1} value={-1}>Nivel</MenuItem>}
                                value={activity.level}
                                onChange={onChangeLevel}
                                error={schoolLevel().invalid}
                                helperText={schoolLevel().invalid ? schoolLevel().message: false}
                            ></ActivityLevels>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box component="div">
                            <FormLabel id="demo-radio-buttons-group-label">Tipo de actividad</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="0"
                                    name="radio-buttons-group"
                                    value={activity.is_range}
                                    onChange={onChangeActivityRange}
                                >
                                    <FormControlLabel value="0" control={<Radio size="small"/>} label="Unico dia" />
                                    <FormControlLabel value="1" control={<Radio size="small"/>} label="Rango de fechas" />
                            </RadioGroup>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box component="div">
                            <Typography variant='h6' component="div">
                                Color de la actividad
                            </Typography>
                            <ColorPicker
                                value={activity.color}
                                disableAlpha
                                hideTextfield
                                disablePlainColor
                                onChange={onChangeColor}/>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="div">
                            <CalendarDatePiker
                                isRange={activity.is_range}
                                onChange={onCalendarChange}
                                start_date={activity.start_date}
                                end_date={activity.end_date}
                            />
                            {errorCalendarUI()}
                        </Box>
                    </Grid>
                </Grid>
                </DialogContentText>
                <Snackbar
                    open={openFeedback}
                    autoHideDuration={3000}
                    onClose={closeFeedback}
                    message={feedbackMessage}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose} autoFocus>
                    Cerrar
                </Button>

                <LoadingButton
                        onClick={onDone}
                        endIcon={<SendIcon />}
                        loading={serverState=="pending"}
                        loadingPosition="end"
                        variant="contained"
                        disabled={activityForminValid()}
                    >
                        {doneButtonUI()}
                </LoadingButton>

            </DialogActions>
        </Dialog>
    )

}

export default ActivityModal