import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Card,
    CardContent,
    Tooltip,
    Typography,
    CircularProgress,
} from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectAllScholarshipStudents,
} from '../../../store/groups/fetchSlice';
import { getAllScholarshipStudents } from '../../../store/groups/thunks';
import { SkeletonGroupsSmallCards } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentGroupsAllScholarshipStudentsStatusServer } from '../../../store/groups/componentStatusSelectors';
import { Tag } from 'antd';

const TotalScholarshipTable = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentGroupsAllScholarshipStudentsStatusServer
    );
    const allScholarshipStudents = useFetchResource(
        () =>
            getAllScholarshipStudents({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectAllScholarshipStudents,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getAllScholarshipStudents({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectAllScholarshipStudents,
            [cycleSelected, schoolId]
        );
    };

    const [pageSize, setPageSize] = useState(7);

    const renderDataGrid = () => {
        const columns = [
            {
                field: 'group',
                headerName: 'Grupo',
                flex: 1,
            },
            {
                field: 'scholarship',
                headerName: 'Becados',
                flex: 0.7,
                renderCell: (params) => (
                    <Tag
                        color="blue"
                        style={{
                            width: '100px',
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        }}
                    >
                        {params.value}
                    </Tag>
                ),
            },
        ];

        const rows =
            allScholarshipStudents?.value?.Grupo?.map((group, index) => ({
                id: index,
                group: group.value,
                scholarship:
                    allScholarshipStudents?.value?.Becados?.[index]?.value,
            })) || [];

        const CustomToolbar = (props) => {
            return (
                <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                    <GridToolbar {...props} />
                </GridToolbarContainer>
            );
        };

        return (
            <Box sx={{ height: 210, width: '100%', mt: 2 }}>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[]}
                    disableSelectionOnClick
                    disableDensitySelector
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{
                        panel: {
                            placement: 'bottom-end',
                        },
                    }}
                    getRowHeight={() => 'auto'}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            overflow: 'visible',
                            lineHeight: '3',
                        },
                        '&.MuiDataGrid-root': {
                            border: 'none',
                        },
                    }}
                    autoHeight
                />
            </Box>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonGroupsSmallCards items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                    }}
                >
                    <CardContent>
                        <Tooltip
                            title={
                                allScholarshipStudents?.description ||
                                'Total de alumnos becados por grupo'
                            }
                            followCursor
                        >
                            <Box>
                                <Typography variant="h6" sx={{ color: '#333' }}>
                                    {allScholarshipStudents?.title ||
                                        'Total de alumnos becados por grupo'}
                                </Typography>
                                {allScholarshipStudents?.loading ? (
                                    <CircularProgress
                                        sx={{
                                            margin: '20px',
                                            display: 'block',
                                            mx: 'auto',
                                        }}
                                    />
                                ) : allScholarshipStudents?.error ? (
                                    <Typography
                                        color="error"
                                        sx={{ textAlign: 'center' }}
                                    >
                                        Error:{' '}
                                        {allScholarshipStudents.error.message}
                                    </Typography>
                                ) : (
                                    renderDataGrid()
                                )}
                            </Box>
                        </Tooltip>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default TotalScholarshipTable;
