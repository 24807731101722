import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import { selectPaymentIncome } from './selectors';
import { upsertManyPayments } from '../../../store/slices/entities/payments/income';
import { upsertManyUsers } from '../../../store/slices/entities/users';
import { upsertManyCataloConcepts } from '../../../store/slices/entities/payments/cat_concepts';
import { upsertManyStudents } from '../../../store/slices/entities/students';
import { upsertManyConcepts } from '../../../store/slices/entities/payments/concepts';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../service/const';
import { upsertManyPartialities } from '../../../store/slices/entities/payments/partialities';
import { upsertManyAgreements } from '../../../store/slices/entities/payments/agreements';

// Estado inicial del slice
const emptyState = {
    expireIn: null,
    fetchingAt: null,
    status: 'idle',
    operation: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

// Slice para manejar el estado de la petición de ingresos
const IncomeSlice = createSlice({
    name: 'Income',
    initialState: emptyState,
    reducers: {
        invalidate: (state, action) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        builder
            // Agregar los casos para la petición de descuentos de pago
            .addCase(fetchIncome.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchIncome.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.expireIn = new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                );
                state.fetchingAt = Date.now();
                state.didInvalidate = false;

                state.status = 'fulfilled';
            })
            .addCase(fetchIncome.rejected, (state, action) => {
                state.status = 'rejected';
                state.feedback = action.payload.feedback;
            });
    },
});

export const { invalidate: invalidateIncome } = IncomeSlice.actions;

export default IncomeSlice.reducer;

// //////////////////////////////////// THUNKS // ////////////////////////////////////

// Thunk para realizar la petición asincrónica de descuentos de pago
export const fetchIncome = createAsyncThunk(
    'Income/fetch',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const [
                fetchPaymentsResponse,
                fetchConceptsResponse,
                fetchCatConcepts,
                fetchUsers,
                fetchStudents,
                fetchPartialities,
                fetchAgreements,
            ] = await Promise.all([
                Connection.getPaymentsBySchool(schoolId),
                Connection.getConceptsBySchool(schoolId),
                Connection.getSchoolsByConceptsCatalog(schoolId),
                Connection.getUsersBySchool(schoolId),
                Connection.StudentsBySchool(schoolId),
                Connection.getPartialitiesBySchool(schoolId),
                Connection.getAgreementBySchool(schoolId),
            ]);

            const payments = fetchPaymentsResponse.data.data;
            const concepts = fetchConceptsResponse.data.data;
            const Catalog_concepts = fetchCatConcepts.data.data;
            const users = fetchUsers.data.data;
            const students = fetchStudents.data.data;
            const partialities = fetchPartialities.data.data;
            const agreements = fetchAgreements.data.data;

            thunkAPI.dispatch(upsertManyPayments(payments));
            thunkAPI.dispatch(upsertManyCataloConcepts(Catalog_concepts));
            thunkAPI.dispatch(upsertManyConcepts(concepts));
            thunkAPI.dispatch(upsertManyUsers(users));
            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyPartialities(partialities));
            thunkAPI.dispatch(upsertManyAgreements(agreements));

            return { payments, concepts, Catalog_concepts, users, students };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectIncomeFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
export const fetchExport = createAsyncThunk(
    'Income/fetchExport',
    async ({ schoolId, paymentId, terms }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const fetchTiket = await Connection.getExportable(
                'payment-bill',
                'pdf',
                {
                    payment_id: paymentId,
                    school_id: schoolId,
                    number_terms: terms,
                }
            );

            const url = window.URL.createObjectURL(new Blob([fetchTiket.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `payment_${paymentId}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            return { success: true };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * fetcha para la creación de un concepto
 */

export const fetchCreatePayment = createAsyncThunk(
    'Income/fetch/create',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const [
                responseConcepts,
                responsePartialities,
                responseStudents,
                responseAgreements,
            ] = await Promise.all([
                Connection.getConceptsBySchool(schoolId),
                Connection.getPartialitiesBySchool(schoolId),
                Connection.StudentsBySchool(schoolId),
                Connection.getAgreementBySchool(schoolId),
            ]);

            const concepts = responseConcepts.data.data;
            const partialities = responsePartialities.data.data;
            const students = responseStudents.data.data;
            const agreements = responseAgreements.data.data;

            thunkAPI.dispatch(upsertManyConcepts(concepts));
            thunkAPI.dispatch(upsertManyPartialities(partialities));
            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyAgreements(agreements));

            return { concepts, partialities, students, agreements };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

// //////////////////////////////////// SELECTORES // ////////////////////////////////////
/**
 * Selector para recuperar el estado de la petición de conceptos de pago
 */
export const selectIncomeFetch = createSelector(
    selectPaymentIncome,
    (state) => state.fetch
);
