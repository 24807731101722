import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

/**
 * Adaptador para grupos
 */
const relationshipsAdapter = createEntityAdapter({
    selectId: (item) => item.relationship_id,
    sortComparer: (a, b) => a.relationship_id - b.relationship_id 
})

/**
 * Slice para la entidades de alumnos
 */
const  relationshipsSlice = createSlice({
    name: "noticesModalUI/entities/relationships",
    initialState: relationshipsAdapter.getInitialState(),
    reducers: {
        addOneRelationShip: relationshipsAdapter.addOne,
        addManyRelationships: relationshipsAdapter.addMany,
        upsertManyRelationships: relationshipsAdapter.upsertMany
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
                    
            return relationshipsAdapter.getInitialState()
        })
        /**
         * Limpiar los datos al cerar el modal
         */
        builder.addCase('noticesModalUI/reset', (state, action) => relationshipsAdapter.getInitialState())
    }
})

export const { addOneRelationShip, addManyRelationships, upsertManyRelationships} = relationshipsSlice.actions

//////////////////////// SELECTORES ///////////////////////

const globalizedSelectors = relationshipsAdapter.getSelectors((state) => state.noticesModalUI.entities.relationships)

/**
 * Selecciona todos los grupos
 */
export const selectAllRelationships = (store) => globalizedSelectors.selectAll(store)


export default relationshipsSlice.reducer