import { Avatar, ListItem, ListItemAvatar, ListItemText, Tooltip } from "@mui/material";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks";
import { styled, useTheme } from "@mui/material/styles";
import * as CONSTANTS from "../../service/const";
import { selectOneSettingBySchoolId } from "../../store/slices/entities/settings";
import { useSelector } from "react-redux";

/**
 * Contiene el listado de paginas
 * del dashboard
 * 
 * @returns 
 */
const DrawerItemsList = () => {
    const Auth = useAuth();
    //const dispatch = useDispatch()
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();

    const schoolId = Auth.getUser().school_id

    ////////////////// LOCAL STATE  //////////////////

    const menuItems = CONSTANTS.MENU_ITEMS

    /////////////////// SHARES STATE /////////////////

    const titleBar = useSelector(selectOneSettingBySchoolId(schoolId, 'theme-color-title-bar'));
    const menuBar = useSelector(selectOneSettingBySchoolId(schoolId, 'theme-color-menu-bar'));
    const selection = useSelector(selectOneSettingBySchoolId(schoolId, 'theme-color-selection'));
    const font = useSelector(selectOneSettingBySchoolId(schoolId, 'theme-color-font'));

    //////////////////// ACTIONS //////////////////////

    /**
     * Coloca los estidlos si es que el elemento 
     * se encuentra seleccionado 
     * 
     * @param {*} item 
     * @returns 
     */
    const getTabItemSelected = (item) => {
        let urlBase = `/${location.pathname.split("/")[1]}`

        //return urlBase === item.path ? classes.active :  " "+classes.hover

        return urlBase === item.path ? { backgroundColor: selection?.value } : {}
    }

    /**
     * Obtenemos los elementos del menu lateral
     * 
     * @returns 
     */
    const menuItemsUI = () => {
        let items = []

        for (const item of menuItems.filter(item => item.inDraweMenu)) {
            let Evaluated = []

            for (const groupAllowed of item.allow) {
                Evaluated.push(Auth.belongToGroup(groupAllowed))
            }

            if (Evaluated.find(item => item)) {
                items.push(
                    <Tooltip title={item.text} placement="right" key={item.key}>
                        <ListItem
                            button
                            onClick={() => history.push(item.path)}
                            style={getTabItemSelected(item)}
                        >
                            <ListItemIcon style={{ color: font?.value }} sx={{ mr: 0, minWidth: '44px' }}>
                                {item.icon}
                            </ListItemIcon>

                            <ListItemText style={{ color: font?.value }} primary={item.text}></ListItemText>
                        </ListItem>
                    </Tooltip>
                )
            }
        }

        return items
    }

    return (
        <List dense={true}>
            {menuItemsUI()}
        </List>
    )
}

export default DrawerItemsList