import { CardHeader, Stack } from "@mui/material"
import { GridToolbar } from "@mui/x-data-grid"

const AssistsToolBar = (props) => {
    return (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <CardHeader
                title="Asistencias"
            />
            <GridToolbar {...props} />
        </Stack>
    )
}

export default AssistsToolBar