import React from 'react';
import { Box, Card, CardContent, Typography, Avatar } from '@mui/material';
import { useAuth } from '../../../../hooks';
import male from '../images/male.png';
import female from '../images/female.png';
import useThemeColors from '../../../../theme/themes';


const WelcomeCard = () => {
    const auth = useAuth();
    const user = auth.getUser(); 
    const colors = useThemeColors();
    const avatarSrc = user.gender === 'M' ? female : male;
    const genderText = user.gender === 'M' ? 'Bienvenida' : 'Bienvenido';

    const getMessageOfTheDay = () => {
        const dayOfWeek = new Date().getDay(); 
        switch (dayOfWeek) {
            case 1: 
                return 'La educación financiera es clave para asegurar un futuro brillante.';
            case 2:
                return 'Invertir en conocimiento siempre da los mejores intereses.';
            case 3: 
                return 'Cada paso hacia la mejora personal también es un paso hacia un futuro más próspero.';
            case 4: 
                return 'El optimismo es el motor que impulsa la superación en cualquier circunstancia.';
            case 5: 
                return 'La gestión efectiva de recursos es la base del éxito académico y financiero.';
            default:
                return 'Hoy es un buen día para aprender algo nuevo y mejorar.';
        }
    };

    return (
        <Box
            className="Special-card"
            sx={{ position: 'relative', display: 'flex', justifyContent: 'flex-end' }}>
            <Card 
                className="Card-Welcome-text"
                sx={{
                    borderRadius: 2,
                boxShadow: 3,
                    flex: 1,
                   
                }}>
                <CardContent className="content-card">
                    <Box>
                        <Typography variant="h3" className="card-title">
                            ¡{genderText}, {user.name}!
                        </Typography>
                 
                        <Typography variant="body1" className="card-message">
                            {getMessageOfTheDay()}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
            <Box
                className="avatar-wrapper"
                sx={{ position: 'absolute', right: '-5px', top: '50%', transform: 'translateY(-60%)' }}>
                <Avatar
                    src={avatarSrc}
                    className="user-avatar"
                    sx={{
                        width: '170px',
                        height: '190px',
                    }}
                />
            </Box>
        </Box>
    );
};

export default WelcomeCard;
