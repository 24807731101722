import { Box, FormControlLabel, FormGroup, List, ListSubheader, Switch, CircularProgress } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectStudentSelected,
    loadParentsStepData,
    selectOperationStatusParentsStep,
    selectParentsDataByStudentsSelected,
    selectParentsSelected,
    changeParentsSelected,
    changeSelectAllParents,
    selectParentsIsAllSelected
} from '../../../../store/slices/noticesModalUI/modes/groupsModeSlice';
import { Empty, Error } from "../../../../components/Feedback";

import ParentListItem from "./ParentListItem"
import { useAuth } from '../../../../hooks';


/**
 * Componente que muestra componentes para selecionar a que hijos se le enviaran una notificacion
 * 
 * @param dataSource Fuente de alumnos
 * @param onChange Evento lanzado cada vez que un alumno es seleccionado 
 * @param selected Alumnos seleccionados
 * 
 * @returns 
 */
const ParentsStep = ({ onChange, selected = [] }) => {
    const dispatch = useDispatch()
    const Auth = useAuth()

    ///////////////// SHARED STATE ////////////////

    let studentSelected = useSelector(selectStudentSelected)
    let parentsSelected = useSelector(selectParentsSelected)

    let parents = useSelector(selectParentsDataByStudentsSelected)

    const operationStatus = useSelector(selectOperationStatusParentsStep)

    const isAllSelected = useSelector(selectParentsIsAllSelected)

    ///////////////// LOCAL STATE ///////////////////

    const [items, setItems] = useState([])
    const [all, setAll] = useState(isAllSelected);

    /////////////////// FUNCTIONS ///////////////////////////

    /**
     * Recuperacion de los datos de los padres
     */
    useEffect(() => {
        let studentListSelected = _.uniq(_.flatten(studentSelected.map(i => i.students)))

        dispatch(loadParentsStepData({
            studentIds: studentListSelected,
            schoolId: Auth.getUser().school_id
        }))
    }, [])

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        let studentListSelected = _.uniq(_.flatten(studentSelected.map(i => i.students)))
        dispatch(loadParentsStepData({
            studentIds: studentListSelected,
            schoolId: Auth.getUser().school_id
        }))
    }

    /**
     * Lo que recuperamos del selector lo almacenamos en un estado local
     */
    useEffect(() => {

        let groups = Object.values(_.groupBy(parents, (value) => value.student.student_id))
            .map(g => ({
                ...g[0].student,
                parents: g.map(i => i.parent)
            }))
            .map(s => {
                let student = parentsSelected.find(ps => ps.student_id == s.student_id)

                if (!student) {
                    return s
                }

                let parents = s.parents.map(p => ({ ...p, checked: student.parents.filter(sS => sS == p.user_id).length != 0 }))

                return {
                    ...s,
                    parents: parents
                }
            })

        setItems(groups)
    }, [operationStatus])

    /**
     * Activar o desactivar el envio de notificacion a un estudiante de un grupo
     * 
     * @param {*} value 
     * @returns 
     */
    const handleStudentToggle = (parent_id, student_id, checked) => {

        const itemsUpdated = enableOneItem(parent_id, student_id, checked)

        const isAll = getIsAllSelected(itemsUpdated)
        const itemsSelected = getItemsSelected(itemsUpdated)

        setItems(itemsUpdated)
        setAll(isAll)

        dispatch(changeParentsSelected(itemsSelected))
        dispatch(changeSelectAllParents(isAll))
    }

    /**
     * Identifica si todos los alumnos ha sido seleccionado
     * 
     * @param {*} groups 
     * 
     * @returns 
     */
    const getIsAllSelected = (items) => _.flatten(items.map(g => g.parents)).map(s => s.checked).every(i => i)

    /**
     * Calcula y devuelve los elementos seleccionados
     * 
     * @param groups Lista de grupos con padres (Actualizada)
     */
    const getItemsSelected = (items) => {
        return items.map(s => {
            return {
                student_id: s.student_id,
                parents: s.parents.filter(s => s.checked).map(i => i.user_id)
            }
        }).filter(g => g.parents.length > 0)
    }


    /**
     * Le cambia el estado a todos los elementos
     * 
     * @param {*} checked 
     * @returns 
     */
    const changeCheckedAllItem = (checked) => items.map(g => ({ ...g, parents: g.parents.map(s => ({ ...s, checked })) }))

    /**
     * Funcion para deshabilitar todo los estudiantes
     */
    const disableAllItems = () => changeCheckedAllItem(false)

    /**
     * Funcion para habilitar todo los estudiantes
     */
    const enableAllItems = () => changeCheckedAllItem(true)

    /**
     * Abilitar un elemento
     * 
     * @param user_id 
     * @param student_id 
     * @param checked 
     * 
     * @returns 
     */
    const enableOneItem = (user_id, student_id, checked) => {
        return items.map(g => {

            if (g.student_id == student_id) {
                return {
                    ...g,
                    parents: g.parents.map(s => (s.user_id == user_id ? ({ ...s, checked }) : s))
                }
            }

            return g
        })
    }

    /**
     * Cambio el swtich para seleccionar todos
     * 
     * @param {*} data 
     */
    const onChangeAll = (event) => {
        setAll(event.target.checked)

        const isAll = event.target.checked
        let parentsAfected = []
        let parentsSelected = []

        if (event.target.checked) {
            parentsAfected = enableAllItems()

            parentsSelected = getItemsSelected(parentsAfected)
        } else {
            parentsAfected = disableAllItems()
        }

        setItems(parentsAfected)

        dispatch(changeParentsSelected(parentsSelected))
        dispatch(changeSelectAllParents(isAll))
    }

    return (
        <Box
            sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        >
            {
                operationStatus == "pending" && (
                    <Box sx={{ textAlign: "center", mt: 2, mb: 2 }}>
                        <CircularProgress />
                    </Box>
                )
            }

            {
                operationStatus == "rejected" && (<Error onRetry={reload} message={"Estamos teniendo problemas"} />)
            }

            {
                (operationStatus == "fulfilled" && items.length == 0) && (<Empty label="Sin alumnos" />)
            }

            {
                (operationStatus == "fulfilled") && (
                    <>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={all}
                                        onChange={onChangeAll}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Todos"
                            />
                        </FormGroup>

                        <List
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 300,
                                '& ul': { padding: 0 },
                            }}
                            subheader={<li />}
                            dense
                        >
                            {
                                items.map((item) => (
                                    <li key={`section-${item.group_id}`}>
                                        <ul>
                                            <ListSubheader>
                                                {`${item.name} ${item.last_name} ${item.second_last_name}`}
                                            </ListSubheader>
                                            {
                                                item.parents.map((parent) => {
                                                    return (
                                                        <ParentListItem
                                                            parent={parent}
                                                            student={item}
                                                            onChange={handleStudentToggle}
                                                        />
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>
                                ))
                            }
                        </List>
                    </>
                )
            }
        </Box>
    )
}


export default ParentsStep