import { createSelector } from '@reduxjs/toolkit';
import { selectParents } from '../../../store/slices/entities/users';
import { selectCatalogConcepts } from '../income/selectors';
import { selectStudentById } from '../../../store/slices/entities/students';

const selectRelationships = (state) =>
    Object.values(state.entities.relationships.entities);

/**
 * Selector para recuperar los partialities
 */
export const selectPartialities = (state) =>
    Object.values(state.entities.partialities.entities);
