import { Avatar, Checkbox, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";

const StudentListItem = ({student,group, onChange}) => {

    const labelId = `checkbox-list-secondary-label-${student.student_id}`;

    const [checked, setChecked] = useState(false) 

    useEffect(() => {
        setChecked(student.checked)
    },[student])

    /**
     * Activar o desactivar el envio de notificacion a un estudiante de un grupo
     * 
     * @param {*} value 
     * @returns 
     */
    const handleStudentToggle = (student_id, group_id) => (event) => {
        onChange(student_id, group_id, !checked)
    }

    return (
        <ListItem
            key={`item-${student.group_id}`}
            disablePadding
        >
            <ListItemButton
                role={undefined}
                onClick={handleStudentToggle(student.student_id, group.group_id)}
                dense
            >
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={checked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </ListItemIcon>
                <ListItemAvatar>
                    <Avatar
                        alt={`Avatar n°${student.student_id}`}
                        src={student.url_photo_profile}
                        sx={{ width: 24, height: 24 }}
                    />
                </ListItemAvatar>
              <ListItemText id={labelId} primary={`(${student.folio}) ${student.name} ${student.last_name} ${student.second_last_name}`} />
            </ListItemButton>
        </ListItem>
    )
}



export default StudentListItem