import { combineReducers } from '@reduxjs/toolkit';

import classroomModelsIds from './ItemsSlice';
import ui from './uiSlice';
import fetch from './fetchSlice';

export default combineReducers({
    classroomModelsIds,
    fetch,
    ui,
});
