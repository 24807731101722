import { combineReducers } from '@reduxjs/toolkit';
import home from './home';
import assistance from './assistance';
import noticeDetails from './noticeDetails';
import assistanceDetails from './assistanceDetails';
import report from './report';
import reportDetails from './reportDetails';
import reprobation from './reprobation';
import reprobationDetails from './reprobationDetails';
import homework from './homework';
import homeworkDetails from './homeworkDetails';

export default combineReducers({
    home,
    assistance,
    report,
    reprobation,
    homework,
    noticeDetails: noticeDetails,
    assistanceDetails: assistanceDetails,
    reportDetails: reportDetails,
    reprobationDetails: reprobationDetails,
    homeworkDetails: homeworkDetails
});
