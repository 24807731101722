import { Box, CircularProgress, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { Transfer } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLevelName, getTurnLevel } from '../../../../libs/utils';
import { useAuth } from '../../../../hooks';
import {
    changeGroupsSelected, changeSelectAllGroups, loadGroupsStepData, selectGroupsSelected,
    selectOperationStatusGroupsStep, selectGroupsIsAllSelected, invalidateStepData
} from '../../../../store/slices/noticesModalUI/modes/groupsModeSlice';
import { selectAllGroups } from '../../../../store/slices/noticesModalUI/entities/groupsSlice';
import { Empty, Error } from "../../../../components/Feedback";

/**
 * Componente encargado se seleccionar grupos
 * 
 * @returns 
 */
const GroupsStep = () => {

    const Auth = useAuth()
    const dispatch = useDispatch()

    ///////////////// SHARED STATE ////////////////

    const groups = useSelector(selectAllGroups)
    const groupsselected = useSelector(selectGroupsSelected)

    const isAllSelected = useSelector(selectGroupsIsAllSelected)

    console.log(isAllSelected)

    const operationStatus = useSelector(selectOperationStatusGroupsStep)

    ///////////////// LOCAL STATE ///////////////////

    const [targetKeys, setTargetKeys] = useState(groupsselected)
    const [selectedKeys, setSelectedKeys] = useState([])

    /////////////////// FUNCTIONS ///////////////////////////

    /**
     * Efecto para recuperar los grupos e iniciar el el flujo
     */
    useEffect(() => {
        dispatch(loadGroupsStepData(Auth.user.school_id))
    }, []);

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        dispatch(loadGroupsStepData(Auth.user.school_id))
    }

    /**
     * Funcion para intercambiar elementos de la lista
     * 
     * @param {*} nextTargetKeys 
     * @param {*} direction 
     * @param {*} moveKeys 
     */
    const onChangeList = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);

        const allSelected = (nextTargetKeys.length == groups.length)

        dispatch(changeSelectAllGroups(allSelected))
        dispatch(changeGroupsSelected(nextTargetKeys))
        dispatch(invalidateStepData('students'))
    }

    /**
     * Funcion para marcar grupos como seleccionados en la lista
     * 
     * @param {*} sourceSelectedKeys 
     * @param {*} targetSelectedKeys 
     */
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    }

    /**
     * Cambio el swtich para seleccionar todos
     * 
     * @param {*} data 
     */
    const onChangeAll = (event) => {
        let allIds = (event.target.checked) ? groups.map(i => i.group_id) : []
        let isAll = (event.target.checked)

        dispatch(changeSelectAllGroups(isAll))
        dispatch(changeGroupsSelected(allIds))
        dispatch(invalidateStepData('students'))

        setTargetKeys(allIds)
    }

    const removeAccents = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    const filterOption = (inputValue, option) => {
        const search = removeAccents(
            inputValue.toLowerCase().replace(/\s/g, '')
        );

        const titleNoSpace = removeAccents(
            `${option.grade} ${option.group} ${getTurnLevel(option.turn)} ${getLevelName(option.level)}`
                .toLowerCase()
                .replace(/\s/g, '')
        );

        const titleMatch = titleNoSpace.includes(search);

        return titleMatch;
    };

    return (
        <Box
            sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        >
            {
                operationStatus == "pending" && (
                    <Box sx={{ textAlign: "center", mt: 2, mb: 2 }}>
                        <CircularProgress />
                    </Box>
                )
            }

            {
                operationStatus == "rejected" && (<Error onRetry={reload} message={"Estamos teniendo problemas"} />)
            }

            {
                (operationStatus == "fulfilled" && groups.length == 0) && (<Empty label="Sin grupos" />)
            }

            {
                (operationStatus == "fulfilled") && (
                    <>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isAllSelected}
                                        onChange={onChangeAll}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Todos"
                            />
                        </FormGroup>

                        <Transfer
                            filterOption={filterOption}
                            style={{ marginTop: '20px' }}
                            showSearch
                            listStyle={{
                                width: 300,
                                height: 300,
                            }}
                            locale={{
                                notFoundContent: ["Sin grupos", "Sin grupos"],
                                itemUnit: "Grupos",
                                itemsUnit: "Grupos",
                                searchPlaceholder: 'Buscar aquí'
                            }}
                            dataSource={groups.map(i => {
                                return { ...i, key: i.group_id }
                            })}
                            titles={['Disponibles', 'Asignados']}
                            targetKeys={targetKeys}
                            selectedKeys={selectedKeys}
                            onChange={onChangeList}
                            onSelectChange={onSelectChange}
                            render={item => `${item.grade} ${item.group} ${getTurnLevel(item.turn)} ${getLevelName(item.level)}`}
                        />
                    </>
                )
            }

        </Box>
    )
}


export default GroupsStep