import { Typography } from "@mui/material"
import { Box } from "@mui/system"

const Empty = (props) => {
    const {label, children} = props 

    const labelBox = label || "Recuperando informacion"

    return (
        <Box sx={{height: 300, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Typography variant="h3" component="div"> {labelBox} </Typography>
            {children}
        </Box>
    )
}


export default Empty