import React from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import JustifyToolbar from './JustifyToolbar';

const SubjectJustifyTable = ({ rows, columns }) => {
    return (
        <>
            <Card sx={{ mt: 4 }}>
                <CardContent
                    sx={{
                        pt: 0,
                        pl: 0,
                        pr: 0,
                        pb: 0,
                        ' & .MuiDataGrid-root': {
                            border: 'none',
                        },
                    }}
                >
                    <Box
                        sx={{
                            '& .partial-active--cell': {
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                color: '#1a3e72',
                                fontWeight: '600',
                            },
                        }}
                    >
                        <DataGrid
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            rows={rows}
                            columns={columns}
                            pageSize={20}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            autoHeight
                            components={{ Toolbar: JustifyToolbar }}
                            disableDensitySelector
                        />
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

export default SubjectJustifyTable;
