import React, { useEffect, useState } from 'react';
import {
    TextField,
    Typography,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Button,
    Box,
    Divider,
    FormHelperText,
    Autocomplete,
    createFilterOptions,
    FormGroup,
    FormControlLabel,
    Switch,
} from '@mui/material';
import { Transfer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectStatusOperation,
    storeCatalgSubject,
    updateCatalogSubject,
} from '../../../store/slices/materias';
import * as constants from '../../../service/const';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import Connection from '../../../service/Connection';
import { selectGroupsByLevel } from '../../../store/slices/entities/groups';
import useFeedback from '../../../hooks/useFeedback';
import { getSpecialsLabel, getTurnsLabel } from '../../../helper';

/**
 * Modal crear materias y catalogos
 */
export default ({ openModal, setOpenModal, catalog = null }) => {
    const Auth = useAuth();
    const user = Auth.getUser();
    const schoolId = Auth.getUser().school_id;
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    ///////////////// LOCAL STATE ////////////////////

    const [catalogItem, setCatalogItem] = useState({
        catalog_subject_id: null,
        title: '',
        folio: '',
        level: '',
        // is_discarted: false
    });
    //const [profesor, setProfesor] = useState(0);

    const [checked, setChecked] = useState(false);

    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);

    //////////// SHARED STATE  /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(selectStatusOperation);

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    const groups = useSelector(selectGroupsByLevel(catalogItem.level)).filter(
        (group) =>
            group.group_type !== 4 &&
            !(group.group_type === 2 && group.subjects > 0)
    );

    //const maestro = useSelector(selectProfessor);

    ///////////////////// ACTIONS ///////////////////////

    /**
     * Filtro para la busqueda
     */

    const removeAccents = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    const filterOption = (inputValue, option) => {
        const search = removeAccents(
            inputValue.toLowerCase().replace(/\s/g, '')
        );

        const titleNoSpace = removeAccents(
            `${option.grade} ${option.group} ${getTurnsLabel(
                option.turn
            )} ${getSpecialsLabel(option.group_type)}`
                .toLowerCase()
                .replace(/\s/g, '')
        );

        const titleMatch = titleNoSpace.includes(search);

        return titleMatch;
    };

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     *
     * @param {*} event
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        if (name == 'level') {
            //setSubjectTargetKeys([])
            //setSubjectSelectedKeys([])
        }

        setCatalogItem({ ...catalogItem, [name]: value });
    };

    /**
     * Funciona para selecionar un profesor
     *
     * @param {*} event
     */
    /*const handleChangeProfesor = (event) => {
        setProfesor(event.target.value);
    };*/

    /**
     * Funcion para intercambiar elementos de la lista
     *
     * @param {*} nextTargetKeys
     * @param {*} direction
     * @param {*} moveKeys
     */
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };

    /**
     * Funcion para marcar grupos como seleccionados en la lista
     *
     * @param {*} sourceSelectedKeys
     * @param {*} targetSelectedKeys
     */
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    /**
     * Efecto escuchando a show para limpiar los valores
     */
    useEffect(() => {
        if (!openModal) {
            setCatalogItem({
                catalog_subject_id: null,
                title: '',
                folio: '',
                level: '',
                // is_discarted: false
            });
            setTargetKeys([]);
            setSelectedKeys([]);

            setValue(null);
            setInputValue('');
            setChecked(false);
        } else {
            setCatalogItem({ ...catalogItem, ...catalog });
        }
    }, [openModal]);

    /**
     * Guardar o actualziar el catalogo
     */
    const enviarDatos = async () => {
        if (catalogItem.catalog_subject_id == null) {
            dispatch(
                storeCatalgSubject({
                    schoolId: schoolId,
                    catalog: catalogItem,
                    profesor: value,
                    groups: targetKeys,
                    createSubject: checked,
                    // is_discarted: catalogItem.is_discarted
                })
            )
                .unwrap()
                .then(({ message }) => {
                    let initialMessage = 'Materia creada';

                    if (message) {
                        initialMessage = message;
                    }

                    feedbackApp.showFeedback({
                        title: initialMessage,
                    });
                    setOpenModal(false);
                })
                .catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title,
                    });
                });
        } else {
            dispatch(
                updateCatalogSubject({
                    catalogSubjectId: catalogItem.catalog_subject_id,
                    catalog: catalogItem,
                })
            )
                .unwrap()
                .then((response) => {
                    feedbackApp.showFeedback({
                        title: 'Materia actualizada',
                    });
                    setOpenModal(false);
                })
                .catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title,
                    });
                });
        }
    };

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpenModal(false);
    };

    ////////////////////////////// validaciones ///////////////////

    const levelValid = () => {
        return {
            invalid: catalogItem.level == 0,
            message: 'Campo requerido',
        };
    };

    const folioValid = () => {
        return {
            invalid: catalogItem.folio == '',
            message: 'Campo requerido, Máximo 20 caracteres',
        };
    };

    const titleValid = () => {
        return {
            invalid: catalogItem.title == '',
            message: 'Campo requerido, Máximo 70 caracteres',
        };
    };

    const groupsValid = () => {
        return {
            invalid: checked && targetKeys.length == 0,
            message: 'Al menos se tiene que seleccionar un grupo',
        };
    };

    const formInvalid = () => {
        return (
            levelValid().invalid ||
            folioValid().invalid ||
            titleValid().invalid ||
            groupsValid().invalid
        );
    };

    ///////////////////////// AUTOCOMPLETE ///////////////////////

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');

    /**
     * Opciones para el componente Autocomplete
     */
    const filterOptions = createFilterOptions({
        limit: 10,
    });

    const [openSearch, setOpenSearch] = useState(false);
    const [professors, setProfessors] = useState([]);
    const loading = openSearch && professors.length === 0;

    /**
     * Recupera todos los asesores
     */
    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            let data = await Connection.getProfessorsActivedBySchool(
                schoolId
            ).then((i) => i.data.data);

            if (active) {
                setProfessors([...data]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    /**
     * Limpiar el buscador
     */
    React.useEffect(() => {
        if (!openSearch) {
            setProfessors([]);
            /*setStudent({
                group_id: null,
                school_id:  null,
                name:  '',
                last_name: '',
                second_last_name: '',
                cellphone: '',
                folio: '',
                colony: '',
                inside_number: '',
                outside_number: '',
                street: '',
                zipcode: '',
                city_id: null,
            })*/
        }
    }, [openSearch]);

    ////////////////////// VIEW //////////////////////////

    const onChangeSubjectDetails = (event) => {
        setChecked(event.target.checked);
    };

    /**
     * Muestra los components
     *
     * @returns
     */
    const subjectDetailsUI = () => {
        return (
            <>
                {catalogItem.catalog_subject_id == null && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 2,
                        }}
                    >
                        {/*<FormControl
                                    variant="standard"
                                    sx={{ width:'100%'}}
                                >
                                    <InputLabel id="professor">Profesor</InputLabel>
                                    <Select
                                        labelId="professor"
                                        id="profesor"
                                        value={profesor}
                                        onChange={handleChangeProfesor}
                                        label="Profesor" 
                                        fullWidth  
                                    >   
                                        <MenuItem value={0} selected>
                                            Selecciona una opción
                                        </MenuItem>
                                    {
                                        maestro?.map(row =>
                                            <MenuItem key={row.professor_id} value={row.professor_id}>
                                                {row.name + " "+ row.last_name + " " + row.second_last_name}
                                            </MenuItem>
                                        )
                                    }
                                    </Select>
                                </FormControl>
                                */}

                        <Autocomplete
                            id="professors"
                            loadingText="Buscando..."
                            noOptionsText="No se encontraron resultados"
                            sx={{ minWidth: 300 }}
                            filterOptions={filterOptions}
                            value={value}
                            options={professors}
                            loading={loading}
                            open={openSearch}
                            onOpen={() => {
                                setOpenSearch(true);
                            }}
                            onClose={() => {
                                setOpenSearch(false);
                            }}
                            getOptionLabel={(option) =>
                                option.name +
                                ' ' +
                                option.last_name +
                                ' ' +
                                option.second_last_name
                            }
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant="outlined"
                                    {...params}
                                    label="Buscar profesor"
                                />
                            )}
                        />
                    </Box>
                )}

                {catalogItem.catalog_subject_id == null && (
                    <>
                        <Divider sx={{ mt: 2 }}>
                            <Typography
                                variant="h6"
                                component="div"
                                style={{ textAlign: 'center' }}
                            >
                                Asignar grupos a la materia
                            </Typography>
                        </Divider>

                        <FormControl
                            variant="standard"
                            sx={{ width: '100%' }}
                            error={groupsValid().invalid}
                        >
                            {groupsValid().invalid && (
                                <FormHelperText>
                                    {groupsValid().message}
                                </FormHelperText>
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Transfer
                                    filterOption={filterOption}
                                    style={{ marginTop: '20px' }}
                                    showSearch
                                    listStyle={{
                                        width: 300,
                                        height: 300,
                                        searchPlaceholder: 'Buscar aquí',
                                    }}
                                    locale={{
                                        notFoundContent: [
                                            'Sin grupos',
                                            'Sin grupos',
                                        ],
                                        itemUnit: 'Grupos',
                                        itemsUnit: 'Grupos',
                                    }}
                                    dataSource={groups.map((i) => {
                                        return { ...i, key: i.group_id };
                                    })}
                                    titles={['Disponibles', 'Asignados']}
                                    targetKeys={targetKeys}
                                    selectedKeys={selectedKeys}
                                    onChange={onChange}
                                    onSelectChange={onSelectChange}
                                    render={(item) =>
                                        `${item.grade}° ${
                                            item.group
                                        } ${getTurnsLabel(
                                            item.turn
                                        )} ${getSpecialsLabel(item.group_type)}`
                                    }
                                />
                            </Box>
                        </FormControl>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <Dialog
                open={openModal}
                fullWidth={true}
                maxWidth="md"
                onClose={handleClose}
            >
                <DialogTitle
                    style={{
                        backgroundColor: titleBarBackground.value,
                        color: fontColor.value,
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography
                            variant="h6"
                            component="div"
                            style={{ flexGrow: 1, color: '#fff' }}
                        >
                            {catalogItem.catalog_subject_id != null &&
                                `Actualizar catálogo materia`}
                            {catalogItem.catalog_subject_id == null &&
                                `Agregar catálogo de materia`}
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" component="div">
                            Datos del catálogo
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid
                                item
                                xl={6}
                                md={6}
                                xs={12}
                                sm={12}
                                style={{ textAlign: 'center' }}
                            >
                                <TextField
                                    id="folio"
                                    name="folio"
                                    label="Folio"
                                    type="text"
                                    size="small"
                                    variant="outlined"
                                    inputProps={{ maxLength: 20 }}
                                    fullWidth
                                    value={catalogItem.folio}
                                    onChange={onInputChange}
                                    error={folioValid().invalid}
                                    helperText={
                                        folioValid().invalid
                                            ? folioValid().message
                                            : false
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xl={6}
                                md={6}
                                xs={6}
                                sm={12}
                                style={{ textAlign: 'center' }}
                            >
                                <TextField
                                    id="title"
                                    name="title"
                                    label="Materia"
                                    type="text"
                                    size="small"
                                    variant="outlined"
                                    inputProps={{ maxLength: 70 }}
                                    fullWidth
                                    value={catalogItem.title}
                                    onChange={onInputChange}
                                    error={titleValid().invalid}
                                    helperText={
                                        titleValid().invalid
                                            ? titleValid().message
                                            : false
                                    }
                                />
                            </Grid>
                            {catalogItem.catalog_subject_id == null && (
                                <Grid item xl={6} md={6} xs={12} sm={12}>
                                    <FormControl
                                        variant="standard"
                                        sx={{ width: '100%' }}
                                        error={levelValid().invalid}
                                    >
                                        <InputLabel id="level">
                                            Nivel
                                        </InputLabel>
                                        <Select
                                            labelId="level"
                                            id="level"
                                            value={catalogItem.level}
                                            onChange={onInputChange}
                                            label="Nivel"
                                            name="level"
                                            fullWidth
                                        >
                                            <MenuItem
                                                value={0}
                                                selected
                                                disabled
                                            >
                                                Selecciona una opción
                                            </MenuItem>
                                            {constants.levelsNames.map((i) => {
                                                return (
                                                    <MenuItem
                                                        key={i.key}
                                                        value={i.key}
                                                    >
                                                        {i.title}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {levelValid().invalid && (
                                            <FormHelperText>
                                                {levelValid().message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            )}
                            {/* <Grid item xl={6} md={6} xs={6} sm={12} style={{ textAlign: "center" }}>
                                <FormControlLabel control={
                                <Switch defaultChecked={!catalogItem.is_discarted} onChange={() => {
                                    let discarted = catalogItem.is_discarted
                                    catalogItem.is_discarted = !discarted
                                }} />
                                } label="Considerado para promedio" />
                            </Grid> */}
                        </Grid>
                        {checked && subjectDetailsUI()}
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        {catalogItem.catalog_subject_id == null && (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            value={checked}
                                            onChange={onChangeSubjectDetails}
                                        />
                                    }
                                    label="Asignar y crear materia"
                                />
                            </FormGroup>
                        )}
                    </Box>
                    <Box>
                        <LoadingButton
                            size="small"
                            color="primary"
                            onClick={enviarDatos}
                            loading={statusOperation == 'pending'}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            disabled={formInvalid()}
                        >
                            {catalogItem.catalog_subject_id != null &&
                                `Actualizar`}
                            {catalogItem.catalog_subject_id == null &&
                                `Agregar`}
                        </LoadingButton>
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            sx={{ ml: 2 }}
                        >
                            Cerrar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};
