import { Box, List, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectPreviewItems } from "../../../../store/slices/noticesModalUI/modes/usersModeSlice";
import UserListItem from "./UserListItem"
import PreviewNotice from "../../../../components/DistributionNoticeModal/PreviewNotice";

/**
 * Componente para mostrar a quien se le enviara la notificacion
 * 
 * @returns 
 */
const SendNoticeStep = () => {

    ///////////////////// SHARED STATE //////////////////////

    /**
     * Elementos de los pasos que ha sido seleccionados 
     */
    let previewItems = useSelector(selectPreviewItems)

    return (
        <Box
        >
            <Box
                style={{
                    marginTop: "10px",
                    textAlign: "center",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                }}
                sx={{ mb: 2 }}
            >
                <Typography
                    variant="h5"
                    component="div"
                    style={{ width: "100%", color: "#000" }}
                >
                    Un último vistazo
                </Typography>
            </Box>

            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="space-evenly"
                alignItems="baseline"
            >
                <PreviewNotice />
                <List
                    sx={{
                        bgcolor: 'background.paper',
                        overflow: 'auto',
                        maxHeight: 300,
                    }}
                >
                    {
                        previewItems.map((user) => {
                            return (
                                <UserListItem user={user} />
                            )
                        })
                    }
                </List>
            </Stack>
        </Box>
    )
}


export default SendNoticeStep