import React from "react";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";

const AvisoPrivacidad = () => {
  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={1}>
        <Grid item xl={2} sm={2} md={2} xs={2}></Grid>
        <Grid
          item
          xl={10}
          sm={10}
          md={10}
          xs={10}
          style={{ textAlign: "justify" }}
        >
          <h1 id="titulo">AVISO DE PRIVACIDAD</h1>
          <Typography
            variant="p"
            component="div"
            style={{ width: "90%", color: "#000" }}
          >
            Estimado usuario: <br /> <br />
            De conformidad con lo establecido en los artículos 1, 2, 3
            fracciones I, II, V, IX y XIV, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20 y 21 de Ley Federal de Protección de Datos Personales en Posesión
            de Particulares, Cura pone a su disposición el siguiente aviso de
            privacidad.
            <br /> <br />
            Cura, es responsable el uso y protección de sus datos personales,
            en ese sentido y atendiendo a las obligaciones legales establecidas
            en la Ley Federal de Protección de Datos Personales en Posesión de
            Particulares, a través de este instrumento se informa a los
            titulares de los datos, la información que de ellos se recaba y los
            fines que se le darán a dicha información.
            <br /> <br />
            Además de lo anterior, informarle a Usted que Cura tiene su
            domicilio ubicado en XXXX
            <br />
            <br />
            Los datos personales que recabamos de Usted serán utilizados además
            para la principal finalidad que se oferta como servicio, como lo es
            el nutrir la base de datos con la que trabaja la aplicación
            tecnológica Cura, las cuales son necesarias, relevantes y
            adecuados para concretar nuestra relación con Usted, así como para
            atender los servicios y/o solicitudes que nos realice.
            <br /> <br />
            Para llevar a cabo las finalidades referidas en el presente aviso de
            privacidad, utilizaremos los siguientes datos personales: <br />
            <br />
            • Nombre <br />
            • Número de teléfono <br />
            • Dirección <br />
            • Correo electrónico <br />
            <br />
            <br />
            Por otra parte, informamos a Usted que sus datos personales no serán
            compartidos con ninguna autoridad, empresa, organización o personas
            distintas a nosotros y serán utilizados exclusivamente para los
            fines señalados.
            <br /> <br />
            Usted tiene en todo momento el derecho a conocer que datos
            personales tenemos de Usted, para que los utilizamos y las
            condiciones del uso que les damos (Acceso). Asimismo, es su derecho
            solicitar la corrección de su información personal en caso de que
            este desactualizada, sea inexacta o incompleta (Rectificación); de
            igual manera, tiene derecho a que su información se elimine de
            nuestros registros o nuestra base de datos cuando considere que la
            misma no está siendo utilizada adecuadamente (Cancelación); así como
            también a oponerse al uso de sus datos personales para fines
            específicos (Oposición). Estos derechos se conocen como derechos
            ARCO.
            <br />
            <br />
            Para ejercicio de cualquiera de los derechos ARCO, se deberá
            presentar la solicitud respectiva a través de la siguiente dirección
            de correo electrónico:{" "}
          
            <strong>curati2023@gmail.com </strong>
            <br />
            <br />
            En todo caso, cualquier procedimiento para el ejercicio de los
            derechos ARCO, deberá ser respondido por Cura en un plazo máximo
            de veinte días, contados desde la fecha en que se recibió la
            solicitud de acceso, rectificación, cancelación u oposición, la
            determinación adoptada, a efecto de que, si resulta procedente, se
            haga efectiva la misma dentro de los quince días siguientes a la
            fecha en que se comunica la respuesta. Tratándose de solicitudes de
            acceso a datos personales, procederá la entrega previa acreditación
            de la identidad del solicitante o representante legal, según
            corresponda. Los plazos antes referidos podrán ser ampliados una
            sola vez por un periodo igual, siempre y cuando así lo justifiquen
            las circunstancias del caso.
            <br />
            <br />
            Los datos de contacto de la persona o departamento de datos
            personales, que está a cargo de dar trámite a las solicitudes de
            derechos ARCO, son los siguientes: <br />
            <br />
            Nombre:
            <br />
            <br />
            Domicilio:
            <br />
            <br />
            Teléfono:
            <br />
            <br />
            <br />
            abe mencionar que, en cualquier momento puede Usted revocar su
            consentimiento para el uso de sus datos personales. Del mismo modo,
            Usted puede revocar el consentimiento que, en su caso, nos haya
            otorgado para el tratamiento de sus datos personales.
            <br />
            <br />
            Asimismo, Usted deberá considerar que para ciertos fines la
            revocación de su consentimiento implicará que no podamos seguir
            prestando el servicio que se nos solicitó, o la conclusión de su
            relación con Cura.
            <br />
            <br />
            <br />
            Para efecto de revocar el consentimiento que Usted otorga en este
            acto o para limitar su divulgación, se deberá presentar la solicitud
            respectiva a través de la siguiente dirección de correo electrónico:{" "}
            <strong>curati2023@gmail.com </strong>
            <br />
            <br />
            Del mismo modo, podrá solicitar la información para conocer el
            procedimiento y requisitos para la revocación del consentimiento,
            así como para limitar el uso y divulgación de su información
            personal. En cualquier caso, la respuesta a las peticiones se dará a
            conocer en un plazo máximo de veinte días.
            <br />
            <br />
            Te sugerimos conocer y analizar el contenido de la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares pues
            pueden generarse cambios normativos a los que estamos sujetos.
            <br />
            <br />
            <br />
            Derivado de nuevos requerimientos legales, de nuevas disposiciones
            administrativas o de otra índole, el presente Aviso de Privacidad
            puede sufrir modificaciones, cambios o actualizaciones; en este
            sentido, Cura, se compromete a mantenerlo informado sobre los
            cambios que pueda sufrir el presente, sin embargo, Usted puede
            solicitar información sobre si el mismo ha sufrido algún cambio a
            través de la siguiente dirección de correo electrónico:{" "}
            <strong>curati2023@gmail.com </strong>
            <br />
            <br />
            Por este conducto y con base en lo anterior los titulares de los
            datos aceptan el tratamiento de sus datos por este Sujeto Obligado.
            <br />
            <br />
            Actualización: Marzo 2023
          </Typography>
        </Grid>
        <Grid item xl={2} sm={2} md={2} xs={2}></Grid>
      </Grid>
    </Box>
  );
};

export default AvisoPrivacidad;
