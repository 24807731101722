import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Tooltip,
    Card,
    CardContent,
    Avatar,
    CardActions,
    Tab,
    Grid,
    Divider,
    Tabs,
    Stack,
    IconButton,
    Menu,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Collapse,
    LinearProgress,
} from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid, esES, GridActionsCellItem } from '@mui/x-data-grid';
import {
    faGraduationCap,
    faBookMedical,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import ListIcon from '@mui/icons-material/List';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import SyncIcon from '@mui/icons-material/Sync';
import SkeletonPage from './components/SkeletonPage';
import { getLevelName, getTurnLevel } from '../../libs/utils';
import { Error } from '../../components/Feedback';
import { selectAssessors } from '../../store/slices/entities/users';
import useAuth from '../../hooks/useAuth';
import useFeedback from '../../hooks/useFeedback';
import AddGroupModal from './components/AddGroupModal';
import AddGroupStepperModal from './components/AddGroupStepperModal';
import StudentsModal from './components/StudentsModal';
import ChangeAssessorsGroupModal from './components/ChangeAssessorsGroupModal';
import SubjectsModal from './components/SubjectsModal';
import AssingSubjectsModal from './components/AssingSubjectsModal';
import AddStudentModal from './components/AddStudentModal';
import AddNewModuleModal from './components/AddNewModuleModal';
import AddExistingModuleModal from './components/AddExistingModuleModal';
import UpdateGroupStepperModal from './components/UpdateGroupStepperModal';
import ChangesTypeGroupModal from './components/ChangesTypeGroupModal';
import { GroupsToolBar } from './components/GroupsToolBar';
import { getAttendanceList } from '../../helper';
import * as constants from './../../service/const';
import {
    loadUI,
    invalidate,
    selectStatusServer,
} from '../../store/slices/groupsUI/serverSlice';
import { selectAllGroupsItems } from '../../store/slices/groupsUI/itemSlice';
import { selectGroupItem } from '../../store/slices/groupsUI/uiSlice';
import Exportable from './../../service/Exportable';
import { saveAs } from 'file-saver';
import Feedback from '../../service/Feedback';
import FeatureFlags from '../../service/FeatureFlags';
import { TabContext, TabPanel } from '@mui/lab';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ModuleDetailsModal from './components/ModuleDetailsModal';
import { updateFilter } from '../../store/slices/groupsUI/uiSlice';
/**
 * Pagina de grupos
 *
 * @returns
 */
const Groups = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const feedbackApp = useFeedback();
    const Auth = useAuth();

    ///////////////// LOCAL STATE /////////////////

    const [openMaterias, setOpenMaterias] = useState(false);
    const [openGrupo, setOpenGrupo] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openAlumnos, setOpenAlumnos] = useState(false);
    const [openAsignarMaterias, setOpenAsignarMaterias] = useState(false);
    const [openAddStudentModal, setOpenAddStudentModal] = useState(false);
    const [grupoSelect, setGrupoSelect] = useState(null);
    const [openAddGroupStepperModal, setOpenAddSpecialGroupsModal] =
        useState(false);
    const [openUpdateGroupStepperModal, setOpenUpdateGroupStepperModal] =
        useState(false);
    const [changesTypeGroup, setChangesTypeGroup] = useState();
    const [openChangeTypeGroup, setOpenChangeTypeGroup] = useState(false);
    const [value, setValue] = useState('1');
    const [currentTab, setCurrentTab] = useState('1');
    const [openAddNewModuleModal, setOpenAddNewModuleModal] = useState(false);
    const [openAddExistingModuleModal, setOpenAddExistingModuleModal] =
        useState(false);
    const [openModuleDetailsModal, setOpenModuleDetailsModal] = useState(false);

    ///////////////// SHARES STATE ////////////////////

    const groups = useSelector(selectAllGroupsItems);
    const assessors = useSelector(selectAssessors);
    const statusServer = useSelector(selectStatusServer);
    const filtersUI = useSelector((state) => state.groupsUI.ui);

    /////////////////// ACTIONS //////////////////////////

    const showAddNewGroupModal = () => {
        setOpenAddSpecialGroupsModal(true);
        setOpenGrupo(true);
    };

    const showAddNewModuleModal = (group) => {
        setGrupoSelect(group);
        setOpenAddNewModuleModal(true);
    };

    const showAddExistingModuleModal = (group) => {
        setGrupoSelect(group);
        setOpenAddExistingModuleModal(true);
    };

    /**
     * Abrir el modal para actualizar un grupo
     */
    const showUpdateGroupStepperModal = (groups) => {
        setGrupoSelect(groups);
        setOpenUpdateGroupStepperModal(true);
    };

    /**
     * Abrir modal para cambiar tipo de grupo
     */
    const showChangeTypeGroups = (groups) => {
        setChangesTypeGroup(groups);
        setOpenChangeTypeGroup(true);
    };

    const getTypeLabel = (row) => {
        if (row && row.group_type === 2) {
            return 'Convertir a grupo extraescolar';
        } else if (row && row.group_type === 1) {
            return 'Convertir tipo de grupo';
        } else {
            return 'Convertir a grupo especial';
        }
    };

    const isNormal = (row) => row && row.group_type === 1;
    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate());
        reload();
    };

    /**
     * Funcion para recargar los datos del usuario
     */
    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };
    /**
     * Inicia el proceso de recuperacion de datos
     */
    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id));
    }, [history]);

    /**
     * Mostrar modal para actualizar el asesor
     * @param {*} data
     */
    const cambiarAsesor = (data) => {
        setGrupoSelect(data.group_id);
        setOpenModal(true);
    };

    function exportNow({ format, group_id, report }) {
        let FeedbackService = new Feedback();
        Exportable.export(report, format, { group_id })
            .then((result) => {
                const ext = format === 'xlsx' ? '.xlsx' : '.pdf';
                exportToFile(result.data, `Reporte${ext}`);
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title: FeedbackService.getMessage(error).title,
                });
            });
    }

    const getCombinedGroup = (combinedValue) => {
        const option = constants.assistanceModality.find(
            (opt) => opt.value === combinedValue
        );
        return option ? option.label : 'Sin Modalidad de lista de asistencia';
    };

    /**
     * Exportar el archivo
     *
     * @param {*} blob
     * @param {*} filename
     */
    function exportToFile(blob, filename) {
        try {
            saveAs(blob, filename);
        } catch (err) {
            throw err;
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setCurrentTab(newValue);
    };

    const handleFilterChange = (event, value) => {
        let newFilterValues = {};

        event.items.forEach((filter) => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value,
            };
        });
        dispatch(updateFilter({ value, filter: newFilterValues }));
    };

    const renderGroupsTab = (value, groups, columns) => {
        const filteredGroups = groups
            .filter((grupo) => {
                return grupo.group_type === parseInt(value);
            })
            .sort((a, b) => {
                if (a.level !== b.level) {
                    return a.level - b.level;
                }
                if (a.turn !== b.turn) {
                    return a.turn - b.turn;
                }
                if (a.grade !== b.grade) {
                    return a.grade - b.grade;
                }
                return a.group.localeCompare(b.group);
            });

        const filtersForValue = filtersUI.filters[value];

        const filtersModified =
            filtersForValue &&
            (filtersForValue.columnField !== '' ||
                filtersForValue.operatorValue !== '' ||
                filtersForValue.value !== '');

        return (
            <TabPanel value={value}>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    getRowId={({ group_id }) => group_id}
                    rows={filteredGroups}
                    columns={columns}
                    pageSize={10}
                    disableSelectionOnClick
                    autoHeight
                    components={{ Toolbar: GroupsToolBar }}
                    componentsProps={{
                        panel: {
                            placement: 'bottom-end',
                        },
                    }}
                    onFilterModelChange={(event) =>
                        handleFilterChange(event, value)
                    }
                    initialState={{
                        filter: {
                            filterModel: (() => {
                                const items = [];
                                if (filtersModified) {
                                    if (
                                        filtersForValue.columnField &&
                                        filtersForValue.operatorValue &&
                                        filtersForValue.value
                                    ) {
                                        items.push({
                                            columnField:
                                                filtersForValue.columnField,
                                            operatorValue:
                                                filtersForValue.operatorValue,
                                            value: filtersForValue.value,
                                        });
                                    }
                                }
                                return { items };
                            })(),
                        },
                    }}
                    disableDensitySelector
                />
            </TabPanel>
        );
    };

    const BasicMenu = ({ params }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [openModules, setOpenModules] = useState(false);
        const [OpenStudentConfig, setOpenStudentConfig] = useState(false);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleClickStudentConfig = () => {
            setOpenStudentConfig(!OpenStudentConfig);
        };

        const handleClickModules = () => {
            setOpenModules(!openModules);
        };

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                    aria-label="more"
                    aria-controls={anchorEl ? 'long-menu' : undefined}
                    aria-expanded={anchorEl ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    style={{ display: anchorEl ? 'block' : 'none' }}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <List
                        sx={{
                            width: '100%',
                            maxWidth: 360,
                            bgcolor: 'background.paper',
                            maxHeight: '300px',
                            overflowY: 'auto',
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItemButton
                            onClick={() => {
                                dispatch(selectGroupItem(params.id));
                                setOpenAsignarMaterias(true);
                            }}
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faBookMedical}
                                    size="lg"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="Agregar materia"
                                sx={{ fontSize: '14px' }}
                            />
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => {
                                dispatch(selectGroupItem(params.id));
                                setOpenAddStudentModal(true);
                            }}
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faGraduationCap}
                                    size="lg"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="Agregar alumno"
                                sx={{ fontSize: '14px' }}
                            />
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => {
                                showUpdateGroupStepperModal(params.row);
                                setOpenUpdateGroupStepperModal(true);
                            }}
                        >
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Editar"
                                sx={{ fontSize: '14px' }}
                            />
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => {
                                const groupId = params.id;
                                exportNow({
                                    format: 'xlsx',
                                    group_id: groupId,
                                    title: 'Tallas por grupo',
                                    report: 'pie_group_size',
                                });
                            }}
                        >
                            <ListItemIcon>
                                <LineWeightIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Exportar tallas"
                                sx={{ fontSize: '14px' }}
                            />
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => {
                                if (!isNormal(params.row)) {
                                    showChangeTypeGroups(params.row);
                                    setOpenChangeTypeGroup(true);
                                }
                            }}
                            showInMenu
                            hidden={isNormal(params.row)}
                        >
                            <ListItemIcon>
                                <PublishedWithChangesIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={getTypeLabel(params.row)}
                                sx={{ fontSize: '14px' }}
                            />
                        </ListItemButton>

                        {params.row.group_type === 1 && (
                            <>
                                <ListItemButton
                                    onClick={handleClickStudentConfig}
                                >
                                    <ListItemIcon>
                                        <ViewModuleIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Módulos"
                                        sx={{ fontSize: '14px' }}
                                    />
                                    {OpenStudentConfig ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={OpenStudentConfig}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <ListItemButton
                                            onClick={() => {
                                                showAddNewModuleModal(
                                                    params.row
                                                );
                                                setOpenAddNewModuleModal(true);
                                            }}
                                        >
                                            <ListItemIcon>
                                                <PlaylistAddIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Agregar módulo nuevo"
                                                sx={{ fontSize: '14px' }}
                                            />
                                        </ListItemButton>
                                        <ListItemButton
                                            onClick={() => {
                                                showAddExistingModuleModal(
                                                    params.row
                                                );
                                                setOpenAddExistingModuleModal(
                                                    true
                                                );
                                            }}
                                        >
                                            <ListItemIcon>
                                                <PlaylistAddCheckIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Asignar módulo existente"
                                                sx={{ fontSize: '14px' }}
                                            />
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </>
                        )}
                    </List>
                </Menu>
            </div>
        );
    };

    const LinearProgressBar = ({ value, total }) => {
        const percentage =
            total !== 0 ? Math.round((value / total) * 100) : 100;

        let color;
        let textColor;
        let textShadow;

        if (value === 0) {
            color = '#FF6060';
            textColor = '#FFEBEE';
            textShadow = '0 0 3px #840005';
        } else if (value === total) {
            color = '#00C483';
            textColor = '#A7FFEB';
            textShadow = '0 0 3px #840005';
        } else if (value >= total / 2) {
            color = '#FFBF43';
            textColor = '#FFF9C4';
            textShadow = '0 0 3px #840005';
        } else {
            color = '#FF6060';
            textColor = '#FFEBEE';
            textShadow = '0 0 3px #840005';
        }

        return (
            <div style={{ width: '100%', position: 'relative' }}>
                <LinearProgress
                    variant="determinate"
                    value={percentage}
                    style={{
                        width: '100%',
                        backgroundColor:
                            value === 0 ? '#FF6060' : 'transparent',
                        borderRadius: '10px',
                        height: '25px',
                    }}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: color,
                            borderRadius: '10px',
                        },
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: textColor,
                        fontWeight: 'bold',
                        fontSize: '1.2em',
                        textShadow: textShadow,
                    }}
                >
                    {`${value}/${total}`}
                </div>
            </div>
        );
    };

    const featureFlagCombinedColumn = FeatureFlags.isFeatureFlagActive(
        'NEW_COLUMN_COMBINED'
    );

    const featureFlagEnableNewModalGroups = FeatureFlags.isFeatureFlagActive(
        'NEW_GROUPS_MODULE_WITH_ATTACHED'
    );

    const featureFlagEnabledChangeGroupType = FeatureFlags.isFeatureFlagActive(
        'NEW_CHANGE_TYPE_GROUPS'
    );

    const featureFlagModuleModals = FeatureFlags.isFeatureFlagActive(
        'NEW_ADD_MODULE_MODALS'
    );

    const columns = [];

    if (featureFlagEnableNewModalGroups) {
        columns.push(
            {
                field: 'grade',
                headerName: 'Grado',
                flex: 0.4,
                minWidth: 50,
                editable: false,
                headerAlign: 'center',
                align: 'center',
            },
            {
                field: 'group',
                headerName: 'Grupo',
                flex: currentTab != 1 ? 0.4 : 0.5,
                minWidth: currentTab != 1 ? 200 : 100,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => {
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {params.value}
                        </div>
                    );
                },
            },
            {
                field: 'turn',
                headerName: 'Turno',
                type: 'singleSelect',
                flex: 0.5,
                minWidth: 70,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                valueOptions: constants.turnsNames.map((i) => i.title),
                valueGetter: (params) => {
                    return getTurnLevel(params.row.turn);
                },
            },
            {
                field: 'level',
                headerName: 'Nivel',
                type: 'singleSelect',
                flex: 0.6,
                minWidth: 80,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                valueOptions: constants.levelsNames.map((i) => i.title),
                valueGetter: (params) => {
                    return getLevelName(params.row.level);
                },
            },
            {
                field: 'assessor',
                headerName: 'Orientador Educativo',
                flex: 1,
                minWidth: 190,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params) => {
                    if (params.row.assessor_id) {
                        let groupAssessor = assessors.find(
                            (i) => i.assessor_id == params.row.assessor_id
                        );
                        return `${groupAssessor?.name || ''} ${
                            groupAssessor?.last_name || ''
                        } ${groupAssessor?.second_last_name || ''}`;
                    }
                    return 'Sin orientador';
                },
                renderCell: (params) => {
                    const asessor = assessors.find(
                        (i) => i.assessor_id == params.row.assessor_id
                    );
                    return (
                        <>
                            {params.row.assessor_id != null &&
                                params.row.assessor_id != 0 && (
                                    <Tooltip
                                        title={'Cambiar a ' + params.value}
                                    >
                                        <Button
                                            sx={{
                                                flexWrap: 'wrap',
                                            }}
                                            style={{
                                                textTransform: 'none',
                                            }}
                                            variant="text"
                                            onClick={() => {
                                                dispatch(
                                                    selectGroupItem(params.id)
                                                );
                                                cambiarAsesor(params.row);
                                            }}
                                            startIcon={
                                                <Avatar
                                                    sx={{
                                                        width: 24,
                                                        height: 24,
                                                    }}
                                                    src={
                                                        asessor?.url_photo_profile
                                                    }
                                                />
                                            }
                                        >
                                            {params.value}
                                        </Button>
                                    </Tooltip>
                                )}
                            {(params.row.assessor_id == null ||
                                params.row.assessor_id == 0) && (
                                <Tooltip title={'Asignar un orientador'}>
                                    <Button
                                        style={{ textTransform: 'none' }}
                                        variant="text"
                                        onClick={() => {
                                            dispatch(
                                                selectGroupItem(params.id)
                                            );
                                            cambiarAsesor(params.row);
                                        }}
                                    >
                                        Sin Orientador
                                    </Button>
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                field: 'SubjectswithOutProfessors',
                headerName: 'Asignación',
                type: 'singleSelect',
                flex: 0.6,
                minWidth: 100,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                description: 'Asignación de profesores a materias',
                renderCell: (params) => {
                    const subjectsWithoutProfessors =
                        params.row.subjects_without_professors;
                    const totalSubjects = params.row.subjects;
                    let subjectsWithProfessors =
                        totalSubjects - subjectsWithoutProfessors;

                    if (subjectsWithProfessors < 0) {
                        subjectsWithProfessors = 0;
                    }

                    return (
                        <LinearProgressBar
                            value={subjectsWithProfessors}
                            total={totalSubjects}
                        />
                    );
                },
            },
            {
                field: 'subjects',
                headerName: 'Materias',
                flex: 0.4,
                minWidth: 60,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <Tooltip title="Mostrar materias">
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ListIcon />}
                            onClick={() => {
                                dispatch(selectGroupItem(params.id));
                                setOpenMaterias(true);
                            }}
                        >
                            {params.value}
                        </Button>
                    </Tooltip>
                ),
            },
            {
                field: 'students',
                headerName: 'Alumnos',
                flex: 0.4,
                minWidth: 60,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <>
                        <Tooltip title="Mostrar alumnos">
                            <Button
                                size="small"
                                variant="outlined"
                                startIcon={<ListIcon />}
                                onClick={() => {
                                    dispatch(selectGroupItem(params.id));
                                    setOpenAlumnos(true);
                                }}
                            >
                                {params.value}
                            </Button>
                        </Tooltip>
                    </>
                ),
            },
            {
                field: 'acciones',
                headerName: 'Acciones',
                sortable: false,
                flex: 0.4,
                minWidth: 50,
                headerAlign: 'center',
                align: 'center',
                type: 'actions',
                renderCell: (params) => <BasicMenu params={params} />,
            }
        );
    } else {
        columns.push(
            {
                field: 'grade',
                headerName: 'Grado',
                flex: 0.4,
                minWidth: 80,
                editable: false,
                headerAlign: 'center',
                align: 'center',
            },
            {
                field: 'group',
                headerName: 'Grupo',
                flex: 0.4,
                minWidth: 80,
                editable: false,
                headerAlign: 'center',
                align: 'center',
            },
            {
                field: 'turn',
                headerName: 'Turno',
                type: 'singleSelect',
                flex: 0.5,
                minWidth: 80,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                valueOptions: constants.turnsNames.map((i) => i.title),
                valueGetter: (params) => {
                    return getTurnLevel(params.row.turn);
                },
            },
            {
                field: 'level',
                headerName: 'Nivel',
                type: 'singleSelect',
                flex: 0.6,
                minWidth: 100,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                valueOptions: constants.levelsNames.map((i) => i.title),
                valueGetter: (params) => {
                    return getLevelName(params.row.level);
                },
            },
            {
                field: 'assessor',
                headerName: 'Orientador Educativo',
                flex: 1,
                minWidth: 250,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params) => {
                    if (params.row.assessor_id) {
                        let groupAssessor = assessors.find(
                            (i) => i.assessor_id == params.row.assessor_id
                        );
                        return `${groupAssessor?.name || ''} ${
                            groupAssessor?.last_name || ''
                        } ${groupAssessor?.second_last_name || ''}`;
                    }
                    return 'Sin orientador';
                },
                renderCell: (params) => {
                    const asessor = assessors.find(
                        (i) => i.assessor_id == params.row.assessor_id
                    );
                    return (
                        <Tooltip title="Mostrar orientador">
                            <>
                                {params.row.assessor_id != null &&
                                    params.row.assessor_id != 0 && (
                                        <Tooltip
                                            title={'Cambiar a ' + params.value}
                                        >
                                            <Button
                                                style={{
                                                    textTransform: 'none',
                                                }}
                                                variant="text"
                                                onClick={() => {
                                                    dispatch(
                                                        selectGroupItem(
                                                            params.id
                                                        )
                                                    );
                                                    cambiarAsesor(params.row);
                                                }}
                                                startIcon={
                                                    <Avatar
                                                        sx={{
                                                            width: 24,
                                                            height: 24,
                                                        }}
                                                        src={
                                                            asessor?.url_photo_profile
                                                        }
                                                    />
                                                }
                                            >
                                                {params.value}
                                            </Button>
                                        </Tooltip>
                                    )}
                                {(params.row.assessor_id == null ||
                                    params.row.assessor_id == 0) && (
                                    <Tooltip title={'Asignar un orientador'}>
                                        <Button
                                            style={{ textTransform: 'none' }}
                                            variant="text"
                                            onClick={() => {
                                                dispatch(
                                                    selectGroupItem(params.id)
                                                );
                                                cambiarAsesor(params.row);
                                            }}
                                        >
                                            Sin Orientador
                                        </Button>
                                    </Tooltip>
                                )}
                            </>
                        </Tooltip>
                    );
                },
            },
            {
                field: 'subjects',
                headerName: 'Materias',
                flex: 0.4,
                minWidth: 80,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <Tooltip title="Mostrar materias">
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ListIcon />}
                            onClick={() => {
                                dispatch(selectGroupItem(params.id));
                                setOpenMaterias(true);
                            }}
                        >
                            {params.value}
                        </Button>
                    </Tooltip>
                ),
            },
            {
                field: 'students',
                headerName: 'Alumnos',
                flex: 0.4,
                minWidth: 80,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <>
                        <Tooltip title="Mostrar alumnos">
                            <Button
                                size="small"
                                variant="outlined"
                                startIcon={<ListIcon />}
                                onClick={() => {
                                    dispatch(selectGroupItem(params.id));
                                    setOpenAlumnos(true);
                                }}
                            >
                                {params.value}
                            </Button>
                        </Tooltip>
                    </>
                ),
            },
            {
                field: 'acciones',
                headerName: 'Acciones',
                sortable: false,
                flex: 0.4,
                minWidth: 50,
                headerAlign: 'center',
                align: 'center',
                type: 'actions',
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={
                            <FontAwesomeIcon icon={faBookMedical} size="lg" />
                        }
                        title="Agregar materia"
                        label="Agregar materia"
                        onClick={() => {
                            dispatch(selectGroupItem(params.id));
                            setOpenAsignarMaterias(true);
                        }}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={
                            <FontAwesomeIcon icon={faGraduationCap} size="lg" />
                        }
                        title="Agregar alumno"
                        label="Agregar alumno"
                        onClick={() => {
                            dispatch(selectGroupItem(params.id));
                            setOpenAddStudentModal(true);
                        }}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        title="Editar"
                        label="Editar"
                        onClick={() => {
                            dispatch(selectGroupItem(params.id));
                            setOpenGrupo(true);
                        }}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<LineWeightIcon />}
                        title="Exportar tallas"
                        label="Exportar tallas"
                        onClick={() => {
                            const groupId = params.id;
                            exportNow({
                                format: 'xlsx',
                                group_id: groupId,
                                title: 'Tallas por grupo',
                                report: 'pie_group_size',
                            });
                        }}
                        showInMenu
                    />,
                ],
            }
        );
    }

    if (featureFlagCombinedColumn) {
        if (currentTab == 1) {
            const combinedColumn = {
                field: 'combined',
                headerName: 'Mod. asistencia',
                type: 'singleSelect',
                flex: 0.2,
                minWidth: 150,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                valueOptions: constants.assistanceModality.map((i) => i.label),
                valueGetter: (params) => {
                    return params.row.combined === true
                        ? 'Por materia del profesor'
                        : getAttendanceList(params.row.combined);
                },
                renderCell: (params) => {
                    const combined = getCombinedGroup(params.row.combined);
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {combined}
                        </div>
                    );
                },
            };

            columns.splice(4, 0, combinedColumn);
        }
    }

    if (currentTab == 1) {
        const modulesColumn = {
            field: 'modules',
            headerName: 'Módulos',
            type: 'singleSelect',
            flex: 0.4,
            minWidth: 60,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Tooltip title="Mostrar módulos">
                    <Button
                        size="small"
                        variant="outlined"
                        startIcon={<ListIcon />}
                        onClick={() =>
                            handlerShowModulesDetailsModal(params.row.group_id)
                        }
                    >
                        {params.row.total_modules}
                    </Button>
                </Tooltip>
            ),
        };

        columns.splice(7, 0, modulesColumn);
    }

    // if (currentTab == 1) {
    //     const subjectsWithOutProfessorColumn = ;

    //     columns.splice(6, 0, subjectsWithOutProfessorColumn);
    // }

    if (currentTab == 2) {
        const modulesColumn = {
            field: 'annexed_name',
            headerName: 'Nombre especial',
            flex: 0.4,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}
                    >
                        {params.value}
                    </div>
                );
            },
        };

        columns.splice(2, 0, modulesColumn);
    }

    const handlerShowModulesDetailsModal = (groupId) => {
        dispatch(selectGroupItem(groupId));
        setGrupoSelect(groupId);
        setOpenModuleDetailsModal(true);
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            <ChangeAssessorsGroupModal
                open={openModal}
                setOpen={setOpenModal}
                groupId={grupoSelect}
            />

            <SubjectsModal open={openMaterias} setOpen={setOpenMaterias} />

            {featureFlagEnableNewModalGroups ? (
                <AddGroupStepperModal
                    open={openAddGroupStepperModal}
                    setOpen={setOpenAddSpecialGroupsModal}
                />
            ) : (
                <AddGroupModal open={openGrupo} setOpen={setOpenGrupo} />
            )}

            {featureFlagEnableNewModalGroups && (
                <UpdateGroupStepperModal
                    open={openUpdateGroupStepperModal}
                    setOpen={setOpenUpdateGroupStepperModal}
                    groupId={grupoSelect}
                />
            )}

            {featureFlagEnabledChangeGroupType && (
                <ChangesTypeGroupModal
                    open={openChangeTypeGroup}
                    setOpen={setOpenChangeTypeGroup}
                    groupId={changesTypeGroup}
                />
            )}

            <StudentsModal open={openAlumnos} setOpen={setOpenAlumnos} />

            <AssingSubjectsModal
                open={openAsignarMaterias}
                setOpen={setOpenAsignarMaterias}
                groupId={grupoSelect}
            />

            <AddStudentModal
                open={openAddStudentModal}
                setOpen={() => {
                    setOpenAddStudentModal(false);
                }}
                groupId={grupoSelect}
            />

            {featureFlagModuleModals && (
                <>
                    {grupoSelect && (
                        <>
                            <AddNewModuleModal
                                open={openAddNewModuleModal}
                                setOpen={setOpenAddNewModuleModal}
                                group={grupoSelect}
                            />
                            <AddExistingModuleModal
                                open={openAddExistingModuleModal}
                                setOpen={setOpenAddExistingModuleModal}
                                group={grupoSelect}
                            />
                        </>
                    )}
                </>
            )}

            {openModuleDetailsModal && (
                <ModuleDetailsModal
                    open={openModuleDetailsModal}
                    setOpen={setOpenModuleDetailsModal}
                    groupId={grupoSelect}
                ></ModuleDetailsModal>
            )}

            {statusServer == 'pending' && <SkeletonPage />}

            {statusServer == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {statusServer == 'fulfilled' && (
                <>
                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <Tabs
                                value={currentTab}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                sx={{ display: { xs: 'none', sm: 'flex' } }}
                            >
                                <Tab label="Principales" value="1" />
                                <Tab label="Especiales" value="2" />
                                <Tab label="Extraescolares" value="3" />
                            </Tabs>
                            <Divider textAlign="left" />
                            <Tabs
                                value={currentTab}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                orientation="vertical"
                                sx={{ display: { xs: 'flex', sm: 'none' } }}
                            >
                                <Tab label="Principales" value="1" />
                                <Tab label="Especiales" value="2" />
                                <Tab label="Extraescolares" value="3" />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Stack
                                spacing={1}
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Tooltip title="Sincronizar información">
                                    <Button
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        onClick={invalidateUI}
                                        startIcon={<SyncIcon />}
                                    >
                                        Sincronizar
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Card
                        sx={{
                            mt: 5,
                        }}
                    >
                        <CardContent
                            sx={{
                                pt: 0,
                                pl: 0,
                                pr: 0,
                                pb: 0,
                                ' & .MuiDataGrid-root': {
                                    border: 'none',
                                },
                                ' & .MuiDataGrid-toolbarContainer': {
                                    justifyContent: 'flex-end',
                                },
                            }}
                        >
                            <TabContext value={value}>
                                {renderGroupsTab('1', groups, columns)}
                                {renderGroupsTab('2', groups, columns)}
                                {renderGroupsTab('3', groups, columns)}
                            </TabContext>
                        </CardContent>
                        <CardActions
                            sx={{
                                direction: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    showAddNewGroupModal(null);
                                }}
                                size="small"
                                startIcon={<AddIcon />}
                            >
                                Agregar
                            </Button>
                        </CardActions>
                    </Card>
                </>
            )}
        </Box>
    );
};

export default Groups;
