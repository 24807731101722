class FeatureFlagsManager {
    flags = {
        NEW_STUDENT_UI: process.env.REACT_APP_FEATURE_FLAGS_NEW_STUDENT_UI,
        NEW_SCORES_TABLE: process.env.REACT_APP_FEATURE_FLAGS_NEW_SCORES_TABLE,
        NEW_REPORTS_UI: process.env.REACT_APP_FEATURE_FLAGS_NEW_REPORTS_UI,
        NEW_IDLE_STORE_LOGOUT:
            process.env.REACT_APP_FEATURE_FLAGS_NEW_IDLE_STORE_LOGOUT,
        NEW_EXPORT_DOCUMENTS:
            process.env.REACT_APP_FEATURE_FLAGS_NEW_EXPORT_DOCUMENTS,
        NEW_ATTACHED_GROUPS:
            process.env.REACT_APP_FEATURE_FLAGS_NEW_ATTACHED_GROUPS,
        NEW_GROUPS_MODULE_WITH_ATTACHED:
            process.env.REACT_APP_FEATURE_FLAGS_NEW_GROUPS_MODULE_WITH_ATTACHED,
        NEW_REPORTS_STRCUTURE: process.env.REACT_APP_NEW_REPORTS_STRUCTURE,
        NEW_CHANGE_TYPE_GROUPS:
            process.env.REACT_APP_FEATURE_FLAGS_NEW_CHANGE_TYPE_GROUPS,
        NEW_IMPORT_TO_PIE_SCORES:
            process.env.REACT_APP_NEW_IMPORT_TO_PIE_SCORES,
        NEW_COLUMN_COMBINED: process.env.REACT_APP_NEW_COLUMN_COMBINED,
        NEW_ANNEXED_GROUPS_ON_SCORES_VIEW:
            process.env.REACT_APP_NEW_ANNEXED_GROUPS_ON_SCORES_VIEW,
        NEW_INDEX: process.env.REACT_APP_FEATURE_FLAGS_NEW_INDEX,
        NEW_ACTIVE_CURA_USER:
            process.env.REACT_APP_FEATURE_FLAGS_NEW_ACTIVE_CURA_USER,
        NEW_ADD_MODULE_MODALS:
            process.env.REACT_APP_FEATURE_FLAGS_NEW_ADD_MODULE_MODALS,
        NEW_PROFESSOR_MODULE:
            process.env.REACT_APP_FEATURE_FLAGS_NEW_PROFESSOR_MODULE,
        NEW_DIRECTORS: process.env.REACT_APP_FEATURE_FLAGS_NEW_DIRECTORS,
        NEW_PAYMENTS_MODULE:
            process.env.REACT_APP_FEATURE_FLAGS_NEW_PAYMENTS_MODULE,
        NEW_NOTICES_MODULE:
            process.env.REACT_APP_FEATURE_FLAGS_NEW_NOTICES_MODULE,
        NEW_REGULARIZATIONS_MODULE:
            process.env.REACT_APP_FEATURE_FLAGS_NEW_REGULARIZATIONS_MODULE,
    };

    /**
     * Verifica si una feacture flag esta activa
     * @param {*} feacture
     * @returns
     */
    isFeatureFlagActive(feacture) {
        return (
            this.flags[feacture] &&
            (this.flags[feacture] == true ||
                this.flags[feacture] == 'true' ||
                this.flags[feacture] == 1)
        );
    }
}

const featureFLagManager = new FeatureFlagsManager();

export default featureFLagManager;
