import { Box, Grid, IconButton, Card, CardContent } from "@mui/material";
import * as _ from "lodash";
import { useHistory, useRouteMatch, Route } from "react-router-dom";
import { esES, DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from 'react';
import Services from "./../../service/Connection";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { SupervisorToolBar } from "../Supervisiones/components";
import { Error } from "../../components/Feedback";
import SkeletonPage from './components/SkeletonPage';

/**
 * 
 * Pagina Supervisiones
 * 
 * @returns
 */
const Supervisiones = () => {
  const history = useHistory();
  const [school, setSchools] = useState([]);
  const match = useRouteMatch();
  const [loading, setLoading] = useState("pending");

  ///////////////// HOOKS EFFECTS ////////////////////

  useEffect(() => {
    Services.getHomeSupervisor().then(data => {

      let row = data.data.map((schoolRow, index) => {
        return { ...schoolRow, ...schoolRow.school }
      })
      setSchools(row)
      setLoading("fullfiled")
    })
  }, []);

  // Función para calcular el porcentaje
  const calcularPorcentaje = (valor1, valor2) => {
    if (valor2 === 0) {
      return 0; // En lugar de Infinity, lanza 0 en caso de división por cero.
    }
    const porcentaje = (valor1 / valor2) * 100;
    return porcentaje.toFixed(2);
  };

  /**
 * Columnas de la Tabla
 */
  const supervisor_columns = [
    {
      field: 'school_id',
      headerName: 'ID',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'clave',
      headerName: 'CCT',
      type: 'string',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'name',
      headerName: 'Escuela',
      type: 'string',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        return `${params.row.name}`;
      }
    },
    {
      field: 'last_supervition',
      headerName: 'Última Supervisión',
      type: 'date',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        if (params.row.last_supervition) {
          return params.row.last_supervition;
        }
        return "No Supervisado";
      }
    },
    {
      field: 'user',
      headerName: 'Supervisor',
      type: 'string',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        if (params.row.user) {
          return `${params.row.user.name || ''} ${params.row.user.last_name || ''}`;
        }
        return "Sin Supervisor";
      }
    },
    {
      field: 'total_students',
      headerName: 'Alumnos',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        return `${params.row.total_students}`;
      }
    },
    {
      field: 'calculo_capacitados',
      headerName: '% de capacitados',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const porcentaje = calcularPorcentaje(params.row.total_users_trained, params.row.total_users);
        return `${porcentaje} %`;
      },
    },
    {
      field: 'calculo_descargas',
      headerName: '% de descarga ',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const porcentaje = calcularPorcentaje(params.row.total_users_apk, params.row.total_users);
        return `${porcentaje} %`;
      },
    },
    {
      field: 'details',
      headerName: 'Detalle',
      filterable: false,
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <strong>
          <IconButton aria-label="Detalle" color="primary" onClick={goDetails(params)}>
            <Route path="/:id">
              <InsertLinkIcon />
            </Route>
          </IconButton>
        </strong>
      ),
    },
  ];

  /**
 * Funcion para ir al detalle
 * 
 * @param {*} params 
 * @returns 
 */
  const goDetails = (params) => () => {
    localStorage.setItem("DatosEscuela", JSON.stringify(params.row))
    history.push({ pathname: `${match.path}/Detalles/${params.id}` });
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      {loading === "pending" && <SkeletonPage />}
      {loading === "rejected" && <Error message={"Estamos teniendo problemas"} />}
      {loading === "fullfiled" && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent sx={{ pl: 0, pr: 0, pb: 0, '& .MuiDataGrid-root': { border: 'none' } }}>
                <DataGrid
                  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                  getRowId={({ school_id }) => school_id}
                  disableColumnFilter={false}
                  disableColumnSelector={false}
                  disableColumnMenu={false}
                  rows={school}
                  columns={supervisor_columns}
                  pageSize={10}
                  disableSelectionOnClick
                  autoHeight
                  components={{ Toolbar: SupervisorToolBar }}
                  componentsProps={{
                    panel: {
                      placement: "bottom-end"
                    },
                  }}
                  disableDensitySelector
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Supervisiones;