import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { convertArrayToObject } from '../../../../libs/utils';
import {
    PROFESSORS_HOME_EXPIRE_TIME,
    USERSUI_EXPIRE_TIME,
} from '../../../../service/const';
import Connection from '../../../../service/Connection';
import Feedback from '../../../../service/Feedback';
import { addOneItem, getEmptyItem, setAllItems } from './ItemsSlice';
import { setAllInformation, setManySubjects } from './entitiesSlice';
import orderBy from 'lodash/orderBy';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { setAllClassroomItems } from '../classrooms';
import { upsertManyGroup } from '../../../../store/slices/entities/groups';
import { upsertManySubjects } from '../../../../store/slices/entities/subjects';
import { upsertManySubjectcatalog } from '../../../../store/slices/entities/subjectcatalogs';
import { upsertManyReportsCatalogs } from '../../../../store/slices/entities/reportscatalogs';

let emptyState = {
    expireIn: null,
    ferchingAt: null,
    groups: null,
    subjects: null,
    status: 'idle',
    operation: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};
export const userSlice = createSlice({
    name: 'newProfessors',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        ////////////////// RECUPERAR GRUPOS //////////////////

        builder.addCase(newFetchInitialData.fulfilled, (state, action) => {
            state.expireIn = new Date().setMinutes(
                new Date().getMinutes() + PROFESSORS_HOME_EXPIRE_TIME
            );
            state.ferchingAt = Date.now();
            state.didInvalidate = false;
            state.status = 'fulfilled';
        });
        builder.addCase(newFetchInitialData.pending, (state, action) => {
            state.status = 'pending';
        });
        builder.addCase(newFetchInitialData.rejected, (state, action) => {
            state.status = 'rejected';
            //state.feedback = action.payload.feedback
        });
    },
});

export const {
    loadedData,
    loading,
    hideLoading,
    setAssists,
    setSubjects,
    setGroups,
    setCatalogSubject,
    setCalifications,
    setStudents,
    setFeedback,
    deleteFeedback,
    invalidate,
} = userSlice.actions;

export default userSlice.reducer;

///////////// SELECTORES  ////////////

/**
 * Selector para recuperar el fetch
 *
 * @param {*} state
 *
 * @returns
 */
export const selectFetch = (state) =>
    state.professorClassroom.meGroups.home.fetch;

/**
 * Selector para recuperar el estado del fetching
 *
 * @param {*} state
 *
 * @returns
 */
export const selectFetchingStatus = (state) => selectFetch(state).status;

export const newFetchInitialData = createAsyncThunk(
    'newFetchProfessorsData',
    async (data, thunkAPI) => {
        const { professorId, schoolId } = data;

        let FeedbackService = new Feedback();
        try {
            let groups = await Connection.getGroupsByProfessor(
                professorId
            ).then((i) => i.data.data);

            let subjects = [];
            for (let group of groups) {
                let groupSubjects = await Connection.getSubjectByGroup(
                    group.group_id,
                    { filters: { professor_id: professorId } }
                ).then((i) => i.data.data);
                group.subjectsItems = _.map(groupSubjects);

                subjects = subjects.concat(groupSubjects);
            }

            let subjectCatalogs = await Connection.getSubjectCatalogBySchool(
                schoolId
            ).then((i) => i.data.data);

            let reportsCatalogs = await Connection.getReportCatalogBySchool(
                schoolId
            ).then((i) => i.data.data);

            let mainGroupsIds = _.chain(groups)
                //.map("group")
                .filter(['group_type', 4])
                .uniqBy('group_id')
                .map('group_annexed_id')
                .value();

            let mainGroupsOfModuleGroups = await Connection.getGroupByIds(
                mainGroupsIds,
                schoolId
            ).then((i) => i.data.data);

            const classrooms = formatClassrooms(
                mainGroupsOfModuleGroups,
                groups,
                subjectCatalogs
            );

            let item = {};

            for (const subject of classrooms.classroomSubject) {
                let id = uuidv4();
                item[id] = {
                    id: id,
                    fetch: {
                        expireIn: null,
                        didInvalidate: true,
                        fetchingAt: null,
                        status: 'idle',
                    },
                    operations: {
                        saveAssists: 'idle',
                        saveScores: 'idle',
                    },
                    feedback: {
                        title: null,
                        message: null,
                        payload: null,
                    },
                    ui: {
                        group_id: subject.group_id,
                        subject_id: subject.subject_id,
                        classroomType: 1,
                    },
                };
            }

            let itemRomGroup = {};

            for (const group of classrooms.classroomGroup) {
                let id = uuidv4();

                itemRomGroup[id] = {
                    id: id,
                    fetch: {
                        expireIn: null,
                        didInvalidate: true,
                        fetchingAt: null,
                        status: 'idle',
                    },
                    operations: {
                        saveAssists: 'idle',
                        saveScores: 'idle',
                    },
                    feedback: {
                        title: null,
                        message: null,
                        payload: null,
                    },
                    ui: {
                        group_id: group.group_id,
                        subjectIds: _.map(group.subjects, 'subject_id'),
                        classroomType: 2,
                    },
                };
            }

            let itemClassRomGroups = {};

            let items = {
                ...item,
                ...itemRomGroup,
                ...itemClassRomGroups,
            };

            let allGroups = groups.concat(mainGroupsOfModuleGroups);
            thunkAPI.dispatch(upsertManyGroup(allGroups));
            thunkAPI.dispatch(upsertManySubjects(subjects));
            thunkAPI.dispatch(upsertManySubjectcatalog(subjectCatalogs));
            thunkAPI.dispatch(upsertManyReportsCatalogs(reportsCatalogs));
            thunkAPI.dispatch(setAllItems(Object.keys(items)));
            thunkAPI.dispatch(setAllClassroomItems(items));

            return {
                groups,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

const formatClassrooms = (
    mainGroupsOfModuleGroups,
    groupsWithSubjects,
    subjectCatalogs
) => {
    /**
     * Normalizamos los datos proveniente del services
     *
     * Solo los grupos con alumnos seran tomados en cuenta
     */
    let groups = _.chain(groupsWithSubjects)
        //.map('group')
        .thru((groupsList) => {
            return [...groupsList, ...mainGroupsOfModuleGroups];
        })
        .uniqBy('group_id')
        .filter(({ students }) => students > 0)
        .value();

    let subjects = _.chain(groupsWithSubjects)
        .map('subjectsItems')
        .flatten()
        .value();

    /**
     * Recuperamos las materias que seran usadas para generar los classroom de materias
     */
    let subjectsForClassroomSubject = _.chain(groups)
        .filter(({ group_type }) => group_type !== 4)
        .filter((group) => group.combined === 1)
        .map((group) => {
            return _.chain(subjects)
                .filter(['group_id', group.group_id])
                .map((subject) => ({
                    subject,
                    group,
                    catalog: _.find(subjectCatalogs, [
                        'catalog_subject_id',
                        subject.catalog_subject_id,
                    ]),
                }))
                .value();
        })
        .flatten()
        .filter((subjectForClassroom) => !_.isEmpty(subjectForClassroom))
        .thru((subjectsForClassroomSubject) => {
            let subjectsFromModule = _.chain(groups)
                .filter(['group_type', 4])
                .filter((group) => {
                    let mainGroup = _.find(groups, [
                        'group_id',
                        group.group_annexed_id,
                    ]);

                    return mainGroup.combined === 1;
                })
                .map((group) => {
                    return _.chain(subjects)
                        .filter(['group_id', group.group_id])
                        .map((subject) => ({
                            subject,
                            group,
                            catalog: _.find(subjectCatalogs, [
                                'catalog_subject_id',
                                subject.catalog_subject_id,
                            ]),
                        }))
                        .value();
                })
                .flatten()
                .value();

            return [...subjectsForClassroomSubject, ...subjectsFromModule];
        })
        .sortBy([
            ({ group }) => group.grade,
            ({ group }) => group.group,
            ({ group }) => group.turn,
            ({ group }) => group.level,
            ({ catalog }) => catalog.title,
        ])
        .map(({ subject }) => subject)
        .value();

    /**
     * Recuperamos los grupos que seran usados para generar los classroom de grupo
     */
    let groupsForClassroomGroup = _.chain(groups)
        .filter(['group_type', 1])
        .filter(['combined', 2])
        .map((group) => ({
            ...group,
            subjects: _.filter(subjects, ['group_id', group.group_id]),
        }))
        .sortBy([(o) => o.grade, (o) => o.group, (o) => o.turn, (o) => o.level])
        .value();

    /**
     * Recuperamos la abstraccion de grupos que sera usado para multinivel
     */
    let multilevelForClassroomGroups = [];

    return {
        classroomSubject: subjectsForClassroomSubject,
        classroomGroup: groupsForClassroomGroup,
        classroomLevel: multilevelForClassroomGroups,
    };
};
