import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { MoneyOffCsredOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { fetchConceptsWithoutPayment, selectConceptsWithoutPayment } from '../../../store/home/kpis';

const ToPayCard = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const schoolId = useSelector((state) => state.auth.user.school_id);

    useEffect(() => {
        if (schoolId) {
            dispatch(fetchConceptsWithoutPayment(schoolId));
        }
    }, [dispatch, schoolId]);

    const conceptsWithoutPaymentData = useSelector(selectConceptsWithoutPayment).value;

    return (
        <Card
            sx={{
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: theme.palette.background.default,
                color: theme.palette.neutral.main,
                maxWidth: '100%',
                mb: 2,
                height: 175,   

                padding: {
                    xs: 2,   
                    md: 3,  
                },
            }}
        >
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#E3242B',
                            borderRadius: '100%',
                            width: '2rem',
                            height: '2rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mr: 1.5,
                        }}
                    >
                        <MoneyOffCsredOutlined
                            sx={{
                                width: '1.5rem',
                                color: '#ffffff',
                            }}
                        />
                    </Box>
                    <Typography variant="h6" className="card-value" >
                        Por Pagar
                    </Typography>
                </Box>

                <Typography variant="h2" sx={{ mb: 1 }}>
                    ${conceptsWithoutPaymentData}{' '}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ToPayCard;
