import React, { useState } from 'react';
import SearchInputs from '../components/SearchInputs';
import { Box } from '@material-ui/core';
import Connection from '../../service/Connection';
import RegularizationsDataGrid from '../components/RegularizationsDataGrid';
import { useAuth, useFeedback } from '../../hooks';
import Feedback from '../../service/Feedback';

const Regularizations = () => {
    const Auth = useAuth();

    const feedbackApp = useFeedback();

    const [dataGridLoading, setDataGridLoading] = useState('idle');
    const [students, setStudents] = useState({
        regularizations: [],
        students: [],
    });
    const [cycle, setCycle] = useState('');

    const searchStudents = async (values) => {
        try {
            let students = await Connection.getHistoricalRegularizations(
                values,
                {
                    school_id: Auth.user.school_id,
                }
            ).then((i) => i.data);

            if (students.students.length === 0) {
                feedbackApp.showFeedback({
                    title: 'No se encontraron registros',
                });
            } else {
                setCycle(values.cycle);
                setStudents(students);
            }
        } catch (err) {
            let FeedbackService = new Feedback();
            feedbackApp.showFeedback({
                title:
                    FeedbackService.getMessage(err)?.message ||
                    'Error en la búsqueda',
            });
            setDataGridLoading('idle');
            setStudents({
                regularizations: [],
                students: [],
            });
        }
    };

    const saveRegularizations = async (values) => {
        let regularizations = await Connection.postHistoricalRegularizations(
            cycle,
            values
        )
            .then(() => {
                setDataGridLoading('idle');
                setStudents({
                    regularizations: [],
                    students: [],
                });
                feedbackApp.showFeedback({
                    title: 'Registros guardados',
                });
            })
            .catch((err) => {
                setDataGridLoading('idle');
                let FeedbackService = new Feedback();
                feedbackApp.showFeedback({
                    title: FeedbackService.getMessage(err).message,
                });
            });

        return regularizations;
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            <SearchInputs
                onSearch={searchStudents}
                dataGridLoading={dataGridLoading}
                setDataGridLoading={setDataGridLoading}
            />

            <RegularizationsDataGrid
                loading={dataGridLoading}
                onSave={saveRegularizations}
                cycle={cycle}
                data={students}
            />
        </Box>
    );
};

export default Regularizations;
