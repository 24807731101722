import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Card, CardContent, Box, Tooltip } from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarContainer,
    GridToolbarExport,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectLagAssists,
} from '../../../store/professors/fetchSlice';
import { getLagAssists } from '../../../store/professors/thunks';
import { SkeletonProfessorsSmallTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentProfessorsLagAssistsStatusServer } from '../../../store/professors/componentsStatusSelectors';
import _ from 'lodash';
import { Tag } from 'antd';

const LagAssists = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentProfessorsLagAssistsStatusServer
    );

    const lagAssists = useFetchResource(
        () =>
            getLagAssists({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectLagAssists,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getLagAssists({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectLagAssists,
            [cycleSelected, schoolId]
        );
    };

    const [pageSize, setPageSize] = useState(10);

    const columns = [
        { field: 'label', headerName: 'Profesor', flex: 1 },
        { field: 'data', headerName: 'Rezago de Asistencias', flex: 1 },
    ];

    const rows = lagAssists?.value
        ? _.orderBy(
              Object.values(lagAssists.value).map((row, index) => ({
                  id: index + 1,
                  label: row.label,
                  data: Number(row.data),
              })),
              ['data', 'label'],
              ['desc', 'asc']
          )
        : [];

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbar {...props} />
            </GridToolbarContainer>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonProfessorsSmallTables items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                    }}
                >
                    <CardContent
                        sx={{
                            width: '100%',
                            padding: '24px',
                        }}
                    >
                        <Tooltip
                            title={
                                lagAssists?.description ||
                                'Información sobre el rezago de asistencias de los profesores'
                            }
                            followCursor
                        >
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                gutterBottom
                            >
                                {lagAssists?.title ||
                                    'Profesores con rezago de asistencias'}
                            </Typography>
                        </Tooltip>
                        <Box sx={{ height: 720, width: '100%' }}>
                            <DataGrid
                                localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                        .localeText
                                }
                                rows={rows}
                                columns={[
                                    {
                                        field: 'label',
                                        headerName: 'Profesor',
                                        flex: 1,
                                        renderCell: (params) => (
                                            <Box
                                                sx={{
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word',
                                                }}
                                            >
                                                {params.value}
                                            </Box>
                                        ),
                                    },
                                    {
                                        field: 'data',
                                        headerName: 'Rezago de Asistencias',
                                        flex: 1,
                                        align: 'right',
                                        headerAlign: 'right',
                                        renderCell: (params) => (
                                            <Tag
                                                color="error"
                                                style={{
                                                    width: '100px',
                                                    textAlign: 'center',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'normal',
                                                    wordBreak: 'break-word',
                                                }}
                                            >
                                                {params.value}
                                            </Tag>
                                        ),
                                    },
                                ]}
                                pageSize={pageSize}
                                rowsPerPageOptions={[]}
                                disableDensitySelector
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                }}
                                sx={{
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                    },
                                    '& .MuiDataGrid-cell': {
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                    },
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default LagAssists;
