import {
    createEntityAdapter,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import _ from 'lodash';
import { selectAllGroupsEntities } from '../groups';
import { selectAllModulesEntities } from '../modules';
import { selectEntitiesSubjectcatalogs } from '../subjectcatalogs';

const subjectsAdapter = createEntityAdapter({
    selectId: (subject) => subject.subject_id,
    sortComparer: (a, b) => a.subject_id - b.subject_id,
});

/**
 * Slice para los parciales
 */
export const SubjectsSlice = createSlice({
    name: 'subjects',
    initialState: subjectsAdapter.getInitialState(),
    reducers: {
        /**
         * Si la materia no se encuentra en las entidades es agregados
         *
         * @param {*} state
         * @param {*} action
         */
        addOneSubject: subjectsAdapter.addOne,
        /**
         * Agrega muchos elementos, si esque estos aun no se encuentran agregados
         *
         * @param {*} state
         * @param {*} action
         */
        addManySubjects: subjectsAdapter.addMany,
        /**
         * Remplazar todos los parciales
         *
         * @param {*} state
         * @param {*} action
         */
        setSubjects: subjectsAdapter.setAll,
        updateSubject: subjectsAdapter.updateOne,
        /**
         * Elimina un parcial de la coleccion
         * @param {*} state
         * @param {*} action
         */
        removeOneSubject: subjectsAdapter.removeOne,
        removeManySubjects: subjectsAdapter.removeMany,
        /**
         * Si el elemento existe realziara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         *
         * @param {*} state
         * @param {*} action
         */
        upsertOneSubject: subjectsAdapter.upsertOne,
        /**
         * Si el elemento existe realizara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         *
         * @param {*} state
         * @param {*} action
         */
        upsertManySubjects: subjectsAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return subjectsAdapter.getInitialState();
        });
    },
});

/**
 * Acciones generadas por la librerias
 */
export const {
    setSubjects,
    addManySubjects,
    removeOneSubject,
    updateSubject,
    addOneSubject,
    upsertOneSubject,
    upsertManySubjects,
    removeManySubjects,
} = SubjectsSlice.actions;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = subjectsAdapter.getSelectors(
    (state) => state.entities.subjects
);

/**
 * Selector para recuperar todos los parciales de la coleccion
 *
 * @param {*} state
 * @returns
 */
export const selectSubjects = (state) =>
    globalizedSelectors.selectEntities(state);
/**
 * Selector para recuperar todas las materias
 *
 * @param {*} state
 * @returns
 */
export const selectAllSubjects = (state) =>
    globalizedSelectors.selectAll(state);

/**
 * Selector para recuperar una materia por id
 *
 * @param {*} subjectId
 * @returns
 */
export const selectSubjectByid = (subjectId) => (state) =>
    globalizedSelectors.selectById(state, subjectId);

/**
 * Selector para recuperar materias filtradas
 *
 * @param {*} state
 * @returns
 */
export const selectSubjectsBy = (filter) => (state) => {
    const allSubjects = selectAllSubjects(state);

    return _.filter(allSubjects, filter);
};

export const selectSubjectDataById = (subjectId) =>
    createSelector(
        selectSubjectByid(subjectId),
        selectAllGroupsEntities,
        selectAllModulesEntities,
        selectEntitiesSubjectcatalogs,
        (subject, groupsEntities, moduleEntities, subjetcatalogsEntities) => {
            if (!subject) {
                return null;
            }

            let group = null;

            if (subject) {
                group = moduleEntities[subject.group_id]
                    ? moduleEntities[subject.group_id]
                    : groupsEntities[subject.group_id];
            }

            return {
                ...subject,
                group,
                catalog: subjetcatalogsEntities[subject.catalog_subject_id],
                module: moduleEntities[subject.group_id],
            };
        }
    );

/**
 * Selector para recuperar parciales de una escuela especifica
 *
 * @param {*} schoolId
 * @returns
 *
 * @deprecated
 */
export const selectSubjectByGroupId = (groupId) => {
    return (state) => {
        if (!groupId) return [];

        return _.filter(Object.values(state.entities.subjects.byId), {
            group_id: parseInt(groupId),
        });
    };
};

/**
 * Selector para recuperar las materias de un grupo
 */
export const selectSubjectsByGroupId = (groupId) =>
    createSelector(selectAllSubjects, (allSubjects) => {
        return _.filter(allSubjects, ['group_id', groupId]);
    });

export default SubjectsSlice.reducer;
