import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import * as Style from "../../../../../styles/index";
import useFeedback from "../../../../../hooks/useFeedback";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import { selectSettingBySchoolId } from "../../../../../store/slices/entities/settings";
import { useAuth } from "../../../../../hooks";
import Services from "../../../../../service/Connection";
import { upsertManyAssists } from "../../../../../store/slices/entities/assists";
import { DateTime } from "luxon";

const AbsencesModal = ({ title, openModal, setOpenModal, absences }) => {
  const classes = Style.tableModalStyle();
  const Auth = useAuth();
  const schoolId = Auth.getUser().school_id;

  const dispatch = useDispatch();
  const feedbackApp = useFeedback();

  const formatDate = (dateString) => {
    const dateObject = DateTime.fromISO(dateString.replace(" ", "T"));
    const formattedDate = dateObject
      .setLocale("es")
      .toFormat("cccc d 'de' MMMM 'del' yyyy");

    const formattedDateWithCapitalized = formattedDate.replace(
      /^\w|\s\w/g,
      (letter) => letter.toUpperCase()
    );

    return formattedDateWithCapitalized;
  };

  const config = useSelector(selectSettingBySchoolId(schoolId));

  const [absencesData, setAbsencesData] = useState([]);
  const [justificationText, setJustificationText] = useState("");
  const [loading, setLoading] = useState(false);

  const onSwitchChange = (index, event) => {
    setAbsencesData((prevAbsences) => {
      const newAbsences = [...prevAbsences];
      newAbsences[index] = {
        ...newAbsences[index],
        is_justified: event.target.checked,
      };
      return newAbsences;
    });
  };

  const onInputChange = (event) => {
    const newValue = event.target.value;
    setJustificationText(newValue);
    const updatedAbsencesData = absencesData.map((row) => ({
      ...row,
      reason_justification: newValue,
    }));
    setAbsencesData(updatedAbsencesData);
  };

  useEffect(() => {
    if (absences) {
      const newArray = absences.map((obj) => {
        return { ...obj };
      });
      setAbsencesData(newArray);
    }
  }, [absences]);

  let titleBarBackground = config.find(
    (res) => res.key === "theme-color-title-bar"
  );

  let fontColor = config.find((res) => res.key === "theme-color-font");

  /* No cerrar click afuera del modal */
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpenModal(false);
    setAbsencesData(absences);
    setJustificationText("");
  };

  const saveJustified = async () => {
    setLoading(true);

    const justifiedAbsences = absencesData.filter(
      (row) =>
        row.is_justified &&
        row.reason_justification !== "" &&
        row.reason_justification !== null
    );

    if (justifiedAbsences.length > 0) {
      await Services.updateAbsence({
        update: justifiedAbsences,
      })
        .then((absences) => {
          feedbackApp.showFeedback({
            title: "Justificaciones actualizadas",
          });
          dispatch(upsertManyAssists(absences.data.data));
          setOpenModal(false);
          setJustificationText("");
        })
        .catch(({ feedback }) => {
          feedbackApp.showFeedback({
            title: feedback.title,
          });
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      feedbackApp.showFeedback({
        title: "No hay justificaciones para guardar",
      });
      setLoading(false);
    }
  };
  return (
    <Dialog
      open={openModal}
      maxWidth={false}
      PaperProps={{
        sx: {
          width: "650px", // Modificar el ancho en píxeles
          maxHeight: "600px", // Modificar el alto en píxeles
        },
      }}
      onClose={handleClose}
    >
      <DialogTitle
        className={classes.dialogTitle}
        style={{
          backgroundColor: titleBarBackground.value,
          color: fontColor.value,
        }}
      >
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            style={{ flexGrow: 1, color: fontColor.value }}
          >
            {title}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        {absencesData.length > 0 && (
          <TableContainer>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: titleBarBackground.value }}>
                  <TableCell align="center" style={{ color: fontColor.value }}>
                    FECHA DE FALTA
                  </TableCell>
                  <TableCell align="center" style={{ color: fontColor.value }}>
                    JUSTIFICAR
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {absencesData?.map((row, i) => (
                  <TableRow
                    key={row.assist_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {formatDate(row.date)}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Switch
                        checked={row.is_justified}
                        onChange={(event) => {
                          onSwitchChange(i, event);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Stack direction="row" justifyContent="center" alignItems="center">
          <TextField
            id="outlined-multiline-static"
            variant="outlined"
            label="Motivo de Justificación"
            size="small"
            multiline
            rows={4}
            fullWidth
            sx={{ m: 3 }}
            inputProps={{ maxLength: 250 }}
            value={justificationText}
            onChange={onInputChange}
            error={justificationText.length < 10 || justificationText === ""}
            helperText={
              "El motivo es obligatorio y debe contener más de 10 caracteres"
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
        <LoadingButton
          color="primary"
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          loading={loading === true}
          disabled={absencesData.every(
            (abs) =>
              abs.is_justified === false ||
              justificationText === "" ||
              justificationText.length < 10
          )}
          onClick={saveJustified}
        >
          {`Guardar`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AbsencesModal;
