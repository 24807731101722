import React, { useEffect, useState } from 'react';
import {
    Box,
    Stack,
    Tab,
    Tabs,
    Grid,
    CssBaseline,
    Tooltip,
    Typography,
    Divider,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useLocation,
} from 'react-router-dom';
import { useAuth } from '../../../hooks';
import Skeleton from '../../components/Skeleton';
import Error from '../../../components/Feedback/Error';
import { CyclesSelectOnDirector } from '../../../components/Director';
import AllStudents from './components/AllStudents';
import BestAverageGrade from './components/BestAverageGrade';
import BestAverageLevel from './components/BestAverageLevel';
import MostInassistGrade from './components/MostInassistGrade';
import MostInassistLevel from './components/MostInassistLevel';
import MostReportGrade from './components/MostReportGrade';
import MostReportLevel from './components/MostReportLevel';
import WorstAverageGrade from './components/WorstAverageGrade';
import WorstAverageLevel from './components/WorstAverageLevel';
import SyncIcon from '@mui/icons-material/Sync';
import {
    getCurrentTab,
    getCycleSelected,
    getLoading,
    invalidate as invalidateComponents,
} from '../../store/students/fetchSlice';
import {
    fetchAllResources,
    selectEntitiesFetchStatus,
    invalidate as invalidateEntities,
} from '../../store/entities';
import DirectorNewHome from '../Home';
import DirectorGroups from '../Groups';
import DirectorSubjects from '../Subjects';
import DirectorProfessors from '../Professors';
import { setCurrentTab } from '../../store/students/uiSlice';
import { LoadingButton } from '@mui/lab';
import LevelIndicators from './components/levelIndicator';
import SchoolPerformanceSummary from './components/schoolPerformanceSummary';

const DirectorStudents = () => {
    const dispatch = useDispatch();
    let match = useRouteMatch();
    const Auth = useAuth();
    let user = Auth.getUser();

    const schoolCycle = useSelector(getCycleSelected);
    const serverState = useSelector(getLoading);
    const currentTab = useSelector(getCurrentTab);
    const fetchingStatus = useSelector(selectEntitiesFetchStatus);

    const [selectedTurn, setSelectedTurn] = useState('');

    const onChangeTab = (event, newValue) => {
        dispatch(setCurrentTab(newValue));
    };

    const onChangeCycle = (cycle) => {
        dispatch(invalidateEntities());
        dispatch(
            fetchAllResources({
                school_id: user.school_id,
                cycle: schoolCycle,
                turn: selectedTurn,
                //limit: 5,
            })
        );
        dispatch(invalidateComponents());
        fetchAllInformation(cycle);
    };

    const reload = () => {
        dispatch(invalidateComponents());
    };

    const handleTurnChange = (event, cycle) => {
        const selectedValue = event.target.value;
        setSelectedTurn(selectedValue);
        dispatch(invalidateEntities());
        dispatch(
            fetchAllResources({
                school_id: user.school_id,
                cycle: schoolCycle,
                turn: selectedTurn,
                //limit: 5,
            })
        );
        dispatch(invalidateComponents());
        fetchAllInformation(cycle);
    };

    const invalidateUI = () => {
        dispatch(invalidateEntities());
        dispatch(
            fetchAllResources({
                school_id: user.school_id,
                cycle: schoolCycle,
                turn: selectedTurn,
                //limit: 5,
            })
        );
        dispatch(invalidateComponents());
    };

    async function fetchAllInformation(cycle = null) {
        let dataRequest = {
            school_id: user.school_id,
            cycle: cycle ? cycle : schoolCycle.cycle_id,
        };
        dispatch(fetchAllResources(dataRequest))
            .unwrap()
            .then((response) => {})
            .catch((err) => {
                let message = 'feedback.getMessage(err)';
            });
    }

    function LinkTab(props) {
        return <Tab component={Link} {...props} />;
    }

    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {fetchingStatus == 'pending' && <Skeleton items={9} />}

            {fetchingStatus == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus == 'fulfilled' && (
                <>
                    <Grid container spacing={2} sx={{ mb: 4 }}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Tabs
                                value={currentTab}
                                onChange={onChangeTab}
                                aria-label="tabs"
                            >
                                <Tab
                                    label="Inicio"
                                    value="inicio"
                                    component={Link}
                                    to="/NewDirector/inicio"
                                />
                                <Tab
                                    label="Grupos"
                                    value="groups"
                                    component={Link}
                                    to="/NewDirector/groups"
                                />
                                <Tab
                                    label="Materias"
                                    value="subjects"
                                    component={Link}
                                    to="/NewDirector/subjects"
                                />
                                <Tab
                                    label="Profesores"
                                    value="professors"
                                    component={Link}
                                    to="/NewDirector/professors"
                                />
                                <Tab
                                    label="Alumnos"
                                    value="students"
                                    component={Link}
                                    to="/NewDirector/students"
                                />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Stack
                                spacing={2}
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <CyclesSelectOnDirector
                                    disabled={serverState === 'pending'}
                                    onChange={onChangeCycle}
                                />
                                <Tooltip title="Sincronizar información">
                                    <LoadingButton
                                        onClick={invalidateUI}
                                        startIcon={<SyncIcon />}
                                        loading={serverState == 'pending'}
                                        loadingPosition="start"
                                        variant="contained"
                                        size="small"
                                    >
                                        Sincronizar
                                    </LoadingButton>
                                </Tooltip>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Box>
                        <Switch>
                            <Route path="/NewDirector/inicio">
                                <DirectorNewHome />
                            </Route>
                            <Route path="/NewDirector/groups">
                                <DirectorGroups />
                            </Route>
                            <Route path="/NewDirector/subjects">
                                <DirectorSubjects />
                            </Route>
                            <Route path="/NewDirector/professors">
                                <DirectorProfessors />
                            </Route>
                            <Route path="/NewDirector/students">
                                <Grid sx={{ mt: 5, mb: 5 }}>
                                    <LevelIndicators levelName="Prescolar" />
                                    <LevelIndicators levelName="Primaria" />
                                    <LevelIndicators levelName="Secundaria" />
                                    <LevelIndicators levelName="Bachillerato" />
                                    <LevelIndicators levelName="Universidad" />
                                    <LevelIndicators levelName="Maestria" />
                                    <LevelIndicators levelName="Posgrado" />
                                    <LevelIndicators levelName="Doctorado" />
                                    <LevelIndicators levelName="Certificacion" />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <BestAverageGrade />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <MostReportGrade />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <WorstAverageGrade />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <MostInassistGrade />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6} mt={7} mb={3}>
                                        <AllStudents />
                                        {/* <SchoolPerformanceSummary /> */}
                                    </Grid>
                                </Grid>
                            </Route>
                        </Switch>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default DirectorStudents;
