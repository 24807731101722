import React, { useEffect, useMemo, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Step,
    StepContent,
    StepIcon,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    createGroup,
    selectStatusOperation,
} from '../../../store/slices/groupsUI/serverSlice';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useAuth } from '../../../hooks';
import { LoadingButton } from '@mui/lab';
import { Transfer } from 'antd';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import { selectSubjectcatalogsByLevel } from '../../../store/slices/entities/subjectcatalogs';
import {
    assistanceModality,
    levelsNames,
    turnsNames,
} from '../../../service/const';
import { ModuleCreator } from './ModuleCreator';
import useFeedback from '../../../hooks/useFeedback';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import SaveIcon from '@mui/icons-material/Save';

/**
 * Modal para crear un grupo
 *
 * @param {*} param0
 *
 * @returns
 */
const AddGroupStepperModal = ({ open, setOpen }) => {
    //////////// HOOKS ////////////////////

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    //////////// LOCAL STATE ////////////////////

    const [groupItem, setGroupItem] = useState({
        group_id: null,
        grade: '',
        group: '',
        turn: '',
        level: '',
        special: '',
        annexed: false,
        annexed_name: '',
        combined: '',
        share_students: false,
        group_annexed_id: null,
        group_type: null,
        modules: [],
    });

    const [activeStep, setActiveStep] = useState(0);
    const [groupType, setGroupType] = useState();
    const [thirdStepView, setThirdStepView] = useState('subjects');
    const [getNameofSubjects, setNameofSubjects] = useState('');
    const [subjectTargetKeys, setSubjectTargetKeys] = useState([]);
    const [subjectSelectedKeys, setSubjectSelectedKeys] = useState([]);
    const [activeView, setActiveView] = useState('resume');

    const steps = [
        {
            label: 'Tipo de Grupo',
            description: 'Diferentes tipos de grupos',
        },
        {
            label: 'Información del grupo',
            description: 'información detallada del grupo',
        },
        {
            label: 'Materias',
            description: 'Asignación de materias al grupo',
        },
        {
            label: 'Finalizar',
            description: 'Resumen informativo del Grupo',
        },
    ];

    //////////// SHARED STATE  /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(selectStatusOperation);
    const subjects = useSelector(selectSubjectcatalogsByLevel(groupItem.level));
    const [subjectsInModules, setSubjectsInModules] = useState([]);

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );

    let menuBarSelected = config.find(
        (res) => res.key === 'theme-color-selection'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    const subjectsForMainTransfer = subjects
        .filter(
            (subject) => !subjectsInModules.includes(subject.catalog_subject_id)
        )
        .map((i) => {
            return {
                ...i,
                key: i.catalog_subject_id,
            };
        });

    /////////////////// ACTIONS /////////////////

    const onAddModule = (module) => {
        setGroupItem({ ...groupItem, modules: [...groupItem.modules, module] });
    };

    const onRemoveModule = ({ updatedModules, moduleDeleted }) => {
        setGroupItem((prevState) => ({
            ...prevState,
            modules: updatedModules,
        }));
        setSubjectsInModules(
            subjectsInModules.filter(
                (subjectId) => !moduleDeleted.subjects.includes(subjectId)
            )
        );
    };

    const onUpdateModule = (module) => {
        const updatedModules = [...groupItem.modules];
        updatedModules.splice(module.index, 1);

        setGroupItem({ ...groupItem, modules: [...updatedModules, module] });
    };

    const handlerSubjectSelected = (subjects) => {
        setSubjectsInModules([...subjectsInModules, ...subjects]);
    };

    const handleViewChange = (view) => {
        setActiveView(view);
    };

    /**
     * Guardar nuevo grupo
     */
    const SaveGroup = async () => {
        const adjustedGroupItem = {
            ...groupItem,
            annexed: groupType === 2,
            special: groupType !== 1 ? 1 : 0,
            share_students: groupType === 1 ? false : true,
            group_annexed_id: null,
            group_type: groupType,
            combined: groupType !== 1 ? 1 : groupItem.combined,
            subjects: subjectTargetKeys,
        };

        dispatch(
            createGroup({
                schoolId: schoolId,
                data: adjustedGroupItem,
            })
        )
            .unwrap()
            .then(({ message }) => {
                let initialmessage = 'Grupo creado';

                if (message) {
                    initialmessage = message;
                }

                feedbackApp.showFeedback({
                    title: initialmessage,
                });

                handleClose();
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };
    //////////////////// Handlers ///////////////////////////

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handlePrincipalGroup = (groupType) => {
        setGroupType(groupType);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            handleClose();
        } else if (activeStep === 1) {
            setGroupItem({
                group_id: null,
                grade: '',
                group: '',
                turn: '',
                level: '',
                special: '',
                annexed: false,
                annexed_name: '',
                combined: '',
                share_students: false,
                group_annexed_id: null,
                group_type: null,
                modules: [],
            });
            setSubjectSelectedKeys([]);
            setSubjectTargetKeys([]);
            setNameofSubjects([]);
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else if (activeStep === 2) {
            setActiveView('resume');
            setThirdStepView('subjects');
            setGroupItem((prevState) => ({
                ...prevState,
                modules: [],
            }));
            setSubjectsInModules([]);
            setSubjectSelectedKeys([]);
            setSubjectTargetKeys([]);
            setNameofSubjects([]);
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false);
    };

    /**
     * Efecto escuchando a show para limpiar los valores
     */
    useEffect(() => {
        if (!open) {
            setGroupItem({
                group_id: null,
                grade: '',
                group: '',
                turn: '',
                level: '',
                special: '',
                annexed: false,
                annexed_name: '',
                combined: '',
                share_students: false,
                group_annexed_id: null,
                group_type: null,
                modules: [],
            });
            setActiveStep(0);
            setSubjectsInModules([]);
            setSubjectSelectedKeys([]);
            setSubjectTargetKeys([]);
            setNameofSubjects([]);
            setThirdStepView('subjects');
        }
    }, [open]);

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     *
     * @param {*} event
     */
    const onInputChange = (event) => {
        const { name, value } = event.target;

        if (name === 'grade') {
            if (/^\d{0,2}$/.test(value)) {
                setGroupItem({ ...groupItem, [name]: value });
            }
        } else if (name === 'level') {
            setSubjectTargetKeys([]);
            setSubjectSelectedKeys([]);
            setNameofSubjects([]);
            setGroupItem({ ...groupItem, [name]: value });
        } else {
            setGroupItem({ ...groupItem, [name]: value });
        }
    };

    ///////////////////////// TRANFER ///////////////////////////

    /**
     * Una funciona que es llamada cuando la tranferencias entre columnas se ha completado
     *
     * @param {*} nextTargetKeys
     * @param {*} direction
     * @param {*} moveKeys
     */
    const onChange = (nextTargetKeys) => {
        setSubjectTargetKeys(nextTargetKeys);
        setNameofSubjects(
            nextTargetKeys.map((key) =>
                subjects.find((subject) => subject.catalog_subject_id === key)
            )
        );
    };

    /**
     *  Funcion que es ejecutada cuendo los elementos selecionados han cambiado
     *
     * @param {*} sourceSelectedKeys
     * @param {*} targetSelectedKeys
     */
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSubjectSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    //////////////////////// COMPONENTS ////////////////////////////

    const GroupCard = ({ title, description, onClick, groupType }) => {
        let imageSource;

        switch (groupType) {
            case 1:
                imageSource = '/images/GroupPrincipal.jpeg';
                break;
            case 2:
                imageSource = '/images/GroupSpecial.jpeg';
                break;
            case 3:
                imageSource = '/images/GroupExtraescolar.jpeg';
                break;
        }

        return (
            <Card
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                    borderRadius: 10,
                }}
                sx={{
                    maxWidth: 250,
                    marginRight: 2,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <CardActionArea onClick={onClick}>
                    <CardMedia
                        component="img"
                        style={{
                            width: '100%',
                            height: '100px',
                            objectFit: 'cover',
                        }}
                        image={imageSource}
                        alt="group image"
                    />
                    <CardContent>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            ssx={{
                                maxWidth: '100%',
                                whiteSpace: 'pre-line',
                            }}
                        >
                            {title.split(' ').map((word, index) => (
                                <React.Fragment key={index}>
                                    {index > 0 && <br />}
                                    {word}
                                </React.Fragment>
                            ))}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            style={{ color: fontColor.value }}
                        >
                            {description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <Divider style={{ borderColor: '#ffffff' }} />
                <Box
                    sx={{
                        ':hover': { backgroundColor: menuBarSelected.value },
                    }}
                >
                    <CardActions sx={{ mt: -2, mb: -2 }}>
                        <CardActionArea
                            onClick={onClick}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    color="text.secondary"
                                    style={{ color: fontColor.value }}
                                >
                                    Seleccionar
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </CardActions>
                </Box>
            </Card>
        );
    };

    const OptionStepperTypeGroup = () => {
        return (
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                sx={{ mt: 2 }}
            >
                <GroupCard
                    title="Principal"
                    description="Grupo en el cual los estudiantes siguen el flujo de la institución"
                    onClick={() => handlePrincipalGroup(1)}
                    groupType={1}
                />
                <GroupCard
                    title="Especial"
                    description="Grupo que está vinculado o adjunto a otro grupo principal "
                    onClick={() => handlePrincipalGroup(2)}
                    groupType={2}
                />
                <GroupCard
                    title="Extraescolar"
                    description="Grupo que se enfoca en áreas específicas de interés o habilidades"
                    onClick={() => handlePrincipalGroup(3)}
                    groupType={3}
                />
            </Box>
        );
    };
    ///////////////////////// TRANFER /////////////////////
    /**
     * Filtro para la busqueda
     */

    const removeAccents = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    const filterOption = (inputValue, option) => {
        const search = removeAccents(
            inputValue.toLowerCase().replace(/\s/g, '')
        );

        const titleNoSpace = removeAccents(
            `${option.folio} ${option.title} ${getLevelName(option.level)}`
                .toLowerCase()
                .replace(/\s/g, '')
        );
        const titleMatch = titleNoSpace.includes(search);

        return titleMatch;
    };
    /////////////////////////  /////////////////////

    const OptionStepperResults = () => {
        const getCombinedLabel = (value) => {
            const option = assistanceModality.find(
                (option) => option.value === value
            );
            return option ? option.label : '';
        };

        const renderGeneralInfo = () => (
            <Stack>
                <Typography>
                    Grado: <strong>{groupItem.grade}</strong>
                </Typography>
                <Typography>
                    Grupo: <strong>{groupItem.group} </strong>
                </Typography>
                <Typography>
                    Nivel educativo:{' '}
                    <strong>{getLevelName(groupItem.level)}</strong>
                </Typography>
                <Typography>
                    Turno: <strong>{getTurnLevel(groupItem.turn)}</strong>
                </Typography>
                <Typography>
                    Modalidad de lista de asistencia:{' '}
                    <strong>{getCombinedLabel(groupItem.combined)}</strong>
                </Typography>
            </Stack>
        );

        const renderAttachedInfo = () => (
            <Stack>
                {renderGeneralInfo()}
                <Typography>
                    Nombre especial: <strong>{groupItem.annexed_name}</strong>
                </Typography>
            </Stack>
        );

        const renderColumn = (column) => (
            <Stack
                direction="row"
                spacing={1}
                mb={1}
                useFlexGap
                flexWrap="wrap"
            >
                {column.map((subject, index) => (
                    <Chip
                        key={index}
                        label={`${subject.folio} - ${subject.title}`}
                    />
                ))}
            </Stack>
        );

        const renderSubjects = () => {
            if (getNameofSubjects.length === 0) {
                return 'N/A';
            }

            const subjectsPerRow = 4;
            const columns = [];

            for (let i = 0; i < getNameofSubjects.length; i += subjectsPerRow) {
                const column = getNameofSubjects.slice(i, i + subjectsPerRow);
                columns.push(column);
            }

            return (
                <div>
                    {columns.map((column, index) => (
                        <div key={index}>{renderColumn(column)}</div>
                    ))}
                </div>
            );
        };

        return (
            <Grid item xs={12} md={12}>
                <Box sx={{ marginBottom: 2 }}>
                    <Typography
                        variant="h6"
                        sx={{
                            marginBottom: 1,
                            fontWeight: 'bold',
                        }}
                    >
                        Datos generales
                    </Typography>
                    <Box>
                        <Grid item xs={12} mb={2}>
                            {groupType === 1 && renderGeneralInfo()}
                            {groupType === 2 && renderAttachedInfo()}
                            {groupType === 3 && renderGeneralInfo()}
                        </Grid>
                    </Box>
                    <Typography
                        variant="h6"
                        sx={{
                            marginBottom: 1,
                            fontWeight: 'bold',
                        }}
                    >
                        Materias seleccionadas
                    </Typography>
                    <Box>{renderSubjects()}</Box>
                    {groupItem.modules.map((module, i) => {
                        return (
                            <>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        marginBottom: 1,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {`Materias de Módulo: ${module.name}`}
                                </Typography>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    mb={1}
                                    useFlexGap
                                    flexWrap="wrap"
                                >
                                    {module.subjects.map((subjectId, i) => {
                                        const subject = subjects.find(
                                            (subject) =>
                                                subject.catalog_subject_id ===
                                                subjectId
                                        );
                                        const subjectName = subject
                                            ? subject.title
                                            : 'Asignatura no encontrada';
                                        return (
                                            <Chip
                                                key={i}
                                                label={`${subject.folio} - ${subject.title}`}
                                            />
                                        );
                                    })}
                                </Stack>
                            </>
                        );
                    })}
                </Box>
            </Grid>
        );
    };

    //////////////////////// VALIDATIONS ////////////////////////

    const DATA_REQUIRED = '';
    const REQUIRED_FIELD_MESSAGE = 'Campo requerido';

    const levelValid = () => {
        return {
            invalid: groupItem.level === DATA_REQUIRED,
            message: REQUIRED_FIELD_MESSAGE,
        };
    };

    const gradeValid = () => {
        return {
            invalid: groupItem.grade === DATA_REQUIRED,
            message: REQUIRED_FIELD_MESSAGE,
        };
    };

    const groupValid = () => {
        return {
            invalid: groupItem.group === DATA_REQUIRED,
            message: 'Campo requerido, Máximo 50 caracteres',
        };
    };

    const turnValid = () => {
        return {
            invalid: groupItem.turn === DATA_REQUIRED,
            message: REQUIRED_FIELD_MESSAGE,
        };
    };

    const combinedValid = () => {
        if (groupType !== 1) {
            return { invalid: false, message: '' };
        }
        return {
            invalid: groupItem.combined === DATA_REQUIRED,
            message: REQUIRED_FIELD_MESSAGE,
        };
    };

    const annexednameValid = () => {
        return {
            invalid: groupItem.annexed_name === DATA_REQUIRED,
            message: 'Campo requerido, Máximo 20 caracteres',
        };
    };

    const StepTwoisInvalid = () => {
        const validations = {
            1: () =>
                levelValid().invalid ||
                gradeValid().invalid ||
                groupValid().invalid ||
                turnValid().invalid ||
                combinedValid().invalid ||
                activeView !== 'resume',
            2: () =>
                levelValid().invalid ||
                gradeValid().invalid ||
                groupValid().invalid ||
                turnValid().invalid ||
                annexednameValid().invalid ||
                combinedValid().invalid,
            3: () =>
                levelValid().invalid ||
                gradeValid().invalid ||
                groupValid().invalid ||
                turnValid().invalid ||
                combinedValid().invalid,
        };

        const validationFunction = validations[groupType] || (() => false);
        return validationFunction();
    };

    const typeOfGroupSelected = () => {
        return activeStep === 0 ? (
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                Agregar grupo
            </Typography>
        ) : (
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                {` ${
                    groupType === 2
                        ? 'Especial'
                        : groupType === 3
                        ? 'Extraescolar'
                        : 'Principal'
                }`}
            </Typography>
        );
    };

    const sortedSubjectsTransfer = useMemo(() => {
        return [...subjectsForMainTransfer].sort((a, b) => {
            return a.folio.localeCompare(b.folio, undefined, {
                numeric: true,
                sensitivity: 'base',
            });
        });
    }, [subjectsForMainTransfer]);

    return (
        <>
            <Dialog
                open={open}
                fullWidth
                maxWidth="md"
                disableEscapeKeyDown={statusOperation === 'pending'}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: '90%',
                        height: '80vh',
                    },
                }}
            >
                <DialogTitle
                    style={{
                        backgroundColor: titleBarBackground.value,
                        color: fontColor.value,
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        {typeOfGroupSelected()}
                    </div>
                </DialogTitle>
                <DialogContent
                    dividers
                    style={{
                        maxHeight: 1000,
                        overflow: 'auto',
                    }}
                >
                    <Stack
                        direction="row"
                        divider={
                            <Divider
                                orientation="vertical"
                                flexItem
                                variant="middle"
                            />
                        }
                        spacing={4.7}
                        sx={{ height: 550 }}
                    >
                        <Stack
                            direction="column"
                            spacing={1}
                            sx={{ minWidth: 153, maxWidth: 153 }}
                        >
                            <Stepper
                                activeStep={activeStep}
                                orientation="vertical"
                            >
                                {steps.map((label, index) => {
                                    let stepValid = true;
                                    let stepText = '';
                                    if (index === 1) {
                                        stepValid = !StepTwoisInvalid();
                                        stepText = StepTwoisInvalid()
                                            ? 'Faltan campos por llenar'
                                            : 'Todos los campos están completos';
                                    }

                                    return (
                                        <Step
                                            key={index}
                                            completed={index < activeStep}
                                            error={
                                                index === activeStep &&
                                                !stepValid
                                            }
                                        >
                                            <StepLabel
                                                StepIconProps={{
                                                    completed:
                                                        index < activeStep,
                                                    error:
                                                        index === activeStep &&
                                                        !stepValid,
                                                }}
                                                StepIconComponent={(props) => {
                                                    if (props.error) {
                                                        return (
                                                            <ErrorIcon
                                                                style={{
                                                                    color: 'red',
                                                                }}
                                                            />
                                                        );
                                                    } else if (
                                                        props.completed
                                                    ) {
                                                        return (
                                                            <CheckIcon
                                                                style={{
                                                                    color: 'green',
                                                                }}
                                                            />
                                                        );
                                                    } else {
                                                        return (
                                                            <StepIcon
                                                                {...props}
                                                            />
                                                        );
                                                    }
                                                }}
                                            >
                                                <Typography
                                                    variant="h7"
                                                    color="primary"
                                                >
                                                    {label.label}
                                                </Typography>
                                                {index === activeStep && (
                                                    <Typography
                                                        variant="body2"
                                                        style={{
                                                            color: stepValid
                                                                ? 'green'
                                                                : 'red',
                                                        }}
                                                    >
                                                        {stepText}
                                                    </Typography>
                                                )}
                                            </StepLabel>
                                            <StepContent>
                                                <Typography>
                                                    {label.description}
                                                </Typography>
                                            </StepContent>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Stack>
                        <Box sx={{ pt: 3 }}>
                            {activeStep === 0 && <OptionStepperTypeGroup />}
                            {activeStep === 1 && (
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} mb={2} mt={3}>
                                            <TextField
                                                id="grade"
                                                name="grade"
                                                label="Grado"
                                                type="text"
                                                autoComplete="current-password"
                                                variant="outlined"
                                                fullWidth
                                                inputProps={{
                                                    maxLength: 2,
                                                    autoComplete: 'turnoff',
                                                }}
                                                value={groupItem.grade}
                                                onChange={onInputChange}
                                                error={gradeValid().invalid}
                                                helperText={
                                                    gradeValid().invalid
                                                        ? gradeValid().message
                                                        : false
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} mb={2} mt={3}>
                                            <TextField
                                                id="group"
                                                name="group"
                                                label="Grupo"
                                                type="text"
                                                autoComplete="current-password"
                                                variant="outlined"
                                                fullWidth
                                                inputProps={{
                                                    maxLength: 50,
                                                    autoComplete: 'turnoff',
                                                }}
                                                value={groupItem.group}
                                                onChange={onInputChange}
                                                error={groupValid().invalid}
                                                helperText={
                                                    groupValid().invalid
                                                        ? groupValid().message
                                                        : false
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} mb={2}>
                                            <FormControl
                                                variant="outlined"
                                                sx={{ width: '100%' }}
                                                error={levelValid().invalid}
                                            >
                                                <InputLabel id="lbl-nivel">
                                                    Nivel
                                                </InputLabel>
                                                <Select
                                                    labelId="lbl-nivel"
                                                    id="level"
                                                    value={groupItem.level}
                                                    onChange={onInputChange}
                                                    label="Nivel"
                                                    name="level"
                                                    fullWidth
                                                    disabled={
                                                        groupItem.group_id !==
                                                        null
                                                    }
                                                >
                                                    <MenuItem
                                                        value={-1}
                                                        selected
                                                        disabled
                                                    >
                                                        Selecciona una opción
                                                    </MenuItem>
                                                    {levelsNames.map((i) => (
                                                        <MenuItem
                                                            key={i.key}
                                                            value={i.key}
                                                        >
                                                            {i.title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {levelValid().invalid && (
                                                    <FormHelperText>
                                                        {levelValid().message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} mb={2}>
                                            <FormControl
                                                variant="outlined"
                                                sx={{ width: '100%' }}
                                                error={turnValid().invalid}
                                            >
                                                <InputLabel id="lbl-turno">
                                                    Turno
                                                </InputLabel>
                                                <Select
                                                    labelId="lbl-turno"
                                                    id="turn"
                                                    name="turn"
                                                    value={groupItem.turn}
                                                    onChange={onInputChange}
                                                    label="Turno"
                                                    fullWidth
                                                >
                                                    <MenuItem
                                                        value={-1}
                                                        selected
                                                        disabled
                                                    >
                                                        Selecciona una opción
                                                    </MenuItem>
                                                    {turnsNames.map((i) => (
                                                        <MenuItem
                                                            key={i.key}
                                                            value={i.key}
                                                        >
                                                            {i.title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {turnValid().invalid && (
                                                    <FormHelperText>
                                                        {turnValid().message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        {groupType === 2 && (
                                            <>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    mb={2}
                                                >
                                                    <TextField
                                                        id="annexed_name"
                                                        name="annexed_name"
                                                        label="Grupo especial"
                                                        type="text"
                                                        variant="outlined"
                                                        value={
                                                            groupItem.annexed_name
                                                        }
                                                        onChange={onInputChange}
                                                        fullWidth
                                                        inputProps={{
                                                            maxLength: 20,
                                                            autoComplete:
                                                                'turnoff',
                                                        }}
                                                        error={
                                                            annexednameValid()
                                                                .invalid
                                                        }
                                                        helperText={
                                                            annexednameValid()
                                                                .invalid
                                                                ? annexednameValid()
                                                                      .message
                                                                : false
                                                        }
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        {groupType === 1 && (
                                            <Grid item xs={12} sm={6} mb={2}>
                                                <FormControl
                                                    variant="outlined"
                                                    sx={{ width: '100%' }}
                                                    error={
                                                        combinedValid().invalid
                                                    }
                                                >
                                                    <InputLabel id="lbl-combinado">
                                                        Modalidad de lista de
                                                        asistencia
                                                    </InputLabel>
                                                    <Select
                                                        labelId="lbl-combinado"
                                                        id="combined"
                                                        name="combined"
                                                        value={
                                                            groupItem.combined
                                                        }
                                                        onChange={onInputChange}
                                                        label="Modalidad de lista de asistencia"
                                                        defaultValue=""
                                                        fullWidth
                                                    >
                                                        <MenuItem
                                                            value={-1}
                                                            selected
                                                            disabled
                                                        >
                                                            Selecciona una
                                                            opción
                                                        </MenuItem>
                                                        {assistanceModality.map(
                                                            (option) => (
                                                                <MenuItem
                                                                    key={
                                                                        option.value
                                                                    }
                                                                    value={
                                                                        option.value
                                                                    }
                                                                >
                                                                    {
                                                                        option.label
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                    {combinedValid()
                                                        .invalid && (
                                                        <FormHelperText>
                                                            {
                                                                combinedValid()
                                                                    .message
                                                            }
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                        )}
                                    </Grid>
                                </>
                            )}
                            {activeStep === 2 && (
                                <>
                                    <Stack
                                        mt={-3}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            style={{
                                                textAlign: 'start',
                                            }}
                                        >
                                            {`Asignar ${
                                                thirdStepView === 'subjects'
                                                    ? 'Materias'
                                                    : 'Módulos'
                                            } a grupo`}
                                        </Typography>
                                        <ButtonGroup
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'end',
                                                alignItems: 'end',
                                                boxShadow: 'none',
                                            }}
                                            variant="contained"
                                            aria-label="Basic button group"
                                        >
                                            <Button
                                                onClick={() =>
                                                    setThirdStepView('subjects')
                                                }
                                                disabled={
                                                    thirdStepView === 'subjects'
                                                }
                                            >
                                                Materias
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    setThirdStepView('modules')
                                                }
                                                disabled={
                                                    thirdStepView === 'modules'
                                                }
                                            >
                                                Módulos
                                            </Button>
                                        </ButtonGroup>
                                    </Stack>
                                    {groupItem.group_id === null &&
                                        thirdStepView === 'subjects' && (
                                            <Box>
                                                <Transfer
                                                    style={{
                                                        marginTop: '20px',
                                                    }}
                                                    showSearch
                                                    filterOption={filterOption}
                                                    listStyle={{
                                                        width: 265,
                                                        height: 350,
                                                    }}
                                                    dataSource={
                                                        sortedSubjectsTransfer
                                                    }
                                                    locale={{
                                                        notFoundContent: [
                                                            'Sin materias',
                                                            'Sin materias',
                                                        ],
                                                        searchPlaceholder:
                                                            'Buscar aquí',
                                                        itemUnit: 'Materias',
                                                        itemsUnit: 'Materias',
                                                    }}
                                                    titles={[
                                                        'Disponibles',
                                                        'Asignadas',
                                                    ]}
                                                    targetKeys={
                                                        subjectTargetKeys
                                                    }
                                                    selectedKeys={
                                                        subjectSelectedKeys
                                                    }
                                                    onChange={onChange}
                                                    onSelectChange={
                                                        onSelectChange
                                                    }
                                                    render={(item) => {
                                                        return (
                                                            item.folio +
                                                            '-' +
                                                            item.title +
                                                            '-' +
                                                            getLevelName(
                                                                item.level
                                                            )
                                                        );
                                                    }}
                                                    disabled={
                                                        groupItem.level === -1
                                                    }
                                                />
                                                {groupType === 2 && (
                                                    <>
                                                        {subjectTargetKeys.length >=
                                                            2 && (
                                                            <Alert
                                                                sx={{ mt: 6 }}
                                                                severity="error"
                                                            >
                                                                Solo puedes
                                                                seleccionar una
                                                                materia para
                                                                este tipo de
                                                                grupo
                                                            </Alert>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                        )}
                                    {groupType === 1 &&
                                        thirdStepView === 'modules' && (
                                            <Box
                                                sx={{
                                                    width: 600,
                                                    overflow: 'auto',
                                                }}
                                            >
                                                <ModuleCreator
                                                    group={groupItem}
                                                    modules={groupItem.modules}
                                                    onAddModule={onAddModule}
                                                    onViewChange={
                                                        handleViewChange
                                                    }
                                                    onRemoveModule={
                                                        onRemoveModule
                                                    }
                                                    onUpdateModule={
                                                        onUpdateModule
                                                    }
                                                    onSubjectSelected={
                                                        handlerSubjectSelected
                                                    }
                                                    noAvailableSubjects={subjectTargetKeys.concat(
                                                        subjectsInModules
                                                    )}
                                                    subjects={subjects}
                                                />
                                            </Box>
                                        )}
                                </>
                            )}
                            {activeStep === 3 && <OptionStepperResults />}
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {activeStep === 0 ? (
                        <Button onClick={handleClose}>Cerrar</Button>
                    ) : (
                        <Button onClick={handleBack}>Atrás</Button>
                    )}
                    {activeStep !== 0 && activeStep < steps.length - 1 ? (
                        <>
                            {groupType === 2 ? (
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{ ml: 2 }}
                                    disabled={
                                        StepTwoisInvalid() ||
                                        subjectTargetKeys.length >= 2
                                    }
                                >
                                    Siguiente
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{ ml: 2 }}
                                    disabled={StepTwoisInvalid()}
                                >
                                    Siguiente
                                </Button>
                            )}
                        </>
                    ) : activeStep === steps.length - 1 ? (
                        <LoadingButton
                            size="small"
                            color="primary"
                            onClick={() => {
                                SaveGroup();
                            }}
                            loading={statusOperation === 'pending'}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            disabled={StepTwoisInvalid()}
                        >
                            Finalizar
                        </LoadingButton>
                    ) : null}
                </DialogActions>
            </Dialog>
        </>
    );
};
export default AddGroupStepperModal;
