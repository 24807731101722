import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../../service/Connection';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../../service/const';

const initialState = {
    last_student_with_discount: {
        fetch: {
            expireIn: null,
            fetchingAt: null,
            status: 'idle',
            operation: 'idle',
            didInvalidate: true,
            feedback: {
                title: null,
                message: null,
                payload: null,
            },
        },
        data: [],
    },
    last_students_with_payments: {
        fetch: {
            expireIn: null,
            fetchingAt: null,
            status: 'idle',
            operation: 'idle',
            didInvalidate: true,
            feedback: {
                title: null,
                message: null,
                payload: null,
            },
        },
        data: [],
    },
    monthly_payments_count: {
        fetch: {
            expireIn: null,
            fetchingAt: null,
            status: 'idle',
            operation: 'idle',
            didInvalidate: true,
            feedback: {
                title: null,
                message: null,
                payload: null,
            },
        },
        data: [],
    },
};

const tablesSlice = createSlice({
    name: 'tables',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // LastStudentWithDis
            .addCase(
                fetchLastStudentWithDiscount.fulfilled,
                (state, action) => {
                    state.last_student_with_discount.fetch = {
                        ...state.last_student_with_discount.fetch,
                        expireIn: new Date().setMinutes(
                            new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                        ),
                        fetchingAt: Date.now(),
                        status: 'fulfilled',
                        didInvalidate: false,
                    };
                    state.last_student_with_discount.fetch.feedback.payload =
                        action.payload;
                    state.last_student_with_discount.data =
                        action.payload.data.components;
                }
            )
            .addCase(fetchLastStudentWithDiscount.pending, (state) => {
                state.last_student_with_discount.fetch.status = 'pending';
            })
            .addCase(fetchLastStudentWithDiscount.rejected, (state, action) => {
                state.last_student_with_discount.fetch.status = 'rejected';
                state.last_student_with_discount.fetch.feedback =
                    action.payload.feedback;
            })

            .addCase(
                fetchLastStudentsWithPayments.fulfilled,
                (state, action) => {
                    state.last_students_with_payments.fetch = {
                        ...state.last_students_with_payments.fetch,
                        expireIn: new Date().setMinutes(
                            new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                        ),
                        fetchingAt: Date.now(),
                        status: 'fulfilled',
                        didInvalidate: false,
                    };
                    state.last_students_with_payments.fetch.feedback.payload =
                        action.payload;
                    state.last_students_with_payments.data =
                        action.payload.data.components;
                }
            )
            .addCase(fetchLastStudentsWithPayments.pending, (state) => {
                state.last_students_with_payments.fetch.status = 'pending';
            })
            .addCase(
                fetchLastStudentsWithPayments.rejected,
                (state, action) => {
                    state.last_students_with_payments.fetch.status = 'rejected';
                    state.last_students_with_payments.fetch.feedback =
                        action.payload.feedback;
                }
            )

            // AddCase for fetchMonthlyPaymentsCount
            .addCase(fetchMonthlyPaymentsCount.fulfilled, (state, action) => {
                state.monthly_payments_count.fetch = {
                    ...state.monthly_payments_count.fetch,
                    expireIn: new Date().setMinutes(
                        new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                    ),
                    fetchingAt: Date.now(),
                    status: 'fulfilled',
                    didInvalidate: false,
                };
                state.monthly_payments_count.fetch.feedback.payload =
                    action.payload;
                state.monthly_payments_count.data =
                    action.payload.data.components;
            })
            .addCase(fetchMonthlyPaymentsCount.pending, (state) => {
                state.monthly_payments_count.fetch.status = 'pending';
            })
            .addCase(fetchMonthlyPaymentsCount.rejected, (state, action) => {
                state.monthly_payments_count.fetch.status = 'rejected';
                state.monthly_payments_count.fetch.feedback =
                    action.payload.feedback;
            });
    },
});
export const { invalidate: invalidateTables } = tablesSlice.actions;

export default tablesSlice.reducer;

///////////// THUNKS  ////////////

export const fetchLastStudentWithDiscount = createAsyncThunk(
    'fetch/lastStudentWithDiscount',
    async (schoolId) => {
        try {
            const response = await Connection.getPaymentsInformation({
                school_id: schoolId,
                data: {
                    page: 'home',
                    type: 'table',
                    idKey: 'last_student_with_discount',
                },
            });
            const lastStudentsDiscounts = response.data;
            console.log(lastStudentsDiscounts);
            return lastStudentsDiscounts;
        } catch (err) {
            console.log(err);
        }
    },
    {
        condition: (arg, { getState }) => {
            const { didInvalidate, expireIn } = selectTableFetchState(
                getState(),
                'last_student_with_discount'
            );

            const valid = expireIn && expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }

            return true; // Permite la petición si ha expirado o el estado está invalidado
        },
    }
);

export const fetchLastStudentsWithPayments = createAsyncThunk(
    'fetch/lastStudentsWithPayments',
    async (schoolId) => {
        try {
            const response = await Connection.getPaymentsInformation({
                school_id: schoolId,
                data: {
                    page: 'home',
                    type: 'table',
                    idKey: 'last_students_with_payments',
                },
            });
            const lastSudentsPayments = response.data;
            console.log(lastSudentsPayments);
            return lastSudentsPayments;
        } catch (err) {
            console.log(err);
        }
    },
    {
        condition: (arg, { getState }) => {
            const { didInvalidate, expireIn } = selectTableFetchState(
                getState(),
                'last_students_with_payments'
            );

            const valid = expireIn && expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }

            return true;
        },
    }
);

export const fetchMonthlyPaymentsCount = createAsyncThunk(
    'fetch/monthlyPaymentsCount',
    async (schoolId) => {
        try {
            const response = await Connection.getPaymentsInformation({
                school_id: schoolId,
                data: {
                    page: 'home',
                    type: 'table',
                    idKey: 'monthly_payments_count',
                },
            });
            const monthlyPayments = response.data;
            console.log(monthlyPayments);

            return monthlyPayments;
        } catch (err) {
            console.log(err);
        }
    },
    {
        condition: (arg, { getState }) => {
            const { didInvalidate, expireIn } = selectTableFetchState(
                getState(),
                'monthly_payments_count'
            );

            const valid = expireIn && expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }

            return true;
        },
    }
);

///////////// SELECTORS  ////////////

const selectPaymentsModuleState = (state) => state.paymentsModule.home.tables;

export const selectLastStudentWithDiscount = createSelector(
    [selectPaymentsModuleState],
    (tables) => tables.last_student_with_discount.data
);

export const selectLastStudentsWithPayments = createSelector(
    [selectPaymentsModuleState],
    (tables) => tables.last_students_with_payments.data
);

export const selectMonthlyPaymentsCount = createSelector(
    [selectPaymentsModuleState],
    (tables) => tables.monthly_payments_count.data
);

export const selectTableFetchState = (state, tableId) =>
    state.paymentsModule.home.tables[tableId].fetch;
