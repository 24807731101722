import { darken, lighten } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { selectSettingBySchoolId } from '../store/slices/entities/settings';
import { useAuth } from '../hooks';

const useThemeColors = () => {
    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const config = useSelector(selectSettingBySchoolId(schoolId));
    const themeColorSelection = config.find(
        (res) => res.key === 'theme-color-selection'
    )?.value;

    const themeColorFont = config.find(
        (res) => res.key === 'theme-color-font'
    )?.value;

    return {
        primary: {
            100: darken(themeColorSelection, 0.125),
            200: darken(themeColorSelection, 0.25),
            300: darken(themeColorSelection, 0.375),
            400: darken(themeColorSelection, 0.5),
            500: themeColorSelection,
            600: lighten(themeColorSelection, 0.125),
            700: lighten(themeColorSelection, 0.25),
            800: lighten(themeColorSelection, 0.375),
            900: lighten(themeColorSelection, 0.5),
        },
        secondary: {
            100: darken(themeColorFont, 0.125),
            200: darken(themeColorFont, 0.25),
            300: darken(themeColorFont, 0.375),
            400: darken(themeColorFont, 0.5),
            500: themeColorFont,
            600: lighten(themeColorFont, 0.125),
            700: lighten(themeColorFont, 0.25),
            800: lighten(themeColorFont, 0.375),
            900: lighten(themeColorFont, 0.5),
        },

        grey: {
            0: '#ffffff', // manually adjusted
            10: '#f6f6f6', // manually adjusted
            50: '#f0f0f0', // manually adjusted
            100: '#e0e0e0',
            200: '#c2c2c2',
            300: '#a3a3a3',
            400: '#858585',
            500: '#666666',
            600: '#525252',
            700: '#3d3d3d',
            800: '#292929',
            900: '#141414',
            1000: '#000000', // manually adjusted
        },
        calmWaters: {
            100: '#ccf2f4',
            200: '#99e6e9',
            300: '#67d9de',
            400: '#34cdd3',
            500: '#01c0c8',
            600: '#019aa0',
            700: '#017378',
            800: '#004d50',
            900: '#002628',
        },
        sunsetBlush: {
            100: '#fee7e1',
            200: '#fdd0c4',
            300: '#fcb8a6',
            400: '#fba189',
            500: '#fa896b',
            600: '#c86e56',
            700: '#965240',
            800: '#64372b',
            900: '#321b15',
        },

        twilight: {
            100: '#dfe7ff',
            200: '#becfff',
            300: '#9eb7ff',
            400: '#7d9fff',
            500: '#5d87ff',
            600: '#4a6ccc',
            700: '#385199',
            800: '#253666',
            900: '#131b33',
        },
        deepOcean: {
            100: '#cce3f1',
            200: '#99c7e3',
            300: '#66acd6',
            400: '#3390c8',
            500: '#0074ba',
            600: '#005d95',
            700: '#004670',
            800: '#002e4a',
            900: '#001725',
        },
        velvetNight: {
            100: '#e4d8f2',
            200: '#c8b2e5',
            300: '#ad8bd7',
            400: '#9165ca',
            500: '#763ebd',
            600: '#5e3297',
            700: '#472571',
            800: '#2f194c',
            900: '#180c26',
        },

        sunbeam: {
            100: '#fff4b3',
            200: '#fffacd',
            300: '#fff5b1',
            400: '#fff176',
            500: '#ffeb3b',
            600: '#fdd835',
            700: '#fbc02d',
            800: '#f9a824',
            900: '#f57f17',
        },
        neutral: {
            100: '#d5d1ea',
            200: '#aba6d5',
            300: '#807bbf',
            400: '#5650aa',
            500: '#2b2595',
            600: '#221f78',
            700: '#191a5b',
            800: '#0f143e',
            900: '#080a21',
            1000: '#000000',
        },
        yellow: {
            100: '#fff0cc',
            200: '#ffe199',
            300: '#ffd266',
            400: '#ffc333',
            500: '#ffb400',
            600: '#cc9000',
            700: '#996c00',
            800: '#664800',
            900: '#332400',
        },
        red: {
            100: '#ffdbdc',
            200: '#ffb7b9',
            300: '#ff9497',
            400: '#ff7074',
            500: '#ff4c51',
            600: '#cc3d41',
            700: '#992e31',
            800: '#661e20',
            900: '#330f10',
        },
        green: {
            100: '#ddf4cc',
            200: '#bbea99',
            300: '#9adf66',
            400: '#78d533',
            500: '#56ca00',
            600: '#45a200',
            700: '#347900',
            800: '#225100',
            900: '#112800',
        },
        lavender: {
            100: '#e6e6ff',
            200: '#ccccff',
            300: '#b3b3ff',
            400: '#9999ff',
            500: '#7f7fff',
            600: '#6666cc',
            700: '#4d4d99',
            800: '#333366',
            900: '#1a1a33',
        },
        coral: {
            100: '#ffebeb',
            200: '#ffc4c4',
            300: '#ff9d9d',
            400: '#ff7676',
            500: '#ff4f4f',
            600: '#cc3f3f',
            700: '#992f2f',
            800: '#661f1f',
            900: '#330f0f',
        },
        teal: {
            100: '#d0f0f0',
            200: '#a0e0e0',
            300: '#70d0d0',
            400: '#40c0c0',
            500: '#10b0b0',
            600: '#0d8a8a',
            700: '#097060',
            800: '#064747',
            900: '#032c2c',
        },
        orange: {
            100: '#ffebcc',
            200: '#ffd699',
            300: '#ffc266',
            400: '#ffb333',
            500: '#ffaa00',
            600: '#cc8800',
            700: '#996600',
            800: '#664400',
            900: '#332200',
        },
    };
};

export default useThemeColors;
