/**
 * Selector para recuperar home de avisos
 */
export const selectNoticesReprobation = (store) => store.notices.reprobation;

/**
 * Recuperamos la ui de home
 */
export const selectedReprobationNoticesUI = (state) =>
    state.notices.reprobation.ui;
