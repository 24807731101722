import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth, useFeedback } from '../../../hooks';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Stack, Tab, Tooltip } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import HomeTable from './components/HomeTable';
import { fetchHomeNoticesData, invalidate } from '../../store/home/fetchSlice';
import DistributionNoticeModal from '../../../components/DistributionNoticeModal';
import AssistsTable from './components/AssistsTable';
import { fetchAssistsNoticesData, invalidateAssistance } from '../../store/assistance/fetchSlice';
import { fetchReportsNoticesData, invalidateReport } from '../../store/report/fetchSlice';
import ReportsTable from './components/ReportsTable';
import ReprobationTable from './components/ReprobationTable';
import { fetchReprobationNoticesData, invalidateReprobation } from '../../store/reprobation/fetchSlice';
import { fetchHomeworkNoticesData, invalidateHomework } from '../../store/homework/fetchSlice';
import HomeworkTable from './components/HomeworkTable';

const NoticesHome = () => {
    //////////// HOOKS ////////////
    const Auth = useAuth();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    ///////// SHARED STATE //////////
    const schoolId = Auth.user.school_id;

    ///////// LOCAL STATE //////////
    const [value, setValue] = useState(1);
    const [openCreateNoticeModal, setOpenCreateNoticeModal] = useState(false);

    //////// HANDLERS /////////////
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const invalidateUI = () => {
        if (value === 1) {
            dispatch(invalidate());
        } else if (value  === 2){
            dispatch(invalidateAssistance())
        } else if (value  === 3){
            dispatch(invalidateReport())
        } else if (value  === 4){
            dispatch(invalidateReprobation())
        } else if (value  === 5){
            dispatch(invalidateHomework())
        }
        reload()
    };

    /**
     * Funcion para recargar los avisos
     */
    const reload = () => {
        if (value === 1) {
            dispatch(
                fetchHomeNoticesData({
                    schoolId,
                    type: value,
                })
            )
                .unwrap()
                .then((response) => {})
                .catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title,
                    });
                });
        } else if (value === 2){
            dispatch(
                fetchAssistsNoticesData({
                    schoolId,
                    type: value,
                })
            )
                .unwrap()
                .then((response) => {})
                .catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title,
                    });
                });
        } else if (value === 3){
            dispatch(
                fetchReportsNoticesData({
                    schoolId,
                    type: value,
                })
            )
                .unwrap()
                .then((response) => {})
                .catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title,
                    });
                });
        } else if (value === 4){
            dispatch(
                fetchReprobationNoticesData({
                    schoolId,
                    type: value,
                })
            )
                .unwrap()
                .then((response) => {})
                .catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title,
                    });
                });
        } else if (value === 5){
            dispatch(
                fetchHomeworkNoticesData({
                    schoolId,
                    type: value,
                })
            )
                .unwrap()
                .then((response) => {})
                .catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title,
                    });
                });
        }
    };
    return (
        <>
            <Box
                sx={{
                    paddingTop: {
                        xs: 1,
                        sm: 2,
                        md: 2,
                    },
                    paddingLeft: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },
                    paddingRight: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },
                    paddingBottom: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },
                }}
            >
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                        >
                            <TabList onChange={handleChange}>
                                <Tab label="Noticias" value={1} />
                                <Tab label="Asistencias" value={2} />
                                <Tab label="Reportes" value={3} />
                                <Tab label="Reprobación" value={4} />
                                <Tab label="Tareas" value={5} />
                            </TabList>
                            <Tooltip
                                sx={{ m: 1 }}
                                title="Sincronizar información"
                            >
                                <Stack direction="row" spacing={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            setOpenCreateNoticeModal(true);
                                        }}
                                        startIcon={<AddIcon />}
                                    >
                                        Agregar
                                    </Button>
                                    <Button
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        onClick={invalidateUI}
                                        startIcon={<SyncIcon />}
                                    >
                                        Sincronizar
                                    </Button>
                                </Stack>
                            </Tooltip>
                        </Stack>
                    </Box>
                    <TabPanel value={1}>
                        <HomeTable schoolId={schoolId} type={value} />
                    </TabPanel>
                    <TabPanel value={2}>
                        <AssistsTable schoolId={schoolId} type={value} />
                    </TabPanel>
                    <TabPanel value={3}>
                        <ReportsTable schoolId={schoolId} type={value} />
                    </TabPanel>
                    <TabPanel value={4}>
                        <ReprobationTable schoolId={schoolId} type={value} />
                    </TabPanel>
                    <TabPanel value={5}>
                        <HomeworkTable schoolId={schoolId} type={value} />
                    </TabPanel>
                </TabContext>
            </Box>
            <DistributionNoticeModal
                openModal={openCreateNoticeModal}
                setOpenModal={setOpenCreateNoticeModal}
            />
        </>
    );
};

export default NoticesHome;
