import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Typography, Button
} from '@mui/material';

import { useDispatch, useSelector } from "react-redux";
import { useAuth } from '../../../../hooks';
import { selectSettingBySchoolId } from '../../../../store/slices/entities/settings';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { selectStudentById } from '../../../../store/slices/entities/students';
import _ from 'lodash';
import useFeedback from '../../../../hooks/useFeedback';
import {
    exchangeSpecialGroup, selectExchangeSpecialGroupStatus,
    selectFetchSpecialGroupStatus, fetchSpecialGroupsGroups
} from '../../../../store/slices/studentsUI/operationsSlice';
import GroupsSelector from './GroupsSelector';
import ResolveConflictsSubjects from './ResolveConflictsSubjects';
import GroupsSelectorSkeleton from './GroupsSelectorSkeleton';

/**
 * Modal para asignar grupo a un alumno
 * 
 * @param {number} studentId Identificador del alumno
 * 
 * @returns 
 */
const ChangeSpecialGroupsModal = ({ open, setOpen, studentId = null }) => {

    const dispatch = useDispatch();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id
    const feedbackApp = useFeedback()

    //////////// SHARES STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))

    const student = useSelector(selectStudentById(studentId))

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    const exchangeGroupsStatus = useSelector(selectExchangeSpecialGroupStatus)

    const fetchGroupsStatus = useSelector(selectFetchSpecialGroupStatus)

    ///////////////// LOCAL STATE ////////////////////

    const [typeView, setTypeView] = useState(1)

    /**
     * Todos los grupo
     * 
     * Arreglo que contiene todos los grupos especiales
     */
    const [groups, setGroups] = useState([])

    /**
     * Grupos actuales
     * 
     * Arreglo que contiene los grupos actuales a los que 
     * pertenece el alumno
     */
    const [studentCurrentGroups, setStudentCurrentGroups] = useState([])

    /**
     * Grupos seleccionados
     * 
     * Grupos que el usuario selecciona para ser cambiados
     */
    const [groupsSelected, setGroupsSelected] = useState({
        current: null,
        new: null
    })

    /**
     * Materias de los grupos
     * 
     * Materias de los grupos seleccionados y su relacion
     */
    const [subjects, setSubjects] = useState({
        sourceSubjects: null,
        targetSubjects: null
    })

    ///////////// ACTIONS ///////////////////////

    /**
     * Manejador de boton guradar 
     */
    const changeGroup = () => {
        dispatch(exchangeSpecialGroup({
            student,
            sourcegroup: groupsSelected.current,
            targetGroup: groupsSelected.new,
            targetSubjects: subjects.targetSubjects
        })).unwrap()
            .then((data) => {
                setOpen(false)

                feedbackApp.showFeedback({
                    title: "Alumno agregado a grupo"
                })
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    /**
     * Efecto lanzado cuando se abre el modal
     */
    useEffect(() => {
        if (!open) {
            setStudentCurrentGroups([])
            setGroups([])
            setTypeView(1)
            setGroupsSelected({
                current: null,
                new: null
            })
        } else {
            dispatch(fetchSpecialGroupsGroups({ schoolId, studentId }))
                .unwrap()
                .then(({ allGroups, studentGroups }) => {
                    setGroups(allGroups.filter(i => i.subjects > 0))
                    setStudentCurrentGroups(studentGroups)
                }).catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title
                    })
                })
        }
    }, [open])


    /**
     * Evento lanzado cuando se selecciona un grupo
     * 
     * @param {*} currentGroup 
     * @param {*} newGroups 
     */
    const onSelectGroups = (groups) => {
        setGroupsSelected(groups)
    }

    /**
     * Evento lanzado cuando se modifican las materias
     * 
     * @param {*} currentGroup 
     * @param {*} newGroups 
     */
    const onChangeSubjects = (sourceSubjects, targetSubjects) => {
        setSubjects({
            sourceSubjects,
            targetSubjects
        })
    }

    /**
     * Manejador del boton siguiente
     */
    const handlerNextButton = () => {
        if (typeView == 1 && isGroupsSelected() && hasOneSubject()) {
            changeGroup()
        } else {
            setTypeView(2)
        }
    }
    ////////////////////////// VALIDACIONES //////////////////////////////////

    /**
     * Validacion al seleccionar grupo
     * 
     * @returns 
     */
    const formSelectGroupInvalid = () => {
        return groupsSelected.current == null || groupsSelected.new == null
    }

    /**
     * Validacion del tipo de vista de materias
     * 
     * @returns 
     */
    const formSelectSubjectsInvalid = () => {
        let { sourceSubjects, targetSubjects } = subjects

        if (sourceSubjects == null || targetSubjects == null) {
            return true
        }

        let sources = sourceSubjects.filter(i => i.target_subject_id).length
        let targets = targetSubjects.filter(i => i.source_subject_id).length

        return !(sources == sourceSubjects?.length || targets == targetSubjects?.length)
    }

    /**
     * funcion que determina si los grupos han sido seleccionados
     * 
     * @returns 
     */
    const isGroupsSelected = () => groupsSelected.current != null && groupsSelected.new != null

    /**
     * Funcion que determina si ambos grupos solo tienen una materia
     * 
     * @returns 
     */
    const hasOneSubject = () => groupsSelected.current?.subjects == 1 && groupsSelected.new?.subjects == 1

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={"md"}
            disableEscapeKeyDown={exchangeGroupsStatus == 'pending'}
            onClose={() => {
                setOpen(false)
            }}
        >
            <DialogTitle
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, color: '#fff' }}>
                        Cambiar grupo especial<br />
                        <span style={{ fontSize: '15px', color: '#fff' }}>
                            Alumno: {student?.name} {student?.last_name}
                        </span>
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {
                    (typeView == 1) && (
                        <>
                            {
                                fetchGroupsStatus == "fulfilled" && (
                                    <GroupsSelector
                                        initialState={groupsSelected}
                                        groups={groups}
                                        groupsCurrentSelected={studentCurrentGroups}
                                        onSelectGroups={onSelectGroups}
                                        disabled={exchangeGroupsStatus == 'pending'}
                                    />
                                )
                            }
                            {
                                fetchGroupsStatus == "pending" && (
                                    <GroupsSelectorSkeleton />
                                )
                            }
                        </>
                    )
                }
                {

                    (typeView == 2) && (
                        <ResolveConflictsSubjects
                            currentGroup={groupsSelected.current}
                            newGroup={groupsSelected.new}
                            onChangeSubjects={onChangeSubjects}
                        />
                    )
                }
            </DialogContent>
            <DialogActions>
                {
                    (typeView == 2) && (
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setTypeView(1);
                                setSubjects({
                                    sourceSubjects: null,
                                    targetSubjects: null
                                })
                            }}
                            disabled={exchangeGroupsStatus == 'pending'}
                        >
                            Regresar
                        </Button>
                    )
                }
                {
                    (typeView == 1) && (
                        <LoadingButton
                            size="small"
                            color="primary"
                            onClick={handlerNextButton}
                            loading={exchangeGroupsStatus == 'pending'}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            disabled={formSelectGroupInvalid()}
                        >
                            {
                                typeView == 1 && !isGroupsSelected() && (
                                    "Siguiente"
                                )
                            }
                            {
                                typeView == 1 && isGroupsSelected() && hasOneSubject() && (
                                    "Guardar"
                                )
                            }
                            {
                                typeView == 1 && isGroupsSelected() && !hasOneSubject() && (
                                    "Siguiente"
                                )
                            }

                        </LoadingButton>
                    )
                }
                {
                    (typeView == 2) && (
                        <LoadingButton
                            size="small"
                            color="primary"
                            onClick={changeGroup}
                            loading={exchangeGroupsStatus == 'pending'}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            disabled={formSelectSubjectsInvalid()}
                        >
                            Guardar
                        </LoadingButton>
                    )
                }
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => { setOpen(false) }}
                    disabled={exchangeGroupsStatus == 'pending'}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>

    )
}


export default ChangeSpecialGroupsModal