import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Button,
    Divider,
    Avatar,
    Stack,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../hooks';
//import { assignAssessor, selectStatusOperation } from '../../../store/slices/groups';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import Connection from '../../../service/Connection';
import { selectUserById } from '../../../store/slices/entities/users';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import useFeedback from '../../../hooks/useFeedback';
import {
    assignAssessor,
    selectAssignAssessorOperationStatus,
} from '../../../store/slices/groupsUI/operationsSlice';
import {
    loadGroupAssessorUI,
    selectGroupByItemSelected,
} from '../../../store/slices/groupsUI/itemSlice';
import { selectGroupItem } from '../../../store/slices/groupsUI/uiSlice';

/**
 * Componente para mostrar el asesor de un grupo
 *
 * @param {boolean} open
 *
 * @returns
 */
const ChangeAssessorsGroupModal = ({ open, setOpen, groupId = null }) => {
    const dispatch = useDispatch();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const feedbackApp = useFeedback();

    //////////// SHARED STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(selectAssignAssessorOperationStatus);

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    let group = useSelector(selectGroupByItemSelected);

    let assessor = useSelector(selectUserById(group?.assessor_id));

    ////////////// LOCAL STATE ////////////

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');

    ///////////// ACTIONS /////////////////

    /**
     * Actualizar el id del usuario
     *
     * @param {*} newState
     * @returns
     */
    const enviarDatos = () => {
        let id_assesor = value.assessor_id;
        dispatch(
            assignAssessor({
                groupId: group?.group_id,
                assessorId: id_assesor,
            })
        )
            .unwrap()
            .then((response) => {
                feedbackApp.showFeedback({
                    title: 'Orientador asignado',
                });
                closeModal();
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback?.title,
                });
            });
    };

    /**
     * Cerrar el modal y restablecer los valores
     */
    const closeModal = () => {
        dispatch(selectGroupItem(null));
        resetAutoComplete();
        setOpen(false);
    };

    /**
     * Establece los valores del componente AutoComplete
     */
    const resetAutoComplete = () => {
        setOpenSearch(false);
        setValue(null);
        setInputValue('');
    };

    ///////////////////////// VALIDACIONES //////////////////////

    const formInvalid = () => {
        return value == null;
    };

    ///////////////////////// AUTOCOMPLETE ///////////////////////

    /**
     * Opciones para el componente Autocomplete
     */
    const filterOptions = createFilterOptions({
        //limit: 10
    });

    const [openSearch, setOpenSearch] = useState(false);
    const [assessors, setAssessors] = useState([]);
    const loading =
        (openSearch && assessors.length === 0) || statusOperation == 'pending';

    /**
     * Recupera todos los asesores
     */
    /**
     * Iniciamos el flujo de recuperacion de los grupos
     * del alumno seleccionado
     */
    useEffect(() => {
        if (open) {
            dispatch(loadGroupAssessorUI(schoolId));
        }
    }, [open]);
    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            let data = await Connection.getAssesorsActivedBySchool(
                schoolId
            ).then((i) => i.data.data);

            if (active) {
                setAssessors([
                    ...data.filter((i) => i.assessor_id != assessor?.user_id),
                ]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    /**
     * Limpiar el buscador
     */
    React.useEffect(() => {
        if (!openSearch) {
            setAssessors([]);
        }
    }, [openSearch]);

    ////////////////////// VIEW //////////////////////////

    return (
        <>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="sm"
                disableEscapeKeyDown={loading}
                onClose={() => {
                    closeModal();
                }}>
                <DialogTitle
                    style={{
                        backgroundColor: titleBarBackground.value,
                        color: fontColor.value,
                    }}>
                    <div style={{ display: 'flex' }}>
                        <Typography
                            variant="h6"
                            component="div"
                            style={{ flexGrow: 1 }}>
                            Orientador educativo
                            <br />
                            <span style={{ fontSize: '15px' }}>
                                Grupo {group?.grade}° {group?.group}{' '}
                                {getTurnLevel(group?.turn)}{' '}
                                {getLevelName(group?.level)}
                            </span>
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{ alignItems: 'center', height: 'auto' }}>
                        {assessor != null && (
                            <Box sx={{ mb: 3, textAlign: 'center' }}>
                                <Typography variant="subtitle1">
                                    Orientador Actual
                                </Typography>

                                <Stack
                                    justifyContent={'center'}
                                    direction="row"
                                    alignItems={'center'}>
                                    <Avatar
                                        src={assessor.url_photo_profile}
                                        sx={{ mr: 2 }}
                                    />

                                    <Typography variant="h6">
                                        {assessor.name} {assessor.last_name}{' '}
                                        {assessor.second_last_name}
                                    </Typography>
                                </Stack>
                            </Box>
                        )}
                        <Box>
                            <Divider sx={{ mb: 2 }}>
                                Seleccionar nuevo orientador educativo
                            </Divider>

                            <Autocomplete
                                id="controllable-states-demo"
                                loadingText="Buscando..."
                                noOptionsText="No se encontraron resultados"
                                filterOptions={filterOptions}
                                value={value}
                                options={assessors}
                                loading={loading}
                                open={openSearch}
                                onOpen={() => {
                                    setOpenSearch(true);
                                }}
                                onClose={() => {
                                    setOpenSearch(false);
                                }}
                                getOptionLabel={(option) =>
                                    option.name +
                                    ' ' +
                                    option.last_name +
                                    ' ' +
                                    option.second_last_name
                                }
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="outlined"
                                        {...params}
                                        label="Buscar Orientador"
                                    />
                                )}
                            />
                        </Box>
                    </div>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        size="small"
                        color="primary"
                        onClick={enviarDatos}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        disabled={formInvalid()}>
                        {assessor != null && `Actualizar`}
                        {assessor == null && `Agregar`}
                    </LoadingButton>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={closeModal}
                        disabled={loading}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ChangeAssessorsGroupModal;
