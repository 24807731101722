import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Connection from '../../../../service/Connection';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../../service/const';

const initialState = {
    Concepts_With_Discount: {
        fetch: {
            expireIn: null,
            fetchingAt: null,
            status: 'idle',
            operation: 'idle',
            didInvalidate: true,
            feedback: {
                title: null,
                message: null,
                payload: null,
            },
        },
        data: [],
    },
};

const chartsSlice = createSlice({
    name: 'charts',
    initialState,
    reducers: {
        invalidate: (state) => {
            state.Concepts_With_Discount.fetch.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchConceptsWithDiscount.fulfilled, (state, action) => {
            state.Concepts_With_Discount.fetch = {
                ...state.Concepts_With_Discount.fetch,
                expireIn: new Date().setMinutes(
                    new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
                ),
                fetchingAt: Date.now(),
                status: 'fulfilled',
                didInvalidate: false,
            };
            state.Concepts_With_Discount.data = action.payload.data.components;
        });
        builder.addCase(fetchConceptsWithDiscount.pending, (state) => {
            state.Concepts_With_Discount.fetch.status = 'pending';
        });
        builder.addCase(fetchConceptsWithDiscount.rejected, (state, action) => {
            state.Concepts_With_Discount.fetch.status = 'rejected';
            state.Concepts_With_Discount.fetch.feedback = action.payload.feedback;
        });
    },
});

export const { invalidate: invalidateCharts } = chartsSlice.actions;
export default chartsSlice.reducer;

/////////  THUNKS  ////////////

export const fetchConceptsWithDiscount = createAsyncThunk(
    'fetch/conceptsWithDiscount',
    async (schoolId) => {
        const response = await Connection.getPaymentsInformation({
            school_id: schoolId,
            data: {
                page: 'home',
                type: 'chart',
                idKey: 'concepts_with_discount',
            },
        });
        const conceptsWithDiscount = response.data;
        console.log(conceptsWithDiscount);
        return conceptsWithDiscount;
    },
  {
        condition: (schoolId, { getState }) => {
            const { didInvalidate, expireIn } = selectChartFetchState(getState());
            const now = Date.now();

            // Si el estado no está invalidado y el tiempo de expiración no ha pasado, no hacer la petición
            if (!didInvalidate && expireIn && expireIn > now) {
                return false;
            }

            return true; // Permite la petición si el estado está invalidado o ha expirado
        },
    }
);

///////////// SELECTORS  ////////////

export const selectChartsData = (state) =>
    state.paymentsModule.home.charts.Concepts_With_Discount.data;

export const selectChartsStatus = (state) =>
    state.paymentsModule.home.charts.Concepts_With_Discount.fetch.status;

export const selectChartFetchState = (state) =>
    state.paymentsModule.home.charts.Concepts_With_Discount.fetch;
