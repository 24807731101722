import { Grid, Skeleton } from "@mui/material"

export default () => {

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={6}>
                    <Skeleton variant="rectangular" height={376} animation="wave" />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <Skeleton variant="rectangular" height={376} animation="wave" />
                </Grid>

                {[1, 2, 3, 4].map((item, index) => {
                    return (
                        <Grid item xs={12} sm={6} md={6} >
                            <Skeleton variant="rectangular" height={150} sx={{ mt: 2 }} animation="wave" />
                        </Grid>
                    )
                })}
            </Grid>
            
            <Grid item xs={12} sm={6} lg={6} mt={2} mb={2} sx={{ mt: { xs: 1, md: 5} }}>
                <Skeleton variant="rectangular" height={50} sx={{ mb: 4 }} animation="wave" />
                <Skeleton variant="rectangular" height={600} sx={{ mt: 2 }} animation="wave" />
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={6}>
                    <Skeleton variant="rectangular" height={376} animation="wave" />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <Skeleton variant="rectangular" height={376} animation="wave" />
                </Grid>
            </Grid>
        </>
    )
}
