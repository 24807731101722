import {configureStore } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import * as deepmerge from 'deepmerge'


const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    stateReconciler: autoDeepMerge,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
    const store = configureStore({
      reducer: persistedReducer,
      middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
    })
  
    /*if (process.env.NODE_ENV !== 'production' && module.hot) {
        console.log("hotReloadActived")
        module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
    }*/
  
    let persistor = persistStore(store)

    return { store, persistor}
}



function autoDeepMerge(inboundState, originalState, reducedState, debug) {
  const newState = { ...reducedState }
  // only rehydrate if inboundState exists and is an object
  if (inboundState && typeof inboundState === 'object') {
    const keys = Object.keys(inboundState)
    keys.forEach(key => {
      // ignore _persist data
      if (key === '_persist') return
      // if reducer modifies substate, skip auto rehydration
      if (originalState[key] !== reducedState[key]) {
        return
      }

      if (isPlainEnoughObject(reducedState[key])) {
        // hacemos un un merge profundo de 
        newState[key] = deepmerge(reducedState[key], inboundState[key])
        return
      }
      // otherwise hard set
      newState[key] = inboundState[key]
    })
  }

  return newState
}

function isPlainEnoughObject(o) {
  return o !== null && !Array.isArray(o) && typeof o === 'object'
}