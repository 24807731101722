import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import Services from '../../../service/Connection';
import { SCORES_EXPIRE_TIME } from '../../../service/const';
import Feedback from '../../../service/Feedback';
import * as _ from 'lodash';
import { setAllItems } from './ItemsSlice';
import { upsertManyGroup } from '../../../store/slices/entities/groups';
import { upsertManyImports } from '../../../store/slices/entities/imports';

const emptyState = {
    expireIn: null,
    ferchingAt: null,
    statusServer: 'idle',
    statusOperation: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

/**
 * Slice para el settings UI
 */
export const scoresServerSlice = createSlice({
    name: 'scoressUI/server',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        /**
         * Termina la carga de informacion
         */
        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.expireIn = new Date().setMinutes(
                new Date().getMinutes() + SCORES_EXPIRE_TIME
            );
            state.ferchingAt = Date.now();
            state.didInvalidate = false;

            state.statusServer = 'fulfilled';
        });

        builder.addCase(loadUI.pending, (state, action) => {
            state.statusServer = 'pending';
        });

        builder.addCase(loadUI.rejected, (state, action) => {
            state.statusServer = 'rejected';
            state.feedback = action.payload.feedback;
        });
    },
});

export const { invalidate } = scoresServerSlice.actions;

export default scoresServerSlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Selector para recuperar la operacion de recuperacion de datos
 */
export const selectFetch = (state) => state.scoresUI.server;

/**
 * Selector para recuperar el estado de la operacion de recuperacion de datos
 */
export const selectStatusServer = createSelector(
    selectFetch,
    (fetch) => fetch.statusServer
)

//////////////// TRUNCKS /////////////////

/**
 * Crea elemento vacios
 */
function getEmptyItem(groupId) {
    return {
        items: {},
        servers: {
            details: {
                expireIn: null,
                ferchingAt: null,
                statusServer: 'idle',
                statusOperation: 'idle',
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
        },
        ui: {
            group_id: groupId,
            studentSelected: null,
        },
    };
}

/**
 * Cargar informacion de la UI de calificaciones
 */
export const loadUI = createAsyncThunk(
    'scoresUI/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const groups = await Services.groupsBySchool(schoolId).then(
                (i) => i.data.data
            );
            const imports = await Services.getGroupsScoresImportsBySchoolId(
                schoolId
            ).then((res) => res.data.data);

            const items = groups.reduce((preveState, curr) => {
                preveState[curr.group_id] = getEmptyItem(curr.group_id);
                return preveState;
            }, {});

            thunkAPI.dispatch(upsertManyGroup(groups));
            thunkAPI.dispatch(setAllItems(items));
            thunkAPI.dispatch(upsertManyImports(imports));

            return {
                groups,
                imports,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
