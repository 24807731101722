import { Avatar, Card, CardContent, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getLevelName, getTurnLevel } from '../../libs/utils';
import { selectSchoolById } from '../../store/slices/entities/schools';
import { selectSettingByKey } from '../../store/slices/entities/settings';
import {
    selectCatalogSubjectById,
    selectGroupById,
    selectSubjectById,
} from '../../store/slices/professorModule/entitiesSlice';
/**
 * Tarjeta que muestra informacion de una materia
 * para el modulo de profesores
 *
 * @param {*} param0
 *
 * @returns
 */
const SubjectPreviewCard = ({ subjectId, sx }) => {
    const subject = useSelector(selectSubjectById(subjectId));
    const group = useSelector(selectGroupById(subject.group_id));
    const catalogSubject = useSelector(
        selectCatalogSubjectById(subject.catalog_subject_id)
    );

    const school = useSelector(selectSchoolById(group.school_id));

    const scoreMin = useSelector(selectSettingByKey('calification-minimum'));

    return (
        <Card sx={{ marginTop: 2, marginBottom: 2 }}>
            <CardContent>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Stack direction={'row'} spacing={2}>
                        <Box>
                            <Typography variant="h5" component="div">
                                {school.name}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack>
                        <Typography variant="h6" component="div">
                            {group.grade}°{group.group}{' '}
                            {getTurnLevel(group.turn)}{' '}
                        </Typography>
                        <Typography variant="h6" component="div">
                            {getLevelName(group.level)} {catalogSubject.title}
                        </Typography>
                    </Stack>
                    <Stack direction={'column'} sx={{ textAlign: 'center' }}>
                        <Typography variant="caption" component="span">
                            Alumnos{' '}
                        </Typography>

                        <Typography
                            variant="h4"
                            component="span"
                            sx={{ fontWeight: 'bold' }}
                        >
                            {group.students}
                        </Typography>
                    </Stack>
                    <Stack direction={'column'} sx={{ textAlign: 'center' }}>
                        <Typography variant="caption" component="span">
                            Faltas{' '}
                        </Typography>

                        <Typography
                            variant="h4"
                            component="span"
                            sx={{ color: 'red', fontWeight: 'bold' }}
                        >
                            {subject.total_absence}
                        </Typography>
                    </Stack>
                    <Stack direction={'column'} sx={{ textAlign: 'center' }}>
                        <Typography variant="caption" component="span">
                            Promedio{' '}
                        </Typography>

                        <Typography
                            variant="h4"
                            component="span"
                            sx={{
                                color:
                                    subject.average_rating >= scoreMin?.value
                                        ? 'green'
                                        : 'red',
                                fontWeight: 'bold',
                            }}
                        >
                            {subject.average_rating}
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default SubjectPreviewCard;
