import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Card,
  Stack,
  Container,
  TextField,
  Button,
  Typography,
  Divider,
  CardContent,
  CardActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  FormControlLabel,
  Switch,
} from "@mui/material";
import SkeletonPage from "./components/SkeletonPage";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ChangePassword from "./components/ChangePassword";
import ChangeUsername from "./components/ChangeUsername";
import { useAuth } from "../../hooks";
import { updateUser } from "../../store/slices/auth";
import SaveIcon from "@mui/icons-material/Save";
import UserPermissions from "./components/UserPermissions";
import useFeedback from "../../hooks/useFeedback";
import UserImage from "./components/UserImage";
import { DatePicker, LocalizationProvider, esES } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import Services from "../../service/Connection";
import generarCurp from "../../helper/CurpGenerator";
import { withStyles } from "@material-ui/core";

/**
 * Pagina de parfil
 *
 * @returns
 */
const Perfil = () => {
  const feedbackApp = useFeedback();
  const dispatch = useDispatch();

  const Auth = useAuth();
  let user = Auth.getUser();

  const [userState, updateUserState] = useState({
    //city_id: user.city_id,
    colony: user.colony,
    //country_code: user.country_code,
    email: user.email,
    enrollment: user.enrollment,
    inside_number: user.inside_number,
    last_name: user.last_name,
    name: user.name,
    outside_number: user.outside_number,
    second_last_name: user.second_last_name,
    street: user.street,
    zipcode: user.zipcode,
    level_education: user.level_education,
    curp: user.curp,
    rfc: user.rfc,
    date_birth: user.date_birth,
    gender: user.gender,
    city_id: user.city_id,
    birth_city_id: user.birth_city_id,
    is_indigenous: user.is_indigenous,
    is_immigrant: user.is_immigrant,
    is_stranger: user.is_stranger,
  });

  const [states, setStates] = useState([]);
  const [stateValue, setStateValue] = useState(null);
  const [municipalities, setMunicipalities] = useState([]);
  const [municipalityValue, setMunicipalityValue] = useState(null);
  const [cities, setCities] = useState([]);
  const [cityValue, setCityValue] = useState(null);

  const [birthStates, setBirthStates] = useState([]);
  const [birthStateValue, setBirthStateValue] = useState(null);
  const [birthMunicipalities, setBirthMunicipialities] = useState([]);
  const [birthMunicipalityValue, setBirthMunicipialityValue] = useState(null);
  const [birthCities, setBirthCities] = useState([]);
  const [birthCityValue, setBirthCityValue] = useState(null);

  const [birthStateSelected, setBirthStateSelected] = useState({ code: "" });

  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState(false);

  const educationLevel = [
    {
      id: 0,
      text: "No definido",
    },
    {
      id: 1,
      text: "Preescolar",
    },
    {
      id: 2,
      text: "Primaria",
    },
    {
      id: 3,
      text: "Secundaria",
    },
    {
      id: 4,
      text: "Bachillerato",
    },
    {
      id: 5,
      text: "Licenciatura/Ingeniería",
    },
    {
      id: 6,
      text: "Maestría",
    },
    {
      id: 7,
      text: "Doctorado",
    },
  ];

  const optionGender = ["Hombre", "Mujer"];

  const GreyBlueSwitch = withStyles((theme) => ({
    switchBase: {
      color: theme.palette.grey[500], // Color inicial del Switch (gris)
      "&$checked": {
        color: theme.palette.primary.main, // Color al activar el Switch (azul)
      },
      "&$checked + $track": {
        backgroundColor: theme.palette.primary.main, // Color del fondo al activar el Switch
      },
    },
    checked: {},
    track: {},
  }))(Switch);

  useEffect(() => {
    const getLocationOptions = async () => {
      setLoading(true);
      let data = { ...userState, ...user };
      let states = await Services.getStates();
      setStates(states.data.data);
      setBirthStates(states.data.data);
      let muncipalityResponse = await Services.getMunicipalityByCityId(
        user.city_id
      );
      let stateResponse = await Services.getStateByMunicipalityID(
        muncipalityResponse.data.data.municipality_id
      );
      let cityResponse = await Services.getCityById(user.city_id);
      let muncipalitiesOptions = await Services.getMunicipalitysByStateId(
        stateResponse.data.data.state_id
      );
      let citiesOptions = await Services.getCityByMunicipalityId(
        muncipalityResponse.data.data.municipality_id
      );
      setMunicipalities(muncipalitiesOptions.data.data);
      setCities(citiesOptions.data.data);
      setCityValue(cityResponse.data.data);
      setMunicipalityValue(muncipalityResponse.data.data);
      setStateValue(stateResponse.data.data);
      if (data.birth_city_id !== null) {
        let birthMunicipalityResponse = await Services.getMunicipalityByCityId(
          user.birth_city_id
        );

        let birthStateResponse = await Services.getStateByMunicipalityID(
          birthMunicipalityResponse.data.data.municipality_id
        );

        let birthCityResponse = await Services.getCityById(user.birth_city_id);

        let birthMunicipalitiesOptions =
          await Services.getMunicipalitysByStateId(
            birthStateResponse.data.data.state_id
          );

        let birthCitiesOptions = await Services.getCityByMunicipalityId(
          birthMunicipalityResponse.data.data.municipality_id
        );

        setBirthMunicipialities(birthMunicipalitiesOptions.data.data);
        setBirthCities(birthCitiesOptions.data.data);
        setBirthCityValue(birthCityResponse.data.data);
        setBirthMunicipialityValue(birthMunicipalityResponse.data.data);
        setBirthStateValue(birthStateResponse.data.data);
        setBirthStateSelected(birthStateResponse.data.data);
      }
      if (data.weight === 0) {
        data.weight = "";
      }
      updateUserState({ ...userState, ...user });
      setLoading(false);
    };
    getLocationOptions();
  }, []);

  const getMunicipalities = async (value) => {
    setStateValue(value);
    setMunicipalityValue(null);
    setCityValue(null);
    setMunicipalities([]);
    setCities([]);
    if (value !== null) {
      let municipality = await Services.getMunicipalitysByStateId(
        value.state_id
      );
      setMunicipalities(municipality.data.data);
    }
  };

  const getCities = async (value) => {
    setMunicipalityValue(value);
    setCityValue(null);
    setCities([]);
    if (value !== null) {
      let city = await Services.getCityByMunicipalityId(value.municipality_id);
      setCities(city.data.data);
    }
  };

  const saveCity = (value) => {
    if (value !== null) {
      setCityValue(value);
      userState.city_id = value.city_id;
    }
  };

  const getBirthMuncipalities = async (value) => {
    setBirthStateSelected(value);
    setBirthMunicipialityValue(null);
    setBirthCityValue(null);
    setBirthStateValue(value);
    setBirthMunicipialities([]);
    setBirthCities([]);
    if (value !== null) {
      let addressmunicipality = await Services.getMunicipalitysByStateId(
        value.state_id
      );
      setBirthMunicipialities(addressmunicipality.data.data);
    }
  };

  const getBirthCities = async (value) => {
    setBirthMunicipialityValue(value);
    setBirthCityValue(null);
    setBirthCities([]);
    if (value !== null) {
      let addresscity = await Services.getCityByMunicipalityId(
        value.municipality_id
      );
      setBirthCities(addresscity.data.data);
    }
  };

  const saveBirthCity = (value) => {
    if (value !== null) {
      setBirthCityValue(value);
      userState.birth_city_id = value.city_id;
    }
  };

  /////////////// ACTIONS //////////////////

  /**
   * Actualizacion de la contraseña
   *
   * @param {*} event
   */
  const onSubmitUser = () => {
    let endpoint = Auth.getEndPoint();
    dispatch(
      updateUser({
        userId: Auth.getUserID(),
        endpoint,
        userData: userState,
      })
    )
      .unwrap()
      .then((response) => {
        feedbackApp.showFeedback({
          title: "Actualizado correctamente",
        });
      })
      .catch(({ feedback }) => {
        feedbackApp.showFeedback({
          title: feedback.title,
        });
      });
  };

  /**
   * Actualizacion de los inputs de formulario de nombre de usuario
   *
   * @param {*} event
   */
  function onInputUserChange(event) {
    const value = event.target.value;
    const name = event.target.name;

    let oldState = {
      email: userState.email,
      name: userState.name,
      last_name: userState.last_name,
      second_last_name: userState.second_last_name,
      curp: userState.curp,
      rfc: userState.rfc,
      level_education: userState.level_education,
      gender: userState.gender,
      date_birth: userState.date_birth,
      colony: userState.colony,
      //country_code: userState.country_code,
      inside_number: userState.inside_number,
      outside_number: userState.outside_number,
      street: userState.street,
      zipcode: userState.zipcode,
      birth_city_id: userState.birth_city_id,
      is_indigenous: userState.is_indigenous,
      is_immigrant: userState.is_immigrant,
      is_stranger: userState.is_stranger,
      //city_id: userState.city_id,
      //enrollment: ""
    };

    oldState[name] = value;

    updateUserState(oldState);
  }

  /////////////////////////////////// VALIDACIONES DE LOS CAMPOS //////////////////////////////////////
  const emailValid = () => {
    const email = userState.email;
    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return {
      invalid: userState.email == "" || !regexEmail.test(email),
      message: "Campo requerido o incorrecto",
    };
  };

  const nameValid = () => {
    return {
      invalid: userState.name == "",
      message: "Campo requerido o incorrecto",
    };
  };

  const lastnameValid = () => {
    return {
      invalid: userState.last_name == "",
      message: "Campo requerido o incorrecto",
    };
  };

  const secondLastnameValid = () => {
    return {
      invalid: false, //userState.second_last_name != "",
      message: "Campo requerido o incorrecto",
    };
  };

  const colonyValid = () => {
    return {
      invalid: userState.colony == "",
      message: "Campo requerido o incorrecto",
    };
  };

  const outsideNumberValid = () => {
    return {
      invalid: userState.outside_number == "",
      message: "Campo requerido o incorrecto",
    };
  };

  const insideNumberValid = () => {
    return {
      invalid: false, //userState.inside_number != "",
      message: "Campo requerido o incorrecto",
    };
  };

  const streetValid = () => {
    return {
      invalid: userState.street == "",
      message: "Campo requerido o incorrecto",
    };
  };

  const zipValid = () => {
    return {
      invalid: userState.zipcode == "",
      message: "Campo requerido o incorrecto",
    };
  };

  const cityValid = () => {
    return {
      invalid: !cityValue || cityValue === "",
      message: "Campo requerido o incorrecto",
    };
  };

  const statesValid = () => {
    return {
      invalid: !stateValue || stateValue === "",
      message: "Campo requerido o incorrecto",
    };
  };

  const municipalityValid = () => {
    return {
      invalid: !municipalityValue || municipalityValue === "",
      message: "Campo requerido o incorrecto",
    };
  };

  const birthCityValid = () => {
    return {
      invalid: !birthCityValue || birthCityValue === "",
      message: "Campo requerido o incorrecto",
    };
  };

  const birthStateValid = () => {
    return {
      invalid: !birthStateValue || birthStateValue === "",
      message: "Campo requerido o incorrecto",
    };
  };

  const birthMunicipalityValid = () => {
    return {
      invalid: !birthMunicipalityValue || birthMunicipalityValue === "",
      message: "Campo requerido o incorrecto",
    };
  };

  const isCurpEditable = (userState, scenario) => {
    // Paso 1: Verifica si los campos requeridos para la CURP están llenos
    const requiredFieldsFilled =
      userState.name &&
      userState.last_name &&
      userState.second_last_name &&
      userState.date_birth &&
      userState.gender;

    // Paso 2: Evalúa el escenario y decide si el campo CURP es editable

    if (scenario === "withData") {
      // Si el escenario es 'withData', el campo CURP es editable si los campos requeridos están llenos
      return true; // Siempre editable
    } else if (scenario === "withFilledFields") {
      // Si el escenario es 'withFilledFields', el campo CURP es editable si los campos requeridos están llenos
      // Esto se mantiene igual a la opción 'withData'
      return true; // Siempre editable
    } else if (scenario === "withSwitches") {
      // Si el escenario es 'withSwitches', el campo CURP es editable si falta al menos un campo requerido
      return !requiredFieldsFilled; // Si falta algún campo, es editable
    }

    // Paso 3: Si el escenario no coincide con ninguna de las opciones anteriores, retorna falso por defecto
    return false;
  };

  const curpValid = () => {
    const isAnySwitchActive =
      userState.is_indigenous ||
      userState.is_immigrant ||
      userState.is_stranger;

    if (isAnySwitchActive) {
      const requiredFieldsFilled =
        userState.name &&
        userState.last_name &&
        userState.second_last_name &&
        userState.date_birth &&
        userState.gender &&
        birthStateSelected &&
        birthStateSelected.code;

      if (
        requiredFieldsFilled &&
        userState.curp !== "" &&
        userState.curp !== null
      ) {
        const curpGenerada = generarCurp(
          userState.name || "",
          userState.last_name || "",
          userState.second_last_name || "",
          userState.date_birth || "",
          userState.gender || "",
          birthStateSelected.code || ""
        );
        const isCurpValid = curpGenerada === userState.curp;

        return {
          invalid: !isCurpValid,
          message: "La CURP proporcionada no es correcta",
        };
      } else {
        return {
          invalid: false,
          message: "",
        };
      }
    } else {
      if (userState.curp === "" || userState.curp === null) {
        return {
          invalid: true,
          message: "La curp es requerida",
        };
      } else {
        if (
          userState.name === "" ||
          userState.last_name === "" ||
          userState.second_last_name === "" ||
          userState.date_birth === null ||
          userState.gender === "" ||
          !birthStateSelected ||
          !birthStateSelected.code
        ) {
          return {
            invalid: true,
            message: "La CURP proporcionada no es correcta",
          };
        }

        const curpGenerada = generarCurp(
          userState.name,
          userState.last_name,
          userState.second_last_name,
          userState.date_birth,
          userState.gender,
          birthStateSelected.code,
          userState.curp.substring(16, 17)
        );

        const isCurpValid = curpGenerada === userState.curp;

        return {
          invalid: !isCurpValid,
          message: isCurpValid ? "" : "La CURP proporcionada no es correcta",
        };
      }
    }
  };
  const rfcValid = () => {
    // var rfcFormat =
    //   /^([A-ZÑ&]{3,4}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1-2]\d|3[0-1])[A-Z\d]{0,3})?$/;
    // const isRFC = rfcFormat.test(userState.rfc);

    const isAnySwitchActive =
      userState.is_indigenous ||
      userState.is_immigrant ||
      userState.is_stranger;

    if (isAnySwitchActive) {
      return {
        invalid: false, // Si hay algún switch activo, el RFC no es obligatorio
        message: "Campo opcional",
      };
    }

    return {
      invalid:
        userState.rfc === "" ||
        userState.rfc === null ||
        userState.rfc.length < 10 ||
        userState.rfc.length > 13,
      message: "Campo requerido o incorrecto",
    };
  };

  const educationLevelValid = () => {
    return {
      invalid:
        userState.level_education === "" || userState.level_education === null,
      message: "Campo requerido o incorrecto",
    };
  };

  const genderValid = () => {
    return {
      invalid: userState.gender === "" || userState.gender === null,
      message: "Campo requerido o incorrecto",
    };
  };

  const birthDateValid = () => {
    return {
      invalid:
        userState.date_birth === "" ||
        userState.date_birth === null ||
        dateError,
      message: "Campo requerido o incorrecto",
    };
  };

  const userForminValid = () => {
    return (
      emailValid().invalid ||
      nameValid().invalid ||
      lastnameValid().invalid ||
      secondLastnameValid().invalid ||
      colonyValid().invalid ||
      insideNumberValid().invalid ||
      outsideNumberValid().invalid ||
      outsideNumberValid().invalid ||
      streetValid().invalid ||
      zipValid().invalid ||
      curpValid().invalid ||
      rfcValid().invalid ||
      educationLevelValid().invalid ||
      genderValid().invalid ||
      genderValid().invalid ||
      birthDateValid().invalid
    );
  };

  return (
    <Container
      sx={{
        flexGrow: 1,
        paddingTop: {
          xs: 1,
          sm: 2,
          md: 2,
        },
        paddingLeft: {
          xs: 1,
          sm: 2,
          md: 5,
        },
        paddingRight: {
          xs: 1,
          sm: 2,
          md: 5,
        },
        paddingBottom: {
          xs: 1,
          sm: 2,
          md: 5,
        },
      }}
    >
      {loading === true ? (
        <SkeletonPage />
      ) : (
        <Box>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    divider={
                      <Divider
                        orientation="horizontal"
                        variant="middle"
                        flexItem
                      />
                    }
                    spacing={4.2}
                  >
                    <Box component="div" sx={{ padding: 1 }}>
                      <UserImage />
                    </Box>

                    <Box>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          component="div"
                        >
                          Teléfono: {user.cellphone}
                        </Typography>
                        {user.enrollment && (
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            component="div"
                          >
                            Folio: {user.enrollment}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={9}>
                  <Box>
                    <Stack spacing={2} sx={{ paddingLeft: 2 }}>
                      <Box direction="div">
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ marginBottom: 1 }}
                        >
                          Información personal
                        </Typography>

                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="name"
                              name="name"
                              label="Nombre(*)"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 50, autocomplete: "turnoff" }}
                              value={userState.name}
                              onChange={onInputUserChange}
                              error={nameValid().invalid}
                              helperText={
                                nameValid().invalid
                                  ? nameValid().message
                                  : `Caracteres disponibles: ${
                                      userState?.name
                                        ? userState.name.length
                                        : 0
                                    }/50`
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="last_name"
                              name="last_name"
                              label="Apellido paterno(*)"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 50, autocomplete: "turnoff" }}
                              value={userState.last_name}
                              onChange={onInputUserChange}
                              error={lastnameValid().invalid}
                              helperText={
                                lastnameValid().invalid
                                  ? lastnameValid().message
                                  : `Caracteres disponibles: ${
                                      userState?.last_name
                                        ? userState.last_name.length
                                        : 0
                                    }/50`
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="second_last_name"
                              name="second_last_name"
                              label="Apellido materno"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 50, autocomplete: "turnoff" }}
                              value={userState.second_last_name}
                              onChange={onInputUserChange}
                              error={secondLastnameValid().invalid}
                              helperText={
                                secondLastnameValid().invalid
                                  ? secondLastnameValid().message
                                  : `Caracteres disponibles: ${
                                      userState?.second_last_name
                                        ? userState.second_last_name.length
                                        : 0
                                    }/50`
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="email"
                              name="email"
                              label="Correo electrónico(*)"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 50, autocomplete: "turnoff" }}
                              value={userState.email}
                              onChange={onInputUserChange}
                              error={emailValid().invalid}
                              helperText={
                                emailValid().invalid
                                  ? emailValid().message
                                  : `Caracteres disponibles: ${
                                      userState?.email
                                        ? userState.email.length
                                        : 0
                                    }/50`
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="curp"
                              name="curp"
                              label="CURP"
                              type="text"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 18, autocomplete: "turnoff" }}
                              value={userState.curp ? userState.curp.toUpperCase(): ''}
                              onChange={onInputUserChange}
                              error={curpValid().invalid}
                              helperText={
                                curpValid().invalid
                                  ? curpValid().message
                                  : `Caracteres disponibles: ${
                                      userState?.curp
                                        ? userState.curp.length
                                        : 0
                                    }/18`
                              }
                              disabled={!isCurpEditable(userState, "withData")}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="rfc"
                              name="rfc"
                              label="RFC"
                              type="text"
                              variant="outlined"
                              fullWidth
                              inputProps={{ autocomplete: "turnoff" }}
                              value={userState.rfc ? userState.rfc.toUpperCase(): ''}
                              onChange={onInputUserChange}
                              error={rfcValid().invalid}
                              helperText={
                                rfcValid().invalid ? rfcValid().message : false
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              required
                              error={educationLevelValid().invalid}
                            >
                              <InputLabel id="test-select-label">
                                Selecciona el nivel educativo
                              </InputLabel>
                              <Select
                                variant="outlined"
                                name="level_education"
                                value={userState.level_education}
                                onChange={onInputUserChange}
                                labelId="test-select-label"
                                label={"Selecciona el nivel educativo"}
                              >
                                <MenuItem value="" />
                                {educationLevel.map((level) => (
                                  <MenuItem key={level.id} value={level.id}>
                                    {level.text}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              required
                              error={genderValid().invalid}
                            >
                              <InputLabel id="gender">
                                Seleccionar sexo
                              </InputLabel>
                              <Select
                                variant="outlined"
                                name="gender"
                                value={userState.gender}
                                onChange={onInputUserChange}
                                labelId="gender"
                                label={"Seleccionar Sexo"}
                              >
                                <MenuItem value="" />
                                {optionGender.map((option) => (
                                  <MenuItem
                                    key={option}
                                    value={option.charAt(0)}
                                  >
                                    {option}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <LocalizationProvider
                              dateAdapter={AdapterLuxon}
                              localeText={
                                esES.components.MuiLocalizationProvider
                                  .defaultProps.localeText
                              }
                              adapterLocale="es-MX"
                            >
                              <DatePicker
                                views={["year", "month", "day"]}
                                label="Fecha de nacimiento"
                                value={
                                  userState.date_birth
                                    ? DateTime.fromISO(userState.date_birth)
                                    : null
                                }
                                onChange={(date) => {
                                  const formattedDate = date
                                    ? date.toISODate()
                                    : null;
                                  updateUserState({
                                    ...userState,
                                    date_birth: formattedDate,
                                  });
                                }}
                                onClose={() => {}}
                                onError={(error) => {
                                  if (error === null) {
                                    setDateError(false);
                                  } else {
                                    setDateError(true);
                                  }
                                }}
                                shouldDisableDate={(day) => {
                                  const currentDate = DateTime.now();
                                  let minDate = currentDate.minus({
                                    years: 80,
                                  });
                                  minDate = DateTime.fromISO(
                                    minDate.year + "-08-30T13:07:04.054"
                                  );
                                  let maxDate = currentDate.minus({
                                    years: 12,
                                  });
                                  maxDate = DateTime.fromISO(
                                    maxDate.year + "-08-30T13:07:04.054"
                                  );
                                  return day < minDate || day > maxDate;
                                }}
                                minDate={DateTime.fromISO(
                                  DateTime.now().minus({ years: 80 }).year +
                                    "-08-30T11:59:59.054"
                                )}
                                maxDate={DateTime.fromISO(
                                  DateTime.now().minus({ years: 12 }).year +
                                    "-08-30T11:59:59.054"
                                )}
                              />
                            </LocalizationProvider>
                            {birthDateValid().invalid && (
                              <Grid item>
                                <Typography variant="caption" color="error">
                                  {birthDateValid().message}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Autocomplete
                              value={birthStateValue}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              getOptionLabel={(option) => option.title}
                              onChange={(event, newValue) => {
                                getBirthMuncipalities(newValue);
                              }}
                              id="controllable-states-demo"
                              options={birthStates}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  {option.title}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Estado de nacimiento"
                                  variant="outlined"
                                  error={birthStateValid().invalid}
                                  helperText={
                                    birthStateValid().invalid
                                      ? birthStateValid().message
                                      : false
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Autocomplete
                              value={birthMunicipalityValue}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              getOptionLabel={(option) => option.title}
                              onChange={(event, newValue) => {
                                getBirthCities(newValue);
                              }}
                              id="controllable-states-demo"
                              options={birthMunicipalities}
                              disabled={
                                birthMunicipalities.length === 0 ? true : false
                              }
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  {option.title}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Municipio de nacimiento"
                                  variant="outlined"
                                  error={birthMunicipalityValid().invalid}
                                  helperText={
                                    birthMunicipalityValid().invalid
                                      ? birthMunicipalityValid().message
                                      : false
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Autocomplete
                              value={birthCityValue}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              getOptionLabel={(option) => option.title}
                              onChange={(event, newValue) => {
                                saveBirthCity(newValue);
                              }}
                              id="controllable-states-demo"
                              options={birthCities}
                              disabled={birthCities.length === 0 ? true : false}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  key={option.city_id}
                                  {...props}
                                >
                                  {option.title}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Ciudad de nacimiento"
                                  variant="outlined"
                                  error={birthCityValid().invalid}
                                  helperText={
                                    birthCityValid().invalid
                                      ? birthCityValid().message
                                      : false
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ pt: 2, pb: 2, pl: 8 }}
                            >
                              <FormControlLabel
                                control={
                                  <GreyBlueSwitch
                                    checked={userState.is_indigenous}
                                    disabled={true}
                                  />
                                }
                                label="Indígena"
                              />
                              <FormControlLabel
                                control={
                                  <GreyBlueSwitch
                                    defaultChecked={userState.is_immigrant}
                                    disabled={true}
                                  />
                                }
                                label="Inmigrante"
                              />
                              <FormControlLabel
                                control={
                                  <GreyBlueSwitch
                                    defaultChecked={userState.is_stranger}
                                    disabled={true}
                                  />
                                }
                                label="Extranjero"
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>

                      <Divider flexItem />

                      <Box>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ marginBottom: 1 }}
                        >
                          Información ubicación
                        </Typography>

                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="street"
                              name="street"
                              label="Calle(*)"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 50, autocomplete: "turnoff" }}
                              value={userState.street}
                              onChange={onInputUserChange}
                              error={streetValid().invalid}
                              helperText={
                                streetValid().invalid
                                  ? streetValid().message
                                  : `Caracteres disponibles: ${
                                      userState?.street
                                        ? userState.street.length
                                        : 0
                                    }/50`
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="colony"
                              name="colony"
                              label="Colonia(*)"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 50, autocomplete: "turnoff" }}
                              value={userState.colony}
                              onChange={onInputUserChange}
                              error={colonyValid().invalid}
                              helperText={
                                colonyValid().invalid
                                  ? colonyValid().message
                                  : `Caracteres disponibles: ${
                                      userState?.colony
                                        ? userState.colony.length
                                        : 0
                                    }/50`
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            {/* 
                                                <TextField
                                                    id="country_code"
                                                    name="country_code" 
                                                    label="Codigo del paiz"
                                                    variant="outlined"
                                                    size="small"
                                                    value={userState.country_code}
                                                    onChange={onInputUserChange}
                                                />
                                                */}

                            <TextField
                              id="outside_number"
                              name="outside_number"
                              label="Número exterior(*)"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 20, autocomplete: "turnoff" }}
                              value={userState.outside_number}
                              onChange={onInputUserChange}
                              error={outsideNumberValid().invalid}
                              helperText={
                                outsideNumberValid().invalid
                                  ? outsideNumberValid().message
                                  : `Caracteres disponibles: ${
                                      userState?.outside_number
                                        ? userState.outside_number.length
                                        : 0
                                    }/20`
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="inside_number"
                              name="inside_number"
                              label="Número interior"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 20, autocomplete: "turnoff" }}
                              value={userState.inside_number}
                              onChange={onInputUserChange}
                              error={insideNumberValid().invalid}
                              helperText={
                                insideNumberValid().invalid
                                  ? insideNumberValid().message
                                  : `Caracteres disponibles: ${
                                      userState?.inside_number
                                        ? userState.inside_number.length
                                        : 0
                                    }/20`
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="zipcode"
                              name="zipcode"
                              label="Código postal(*)"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 5, autocomplete: "turnoff" }}
                              value={userState.zipcode}
                              onChange={onInputUserChange}
                              error={zipValid().invalid}
                              helperText={
                                zipValid().invalid
                                  ? zipValid().message
                                  : `Caracteres disponibles: ${
                                      userState?.zipcode
                                        ? userState.zipcode.length
                                        : 0
                                    }/5`
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Autocomplete
                              value={stateValue}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              getOptionLabel={(option) => option.title}
                              onChange={(event, newValue) => {
                                getMunicipalities(newValue);
                              }}
                              id="controllable-states-demo"
                              options={states}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  {option.title}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Elige un estado"
                                  variant="outlined"
                                  error={statesValid().invalid}
                                  helperText={
                                    statesValid().invalid
                                      ? statesValid().message
                                      : false
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Autocomplete
                              value={municipalityValue}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              getOptionLabel={(option) => option.title}
                              onChange={(event, newValue) => {
                                getCities(newValue);
                              }}
                              id="controllable-states-demo"
                              options={municipalities}
                              disabled={
                                municipalities.length === 0 ? true : false
                              }
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  {option.title}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Elige un municipio"
                                  variant="outlined"
                                  error={municipalityValid().invalid}
                                  helperText={
                                    municipalityValid().invalid
                                      ? municipalityValid().message
                                      : false
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Autocomplete
                              value={cityValue}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              getOptionLabel={(option) => option.title}
                              onChange={(event, newValue) => {
                                saveCity(newValue);
                              }}
                              id="controllable-states-demo"
                              options={cities}
                              disabled={cities.length === 0 ? true : false}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  key={option.city_id}
                                  {...props}
                                >
                                  {option.title}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Elige una ciudad"
                                  variant="outlined"
                                  error={cityValid().invalid}
                                  helperText={
                                    cityValid().invalid
                                      ? cityValid().message
                                      : false
                                  }
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions
              sx={{
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                size="small"
                disabled={userForminValid()}
                startIcon={<SaveIcon />}
                onClick={onSubmitUser}
              >
                Actualizar
              </Button>
            </CardActions>
          </Card>

          {Auth.isAdminister() && <UserPermissions sx={{ marginTop: 2 }} />}

          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            sx={{ marginTop: 2 }}
          >
            <Grid item xs={12} sm={6}>
              <ChangePassword></ChangePassword>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ChangeUsername></ChangeUsername>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
};

export default Perfil;
