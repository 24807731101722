import { CardHeader, Stack } from '@mui/material';
import { GridToolbar } from '@mui/x-data-grid';

const ReportsToolBar = (props) => {
    return (
        <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <CardHeader />
            <GridToolbar {...props} />
        </Stack>
    );
};

export { ReportsToolBar };
