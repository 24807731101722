import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Tabs, Tab, Typography } from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbar,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectAssistsAbsences,
} from '../../../store/groups/fetchSlice';
import { getAssistsAbsences } from '../../../store/groups/thunks';
import { selectComponentGroupsAssistsAbsencesStatusServer } from '../../../store/groups/componentStatusSelectors';
import { SkeletongGrafics } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { Tag } from 'antd';

const AssistsAbsences = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentGroupsAssistsAbsencesStatusServer
    );
    const assistsAbsences = useFetchResource(
        () =>
            getAssistsAbsences({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectAssistsAbsences,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getAssistsAbsences({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectAssistsAbsences,
            [cycleSelected, schoolId]
        );
    };

    const [tabIndex, setTabIndex] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangeTab = (event, newIndex) => {
        setTabIndex(newIndex);
        setPage(0);
    };

    const handlePageSizeChange = (newPageSize) => {
        setRowsPerPage(newPageSize);
    };

    const tabs = ['Principal', 'Especial', 'Extraescolar', 'Modulo'];
    const availableTabs = tabs.filter(
        (tab) =>
            assistsAbsences?.value && assistsAbsences?.value[tab.toUpperCase()]
    );

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbar {...props} />
            </GridToolbarContainer>
        );
    };

    const renderDataGrid = () => {
        if (availableTabs.length === 0) {
            return <Typography>No hay datos disponibles</Typography>;
        }

        const category = availableTabs[tabIndex];
        const data = assistsAbsences?.value
            ? assistsAbsences.value[category.toUpperCase()]
            : [];

        if (data.length === 0)
            return <Typography>No hay datos disponibles</Typography>;

        const rows = data.map((item, index) => ({
            id: index,
            ...item.data.reduce((obj, value, idx) => {
                obj[`col${idx}`] = value;
                return obj;
            }, {}),
        }));

        const columns =
            data[0]?.labels.map((label, index) => ({
                field: `col${index}`,
                headerName: label,
                flex: 1,
                renderCell: (params) => {
                    if (label === 'ASISTENCIAS') {
                        return (
                            <Tag
                                color="success"
                                style={{
                                    width: '100px',
                                    textAlign: 'center',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'normal',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {params.value || '0'}
                            </Tag>
                        );
                    }
                    if (label === 'FALTAS') {
                        return (
                            <Tag
                                color="error"
                                style={{
                                    width: '100px',
                                    textAlign: 'center',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'normal',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {params.value || '0'}
                            </Tag>
                        );
                    }
                    if (label === 'JUSTIFICADAS') {
                        return (
                            <Tag
                                color="blue"
                                style={{
                                    width: '100px',
                                    textAlign: 'center',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'normal',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {params.value || '0'}
                            </Tag>
                        );
                    }
                    return <span>{params.value || '0'}</span>;
                },
            })) || [];

        return (
            <Box sx={{ width: '100%', mt: 2 }}>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[]}
                    disableSelectionOnClick
                    disableDensitySelector
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{
                        panel: {
                            placement: 'bottom-end',
                        },
                    }}
                    getRowHeight={() => 'auto'}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            overflow: 'visible',
                            lineHeight: '3',
                        },
                        '&.MuiDataGrid-root': {
                            border: 'none',
                        },
                    }}
                    autoHeight
                />
            </Box>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && <SkeletongGrafics items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                    }}
                >
                    <Typography variant="h6" sx={{ ml: 1 }}>
                        Resumen
                    </Typography>
                    <CardContent sx={{ padding: '2px' }}>
                        {availableTabs.length > 0 ? (
                            <>
                                <Tabs
                                    value={tabIndex}
                                    onChange={handleChangeTab}
                                    aria-label="tabs"
                                    sx={{
                                        '& .MuiTabs-indicator': {
                                            backgroundColor: '#1a73e8',
                                        },
                                    }}
                                >
                                    {availableTabs.map((tab, index) => (
                                        <Tab
                                            key={index}
                                            label={tab}
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: 600,
                                                color:
                                                    tabIndex === index
                                                        ? '#1a73e8'
                                                        : '#575959',
                                                transition: 'color 0.3s ease',
                                                '&:hover': {
                                                    color: '#0c47a1',
                                                },
                                            }}
                                        />
                                    ))}
                                </Tabs>
                                {renderDataGrid()}
                            </>
                        ) : (
                            <Typography>No hay datos disponibles</Typography>
                        )}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default AssistsAbsences;
