import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    DataGrid,
    esES,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Divider,
    IconButton,
    Pagination,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useAuth, useFeedback } from '../../../../hooks';
import { selectTableData } from '../../../store/concepts/selectors';
import { useTheme } from '@emotion/react';
import {
    AddCircleOutline,
    BookmarkAddRounded,
    CheckOutlined,
    CloseOutlined,
    Delete,
    Edit,
} from '@mui/icons-material';
import CreateConcept from './CreateConcept';
import CatalogModal from './CatalogModal';
import {
    DeleteConcept,
    UpdateConcept,
} from '../../../store/concepts/operations';
import ModalUpdateConcept from './ModalUpdateConcept';
import useThemeColors from '../../../../theme/themes';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { setStatusFilter } from '../../../store/concepts/ui';

import student from '../../../../assets/images/default-image-niña.svg';
import student2 from '../../../../assets/images/default-image-niño.svg';
import ExportButton from '../../../components/exportButton';
import CustomPagination from '../../../components/CustomPagination';
import DeleteSweepTwoToneIcon from '@mui/icons-material/DeleteSweepTwoTone';
import EditRoadTwoToneIcon from '@mui/icons-material/EditRoadTwoTone';
import RunningWithErrorsOutlinedIcon from '@mui/icons-material/RunningWithErrorsOutlined';
const ConceptTable = () => {
    ///////////// SHARED STATE /////////////
    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = useThemeColors();
    const feedbackApp = useFeedback();

    const datatable = useSelector(selectTableData);
    const statusFilter = useSelector(
        (state) => state.paymentsModule.concepts.ui.statusFilter
    );

    ///////////// LOCAL STATE /////////////

    const [open, setOpen] = useState(false);
    const [openCatalog, setOpenCatalog] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');

    const [tabValue, setTabValue] = useState(() => {
        const savedTab = localStorage.getItem('conceptsTab');
        return savedTab ? parseInt(savedTab, 10) : 0;
    });

    ///////////// USE EFFECT /////////////

    ///////////// HANDLERS /////////////
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenCatalog = () => setOpenCatalog(true);
    const handleCloseCatalog = () => setOpenCatalog(false);

    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const handleTabChange = (event, newValue) => {
        dispatch(setStatusFilter(newValue));
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleSelectionChange = (selection) => {
        const selectedData = datatable.filter((row) =>
            selection.includes(row.concept_id)
        );
        setSelectedRows(selectedData);
        setSelectedIds(selection);
    };

    const handleDeleteSelected = async () => {
        const data = selectedIds.map((concept_id) => ({
            concept_id: concept_id,
        }));

        dispatch(DeleteConcept({ data }))
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Concepto Cancelado',
                });
                setSelectedRows([]);
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title:
                        error.feedback?.title ||
                        'Error al cancelar el concepto',
                });
            });
    };

    /////////// COLUMN CONFIG /////////////
    const getStatusColor = (status) => {
        switch (status) {
            case 1:
                return {
                    color: colors.green[200],
                    description: 'Pagado',
                    icon: (
                        <CheckOutlined
                            size="small"
                            sx={{
                                color: colors.green[600],
                            }}
                        />
                    ),
                };
            case 2:
                return {
                    color: colors.orange[200],
                    description: 'Pendiente',
                    icon: (
                        <RunningWithErrorsOutlinedIcon
                            size="small"
                            sx={{
                                color: colors.orange[600],
                            }}
                        />
                    ),
                };
            case 3:
                return {
                    color: colors.red[200],
                    description: 'Cancelado',
                    icon: (
                        <CloseOutlined
                            size="small"
                            sx={{
                                color: colors.red[600],
                            }}
                        />
                    ),
                };
            default:
                return { color: colors.grey[300], description: 'Desconocido' };
        }
    };

    const columns = [
        {
            field: 'in_agreement',
            headerName: '',
            width: 10,
            renderCell: (params) => (
                <Tooltip
                    title={
                        params.row.in_agreement
                            ? 'En convenio'
                            : 'No conveniado'
                    }
                    arrow>
                    <Box
                        sx={{
                            width: '10%',
                            height: '100%',
                            backgroundColor: params.row.in_agreement
                                ? colors.calmWaters[500]
                                : colors.sunsetBlush[500],
                        }}
                    />
                </Tooltip>
            ),
        },
        {
            field: 'concept_id',
            headerName: 'Folio',
            flex: 0.5,
            minWidth: 100,
            renderCell: (params) => (
                <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                    #{params.value}
                </Typography>
            ),
        },
        {
            field: 'user',
            headerName: 'Alumno',
            flex: 3,
            minWidth: 400,
            renderCell: (params) => {
                const user = params.row.user;
                const defaultAvatar = user.gender === 'M' ? student : student2;
                const avatarSrc = user.url_photo_profile || defaultAvatar;

                return (
                    <Box display="flex" alignItems="center">
                        <Avatar src={avatarSrc} sx={{ marginRight: 2 }} />
                        <Box>
                            <Typography variant="body1" noWrap>
                                {`${user.name} ${user.last_name} ${user.second_last_name}`}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                noWrap>
                                ID: {user.student_id}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                noWrap>
                                Teléfono: {user.cellphone}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Estado',
            flex: 1,
            headerAlign: 'center',
            minWidth: 200,
            align: 'center',
            renderCell: (params) => {
                const { color, description, icon } = getStatusColor(
                    params.value
                );
                return (
                    <Box>
                        <Tooltip title={description}>
                            <Box
                                sx={{
                                    borderRadius: '100%',
                                    height: 30,
                                    width: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: color,
                                    marginRight: 1,
                                }}>
                                {icon}
                            </Box>
                        </Tooltip>
                    </Box>
                );
            },
        },
        {
            field: 'Ultimo_Pago',
            headerName: 'Último Pago',
            flex: 2,
            minWidth: 150,
        },
        {
            field: 'Fecha_limite',
            headerName: 'Fecha Límite',
            flex: 2,
            minWidth: 150,
        },
        {
            field: 'concept',
            headerName: 'Concepto',
            flex: 2,
            headerAlign: 'center',
            Align: 'center',
            minWidth: 200,
            renderCell: (params) => {
                const title = params.value;
                const description = params.row.description;

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                color: colors.deepOcean[500],
                            }}
                            noWrap>
                            {title}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: '0.875rem',
                                color: colors.grey[800],
                            }}
                            noWrap>
                            {description}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'Subtotal',
            headerName: 'Subtotal',
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',

            renderCell: (params) => (
                <Box display="flex" justifyContent="center" width="100%">
                    <Typography
                        variant="h6"
                        sx={{
                            width: '100%',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            textAlign: 'center',
                        }}>
                        ${params.value}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'Recargo',
            headerName: 'Recargo',
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',

            renderCell: (params) => (
                <Box display="flex" justifyContent="center" width="100%">
                    <Typography
                        variant="h6"
                        sx={{
                            color: colors.red[500],
                            width: '100%',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            textAlign: 'center',
                        }}>
                        + ${params.value}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'Descuento',
            headerName: 'Descuento',
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',

            renderCell: (params) => (
                <Box display="flex" justifyContent="center" width="100%">
                    <Typography
                        variant="h6"
                        sx={{
                            color: colors.green[500],
                            width: '100%',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            textAlign: 'center',
                        }}>
                        - ${params.value}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'Total',
            headerName: 'Total',
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',

            renderCell: (params) => (
                <Typography
                    variant="h6"
                    sx={{
                        width: '100%',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        textAlign: 'center',
                    }}>
                    ${params.value}
                </Typography>
            ),
        },
    ];

    const filteredRows = datatable
        .filter((row) => {
            const normalize = (str) =>
                str
                    .toString()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase();

            const searchTermNormalized = normalize(searchTerm);

            const matches = [
                normalize(row.concept_id).includes(searchTermNormalized),
                normalize(row.user.name).includes(searchTermNormalized),
                normalize(row.user.last_name).includes(searchTermNormalized),
                normalize(row.user.second_last_name).includes(
                    searchTermNormalized
                ),
                normalize(row.user.student_id).includes(searchTermNormalized),
                normalize(row.user.cellphone).includes(searchTermNormalized),
                normalize(row.catConcept.concept).includes(
                    searchTermNormalized
                ),
                normalize(row.description).includes(searchTermNormalized),
            ];

            if (!isNaN(searchTerm)) {
                const searchNumber = Number(searchTerm);
                matches.push(
                    row.concept_id === searchNumber,
                    row.user.student_id === searchNumber,
                    row.subtotal === searchNumber,
                    row.surcharge_total === searchNumber,
                    row.discount_total === searchNumber,
                    row.net_total === searchNumber
                );
            }

            return matches.some(Boolean);
        })
        .filter((row) => {
            if (statusFilter === 'all') return true;
            if (statusFilter === 'paid' && row.status === 1) return true;
            if (statusFilter === 'pending' && row.status === 2) return true;
            if (statusFilter === 'canceled' && row.status === 3) return true;
            return false;
        })
        .map((row) => ({
            in_agreement: row.in_agreement,
            id: row.concept_id,
            user: row.user,
            status: row.status,
            concept_id: row.concept_id,
            Ultimo_Pago: row.last_payment_date,
            Fecha_limite: row.limit_date,
            concept: row.catConcept.concept,
            description: row.description,
            Subtotal: row.subtotal,
            Recargo: row.surcharge_total,
            Descuento: row.discount_total,
            Total: row.net_total,
            date: row.created_at,
        }))
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    const hasCancelledConcepts = selectedRows.some((row) => row.status !== 2);

    return (
        <>
            <Card
                sx={{
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.neutral.main,
                    maxWidth: '100%',
                }}>
                <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="flex-end"
                    alignItems="center"
                    paddingBottom={3}>
                    <CardActions>
                        <Tooltip title="Catálogo de conceptos">
                            <Button
                                onClick={handleOpenCatalog}
                                variant="contained"
                                color="primary">
                                <BookmarkAddRounded />
                            </Button>
                        </Tooltip>
                    </CardActions>
                </Box>
                <CardContent>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}>
                        <Button
                            variant="contained"
                            startIcon={<AddCircleOutline />}
                            onClick={handleOpen}
                            sx={{
                                flexBasis: { xs: '90%', sm: '30%' },
                                minWidth: { xs: '90%', sm: '30%' },
                                mb: { xs: 2, sm: 0 },
                            }}>
                            Registrar un nuevo concepto
                        </Button>
                        <TextField
                            variant="outlined"
                            placeholder="Buscar un concepto"
                            size="small"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            sx={{
                                minWidth: { xs: '90%', sm: '30%' },
                                backgroundColor: theme.palette.background.paper,
                                mr: -2,
                            }}
                        />
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end">
                        <Tooltip title="Editar los seleccionados">
                            <IconButton
                                color="inherit"
                                disabled={
                                    selectedIds.length === 0 ||
                                    hasCancelledConcepts
                                }
                                onClick={handleOpenEdit}
                                sx={{
                                    backgroundColor: 'transparent',
                                    color:
                                        selectedRows.length > 0
                                            ? colors.yellow[500]
                                            : theme.palette.grey[500],
                                }}>
                                <EditRoadTwoToneIcon />
                            </IconButton>
                        </Tooltip>
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ mx: 1 }}
                        />
                        <Tooltip title="Cancelar los seleccionados">
                            <IconButton
                                color="inherit"
                                disabled={
                                    selectedIds.length === 0 ||
                                    hasCancelledConcepts
                                }
                                onClick={handleDeleteSelected}
                                sx={{
                                    backgroundColor: 'transparent',
                                    color:
                                        selectedIds.length > 0 &&
                                        !hasCancelledConcepts
                                            ? colors.red[500]
                                            : theme.palette.grey[500],
                                }}>
                                <DeleteSweepTwoToneIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <Box
                        flexWrap="wrap"
                        display="grid"
                        gridTemplateColumns={{ xs: '1fr', md: 'auto' }}
                        gap={2}>
                        <Tabs
                            value={statusFilter}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            sx={{ marginBottom: 2 }}>
                            <Tab label="Todos" value="all" />
                            <Tab label="Pagados" value="paid" />
                            <Tab label="Pendientes" value="pending" />
                            <Tab label="Cancelados" value="canceled" />
                        </Tabs>
                        <DataGrid
                            rowHeight={100}
                            rows={filteredRows}
                            columns={columns}
                            autoHeight
                            pageSize={15}
                            checkboxSelection
                            onSelectionModelChange={(newSelection) => {
                                setSelectedIds(newSelection);
                                handleSelectionChange(newSelection);
                            }}
                            selectionModel={selectedIds}
                            disableColumnReorder
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            components={{
                                Toolbar: () => (
                                    <ExportButton fileName="Conceptos" />
                                ),
                                Pagination: CustomPagination,
                            }}
                        />
                    </Box>
                </CardContent>
            </Card>

            <CreateConcept open={open} onclose={handleClose} />
            <CatalogModal open={openCatalog} onclose={handleCloseCatalog} />

            <ModalUpdateConcept
                open={openEdit}
                onclose={handleCloseEdit}
                selectedRows={selectedRows}
            />
        </>
    );
};

export default ConceptTable;
