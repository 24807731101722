import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as Style from "../../../../../styles/index";
import { useSelector } from "react-redux";
import { selectSettingBySchoolId } from "../../../../../store/slices/entities/settings";
import { useAuth } from "../../../../../hooks";
import { DateTime } from "luxon";

const JustificationModal = ({ title, openModal, setOpenModal, absences }) => {
  const classes = Style.tableModalStyle();
  const Auth = useAuth();
  const schoolId = Auth.getUser().school_id;

  const config = useSelector(selectSettingBySchoolId(schoolId));

  const [absencesData, setAbsencesData] = useState([]);

  const formatDate = (dateString) => {
    const dateObject = DateTime.fromISO(dateString.replace(" ", "T"));
    const formattedDate = dateObject
      .setLocale("es")
      .toFormat("cccc d 'de' MMMM 'del' yyyy");

    const formattedDateWithCapitalized = formattedDate.replace(
      /^\w|\s\w/g,
      (letter) => letter.toUpperCase()
    );

    return formattedDateWithCapitalized;
  };

  useEffect(() => {
    if (absences) {
      const newArray = absences.map((obj) => {
        return { ...obj };
      });
      setAbsencesData(newArray);
    }
  }, [absences]);

  let titleBarBackground = config.find(
    (res) => res.key === "theme-color-title-bar"
  );

  let fontColor = config.find((res) => res.key === "theme-color-font");
  /* No cerrar click afuera del modal */
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpenModal(false);
    setAbsencesData(absences);
  };

  return (
    <Dialog
      open={openModal}
      maxWidth={false}
      PaperProps={{
        sx: {
          width: "650px", // Modificar el ancho en píxeles
          maxHeight: "600px", // Modificar el alto en píxeles
        },
      }}
      onClose={handleClose}
    >
      <DialogTitle
        className={classes.dialogTitle}
        style={{
          backgroundColor: titleBarBackground.value,
          color: fontColor.value,
        }}
      >
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            style={{ flexGrow: 1, color: fontColor.value }}
          >
            {title}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        {absencesData.length > 0 && (
          <TableContainer>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: titleBarBackground.value }}>
                  <TableCell align="center" style={{ color: fontColor.value }}>
                    FECHA DE FALTA
                  </TableCell>
                  <TableCell align="center" style={{ color: fontColor.value }}>
                    MOTIVO JUSTIFICACIÓN
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {absencesData?.map((row, i) => (
                  <TableRow
                    key={row.assist_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {formatDate(row.date)}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.reason_justification}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default JustificationModal;
