import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const discountCatalogAdapter = createEntityAdapter({
    selectId: (discount) => discount.discount_catalog_id,
    sortComparer: (a, b) => a.discount_id - b.discount_catalog_id,
});

export const DiscountCatalogSlice = createSlice({
    name: 'discount_catalog',
    initialState: discountCatalogAdapter.getInitialState(),
    reducers: {
        setAllCatalogDiscounts: discountCatalogAdapter.setAll,
        addManyCatalogDiscounts: discountCatalogAdapter.addMany,
        addOneCatalogDiscount: discountCatalogAdapter.addOne,
        removeOneCatalogDiscount: discountCatalogAdapter.removeOne,
        upsertOneCatalogDiscount: discountCatalogAdapter.upsertOne,
        upsertManyCatalogDiscounts: discountCatalogAdapter.upsertMany,
        updateManyCatalogDiscounts: discountCatalogAdapter.updateMany,
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return discountCatalogAdapter.getInitialState();
        });
    },
});

export const {
    setAllCatalogDiscounts,
    addManyCatalogDiscounts,
    addOneCatalogDiscount,
    removeOneCatalogDiscount,
    upsertOneCatalogDiscount,
    upsertManyCatalogDiscounts,
    updateManyCatalogDiscounts,
} = DiscountCatalogSlice.actions;

const globalizedSelectors = discountCatalogAdapter.getSelectors(
    (state) => state.entities.discount_catalog
);

export default DiscountCatalogSlice.reducer;
