import { createSlice } from '@reduxjs/toolkit';
import * as constants from '../../../service/const';
import _ from 'lodash';
import { fetchAllResources } from '../entities';
import { addAsyncCase, initialStateStudents } from '../commonState';
import {
    getAllStudentsOnDirectors,
    getBestAverageGrade,
    getBestAverageLevel,
    getMoreInassistLevel,
    getMoreInassistsGrade,
    getMostReportGrade,
    getMostReportLevel,
    getSchoolPerformanceSummary,
    getWorstAverageGrade,
    getWorstAverageLevel,
} from './thunks';

export const fetchSlice = createSlice({
    name: 'components',
    initialState: initialStateStudents,
    reducers: {
        invalidate: (state) => {
            if (state) {
                Object.keys(state).forEach((kpiName) => {
                    if (state[kpiName] && state[kpiName].fetch) {
                        state[kpiName].fetch.didInvalidate = true;
                    }
                });
            }
        },
    },

    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return initialStateStudents;
        });

        addAsyncCase(builder, getBestAverageLevel, 'best_average_level');
        addAsyncCase(builder, getWorstAverageLevel, 'worst_average_level');
        addAsyncCase(builder, getBestAverageGrade, 'best_average_grade');
        addAsyncCase(builder, getWorstAverageGrade, 'worst_average_grade');
        addAsyncCase(builder, getMoreInassistLevel, 'more_inassist_level');
        addAsyncCase(builder, getMoreInassistsGrade, 'more_inassists_grade');
        addAsyncCase(builder, getMostReportLevel, 'most_report_level');
        addAsyncCase(builder, getMostReportGrade, 'most_report_grade');
        addAsyncCase(builder, getAllStudentsOnDirectors, 'students');
        //addAsyncCase(builder, getSchoolPerformanceSummary, 'students_sumari');
    },
});

export const { invalidate } = fetchSlice.actions;

export const getLoading = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getDataStatus = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getFetchStatus = (state) =>
    state.moduleDirector.entities.fetch.statusServer;
export const getCycleSelected = (state) =>
    state.moduleDirector.professors.ui.cycleSelected;
export const getCurrentTab = (state) =>
    state.moduleDirector.professors.ui.currentTab;

export default fetchSlice.reducer;

//////////////////////////////////////////////////////////////////////////////////////////

export const selectBestAverageLevel = (state) => {
    return state.moduleDirector.students.components.best_average_level.data;
};
export const selectWorstAverageLevel = (state) => {
    return state.moduleDirector.students.components.worst_average_level.data;
};
export const selectBestAverageGrade = (state) => {
    return state.moduleDirector.students.components.best_average_grade.data;
};
export const selectWorstAverageGrade = (state) => {
    return state.moduleDirector.students.components.worst_average_grade.data;
};
export const selectMoreInassistLevel = (state) => {
    return state.moduleDirector.students.components.more_inassist_level.data;
};
export const selectMoreInassistsGrade = (state) => {
    return state.moduleDirector.students.components.more_inassists_grade.data;
};
export const selectMostReportLevel = (state) => {
    return state.moduleDirector.students.components.most_report_level.data;
};
export const selectMostReportGrade = (state) => {
    return state.moduleDirector.students.components.most_report_grade.data;
};
export const selectAllStudentsOnDirectors = (state) => {
    return state.moduleDirector.students.components.students.data;
};
export const selectSchoolPerformanceSummary = (state) => {
    return state.moduleDirector.students.components.students_sumari.data;
};
export const selectStudents = (state) => {
    return state.moduleDirector.students;
};
export const selectDirectorStudentsStatusServer = (store) =>
    store.moduleDirector.students.components.status.statusServer;
