import React from 'react';
import applestore from '../assets/applestore.png';
import googlePlay from '../assets/google_play.png';
import huawei from '../assets/huawei.png';
import Stack from '@mui/material/Stack';

const Downloads = () => {
    return (
        <div className="container">
            <h4>Descarga la aplicación en</h4>
            <Stack
                className="tiendas"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                useFlexGap
                direction="row"
                spacing={2}>
                <a
                    href="https://play.google.com/store/apps/details?id=com.cura.appmovil"
                    target="_blank">
                    <img className="stores" src={googlePlay} />
                </a>
                <a
                    href="https://apps.apple.com/mx/app/cura/id1671354129"
                    target="_blank">
                    <img className="stores" src={applestore} />
                </a>
                <a
                    href="https://appgallery.huawei.com/app/C108736207"
                    target="_blank">
                    <img className="stores" src={huawei} />
                </a>
            </Stack>
        </div>
    );
};
export default Downloads;
