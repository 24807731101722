import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Container,
    Avatar,
    Chip,
} from '@mui/material';
import {
    People,
    Work,
    InsertChart,
    Event,
    AttachMoney,
    Description,
} from '@mui/icons-material';
import { ResponsiveBar } from '@nivo/bar';

const Testing = () => {
    const SummaryCard = ({ icon: Icon, title, value }) => (
        <Card style={{ backgroundColor: '#ffffff' }}>
            <CardContent style={{ textAlign: 'center' }}>
                <Icon style={{ fontSize: 40, color: '#3f51b5' }} />
                <Typography variant="h6">{title}</Typography>
                <Typography variant="h4" style={{ color: '#3f51b5' }}>
                    {value}
                </Typography>
            </CardContent>
        </Card>
    );

    const revenueData = [
        { day: '16/08', earnings: 2500, expenses: -1500 },
        { day: '17/08', earnings: 3000, expenses: -1000 },
        { day: '18/08', earnings: 2000, expenses: -1000 },
        { day: '19/08', earnings: 4000, expenses: -2000 },
        { day: '20/08', earnings: 1500, expenses: -500 },
        { day: '21/08', earnings: 2000, expenses: -1000 },
        { day: '22/08', earnings: 1000, expenses: -500 },
    ];

    const projectsData = [
        {
            name: 'Sunil Joshi',
            role: 'Web Designer',
            project: 'Elite Admin',
            priority: 'Low',
            budget: '$3.9k',
            avatar: 'S',
        },
        {
            name: 'John Deo',
            role: 'Web Developer',
            project: 'Flexy Admin',
            priority: 'Medium',
            budget: '$24.5k',
            avatar: 'J',
        },
        {
            name: 'Mathew Anderson',
            role: 'Web Manager',
            project: 'Material Pro',
            priority: 'High',
            budget: '$12.8k',
            avatar: 'M',
        },
        {
            name: 'Yuvraj Sheth',
            role: 'Project Manager',
            project: 'Xtreme Admin',
            priority: 'Very High',
            budget: '$2.4k',
            avatar: 'Y',
        },
    ];

    const getPriorityStyles = (priority) => {
        const baseStyles = {
            maxWidth: '100%',
            fontFamily: '"Plus Jakarta Sans", sans-serif',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '24px',
            cursor: 'unset',
            verticalAlign: 'middle',
            boxSizing: 'border-box',
            fontWeight: 600,
            fontSize: '0.75rem',
            whiteSpace: 'nowrap',
            transition:
                'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
            outline: 0,
            textDecoration: 'none',
            borderWidth: 0,
            padding: 0,
            borderRadius: '8px',
        };

        switch (priority) {
            case 'Low':
                return {
                    ...baseStyles,
                    backgroundColor: 'rgb(230, 255, 250)', // Light Green
                    color: 'rgb(19, 222, 185)', // Dark Green
                };
            case 'Medium':
                return {
                    ...baseStyles,
                    backgroundColor: 'rgb(254, 245, 229)', // Light Yellow
                    color: 'rgb(255, 174, 31)', // Dark Yellow
                };
            case 'High':
                return {
                    ...baseStyles,
                    backgroundColor: 'rgb(232, 247, 255)', // Light Blue
                    color: 'rgb(73, 190, 255)', // Dark Blue
                };
            case 'Very High':
                return {
                    ...baseStyles,
                    backgroundColor: 'rgb(253, 237, 232)', // Light Red
                    color: 'rgb(250, 137, 107)', // Dark Red
                };
            default:
                return {
                    ...baseStyles,
                    backgroundColor: '#e0e0e0', // Default background color
                    color: '#000000', // Default text color
                };
        }
    };

    return (
        <Container>
            <Grid container spacing={3} style={{ marginTop: 20 }}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={2}>
                            <SummaryCard
                                icon={People}
                                title="Employees"
                                value="96"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <SummaryCard
                                icon={Work}
                                title="Clients"
                                value="3,650"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <SummaryCard
                                icon={InsertChart}
                                title="Projects"
                                value="356"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <SummaryCard
                                icon={Event}
                                title="Events"
                                value="696"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <SummaryCard
                                icon={AttachMoney}
                                title="Payroll"
                                value="$96k"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <SummaryCard
                                icon={Description}
                                title="Reports"
                                value="59"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card style={{ backgroundColor: '#f7f9fc' }}>
                        <CardContent>
                            <Typography
                                variant="h6"
                                style={{ marginBottom: 20 }}
                            >
                                Revenue Updates
                            </Typography>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginBottom: 20 }}
                            >
                                <InputLabel>March 2023</InputLabel>
                                <Select defaultValue="March 2023">
                                    <MenuItem value="March 2023">
                                        March 2023
                                    </MenuItem>
                                    <MenuItem value="April 2023">
                                        April 2023
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <div style={{ height: 400 }}>
                                <ResponsiveBar
                                    data={revenueData}
                                    keys={['earnings', 'expenses']}
                                    indexBy="day"
                                    margin={{
                                        top: 50,
                                        right: 130,
                                        bottom: 50,
                                        left: 60,
                                    }}
                                    padding={0.7}
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={['#1e90ff', '#87cefa']}
                                    borderColor={{
                                        from: 'color',
                                        modifiers: [['darker', 1.6]],
                                    }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Date',
                                        legendPosition: 'middle',
                                        legendOffset: 32,
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Amount ($k)',
                                        legendPosition: 'middle',
                                        legendOffset: -40,
                                    }}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor={{
                                        from: 'color',
                                        modifiers: [['darker', 1.6]],
                                    }}
                                    enableLabel={false}
                                    tooltip={({
                                        id,
                                        value,
                                        color,
                                        indexValue,
                                    }) => (
                                        <div
                                            style={{
                                                padding: '5px 10px',
                                                background: '#fff',
                                                border: '1px solid #ccc',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            <strong style={{ color }}>
                                                {id}
                                            </strong>
                                            : ${value.toLocaleString()}
                                        </div>
                                    )}
                                    legends={[
                                        {
                                            dataFrom: 'keys',
                                            anchor: 'bottom-right',
                                            direction: 'column',
                                            justify: false,
                                            translateX: 120,
                                            translateY: 0,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 20,
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 0.85,
                                            symbolSize: 20,
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemOpacity: 1,
                                                    },
                                                },
                                            ],
                                        },
                                    ]}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    borderRadius={15}
                                />
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{ marginTop: 20 }}
                            >
                                View Full Report
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card style={{ backgroundColor: '#f7f9fc' }}>
                        <CardContent>
                            <Typography
                                variant="h6"
                                style={{ marginBottom: 20 }}
                            >
                                Expenses
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{ color: 'green', marginBottom: 10 }}
                            >
                                This Month: $5,000
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{ color: 'red' }}
                            >
                                Last Month: $7,500
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{ marginTop: 20 }}
                            >
                                View Detailed Report
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ padding: 20 }}>
                        <Typography variant="h6" style={{ marginBottom: 20 }}>
                            Top Projects
                        </Typography>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            style={{ marginBottom: 20 }}
                        >
                            <InputLabel>March 2022</InputLabel>
                            <Select defaultValue="March 2022">
                                <MenuItem value="March 2022">
                                    March 2022
                                </MenuItem>
                                <MenuItem value="April 2022">
                                    April 2022
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Assigned</TableCell>
                                        <TableCell>Project</TableCell>
                                        <TableCell>Priority</TableCell>
                                        <TableCell>Budget</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {projectsData.map((project, index) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Avatar
                                                    style={{ marginRight: 10 }}
                                                >
                                                    {project.avatar}
                                                </Avatar>
                                                <div>
                                                    <Typography variant="subtitle1">
                                                        {project.name}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                    >
                                                        {project.role}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                {project.project}
                                            </TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={project.priority}
                                                    sx={getPriorityStyles(
                                                        project.priority
                                                    )}
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {project.budget}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Testing;
