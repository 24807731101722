import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    Skeleton,
    Box,
    Stack,
} from '@mui/material';
import { getSpecialName, getTurnLevel } from '../../../libs/utils';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useSelector } from 'react-redux';
import {
    loadSubjectsUI,
    selectStudentItemSelected,
    selectSubjectstatusServerItemSelected,
} from '../../../store/slices/studentsUI/ItemsSlice';
import { useDispatch } from 'react-redux';
import { DataGrid, esES } from '@mui/x-data-grid';
import * as Style from '../../../styles/index';
import { Error } from '../../../components/Feedback';
import { selectProfessors } from '../../../store/slices/entities/users';

/**
 * Modal para mostrar las materias de un alumno
 *
 * @param {*} param0
 *
 * @returns
 */
const StudentSubjectsModal = ({ open, setOpen }) => {
    const classes = Style.tableModalStyle();

    const dispatch = useDispatch();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    //////////// SHARED STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    const studentSelected = useSelector(selectStudentItemSelected(null));
    const statusServer = useSelector(selectSubjectstatusServerItemSelected);

    const professorList = useSelector(selectProfessors);

    /**
     * Iniciamos el flujo de recuperacion de los grupos
     * del alumno seleccionado
     */
    useEffect(() => {
        if (open) {
            dispatch(loadSubjectsUI());
        }
    }, [open]);

    /////////////////////// ACTIONS ////////////////////
    /**
     *
     */
    const closeModal = () => {
        setOpen(false);
    };

    /**
     * Funcion para recargar los datos del usuario
     */
    const reload = () => {
        dispatch(loadSubjectsUI())
            .unwrap()
            .then((response) => { });
    };

    /**
     * Definicion de las columnas
     */
    const columns = [
        {
            field: 'folio',
            headerName: 'Folio',
            flex: 0.5,
            width: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params.row.subjectCatalog.folio,
        },
        {
            field: 'title',
            headerName: 'Nombre',
            flex: 0.5,
            width: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params.row.subjectCatalog.title,
            renderCell: ({ row }) => {
                const fullName = `${row.subjectCatalog.title}`;
                return (
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}
                    >
                        {fullName}
                    </div>
                );
            },
        },
        {
            field: 'special',
            headerName: 'Especialidad',
            flex: 0.5,
            width: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return params.row.group.annexed_name;
            },
            renderCell: ({ row }) => {
                const moduleName = `${row.group.annexed_name}`;
                return (
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}
                    >
                        {moduleName !== 'null' ? moduleName : ''}
                    </div>
                );
            },
        },
        {
            field: 'group',
            headerName: 'Grupo',
            flex: 1,
            width: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return `${params.row.group.grade}° ${params.row.group.group
                    } ${getTurnLevel(params.row.group.turn)}`;
            },
        },
        {
            field: 'teacher',
            headerName: 'Maestro',
            flex: 1,
            width: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                let professor = professorList.find(
                    (e) => e.professor_id == params.row.professor_id
                );
                if (professor) {
                    return `${professor?.name +
                        ' ' +
                        professor?.last_name +
                        ' ' +
                        professor?.second_last_name
                        }`;
                } else {
                    return 'Sin Maestro';
                }
            },
        },
    ];

    const modulesColumns = [
        {
            field: 'folio',
            headerName: 'Folio',
            flex: 0.5,
            width: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => params.row.subjectCatalog.folio,
        },
        {
            field: 'title',
            headerName: 'Nombre',
            flex: 0.5,
            width: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => params.row.subjectCatalog.title,
            renderCell: ({ row }) => {
                const fullName = `${row.subjectCatalog.title}`;
                return (
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}
                    >
                        {fullName}
                    </div>
                );
            },
        },
        {
            field: 'group',
            headerName: 'Grupo',
            flex: 1,
            width: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.group.grade}° ${params.row.group.group
                    } ${getTurnLevel(params.row.group.turn)}`;
            },
        },
        {
            field: 'teacher',
            headerName: 'Maestro',
            flex: 1,
            width: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                let professor = professorList.find(
                    (e) => e.professor_id == params.row.professor_id
                );
                if (professor) {
                    return `${professor?.name +
                        ' ' +
                        professor?.last_name +
                        ' ' +
                        professor?.second_last_name
                        }`;
                } else {
                    return 'Sin Maestro';
                }
            },
        },
    ];

    const studentsGroups = studentSelected.subjectsList.map((i) => {
        return {
            id: i.subject_id,
            ...i,
        };
    });

    const stundetModules = studentSelected.modules.map((i) => {
        return {
            id: i.group_id,
            ...i,
        };
    });

    const sortedGroups = studentsGroups.sort((a, b) => {
        const handleNullOrUndefined = (value) => {
            return value === undefined || value === null || value === "" ? "" : value;
        };

        const especialidadA = handleNullOrUndefined(a.group.annexed_name);
        const especialidadB = handleNullOrUndefined(b.group.annexed_name);
        if (especialidadA < especialidadB) return -1;
        if (especialidadA > especialidadB) return 1;

        const grupoPrincipalA = [a.group.grade, a.group.group, a.group.turn].map(handleNullOrUndefined).join("-");
        const grupoPrincipalB = [b.group.grade, b.group.group, b.group.turn].map(handleNullOrUndefined).join("-");
        if (grupoPrincipalA < grupoPrincipalB) return -1;
        if (grupoPrincipalA > grupoPrincipalB) return 1;

        const materiaA = handleNullOrUndefined(a.subjectCatalog.title);
        const materiaB = handleNullOrUndefined(b.subjectCatalog.title);
        if (materiaA < materiaB) return -1;
        if (materiaA > materiaB) return 1;

        return 0;
    });

    return (
        <>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="lg"
                lasses={{ paper: classes.dialogWrapper }}
                disableEscapeKeyDown={statusServer == 'pending'}
                onClose={() => {
                    closeModal();
                }}
            >
                <DialogTitle
                    className={classes.dialogTitle}
                    style={{
                        backgroundColor: titleBarBackground.value,
                        color: fontColor.value,
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography
                            variant="h6"
                            component="div"
                            style={{ flexGrow: 1 }}
                        >
                            Materias
                            <br />
                            <span style={{ fontSize: '15px' }}>
                                Alumno:{' '}
                                {`${studentSelected.name} ${studentSelected.last_name}`}
                            </span>
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {statusServer == 'pending' && (
                        <Stack spacing={2} sx={{ padding: 2 }}>
                            <Skeleton variant="rectangular" height={30} />
                            <Skeleton variant="rectangular" height={30} />
                            <Skeleton variant="rectangular" height={30} />
                            <Skeleton variant="rectangular" height={30} />
                        </Stack>
                    )}

                    {statusServer == 'rejected' && (
                        <Error
                            onRetry={reload}
                            message={'Estamos teniendo problemas'}
                        />
                    )}

                    {statusServer == 'fulfilled' && (
                        <Box
                            sx={{
                                '& .super-app-theme--header': {
                                    backgroundColor: titleBarBackground.value,
                                    color: fontColor.value,
                                },
                                '& .MuiDataGrid-root': {
                                    border: 0,
                                },
                            }}
                        >
                            <Box sx={{ textAlign: 'center' }}>
                                <DataGrid
                                    localeText={
                                        esES.components.MuiDataGrid.defaultProps
                                            .localeText
                                    }
                                    rows={sortedGroups}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    autoHeight
                                />
                            </Box>
                            {stundetModules.map((module, i) => {
                                return (
                                    <Box key={i}>
                                        <Typography
                                            sx={{ textAlign: 'center ' }}
                                            variant="h6"
                                        >{`Módulo: ${module.annexed_name}`}</Typography>
                                        <DataGrid
                                            getRowId={(row) => row.subject_id}
                                            localeText={
                                                esES.components.MuiDataGrid
                                                    .defaultProps.localeText
                                            }
                                            rows={module.subjects}
                                            columns={modulesColumns}
                                            pageSize={10}
                                            rowsPerPageOptions={[10]}
                                            disableSelectionOnClick
                                            autoHeight
                                            sx={{
                                                '& .super-app-theme--header': {
                                                    backgroundColor: 'initial',
                                                    color: 'initial',
                                                },
                                            }}
                                        />
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={closeModal}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default StudentSubjectsModal;
