import * as constants from "./../service/const";


const SCHOOL_LEVELS = constants.levelsNames

const NOTICE_TYPES = constants.NOTICE_ITEMS
const SPECIAL_TYPES = constants.specialNames
const TURNS_TYPES = constants.turnsNames

const VALIDATIONS = {
    ALPHA_NUMERIC_SPACES: /(^[#A-Za-z0-9-\/:ñÑáéíóúÁÉÍÓÚÜü,.\s_-]+$)+/,
    INTEGER: /^[0-9\-]+$/,
    FOLIO: /^[A-Za-z0-9\-]+$/
}


export {
    SCHOOL_LEVELS,
    NOTICE_TYPES,
    SPECIAL_TYPES,
    TURNS_TYPES,
    VALIDATIONS
}