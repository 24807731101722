import React, { useState } from 'react';
import Services from '../../../service/EficazAPI';
import ClientHttp from '../../../service/HttpClient';
import { Box, Grid, Snackbar } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Feedback from '../../../service/Feedback';
import { LoadingButton } from '@mui/lab';
import { NavLink } from 'react-router-dom';

// Assets
import directivo from '../../assets/directivo.png';
import facebook from '../../assets/facebook.svg';
import instagram from '../../assets/instagram.svg';
import whatsapp from '../../assets/whatsapp.svg';
import whatsAppFloat from '../../assets/whatsAppFloat.svg';
import logoFooter from '../../assets/logo_footer.svg';
import palomita from '../../assets/palomita.svg';
import youtube from '../../assets/youtube.svg';
import funcionalidades_top from '../../assets/funcionalidades_top.png';
import NavBar from '../../Components/NavBar';

// Styles
// import '../style.css';

const RecoverAccount = () => {
    ///////////////////// DATOS PARA EL FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message);
        setOpenFeedback(true);
    };

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    const [isValid, setIsValid] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);

    const [loading, setLoading] = React.useState(false);

    ///////////////////////// CODIGO DEL COMPONENTE ////////////////////////////////

    const [email, setEmail] = useState('');

    const [FeedbackAPI] = useState(() => new Feedback());

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     *
     * @param {*} event
     */
    function onEmailInputChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        setEmail(value);
    }

    const emailValid = () => {
        const regexEmail = /^[0-9]{10}$/;

        return {
            invalid: email == '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const onResetPassword = () => {
        setLoading(true);

        let client = new ClientHttp();
        client.useDomain();
        let EficazApi = new Services(client);

        EficazApi.recoverAccount({ email: email })
            .then((response) => {
                showFeedback('En breve nos comunicaremos con usted');
                setEmail('');
            })
            .catch((err) => {
                let message = FeedbackAPI.getMessage(err);
                showFeedback(message.message);
            })
            .then(() => {
                setLoading(false);
            });
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onResetPassword();
        }
    };

    const inputStyles = {
        width: '100%',
        border: '0',
        fontFamily: 'inherit',
        padding: '0',
        height: '48px',
        fontSize: '16px',
        fontWeight: '500',
        background: 'none',
        borderRadius: '0',
        color: '#fff',
        transition: 'all .15s ease',
        outline: 'none',
        borderBottom: `2px solid ${isFocused || isEmpty ? '#fff' : '#ff6969'}`,
    };

    const borderStyles = {
        position: 'absolute',
        left: '0',
        bottom: '0',
        height: '18px',
        fill: 'none',
        stroke: isValid ? '#fff' : '#ff6969',
        strokeWidth: '2',
        transition: 'all .2s ease',
    };

    const containerStyles = {
        position: 'relative',
        width: '100%',
        maxWidth: '280px',
        height: '53px',
        marginBottom: '10px',
    };

    return (
        <Box>
            <div>
                <Snackbar
                    open={openFeedback}
                    autoHideDuration={3000}
                    onClose={closeFeedback}
                    message={feedbackMessage}
                />
            </div>
            <NavBar />
            <div className="body">
                <div className="mweb">
                    <section className="sidebar">
                        <div className="custom-container">
                            <div className="grid dos">
                                <div className="info">
                                    <img src={palomita} alt="Palomita" />
                                    <h1>Recupera tu contraseña</h1>
                                    <p>
                                        Escriba su número de celular o su correo
                                        electrónico por el cual será contactado
                                        para recuperar su cuenta.
                                    </p>
                                    <div style={containerStyles}>
                                        <input
                                            id="cellphone"
                                            name="cellphone"
                                            placeholder="Celular o correo"
                                            variant="outlined"
                                            size="small"
                                            value={email}
                                            required
                                            onChange={onEmailInputChange}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            onKeyDown={handleKeyDown}
                                            style={inputStyles}
                                            error={emailValid().invalid}
                                            helperText={
                                                emailValid().invalid
                                                    ? emailValid().message
                                                    : false
                                            }
                                        />
                                        <svg
                                            width="280px"
                                            height="18px"
                                            viewBox="0 0 280 18"
                                            className="border"
                                            style={borderStyles}
                                        >
                                            <path d="M0,12 L223.166144,12 C217.241379,12 217.899687,12 225.141066,12 C236.003135,12 241.9279,12 249.827586,12 C257.727273,12 264.639498,12 274.514107,12 C281.097179,12 281.755486,12 276.489028,12"></path>
                                        </svg>
                                    </div>

                                    <div className="buttons">
                                        <Grid item xs={12}>
                                            <LoadingButton
                                                className="btn_mw red"
                                                style={{ marginBottom: '10px' }}
                                                loading={loading}
                                                endIcon={<SendIcon />}
                                                loadingPosition="end"
                                                variant="contained"
                                                onClick={onResetPassword}
                                            >
                                                Recuperar
                                            </LoadingButton>
                                        </Grid>
                                    </div>
                                </div>
                                <div className="maestra">
                                    <img
                                        src={funcionalidades_top}
                                        alt="maestra"
                                    />
                                    <div className="botonix">
                                        <div></div>
                                        <div className="delay"></div>
                                        <div className="moredelay"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer>
                        <img className="palomita" src={palomita} />
                        <div className="container">
                            <div className="grid cinco">
                                <div>
                                    <img src={logoFooter} />
                                    <p>
                                        Transformando la manera en que Maestros,
                                        Padres de familia y Directores gestionan
                                        la educación.
                                    </p>
                                </div>
                                <div>
                                    <img
                                        src={directivo}
                                        style={{ width: '230px' }}
                                    />
                                </div>
                                <div className="FBeneficios">
                                    <h4>Beneficios</h4>
                                    <ul>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}
                                            >
                                                <a>Maestros</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}
                                            >
                                                <a>Padres</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}
                                            >
                                                <a>Orientadores</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}
                                            >
                                                <a>Directivos</a>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="FFuncionalidades">
                                    <h4>Funcionalidades</h4>
                                    <ul>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}
                                            >
                                                <a>
                                                    Calificaciones y asistencias
                                                </a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}
                                            >
                                                <a>Promedio automático</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}
                                            >
                                                <a>
                                                    Notificaciones para padres
                                                </a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}
                                            >
                                                <a>
                                                    Reportes de comportamiento
                                                </a>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul className="redes">
                                        <li>
                                            <a
                                                href="https://www.facebook.com/curaeducacion"
                                                target="_blank"
                                            >
                                                <img src={facebook} />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/curaeducacion/"
                                                target="_blank"
                                            >
                                                <img src={instagram} />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.youtube.com/@cura-educacionefectiva-oficial"
                                                target="_blank"
                                            >
                                                <img src={youtube} />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="https://wa.me/5216181448945" target="_blank">
                                                <img src={whatsapp} />
                                            </a>
                                        </li> */}
                                    </ul>
                                    <a
                                        href="https://wa.me/5216181448945"
                                        className="float-wa"
                                        target="_blank"
                                    >
                                        <img
                                            src={whatsAppFloat}
                                            className="wa-icon"
                                        />
                                    </a>
                                    <a
                                        href="https://wa.me/5216181448945"
                                        target="_blank"
                                    >
                                        <h2>
                                            <img src={whatsapp} /> 618 144 8945
                                        </h2>
                                    </a>
                                    <h2>Email: curati2023@gmail.com</h2>
                                    <h2>Dirección</h2>
                                    <p className="dir">
                                        Blvd. Domingo Arrieta 1700, Gral Domingo
                                        Arrieta, CP 34180, Durango, Dgo., Mexico
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="bottom">
                            <div className="container">
                                <span>
                                    Cura App ® Todos los Derechos Reservados •
                                    Durango, Dgo. México • 2023
                                </span>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </Box>
    );
};

export default RecoverAccount;
