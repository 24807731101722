import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useAuth } from "../../../hooks";
import useFeedback from "../../../hooks/useFeedback";
import { useDispatch, useSelector } from "react-redux";
import { selectSettingBySchoolId } from "../../../store/slices/entities/settings";
import Services from "./../../../service/Connection";
import { upsertOneImport } from "../../../store/slices/entities/imports";
import Feedback from "../../../service/Feedback";

export const UpdateImportModal = ({
    openUpdateModal,
    setOpenUpdateModal,
    importData,
}) => {
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const feedbackApp = useFeedback();
    const dispatch = useDispatch();

    const config = useSelector(selectSettingBySchoolId(schoolId));

    let titleBarBackground = config.find(
        (res) => res.key === "theme-color-title-bar"
    );
    let fontColor = config.find((res) => res.key === "theme-color-font");

    const [importState, setImportState] = useState({
        file: null,
    });
    const [loading, setLoading] = useState(false);

    /**
     * file extensions allowed 
     */
    const importFiles = [
        '.csv',
        '.xlsx',
        '.xls'
    ]

    /**
     * handler change input
     */
    const onInputChange = (event) => {
        let selectedFile = event.target.files[0];

        if (selectedFile) {
            let allowFile = importFiles.find(ext => selectedFile.name.endsWith(ext))

            if (allowFile) {
                setImportState({ import: selectedFile });
            } else {
                feedbackApp.showFeedback({
                    title: "Por favor seleccione un archivo con extensión " + importFiles.join(","),
                });

                event.target.value = null;
                selectedFile = null;

                setImportState({ file: null });
            }
        }
    };

    /**
     * handler close modal
     */
    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setImportState({
            file: null,
        })
        setLoading(false)
        setOpenUpdateModal(false);
    };

    /**
     * handler retry importation
     */
    const sendNewImport = () => {
        setLoading(true);

        let formData = new FormData();
        formData.append("file", importState.import);
        formData.append("_method", "PATCH");

        Services.updateImportFile(importData.import_id, formData)
            .then((response) => {

                dispatch(upsertOneImport({
                    ...response.data.data,
                    status: 1
                }));

                feedbackApp.showFeedback({
                    title: "Importación Actualizada, por favor sincronizar para revisar el estado",
                });

                handleClose();
            })
            .catch((err) => {
                console.log(err)
                let FeedbackService = new Feedback()

                feedbackApp.showFeedback({
                    title: FeedbackService.getMessage(err).message
                });
            }).then(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog
            open={openUpdateModal}
            fullWidth={true}
            disableEscapeKeyDown={loading === true}
            onClose={handleClose}
        >
            <DialogTitle
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <div style={{ display: "flex" }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ flexGrow: 1, color: fontColor.value }}
                    >
                        {`Reintentar importación`}
                    </Typography>
                </div>
            </DialogTitle>

            <DialogContent dividers>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <Grid container spacing={1}>
                        <Grid
                            container
                            item
                            xl={12}
                            sm={12}
                            md={12}
                            xs={12}
                            style={{ marginTop: "5px" }}
                        >
                            <TextField
                                id="import"
                                name="import"
                                size="small"
                                variant="outlined"
                                inputProps={{ maxLength: 50 }}
                                value={importState?.file_name}
                                onChange={onInputChange}
                                fullWidth
                                type="file"
                                accept=".csv"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={() => {
                        sendNewImport();
                    }}
                    loading={loading === true}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={importState.file === null}
                >
                    {"Importar"}
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        handleClose();
                    }}
                    disabled={loading === true}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
