import axios from 'axios';
import enviroment from './Service';

export const setContactForm = (data) => {
    return axios.post(
        `${enviroment.RUTA_API}${enviroment.VERSION}/support/contact`,
        data,
        { proxy: false }
    );
};
