
import {
    Card, CardContent, Box,
    Typography, Button, CardHeader,
    Stack, Grid, Tooltip, LinearProgress
} from '@mui/material'
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { invalidate, loadUI, saveSetting, selectStatusOperation, selectStatusServer } from '../../../store/slices/settingsUI';
import { ColorPicker, useTranslate } from 'material-ui-color'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFeedback from '../../../hooks/useFeedback';
import { useAuth } from '../../../hooks';

/**
 * Tarjeta que contiene las configuraciones del tema
 * 
 * @returns 
 */
const ThemeSettingsCard = () => {

    const dispatch = useDispatch();
    const feedbackApp = useFeedback()

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    const palette = {
        Rojo: '#ff0000',
        Azul: '#0000ff',
        Verde: '#00ff00',
        Amarillo: '#FDFD00',
        Cyan: '#05F6F6',
        Lima: '#05F405',
        Gris: '#818080',
        Naranja: '#F8A202',
        Morado: '#7E027E',
        Negro: '#000',
        Blanco: '#fff',
        Rosa: '#FABAC5',
        AzulMarino: '#01018A',
    };

    //////////////// SHARED STATE ///////////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))

    const statusServer = useSelector(selectStatusServer)
    const statusOperation = useSelector(selectStatusOperation)

    ///////////// LOCAL STATE //////////////

    const [state, setState] = useState('idle')

    /**
     * Estado de las configuraciones para tema
     */
    let themeColorTitleBar = config.find(res => res.key === 'theme-color-title-bar');
    let themeColorMenuBar = config.find(res => res.key === 'theme-color-menu-bar');
    let themeColorSelection = config.find(res => res.key === 'theme-color-selection');
    let themeColorFont = config.find(res => res.key === 'theme-color-font');

    //////////////// TRADUCCION DE LOS COLORE ///////////////////////

    const es = {
        Set: "seleccionar"
    }

    const [language, setLanguage] = useState('es');
    const translate = value => {
        let valueTranslated;
        if (value && language === 'es') valueTranslated = es[value];
        return valueTranslated || value;
    };

    useTranslate(() => ({ i18n: { language }, t: translate }))


    /////////////// ACTIONS //////////////

    /**
     * Escuchamos las propiedades en busca de una
     * actualizacio
     */
    useEffect(() => {
        if ((statusOperation == 'rejected' || statusOperation == 'fulfilled') && state == 'pending') {
            setState('idle')
        }
    }, [statusOperation]);

    /**
     * Funcion para actualizar las configuraciones
     * 
     * @param string value
     * @param string settingKey
     */
    const onUpdateInputSetting = (value, settingKey) => {
        setState('pending')
        let setting = config.find(res => res.key === settingKey)

        dispatch(saveSetting({ settingId: setting.configuration_id, value }))
            .unwrap()
            .then((res) => {
                feedbackApp.showFeedback({
                    title: 'Configuración actualizada correctamente',
                })
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }


    return (
        <Card>
            {
                state == 'pending' && <LinearProgress />
            }
            <CardHeader
                title="Ajustes de tema"
                titleTypographyProps={{
                    sx: {
                        fontSize: 18
                    }
                }}
                sx={{
                    paddingBottom: 2
                }}
            />
            <CardContent>
                <Stack
                    spacing={2}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box>
                        <Typography variant='subtitle2' component="div" style={{ width: '100%' }} >
                            Color de barra de títulos
                        </Typography>

                        <ColorPicker
                            deferred
                            palette={palette}
                            value={themeColorTitleBar?.value}
                            disableAlpha={true}
                            onChange={(value) => { onUpdateInputSetting(`#${value.hex}`, 'theme-color-title-bar') }}
                        />
                    </Box>
                    <Box>
                        <Typography variant='subtitle2' component="div" style={{ width: '100%' }} >
                            Color del menú
                        </Typography>
                        <ColorPicker
                            deferred
                            palette={palette}
                            value={themeColorMenuBar?.value}
                            disableAlpha={true}
                            onChange={(value) => { onUpdateInputSetting(`#${value.hex}`, 'theme-color-menu-bar') }}
                        />
                    </Box>
                    <Box>
                        <Typography variant='subtitle2' component="div" style={{ width: '100%' }} >
                            Color de selección
                        </Typography>
                        <ColorPicker
                            deferred
                            palette={palette}
                            value={themeColorSelection?.value}
                            disableAlpha={true}
                            onChange={(value) => { onUpdateInputSetting(`#${value.hex}`, 'theme-color-selection') }}
                        />
                    </Box>
                    <Box>
                        <Typography variant='subtitle2' component="div" style={{ width: '100%' }} >
                            Color de la fuente
                        </Typography>
                        <ColorPicker
                            deferred
                            palette={palette}
                            value={themeColorFont?.value}
                            disableAlpha={true}
                            onChange={(value) => { onUpdateInputSetting(`#${value.hex}`, 'theme-color-font') }}
                        />
                    </Box>
                </Stack>
            </CardContent>

        </Card>
    )
}

export default ThemeSettingsCard