import {
    Autocomplete,
    Avatar,
    Box,
    createFilterOptions,
    TextField,
    Typography,
    Stack,
} from '@mui/material';
import { throttle } from 'lodash';
import React, { useState } from 'react';
import Connection from '../../service/Connection';

const StudentsSearch = ({
    onChange,
    title = 'Buscar alumno',
    schoolId,
    filterParameters = {},
}) => {
    const filterOptions = createFilterOptions({
        limit: 30,
    });

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    React.useEffect(() => {
        let active = true;
        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        const request = {
            search: inputValue,
            filters: {
                status: 1,
                'not-calculate-properties': true,
                ...filterParameters,
            },
            per_page: 30,
            fields: {
                only: [
                    'folio',
                    'cellphone',
                    'email',
                    'has_smartphone',
                    'last_name',
                    'name',
                    'student_id',
                    'second_last_name',
                    'Apm',
                    'url_photo_profile',
                ],
            },
        };
        setLoading(true);

        fetch({ schoolId, request }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
                setLoading(false);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, filterParameters]);

    const fetch = React.useMemo(
        () =>
            throttle(({ schoolId, request }, callback) => {
                Connection.StudentsBySchool(schoolId, request).then((i) => {
                    callback(i.data.data);
                });
            }, 1000),
        [filterParameters]
    );

    const onChangeItem = (event, newValue) => {
        setValue(newValue);

        if (newValue) {
            Connection.getStudentById(newValue.student_id)
                .then((i) => {
                    onChange(i.data.data);
                })
                .catch((err) => {
                    setInputValue('');
                    onChange(null);
                });
        } else {
            onChange(null);
        }
    };

    return (
        <Box>
            <Typography variant="h6" component="div">
                {title}
            </Typography>

            <Autocomplete
                sx={{ paddingTop: 2 }}
                id="controllable-states-demo"
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                loading={loading}
                loadingText="Buscando..."
                noOptionsText="No se encontraron resultados"
                onChange={onChangeItem}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                getOptionLabel={(option) =>
                    `${option.name} ${option.last_name} ${option.second_last_name}`
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="ej Pedro"
                        variant="outlined"
                        fullWidth
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props}>
                        <Stack
                            direction={'row'}
                            spacing={2}
                            justifyContent="flex-start"
                            alignItems="center">
                            <Avatar
                                alt={option.name}
                                src={option.url_photo_profile}
                                sx={{ width: 34, height: 34, mr: 1 }}
                            />
                            <Typography variant="body2" color="text.secondary">
                                <strong>
                                    {option.folio ? option.folio : 'Sin folio'}
                                </strong>
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {option.name} {option.last_name}{' '}
                                {option.second_last_name}
                            </Typography>
                        </Stack>
                    </li>
                )}
            />
        </Box>
    );
};

export default StudentsSearch;
