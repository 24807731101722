import { CardHeader, Stack } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";

const ImportsToolbar = (props) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <CardHeader title="Centro de importaciones" />
      <GridToolbar {...props} />
    </Stack>
  );
};

export { ImportsToolbar };
