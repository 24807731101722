import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as _ from 'lodash';

const emptyState = {
    studentSelected: null,
    columnField: '',
    operatorValue: '',
    value: ''
}

/**
 * Slice para el settings UI
 */
export const studentsUISlice = createSlice({
    name: 'studentsUI/ui',
    initialState: emptyState,
    reducers: {
        selectStudentItem: (state, { payload }) => {
            state.studentSelected = payload
        },
        selectColumnField: (state, action) => {
            state.columnField = action.payload;
        },
        selectOperatorValue: (state, action) => {
            state.operatorValue = action.payload;
        },
        selectValue: (state, action) => {
            state.value = action.payload;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })
    }
});

export const { invalidate, selectStudentItem, selectColumnField, selectOperatorValue, selectValue } = studentsUISlice.actions;

export default studentsUISlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 * 
 * @param {*} state 
 * @returns 
 */
export const selectStudentsUI = (state) => state.studentsUI.ui;


//////////////// TRUNCKS /////////////////