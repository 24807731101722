import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../../../hooks';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    MenuItem,
    Slide,
    TextField,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PostAddIcon from '@mui/icons-material/PostAdd';
import GridOnIcon from '@mui/icons-material/GridOn';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { selectThemeBySchool } from '../../../../../store/slices/entities/settings';
import Exportable from './../../../../../service/Exportable';
import Feedback from '../../../../../service/Feedback';
import { saveFile } from '../../../../../helper';

const ExportAssistanceModal = ({
    open,
    data,
    onClose,
    selectedTitle,
    subjects,
}) => {
    /////////////// HOOKS ////////////////

    const Auth = useAuth();
    const feedbackApp = useFeedback();
    const schoolId = Auth.getUser().school_id;

    /////////////// LOCAL STATE ////////////////

    let FeedbackService = new Feedback();
    const [selectedSubject, setSelectedSubject] = useState('');
    const [fileFormat, setFileFormat] = useState('');
    const [loading, setLoading] = useState(false);

    /////////////// SHARED STATE ////////////////

    const { colorTitleBar, fontColor } = useSelector(
        selectThemeBySchool(schoolId)
    );

    /////////////// HANDLERS ////////////////

    /**
     * manejador para el cierre del modal
     */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    /**Exportar assitecnias por materia */
    const onExport = () => {
        setLoading(true);
        const newData = {
            ...data,
            query: { ...data.query, subject_id: selectedSubject },
        };

        let format = fileFormat === 'excel' ? 'xlsx' : 'pdf';
        Exportable.export(newData.report, fileFormat, newData.query, null)
            .then((result) => {
                saveFile(result.data, `asistencias-por-materia.${format}`)
                    .then(() => {
                        feedbackApp.showFeedback({
                            title: 'Archivo exportado correctamente',
                        });
                    })
                    .catch((err) => {
                        feedbackApp.showFeedback({
                            title: 'Estamos teniendo problemas al almacenar el archivo',
                        });
                    });
            })

            .catch((error) => {
                feedbackApp.showFeedback({
                    title: FeedbackService.getMessage(error).title,
                });
            })
            .then(() => {
                handleClose();

                setLoading(false);
            });
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            disableEscapeKeyDown={loading !== ''}
            aria-labelledby="responsive-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Slide}
            TransitionProps={{
                direction: 'left',
            }}
            PaperProps={{
                style: {
                    color: fontColor.value,
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    height: '100%',
                    width: '300px',
                    overflowY: 'auto',
                },
            }}
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    textAlign: 'center',
                    backgroundColor: colorTitleBar.value,
                    color: fontColor.value,
                }}
            >
                Exportador
            </DialogTitle>
            <DialogContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '16px',
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        component="div"
                        fontWeight="bold"
                        sx={{ mt: 2, textAlign: 'center', color: 'black' }}
                    >
                        {selectedTitle}
                    </Typography>
                    <PostAddIcon
                        sx={{ fontSize: 72, color: colorTitleBar.value }}
                    />
                    <Typography
                        variant="subtitile1"
                        sx={{
                            mt: 2,
                            textAlign: 'center',
                            color: 'gray',
                        }}
                    >
                        Seleccionar materia
                    </Typography>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        sx={{ paddingTop: 2 }}
                    >
                        <TextField
                            label="Seleccionar"
                            variant="outlined"
                            fullWidth
                            select
                            value={selectedSubject}
                            onChange={(e) => setSelectedSubject(e.target.value)}
                            id="subject"
                        >
                            {subjects.map((subject) => (
                                <MenuItem
                                    key={subject.subject_id}
                                    value={subject.subject_id}
                                >
                                    {`${subject.subjectCatalog.title}`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <Typography
                        variant="subtitile1"
                        sx={{
                            mt: 2,
                            textAlign: 'center',
                            color: 'gray',
                        }}
                    >
                        Seleccionar formato
                    </Typography>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        sx={{ paddingTop: 2 }}
                    >
                        <TextField
                            label="Seleccionar"
                            variant="outlined"
                            fullWidth
                            select
                            value={fileFormat}
                            onChange={(e) => setFileFormat(e.target.value)}
                            id="file"
                        >
                            <MenuItem value="" />
                            <MenuItem value="pdf">{'PDF'}</MenuItem>
                            <MenuItem value="excel">{'EXCEL'}</MenuItem>
                        </TextField>
                        <Divider sx={{ mt: 2, mx: 'auto', width: '90%' }} />
                    </FormControl>
                </div>
            </DialogContent>
            <Divider sx={{ mt: 2, mx: 'auto', width: '90%' }} />
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    color={fileFormat === 'pdf' ? 'error' : 'success'}
                    loading={false}
                    loadingPosition="start"
                    onClick={onExport}
                    startIcon={
                        fileFormat === 'pdf' ? (
                            <PictureAsPdfIcon />
                        ) : (
                            <GridOnIcon />
                        )
                    }
                    disabled={
                        selectedSubject === '' || fileFormat === '' || loading
                    }
                >
                    Descargar
                </LoadingButton>

                <Button
                    onClick={handleClose}
                    autoFocus
                    variant="contained"
                    disabled={loading}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExportAssistanceModal;
